import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import moment from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import {
    FilterSortDto,
    GenericDropDownDto,
    newSequentialId,
    ProductDistributionColumns,
    RefinerLocation,
    ResponseCountKey,
    SharedHelper,
    SortDirection,
    Subsidiary} from "shield.shared";
import { MatSortHeader } from "@angular/material/sort";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { ProductDistributionReportRefinerService } from "./product-distribution-report-refiner.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { Helper } from "src/app/helpers/helper";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { FilterLocation } from "src/app/enums/filter-location";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { ProductDistributionReportZrtFilterService } from "./product-distribution-report-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { MyListCommands } from "src/app/enums/my-list-commands";

export class ProductDistributionReportViewmodel {
    //private vars
    private formBuilder: UntypedFormBuilder;

    //public vars
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<ProductDistributionColumns>[];
    activitiesFilterService: ActivitiesFilterService;
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    customerDelineationService: CustomerDelineationService;
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    filterDataLoaded = false;
    filterLocation = FilterLocation.productDistribution;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridHeightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Product Distribution";
    isEnabledExpandedDetails = false;
    isFixedLayout = false;
    isGapSet = false;
    isInitialBatchCompleted = false;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 1000; // buffer size of the grid. We probally need to make the min and output buffers of the grid virtual scroll an input.
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    productDelineationService: ProductDelineationService;
    projectDelineationService: ProjectDelineationService;
    refinerInputChangeSubscription: Subscription;
    refinerService: ProductDistributionReportRefinerService;
    refinerServiceSubscription: Subscription;
    screenHeight: number = 0;
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    storeCount = 0; // TO DO: Store count.
    topGapDistance = 0;
    total?: number;
    zrtFilterService: ProductDistributionReportZrtFilterService;
    zrtSelectionSubscription: Subscription;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<ProductDistributionColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<ProductDistributionColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as ProductDistributionColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;

            void this.getBatch(0, true);
        }
    }

    constructor(
        refinerService: ProductDistributionReportRefinerService,
        formBuilder: UntypedFormBuilder,
        snackbar: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        appStateService: AppStateService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        activitiesFilterService: ActivitiesFilterService,
        customerDelineationService: CustomerDelineationService,
        employeeDelineationService: EmployeeDelineationService,
        productDelineationService: ProductDelineationService,
        projectDelineationService: ProjectDelineationService,
        filterService: FilterService,
        zrtFilterService: ProductDistributionReportZrtFilterService,
        overlayService: OverlayService
    ) {
        this.refinerService = refinerService;
        this.snackbar = snackbar;
        this.pleaseWaitService = pleaseWaitService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.activitiesFilterService = activitiesFilterService;
        this.customerDelineationService = customerDelineationService;
        this.employeeDelineationService = employeeDelineationService;
        this.productDelineationService = productDelineationService;
        this.projectDelineationService = projectDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;
        this.overlayService = overlayService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = appStateService.currentEmployee.subscribe(async (employee) => {
                this.employee = employee;
                await this.setFilterData();
                this.getBatch(0);
            });
        }
    }

    // events
    onButtonAction(value: any): void {
        switch (value) {
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onHeaderExpansionChange(): void {
        this.isGapSet = false;
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrBeforeDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "callOnOrBeforeDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrBeforeDate,
                    true,
                    false
                );
                this.dateForm.controls.endDate.setValue(null);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.callOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.callOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.callOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "callOnOrAfterDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.callOnOrAfterDate,
                    true,
                    false
                );
                this.dateForm.controls.startDate.setValue(null);
            }
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }


        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    // public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridHeightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridHeightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridHeightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridHeightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.refinerService.areDefaultsSet) {
            return;
        }

        const areRefinersTheSame = this.resolvePageIndex(index);

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.productDelineationService.getDistributionBatch(
                    id,
                    this.refinerService.refiners,
                    this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (!results) {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(false);
                    return;
                }

                this.total = results.count;
                const responseCountStore = results.counts.find(c => c.key === ResponseCountKey.customer);
                this.storeCount = responseCountStore?.count ?? 0;
                this.isGapSet = false;

                let gridData: GridData[] = this.gridData.slice();
                if (this.pageIndex === 0) {
                    gridData = new Array<GridData>();
                }
                const largestIndex = gridData.length;

                for (const item of results?.values ?? []) {
                    const gridItem: GridData = {
                        data: item,
                        detailArrayName: "",
                        isExpanded: false,
                        index: largestIndex + results.values.indexOf(item)
                    };
                    gridData.push(gridItem);
                }
                this.gridData = gridData;

                this.dataSource = new TableVirtualScrollDataSource(
                    this.gridData
                );
                this.shouldResetGrid = false;
                this.isInitialBatchCompleted = true;
            }, 0);
        }
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;

        this.columnDef = [
            {
                headerName: ProductDistributionColumns.productDescription,
                dataPropertyName: "description",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: ProductDistributionColumns.itemNumber,
                dataPropertyName: "itemNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: ProductDistributionColumns.inDistribution,
                dataPropertyName: "inDistribution",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: ProductDistributionColumns.intro,
                dataPropertyName: "intro",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: ProductDistributionColumns.oos,
                dataPropertyName: "oos",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: ProductDistributionColumns.cos,
                dataPropertyName: "cos",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: ProductDistributionColumns.distPercent,
                dataPropertyName: "distPercent",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.percentPlusTwoPlaces
            }
        ];

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        this.setSubscriptions();
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getBatch(this.pageIndex);
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }
        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    // private methods
    private checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.productDelineationService.getDistributionBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    private lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    private populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    private resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.callOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            default:
                break;
        }
    }

    private async setActivitiesFilter(): Promise<void> {
        let projects = new Array<GenericDropDownDto>();
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (projectResponse) {
            projects = projectResponse.values;
        }
        this.activitiesFilterService.projects = projects;

        let wholesalers = new Array<Customer>();
        const wholesalerResponse = await this.customerDelineationService.getWholesalersWithGroupProducts();
        if (wholesalerResponse) {
            wholesalers = wholesalerResponse.values;
            wholesalers = wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.activitiesFilterService.wholesalers = wholesalers;

        let chains = new Array<AccountOwnership>();
        const chainsResponse = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (chainsResponse && chainsResponse.values) {
            chains = chainsResponse.values;
            chains = chains.sort((a, b) => a.name < b.name ? -1 : 1);
        }
        this.activitiesFilterService.chains = chains;

        let products = new Array<GenericDropDownDto>();
        const allProductResponse = await this.productDelineationService.getAll();
        if (allProductResponse && allProductResponse.values) {
            products = allProductResponse.values.map(v => {
                const rtn = new GenericDropDownDto();

                rtn.id = v.id;
                rtn.name = v.description;
                rtn.displayValue = v.description;

                return rtn;
            });

            this.activitiesFilterService.products = products;

            const distinctDivision: string[] = [
                ...new Set(
                    allProductResponse.values.map((vm) => vm.division)
                )
            ];

            this.activitiesFilterService.productGroups = (distinctDivision ?? []).sort((a, b) => a?.localeCompare(b)).map(v => {
                const rtn = new GenericDropDownDto();
                rtn.id = rtn.name = rtn.displayValue = v;
                return rtn;
            });
        }
    }

    private setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();
            
            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee, true);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;
            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    private async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }


        this.setDefaultFilter();
        void this.setActivitiesFilter();
    }

    private setSubscriptions(): void {
        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (this.isInitialBatchCompleted || dc) {
                        this.shouldWait$.next(true)
                    }
                }
            );
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }
}

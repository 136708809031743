<ng-container>
    <div class="row">
        <div class="col-12">
            <app-page-header #header [name]="viewmodel?.headerName"
                [entryCount]="viewmodel?.total ? viewmodel.total : 0" [buttons]="viewmodel?.headerButtons"
                [refinerService]="viewmodel?.refinerService" [subAvailableColumns]="viewmodel?.availableColumns"
                (onClickButton)="viewmodel?.onButtonClickEvent($event)"
                (onButtonAction)="viewmodel?.onButtonAction($event)"
                (headerExpansionChanged)="viewmodel?.headerExpansionChanged()"
                (onColumnVisabilityMenuCloseEvent)="viewmodel?.setColumnVisibilityCommunicator($event)">
            </app-page-header>
        </div>
    </div>
    <div class="d-flex p-2 justify-content-center align-items-center">
        <div>
            <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom" title="View Filters"
                (click)="viewmodel?.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel?.refinerService.refiners.length }}
                </span>
            </button>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                <mat-label>Order on or After</mat-label>
                <input #startDateInput matInput formControlName="startDate" [max]="viewmodel?.startMaxDate"
                    [matDatepicker]="afterPicker" (keyup)="viewmodel?.onAddStartDateRefiner($event)"
                    (blur)="viewmodel?.onAddStartDateRefiner($event)"
                    (dateChange)="viewmodel?.onAddStartDateRefiner()" />
                <mat-datepicker touchUi #afterPicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel?.dateForm
                        .get('startDate')
                        .hasError('dateGreaterThanEndDate')
                ">
                Start date can not be greater than End date.
            </mat-error>
        </div>
        <div class="flex-grow-1">
            <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel?.dateForm">
                <mat-label>Order on or Before</mat-label>
                <input #endDateInput matInput formControlName="endDate" [min]="viewmodel?.endMinDate"
                    [max]="viewmodel?.endMaxDate" [matDatepicker]="beforePicker"
                    (keyup)="viewmodel?.onAddEndDateRefiner($event)"
                    (blur)="viewmodel?.onAddEndDateRefiner($event)"
                    (dateChange)="viewmodel?.onAddEndDateRefiner()"/>
                <mat-datepicker touchUi #beforePicker></mat-datepicker>
                <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="
                    viewmodel?.dateForm
                        .get('endDate')
                        .hasError('dateLessThanStartDate')
                ">
                End date can not be less than Start date.
            </mat-error>
        </div>
        <div class="flex-grow-1 d-flex">
            <angular2-multiselect #wholesalerSelect class="flex-grow-1 mr-2"
                [data]="viewmodel?.wholesalers"
                [(ngModel)]="viewmodel.selectedWholesalers"
                [settings]="viewmodel.wholeSalerDropdownSettings"
                (onDeSelectAll)="viewmodel.onClosedWholesalerSelect()"
                (onClose)="viewmodel.onClosedWholesalerSelect()"
                (onOpen)="viewmodel.onOpenedWholesalerSelect()">
            </angular2-multiselect>
        </div>
        <div class="d-flex">
            <button appThrottleClick mat-raised-button (throttledClick)="viewmodel?.search()" [disabled]="viewmodel.isSearchButtonDisabled"
                class="mr-2" color="primary">SEARCH</button>
            <button mat-raised-button (click)="viewmodel?.reset()" class="btn btn-secondary">RESET</button>
        </div>
    </div>
    <ng-container *ngIf="viewmodel.isEnabledOrderEdit">
        <div class="row">
            <div class="col-1"></div>
            <div class="col-3">
                <mat-form-field *ngIf="viewmodel.selectedWholesalers?.length === 1 && !this.viewmodel.isSearchStale"
                    appearance="outline" color="accent" class="w-100">
                    <mat-label>Order Edit</mat-label>
                    <mat-select #orderEditSelect [(value)]="viewmodel.selectedOrderEdit"
                        (openedChange)="onOrderEditSelectChange()">
                        <mat-option *ngFor="let orderEdit of viewmodel?.orderEditOptions" [value]="orderEdit">
                            {{ orderEdit }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-3">
                <mat-form-field *ngIf="viewmodel.selectedWholesalers?.length === 1
                    && !viewmodel.isSearchStale
                    && viewmodel.selectedOrderEdit !== viewmodel.noneOrderEditOption
                    && viewmodel.selectedOrderEdit !== viewmodel.changeWholesalerItemNumber" appearance="outline" color="accent" [formGroup]="viewmodel?.dateForm">
                    <mat-label>New Order Date</mat-label>
                    <input #orderDateInput matInput formControlName="orderDate" [min]="viewmodel?.orderDateMin" [max]="viewmodel?.endMaxDate"
                        [matDatepicker]="orderDatePicker"
                        (blur)="viewmodel.validateOrderDate()" />
                    <mat-datepicker touchUi #orderDatePicker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="orderDatePicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-error *ngIf="
                        viewmodel?.dateForm
                            .get('orderDate')
                            .hasError('NotInFuture')
                    ">
                    Order date must be in the future.
                </mat-error>
            </div>
            <div class="col-4">
                <button *ngIf="viewmodel.selectedOrderEdit
                    && viewmodel.selectedOrderEdit !== viewmodel.noneOrderEditOption
                    && viewmodel.selectedOrderEdit !== viewmodel.changeWholesalerItemNumber" mat-raised-button
                    color="primary" (click)="save()" [disabled]="viewmodel.isSaveDisabled">SAVE</button>
            </div>
            <div class="col-1"></div>
        </div>
        <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <app-order-edit-options #orderEditOptionsComponent
                    *ngIf="viewmodel.selectedOrderEdit !== viewmodel.noneOrderEditOption
                        && viewmodel.selectedOrderEdit !== viewmodel.changeWholesalerItemNumber"
                    [orderLineItems]="viewmodel.orderLineItems" [orderSelectables]="viewmodel.orderSelectables"
                    [orderDate]="viewmodel.orderDate" (rowSelectionChange)="rowSelectionChanged()">
                </app-order-edit-options>
                <app-change-wholesaler-uin #changeWholesalerUin
                    *ngIf="viewmodel.selectedOrderEdit === viewmodel.changeWholesalerItemNumber && viewmodel.selectedWholesaler"
                    [orderLineItems]="viewmodel.orderLineItems"
                    [wholesaler]="viewmodel.selectedWholesaler"
                    (wholesalerUinChange)="onOrderEditSelectChange()">
                </app-change-wholesaler-uin>
            </div>
        </div>
    </ng-container>
</ng-container>
<mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
    <mat-sidenav #drawer [mode]="viewmodel?.drawerMode" [fixedInViewport]="true"
        [fixedTopGap]="viewmodel?.topGapDistance" [fixedBottomGap]="0" class="sidenav">
        <app-filter-container [refinerService]="viewmodel?.refinerService" [filters]="viewmodel?.filters"
            [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
            (search)="viewmodel?.search()" (reset)="viewmodel?.reset()">
        </app-filter-container>
    </mat-sidenav>
    <mat-sidenav-content #sideNavContent>
        <div class="sidenav-content d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="table-responsive d-flex flex-column flex-grow-1">
                    <app-grid #grid [(dataSource)]="viewmodel.dataSource" [columnDefinitions]="viewmodel?.columnDef"
                        [detailTemplate]="detailTemplate" [searchRefiners]="viewmodel?.activeRefiners"
                        [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                        [isFixedLayout]="viewmodel?.isFixedLayout" [height]="viewmodel?.gridHeight"
                        [isEnabledExpandedDetails]="viewmodel?.isEnabledExpandedDetails"
                        [itemsRendedInViewPort]="viewmodel?.itemsRendedInViewPort"
                        [detailHeight]="viewmodel?.detailHeight" [sortFunction]="viewmodel.sortFunction"
                        (getDataBatch)="viewmodel?.getOrderBatch($event)"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
            </ng-container>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #detailTemplate let-data="data">
    <table *ngIf="data.length > 0" class="table-sm table-borderless">
        <thead class="detail-table-head">
            <tr class="fixed-width">
                <th class="detail-table-col h-center detail-sm"><u>UIN #</u></th>
                <th class="detail-table-col h-center detail-md"><u>UPC</u></th>
                <th class="detail-table-col h-center detail-lg"><u>Product Description</u></th>
                <th class="detail-table-col h-center detail-xs"><u>Quantity</u></th>
                <th class="detail-table-col h-center detail-sm"><u>Line Status</u></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of data" class="fixed-width">
                <td class="detail-table-col h-left detail-sm">{{row.uin}}</td>
                <td class="detail-table-col h-left detail-md">{{row.upc}}</td>
                <td class="detail-table-col h-left detail-lg">{{row.productDescription}}</td>
                <td class="detail-table-col h-right detail-xs">{{row.quantity}}</td>
                <td class="detail-table-col h-left detail-sm">{{row.orderLineItemStatus}}</td>
            </tr>
        </tbody>
    </table>
</ng-template>

// Angular
import { Directionality } from "@angular/cdk/bidi";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import {
    CdkStepper,
    StepperSelectionEvent,
    STEPPER_GLOBAL_OPTIONS
} from "@angular/cdk/stepper";

//Services
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { Subscription } from "rxjs";
import { ErrorLevel } from "../../../account-enums/error-level";
import { ValidationError } from "../../../account-models/validationError";
import { CallValidationService } from "../../../account-services/call-validation.service";
import { CallService } from "../../call-services/call.service";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { StepperCallApplicationService } from "../../stepper-call/stepper-call-services/stepper-call-application.service";

@Component({
    selector: "app-stepper",
    templateUrl: "./retail-call-stepper.component.html",
    styleUrls: ["./retail-call-stepper.component.scss"],
    providers: [
        {
            provide: CdkStepper,
            useExisting: RetailCallStepperComponent
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        }
    ]
})
export class RetailCallStepperComponent extends CdkStepper implements OnInit, OnDestroy {
    isLinear = false;
    farthestIndex = 0;
    mobile = false;

    // Public variables
    validationInvalid: ErrorLevel = ErrorLevel.invalid;
    isContentInitialized = false;
    callSubscription: Subscription;
    callValidation: ValidationError[] = [];
    selectedIndexSubscription: Subscription;
    isRendered = false;

    //Public variables
    constructor(
        dir: Directionality,
        changeDetectorRef: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        private callService: CallService,
        private stepperCallApplicationService: StepperCallApplicationService,
        private callValidationService: CallValidationService
    ) {
        super(dir, changeDetectorRef, elementRef);
    }

    // Public methods
    public handleSelectionChange(event: StepperSelectionEvent): void {
        this.selectedIndex = this.stepperCallApplicationService.selectedIndex =
            event.selectedIndex;

        if (this.selectedIndex > this.farthestIndex) {
            this.farthestIndex = this.stepperCallApplicationService.farthestIndex = this.selectedIndex;
        }
    }

    ngOnInit(): void {
        this.selectedIndex =
            (this.callService.call as RetailCall)?.lastIndexVisited ??
            this.stepperCallApplicationService.selectedIndex;

        this.mobile = window.screen.width < 524;

        window.addEventListener("resize", () => {
            this.mobile = window.screen.width < 524;
        });

        if (!this.callSubscription || this.callSubscription.closed) {
            this.callSubscription = this.callService.observableCall.subscribe(
                (call) => {
                    if (
                        this.farthestIndex < (call as RetailCall)?.farthestIndex
                    ) {
                        this.farthestIndex = (call as RetailCall)?.farthestIndex;
                    }

                    for (let i = 0; i <= RetailStepperStep.closingNotes; i++) {
                        this.isValid(i);
                    }
                }
            );
        }

        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.stepperCallApplicationService.observableSelectedIndex.subscribe(
                () => {
                    for (let i = 0; i <= RetailStepperStep.closingNotes; i++) {
                        this.isValid(i);
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.callSubscription && !this.callSubscription.closed) {
            this.callSubscription.unsubscribe();
        }

        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }
    }

    isValid(index: number): ValidationError {
        const results: ValidationError[] = this.callValidationService.isCallStepValid(
            index
        );
        const rtn: ValidationError = new ValidationError();

        const messageArray: string[] = results.filter(r => r.errorLevel === ErrorLevel.invalid).map(ma => ma.message);

        if (messageArray.length > 1) {
            rtn.message = messageArray.length.toString() + " Errors...";
            rtn.errorLevel = ErrorLevel.invalid;
        } else if (messageArray[0]?.length > 0) {
            rtn.message = messageArray[0];
            rtn.errorLevel = ErrorLevel.invalid;
        }

        rtn.hoverMessage = rtn.message;

        this.callValidation[index] = rtn;
        return rtn;
    }

    setSelectedIndex(index: number): void {
        this.selectedIndex = index;
    }
}

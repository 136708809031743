import { Component, ElementRef, ViewChild, signal } from "@angular/core";
import { RetailBaseComponent } from "../retail-format-base/retail-base.component";
import { Subsidiary } from "shield.shared";

@Component({
    selector: "app-eas-retail-format",
    templateUrl: "./eas-retail-format.component.html",
    styleUrls: ["./eas-retail-format.component.scss"]
})
export class EasRetailFormatComponent extends RetailBaseComponent {

    @ViewChild("originalEasRetailReceipt") originalEasRetailReceipt: ElementRef<HTMLElement>;
    printLogo = "/assets/img/easPrintLogo.jpg";
    subsidiary = signal(Subsidiary.EAS);
    constructor() {
        super();
    }
}

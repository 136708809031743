<ng-container>
    <form class="container-auto" [formGroup]="viewmodel.gratisRequestFormGroup">
        <div class="row d-flex align-items-center justify-content-between mt-3 mx-2">
            <h2 *ngIf="viewmodel.employee" class="mb-0">Gratis Request #{{viewmodel.gratisRequestNumber}}</h2>
            <div>
                <button appThrottleClick *ngIf="viewmodel.isAllowed && (!viewmodel.gratisEntity?.gratisStatusId
                        || viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusNotSubmitted
                        || viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusRejected)"
                    [disabled]="viewmodel.isSaveDisabled()"
                    class="btn btn-lg btn-primary mr-2" (throttledClick)="viewmodel.save()">
                    Save Gratis Request
                </button>
                <button appThrottleClick *ngIf="viewmodel.isAllowed && (viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusNotSubmitted || viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusRejected)"
                    class="btn btn-lg btn-green mr-2" [disabled]="viewmodel.isSubmitDisabled()"
                    (throttledClick)="viewmodel.validateSubmit()">
                    Submit Gratis Request
                </button>
                <button *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusNotSubmitted
                    || viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusRejected"
                    class="btn btn-lg btn-primary mr-2"
                    (click)="viewmodel.deleteGratisRequest()">
                    Delete
                </button>
                <button class="btn btn-lg btn-secondary" (click)="viewmodel.cancel()">
                    Cancel
                </button>
            </div>
        </div> 
        <div class="row mt-2">
            <div class="col-12 col-xl">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="d-flex flex-column flex-grow-1">Gratis Details</div>
                            <div *ngIf="viewmodel.isEditable">* Fields are required</div>
                        </div>
                    </div>
                    <div class="card-body bg-white container">
                        <div class="row">
                            <div class="col col-lg-6">
                                <div class="mb-3">
                                    <p class="mb-0">Created By</p>
                                    <b *ngIf="viewmodel.tmEmployee">ZRT&nbsp;{{viewmodel.gratisEntity?.createdUserZrt
                                            ? viewmodel.gratisEntity?.createdUserZrt
                                            : viewmodel.tmEmployee.zrt
                                        }} - {{viewmodel.tmEmployee.fullName}}</b>
                                </div>
                                <div class="mb-3">
                                    <p class="mb-0">Phone</p>
                                    <b *ngIf="viewmodel.phone">{{viewmodel.phone}}</b>
                                </div>
                                <div class="mb-3">
                                    <p class="mb-0">Purpose</p>
                                    <mat-form-field appearance="outline" color="accent" class="w-100">
                                        <textarea matInput type="text" formControlName="purposeControl"
                                            placeholder="Purpose *" maxlength="1000" required
                                            cdkTextareaAutosize cdkAutosizeMinRows="7" cdkAutosizeMaxRows="7"></textarea>
                                        <mat-hint align="end">{{
                                            viewmodel.purpose
                                            ?.length || 0
                                            }}/1000</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 container-auto">
                                <div class="row mb-3">
                                    <b class="col">Ship To</b>
                                </div>
                                <div class="row mb-3">
                                    <mat-form-field appearance="outline" color="accent" class="col">
                                        <mat-label>Address 1</mat-label>
                                        <input matInput placeholder="Address 1" maxlength="200"
                                            formControlName="address1Control" required>
                                    </mat-form-field>
                                </div>
                                <div class="row mb-3">
                                    <mat-form-field appearance="outline" color="accent" class="col">
                                        <mat-label>Address 2</mat-label>
                                        <input matInput placeholder="Address 2" maxlength="100"
                                            formControlName="address2Control">
                                    </mat-form-field>
                                </div>
                                <div class="row mb-3">
                                    <mat-form-field appearance="outline" color="accent" class="col-12">
                                        <mat-label>City</mat-label>
                                        <input matInput placeholder="City" maxlength="100" formControlName="cityControl"
                                            required>
                                    </mat-form-field>
                                </div>
                                <div class="row mb-3">
                                    <mat-form-field appearance="outline" color="accent" class="col-12 col-md-6">
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="selectedStateControl" required
                                            [compareWith]="viewmodel.compareStateOptions" [disabled]="!viewmodel.isEditable">
                                            <mat-option *ngFor="let vm of viewmodel.states" [value]="vm">
                                                {{vm.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" color="accent" class="col-12 col-md-6">
                                        <mat-label>Zip</mat-label>
                                        <input matInput placeholder="Zip" maxlength="9" formControlName="zipControl" required
                                            digitOnly>
                                    </mat-form-field>
                                </div>
                                <div class="row mb-3">
                                    <mat-form-field appearance="outline" color="accent" class="col">
                                        <mat-label>Need Gratis By</mat-label>
                                        <input #neededByDateInput matInput formControlName="neededByDateControl"
                                            [matDatepicker]="neededByDatePicker" />
                                        <mat-datepicker touchUi #neededByDatePicker></mat-datepicker>
                                        <mat-datepicker-toggle matSuffix [for]="neededByDatePicker"
                                            [disabled]="!viewmodel.isEditable"></mat-datepicker-toggle>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="viewmodel.gratisEntity?.gratisStatusId !== null
                    && viewmodel.gratisEntity?.gratisStatusId !== undefined"
                class="col-12 col-xl-6">
                <div class="card d-flex flex-column flex-grow-1">
                    <div class="card-header">
                        Approvals
                    </div>
                    <div *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusNotSubmitted && viewmodel.gratisEntity?.gratisApprovals?.length === 0"
                        class="card-body bg-white">
                        <p class="empty-state">Approval Information will appear here once your gratis request is submitted.
                        </p>
                    </div>
                    <div *ngIf="viewmodel.gratisEntity?.gratisStatusId !== viewmodel.gratisStatusNotSubmitted || viewmodel.gratisEntity?.gratisApprovals?.length !== 0"
                        class="card-body bg-white">
                        <div class="row justify-content-between mb-3">
                            <div class="col-3">
                                <div>
                                    <p>Requested Amount</p>
                                    <b>{{viewmodel.requestedAmount | currency}}</b>
                                </div>
                            </div>
                            <div class="col-3">
                                <div>
                                    <p>Shipped Amount</p>
                                    <b>{{viewmodel.shippedTotal | currency}}</b>
                                </div>
                            </div>
                            <div class="col-3">
                                <div *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete">
                                    <p>Order Number</p>
                                    <b>{{viewmodel.gratisEntity?.orderReferenceNumber}}</b>
                                </div>
                            </div>
                            <div class="col-3">
                                <div *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete">
                                    <p>Ordered By</p>
                                    <b>{{viewmodel.orderedByName}}</b>
                                </div>
                            </div>
                        </div>
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">ZRT</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Actions/Status</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">TM</th>
                                    <td>{{viewmodel.gratisEntity?.createdUserZrt}}</td>
                                    <td class="td-emphasis">
                                        {{viewmodel.tmEmployee?.fullName}}
                                    </td>
                                    <td class="td-emphasis">
                                        {{viewmodel.tmEmployeeApprovalStatus}}
                                    </td>
                                    <td>
                                        {{viewmodel.gratisEntity?.employeeApprovalStatusDate | date:
                                        viewmodel.jsDateFormat}}
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">RM</th>
                                    <td>{{viewmodel.rmEmployee?.zrt}}</td>
                                    <td class="td-emphasis">
                                        {{viewmodel.rmEmployee?.fullName}}
                                    </td>
                                    <td *ngIf="viewmodel.employee.id !== viewmodel.rmEmployee?.id ||
                                        viewmodel.rmEmployeeApprovalStatus !== viewmodel.gratisEmployeeApprovalAwaitingApproval"
                                        class="td-emphasis">{{viewmodel.rmEmployeeApprovalStatus}}</td>
                                    <td
                                        *ngIf="viewmodel.employee.id !== viewmodel.rmEmployee?.id ||
                                        viewmodel.rmEmployeeApprovalStatus !== viewmodel.gratisEmployeeApprovalAwaitingApproval">
                                        {{viewmodel.gratisEntity?.regionalEmployeeApprovalStatusDate | date:
                                        viewmodel.jsDateFormat}}
                                    </td>
                                    <td *ngIf="viewmodel.employee.id === viewmodel.rmEmployee?.id
                                        && viewmodel.rmEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval"
                                        class="td-emphasis">
                                        <button appThrottleClick (throttledClick)="viewmodel.approve()" class="btn btn-green mr-2">
                                            Approve
                                        </button>
                                        <button appThrottleClick (throttledClick)="viewmodel.openRejectionReason()" class="btn btn-primary">
                                            Reject
                                        </button>
                                    </td>
                                    <td
                                        *ngIf="viewmodel.employee.id === viewmodel.rmEmployee?.id
                                    && viewmodel.rmEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval">
                                    </td>
                                    <td>{{viewmodel.gratisEntity?.regionalEmployeeApprovalComment}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">ZM</th>
                                    <td>{{viewmodel.zmEmployee?.zrt}}</td>
                                    <td class="td-emphasis">
                                        {{viewmodel.zmEmployee?.fullName}}
                                    </td>
                                    <td *ngIf="viewmodel.employee.id !== viewmodel.zmEmployee?.id ||
                                        viewmodel.gratisEmployeeApprovalAwaitingApproval !== viewmodel.zmEmployeeApprovalStatus"
                                        class="td-emphasis">
                                        {{viewmodel.zmEmployeeApprovalStatus}}
                                    </td>
                                    <td
                                        *ngIf="viewmodel.employee.id !== viewmodel.zmEmployee?.id ||
                                        viewmodel.gratisEmployeeApprovalAwaitingApproval !== viewmodel.zmEmployeeApprovalStatus">
                                        {{viewmodel.gratisEntity?.zoneEmployeeApprovalStatusDate | date:
                                        viewmodel.jsDateFormat}}
                                    </td>
                                    <td *ngIf="viewmodel.employee.id === viewmodel.zmEmployee?.id
                                    && viewmodel.zmEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval"
                                        class="td-emphasis">
                                        <button appThrottleClick (throttledClick)="viewmodel.approve()" class="btn btn-green mr-2">
                                            Approve
                                        </button>
                                        <button appThrottleClick (throttledClick)="viewmodel.openRejectionReason()" class="btn btn-primary">
                                            Reject
                                        </button>
                                    </td>
                                    <td
                                        *ngIf="viewmodel.employee.id === viewmodel.zmEmployee?.id
                                    && viewmodel.zmEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval">
                                    </td>
                                    <td>{{viewmodel.gratisEntity?.zoneEmployeeApprovalComment}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Customer Service</th>
                                    <td colspan="2"></td>
                                    <td *ngIf="viewmodel.isEmployeeRoleCustomerService(viewmodel.employee)
                                        && viewmodel.csEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval"
                                        class="td-emphasis">
                                        <button (click)="viewmodel.order()" class="btn btn-green mr-2">
                                            Order
                                        </button>
                                        <button appThrottleClick (throttledClick)="viewmodel.openRejectionReason()" class="btn btn-primary">
                                            Reject
                                        </button>
                                    </td>
                                    <td *ngIf="viewmodel.isEmployeeRoleCustomerService(viewmodel.employee)
                                    && viewmodel.csEmployeeApprovalStatus === viewmodel.gratisEmployeeApprovalAwaitingApproval">
                                    </td>
                                    <td *ngIf="!viewmodel.isEmployeeRoleCustomerService(viewmodel.employee) ||
                                        viewmodel.gratisEmployeeApprovalAwaitingApproval !== viewmodel.csEmployeeApprovalStatus" class="td-emphasis">
                                        {{viewmodel.csEmployeeApprovalStatus}}
                                    </td>
                                    <td>
                                        {{viewmodel.gratisEntity?.customerServiceEmployeeApprovalStatusDate | date: viewmodel.jsDateFormat}}
                                    </td>
                                    <td>{{viewmodel.gratisEntity?.customerServiceEmployeeApprovalComment}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    <div class="row mt-2">
        <div *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete || (viewmodel.isEmployeeRoleCustomerService(viewmodel.employee) &&
            viewmodel.gratisEmployeeApprovalAwaitingApproval === viewmodel.csEmployeeApprovalStatus)"
            class="col-12 ">
            <div class="card d-flex flex-grow-1">
                <div class="card-header">
                    Products Shipped
                </div>
                <div class="card-body bg-white">
                    <div class="card-body bg-white no-v-scroll">
                        <form [formGroup]="viewmodel.shippedInfoFormGroup">
                            <div class="d-flex justify-content-between">
                                <div class="d-flex">
                                    <div class="mr-5">
                                        <p class="mb-0">Requested Amount</p>
                                        <b>{{viewmodel.requestedAmount | currency}}</b>
                                    </div>
                                    <div class="mr-5">
                                        <p class="mb-0">Shipped Amount</p>
                                        <b>{{viewmodel.shippedTotal | currency}}</b>
                                    </div>
                                    <div class="mr-5">
                                        <mat-form-field appearance="outline" color="accent" class="w-100">
                                            <mat-label>Order Number</mat-label>
                                            <input matInput maxlength="200"
                                                [disabled]="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete"
                                                formControlName="orderNumberControl" required>
                                            <mat-hint align="end">{{
                                                viewmodel.gratisEntity?.orderReferenceNumber
                                                ?.length || 0
                                                }}/200</mat-hint>
                                        </mat-form-field>
                                    </div>
                                    <div class="mr-5">
                                        <mat-form-field appearance="outline" color="accent" class="w-100">
                                            <mat-label>Order Date</mat-label>
                                            <input #OrderDateInput matInput formControlName="orderDateControl"
                                                [matDatepicker]="orderDatePicker" required
                                                [disabled]="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete" />
                                            <mat-datepicker touchUi #orderDatePicker></mat-datepicker>
                                            <mat-datepicker-toggle matSuffix [for]="orderDatePicker"
                                                [disabled]="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete">
                                            </mat-datepicker-toggle>
                                        </mat-form-field>
                                    </div>
                                    <div class="mr-5">
                                        <div
                                            *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete && viewmodel.orderedByName">
                                            <b>Ordered By - {{viewmodel.orderedByName}}
                                            </b>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button appThrottleClick type="submit"
                                        [disabled]="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusComplete || !viewmodel.isAllowed"
                                        (throttledClick)="viewmodel.addShippedProduct()" class="btn btn-primary btn-lg pull-right">
                                        <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Products
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="row">
                        <div class="col-1 h-center">
                        </div>
                        <div class="col-4">
                            <b class="mb-0">Product Description</b>
                        </div>
                        <div class="col h-center">
                            <b class="mb-0">Qty</b>
                        </div>
                        <div class="col-2 h-center">
                            <b class="mb-0">Units By</b>
                        </div>
                        <div class="col-2 h-center">
                            <b class="mb-0">Cost by Unit</b>
                        </div>
                        <div class="col h-center">
                            <b class="mb-0">Total Cost</b>
                        </div>
                    </div>
                        <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="600" maxBufferPx="800" class="viewport">
                            <div *cdkVirtualFor="let vm of viewmodel.shippedGratis let index = index"
                                [ngClass]="{'striped': 0 === index % 2, 'viewport-item-readonly': viewmodel.isProductDisabled()}"
                                minBufferPx="200" maxBufferPx="400" class="viewport-item">
                                <div *ngIf="viewmodel.shippedGratis.length > 0" class="row row-padding">
                                    <div class="col-1 h-center v-center">
                                        <ng-container *ngIf="!viewmodel.isProductDisabled()">
                                            <button class="btn btn-outline-danger btn-sm"
                                                (click)="viewmodel.removeShippedProduct(vm)" title="Remove Product">
                                                <span class="flaticon-delete"></span>
                                            </button>
                                        </ng-container>
                                    </div>
                                    <div class="col-4 v-center">
                                        {{vm.product.itemNumber}} - {{vm.product.description}}
                                    </div>
                                    <div class="col h-center v-center">
                                        <div class="input-group counter-control" *ngIf="!viewmodel.isProductDisabled(); else readonlyShippedQty">
                                            <div class="input-group-prepend">
                                                <button [disabled]="viewmodel.isProductDisabled()"
                                                    class="btn btn-outline-secondary btn-sm input-control" type="button"
                                                    (click)="viewmodel.incrementQuantity(vm)">
                                                    <span class="material-icons">add</span>
                                                </button>
                                            </div>
                                            <input
                                                [disabled]="viewmodel.isProductDisabled()"
                                                type="text" #gratisShippedProductQty
                                                (mouseup)="viewmodel.select(gratisShippedProductQty)"
                                                (blur)="viewmodel.validateQuantity(vm)"
                                                class="form-control form-control-sm mw-7rem-form input-control"
                                                mask="000000"
                                                [(ngModel)]="vm.quantity" [ngModelOptions]="{standalone: true}" />
                                            <div class="input-group-append">
                                                <button [disabled]="viewmodel.isProductDisabled()"
                                                    class="btn btn-outline-secondary btn-sm input-control" type="button"
                                                    (click)="viewmodel.decrementQuantity(vm)">
                                                    <span class="material-icons">remove</span>
                                                </button>
                                            </div>
                                        </div>
                                        <ng-template #readonlyShippedQty>
                                            {{ vm.quantity }}
                                        </ng-template>
                                    </div>
                                    <div class="col-2 h-center v-center">
                                        <mat-form-field class="w-100" appearance="outline" *ngIf="!viewmodel.isProductDisabled(); else readonlyShippedUom">
                                            <mat-label>Units By</mat-label>
                                            <mat-select [(value)]="vm.unitOfMeasure" required
                                                (selectionChange)="viewmodel.onSelectedShippedProductUOfMChange(vm)"
                                                [compareWith]="viewmodel.compareNamedStringOptions"
                                                [disabled]="viewmodel.isProductDisabled()">
                                                <mat-option [value]="value"
                                                    *ngFor="let value of vm.availableUnitOfMeasures">{{ value.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <ng-template #readonlyShippedUom>
                                            {{ vm.unitOfMeasure?.name }}
                                        </ng-template>
                                    </div>
                                    <div class="col-2 h-center v-center">
                                        <ng-container *ngIf="!viewmodel.isProductDisabled(); else readonlyShippedCbu">
                                            <form [formGroup]="vm.formGroup">
                                                <mat-form-field appearance="outline" color="accent">
                                                    <mat-label>Cost By Unit</mat-label>
                                                    <input formControlName="scbu" matInput #gratisShippedProductValue
                                                        (mouseup)="viewmodel.select(gratisShippedProductValue)" currencyMask required />
                                                </mat-form-field>
                                            </form>
                                        </ng-container>
                                        <ng-template #readonlyShippedCbu>
                                            {{ vm.costPerUnit | currency }}
                                        </ng-template>
                                    </div>
                                    <div class="col h-center v-center">
                                        <p class="mb-0">{{vm.costPerUnit * vm.quantity | currency}}</p>
                                    </div>
                                </div>
                            </div>
                        </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <div *ngIf="viewmodel.gratisEntity?.gratisStatusId !== null" class="card d-flex flex-grow-1">
                <div class="card-header">
                    Products Requested
                </div>
                <div class="card-body bg-white no-v-scroll">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <div class="d-flex">
                            <div class="mr-5">
                                <p class="mb-0" >Remaining Gratis</p>
                                <b [ngClass]="viewmodel.remainingGratisAmount < 0 ? 'text-danger' : ''">
                                    {{viewmodel.remainingGratisAmount | currency}}
                                </b>
                            </div>
                            <div>
                                <p class="mb-0">Requested Amount</p>
                                <b>{{viewmodel.requestedAmount | currency}}</b>
                            </div>
                        </div>
                        <div>
                            <button appThrottleClick type="submit" [disabled]="viewmodel.isProductDisabled()  || !viewmodel.isAllowed"
                                (throttledClick)="viewmodel.addRequestedProduct()" class="btn btn-primary btn-lg">
                                <fa-icon [icon]="faPlus" class="mr-1"></fa-icon>Add Products
                            </button>
                        </div>
                    </div>
                    <div *ngIf="viewmodel.remainingGratisAmount < 0" class="mb-2 text-danger">
                        Requested amount cannot exceed gratis available.
                    </div>
                    <div *ngIf="viewmodel.productsRequested.length > 0" class="row">
                        <div class="col-1 h-center">

                        </div>
                        <div class="col-4">
                            <b class="mb-0">Product Description</b>
                        </div>
                        <div class="col h-center">
                            <b class="mb-0">Qty</b>
                        </div>
                        <div class="col-2 h-center">
                            <b class="mb-0">Units By</b>
                        </div>
                        <div class="col-2 h-center">
                            <b class="mb-0">Cost by Unit</b>
                        </div>
                        <div class="col h-center">
                            <b class="mb-0">Total Cost</b>
                        </div>
                    </div>
                    <cdk-virtual-scroll-viewport #productsRequestedViewPort itemSize="84" minBufferPx="600"
                        maxBufferPx="800" class="viewport">
                        <div *cdkVirtualFor="let vm of viewmodel.productsRequested let index = index"
                            [ngClass]="{'striped': 0 === index % 2, 'viewport-item-readonly': viewmodel.isProductDisabled()}"
                            minBufferPx="200" maxBufferPx="400" class="viewport-item">
                            <div *ngIf="viewmodel.productsRequested.length > 0" class="row row-padding">
                                <div class="col-1 h-center v-center">
                                    <ng-container *ngIf="!viewmodel.isProductDisabled()">
                                        <button appThrottleClick class="btn btn-outline-danger btn-sm"
                                            (click)="viewmodel.removeRequestedProduct(vm)" title="Remove Product">
                                            <span class="flaticon-delete"></span>
                                        </button>
                                    </ng-container>
                                </div>
                                <div class="col-4 v-center">
                                    {{vm.product.itemNumber}} - {{vm.product.description}}
                                </div>
                                <div class="col h-center v-center">
                                    <div class="input-group counter-control" *ngIf="!viewmodel.isProductDisabled(); else readonlyRequestedQty">
                                        <div class="input-group-prepend">
                                            <button [disabled]="viewmodel.isProductDisabled()"
                                                class="btn btn-outline-secondary btn-sm input-control" type="button"
                                                (click)="viewmodel.incrementQuantity(vm)">
                                                <span class="material-icons">add</span>
                                            </button>
                                        </div>
                                        <input [disabled]="viewmodel.isProductDisabled()" type="text" #gratisProductQty
                                            (mouseup)="viewmodel.select(gratisProductQty)"
                                            (blur)="viewmodel.validateQuantity(vm)"
                                            class="form-control form-control-sm mw-7rem-form input-control" mask="000000"
                                            [(ngModel)]="vm.quantity"
                                            [ngModelOptions]="{standalone: true}" />
                                        <div class="input-group-append">
                                            <button [disabled]="viewmodel.isProductDisabled()"
                                                class="btn btn-outline-secondary btn-sm input-control" type="button"
                                                (click)="viewmodel.decrementQuantity(vm)">
                                                <span class="material-icons">remove</span>
                                            </button>
                                        </div>
                                    </div>
                                    <ng-template #readonlyRequestedQty>
                                        {{ vm.quantity }}
                                    </ng-template>
                                </div>
                                <div class="col-2 h-center v-center">
                                    <mat-form-field class="w-100" appearance="outline" *ngIf="!viewmodel.isProductDisabled(); else readonlyRequestedUom">
                                        <mat-label>Units By</mat-label>
                                        <mat-select [(value)]="vm.unitOfMeasure" required
                                            (selectionChange)="viewmodel.onSelectedRequestedProductUOfMChange(vm)"
                                            [compareWith]="viewmodel.compareNamedStringOptions"
                                            [disabled]="viewmodel.isProductDisabled()">
                                            <mat-option [value]="value" *ngFor="let value of vm.availableUnitOfMeasures">{{
                                                value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <ng-template #readonlyRequestedUom>
                                        {{ vm.unitOfMeasure?.name }}
                                    </ng-template>
                                </div>
                                <div class="col-2 h-center v-center">
                                    <ng-container *ngIf="!viewmodel.isProductDisabled(); else readonlyRequestedCbu">
                                        <form [formGroup]="vm.formGroup">
                                            <mat-form-field appearance="outline" color="accent">
                                                <mat-label>Cost By Unit</mat-label>
                                                <input formControlName="cbu" matInput #gratisProductValue
                                                    (mouseup)="viewmodel.select(gratisProductValue)" currencyMask required
                                                    (blur)="viewmodel.openRequestedPriceWarning(index)" />
                                            </mat-form-field>
                                        </form>
                                    </ng-container>
                                    <ng-template #readonlyRequestedCbu>
                                        {{ vm.costPerUnit | currency }}
                                    </ng-template>
                                </div>
                                <div class="col h-center v-center">
                                    <p class="mb-0">{{vm.costPerUnit * vm.quantity | currency}}</p>
                                </div>
                            </div>
                        </div>
                        <p *ngIf="viewmodel.productsRequested.length === 0 && viewmodel.isAllowed" class="empty-state">All Gratis requests must
                            have at
                            least one product. Click "Add Product" to add a product
                            to your gratis request.</p>
                    </cdk-virtual-scroll-viewport>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <div class="card d-flex flex-grow-1">
                <div class="card-header">
                    <div class="d-flex flex-grow-1 justify-content-between">
                        <div class="d-flex flex-column">
                            Invoices
                        </div>
                        <div class="d-flex flex-column">
                            Gratis Available: {{viewmodel.availableGratisAmount | currency}}
                        </div>
                        <div class="d-flex flex-column">
                            <fa-icon *ngIf="viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusNotSubmitted
                                || viewmodel.gratisEntity?.gratisStatusId === viewmodel.gratisStatusRejected
                                || !viewmodel.gratisEntity?.gratisStatusId"
                                [icon]="faPlus" appThrottleClick (throttledClick)="viewmodel.openManualGratis()" class="mr-2 pull-right"></fa-icon>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-white d-flex flex-grow-1">
                    <app-grid 
                        #grid 
                        [(renderedDataSource)]="viewmodel.availableGratisDataSource"
                        [columnDefinitions]="(viewmodel?.availableGratisColumnDefs ? 
                            viewmodel.availableGratisColumnDefs : 
                            []
                        )"
                        [detailTemplate]="detailTemplate"
                        [isFixedLayout]="false" 
                        [width]="viewmodel.availableGratisGridWidth" 
                        [isSortable]="false"
                        [isEnabledExpandedDetails]="true" 
                        [itemsRendedInViewPort]="5" 
                        [detailHeight]="48"
                        [isVirtualScroll]="false" 
                        class="invoices-grid d-flex flex-grow-1"
                    >
                    </app-grid>
                </div>
            </div>
        </div>
    </div>
    </form>

    <ng-template #detailTemplate let-data="data">
        <table *ngIf="data?.length > 0; else emptyMessage" class="table-sm table-borderless">
            <thead class="detail-table-head">
                <tr>
                    <th class="detail-table-col h-center"><u>UIN #</u></th>
                    <th class="detail-table-col h-center"><u>UPC</u></th>
                    <th class="detail-table-col h-center"><u>Product Description</u></th>
                    <th class="detail-table-col h-center"><u>Quantity</u></th>
                    <th class="detail-table-col h-center"><u>Eaches</u></th>
                    <th class="detail-table-col h-center"><u>Price</u></th>
                    <th class="detail-table-col h-center"><u>Sales Total</u></th>
                    <th class="detail-table-col h-center"><u>Discount</u></th>
                    <th class="detail-table-col h-center"><u>Net Total</u></th>
                    <th class="detail-table-col h-center"><u>Total Gratis</u></th>
                    <th class="detail-table-col h-center"><u>Transaction</u></th>
                    <th class="detail-table-col h-center"><u>Wholesaler</u></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data">
                    <td class="detail-table-col h-left">{{row.uin}}</td>
                    <td class="detail-table-col h-left">{{row.upc}}</td>
                    <td class="detail-table-col h-left">{{row.productDescription}}</td>
                    <td class="detail-table-col h-right">{{row.quantity}}</td>
                    <td class="detail-table-col h-right">{{row.eaches}}</td>
                    <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                        row.type === viewmodel.transactionTypes.ExchangeOut
                        ? row.wholesalePrice
                        : row.price) | currency}}</td>
                    <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                        row.type === viewmodel.transactionTypes.ExchangeOut
                        ? row.wholesaleTotal
                        : row.salesTotal) | currency}}</td>
                    <td class="detail-table-col h-right">{{row.discount | currency}}</td>
                    <td class="detail-table-col h-right">{{(row.type === viewmodel.transactionTypes.ExchangeIn ||
                        row.type === viewmodel.transactionTypes.ExchangeOut
                        ? row.wholesaleTotal
                        : row.netTotal) | currency}}</td>
                    <td class="detail-table-col h-right">{{row.gratisTotal | currency}}</td>
                    <td class="detail-table-col h-left">{{row.typeDescription}}</td>
                    <td>{{row.wholesalerName}}</td>
                </tr>
            </tbody>
        </table>
        <ng-template #emptyMessage>
            <div class="mt-1 mb-1"><em>This invoice doesn't have any additional transactions.</em></div>
        </ng-template>
    </ng-template>
</ng-container>

import { RouterModule, Routes, mapToCanActivate } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { NgModule } from "@angular/core";
import { CallHistoryComponent } from "./call-history/call-history.component";
import { ProductDistributionReportComponent } from "./product-distribution-report/product-distribution-report.component";
import { CallPicturesComponent } from "./call-pictures/call-pictures.component";
import { DailySummaryReportComponent } from "./daily-summary-report/daily-summary-report.component";
import { RetailContractsComponent } from "./retail-contracts/retail-contracts.component";
import { ProjectListComponent } from "./project-list/project-list.component";

export const routes: Routes = [
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/call-history",
        component: CallHistoryComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/call-pictures",
        component: CallPicturesComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/daily-summary",
        component: DailySummaryReportComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/product-distribution",
        component: ProductDistributionReportComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/retail-contracts",
        component: RetailContractsComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "details/project-list",
        component: ProjectListComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class DetailsRoutingModule {}

<div
    class="order-details-modal"
    [style]="{
        height: height?.length > 0 ? height : 'auto',
        width: width?.length > 0 ? width : 'auto'
    }"
    cdkDrag
    cdkDragBoundary=".cdk-overlay-container"
>
    <div cdkDragHandle>
        <div class="modal-header d-flex justify-content-between">
            <div class="">
                Order Details
            </div>
            <div *ngIf="data.lastUpdatedBy && data.lastUpdated">
                Last Updated by {{ data.lastUpdatedBy }} on {{ data.dateUpdatedFormatted }}
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex justify-content-between align-items-start pb-4">
            <div>
                <p class="mb-1">
                Call Date: <span class="font-weight-bold">{{ data.dateCreatedFormatted }}</span>
            </p>
            <p class="mb-1"> Call made by: {{ data.createdBy }}</p> <!-- need ZRT -->
            </div>
            <div>
                <div class="pull-right">
                    Reprint/Send Original Receipt:
                    <button appThrottleClick *ngIf="receipts?.length > 0|| receiptsEmailed" class="btn btn-sm btn-white-wide actionBtn mr-1"
                        (throttledClick)="reprint()"
                        title="Print Receipt">
                        <span class="flaticon-printer larger green"></span>
                    </button>
                    <button appThrottleClick *ngIf="receipts?.length > 0|| receiptsEmailed" class="btn btn-sm btn-white-wide actionBtn"
                        (throttledClick)="email()"
                        title="Send Receipt">
                        <span class="flaticon-send larger green"></span>
                    </button>
                    <div *ngIf="!receipts?.length" class="red">
                        Original Receipt Not Found
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center pb-3">
            <p class="mb-1 font-weight-bold">{{ data.customerName }} - Receipt # 123456</p> <!-- need receipt number -->
            <p class="mb-1">Swisher License # {{ data.swisherLicenseNumber }}</p>
            <p class="mb-1">Retail License # {{ retailStateOptLicense }}</p>
            <p class="mb-1">
                {{ data.customerAddress1 }},
                {{ data.customerCity }},
                {{ data.customerState }}
                {{ data.customerZip }}

            </p>
            <p class="mb-1">{{ data.customerCounty ? data.customerCounty + " County" : "" }}</p>
        </div>
       <table class="table table-striped text-center">
        <thead>
            <tr>
                <th>UIN</th>
                <th>Product Description</th>
                <th>Quantity</th>
                <th>Order Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data.orderLineItems">
                <td>{{ item.uin }}</td>
                <td>{{ item.productDescription }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ data.orderDate }}</td>
            </tr>
        </tbody>
       </table>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div class="p-0">
            <ng-container *ngIf="isCustomerServiceOrAdmin">
                <div *ngIf="data.orderStatusId === pendingOrderStatus">
                    <button appThrottleClick
                    mat-raised-button
                    class="btn-white actionBtn btn btn-secondary"
                    (throttledClick)="cancelSingleOrderWarning()"
                >
                    Cancel This Order
                </button>
                    <button appThrottleClick
                    mat-raised-button
                    class="btn-white actionBtn btn btn-secondary"
                    (throttledClick)="cancelCallOrdersWarning()"
                >
                    Cancel All Pending Orders On Call
                </button>
                </div>
            </ng-container>
        </div>
        <div class="p-0">
            <button mat-raised-button
                class="sw-red-btn actionBtn"
                color="primary"
                (click)="close()"
            >
                Close
            </button>
        </div>
    </div>
</div>

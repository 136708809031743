import { EventEmitter } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors } from "@angular/forms";
import cloneDeep from "lodash-es/cloneDeep";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";
import { AddProductViewModel } from "src/app/products/add-products/add-product.viewmodel";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";

export abstract class WholesalerProductsListViewModel
    <T extends WholesalerGroupProductCatalogItem | WholesalerProductCatalogItems>
    extends BasicDialogViewModel
{
    save$ = new EventEmitter();
    afterSave$ = new EventEmitter();
    changed = false;
    originalItems: T[];

    abstract errorMessage: string;

    constructor(
        public canEdit: boolean,
        public group: WholesalerGroupEntry,
        public items: T[],
    ) {
        super();

        this.originalItems = cloneDeep(items);

        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;

        if (this.canEdit) {
            this.buttonRightText = "Save";
            this.buttonRightFunction = () => this.save$.emit();
        }
    }

    abstract getGroupItem(item: T): WholesalerGroupProductCatalogItem;

    abstract createFormRow(
        form: FormGroup,
        builder: FormBuilder,
        item: T,
        isNew?: boolean,
    ): FormGroup;

    abstract addProducts(selected: AddProductViewModel[], form: FormGroup, builder: FormBuilder)
        : void;

    abstract updateItems(form: FormGroup): WholesalerGroupProductCatalogItem[];

    abstract exportToXlsx(catalogItemService: WholesalerGroupProductCatalogItemDelineationService)
        : Promise<Blob>;

    createFormRows(form: FormGroup, builder: FormBuilder): FormArray {
        return builder.array(this.items.map(i => this.createFormRow(form, builder, i)));
    }

    getUpc(upcs: ProductUpc[], key: string): string {
        return upcs.find((v) => v.uom === key)?.upc ?? "N/A";
    }

    validateUnique(form: FormGroup, ctrl: AbstractControl): ValidationErrors {
        if (!form || !ctrl.parent) {
            return null;
        }
        const toUpdateId = ctrl.parent.value.id;
        const isUnique = form.value.items
            .filter((i: any) => i.id != toUpdateId && i.productUIN == ctrl.value)
            .length == 0;

        if (!isUnique) {
            return {
                notUnique: ctrl.value,
            };
        } else {
            return null;
        }
    }


}

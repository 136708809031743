<div class="d-flex h-100">
    <div class="card card-project-panel m-0 d-flex flex-column flex-grow-1">
        <div class="card-header">Activities / Surveys</div>
        <div class="card-body un-scrollable d-flex flex-column flex-grow-1">
            <ng-container>
                <div class="row d-flex flex-column flex-grow-1">
                    <div *ngIf="viewmodel.checkingProjectProperties || viewmodel.projectActivitiesSurveys?.length !== 0"
                        class="col-12 table-responsive d-flex flex-column flex-grow-1">
                        <app-grid #grid
                            [(dataSource)]="viewmodel.dataSource"
                            [columnDefinitions]="viewmodel?.columnDef"
                            [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                            [isFixedLayout]="false" [height]="'30vh'"
                            [isEnabledExpandedDetails]="false"
                            [itemsRendedInViewPort]="6"
                            [detailHeight]="48"
                            class="d-flex flex-column flex-grow-1">
                        </app-grid>
                    </div>
                    <div *ngIf="!viewmodel.projectActivitiesSurveys?.length" class="margin-auto">
                        <b class="missing-data-warning">Project activities/surveys will not be visible until they have been associated.</b>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

import { NgModule } from "@angular/core";
import { RouterModule, Routes, mapToCanActivate, mapToCanDeactivate } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ProjectConfigurationComponent } from "./project-configuration/project-configuration.component";
import { ProjectMetricsComponent } from "./project-metrics/project-metrics.component";
import { ProjectSummaryComponent } from "./project-configuration/project-summary/project-summary.component";
import { ProjectComponent } from "./project.component";
import { CanDeactivateGuard } from "src/app/guards/can-deactivate.guard";
import { CanActivateProjectCreationGuard } from "src/app/guards/can-activate-project-creation.guard";

const routes: Routes = [
    {
        path: "details/project/:projectId",
        canActivate: mapToCanActivate([MsalGuard]),
        component: ProjectComponent,
        children: [
            {
                canActivate: mapToCanActivate([MsalGuard]),
                path: "summary",
                component: ProjectSummaryComponent
            },
            {
                canActivate: mapToCanActivate([MsalGuard]),
                path: "metrics",
                component: ProjectMetricsComponent
            },
            {
                canActivate: mapToCanActivate([MsalGuard, CanActivateProjectCreationGuard]),
                canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
                path: "configuration",
                component: ProjectConfigurationComponent
            },
            {
                canActivate: mapToCanActivate([MsalGuard]),
                canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
                path: "configuration/wholesaler/:wholesalerId",
                component: ProjectConfigurationComponent
            },
            {
                canActivate: mapToCanActivate([MsalGuard]),
                canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
                path: "configuration/chain-hq/:chainHqCode",
                component: ProjectConfigurationComponent
            },
            {
                canActivate: mapToCanActivate([MsalGuard]),
                canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
                path: "configuration/retail/:retailId",
                component: ProjectConfigurationComponent
            }
         ]
    },
    {
        path: "details/project/:projectId/wholesaler/:wholesalerId",
        canActivate: mapToCanActivate([MsalGuard]),
        component: ProjectComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    },
    {
        path: "details/project/:projectId/chain-hq/:chainHqCode",
        canActivate: mapToCanActivate([MsalGuard]),
        component: ProjectComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
    },
    {
        path: "details/project/:projectId/retail/:retailId",
        canActivate: mapToCanActivate([MsalGuard]),
        component: ProjectComponent,
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard])
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectRoutingModule {}

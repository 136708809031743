<div class="control-box">
    <div class="row">
        <div class="col margin-bottom"><u class="title-offset"><b>Change Wholesale Item Number (UIN)</b></u></div>
    </div>
    <div class="row">
        <div class="col-1"></div>
        <div class="col-3">
            <div class="flex-grow-1 d-flex">
                <angular2-multiselect class="flex-grow-1"
                    [data]="oldUinViewmodels"
                    [(ngModel)]="selectedUin"
                    [settings]="oldUinSettings"
                    (onDeSelectAll)="buildOldUinViewmodels()"
                    (onClose)="buildOldUinViewmodels()"
                    (onOpen)="buildOldUinViewmodels()">
                </angular2-multiselect>
            </div>
        </div>
        <div class="col-6">
            <div class="flex-grow-1 d-flex">
                <angular2-multiselect class="flex-grow-1 mr-2"
                    [data]="wholesalerOrderCatalog"
                    [(ngModel)]="selectedNewUin"
                    [settings]="newUinSettings">
                </angular2-multiselect>
            </div>
        </div>
        <div class="col-2 margin-auto">
            <button mat-raised-button
            color="primary" appThrottleClick (throttledClick)="openSaveConfirmation()" [disabled]="selectedUin?.length !== 1 || selectedNewUin?.length !== 1">SAVE</button>
        </div>
    </div>
</div>


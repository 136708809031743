import { FilterSortDto, GenericResponseDto, OrderListColumns } from "shield.shared";
import { Order } from "src/app/entity-models/order.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { DirectOrderDetailsComponent } from "../direct-order-details/direct-order-details.component";
import { DirectOrderDetailsViewModel } from "../direct-order-details/direct-order-details.viewmodel";
import { OrderListGridViewmodel } from "./order-list-grid.viewmodel";
import { OrderListViewmodel } from "./order-list.viewmodel";

export class OrderListDirectViewModel extends OrderListViewmodel {

    columnDef = [
        {
            headerName: OrderListColumns.orderDate,
            dataPropertyName: "orderDate",
            isAvailable: true,
            isSelected: true,
            clickFunction: this.openDirectOrderDetails,
        },
        {
            headerName: OrderListColumns.orderStatus,
            dataPropertyName: "orderStatus",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.wholesalerCode,
            dataPropertyName: "retailNumber",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.wholesaler,
            dataPropertyName: "customerName",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: "Orderlineitems",
            dataPropertyName: "orderLineItems",
            isAvailable: false,
            isSelected: true
        }
    ];

    get openDirectOrderDetails(): (event: MouseEvent, index: number) => void {
        return async (_, index) => {
            const row = this.gridData[index].data as OrderListGridViewmodel;
            try {
                const details = await this.pleaseWaitService.withSpinnerShowing(async () =>
                    await this.orderDelineationService.getDirectOrderDetails(row.orderNumber),
                    "Retrieving Data (this may take some time)"
                );

                if (!details) {
                    this.dialogService.showPromptDialog(
                        `Order ${row.orderNumber} does not exist in the Netsuite system.`,
                        "Not found",
                    );
                } else {
                    this.overlayService.open(
                        DirectOrderDetailsComponent,
                        new DirectOrderDetailsViewModel(details),
                        true,
                    );
                }
            } catch (e) {
                this.snackbar.showError(
                    `Something went wrong when attempting to get the order details. 
                    Please try again later.`
                );
                throw e;
            }
        }
    }

    protected getBatchApiCall(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<OrderListColumns>[]
    ): Promise<GenericResponseDto<Order[]>> {
        return this.orderDelineationService.getDirectBatch(
            id,
            refiners,
            pageSize,
            startIndex,
            filterSorts,
        );
    }

}
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import moment from "moment";
import { Subscription } from "rxjs";
import { ContractTemplateTypes } from "shield.shared";
import SignaturePad from "signature_pad";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ContractTemplate } from "src/app/entity-models/contract-template.entity";
import { Helper } from "src/app/helpers/helper";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

export class NewAgreementViewModel extends BasicDialogViewModel {
    template: ContractTemplate;
    templateTypes = ContractTemplateTypes;
    isConfirmed = false;

    level: number;
    date: Date;
    minDate = new Date();
    contractDateFormatted: string;
    contact: ContactViewModel;
    contacts: ContactViewModel[];

    signaturePad: SignaturePad;
    canvasWidth = 750;
    canvasHeight = 150;

    fb: UntypedFormBuilder
    agreementFormGroup: UntypedFormGroup;
    private levelSubscription: Subscription;
    private dateSubscription: Subscription;
    private contactSubscription: Subscription;

    constructor(template: ContractTemplate) {
        super();
        this.template = template;

        this.headerLeftText = "New Agreement";
        this.buttonLeftText = "Close";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightDisabledFunction = () => this.isSaveDisabled();
        this.buttonRightText = "Save";

        this.fb = new UntypedFormBuilder;
        this.agreementFormGroup = this.fb.group({
            levelControl: new UntypedFormControl("", Validators.required),
            dateControl: new UntypedFormControl("", Validators.required),
            contactControl: new UntypedFormControl("", Validators.required)
        });
        this.levelSubscription = this.agreementFormGroup.controls["levelControl"].valueChanges.subscribe((value) => {
            this.level = value;
        });
        this.dateSubscription = this.agreementFormGroup.controls['dateControl'].valueChanges.subscribe((value) => {
            this.date = new Date(value);
            this.contractDateFormatted = moment(value).format(MY_DATE_FORMATS.display.monthDayYearInput)
        });
        this.contactSubscription = this.agreementFormGroup.controls['contactControl'].valueChanges.subscribe((value) => {
            this.contact = value;
        })
    }

    unsubscribe(): void {
        if (this.levelSubscription && !this.levelSubscription.closed) {
            this.levelSubscription.unsubscribe();
        }
        if (this.dateSubscription && !this.dateSubscription.closed) {
            this.dateSubscription.unsubscribe();
        }
        if (this.contactSubscription && !this.contactSubscription.closed) {
            this.contactSubscription.unsubscribe();
        }
    }

    isSaveDisabled(): boolean {
        return (this.agreementFormGroup.controls["levelControl"].hasError("required") && this.agreementFormGroup.controls["levelControl"].touched)
            || (this.agreementFormGroup.controls["dateControl"].hasError("required") && this.agreementFormGroup.controls["dateControl"].touched)
            || (this.agreementFormGroup.controls["contactControl"].hasError("required") && this.agreementFormGroup.controls["contactControl"].touched)
            || this.isSignatureInvalid();
    }

    onClearSignature(): void {
        this.signaturePad.clear();
    }

    isSignatureInvalid(): boolean {
        return this.signaturePad.isEmpty() && this.signaturePad.penColor === "black";
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }


}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    CallBatchParamsDto, CallCounts, CallDto, CallHistoryColumns, CallHistoryEntryDto, CallHistoryFilterDto, CallHistoryParamsDto, CallTypes, FilterRequestDto,
    FilterRequestV2Dto,
    GenericRequestDto, GenericResponseDto, newSequentialId, RefinerDto, RefinerGroupDto,
    RefinerLocation, SortDirection, SortDto
} from "shield.shared";
import { Call } from "../../accounts/call-master/call-services/call.service";
import { CallHistoryEntry } from "../../entity-models/call-history-entry.entity";
import { Refiner } from "../../entity-models/refiner.entity";
import { CallConverterService } from "../converter-services/call-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";

@Injectable()
export class CallOnlineService {
    constructor(private http: HttpClient) { }

    async getCallCountsForCustomerIds(
        customerIds: string[],
        startDate: Date,
        endDate: Date
    ) : Promise<Map<string, number>> {
        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = customerIds;

        const response = await this.http
            .post<GenericResponseDto<CallCounts>>(
                `/api/calls/counts-by-customer-ids?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                request,
            )
            .toPromise();

        return new Map(Object.entries(response.values));
    }

    async getCallById(id: string): Promise<GenericResponseDto<Call>> {

        const response = await this.http
            .get<GenericResponseDto<CallDto>>(`/api/calls/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<CallDto, Call>(response, (r) => CallConverterService.callDtoToCall(r));
    }

    async getCallByIds(ids: string[]): Promise<GenericResponseDto<Call[]>> {

        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = ids;

        const response = await this.http
            .post<GenericResponseDto<CallDto[]>>(`/api/calls/get-by-ids`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CallDto, Call>(response, (r) => CallConverterService.callDtoToCall(r));
    }


    async getCallsByCustomerId(params: CallBatchParamsDto): Promise<GenericResponseDto<Call[]>> {

        const request = new GenericRequestDto();
        request.id = params.customerId;
        request.values = params.filterRequestDto;
        const result = await this.http
            .post<GenericResponseDto<CallDto[]>>(`/api/calls`, request).toPromise();

        return ConverterHelper.dtoToEntityArray<CallDto, Call>(result, (r) => CallConverterService.callDtoToCall(r));
    }

    async getCallHistory(params: CallHistoryParamsDto): Promise<GenericResponseDto<CallHistoryEntry[]>> {
        const request = new GenericRequestDto<FilterRequestV2Dto<CallHistoryFilterDto, CallHistoryColumns>>();
        request.id = params.employeeId;
        request.values = params.filterRequestDto;
        const result = await this.http
            .post<GenericResponseDto<CallHistoryEntryDto[]>>(`/api/calls/history`, request).toPromise();

        return ConverterHelper.dtoToEntityArray<CallHistoryEntryDto, CallHistoryEntry>(result, (r) => CallConverterService.callHistoryEntryDtoToCallHistoryEntry(r));
    }

    async getLastCallOfTypeByCustomerId(id: string, callType: CallTypes): Promise<GenericResponseDto<Call | undefined>> {
        const response = await this.http
            .get<GenericResponseDto<CallDto | undefined>>(`/api/customers/${id}/get-last-call-of-type/${callType}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<CallDto | undefined, Call | undefined>(response, (r) => CallConverterService.callDtoToCall(r));
    }

    async getLastCallWithOrdersByCustomerId(id: string, callType: CallTypes): Promise<GenericResponseDto<Call | undefined>> {
        const params = new HttpParams().set("with-orders", "true");
        const response = await this.http
            .get<GenericResponseDto<CallDto | undefined>>(`/api/customers/${id}/get-last-call-of-type/${callType}`, { params: params })
            .toPromise();

        return ConverterHelper.dtoToEntity<CallDto | undefined, Call | undefined>(response, (r) => CallConverterService.callDtoToCall(r));
    }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { SharedModule } from "../shared/shared.module";
import { UserRoutingModule } from "./user.routing.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChip, MatChipsModule } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

@NgModule({
    declarations: [
        UserProfileComponent
    ],
    imports: [
        CommonModule,
        UserRoutingModule,
        SharedModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatSelectModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatExpansionModule,
        MatButtonModule,
        MatCheckboxModule,
        MatSelectModule,
        MatChipsModule,
        MatFormFieldModule,
        FontAwesomeModule,
        FormsModule,
        MatMenuModule,
        MatAutocompleteModule,
        NgxMaskPipe,
        NgxMaskDirective
    ],
    providers: [
        MatChip,
        provideNgxMask()
    ]
})
export class UserModule {}

import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Helper } from "src/app/helpers/helper";

export abstract class CustomerProfileViewModel {

    protected getDisplayZrtFromCustomer(customer: Customer, employee: Employee): string {
        const displayZrt = customer.customerZrts.find(cz => cz.subsidiaryId === employee.subsidiaryId);
        return Helper.formatDisplayZrt((displayZrt ?? customer.customerZrts[0]).zrt);
    }
}

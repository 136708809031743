import { ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation, computed, input, viewChild } from "@angular/core";
import { WholesalerBaseComponent } from "../wholesaler-format-base/wholesaler-base.component";
import { Subsidiary } from "shield.shared";

@Component({
  selector: "app-wholesaler-format-draft",
  templateUrl: "./wholesaler-format-draft.component.html",
  styleUrls: ["./wholesaler-format-draft.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerFormatDraftComponent extends WholesalerBaseComponent {
    wholesaleDraftReceipt = viewChild<ElementRef<HTMLElement>>("wholesaleDraftReceipt");
    isEasEmployee = computed(() => {
        const employee = this.employee();
        return employee && employee.subsidiaryId === Subsidiary.EAS;
    });
}

// Angular
import { Directionality } from "@angular/cdk/bidi";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import {
    CdkStepper,
    StepperSelectionEvent,
    STEPPER_GLOBAL_OPTIONS
} from "@angular/cdk/stepper";

//Services
import { Subscription } from "rxjs";
import { ProjectApplicationService } from "../project-services/project-application.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ProjectStep } from "src/app/enums/project-step";
import { ValidationError } from "src/app/accounts/account-models/validationError";

@Component({
    selector: "app-project-stepper",
    templateUrl: "./project-stepper.component.html",
    styleUrls: ["./project-stepper.component.scss"],
    providers: [
        {
            provide: CdkStepper,
            useExisting: ProjectStepperComponent
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        }
    ]
})
export class ProjectStepperComponent extends CdkStepper implements OnInit, OnDestroy {
    isLinear = false;
    mobile = false;

    // Public variables
    isContentInitialized = false;
    projectSubscription: Subscription;
    selectedIndexSubscription: Subscription;
    isRendered = false;

    //Public variables
    constructor(
        dir: Directionality,
        changeDetectorRef: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        private projectApplicationService: ProjectApplicationService,
        private projectStateService: ProjectStateService
    ) {
        super(dir, changeDetectorRef, elementRef);
    }

    // Public methods
    public handleSelectionChange(event: StepperSelectionEvent): void {
        this.selectedIndex = this.projectApplicationService.selectedIndex =
            event.selectedIndex;
    }

    ngOnInit(): void {
        this.selectedIndex = 0
        this.projectApplicationService.selectedIndex = this.selectedIndex;

        this.mobile = window.screen.width < 524;

        window.addEventListener("resize", () => {
            this.mobile = window.screen.width < 524;
        });

        if (
            !this.selectedIndexSubscription ||
            this.selectedIndexSubscription.closed
        ) {
            this.projectApplicationService.observableSelectedIndex.subscribe(
                () => {
                    for (let i = 0; i <= ProjectStep.summary; i++) {
                        this.isValid(i);
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }

        if (
            this.selectedIndexSubscription &&
            !this.selectedIndexSubscription.closed
        ) {
            this.selectedIndexSubscription.unsubscribe();
        }
    }

    isValid(index: number): ValidationError {
        // Add call to Project validation
        const rtn: ValidationError = new ValidationError();

        return rtn;
    }

    setSelectedIndex(index: number): void {
        this.selectedIndex = index;
    }
}

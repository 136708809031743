import { HttpClient } from "@angular/common/http";
import { Injectable, inject, computed, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { map, Subject, switchMap, merge, of, shareReplay, tap, BehaviorSubject, catchError } from "rxjs";
import { MissingReceiptPayload, SaveMissingReceiptPayload } from "shield.shared";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { Helper } from "src/app/helpers/helper";
import { AppStateService } from "src/app/services/app-state.service";
import { CallConverterService } from "src/app/services/converter-services/call-converter.service";
import { ContactConverterService } from "src/app/services/converter-services/contact-converter.service";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { EmployeeConverterService } from "src/app/services/converter-services/employee-converter.service";
import { SnackbarService } from "src/app/services/snackbar.service";



@Injectable({
    providedIn: 'root'
})
export class MissingReceiptsService {
    private httpClient = inject(HttpClient);
    private appState = inject(AppStateService);
    private snackbar = inject(SnackbarService);
    private isCurrentEmployeeAdmin$ = this.appState.currentEmployee.pipe(
        map(employee => Helper.isEmployeeCustomerServiceOrAdmin(employee))
    );
    private getMissingReceipts = of(undefined).pipe(
        tap(_ => this.nextReceiptLoading$.next(true)),
        switchMap(_ => this.httpClient.get<MissingReceiptPayload>('/api/missing-receipts').pipe(
            catchError(err => {
                this.snackbar.showError(err.error.message);
                this.errorState.set(err.error.message);
                return of(undefined)
            })
        )),
        tap(_ => this.nextReceiptLoading$.next(false))        
    );
    private nextReceiptLoading$ = new BehaviorSubject<boolean>(false);
    saveReceipt = new Subject<SaveMissingReceiptPayload>();
    private receiptSaved = this.saveReceipt.pipe(
        tap(_ => this.nextReceiptLoading$.next(true)),
        switchMap(payload => this.httpClient.post<MissingReceiptPayload>('/api/missing-receipts', payload).pipe(
            catchError(err => {
                this.snackbar.showError(err.error.message);
                this.errorState.set(err.error.message);
                return of(undefined)
            })
        )),
        tap(_ => this.nextReceiptLoading$.next(false)),
    );
    
    private missingReceipt = toSignal(
        this.isCurrentEmployeeAdmin$.pipe(
            switchMap(isAdmin => {
                return isAdmin ? merge(this.getMissingReceipts, this.receiptSaved) : of(null)
            })
        )
    );
    private errorState = signal<string>(undefined);
    error = this.errorState.asReadonly();
    nextReceiptLoading = toSignal(this.nextReceiptLoading$);
    count = computed(() => this.missingReceipt()?.count ?? 0);
    customer = computed(() => 
        this.missingReceipt() && this.missingReceipt().customer ? 
        CustomerConverterService.customerDtoToCustomer(this.missingReceipt().customer) : undefined
    );
    wholesaler = computed(() => 
        this.missingReceipt() && this.missingReceipt().wholesaler ? 
        CustomerConverterService.customerDtoToCustomer(this.missingReceipt().wholesaler) : undefined
    );
    call = computed(() => 
        this.missingReceipt() && this.missingReceipt().call ?
        CallConverterService.callDtoToCall(this.missingReceipt().call) as RetailCall | RmWholesaleCall : undefined
    );
    employee = computed(() => 
        this.missingReceipt() && this.missingReceipt().employee ?
        EmployeeConverterService.employeeDtoToEmployee(this.missingReceipt().employee) : undefined
    );
    contact = computed(() => 
        this.missingReceipt() && this.missingReceipt().contact ?
        ContactConverterService.contactDtoToContact(this.missingReceipt().contact) : undefined
    );
    receiptNumberExtention = computed(() => this.missingReceipt()?.receiptNumberExtention);
    hasMissingReceipt = computed(() => this.count() > 0);
}


import moment from "moment";
import { DailySummaryEntry } from "src/app/entity-models/daily-summary-entry.entity";
import { Helper } from "src/app/helpers/helper";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

export class DailySummaryReportGridItemViewModel {
    dayTimeEntryId: string;
    zrt: string;
    name: string;
    date: string;
    callBreakdown: string;
    endOfDayComments: string;
    workWithAndDuration: string;

    constructor(entry: DailySummaryEntry) {
        this.dayTimeEntryId = entry.dayTimeEntryId;
        this.zrt = entry.zrt;
        this.name = entry.name;
        const dateFormatted = moment(entry.date).format(MY_DATE_FORMATS.display.dateInput);
        this.date = `${dateFormatted}${entry.resetDay ? "<br /><p>Reset Day</p>" : ""}`;
        this.callBreakdown = `Total Calls Made: ${entry.totalCalls ?? 0}<br />` +
            `Hours at Retail: ${Helper.getHoursAndMinutesFromMinutes(entry.retailMinutes, false) ?? 0}<br />` +
            `Hours at Wholesale: ${Helper.getHoursAndMinutesFromMinutes(entry.wholesaleMinutes, false) ?? 0}<br />` +
            `Hours Driving: ${Helper.getHoursAndMinutesFromMinutes(entry.drivingMinutes, false) ?? 0}<br />` +
            `Hours Other: ${Helper.getHoursAndMinutesFromMinutes(entry.otherMinutes, false) ?? 0}<br />` +
            `Start Time: ${moment(entry.startDate).isValid() ? moment(entry.startDate).format('h:mm A') : 'N/A'}<br />` +
            `End Time: ${moment(entry.endDate).isValid() ? moment(entry.endDate).format('h:mm A') : 'N/A'}<br />`;
        this.endOfDayComments = entry.endOfDayComments;
        this.workWithAndDuration = entry.workWiths?.length
            ? entry.workWiths.join("<br />")
            : "None";
    }
}

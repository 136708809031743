<mat-card appearance="outlined" class="mt-3">
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item top mb-3">
                <div class="card-header">
                    Call Entry
                </div>
                <div class="card-body p-0">
                    <div *ngIf="vm" class="card-view-port">
                     <div class="left-margin">
                            <div class="row">
                                <div class="col-2 v-center-left-align">
                                    <mat-checkbox [indeterminate]="false"
                                        [color]="'warn'"
                                        [(ngModel)]="vm.isReturns"
                                        [disabled]="!!injectedData"
                                        (change)="callService.saveCallEntry(vm)">
                                        Returns
                                    </mat-checkbox>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" color="accent">
                                        <mat-label>Purchase Invoice #</mat-label>
                                        <input matInput placeholder="Type the purchase invoice number..."
                                        [(ngModel)]="vm.purchaseInvoice"
                                        [disabled]="!!injectedData"
                                        maxlength="50"
                                        (blur)="callService.saveCallEntry(vm)">
                                        <mat-hint align="end"
                                                            >{{
                                                                vm.purchaseInvoice
                                                                    ?.length || 0
                                                            }}/50</mat-hint
                                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" color="accent">
                                        <mat-label>
                                            Purchase Amount
                                        </mat-label>
                                        <input matInput currencyMask
                                            [(ngModel)]="vm.purchaseAmount"
                                            [disabled]="!!injectedData"
                                            (blur)="callService.saveCallEntry(vm)"/>
                                    </mat-form-field>
                                </div>
                                <div class="col"></div>
                            </div>
                            <div class="row">
                                <div class="col-2 v-center-left-align">
                                    <mat-checkbox [indeterminate]="false"
                                        [color]="'warn'"
                                        [(ngModel)]="vm.isPurchase"
                                        [disabled]="!!injectedData"
                                        (change)="callService.saveCallEntry(vm)">
                                        Purchase
                                    </mat-checkbox>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" color="accent">
                                        <mat-label>Credit Memo #</mat-label>
                                        <input matInput placeholder="Type the credit memo number..."
                                        [(ngModel)]="vm.creditMemo"
                                        [disabled]="!!injectedData"
                                        maxlength="50"
                                        (blur)="callService.saveCallEntry(vm)">
                                        <mat-hint align="end"
                                                            >{{
                                                                vm.creditMemo
                                                                    ?.length || 0
                                                            }}/50</mat-hint
                                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" color="accent">
                                        <mat-label>
                                            Credit Amount
                                        </mat-label>
                                        <input matInput currencyMask
                                            [(ngModel)]="vm.creditAmount"
                                            [disabled]="!!injectedData"
                                            (blur)="callService.saveCallEntry(vm)"/>
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <div>
                                        Net Difference: {{(vm.purchaseAmount ? vm.purchaseAmount : 0)  - (vm.creditAmount ? vm.creditAmount : 0) | currency}}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2 v-center-left-align">
                                    <mat-checkbox [indeterminate]="false"
                                        [color]="'warn'"
                                        [(ngModel)]="vm.isSwisherDay"
                                        [disabled]="!!injectedData"
                                        (change)="callService.saveCallEntry(vm)">
                                        Swisher Day
                                    </mat-checkbox>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" color="accent">
                                        <mat-label>Bill Through</mat-label>
                                        <input matInput placeholder="Type the bill through number..."
                                        [(ngModel)]="vm.billThrough"
                                        [disabled]="!!injectedData"
                                        maxlength="50"
                                        (blur)="callService.saveCallEntry(vm)">
                                        <mat-hint align="end"
                                                            >{{
                                                                vm.billThrough
                                                                    ?.length || 0
                                                            }}/50</mat-hint
                                                        >
                                    </mat-form-field>
                                </div>
                                <div class="col-3"></div>
                                <div class="col"></div>
                            </div>
                            <div class="row">
                                <div class="col-2 v-center-left-align">
                                    <mat-checkbox [indeterminate]="false" [color]="'warn'"
                                    [(ngModel)]="vm.isTradeShow"
                                    [disabled]="!!injectedData"
                                    (change)="callService.saveCallEntry(vm)">
                                        Trade Show
                                    </mat-checkbox>
                                </div>
                                <div class="col-3"></div>
                                <div class="col-3"></div>
                                <div class="col"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item top mb-3">
                <div class="card-header">Pictures</div>
                <div class="card-body p-0">
                    <div class="card-view-port">
                        <div *ngIf="slides && slides.length" class="row">
                            <div class="col-3 chevron-container padding-left-picture-type">
                                <div *ngIf="pictureType" class="picture-info-container">
                                    Picture Type: {{ pictureType }}
                                </div>
                                <span class="chevron material-icons" (click)="carousel.previous()">chevron_left</span>
                            </div>
                            <div class="col carousel-container">
                                <mat-carousel #carousel (change)="setPictureType($event)" [autoplay]="false"
                                    color="primary" [proportion]="proportion" maxWidth="400px" [slides]="slides.length"
                                    [loop]="true" [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="true"
                                    [useMouseWheel]="true" orientation="ltr">
                                    <mat-carousel-slide #matCarouselSlide *ngFor="
                                            let slide of slides;
                                            let i = index
                                        " [image]="slide.image" overlayColor="#00000040" [hideOverlay]="true">
                                    </mat-carousel-slide>
                                </mat-carousel>
                            </div>
                            <div class="col-2 chevron-container padding-right-picture-type">
                                <div *ngIf="pictureTags" class="picture-info-container">
                                    Picture Tags: {{ pictureTags }}
                                </div>
                                <span class="chevron material-icons" (click)="carousel.next()">chevron_right</span>
                            </div>
                            <div class="col-1 trash-adjust">
                                <fa-icon *ngIf="!injectedData" [icon]="faTrash" (click)="deletePicture()" class="mr-1"></fa-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item bottom mb-3">
                <div class="card-header">Activities and Surveys</div>
                <div class="card-body no-scroll p-0">
                    <div *ngIf="vm">
                        <div class="inner-margin">
                            <app-activities-surveys
                            [isReadOnly]="!!injectedData"
                            [isPanel]="true"
                            [call]="call"></app-activities-surveys>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item bottom mb-3">
                <div class="card-header">Call Comments</div>
                <div class="card-body p-0">
                    <div class="card-view-port comment-height">
                    <div *ngIf="vm">
                        <div *ngIf="!injectedData" class="row">
                            <div class="col">
                                <mat-form-field appearance="outline" color="accent" class="call-note-form-field">
                                    <mat-label id="notes">Notes</mat-label>
                                    <textarea cdkTextareaAutosize="false" matAutosizeMaxRows="12"
                                        (blur)="callService.saveClosingNotes(vm.closingNotes)"
                                        [(ngModel)]="vm.closingNotes" rows="6" type="text" matInput maxlength="4000">
                                    </textarea>
                                    <mat-hint align="end">{{
                                        vm.closingNotes?.length || 0
                                        }}/4000
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="injectedData" class="col">
                            {{ vm.closingNotes }}
                        </div>
                        <div *ngIf="!injectedData" class="row">
                            <div class="col center">
                                <button appThrottleClick mat-raised-button class="btn sw-green-btn btm-sm actionBtn"
                                    (throttledClick)="onOpenConfirmation()">
                                    <mat-icon style="display: inline">check_circle_outline</mat-icon>
                                    Save
                                </button>
                                <button appThrottleClick mat-raised-button class="btn sw-teal-btn btn-sm actionBtn"
                                    (throttledClick)="onOpenShare()">
                                    Share
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>


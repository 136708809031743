import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
//Icons
import {
    faTrash,
    faTimes,
    faPlus,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { RadioButtonViewModel } from "../../shared/viewmodels/radio-button.viewmodel";
import { AddProductsViewmodel } from "./add-products.viewmodel";
import { AddProductViewModel } from "./add-product.viewmodel";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { CommonFilters } from "shield.shared";

@Component({
    selector: "app-add-wholesale-products",
    templateUrl: "./add-products.component.html",
    styleUrls: ["./add-products.component.scss"]
})
export class AddProductsComponent implements OnInit {
    @ViewChild("search") search: ElementRef;
    @ViewChild("viewport") viewport: CdkVirtualScrollViewport;

    faTrash: IconDefinition = faTrash;
    faTimes: IconDefinition = faTimes;
    faPlus: IconDefinition = faPlus;
    filterViewmodels: AddProductViewModel[] = [];
    selectedCount = 0;
    isAllSelected: boolean;
    searchText = "";

    selectedFilteredProduct: string;
    selectedFilteredCategory: string;

    categories: RadioButtonViewModel[] = [];

    searchValueSubscription: Subscription;

    constructor(
        public overlayRef: SwisherOverlayRef<
            AddProductsViewmodel,
            AddProductsComponent
        >
    ) {}

    @HostListener('window:keydown', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.overlayRef) {
                this.overlayRef.data.buttonLeftFunction()
            }
        }

    ngOnInit(): void {
        this.overlayRef.blocking = true;

        this.selectedFilteredProduct = CommonFilters.All;
        this.overlayRef.data.result = Array.from(
            this.overlayRef.data.products.values()
        );

        this.categories = [];
        const all: RadioButtonViewModel = new RadioButtonViewModel();
        all.name = CommonFilters.All;
        all.value = CommonFilters.All;
        this.categories.push(all);

        const distinctDivision: string[] = [
            ...new Set(
                this.overlayRef.data.result.map((vm) => vm.product.division)
            )
        ];
        (distinctDivision ?? []).sort((a, b) => a?.localeCompare(b)).forEach((division) => {
            const rb = new RadioButtonViewModel();
            rb.name = division;
            rb.value = division;
            this.categories.push(rb);
        });

        this.selectedFilteredCategory = CommonFilters.All;

        this.filterViewmodels = this.overlayRef.data.result.slice();
        this.isAllSelected = !this.filterViewmodels.find(
            (fvm) => !fvm.selected
        );

        setTimeout(() => {
            if (this.search) {
                const searchValue = fromEvent(
                    this.search.nativeElement,
                    "input"
                ).pipe(
                    map(
                        (e: InputEvent) => (e.target as HTMLInputElement).value
                    ),
                    debounceTime(500),
                    distinctUntilChanged()
                );

                if (
                    !this.searchValueSubscription ||
                    this.searchValueSubscription.closed
                ) {
                    this.searchValueSubscription = searchValue.subscribe(() => {
                        this.filter();
                    });
                }
            }
        }, 0);

        this.setSelectedCount();

        this.overlayRef.overlay.backdropClick().subscribe(() => {
            this.overlayRef.close(this.overlayRef.data);
        });
    }

    filter(): void {
        this.filterViewmodels = this.overlayRef.data.result.slice();

        if (this.searchText) {
            const searchText = this.searchText.toLowerCase();
            this.filterViewmodels = this.filterViewmodels.filter(
                (fvm) =>
                    (fvm.product.description &&
                        fvm.product.description
                            .toLowerCase()
                            .includes(searchText)) ||
                    (fvm.product.longDescription &&
                        fvm.product.longDescription
                            .toLowerCase()
                            .includes(searchText)) ||
                    (fvm.product.itemNumber &&
                        fvm.product.itemNumber
                            .toLowerCase()
                            .includes(searchText))
            );
        }
        if (this.selectedFilteredProduct === "Selected") {
            this.filterViewmodels = this.filterViewmodels.filter(
                (fvm) => fvm.selected
            );
        }

        if (this.selectedFilteredCategory !== CommonFilters.All) {
            const value = this.selectedFilteredCategory.toLocaleLowerCase();
            this.filterViewmodels = this.filterViewmodels.filter(
                (fvm) => fvm.product.division?.toLocaleLowerCase() === value
            );
        }

        this.filterViewmodels.sort((a, b) =>
            a.product.description <= b.product.description ? -1 : 1
        );
    }

    selectAll(): void {
        for (const row of this.filterViewmodels) {
            row.selected = this.isAllSelected;
            this.changeSelected(row);
        }
        this.setSelectedCount();
    }

    changeSelected(row: AddProductViewModel): void {
        if (this.overlayRef.data.products.has(row.product.id)) {
            const record = this.overlayRef.data.products.get(
                row.product.id
            );
            record.selected = row.selected;
        }
    }

    setSelectedCount(): void {
        this.overlayRef.data.result = Array.from(
            this.overlayRef.data.products.values()
        );
        this.selectedCount =
            this.overlayRef.data.result.filter((fvm) => fvm.selected)?.length ??
            0;
        this.filter();
        this.isAllSelected = !this.filterViewmodels.find(
            (fvm) => !fvm.selected
        );
    }

    //this function handles the known issue of implementing a sticky header in a cdk virtual scroll component (https://github.com/angular/components/issues/14833). It implements one of the solutions found on GitHub (https://stackblitz.com/edit/components-issue-brsnj4?file=app%2Fapp.component.html)
    public get inverseOfTranslation(): string {
        if (!this.viewport || !this.viewport["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewport["_renderedContentOffset"];
        return `-${offset}px`;
    }
}

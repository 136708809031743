import {
    AfterContentChecked,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { OverlayService } from "src/app/services/overlay.service";
import { SnackbarService } from "src/app/services/snackbar.service";

import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { TransactionsListRefinerService } from "./transactions-list-refiner.service";
import { TransactionsListViewmodel } from "./transactions-list.viewmodel";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { TransactionDelineationService } from "src/app/services/delineation-services/transaction-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { StateLicenseDelineationService } from "src/app/services/delineation-services/state-license-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { TransactionListZrtFilterService } from "./transaction-list-zrt-filter.service";
import { NotificationDelineationService } from "src/app/services/delineation-services/notification-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CallEventLoggerService } from "src/app/accounts/call-master/call-services/call-event-logger.service";

@Component({
    selector: "app-transactions-list",
    templateUrl: "./transactions-list.component.html",
    styleUrls: ["./transactions-list.component.scss"],
    providers: [PleaseWaitService]
})
export class TransactionsListComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;

    // Public vars
    viewmodel: TransactionsListViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private refinerService: TransactionsListRefinerService,
        private overlayService: OverlayService,
        private transactionDelineationService: TransactionDelineationService,
        private formBuilder: UntypedFormBuilder,
        private productDelineationService: ProductDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private snackbar: SnackbarService,
        private receiptDelineationService: ReceiptDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private activitiesFilterService: ActivitiesFilterService,
        private appStateService: AppStateService,
        private employeeDelneationService: EmployeeDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private stateLicenseDelineationService: StateLicenseDelineationService,
        private zrtFilterService: TransactionListZrtFilterService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private filterService: FilterService,
        private notificationDelineationService: NotificationDelineationService,
        private callService: CallDelineationService,
        private eventLogger: CallEventLoggerService,
    ) {}

    async ngOnInit(): Promise<void> {
        this.viewmodel = new TransactionsListViewmodel(
            this.refinerService,
            this.overlayService,
            this.transactionDelineationService,
            this.formBuilder,
            this.productDelineationService,
            this.customerDelineationService,
            this.snackbar,
            this.receiptDelineationService,
            this.pleaseWaitService,
            this.activitiesFilterService,
            this.appStateService,
            this.employeeDelneationService,
            this.projectDelineationService,
            this.stateLicenseDelineationService,
            this.zrtFilterService,
            this.accountOwnershipDelineationService,
            this.filterService,
            this.notificationDelineationService,
            this.callService,
            this.eventLogger,
        );
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.startDateInput
            && this.endDateInput
            ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.startDateInput,
                this.endDateInput
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, computed, input, viewChildren } from '@angular/core';
import { Contact } from 'src/app/entity-models/contact.entity';
import { ReceiptSelectedFormat } from 'src/app/enums/receipt-selected-format';
import { ReceiptAddressViewModel } from '../../receipt-address.viewmodel';
import { Customer } from 'src/app/entity-models/customer.entity';
import { Employee } from 'src/app/entity-models/employee.entity';
import { RetailCall } from 'src/app/entity-models/retail-call.entity';
import { RmWholesaleCall } from 'src/app/entity-models/rm-wholesale-call.entity';
import { Product } from 'src/app/entity-models/product.entity';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-retail-format-draft',
  templateUrl: './retail-format-draft.component.html',
  styleUrls: ['./retail-format-draft.component.scss']
})
export class RetailFormatDraftComponent {

    @ViewChild("originalSwisherRetailReceipt") originalSwisherRetailReceipt: ElementRef;
    private _draftReceipts = viewChildren<RetailBaseComponent>("draftReceipts");
    draftReceiptElements = computed(() => this._draftReceipts()
        .filter(c => c.subsidiary() === Subsidiary.Swisher ? c.hasSwisherProducts() : c.hasEasProducts())  
        .map(c => c.elementRef)
    );
    @Input() isRetailDraft = false;
    @Input() isRetailFinal = false;
    @Input() customerReceiptAddress: ReceiptAddressViewModel;
    @Input() signature: string;
    @Input() signatureRetailWidth: number;
    @Input() signatureRetailHeight: number;
    @Input() selectedContact: Contact;
    @Input() isEasEmployee: boolean;
    @Input() isTaxState: boolean;
    @Input() selectedFormat: ReceiptSelectedFormat;
    @Input() customer: Customer;
    @Input() employee: Employee;
    @Input() call$: Observable<RetailCall | RmWholesaleCall>;
    @Input() activeProducts: Map<string, Product>;
    

    @Output() captureReady = new EventEmitter();

    isEasRetailFormatDraftBuilt = false;
    isSwisherRetailFormatDraftBuild = false;
    selectedFormats = ReceiptSelectedFormat;

    easWholesalerFormatDraftBuilt(): void {
        this.isEasRetailFormatDraftBuilt = true;
        this.isCapureReady();
    }

    swisherWholesalerFormatDraftBuilt(): void {
        this.isSwisherRetailFormatDraftBuild = true;
        this.isCapureReady();
    }

    isCapureReady(): void {
        if (this.isEasRetailFormatDraftBuilt && this.isSwisherRetailFormatDraftBuild) {
            this.captureReady.emit();
            this.isEasRetailFormatDraftBuilt = false;
            this.isSwisherRetailFormatDraftBuild = false;
        }
    }

}

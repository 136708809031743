import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MissingReceiptsComponent } from './missing-receipts.component';

import { SharedModule } from 'src/app/shared/shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StepperCallModule } from 'src/app/accounts/call-master/stepper-call/stepper-call.module';


@NgModule({
  declarations: [
    MissingReceiptsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatSlideToggleModule,
    StepperCallModule
  ]
})
export class MissingReceiptsModule { }

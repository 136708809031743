import { CommonFilters } from "shield.shared";

export class DailySummaryAttributesViewmodel {
    all: CommonFilters.All = CommonFilters.All;
    yes: CommonFilters.Yes = CommonFilters.Yes;
    no: CommonFilters.No = CommonFilters.No;

    selectedResetDays: string = this.all;
    workWithInput: string;
}

import { ElementRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { FilterService } from "src/app/services/filter.service";
export class CallDateInformationViewmodel {
    filterService: FilterService;
    formBuilder: UntypedFormBuilder;

    callDateForm: UntypedFormGroup;

    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment();
    endMinDate: moment.Moment = moment().subtract(3, "years");

    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment();

    constructor(
        filterService: FilterService,
        formBuilder: UntypedFormBuilder
    ) {
        this.filterService = filterService;
        this.formBuilder = formBuilder;
        this.callDateForm = this.formBuilder.group({
            callEndDate: ["", null],
            callStartDate: ["", null]
        });

    }
}

import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { ProjectFilterService } from 'src/app/details/project-list/project-filter.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { PageHeaderComponent } from 'src/app/shared/page-header/page-header.component';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectStoresViewmodel } from './project-stores.viewmodel';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { SystemInformationDelineationService } from 'src/app/services/delineation-services/system-information-delineation.service';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { AccountOwnershipDelineationService } from 'src/app/services/delineation-services/account-ownership-delineation.service';
import { FilterService } from 'src/app/services/filter.service';
import { ProjectStoresRefinerService } from './project-stores-refiner.service';
import { ProjectStoresZrtFilterService } from './project-stores-zrt-filter.service';
import { MySearchesFilterComponent } from 'src/app/shared/filters/my-searches/my-searches-filter.component';
import { CustomerLocationFilterComponent } from 'src/app/shared/filters/customer-location-filter/customer-location-filter.component';
import { CallDateInformationFilterComponent } from 'src/app/shared/filters/call-date-information-filter/call-date-information-filter.component';
import { ProjectActivitiesFilterComponent } from 'src/app/shared/filters/project-activities-filter/project-activities-filter.component';
import { ProjectCustomerAttributesFilterComponent } from 'src/app/shared/filters/project-customer-attributes-filter/project-customer-attributes-filter.component';
import { OverlayService } from 'src/app/services/overlay.service';
import { ProjectStep } from 'src/app/enums/project-step';
import { Px3DelineationService } from 'src/app/services/delineation-services/px3-delineation.service';
import { CallDelineationService } from 'src/app/services/delineation-services/call-delineation.service';

@Component({
    selector: 'app-project-stores',
    templateUrl: './project-stores.component.html',
    styleUrls: ['./project-stores.component.scss']
})
export class ProjectStoresComponent implements OnDestroy, AfterViewChecked {

    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("headerContainer") headerContainer: ElementRef;
    @ViewChild("gridContainer") gridContainer: ElementRef;

    viewmodel = new ProjectStoresViewmodel(this.refinerService,
        this.formBuilder,
        this.pleaseWaitService,
        this.projectDelineationService,
        this.snackbarService,
        this.projectStateService,
        this.projectApplicationService,
        this.systemInformationDelineationService,
        this.activatedRoute,
        this.projectFilterService,
        this.customerDelineationService,
        this.employeeDelineationService,
        this.zrtService,
        this.accountOwnershipDelineationService,
        this.filterService,
        this.overlayService,
        this.px3RankService,
        this.callsService
    );
    isViewmodelReady = false;
    currentStep = ProjectStep.stores;

    constructor(private refinerService: ProjectStoresRefinerService,
        private pleaseWaitService: PleaseWaitService,
        private projectDelineationService: ProjectDelineationService,
        public projectStateService: ProjectStateService,
        public projectApplicationService: ProjectApplicationService,
        private snackbarService: SnackbarService,
        private formBuilder: UntypedFormBuilder,
        private systemInformationDelineationService: SystemInformationDelineationService,
        private activatedRoute: ActivatedRoute,
        private projectFilterService: ProjectFilterService,
        private customerDelineationService: CustomerDelineationService,
        private employeeDelineationService: EmployeeDelineationService,
        private zrtService: ProjectStoresZrtFilterService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private filterService: FilterService,
        private overlayService: OverlayService,
        private px3RankService: Px3DelineationService,
        private callsService: CallDelineationService
    ) { }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (this.projectApplicationService.selectedIndex !== ProjectStep.stores) return;

        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.drawer
            && this.projectStateService.project
            && this.headerContainer
            && this.gridContainer
        ) {
            this.isViewmodelReady = true;

            this.viewmodel.filters.push({ filter: MySearchesFilterComponent });
            this.viewmodel.filters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtService });
            this.viewmodel.filters.push({ filter: CallDateInformationFilterComponent });
            this.viewmodel.filters.push({ filter: ProjectActivitiesFilterComponent });
            this.viewmodel.filters.push({ filter: ProjectCustomerAttributesFilterComponent });
            this.viewmodel.setDefaults();

            this.getScreenSize();
            setTimeout(() => {
                void this.viewmodel.initialize(
                    this.grid,
                    this.header,
                    this.drawer,
                    this.headerContainer,
                    this.gridContainer
                );
                this.refinerService.refiners.forEach((refiner) => this.viewmodel.onInputChange(refiner));
            }, 0);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }

    @HostListener('window:scroll', [])
    private onScroll(): void {
        this.viewmodel.calculateGap();
    };
}

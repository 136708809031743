import { Component, Input } from '@angular/core';
import { TaxCalculatorRecordViewModel } from '../tax-calculator-record.viewmodel';
import { TaxCalculationMethodLookup, TaxCalculationMethods } from 'shield.shared';

@Component({
  selector: 'tax-entry',
  templateUrl: './tax-entry.component.html',
  styleUrl: './tax-entry.component.scss'
})
export class TaxEntryComponent {

    readonly TaxCalculationMethods = TaxCalculationMethods;
    @Input("tax") tax: number;
    @Input("rate") rate: number;
    @Input("calculationMethod") calculationMethod: TaxCalculationMethods;
    @Input("record") record: TaxCalculatorRecordViewModel;

    get calculationMethodName() {
        return TaxCalculationMethodLookup.find((tc) => tc.id === this.calculationMethod).name;
    }

}

{
  "name": "shield-client",
  "version": "1.6.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port=5001 --ssl=true --ssl-cert=../Shield.Server/secrets/public-certificate.pem --ssl-key=../Shield.Server/secrets/private-key.pem",
    "start:localToDev": "ng serve --configuration=localToDev --port=5001 --ssl=true --ssl-cert=../Shield.Server/secrets/public-certificate.pem --ssl-key=../Shield.Server/secrets/private-key.pem",
    "start:localToTest": "ng serve --configuration=localToTest --port=5001 --ssl=true --ssl-cert=../Shield.Server/secrets/public-certificate.pem --ssl-key=../Shield.Server/secrets/private-key.pem",
    "start:localToUat": "ng serve --configuration=localToUat --port=5001 --ssl=true --ssl-cert=../Shield.Server/secrets/public-certificate.pem --ssl-key=../Shield.Server/secrets/private-key.pem",
    "build:prod": "ng build --configuration production",
    "build": "ng build",
    "test": "ng test --source-map=false",
    "lint": "ng lint",
    "e2e": "ng e2e"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.3.1",
    "@angular/cdk": "17.3.1",
    "@angular/common": "17.3.1",
    "@angular/compiler": "17.3.1",
    "@angular/core": "17.3.1",
    "@angular/forms": "17.3.1",
    "@angular/google-maps": "^17.3.1",
    "@angular/localize": "17.3.1",
    "@angular/material": "17.3.1",
    "@angular/material-moment-adapter": "17.3.1",
    "@angular/platform-browser": "17.3.1",
    "@angular/platform-browser-dynamic": "17.3.1",
    "@angular/platform-server": "17.3.1",
    "@angular/router": "17.3.1",
    "@angular/service-worker": "17.3.1",
    "@azure/msal-angular": "^3.0.0",
    "@azure/msal-browser": "^3.24.0",
    "@danielmoncada/angular-datetime-picker": "^17.0.0",
    "@fortawesome/angular-fontawesome": "^0.14.1",
    "@fortawesome/fontawesome-svg-core": "^1.2.36",
    "@fortawesome/free-solid-svg-icons": "^5.15.4",
    "@googlemaps/markerclustererplus": "^1.2.10",
    "@magloft/material-carousel": "^14.2.0",
    "@microsoft/applicationinsights-angularplugin-js": "^3.0.3",
    "@microsoft/applicationinsights-web": "^2.8.18",
    "@ng-bootstrap/ng-bootstrap": "^16.0.0",
    "@ngneat/until-destroy": "^9.2.3",
    "@popperjs/core": "^2.11.6",
    "@swimlane/ngx-charts": "^20.5.0",
    "@types/humanize-duration": "^3.25.0",
    "@types/jquery": "^3.5.13",
    "angular-popper": "^2.0.1",
    "angular2-multiselect-dropdown": "https://github.com/JasonCannonJBS/angular2-multiselect-dropdown-ivy-dist#Angular15",
    "applicationinsights": "^1.8.10",
    "bootstrap": "^4.6.2",
    "colors": "^1.4.0",
    "core-js": "^3.7.0",
    "dexie": "^3.1.0-beta.13",
    "hammerjs": "^2.0.8",
    "html2canvas": "^1.4.1",
    "http-server": "^14.1.1",
    "humanize-duration": "^3.26.0",
    "jquery": "^3.5.1",
    "jspdf": "^2.5.0",
    "lodash-es": "^4.17.21",
    "material-design-icons": "^3.0.1",
    "moment": "^2.29.3",
    "moment-range": "^4.0.2",
    "ng-table-virtual-scroll": "^1.3.7",
    "ngx-capture": "^0.14.0",
    "ngx-currency": "^17.0.0",
    "ngx-mask": "^17.1.8",
    "ngx-webcam": "^0.4.1",
    "oidc-client": "^1.10.1",
    "pdf-lib": "^1.17.1",
    "rxjs": "^7.8.1",
    "shield.shared": "../Shield.Shared",
    "signature_pad": "^3.0.0-beta.4",
    "tslib": "^2.0.3",
    "uuid": "^8.3.2",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.3/xlsx-0.20.3.tgz",
    "zone.js": "^0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.9",
    "@angular-eslint/builder": "1.2.0",
    "@angular-eslint/eslint-plugin": "1.2.0",
    "@angular-eslint/eslint-plugin-template": "1.2.0",
    "@angular-eslint/template-parser": "1.2.0",
    "@angular/cli": "17.3.1",
    "@angular/compiler-cli": "17.3.1",
    "@angular/language-service": "17.3.1",
    "@types/file-saver": "^2.0.5",
    "@types/google.maps": "^3.44.6",
    "@types/jasmine": "^3.10.18",
    "@types/jasminewd2": "~2.0.8",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^14.18.63",
    "@types/uuid": "^8.3.4",
    "@types/webpack-env": "^1.16.0",
    "@typescript-eslint/eslint-plugin": "^4.3.0",
    "@typescript-eslint/parser": "^4.3.0",
    "eslint": "^7.32.0",
    "eslint-config-prettier": "^8.10.0",
    "eslint-plugin-import": "^2.22.1",
    "eslint-plugin-jsdoc": "^30.7.6",
    "eslint-plugin-prefer-arrow": "^1.2.2",
    "eslint-plugin-prettier": "^3.4.1",
    "file-saver": "^2.0.5",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "prettier": "^2.8.8",
    "sass": "^1.79.2",
    "sass-loader": "^10.5.2",
    "typescript": "^5.4.3"
  },
  "optionalDependencies": {
    "ts-node": "~9.0.0"
  }
}

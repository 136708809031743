import { CommonFilters, CustomerTypeEnum, GenericLookup } from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { FilterService } from "src/app/services/filter.service";

export class CallAttributesViewmodel {
    filterService: FilterService;
    all: CommonFilters.All = CommonFilters.All;
    yes: CommonFilters.Yes = CommonFilters.Yes;
    no: CommonFilters.No = CommonFilters.No;

    allCustomerType = new GenericLookup<CustomerTypeEnum>();
    isAllCustomerTypePresent: boolean;
    allPx3Rank = new GenericLookup<Px3Rank>();
    notRankedPx3Rank = new GenericLookup<Px3Rank>();
    isAllPx3RankPresent: boolean;
    px3Ranks = new Array<GenericLookup<Px3Rank>>();
    customerTypes = new Array<GenericLookup<CustomerTypeEnum>>();
    selectedCustomerTypes = new Array<GenericLookup<CustomerTypeEnum>>();
    selectedMsa: string = this.all;
    selectedPx3Ranks = new Array<GenericLookup<Px3Rank>>();

    constructor(filterService: FilterService, private px3RankService: Px3DelineationService) {
        this.filterService = filterService;
    }

    async initializeStoreTypeDropDown(): Promise<void> {
        this.allCustomerType.name = "All";
        this.selectedCustomerTypes.push(this.allCustomerType);
        this.customerTypes.push(this.allCustomerType);
        const customerTypes = this.filterService.getCustomerTypes();
        if(customerTypes) {
            for(let type of customerTypes) {
                this.customerTypes.push(type);
            }
        }
    }

    async initializePx3RankDropDown(): Promise<void> {
        this.allPx3Rank.name = "All";
        this.notRankedPx3Rank.name = "Not Ranked";
        this.px3Ranks.push(this.allPx3Rank);
        this.px3Ranks.push(this.notRankedPx3Rank);
        const px3Ranks = (await this.px3RankService.getAll());
        for (let rank of px3Ranks){
            this.px3Ranks.push({
                id: rank,
                name: rank.rank,
                description: rank.rank,
            });
        }
        this.selectedPx3Ranks.push(this.allPx3Rank);
    }
}

<td *ngIf="tax" colspan="2">
    <div>
        <b>Rate: </b>
        <span>{{ rate }}{{ calculationMethodName }}</span>
    </div>
    <div>
        <b>Tax: </b>
        <span>
            @switch (calculationMethod) {
                @case(TaxCalculationMethods.DollarPerPack) {
                    {{ record.quantity * record.noOfPacks }} packs
                } @case (TaxCalculationMethods.DollarPerEach) {
                    {{ record.quantityExtention }} eaches
                } @case (TaxCalculationMethods.DollarPerOunce) {
                    {{ record.weight }} oz per each
                }
            }
        </span>
        <span>
            {{(calculationMethod !== TaxCalculationMethods.PercentOfPrice ? " - " : "")
                + (record.stateTax | precisionCurrency)}}
        </span>
    </div>
</td>
<td *ngIf="!tax" colspan="2">
No Tax
</td>

<div class="card d-flex flex-grow-1 m-0">
    <div class="card-header d-flex justify-content-between">
        Products
        <div>
            <fa-icon [icon]="faPlus" appThrottleClick (throttledClick)="addProduct()"></fa-icon>
        </div>
    </div>
    <div class="card-body">
        <cdk-virtual-scroll-viewport itemSize="50" minBufferPx="600" maxBufferPx="800" class="viewport">
        <form [formGroup]="myForm">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th scope="col">
                    </th>
                    <th scope="col">
                        Swisher #
                    </th>
                    <th scope="col">
                        UIN # <i class="text-primary">Required</i>
                    </th>
                    <th scope="col">
                        Description
                    </th>
                    <th scope="col">
                        Display UPC
                    </th>
                    <th scope="col">
                        Date Available
                    </th>
                    <th scope="col">
                        Wholesale Price
                    </th>
                    <th scope="col">
                        At Wholesale
                    </th>
                    <th scope="col">
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                    <tr class="viewport-item" *cdkVirtualFor="let row of wholesalerProductsViewmodel.wholesaleProducts; index as i" minBufferPx="600"
                                maxBufferPx="800" class="viewport-item">
                    <td>
                        <fa-icon [icon]="faTrash" (click)="delete(i)" class="text-m-gray"></fa-icon>
                    </td>
                    <td>
                        {{row.product.itemNumber}}
                    </td>
                    <td>
                        <mat-form-field *ngIf="myForm.controls['uin' + i]" appearance="outline" color="accent">
                            <mat-label>
                                UIN #
                            </mat-label>
                            <input matInput [value]="myForm.value.uin" [formControlName]="'uin' + i" maxlength="50"/>
                            <mat-hint align="end">{{myForm.value.uin?.length || 0}}/50</mat-hint>
                        </mat-form-field>
                    </td>
                    <td>
                        {{row.product.description}}
                    </td>
                    <td>
                        {{row.product.lowestSellableUpc?.upc}}
                    </td>
                    <td>
                        <mat-form-field *ngIf="myForm.controls['dateAvailable' + i]" appearance="outline" color="accent">
                            <mat-label>Date Available</mat-label>
                            <input matInput [placeholder]="dateFormat"
                                [min]="tomorrow" [matDatepicker]="afterPicker" [formControlName]="'dateAvailable' + i"
                                [value]="myForm.value.dateAvailable" />
                            <mat-datepicker touchUi #afterPicker></mat-datepicker>
                            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-error *ngIf="myForm.controls['dateAvailable' + i]?.invalid">
                                Date available must be a valid future date.
                        </mat-error>
                    </td>
                    <td>
                        <mat-form-field appearance="outline" color="accent">
                            <mat-label>
                                Wholesale Price
                            </mat-label>
                            <input matInput currencyMask [(ngModel)]="row.wholesalePrice"
                            [ngModelOptions]="{standalone: true}" />
                        </mat-form-field>
                    </td>
                    <td>
                        {{row.dateAvailable ? 'Pending' : 'Indist'}}
                    </td>
                    <td>
                        {{row.isActive}}
                    </td>
                </tr>
            </tbody>
        </table>
    </form>
</cdk-virtual-scroll-viewport>
    </div>
    <div class="navbar fixed-bottom card-footer position-sticky card-footer bg-m-gray d-flex justify-content-end">
        <button class="btn btn-lg btn-secondary mr-2 pl-5 pr-5"
        (click)="cancel()">
            Cancel
        </button>
        <button appThrottleClick class="btn btn-lg btn-primary ml-2 pl-5 pr-5"
            (throttledClick)="save()">
            Save
        </button>
    </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tax-payment-options',
  templateUrl: './tax-payment-options.component.html',
  styleUrl: './tax-payment-options.component.scss'
})
export class TaxPaymentOptionsComponent {
    @Input("displayName") displayName: string;
    @Input("total") total: number;
    @Input("selectedPaymentType") selectedPaymentType: string;
    @Output("selectedPaymentTypeChange") selectedPaymentTypeChange = new EventEmitter<string>();
    @Input("paymentOptions") paymentOptions: string[];
}

import { Component, HostBinding, HostListener, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { BehaviorSubject, Subscription } from "rxjs";
import { Employee } from "src/app/entity-models/employee.entity";
import { RegisteredUser } from "src/app/entity-models/registered-user-entity";
import { AppStateService } from "src/app/services/app-state.service";
import { DatabaseService } from "src/app/services/database.service";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";
import { SystemInformation } from "src/app/entity-models/system-information.entity";
import { CallOnlineService } from "src/app/services/online-services/call-online.service";
@Component({
    selector: "app-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"]
})
export class UserProfileComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "worksheet-static";
    employeeSubscription: Subscription;
    employee: Employee;
    registeredUser: RegisteredUser;
    canSeeZrtOverride = false;
    showAdminPanel = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );
    code = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    currentKey = 0;
    phoneFormControl: UntypedFormControl;

    storageAddressLineOne: string;
    storageAddressLineTwo: string;
    sysInfo: Array<SystemInformation>;
    constructor(
        private registeredUserDelineationService: RegisteredUserDelineationService,
        private appStateService: AppStateService,
        private pleaseWaitService: PleaseWaitService,
        private databaseService: DatabaseService,
        private callOnlineService: CallOnlineService,
        private dataSyncQueueService: DataSyncQueueService
    ) { }

    ngOnInit(): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                (employee) => {
                    this.employee = employee;
                    if (this.employee && this.employee.user) {
                        void this.setRegisteredUser();
                    }
                }
            );
        }
        
        this.databaseService.systemInformation.toArray().then(i => {
            this.sysInfo = i;
        });
    }

    ngOnDestroy(): void {
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.code.indexOf(event.key) < 0 || event.key !== this.code[this.currentKey]) {
            this.currentKey = 0;
            return;
        }
        this.currentKey++;
        if (this.code.length === this.currentKey) {
            this.currentKey = 0;
            this.showAdminPanel = true;
        }
    };

    async saveRegisteredUser(): Promise<void> {
        if (!this.phoneFormControl.hasError("pattern")) {
            this.registeredUser.otherPhone = this.phoneFormControl
                .value as string;
            this.registeredUser.modifiedUserId = this.employee.id;
            await this.registeredUserDelineationService.saveRegisteredUser(this.registeredUser);
        }
    }

    async setRegisteredUser(): Promise<void> {
        if (this.employee.user && this.employee) {

            this.registeredUser = (await this.registeredUserDelineationService.getByEmployeeId(this.employee.id))?.values;

            this.phoneFormControl = new UntypedFormControl(this.registeredUser?.otherPhone, {
                validators: [Validators.pattern(this.usPhoneNumberRegEx)],
                updateOn: "blur"
            });
            if (
                window.localStorage.getItem("can_see_zrt-override") === "true"
            ) {
                this.canSeeZrtOverride = true;
            }
            this.shouldWait$.next(false);
        }
    }

    async saveSysInfo(idx: number): Promise<void> {
        let current = this.sysInfo[idx];
        console.log(`Updating ${current.key} to a value of ${current.value}`)
        await this.databaseService.systemInformation.update(current.key, current)
    }

    removeLocalStorageKey(key: string): void {
        localStorage.removeItem(key);
    }

    getStorageAddressLineOne(): string {
        if (this.storageAddressLineOne != null) return this.storageAddressLineOne;

        this.storageAddressLineOne = this.employee.shipAddress1
            ? `${this.employee.shipAddress1}${this.employee.shipAddress2 ? ", " + this.employee.shipAddress2 : ""} ${this.employee.shipAddress3 ?? ""}`
            : "None found."
        return this.storageAddressLineOne;
    }

    getStorageAddressLineTwo(): string {
        if (this.storageAddressLineTwo != null) return this.storageAddressLineTwo;

        this.storageAddressLineTwo = this.employee.shipAddress1
            ? `${this.employee.shipCity ?? ""}, ${this.employee.shipState ?? ""} ${this.employee.shipZipCode ?? ""}`
            : ""
        return this.storageAddressLineTwo;
    }
}

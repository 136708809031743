import { ElementRef } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn
} from "@angular/forms";
import moment from "moment";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { EmployeeRoleType, newSequentialId, RefinerLocation, Subsidiary, valueSeparator } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { ZoneRecapProductInDist } from "src/app/entity-models/zone-recap-product-indist.entity";
import { ZoneRecap } from "src/app/entity-models/zone-recap.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AddProductsComponent } from "src/app/products/add-products/add-products.component";
import { AddProductsViewmodel } from "src/app/products/add-products/add-products.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { ZoneRecapDelineationService } from "src/app/services/delineation-services/zone-recap-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { ZoneRecapProductStoreCount } from "./zone-recap-product-store-count";
import { ZoneRecapProduct } from "./zone-recap-product.viewmodel";
import { ZoneRecapReportZrtFilterService } from "./zone-recap-report-filter.service";
import { ZoneRecapReportRefinerService } from "./zone-recap-report-refiner.service";
import  XLSX from "xlsx";
import jsPDF from "jspdf";

export class ZoneRecapReportViewmodel {
    zone: string;

    appStateService: AppStateService;
    dateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    momentDateFormat: string = MY_DATE_FORMATS.display.customDateInput;
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    endDateDefault: moment.Moment;
    endDateInput: ElementRef;
    formBuilder: UntypedFormBuilder;
    initialized = false;
    isSearching = false;
    myData: ZoneRecap;
    overlayService: OverlayService;
    pleaseWaitService: PleaseWaitService;
    productDelineationService: ProductDelineationService;
    startDateDefault: moment.Moment;
    startDateInput: ElementRef;
    refinerService: ZoneRecapReportRefinerService;
    resultsDaysWorked = new Array<string | number>();
    resultsProductsHeaders = new Array<string>();
    resultsRetailCalls = new Array<string | number>();
    resultsCallAverageIncludingResetDays = new Array<string | number>();
    resultsCallAverageExcludingResetDays = new Array<string | number>();
    resultsTop300CalledOnOnce = new Array<string | number>();
    resultsTop300CalledOnTwiceOrMore = new Array<string | number>();
    resultsCallInformationHeaders = new Array<string>();
    resultsRetailAgreementsHeaders = new Array<string>();
    resultsNew = new Array<string | number>();
    resultsUnderContract = new Array<string | number>();
    resultsCanceled = new Array<string | number>();
    resultsDollarsPaidOut = new Array<string | number>();
    zoneRecap: ZoneRecap;
    zoneRecapDelineationService: ZoneRecapDelineationService;
    zoneRecapProducts: Array<ZoneRecapProduct>;
    zrtFilterService: ZoneRecapReportZrtFilterService;
    zrtSelectionSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    resultsTable: ElementRef;
    productsTable: ElementRef;
    recap: ElementRef;

    addProductOverlayRef: SwisherOverlayRef<
    AddProductsViewmodel,
    AddProductsComponent
>;

    private shouldWait$ = new BehaviorSubject<boolean>(false);
;

    constructor(
        formBuilder: UntypedFormBuilder,
        zoneRecapReportRefinerService: ZoneRecapReportRefinerService,
        zrtFilterService: ZoneRecapReportZrtFilterService,
        employeeDelineationService: EmployeeDelineationService,
        appStateService: AppStateService,
        zoneRecapDelineationService: ZoneRecapDelineationService,
        pleaseWaitService: PleaseWaitService,
        overlayService: OverlayService,
        productDelineationService: ProductDelineationService
    ) {
        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });
        this.refinerService = zoneRecapReportRefinerService;
        this.zrtFilterService = zrtFilterService;
        this.employeeDelineationService = employeeDelineationService;
        this.appStateService = appStateService;
        this.zoneRecapDelineationService = zoneRecapDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.overlayService = overlayService;
        this.productDelineationService = productDelineationService;
    }

    //events
    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        this.checkValidity();

        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.endDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.endDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.endDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "endDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                if (event?.type === "blur") {
                    this.dateForm.controls.endDate.setValue(
                        this.endDateDefault
                    );
                    this.refinerService.onInputChange(
                        RefinerLocation.endDate,
                        this.endDateInput.nativeElement.value
                    );
                }
            }
        }
    }
    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        this.checkValidity();

        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.startDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.startDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.startDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "startDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.dateForm.controls.startDate.setValue(this.startDateDefault);
                this.refinerService.onInputChange(
                    RefinerLocation.startDate,
                    this.startDateInput.nativeElement.value
                );
            }
        }
    }

    // public methods
    async initialize(
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        zrtTree: ZrtTreeViewComponent,
        resultsTable: ElementRef,
        productsTable: ElementRef,
        recap: ElementRef
    ): Promise<void> {
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;
        this.resultsTable = resultsTable;
        this.productsTable = productsTable;
        this.recap = recap;

        if (zrtTree) {
            zrtTree.zrtFilterService = this.zrtFilterService;
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                () => {
                    setTimeout(() => { this.search(); }, 0);
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrts.subscribe(
                (selectedZrts) => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.zrtByEmployee;
                    refiner.value =
                        selectedZrts.length > 1
                            ? `${selectedZrts.length} Selected`
                            : selectedZrts.map((vm) => vm.zrt).join(", ");
                    refiner.dataPropertyName = "employeeId";
                    refiner.dataValue = selectedZrts
                        .map((vm) => vm.id)
                        .join(valueSeparator);

                    this.refinerService.checkAndUpdateRefiner(refiner, true, true);
                }
            );
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    this.employee = employee;
                    this.setZone();
                    if (employee && !this.refinerService.areDefaultsSet) {
                        await this.setFilterData();
                        void this.search();
                    }
                }
            );
        }
    }

    onOpenProductModal(): void {
        const data: AddProductsViewmodel = new AddProductsViewmodel();
        data.buttonLeftDisabledFunction = () => false;
        data.buttonLeftFunction = () => this.addProductOverlayRef.close(data);
        data.buildViewmodelProductsFromProductDomainModel(
            this.productDelineationService.activeProducts,
            this.zoneRecapProducts.map((p) => p.productId)
        );
        data.hideItemNumber = true;
        data.hidePacksize = true;
        this.addProductOverlayRef = this.overlayService.open(
            AddProductsComponent,
            data,
            true
        );

        this.addProductOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.result) {
                const selectedProductIds = ref.data.result.filter((r) => r.selected && r.product).map((vm) => vm.product.id);
                await this.zoneRecapDelineationService.upsertProducts(this.zone, selectedProductIds);
                void this.search();
            }
        });
    }

    export(): void {
        const displayZone = `Zone ${this.zone ? this.zone + " " : ""}Recap`;
        const resultsWs: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            this.resultsTable.nativeElement, {
                raw: true,
                display: true
            }
        );
        const productsWs: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            this.productsTable.nativeElement, {
                raw: true,
                display: true
            }
        );
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, resultsWs, "Results");
        XLSX.utils.book_append_sheet(wb, productsWs, "Products");

        const startDate = this.startDateInput.nativeElement?.value;
        const endDate = this.endDateInput.nativeElement?.value;
        XLSX.writeFile(wb, `${displayZone} ${startDate}${endDate ? " thru " + endDate : ""}.xlsx`);
    }

    async print(): Promise<void> {
        let rtn = "";
        const pdf = new jsPDF('p', 'mm', 'a4', true);

        await pdf.html(this.recap?.nativeElement, {
            margin: [5, 5, 12, 12],
            html2canvas: { logging: false, scale: 0.1 }
        }).then(() => {
            rtn = pdf.output("dataurlstring");
        });

        if (rtn) {
            Helper.addIFrameImage(document, [rtn]);
        }
    }

    async search(): Promise<void> {

        if (this.employee && this.refinerService.areDefaultsSet && !this.isSearching) {
            this.isSearching = true;
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
            const requestId = newSequentialId();
            const response = await this.zoneRecapDelineationService.getRecap(this.zone, requestId, 50, 0, this.refinerService.refiners);
            if (!response) {
                this.shouldWait$.next(false);
                this.isSearching = false;
                return;
            }
            this.zoneRecap = response.values;
            this.buildDisplayViewmodel();
            this.shouldWait$.next(false);
            this.isSearching = false;
        }
    }

    reset(): void {
        this.refinerService.areDefaultsSet = false;
        this.setDefaultFilter();
        this.search();
    }

    async setFilterData(): Promise<void> {
        const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(
            `${this.zone}00`
        );

        if (zrtsResponse && zrtsResponse?.values) {
            this.zrtFilterService.employeeZrts = zrtsResponse.values;
            this.zrtFilterService.byArea = false;
        }

        this.setDefaultFilter();
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.setDefaultDates(new Date());
            const isEmployeeCSOrAdmin = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            const findNodeMethod = isEmployeeCSOrAdmin ? ZoneRecapReportZrtFilterService.findNodeByZrt : ZoneRecapReportZrtFilterService.findNodeById;
            let selected: SearchZrtDropDown = findNodeMethod(this.zrtFilterService.zrts, isEmployeeCSOrAdmin ? '1900' : this.employee.id);
            if (selected) {
                this.zrtFilterService.selectedZrts = ZoneRecapReportZrtFilterService.addSelectedZrts(new Array<SearchZrtDropDown>(), selected);
                const refiner = new Refiner();
                refiner.location = RefinerLocation.zrtByEmployee;
                refiner.dataValue = selected.id;
                refiner.value = selected.displayValue;
                refiner.dataPropertyName = "employeeId";
                this.refinerService.checkAndUpdateRefiner(refiner, true, true);
                if (!this.zrtFilterService.defaultZrtSelection.length) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.areDefaultsSet = true;
        }
    }

    unsubscribe(): void {
        if (
            this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        this.zrtFilterService.selectedZrts = [];
        this.refinerService.areDefaultsSet = false;
    }

    buildDisplayViewmodel(): void {
        this.resultsCallInformationHeaders = new Array<string>();
        this.resultsCallInformationHeaders.push("Call Information");
        this.resultsDaysWorked = new Array<string | number>();
        this.resultsDaysWorked.push("Days Worked");
        this.resultsRetailCalls = new Array<string | number>();
        this.resultsRetailCalls.push("Retail Calls");
        this.resultsCallAverageIncludingResetDays = new Array<string | number>();
        this.resultsCallAverageIncludingResetDays.push("Call Average - Includes Reset Days");
        this.resultsCallAverageExcludingResetDays = new Array<string | number>();
        this.resultsCallAverageExcludingResetDays.push("Call Average - Excludes Reset Days");
        this.resultsTop300CalledOnOnce = new Array<string | number>();
        this.resultsTop300CalledOnOnce.push("Top 300 Called on Once");
        this.resultsTop300CalledOnTwiceOrMore = new Array<string | number>();
        this.resultsTop300CalledOnTwiceOrMore.push("Top 300 Called on Twice or More");
        this.resultsRetailAgreementsHeaders = new Array<string>();
        this.resultsRetailAgreementsHeaders.push("Retail Agreements");
        this.resultsNew = new Array<string | number>();
        this.resultsNew.push("New");
        this.resultsUnderContract = new Array<string | number>();
        this.resultsUnderContract.push("Under Contract");
        this.resultsCanceled = new Array<string | number>();
        this.resultsCanceled.push("Canceled");
        this.resultsDollarsPaidOut = new Array<string | number>();
        this.resultsDollarsPaidOut.push("Dollars Paid Out");
        this.resultsProductsHeaders = new Array<string>();
        this.resultsProductsHeaders.push("Products");
        let productMap = new Map<string, string>();
        const zoneRecapProductInDistMap = new Map<string, ZoneRecapProductInDist>();

        const filteredZrts = this.zoneRecap.zrts.filter(v => v.zrt != "Total");
        const zoneRecapZrts = filteredZrts.length > 1
            ? this.zoneRecap.zrts
            : filteredZrts;

        for (const zoneRecapZrt of zoneRecapZrts) {
            this.resultsCallInformationHeaders.push(zoneRecapZrt.zrt);
            this.resultsDaysWorked.push(zoneRecapZrt.results.daysWorked);
            this.resultsRetailCalls.push(zoneRecapZrt.results.retailCalls);
            this.resultsCallAverageIncludingResetDays.push(zoneRecapZrt.results.callAverageIncludesResetDays.toFixed(2));
            this.resultsCallAverageExcludingResetDays.push(zoneRecapZrt.results.callAverageExcludesResetDays.toFixed(2));
            this.resultsTop300CalledOnOnce.push(zoneRecapZrt.results.top300CalledOnOnce);
            this.resultsTop300CalledOnTwiceOrMore.push(zoneRecapZrt.results.top300CalledOnTwiceOrMore);
            this.resultsRetailAgreementsHeaders.push(zoneRecapZrt.zrt);
            this.resultsNew.push(zoneRecapZrt.results.new);
            this.resultsUnderContract.push(zoneRecapZrt.results.underContract);
            this.resultsCanceled.push(zoneRecapZrt.results.canceled);
            this.resultsDollarsPaidOut.push(zoneRecapZrt.results.dollarsPaidOut.toLocaleString('en-US', {style: 'currency', currency: 'USD'}));
            this.resultsProductsHeaders.push(zoneRecapZrt.zrt);
            for (const product of zoneRecapZrt.productsInDist) {
                zoneRecapProductInDistMap.set(zoneRecapZrt.zrt + product.productId, product);
                if (!productMap.has(product.productId)) {
                    productMap.set(product.productId, product.description);
                }
            }
        }

        const zoneRecapProducts = new Array<ZoneRecapProduct>();
        for (const id of productMap.keys()) {
            const zoneRecapProduct = new ZoneRecapProduct();
            zoneRecapProduct.productId = id;
            zoneRecapProduct.description = productMap.get(id);
            for (const zoneRecapZrt of zoneRecapZrts) {
                const zoneProductStoreCount = new ZoneRecapProductStoreCount();
                zoneProductStoreCount.zrt = zoneRecapZrt.zrt;
                if (zoneRecapProductInDistMap.has(zoneRecapZrt.zrt + id)) {
                    const productData = zoneRecapProductInDistMap.get(zoneRecapZrt.zrt + id);
                    zoneProductStoreCount.storeCount = productData.storeCountWithProductInDist
                } else {
                    zoneProductStoreCount.storeCount = 0;
                }
                zoneRecapProduct.zoneRecapProductStoreCount.push(zoneProductStoreCount);
            }
            zoneRecapProducts.push(zoneRecapProduct);
        }
        this.zoneRecapProducts = zoneRecapProducts;
    }

    checkZmRoleOrHigher(): boolean {
        return Helper.isEmployeeAboveZmRole(this.employee);
    }


    /*
     * PRIVATE methods
     */

    private greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;
            const momentStart: Moment = control.value as Moment;
            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            }
            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    private lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;
            const momentEnd: Moment = control.value as Moment;
            if (momentEnd) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            }
            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    private setDefaultDates(start: Date): void {
        const firstDay = Helper.getFirstDayOfWeek(start);
        this.startDateDefault = firstDay ? moment(firstDay) : null;

        this.dateForm.controls.startDate.setValue(this.startDateDefault);
        this.dateForm.controls.endDate.setValue(null);

        this.onAddStartDateRefiner();
        this.onAddEndDateRefiner();
    }

    private setZone(): void {
        this.zone = "19";
    }

    private checkValidity(): void {
        this.dateForm.controls.startDate.updateValueAndValidity();
        this.dateForm.controls.endDate.updateValueAndValidity();
    }
}

import { ElementRef } from "@angular/core";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import moment from "moment";
import { ContractPaymentStatuses } from "shield.shared";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { RetailIncentiveRetailerViewModel } from "src/app/contracts/retail-incentive-agreement/retail-incentive-retailer.viewmodel";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Signature } from "src/app/entity-models/signature.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { CustomerContractPaymentConverterService } from "src/app/services/converter-services/customer-contract-payment-converter.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ContractPaymentDelineationService } from "src/app/services/delineation-services/contract-payment-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { SyncService } from "src/app/services/sync.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { PaymentStatusComponent } from "../payment-status/payment-status.component";
import { PaymentStatusViewModel } from "../payment-status/payment-status.viewmodel";
import { ViewAgreementLineItemViewModel } from "./view-agreement-line-item.viewmodel";

export class ViewAgreementViewModel extends BasicDialogViewModel {
    private overlayService: OverlayService;
    private contactDelineationService: ContactDelineationService;
    private contractPaymentDelineationService: ContractPaymentDelineationService;
    private customerContractDelineationService: CustomerContractDelineationService;
    private employee: Employee;

    customerContract: CustomerContract;
    contacts: ContactViewModel[];
    completedOrCancelled = true;
    retailer: RetailIncentiveRetailerViewModel;
    paymentVms = new Array<ViewAgreementLineItemViewModel>();
    paymentTotal = 0;
    agreement: ElementRef;

    faEnvelope: IconDefinition = faEnvelope;
    faPrint: IconDefinition = faPrint;

    shareEmployees: Employee[];
    shareComments: string;

    paymentStatusOverlayRef: SwisherOverlayRef<
        PaymentStatusViewModel,
        PaymentStatusComponent
    >;
    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    constructor(
        customerContract: CustomerContract,
        overlayService: OverlayService,
        employee: Employee,
        contactDelineationService: ContactDelineationService,
        contractPaymentDelineationService: ContractPaymentDelineationService,
        customerContractDelineationService: CustomerContractDelineationService,
        private syncService: SyncService,
    ) {
        super();
        this.overlayService = overlayService;
        this.employee = employee;
        this.contactDelineationService = contactDelineationService;
        this.contractPaymentDelineationService = contractPaymentDelineationService;
        this.customerContractDelineationService = customerContractDelineationService;

        this.customerContract = customerContract;
        if (!this.customerContract.customerContractPayments) {
            this.customerContract.customerContractPayments = new Array<CustomerContractPayment>();
        }

        void this.buildRetailerAndPaymentViewModels();

        this.headerLeftText = "View Agreement";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonLeftText = "Close";
        this.width = "85vw";
        this.backgroundColor = "white";
    }

    async buildRetailerAndPaymentViewModels(): Promise<void> {

        let paymentTotal = 0;
        const response = await this.contactDelineationService.getByCustomerId(this.customerContract.customerId);
        if (!response) { return; }

        const contacts = response.values;

        const retailerName = contacts.find((c) => c.id === this.customerContract.customerContactId);
        this.retailer = {
            ...this.customerContract,
            signatureDate: moment(this.customerContract.createdUtcDateTime).format(MY_DATE_FORMATS.display.monthDayYearInput),
            swisherName: Helper.formatDisplayName(this.customerContract),
            customerContactName: retailerName.firstName + " " + retailerName.lastName,
            customerContactSignature: this.customerContract.customerSignature?.image ?? ""
        };

        this.paymentVms = new Array<ViewAgreementLineItemViewModel>();

        const payments = this.customerContract.customerContractPayments.sort((a, b) =>
            a.createdUtcDateTime > b.createdUtcDateTime ? 1 : -1
        );

        const myResponse = await this.contractPaymentDelineationService.getContractPaymentSignatures(this.customerContract);
        if (!myResponse) { return; }

        const paymentSignatures = myResponse.values;

        this.customerContract.customerContractPayments.forEach(
            (p) => p.customerContactSignature = paymentSignatures.find((s) => s && s.id === p.id)
        );

        this.completedOrCancelled = this.checkCompletedOrCancelled();

        for (const payment of payments) {
            const paymentVm = new ViewAgreementLineItemViewModel();
            const paymentRep = contacts.find((c) => c.id === payment.customerContactId);
            paymentVm.status = payment.contractPaymentStatus.description;
            paymentVm.paymentDate = moment(payment.createdUtcDateTime).format(MY_DATE_FORMATS.display.dateInput);
            paymentVm.paymentAmount = payment.paymentAmount ?? 0;
            paymentVm.rep = paymentRep
                ? paymentRep.firstName + " " + paymentRep.lastName
                : "";
            paymentVm.contractPayment = payment;
            paymentVm.isEditable = !payment.gratisId;

            paymentTotal += paymentVm.paymentAmount;
            this.paymentVms.push(paymentVm);
        }
        this.paymentTotal = paymentTotal;
    }

    checkCompletedOrCancelled(): boolean {
        return !!(this.customerContract.customerContractPayments.map((p) => p.contractPaymentStatus)
            .filter(
                (p) =>
                    p.id === ContractPaymentStatuses.Completed ||
                    p.id === ContractPaymentStatuses.Canceled
            ).length);
    }

    async email(): Promise<void> {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.shareEmployees;
        data.confirmButtonText = "Share Contract";
        data.comments = this.shareComments;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.shareComments = closeData.comments;
            this.shareEmployees = closeData.shareEmployees;
        }
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<void> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            let image = await this.generateContractPdfDataUrl(
                this.agreement?.nativeElement
            );
            if (image) {
                image = image.replace(/^[^,]+,/, '');
                image = image.replace(/\s/g, '')
                for (const employeeId of ids) {
                    await this.customerContractDelineationService.emailContract(employeeId, image, this.shareComments);
                }
            }
        }
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );
    }

    async generateContractPdfDataUrl(htmlElement: HTMLElement): Promise<string> {
        let rtn = "";
        const pdf = new jsPDF('p', 'mm', 'a4', true);

        await pdf.html(htmlElement, {
            margin: [5, 5, 12, 12],
            html2canvas: { logging: false, scale: 0.2 }
        }).then(() => {
            rtn = pdf.output("dataurlstring");
        });

        return rtn;
    }

    openPaymentStatus(paymentStatus?: CustomerContractPayment, view?: boolean): void {
        const data = new PaymentStatusViewModel(
            paymentStatus ?? new CustomerContractPayment(),
            this.contacts,
            view,
            this.customerContractDelineationService,
            this.overlayService
        );
        data.width = "45vw";
        if (!view) {
            data.buttonRightFunction = () => {
                data.signaturePad.penColor = "black";
                data.paymentStatusFormGroup.markAllAsTouched();
                data.paymentStatusFormGroup.controls["statusControl"].markAllAsTouched();
                if (data.isSaveDisabled()) {
                    return;
                }
                data.isConfirmed = true;
                this.paymentStatusOverlayRef.close(data);
            }
        } else {
            data.contractNumber = this.customerContract.number;
            data.swisherRep = Helper.formatDisplayName(this.customerContract);
        }
        this.paymentStatusOverlayRef = this.overlayService.open(
            PaymentStatusComponent,
            data,
            true
        );
        this.paymentStatusOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.isConfirmed) {
                if (!this.customerContract.customerContractPayments) {
                    this.customerContract.customerContractPayments = new Array<CustomerContractPayment>();
                }
                if (!paymentStatus) {
                    const payment = ref.data.payment;
                    payment.createdUtcDateTime = new Date();
                    payment.createdUserId = this.employee.id;
                    payment.createdUserZrt = this.employee.zrt;
                    payment.createdUserFullName = this.employee.fullName;
                    payment.customerContractId = this.customerContract.id;
                    payment.customerContactId = ref.data.contact.id;
                    payment.customerContactName = ref.data.contact.firstName + " " + ref.data.contact.lastName;

                    payment.customerContactSignature = new Signature();
                    payment.customerContactSignature.id = payment.id;
                    payment.customerContactSignature.image = ref.data.signaturePad.toDataURL();

                    payment.contractPaymentStatus = ref.data.status;
                    payment.reason = ref.data.reason;
                    payment.paymentAmount = ref.data.amount;
                    payment.contractPaymentMethod = ref.data.method;
                    payment.contractPaymentType = ref.data.type;

                    this.customerContract.customerContractPayments.push(payment);

                    const signatureBlob = await (await fetch(ref.data.payment.customerContactSignature?.image)).blob();
                    const dto = CustomerContractPaymentConverterService.customerContractPaymentToCustomerContractPaymentDto(ref.data.payment, signatureBlob);

                    const result = await this.contractPaymentDelineationService.upsertCustomerContractPayment(dto);
                    if (!result) {
                        return;
                    } else if (result.values) {
                        this.customerContract.modifiedUtcDateTime = new Date();
                        await this.customerContractDelineationService.persist(this.customerContract, DexieTableNames.customerContracts);
                    }

                } else {
                    const payment = ref.data.payment;
                    payment.modifiedUtcDateTime = new Date();
                    payment.modifiedUserId = this.employee.id;
                    payment.modifiedUserZrt = this.employee.zrt;
                    payment.modifiedUserFullName = this.employee.fullName;
                    payment.customerContactId = ref.data.contact.id;
                    payment.customerContactName = ref.data.contact.firstName + " " + ref.data.contact.lastName;

                    payment.customerContactSignature = new Signature();
                    payment.customerContactSignature.id = payment.id;
                    payment.customerContactSignature.image = ref.data.signaturePad.toDataURL();

                    payment.contractPaymentStatus = ref.data.status;
                    payment.reason = ref.data.reason;
                    payment.paymentAmount = ref.data.amount;
                    payment.contractPaymentMethod = ref.data.method;
                    payment.contractPaymentType = ref.data.type;

                    this.customerContract.customerContractPayments.splice(
                        this.customerContract.customerContractPayments.indexOf(paymentStatus), 1, payment
                    );

                    const signatureBlob = await (await fetch(ref.data.payment.customerContactSignature?.image)).blob();
                    // const formData = new FormData();
                    // formData.append("file", signatureBlob);
                    const dto = CustomerContractPaymentConverterService.customerContractPaymentToCustomerContractPaymentDto(ref.data.payment, signatureBlob);

                    const result = await this.contractPaymentDelineationService
                        .upsertCustomerContractPayment(dto);
                    if (result) {
                        await this.customerContractDelineationService
                            .persist(this.customerContract, DexieTableNames.customerContracts);
                    }
                }
                this.syncService.forceOutboundSync();

                this.buildRetailerAndPaymentViewModels();
            }
        });
    }

    async print(): Promise<void> {
        const image = await this.generateContractPdfDataUrl(
            this.agreement?.nativeElement
        );
        if (image) {
            Helper.addIFrameImage(document, [image]);
        }
    }
}

import { DirectOrderDetailsDto } from "shield.shared";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";

export class DirectOrderDetailsViewModel extends BasicDialogViewModel {
    headerLeftText = "Order Details";
    buttonLeftText = "OK";
    buttonLeftDisabledFunction = () => false;
    buttonLeftFunction = () => {};
    height = "80vh";
    width = "75vw";

    constructor(public details: DirectOrderDetailsDto) {
        super();
    }
}
import { NgModule } from "@angular/core";
import { RouterModule, Routes, mapToCanActivate } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { CanActivateDirectOrderGuard } from "../guards/can-activate-direct-order.guard";
import { DirectOrderComponent } from "./direct-order/direct-order/direct-order.component";
import { OrderTrackingComponent } from "./direct-order/order-tracking/order-tracking.component";
import { QuickOrderComponent } from "./direct-order/quick-order/quick-order.component";
import { MissingReceiptsComponent } from "./missing-receipts/missing-receipts.component";
import { Px3ImportComponent } from "./px3-import/px3-import/px3-import.component";
import { WholesalerGroupsListComponent } from "./wholesaler-groups/wholesaler-groups-list.component";
import { ZoneRecapReportComponent } from "./zone-recap-report/zone-recap-report.component";

export const routes: Routes = [
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "resources/wholesaler-groups",
        component: WholesalerGroupsListComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "resources/zone-recap-report",
        component: ZoneRecapReportComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "resources/px3-import",
        component: Px3ImportComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "resources/missing-receipts",
        component: MissingReceiptsComponent
    },
    {
        canActivate: [MsalGuard, CanActivateDirectOrderGuard],
        path: "resources/direct-order",
        component: DirectOrderComponent,
        children: [
            {
                path: "tracking",
                component: OrderTrackingComponent,
            },
            {
                path: "quick-order",
                component: QuickOrderComponent,
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [CanActivateDirectOrderGuard],
})
export class ResourcesRoutingModule { }

<ng-container *ngIf="vm">
<mat-card appearance="outlined" class="mt-3">
    <div class="row">
        <div class="col-lg-12 col-sm-12">
            <div class="card grid-item mb-3">
                <div class="card-header">
                    Call Entry
                </div>
                <mat-card appearance="outlined" class="no-top-padding">
                    <div class="p-1">
                        <mat-card appearance="outlined" class="inner-card">
                            <div class="row small-top-padding">
                                <div class="col-lg-1 col-sm-6">
                                    <h2 class="m-teal"><b>Order Entry</b></h2>
                                </div>
                                <div class="col button-bottom-margin">
                                    <button appThrottleClick *ngIf="!injectedData" class="btn sw-red-btn btn-sm" type="button"
                                        (throttledClick)="addProduct()">
                                        ADD PRODUCTS
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <table *ngIf="
                                    chainHqApplicationService.chainHqCallOrderProductViewModels &&
                                    chainHqApplicationService.chainHqCallOrderProductViewModels.length > 0
                                        " class="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Description</th>
                                                <th scope="col"></th>
                                                <th scope="col">Unit QTY</th>
                                                <th scope="col"># Sticks/Units</th>
                                                <th scope="col">Store Count</th>
                                                <th scope="col">Total Sticks</th>
                                                <th scope="col">Distribution Date</th>
                                                <th scope="col">Wholesaler</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                    let orderProduct of chainHqApplicationService.chainHqCallOrderProductViewModels;
                                                    let i = index
                                                ">
                                                <td class="icon-width">
                                                    <button [disabled]="!!injectedData"
                                                        class="btn btn-outline-danger text-decoration-none btn-sm"
                                                        (click)="
                                                            removeOrderProduct(
                                                                orderProduct
                                                            )
                                                        " title="Remove Product">
                                                        <span class="flaticon-delete"></span>
                                                    </button>
                                                </td>
                                                <td>
                                                    {{
                                                    orderProduct?.product
                                                    ?.description
                                                    }}
                                                </td>
                                                <td class="icon-width">
                                                    <button [disabled]="!!injectedData"
                                                        class="btn btn-outline-info btn-sm" (click)="
                                                            copyOrderProduct(
                                                                orderProduct
                                                            )
                                                        " title="Copy Product Metadata">
                                                        <span class="material-icons">content_copy</span>
                                                    </button>
                                                </td>
                                                <td class="counter-fixed-width">
                                                    <div class="input-group counter-control">
                                                        <div class="input-group-prepend">
                                                            <button [disabled]="!!injectedData" tabindex="-1"
                                                                class="btn btn-outline-secondary btn-sm" type="button"
                                                                (click)="
                                                                    incrementOrderProductQuantity(
                                                                        orderProduct
                                                                    )
                                                                ">
                                                                <span class="material-icons">add</span>
                                                            </button>
                                                        </div>
                                                        <input [disabled]="!!injectedData" type="text" #orderProductQty
                                                            (mouseup)="select(orderProductQty)" (blur)="
                                                                validationOrderProductQuantity(
                                                                    orderProduct
                                                                )
                                                            " class="form-control form-control-sm mw-7rem-form"
                                                            mask="000000" aria-label="Example text with button addon"
                                                            [(ngModel)]="
                                                                orderProduct.quantity
                                                            " />
                                                        <div class="input-group-append">
                                                            <button [disabled]="!!injectedData" tabindex="-1"
                                                                class="btn btn-outline-secondary btn-sm" type="button"
                                                                (click)="
                                                                    decrementOrderProductQuantity(
                                                                        orderProduct
                                                                    )
                                                                ">
                                                                <span class="material-icons">remove</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="counter-fixed-width">
                                                    <div class="input-group counter-control">
                                                        <div class="input-group-prepend">
                                                            <button [disabled]="!!injectedData" tabindex="-1"
                                                                class="btn btn-outline-secondary btn-sm" type="button"
                                                                (click)="
                                                                    incrementOrderProductUnit(
                                                                        orderProduct
                                                                    )
                                                                ">
                                                                <span class="material-icons">add</span>
                                                            </button>
                                                        </div>
                                                        <input [disabled]="!!injectedData" type="text" #orderProductUnit
                                                            (mouseup)="select(orderProductUnit)" (blur)="
                                                                validationOrderProductUnit(
                                                                    orderProduct
                                                                )
                                                            " class="form-control form-control-sm mw-7rem-form"
                                                            mask="000000" aria-label="Example text with button addon"
                                                            [(ngModel)]="
                                                                orderProduct.units
                                                            " />
                                                        <div class="input-group-append">
                                                            <button [disabled]="!!injectedData" tabindex="-1"
                                                                class="btn btn-outline-secondary btn-sm" type="button"
                                                                (click)="
                                                                    decrementOrderProductUnit(
                                                                        orderProduct
                                                                    )
                                                                ">
                                                                <span class="material-icons">remove</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="total-sticks-fixed-width dotted-right-border">
                                                    <input [disabled]="!!injectedData" type="text"
                                                        #orderProductStoreCount
                                                        (mouseup)="select(orderProductStoreCount)"
                                                        (blur)="validationStoreCount(orderProduct)"
                                                        class="form-control form-control-sm mw-7rem-form" mask="000000"
                                                        aria-label="Example text with button addon" [(ngModel)]="
                                                                orderProduct.storeCount
                                                            " />
                                                </td>
                                                <td class="total-sticks-fixed-width">
                                                    {{
                                                    orderProduct.quantity *
                                                    orderProduct.units *
                                                    orderProduct.storeCount
                                                    }}
                                                </td>
                                                <td>
                                                    <div class="mt-3 mb-3">
                                                        <mat-form-field appearance="outline" class="w-100"
                                                            color="accent">
                                                            <mat-label>Select a distribution date...</mat-label>
                                                            <input matInput [placeholder]="dateFormat" [min]="today"
                                                                [matDatepicker]="distributionDatePicker"
                                                                [formControl]="orderProduct.formControl"
                                                                [value]="orderProduct.formControl.value"
                                                                (dateChange)="saveOrderProduct(orderProduct)" />
                                                            <mat-datepicker touchUi #distributionDatePicker>
                                                            </mat-datepicker>
                                                            <mat-datepicker-toggle matSuffix
                                                                [for]="distributionDatePicker"></mat-datepicker-toggle>
                                                        </mat-form-field>
                                                        <mat-error *ngIf="
                                                            orderProduct.formControl.invalid
                                                        ">
                                                            Date must be a valid date for today or in the future.
                                                        </mat-error>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="!injectedData; else wholesalerReadonly">
                                                        <angular2-multiselect
                                                            [data]="orderProduct.wholesalers"
                                                            [(ngModel)]="orderProduct.wholesaler"
                                                            [settings]="vm.wholeSalerDropdownSettings"
                                                            (onDeSelect)="onWholsalerSelectionChange(orderProduct)"
                                                            (onSelect)="onWholsalerSelectionChange(orderProduct)"
                                                            (onDeSelectAll)="onWholsalerSelectionChange(orderProduct)">
                                                        </angular2-multiselect>
                                                    </ng-container>
                                                    <ng-template #wholesalerReadonly>
                                                        {{ orderProduct.wholesaler?.length ? orderProduct.wholesaler[0].displayValue : "None" }}
                                                    </ng-template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item bottom mb-3">
                <div class="card-header">Activities and Surveys</div>
                <div class="card-body no-scroll p-0">
                    <div *ngIf="vm">
                        <div class="inner-margin">
                            <app-activities-surveys [isReadOnly]="!!injectedData" [isPanel]="true" [call]="call">
                            </app-activities-surveys>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="card grid-item bottom mb-3">
                <div class="card-header">Call Comments</div>
                <div class="card-body p-0">
                    <div class="card-view-port comment-height">
                        <div *ngIf="vm">
                            <div *ngIf="!injectedData" class="row">
                                <div class="col">
                                    <mat-form-field appearance="outline" color="accent" class="call-note-form-field">
                                        <mat-label id="notes">Notes</mat-label>
                                        <textarea [disabled]="!!injectedData" cdkTextareaAutosize="false"
                                            matAutosizeMaxRows="12"
                                            (blur)="callService.saveClosingNotes(vm.closingNotes)"
                                            [(ngModel)]="vm.closingNotes" rows="6" type="text" matInput
                                            maxlength="4000">
                                    </textarea>
                                        <mat-hint align="end">{{
                                            vm.closingNotes?.length || 0
                                            }}/4000
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="injectedData" class="col">
                                {{ vm.closingNotes }}
                            </div>
                            <div *ngIf="!injectedData" class="row">
                                <div class="col center">
                                    <button appThrottleClick mat-raised-button class="btn sw-green-btn btm-sm actionBtn"
                                        (throttledClick)="onOpenConfirmation()">
                                        <mat-icon style="display: inline">check_circle_outline</mat-icon>
                                        Save
                                    </button>
                                    <button appThrottleClick [disabled]="!!injectedData" mat-raised-button
                                        class="btn sw-teal-btn btn-sm actionBtn" (throttledClick)="onOpenShare()">
                                        Share
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
</ng-container>

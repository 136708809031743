import {
    AfterContentChecked,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { CallPicturesRefinerService } from "./call-pictures-refiner.service";
import { CallPicturesViewmodel } from "./call-pictures.viewmodel";
import { MatTable } from "@angular/material/table";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { Router } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { CallPicturesZrtFilterService } from "./call-pictures-zrt-filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { CallDateInformationFilterComponent } from "src/app/shared/filters/call-date-information-filter/call-date-information-filter.component";
import { CallPicturesActivitiesFilterComponent } from "src/app/shared/filters/call-pictures-activities-filter/call-pictures-activities-filter.component";
import { CallPicturesAttributesFilterComponent } from "src/app/shared/filters/call-pictures-attributes-filter/call-pictures-attributes-filter.component";
import { PictureModalComponent } from "src/app/dialogs/pop-out-img-dialog/img-dialog.component";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-call-pictures",
    templateUrl: "./call-pictures.component.html",
    styleUrls: ["./call-pictures.component.scss"],
    providers: [PleaseWaitService]
})
export class CallPicturesComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class =
        "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: MatTable<GridData>;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;

    // Public vars
    viewmodel: CallPicturesViewmodel;
    isViewmodelReady = false;
    screenHeight: number;
    slides: any
    pictureTag: string = ''
    pictureType: string = ''
    type: string = 'Before'
    picArr: any = []
    typeArr: any = []
    tagArr: any = []

    constructor(
        private refinerService: CallPicturesRefinerService,
        private formBuilder: UntypedFormBuilder,
        private callDelineationService: CallDelineationService,
        private snackbar: SnackbarService,
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService,
        private router: Router,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private zrtFilterService: CallPicturesZrtFilterService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private activitiesFilterService: ActivitiesFilterService,
        private projectDelineationService: ProjectDelineationService,
        private overlayService: OverlayService,
        private dialog: MatDialog,
        private px3RankService: Px3DelineationService
    ) {}

    openPictureInModal(picture: object, allData: object) {
        this.dialog.open(PictureModalComponent, {
            data: {picture: picture, allData: this.type},
            autoFocus: false,
            maxHeight: "80vh",
            width: "800px"
        });
    }
    async ngOnInit(): Promise<void> {
        this.viewmodel = new CallPicturesViewmodel(
            this.refinerService,
            this.formBuilder,
            this.callDelineationService,
            this.snackbar,
            this.pleaseWaitService,
            this.appStateService,
            this.router,
            this.employeeDelineationService,
            this.filterService,
            this.zrtFilterService,
            this.accountOwnershipDelineationService,
            this.activitiesFilterService,
            this.projectDelineationService,
            this.overlayService,
            this.px3RankService
            );

        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: MySearchesFilterComponent });
        tempFilters.push({
            filter: CustomerLocationFilterComponent,
            zrtFilterService: this.zrtFilterService
        });
        tempFilters.push({ filter: CallDateInformationFilterComponent });
        tempFilters.push({ filter: CallPicturesActivitiesFilterComponent });
        tempFilters.push({ filter: CallPicturesAttributesFilterComponent });

        this.viewmodel.filters = tempFilters;
    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (
            !this.isViewmodelReady &&
            this.grid &&
            this.header &&
            this.sideNavContent &&
            this.drawer &&
            this.startDateInput &&
            this.endDateInput
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.sort,
                this.startDateInput,
                this.endDateInput
            );
        }
    }

    setPictureType(index: number, element: any): void {
        this.typeArr[element.index] = this.viewmodel.gridData[element.index].data.pictures[index].type || 'N/A'
        this.type = this.typeArr[element.index]
        this.tagArr[element.index] = this.viewmodel.gridData[element.index].data.pictures[index].tags || 'N/A'
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener("window:resize", ["$event"])
    getScreenSize(event?: any): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }
}

import { Refiner } from "src/app/entity-models/refiner.entity";
import { OrderListViewmodel } from "./order-list.viewmodel";
import { FilterSortDto, GenericResponseDto, OrderListColumns } from "shield.shared";
import { Order } from "src/app/entity-models/order.entity";

export class OrderListNondirectViewModel extends OrderListViewmodel {
    columnDef = [
        {
            headerName: OrderListColumns.callNumber,
            dataPropertyName: "callId",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: OrderListColumns.customerId,
            dataPropertyName: "retailNumber",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.orderStatus,
            dataPropertyName: "orderStatus",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.callDate,
            dataPropertyName: "callDate",
            isAvailable: true,
            isSelected: true,
            clickFunction: this.onOpenOrderDetails()
        },
        {
            headerName: OrderListColumns.orderDate,
            dataPropertyName: "orderDate",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.storeInformation,
            dataPropertyName: "storeInformation",
            isAvailable: true,
            isSelected: true,
            clickFunction: this.onRouteToProfile()
        },
        {
            headerName: OrderListColumns.wholesalerCode,
            dataPropertyName: "wholesalerCustomerCode",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.wholesaler,
            dataPropertyName: "wholesalerName",
            isAvailable: true,
            isSelected: true
        },
        {
            headerName: OrderListColumns.wholesalerCustomerId,
            dataPropertyName: "wholesalerId",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: OrderListColumns.chain,
            dataPropertyName: "chainName",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: "Order Number",
            dataPropertyName: "orderNumber",
            isAvailable: true,
            isSelected: false
        },
        {
            headerName: "Orderlineitems",
            dataPropertyName: "orderLineItems",
            isAvailable: false,
            isSelected: true
        }
    ];
    protected getBatchApiCall(
        id: string, 
        refiners: Refiner[], 
        pageSize: number, 
        startIndex: number, 
        filterSorts: FilterSortDto<OrderListColumns>[]
    ): Promise<GenericResponseDto<Order[]>> {
        return this.orderDelineationService.getBatch(
            id, 
            refiners, 
            pageSize, 
            startIndex, 
            filterSorts,
        );
    }
}
import { Component, HostListener, OnInit } from "@angular/core";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { DirectionsDialogViewModel } from "./directions-dialog.viewmodel";
import {
    faInfoCircle,
    faLocationArrow,
    faMapMarkerAlt,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { Address } from "src/app/entity-models/address.entity";
import { FilterService } from "src/app/services/filter.service";
import { State } from "src/app/entity-models/state.entity";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "app-directions-dialog",
    templateUrl: "./directions-dialog.component.html",
    styleUrls: ["./directions-dialog.component.scss"]
})
export class DirectionsDialogComponent implements OnInit {
    private filterService: FilterService;

    faInfoCircle: IconDefinition = faInfoCircle;
    faLocationArrow: IconDefinition = faLocationArrow;
    faMapMarkerAlt: IconDefinition = faMapMarkerAlt;
    states: State[];
    checkingAddress = false;
    startLocationError = false;
    endLocationError = false;
    vm: DirectionsDialogViewModel;

    constructor(
        private ref: SwisherOverlayRef<
            DirectionsDialogViewModel,
            DirectionsDialogComponent
        >,
        filterService: FilterService
    ) {
        this.filterService = filterService;
    }

    async ngOnInit(): Promise<void> {
        this.states = await this.filterService.getStates();

        this.vm = this.ref.data;
        this.vm.startLocation = this.vm.startFirstStopLocation;
        this.vm.endLocation = this.vm.endLastStopLocation;
        this.adjustStartNewLocation(this.vm.startLocation);
        this.adjustEndNewLocation(this.vm.endLocation);
    }

    adjustStartNewLocation(newAddress: Address): void {
        if(this.vm.startNewLocation !== newAddress) {
            this.setAddresses(this.vm.startNewLocation, newAddress)
        }
    }

    adjustEndNewLocation(newAddress: Address): void {
        if(this.vm.endNewLocation !== newAddress) {
            this.setAddresses(this.vm.endNewLocation, newAddress);
        }
    }

    isSaveDisabled(): boolean {
        return (this.checkingAddress || this.startLocationError || this.endLocationError);
    }

    shouldHideStart(): boolean {
        if (this.validateAddresses(this.vm.startLocation, this.vm.startFirstStopLocation) ||
            this.vm.startPrevLocations.find((start) => this.validateAddresses(this.vm.startLocation, start))) {
                return true;
            }
        return false;
    }

    shouldHideEnd(): boolean {
        if (this.validateAddresses(this.vm.endLocation, this.vm.endLastStopLocation) ||
            this.vm.endPrevLocations.find((end) => this.validateAddresses(this.vm.endLocation, end))) {
                return true;
            }
        return false;
    }

    setAddresses(a: Address, b: Address) {
        a.address1 = b.address1;
        a.address2 = b.address2;
        a.city    =  b.city;
        a.state  =   b.state;
        a.zip   =    b.zip;
    }

    setEditedAddress(a: Address, b: Address): Address {
        const temp = new Address;
        if(a.address1 !== b.address1) {
            temp.address1 = a.address1;
            a.address1 = b.address1;
            b.address1 = temp.address1;
        }
        if(a.address2 !== b.address2) {
            temp.address2 = a.address2;
            a.address2 = b.address2;
            b.address2 = temp.address2;
        }
        if(a.city !== b.city) {
            temp.city = a.city;
            a.city = b.city;
            b.city = temp.city;
        }
        if(a.state !== b.state) {
            temp.state = a.state;
            a.state = b.state;
            b.state = temp.state;
        }
        if(a.zip !== b.zip) {
            temp.zip = a.zip;
            a.zip = b.zip;
            b.zip = temp.zip;
        }

        return b;
    }

    validateAddresses(a: Address, b: Address) {
        return a.address1 === b.address1 &&
               a.address2 === b.address2 &&
               a.city    ===  b.city     &&
               a.state  ===   b.state    &&
               a.zip   ===    b.zip;
    }

    async validateAddressExists(address: Address, endLocation?: boolean): Promise<void> {
        this.checkingAddress = true;

        const geocodedAddress = address.address1
            + (address.address2 ? " " + address.address2 : "")
            + " " + address.city
            + " " + address.state
            + " " + address.zip;
        const request: google.maps.GeocoderRequest = {
            address: geocodedAddress
        };
        const results = await lastValueFrom(this.vm.geocoder.geocode(request));
        if (results) {
            endLocation
                ? this.endLocationError = results[0].partial_match
                : this.startLocationError = results[0].partial_match;
        }
        this.checkingAddress = false;
    }

    validateIfStartIsNew(): void {
        if (this.validateAddresses(this.vm.startLocation, this.vm.startFirstStopLocation)) {
            this.vm.startLocation = this.vm.startFirstStopLocation
        }
        else {
            const prevStart = this.vm.startPrevLocations.find((start) =>
                this.validateAddresses(this.vm.startLocation, start)
            );
            if(prevStart) {
                this.vm.startLocation = prevStart;
            }
        }

        this.vm.startLocation = this.setEditedAddress(this.vm.startLocation, this.vm.startNewLocation);
    }

    validateIfEndIsNew(): void {
        if (this.validateAddresses(this.vm.endLocation, this.vm.endLastStopLocation)) {
            this.vm.endLocation = this.vm.endLastStopLocation
        }
        else {
            const prevEnd = this.vm.endPrevLocations.find((end) =>
                this.validateAddresses(this.vm.endLocation, end)
            );
            if(prevEnd) {
                this.vm.endLocation = prevEnd;
            }
        }

        this.vm.endLocation = this.setEditedAddress(this.vm.endLocation, this.vm.endNewLocation);
    }

    close(): void {
        this.ref.close(this.ref.data);
    }

    saveClick(): void {
        this.ref.data.onSave(this.ref.data);
        this.ref.close(this.ref.data);
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.saveClick()
            }
        }
}

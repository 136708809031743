<div class="card card-project-panel m-0 d-flex flex-column flex-grow-1">
    <div class="card-header">People / Assignments</div>
    <div class="card-body un-scrollable d-flex flex-column flex-grow-1">
        <ng-container>
            <div class="row d-flex flex-column flex-grow-1">
                <div 
                    *ngIf="projectStateService.project?.projectEmployees?.length" 
                    class="col-12 table-responsive d-flex flex-column flex-grow-1"
                >
                    <app-grid #grid
                        [(dataSource)]="viewmodel.dataSource"
                        [columnDefinitions]="viewmodel?.columnDef"
                        [selectedColumnVisability]="viewmodel?.selectedColumnVisibilityComunicator"
                        [isFixedLayout]="false" [height]="'30vh'"
                        [isEnabledExpandedDetails]="false"
                        [itemsRendedInViewPort]="6"
                        [detailHeight]="48"
                        class="d-flex flex-column flex-grow-1">
                    </app-grid>
                </div>
                <div *ngIf="(!projectStateService.project?.projectEmployees?.length)" class="margin-auto">
                    <b class="missing-data-warning">Project assignments will not be visible until stores and employees have been added and associated.</b>
                </div>
            </div>
        </ng-container>
    </div>
</div>

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AccountBatchParamsDto,
    AccountFilterDto,
    CustomerSyncCommand,
    CustomerTypeEnum,
    SharedHelper
} from "shield.shared";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { Helper } from "src/app/helpers/helper";
import { Customer } from "../../entity-models/customer.entity";
import { DatabaseService } from "../database.service";
import { DataSyncQueueService } from "src/app/sync/data-sync-queue.service";

@Injectable()
export class CustomerOfflineService {

    constructor(
        private dbService: DatabaseService,
        private dataSyncQueueService: DataSyncQueueService
    ) { }

    private _customerStore: Customer[] = [];

    resetCustomerStore() {
        this._customerStore = [];
    }

    updateCustomerStore(customer: Customer) {
        let idx = this._customerStore?.findIndex(c => c.id == customer.id);
        if (idx > -1) {
            this._customerStore[idx] = customer;
        } else {
            console.log('Customer not found in mem store', this._customerStore, customer)
        }
        return;
    }

    private async applyFilter(customers: Customer[], filter: AccountFilterDto): Promise<Customer[]> {
        if (!filter) return customers;
        customers = customers.filter((c) => {

            if (filter.ids) {
                if (!SharedHelper.searchStringArray(c.id, filter.ids)) {
                    return false;
                }
            }
            if (filter.customerNumbers) {
                if (!SharedHelper.searchStringArray(c.customerNumber, filter.customerNumbers)) {
                    return false;
                }
            }
            if (filter.zrtFilterDto?.zrts) {
                if (!filter.zrtFilterDto.zrts.includes(c.zrt)) {
                    return false;
                }
            }
            if (filter.account) {
                if (!(
                    SharedHelper.searchStringArray(c.name, filter.account) ||
                    SharedHelper.searchStringArray(c.customerNumber, filter.account) ||
                    SharedHelper.searchStringArray(c.id, filter.account)
                )) {
                    return false;
                }
            }
            if (filter.businessAddressFilterDto?.street) {
                if (!SharedHelper.searchStringArray(c.businessAddress?.address1, filter.businessAddressFilterDto.street)) {
                    return false;
                }
            }
            if (filter.businessAddressFilterDto?.city) {
                if (!SharedHelper.searchStringArray(c.businessAddress?.city, filter.businessAddressFilterDto.city)) {
                    return false;
                }
            }
            if (filter.businessAddressFilterDto?.counties) {
                if (!SharedHelper.searchStringArray(c.businessAddress?.county, filter.businessAddressFilterDto.counties)) {
                    return false;
                }
            }
            if (filter.businessAddressFilterDto?.states) {
                if (!SharedHelper.searchStringArray(c.businessAddress?.state, filter.businessAddressFilterDto.states)) {
                    return false;
                }
            }
            if (filter.businessAddressFilterDto?.zipCode) {
                if (!SharedHelper.searchString(c.businessAddress?.zip, filter.businessAddressFilterDto.zipCode)) {
                    return false;
                }
            }
            if (filter.callOnOrAfterDate) {
                if (!(c.lastCall && c.lastCall.getTime() >= new Date(filter.callOnOrAfterDate).setHours(0, 0, 0, 0))) {
                    return false;
                }
            }
            if (filter.callOnOrBeforeDate) {
                if (!(c.lastCall && c.lastCall.getTime() <= new Date(filter.callOnOrBeforeDate).setHours(23, 59, 59, 0))) {
                    return false;
                }
            }
            if (filter.availability) {
                if (filter.availability.includes('N')) {
                    if (c.availability) return false;
                }
                else if (!c.availability || !filter.availability.find(v => c.availability.includes(v))) {
                    return false;
                }
            }
            if (filter.accountOwnerCodes) {
                if (!filter.accountOwnerCodes.includes(c.ownerCode?.trim())) {
                    return false;
                }
            }
            if (filter.customerTypeIds?.length) {
                if (!filter.customerTypeIds.filter((ct) => ct === c.customerType.id).length) {
                    return false;
                }
            }
            if (filter.isMsa !== undefined) {
                if (!c.isMsa == filter.isMsa) {
                    return false;
                }
            }
            if (filter.hasPhone !== undefined) {
                if (!c.hasPhone == filter.hasPhone) {
                    return false;
                }
            }
            if (filter.isActive !== undefined) {
                if (!c.isActive == filter.isActive) {
                    return false;
                }
            }
            if (filter.volume !== undefined) {
                //For this filter property, true indicates to filter to all positive industry volumes.
                //Otherwise if false, pull all negative/zero volumes.
                return filter.volume ? c.industryVolume > 0 : c.industryVolume <= 0;
            }
            if (filter.px3Rank !== undefined && filter.px3Rank.length > 0) {
                if (c.px3RankId) {
                    if (!filter.px3Rank.includes(c.px3RankId)) {
                        return false;
                    }
                } else if (!filter.px3Rank.includes('Not Ranked')) {
                    return false;
                }
            }

            return true;
        });

        // Activities needs context for other tables
        if (filter.projectIds) {
            const projects = await this.dbService.projects.where("id").anyOf(filter.projectIds).toArray();
            customers = customers.filter((c) =>
                projects.filter((p) =>
                    p.projectCustomers.map((pc) => pc.customerId).includes(c.id)
                ).length > 0
            );
        }
        if (filter.wholesalerIds) {
            customers = customers.filter(
                (c) => c.customerWholesalers
                    .map(cw => cw.wholesalerId)
                    .some(v => filter.wholesalerIds.includes(v))
            );
        }
        if (filter.productIds || filter.productIdsInDist || filter.productIdsNotInDist) {
            const callDates = customers.map(v => v.lastCall).filter(v => !!v);
            const lastCalls = (await this.dbService.calls
                .where("stopTime")
                .anyOf(callDates)
                .toArray() ?? []) as RetailCall[];
            customers = customers.filter((c) => {
                const inDistIds = lastCalls.find(lc => lc.customerId === c.id)?.inDistProductIds;
                return filter.productIdsNotInDist
                    ? (filter.productIdsNotInDist ?? []).filter((pIds) =>
                        (inDistIds ?? []).some(v => pIds.includes(v))
                    ).length == 0
                    : (filter.productIdsInDist ?? []).filter((pIds) =>
                        (inDistIds ?? []).some(v => pIds.includes(v))
                    ).length > 0
            });
        }

        if (!!filter.callable?.length) {
            let filteredCustomers = new Array<Customer>();
            for (const callableOption of filter.callable ?? []) {
                filteredCustomers = filteredCustomers.concat(customers.filter((customer) =>
                    customer.isCallable === callableOption.callable
                    && customer.isCallableOverridden === callableOption.callableOverridden))
            }
            customers = [...new Set(filteredCustomers)];
        }

        return customers;
    }

    async getCustomerByCustomerNumber(
        customerNumber: string
    ): Promise<Customer> {
        return await this.dbService.customers
            .where("customerNumber")
            .equals(customerNumber)
            .first();
    }

    async getDirectWholesalers(): Promise<Customer[]> {
        return this.dbService.customers
            .where("customerType.id")
            .equals(CustomerTypeEnum.DirectWholesaler)
            .toArray();
    }

    async getCustomersByTypes(customerTypes: CustomerTypeEnum[]): Promise<Customer[]> {

        let wholesalers = new Array<Customer>();
        for (const customerType of customerTypes) {
            const batch = await this.dbService.customers.where("customerType.id").equals(customerType).toArray();
            wholesalers = wholesalers.concat(batch);
        }
        wholesalers.sort((a, b) => a.name.localeCompare(b.name));

        return wholesalers;
    }

    async getById(id: string): Promise<Customer> {
        // return id ? await this.dbService.customers.where("id").equals(id)?.first() : null;
        let ret = null;
        if (id) {
            let resp = await this.dbService.customers.where("id").equals(id)?.first()
            if (!resp) {
                throw new Error('BYPASS');
            } else {
                ret = resp;
            }
        }
        return ret;
    }

    async getByIds(ids: string[]): Promise<Customer[]> {
        const rtn = ids?.length ? await this.dbService.customers.bulkGet(ids) : [];
        return rtn.filter((v) => !!v);
    }

    async upsertCustomer(customer: Customer): Promise<Customer> {
        if (customer) {
            customer.hasServerProcessed = 0;
            await this.dbService.transaction(
                "rw",
                this.dbService.customers,
                this.dbService.syncQueue,
                async () => {
                    await this.dbService.customers.put(customer);
                    await this.dataSyncQueueService.enqueue(
                        new CustomerSyncCommand(customer.id)
                    );
                }
            );
        }
        return customer;
    }

    async getWholesalersbyProductId(productId: string): Promise<Customer[]> {

        const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems.where("productId").equals(productId).toArray();
        if (wholesalerProductCatalogItems.length) {
            const wholesalerIds = wholesalerProductCatalogItems.map((g) => g.wholesalerId);
            return await this.getByIds(wholesalerIds);

        }
        return new Array<Customer>();
    }

    async getByCustomerTypes(
        customerTypes: CustomerTypeEnum[]
    ): Promise<Customer[]> {
        return await this.dbService.customers
            .where("customerType.id")
            .anyOf(customerTypes)
            .toArray();
    }

    async getCustomersByOwnerCode(key: string): Promise<Customer[]> {
        return this.dbService.customers.where("ownerCode").equals(key).toArray();
    }

    dedupe(array: Customer[]): Array<Customer> {
        let ret = array.filter((value, index, self) =>
            index === self.findIndex((t) => (
                t.id === value.id
            ))
        )
        return ret
    }

    async getBatch(
        params: AccountBatchParamsDto,
    ): Promise<Customer[]> {
        if (this._customerStore?.length) {
            console.log('getting from store')
            return await this.applyFilter(this._customerStore, params.filterRequestDto.filters);
        } else {
            console.log('getting from db')
            let customers = new Array<Customer>();
            let zrtCustomers = await this.dbService.customers
                .where("isZrtAssignment")
                .equals(1)
                .toArray();
            let pCustomers = await this.dbService.customers
                .where("isProjectAssignment")
                .equals(1)
                .toArray();
            let saCustomers = await this.dbService.customers
                .where("isSpecialAssignment")
                .equals(1)
                .toArray();

            customers = this.dedupe([...zrtCustomers, ...pCustomers, ...saCustomers]);

            const chains = await this.dbService.accountOwnerships.toArray();
            customers.forEach((c) => c.chain = chains.find((ch) => ch.ownerCode === c.ownerCode?.trim())?.name ?? "");

            customers.sort((a, b) => a.lastEdited > b.lastEdited ? -1 : 1);
            this._customerStore = customers;
            return await this.applyFilter(customers, params.filterRequestDto.filters);
        }
    }

    getCustomerListExport(
        params: AccountBatchParamsDto
    ): Observable<Blob | never> {
        throw ("You must be online to export the customer list.");
    }

    async getWholesalersWithGroupProducts(): Promise<Customer[]> {
        let rtn = new Array<Customer>();
        const wholesalerGroupProductCatalogItems = 
            await this.dbService.wholesalerGroupProductCatalogItems
                .where("isAvailable")
                .equals(0).
                toArray();
        const groupIds = 
            [...new Set(wholesalerGroupProductCatalogItems.map((wgpci => wgpci.wholesalerGroupId)))];
        if (groupIds?.length) {
            const wholesalerGroupMembers = await this.dbService.wholesalerGroupMembers
                .where("wholesalerGroupId")
                .anyOf(groupIds)
                .toArray();
            const wholesalerIds = [...new Set(wholesalerGroupMembers.map((wgm) => wgm.wholesalerId))];
            if (wholesalerIds?.length) {
                const ids = wholesalerIds.map((ia) => ia.toString());
                rtn = await this.getByIds(ids);
            }
        }
        return rtn;
    }

    async getWholesalersInWholesalerGroups(key: undefined): Promise<Customer[]> {

        const wholesalerGroupMembers = await this.dbService.wholesalerGroupMembers
            .toArray();
        const distinctWholesalerIds = [...new Set(wholesalerGroupMembers.map((member) => member.wholesalerId))];

        return await this.dbService.customers.where("id").anyOf(distinctWholesalerIds).toArray();
    }

    async getWholesalersForCustomer(customer: Customer): Promise<Customer[]> {

        const wholesalerId = (customer.customerWholesalers ?? []).map((w) => w.wholesalerId);

        const distinctWholesalerIds = [...new Set(wholesalerId)];

        return await this.dbService.customers.where("id").anyOf(distinctWholesalerIds).toArray();
    }

    async getMarkersByEmployeeId(employeeId: string): Promise<CustomerMarker[]> {
        return;
    }

    async getMarkersByFilters(key: AccountBatchParamsDto): Promise<CustomerMarker[]> {
        throw ("You must be online to access the route map.");
    }

    async getCustomerCallableStatus(customerId: string): Promise<boolean> {
        const syncedCustomer = await this.dbService.customers.where("id").equals(customerId).first();

        if (!syncedCustomer) return false;

        let isStartedProjectAssignment = false;
        if (syncedCustomer.isProjectAssignment == 1) {
            const isWholesaler = (syncedCustomer.customerType.id == CustomerTypeEnum.DirectWholesaler ||
                syncedCustomer.customerType.id == CustomerTypeEnum.IndirectWholesaler);
            const activeProjects = await this.dbService.projects.where("startDate").below(new Date())
            .and(v =>
                (isWholesaler
                    ? v.projectProducts.some(pp => pp.wholesalerId == customerId)
                    : v.projectCustomers.some(pc => pc.customerId == customerId)
                )
            )
            .count();
            isStartedProjectAssignment = activeProjects > 0;
        }

        return ((syncedCustomer.isZrtAssignment == 1) || (syncedCustomer.isSpecialAssignment == 1) || isStartedProjectAssignment);
    }
}

<div class="card m-0 h-100">
    <div class="card-header">
        MSA Information
    </div>
    <div class="card-body">
        <h2 class="text-teal border-bottom border-teal">Store</h2>
        <p><strong>Total Cigar Volume:</strong> {{ (volumeData.summary$ | async)?.total | number }}</p>
        <p><strong>Total Swisher Volume:</strong> {{ (volumeData.summary$ | async)?.swisherTotal | number }}</p>
        <ng-container class="chart-container" *ngIf="(volumeData.summary$ | async)">
            <ngx-charts-pie-chart [results]="(volumeData.summary$ | async).manufacturerTotals" [view]="[425, 270]" [scheme]="" [scheme]="colorScheme"
                                  [legend]="showLegend" [legendPosition]="legendPosition" [labels]="showLabels">
            </ngx-charts-pie-chart>
            <ng-container *ngFor="let entry of (volumeData.summary$ | async).manufacturerTotals; index as i">
                <div class="ml-4 mr-4">
                    <span class="marker" [ngStyle]="{'border': '6px solid ' + colorScheme.domain[i]}" ></span>
                    <strong> {{ entry.name }}</strong>
                    {{ entry.value | number }} = {{ entry.value / (volumeData.summary$ | async).total | percent:'1.0-2' }}
                    <br />
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>

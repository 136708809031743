import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatCarouselModule } from "@magloft/material-carousel";
import { SharedModule } from "../shared/shared.module";
import { CallHistoryRefinerService } from "./call-history/call-history-refiner.service";
import { CallHistoryZrtFilterService } from "./call-history/call-history-zrt.filter.service";
import { CallHistoryComponent } from "./call-history/call-history.component";
import { CallPicturesRefinerService } from "./call-pictures/call-pictures-refiner.service";
import { CallPicturesZrtFilterService } from "./call-pictures/call-pictures-zrt-filter.service";
import { CallPicturesComponent } from "./call-pictures/call-pictures.component";
import { CopyProjectModalComponent } from "./copy-project-modal/copy-project-modal.component";
import { DailySummaryReportRefinerService } from "./daily-summary-report/daily-summary-report-refiner.service";
import { DailySummaryReportZrtFilterService } from "./daily-summary-report/daily-summary-report-zrt-filter.service";
import { DailySummaryReportComponent } from "./daily-summary-report/daily-summary-report.component";
import { DetailsRoutingModule } from "./details.routing.module";
import { NewProjectModalComponent } from "./new-project-modal/new-project-modal.component";
import { ProductDistributionReportRefinerService } from "./product-distribution-report/product-distribution-report-refiner.service";
import { ProductDistributionReportZrtFilterService } from "./product-distribution-report/product-distribution-report-zrt-filter.service";
import { ProductDistributionReportComponent } from "./product-distribution-report/product-distribution-report.component";
import { ProjectFilterService } from "./project-list/project-filter.service";
import { ProjectListRefinerService } from "./project-list/project-list-refiner.service";
import { ProjectListZrtFilterService } from "./project-list/project-list-zrt-filter.service";
import { ProjectListComponent } from "./project-list/project-list.component";
import { ProjectStoresZrtFilterService } from "./project/project-configuration/project-stores/project-stores-zrt-filter.service";
import { ProjectAssignmentsZrtFilterService } from "./project/project-configuration/project-assignments/project-assignments-zrt-filter.service";
import { ProjectMetricsRefinerService } from "./project/project-metrics/project-metrics-refiner.service";
import { ProjectMetricsZrtFilterService } from "./project/project-metrics/project-metrics-zrt-filter.service";
import { ProjectModule } from "./project/project.module";
import { RetailContractsRefinerService } from "./retail-contracts/retail-contracts-refiner.service";
import { RetailContractsZrtFilterService } from "./retail-contracts/retail-contracts-zrt-filter.service";
import { RetailContractsComponent } from "./retail-contracts/retail-contracts.component";
import { ViewContractComponent } from "./retail-contracts/view-contract/view-contract.component";

@NgModule({
    declarations: [
        CallHistoryComponent,
        CallPicturesComponent,
        DailySummaryReportComponent,
        ProductDistributionReportComponent,
        RetailContractsComponent,
        ProjectListComponent,
        ViewContractComponent,
        NewProjectModalComponent,
        CopyProjectModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MatSidenavModule,
        MatExpansionModule,
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatChipsModule,
        MatDatepickerModule,
        MatTableModule,
        DetailsRoutingModule,
        ProjectModule,
        MatCarouselModule
    ],
    providers: [
        CallHistoryRefinerService,
        CallPicturesRefinerService,
        DailySummaryReportRefinerService,
        ProductDistributionReportRefinerService,
        RetailContractsRefinerService,
        ProjectListRefinerService,
        ProjectFilterService,
        CallHistoryZrtFilterService,
        CallPicturesZrtFilterService,
        DailySummaryReportZrtFilterService,
        ProductDistributionReportZrtFilterService,
        ProjectStoresZrtFilterService,
        ProjectAssignmentsZrtFilterService,
        ProjectMetricsZrtFilterService,
        ProjectListZrtFilterService,
        RetailContractsZrtFilterService,
        ProjectMetricsRefinerService
    ],
    exports: [
        ProjectModule
    ]
})
export class DetailsModule {}

import { Component, ElementRef, Signal, ViewChild, signal } from "@angular/core";
import { RetailBaseComponent } from "../retail-format-base/retail-base.component";
import { Subsidiary } from "shield.shared";

@Component({
    selector: "app-swisher-retail-format",
    templateUrl: "./swisher-retail-format.component.html",
    styleUrls: ["./swisher-retail-format.component.scss"]
})
export class SwisherRetailFormatComponent extends RetailBaseComponent {
    require: any

    @ViewChild("originalSwisherRetailReceipt") originalSwisherRetailReceipt: ElementRef<HTMLElement>;

    printLogo = "/assets/img/SwisherPrintLogo.jpg";
    subsidiary = signal(Subsidiary.Swisher);
    constructor() {
        super();
    }
}

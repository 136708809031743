/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import "@angular/localize/init";

import { enableProdMode, StaticProvider } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import html2Canvas from "html2canvas";
import 'hammerjs';

export const getBaseUrl = (): string => {
    return document.getElementsByTagName("base")[0].href;
};

(window["html2canvas" as unknown as number] as any) = html2Canvas;

const providers: StaticProvider[] = [
    { provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .then(() => {
        if ('serviceWorker' in navigator && environment.production) {
            navigator.serviceWorker.register('ngsw-worker.js');
        }
    })
    .catch((err) => console.log(err));

import { Order } from "src/app/entity-models/order.entity";
import { OrderExtraction } from "src/app/entity-models/order-extraction.entity";
import moment from "moment";

export class OrderExtractionItemViewmodel {
    id: string;
    wholesalerId: string;
    dateExtracted: Date;
    extractedByEmployeeId: string;
    orders: Order[];

    extractionName: string;
    duplicateCount: number;


    constructor(orderExtraction: OrderExtraction){
        this.id = orderExtraction.id;
        this.wholesalerId = orderExtraction.wholesalerId;
        this.dateExtracted = orderExtraction.createdUtcDateTime;
        this.dateExtracted?.setHours(this.dateExtracted.getHours(), 0, 0, 0);
        this.extractedByEmployeeId = orderExtraction.createdUserId;
        this.orders = orderExtraction.orders;

        this.extractionName = moment(this.dateExtracted).format("M/D/YY ha");
        this.duplicateCount = 0;

    }
}



<div *ngIf="!mobile" class="d-flex flex-column flex-grow-1">
    <mat-horizontal-stepper
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        (selectionChange)="handleSelectionChange($event)"
    >
        <mat-step
            *ngFor="let step of steps; let i = index"
            [editable]="true"
            [hasError]="false"
            color="accent"
            [errorMessage]="'None'"
            state="index"
        >
            <ng-template matStepLabel>
                <div [title]="step.label">
                    {{ step.label }}
                </div>
            </ng-template>
            <ng-container [ngTemplateOutlet]="step.content"></ng-container>
        </mat-step>

        <ng-template matStepperIcon="edit">
            <mat-icon style="top: -3px">check</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
            <mat-icon color="warn">warning</mat-icon>
        </ng-template>
    </mat-horizontal-stepper>
</div>

<div *ngIf="mobile">
    <mat-vertical-stepper
        [linear]="isLinear"
        [selectedIndex]="selectedIndex"
        (selectionChange)="handleSelectionChange($event)"
    >
        <mat-step
            *ngFor="let step of steps; let i = index"
            [editable]="true"
            color="accent"
            [errorMessage]="'None'"
            state="index"
        >
            <ng-template matStepLabel>
                <div title="{{ step.label }}">
                    {{ step.label }}
                </div>
            </ng-template>
            <ng-container [ngTemplateOutlet]="step.content"></ng-container>
        </mat-step>

        <ng-template matStepperIcon="edit">
            <mat-icon style="top: -3px">check</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="error">
            <mat-icon color="warn">warning</mat-icon>
        </ng-template>
    </mat-vertical-stepper>
</div>

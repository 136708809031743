<div class="order-page mx-lg-3">
  <mat-card>
    <div class="inputs container">
      <div class="row">
        <div class="col-3">Select Wholesaler</div>
        <div class="col-4">
          <angular2-multiselect
            class="col-4"
            [data]="wholesalers$ | async"
            [(ngModel)]="selectedWholesaler"
            [settings]="wholesalerDropdownSettings"
          >
          </angular2-multiselect>
          <div class="error" *ngIf="showWholesalerError">Wholesaler is required</div>
        </div>
      </div>
      <div class="row">
        <div class="col-3">Suggested Ship Date</div>
        <mat-form-field class="col-4" appearance="outline" color="accent">
          <input matInput [matDatepicker]="picker" [(ngModel)]="shipDate" />
          <mat-datepicker touchUi #picker></mat-datepicker>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        </mat-form-field>
        <div class="error" *ngIf="showShipDateError">Suggested Ship Date is required</div>
      </div>
    </div>
  </mat-card>
  <mat-card class="inner-card m-0">
    <div class="row">
      <div class="col-12">
        <h2 class="m-teal"><b>Order Products</b></h2>
      </div>
      <div class="col-12 button-bottom-margin">
        <button appThrottleClick
          tabindex="0"
          class="btn sw-red-btn btn-sm" 
          type="button" 
          (throttledClick)="addOrderProduct()"
        >
          ADD PRODUCTS
        </button>
        <div class="error" *ngIf="showProductsError">At least one product is required</div>
      </div>
    </div>
    <div class="row">
      <div class="col overflow-auto">
        <table *ngIf="lineItems.length > 0" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col"><!-- Delete --></th>
              <th scope="col">Swisher Item Number</th>
              <th scope="col">Description</th>
              <th scope="col">Quantity</th>
              <th scope="col"># Sticks/Units</th>
              <th scope="col">Total Sticks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="
                let lineItem of lineItems;
                let i = index
              ">
              <td class="icon-width"> <!-- Delete -->
                <button tabindex="0"
                  class="btn btn-outline-danger text-decoration-none btn-sm" 
                  (click)="removeOrderProduct(i)" 
                  title="Remove Product"
                >
                  <span class="flaticon-delete"></span>
                </button>
              </td>
              <td class="item-number-fixed-width"> <!-- Swisher Item Number -->
                {{lineItem.uin}}
              </td>
              <td> <!-- Description -->
                {{lineItem.productDescription}}
              </td>
              <td class="quantity-fixed-width"> <!-- Quantity -->
                <div class="input-group counter-control">
                  <div class="input-group-prepend">
                    <button 
                      tabindex="-1"
                      class="btn btn-outline-secondary btn-sm" 
                      type="button"
                      (click)="incrementItemQuantity(lineItem)"
                    >
                      <span class="material-icons">add</span>
                    </button>
                  </div>
                  <input
                    tabindex="0"
                    type="text" 
                    #orderProductQty
                    class="form-control form-control-sm mw-7rem-form" 
                    mask="000000"
                    aria-label="Example text with button addon" 
                    [(ngModel)]="lineItem.quantity" 
                  />
                  <div class="input-group-append">
                    <button 
                      tabindex="-1"
                      class="btn btn-outline-secondary btn-sm" 
                      type="button"
                      (click)="decrementItemQuantity(lineItem)"
                    >
                      <span class="material-icons">remove</span>
                    </button>
                  </div>
                </div>
              </td>
              <td class="units-fixed-width"> <!-- # Sticks/Units -->
                {{ lineItem.unitsPerUpc }}
              </td>
              <td class="total-sticks-fixed-width"> <!-- Total Sticks -->
                {{ lineItem.quantity * lineItem.unitsPerUpc }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card>
  <mat-card class="buttons">
    <button class="btn btn-secondary" (click)="clearForm()">Reset</button>
    <button class="btn btn-primary" (click)="submitOrder()">Submit Order</button>
  </mat-card>
</div>
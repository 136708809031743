import {
    AfterContentChecked,
    Component, ElementRef,
    HostBinding, HostListener,
    Input,
    OnDestroy,
    OnInit, ViewChild,
} from "@angular/core";

import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";

import { UntypedFormBuilder } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatSelect } from "@angular/material/select";
import { Router } from "@angular/router";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import moment from "moment";
import { EmployeeRoleType } from "shield.shared";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AppStateService } from "src/app/services/app-state.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { OrderDelineationService } from "src/app/services/delineation-services/order-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { DialogService } from "src/app/services/dialog.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { OrderEditOptions } from "../order-edit-options.enum";
import { OrderEditOptionsComponent } from "../order-edit-options/order-edit-options.component";
import { OrderListDirectViewModel } from "./order-list-direct.viewmodel";
import { OrderListNondirectViewModel } from "./order-list-nondirect.viewmodel";
import { OrderListZrtFilterService } from "./order-list-zrt-filter.service";
import { OrderListViewmodel } from "./order-list.viewmodel";
import { OrderListRefinerService } from "./orders-list-refiner.service";

@Component({
    selector: "app-orders-list",
    templateUrl: "./orders-list.component.html",
    styleUrls: ["./orders-list.component.scss"],
    providers: [PleaseWaitService]
})
export class OrdersListComponent
    implements OnInit, AfterContentChecked, OnDestroy {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    // ViewChilds
    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("header") header: PageHeaderComponent;
    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;
    @ViewChild("orderDateInput") orderDateInput: ElementRef;
    @ViewChild("afterPicker") afterPicker: MatDatepicker<moment.Moment>;
    @ViewChild("wholesalerSelect") wholesalerSelect: AngularMultiSelect;
    @ViewChild("orderEditSelect") orderEditSelect: MatSelect;
    @ViewChild("orderEditOptionsComponent") orderEditOptionsComponent: OrderEditOptionsComponent;

    @Input() isDirect = false;

    // Public vars
    viewmodel: OrderListViewmodel;
    isViewmodelReady = false;
    screenHeight: number;

    constructor(
        private refinerService: OrderListRefinerService,
        private formBuilder: UntypedFormBuilder,
        private orderDelineationService: OrderDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private overlayService: OverlayService,
        private pleaseWaitService: PleaseWaitService,
        private router: Router,
        private snackbar: SnackbarService,
        private appStateService: AppStateService,
        private employeeDelineationService: EmployeeDelineationService,
        private filterService: FilterService,
        private zrtFilterService: OrderListZrtFilterService,
        private activitiesFilterService: ActivitiesFilterService,
        private callDelineationService: CallDelineationService,
        private dialogService: DialogService,
    ) { }

    async ngOnInit(): Promise<void> {
        if (this.isDirect) {
            this.viewmodel = new OrderListDirectViewModel(
                this.refinerService,
                this.formBuilder,
                this.orderDelineationService,
                this.customerDelineationService,
                this.accountOwnershipDelineationService,
                this.projectDelineationService,
                this.overlayService,
                this.pleaseWaitService,
                this.router,
                this.snackbar,
                this.employeeDelineationService,
                this.filterService,
                this.zrtFilterService,
                this.activitiesFilterService,
                this.callDelineationService,
                this.dialogService,
            );
        } else {
            this.viewmodel = new OrderListNondirectViewModel(
                this.refinerService,
                this.formBuilder,
                this.orderDelineationService,
                this.customerDelineationService,
                this.accountOwnershipDelineationService,
                this.projectDelineationService,
                this.overlayService,
                this.pleaseWaitService,
                this.router,
                this.snackbar,
                this.employeeDelineationService,
                this.filterService,
                this.zrtFilterService,
                this.activitiesFilterService,
                this.callDelineationService,
                this.dialogService,
            );
        }

        this.viewmodel.orderEditOptions = [
            OrderEditOptions.none,
            OrderEditOptions.moveRejectedProductsToNewOrder,
            OrderEditOptions.movePendingOrderProductsToNewOrder,
            OrderEditOptions.changeWholesalerItemNumber,
            OrderEditOptions.changeOrderDate
        ];

        if (!this.viewmodel.orderDateSubscription || this.viewmodel.orderDateSubscription.closed) {
            this.viewmodel.orderDateSubscription = this.viewmodel.dateForm.controls.orderDate.valueChanges.subscribe((changedDate) => {
                this.viewmodel.orderDate = changedDate;
            })
        }

        if (!this.viewmodel.employeeSubscription || this.viewmodel.employeeSubscription.closed) {
            this.viewmodel.employeeSubscription = this.appStateService.currentEmployee.subscribe(async (employee) => {
                this.viewmodel.employee = employee;
                await this.viewmodel.setFilterData();
                if (employee?.employeeRoles) {
                    if (employee.employeeRoles
                        .map((r) => r.employeeRoleType.id)
                        .find((id) => id === EmployeeRoleType.CS || id === EmployeeRoleType.CorpAdmin)
                    ) {
                        this.viewmodel.isEnabledOrderEdit = true;
                    }
                }
                this.viewmodel.getOrderBatch(0);
            })
        }

    }

    ngAfterContentChecked(): void {
        if (this.isViewmodelReady && !this.viewmodel.isGapSet) {
            this.viewmodel.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.header
            && this.sideNavContent
            && this.drawer
            && this.startDateInput
            && this.endDateInput
            && this.wholesalerSelect
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            void this.viewmodel.initialize(
                this.grid,
                this.header,
                this.sideNavContent,
                this.drawer,
                this.startDateInput,
                this.endDateInput,
                this.wholesalerSelect,
                this.orderEditSelect,
                this.orderEditOptionsComponent
            );
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        if (this.viewmodel) {
            this.viewmodel.screenHeight = window.innerHeight;
        }
    }

    onOrderEditSelectChange() {
        if (this.viewmodel.selectedWholesalers?.length === 1) {
            this.viewmodel.selectedWholesaler = this.viewmodel.selectedWholesalers[0];
        } else {
            this.viewmodel.selectedWholesaler = null;
        }
        setTimeout(() => {
            this.updateViewChildren();
            this.viewmodel.onOrderEditSelectChange();
        }, 0);
    }

    rowSelectionChanged(): void {
        if (this.orderEditOptionsComponent?.viewmodel?.dataSource?.data?.filter((gridData) => gridData.data?.isSelected).length === 0) {
            this.viewmodel.isSaveDisabled = true;
        } else {
            this.viewmodel.isSaveDisabled = false;
        }
    }

    save() {
        setTimeout(() => {
            this.updateViewChildren();
            this.viewmodel.save();
        }, 0);

    }

    updateViewChildren() {
        if (this.viewmodel) {
            this.viewmodel.orderEditOptionsComponent = this.orderEditOptionsComponent;
            this.viewmodel.orderEditSelect = this.orderEditSelect;
        }
    }
}

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import moment from "moment";
import { Subscription } from "rxjs";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ProjectOrderDate } from "src/app/entity-models/project-order-date.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ProjectProductOrderDatesComponent } from "./project-product-order-dates.component";
import { ProjectProductSelectViewmodel } from "../project-product-select.viewmodel";

export class ProjectProductOrderDatesViewmodel extends BasicDialogViewModel {

    orderDateOne: moment.Moment;
    orderDateTwo: moment.Moment;
    orderDateThree: moment.Moment;
    orderDateFour: moment.Moment;

    orderDateOneValueChangesSubscription: Subscription;
    orderDateTwoValueChangesSubscription: Subscription;
    orderDateThreeValueChangesSubscription: Subscription;
    orderDateFourValueChangesSubscription: Subscription;

    orderDateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    formBuilder: UntypedFormBuilder;
    futureDatedProducts: Array<ProjectProductSelectViewmodel>;
    overlayRef: SwisherOverlayRef<ProjectProductOrderDatesViewmodel, ProjectProductOrderDatesComponent>;

    constructor(formBuilder: UntypedFormBuilder, futureDatedProducts: Array<ProjectProductSelectViewmodel>){
        super();

        this.formBuilder = formBuilder;
        this.futureDatedProducts = futureDatedProducts;

        this.orderDateForm = this.formBuilder.group({
            orderDateOneControl: [""],
            orderDateTwoControl: [""],
            orderDateThreeControl: [""],
            orderDateFourControl: [""],
        })

        this.headerLeftText = "Order Dates";
        this.showFooter = true;
        this.buttonLeftText = "Cancel";
        this.buttonLeftFunction = () => this.overlayRef.close(this);
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightText = "Submit";
        this.buttonRightFunction = () => {
            this.isConfirmed = true;
            this.overlayRef.close(this);
        }
        this.buttonRightDisabledFunction = () => {
            let rtn = false;
            if (this.orderDateForm.invalid) {
                rtn = true;
            }
            return rtn;
        }

        this.width = "50vw";
        this.height = "50vh";
    };

    initialize(projectOrderDates?: ProjectOrderDate[]): void {
        //set subscriptions
        if (!this.orderDateOneValueChangesSubscription || this.orderDateOneValueChangesSubscription.closed) {
            this.orderDateOneValueChangesSubscription = this.orderDateForm.controls.orderDateOneControl.valueChanges.subscribe((date: moment.Moment) => {
                if ((date && date.isValid) || date === null) {
                    this.orderDateOne = date;
                }
            });
        }
        if (!this.orderDateTwoValueChangesSubscription || this.orderDateTwoValueChangesSubscription.closed) {
            this.orderDateTwoValueChangesSubscription = this.orderDateForm.controls.orderDateTwoControl.valueChanges.subscribe((date: moment.Moment) => {
                if ((date && date.isValid) || date === null) {
                    this.orderDateTwo = date;
                }
            });
        }
        if (!this.orderDateThreeValueChangesSubscription || this.orderDateThreeValueChangesSubscription.closed) {
            this.orderDateThreeValueChangesSubscription = this.orderDateForm.controls.orderDateThreeControl.valueChanges.subscribe((date: moment.Moment) => {
                if ((date && date.isValid) || date === null) {
                    this.orderDateThree = date;
                }
            });
        }
        if (!this.orderDateFourValueChangesSubscription || this.orderDateFourValueChangesSubscription.closed) {
            this.orderDateFourValueChangesSubscription = this.orderDateForm.controls.orderDateFourControl.valueChanges.subscribe((date: moment.Moment) => {
                if ((date && date.isValid) || date === null) {
                    this.orderDateFour = date;
                }
            });
        }

        //set initial values if we have them;
        if (projectOrderDates?.length) {
            const projectOrderDateOne = projectOrderDates.find((pod) => pod.dateIndex === 1);
            if (projectOrderDateOne?.orderDate) {
                this.orderDateForm.controls.orderDateOneControl.setValue(moment(projectOrderDateOne?.orderDate));
            }
            const projectOrderDateTwo = projectOrderDates.find((pod) => pod.dateIndex === 2);
            if (projectOrderDateTwo?.orderDate) {
                this.orderDateForm.controls.orderDateTwoControl.setValue(moment(projectOrderDateTwo?.orderDate));
            }
            const projectOrderDateThree = projectOrderDates.find((pod) => pod.dateIndex === 3);
            if (projectOrderDateThree?.orderDate) {
                this.orderDateForm.controls.orderDateThreeControl.setValue(moment(projectOrderDateThree?.orderDate));
            }
            const projectOrderDateFour = projectOrderDates.find((pod) => pod.dateIndex === 4);
            if (projectOrderDateFour?.orderDate) {
                this.orderDateForm.controls.orderDateFourControl.setValue(moment(projectOrderDateFour?.orderDate));
            }
        }
    }

    unsubscribe(): void {
        if (this.orderDateOneValueChangesSubscription && !this.orderDateOneValueChangesSubscription.closed) {
            this.orderDateOneValueChangesSubscription.unsubscribe();
        }
        if (this.orderDateTwoValueChangesSubscription && !this.orderDateTwoValueChangesSubscription.closed) {
            this.orderDateTwoValueChangesSubscription.unsubscribe();
        }
        if (this.orderDateThreeValueChangesSubscription && !this.orderDateThreeValueChangesSubscription.closed) {
            this.orderDateThreeValueChangesSubscription.unsubscribe();
        }
        if (this.orderDateFourValueChangesSubscription && !this.orderDateFourValueChangesSubscription.closed) {
            this.orderDateFourValueChangesSubscription.unsubscribe();
        }
    }
}

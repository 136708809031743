import { Order } from "src/app/entity-models/order.entity";
import { OrderStatusLookup } from "shield.shared";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import moment from "moment";
import { Helper } from "src/app/helpers/helper";

export class OrderExtractionGridViewmodel {
    id: string;
    referenceId?: string;
    orderDate: string;
    orderStatusLookupId: number;
    callId?: string;
    callNumber: string | null;
    callDate?: string;
    customerId: string;
    retailNumber: string;
    customerName: string | null;
    customerAddress1: string | null;
    customerAddress2: string | null;
    customerCity: string | null;
    customerState: string | null;
    customerZip: string | null;
    wholesalerId: string;
    wholesalerName: string | null;
    chainName: string | null;
    chainNumber: string | null;
    cancelledDate: Date | null;
    cancelledBy: string | null;
    orderExtractionId: string | null;
    dateCreated: Date;
    createdBy: string;
    lastUpdated: Date;
    lastUpdatedBy: string;

    orderStatus: string;
    storeInformation: string;

    dateFormat = MY_DATE_FORMATS.display.dateInput;

    constructor(order: Order){
        this.id = order.id;
        this.referenceId = order.referenceId;
        const orderDate = moment(order.orderDate);
        this.orderDate = orderDate.format(this.dateFormat);
        this.orderStatusLookupId = order.orderStatusId;
        this.callId = order.callId;
        this.callNumber = order.callNumber;
        const callDate = moment(order.callDate);
        this.callDate = callDate.format(this.dateFormat);
        this.customerId = order.customerId;
        this.retailNumber = order.retailNumber;
        this.customerName = order.customerName;
        this.customerAddress1 = order.customerAddress1;
        this.customerAddress2 = order.customerAddress2;
        this.customerCity = order.customerCity;
        this.customerState = this.customerState;
        this.customerZip = order.customerZip;
        this.wholesalerId = order.wholesalerId;
        this.wholesalerName = order.wholesalerName;
        this.chainName = order.chainName;
        this.chainNumber = order.chainNumber;
        this.cancelledDate = order.cancelledDate;
        this.cancelledBy = order.cancelledBy;
        this.orderExtractionId = order.orderExtractionId;
        this.dateCreated = order.createdUtcDateTime;
        this.lastUpdated = order.modifiedUtcDateTime;
        this.lastUpdatedBy = Helper.formatDisplayName(order);
        this.orderStatus = OrderStatusLookup.find((lookup) => lookup.id === this.orderStatusLookupId)?.name;
        this.storeInformation = (this.customerName ?? "")
            + (this.customerAddress1 ? "; " + this.customerAddress1 : "")
            + (this.customerCity ? "; " + this.customerCity : "")
            + (this.customerState ? " " + this.customerState : "")
            + (this.customerZip ? ", " + this.customerZip : "");

    }
}



<div  *ngIf="hasEasProducts()">
    <div>
        <div class="center">
            ******Draft******Draft******Draft******Draft******Draft******Draft******
            ******Draft******Draft******Draft******Draft******Draft******Draft******
        </div>
        <div class="row prow">
            <div class="col pcol center">
                {{ customer()?.businessAddress?.name }} - Receipt #{{receiptNumber()}}
            </div>
        </div>
        <div *ngIf="customerCode()" class="row prow">
            <div class="col pcol center">
                Distributor Acct # for Retailer {{customerCode()}}
            </div>
        </div>
        <div *ngIf="stateVaporLicense()?.licenseNumber" class="row prow">
            <div class="col pcol center">
                Retail State Vapor License # {{ stateVaporLicense().licenseNumber }}
            </div>
        </div>
        <div class="row prow center">
            <div class="col pcol center">Retail State OTP License # {{stateOptLicense()?.licenseNumber ?
                stateOptLicense().licenseNumber : 'None Listed'}}</div>
        </div>
        <div class="row prow">
            <div class="col pcol center">
                {{ formattedAddress() }}
            </div>
        </div>
        <div *ngIf="customer()?.businessAddress?.county" class="row prow">
            <div class="col pcol center">{{ customer().businessAddress.county + ' County' }}</div>
        </div>
        <hr />
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">{{wholesaler().name}}</div>
        </div>
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">State OTP License ID {{wholesalerOptLicense()?.licenseNumber}}</div>
        </div>
        <div *ngIf="orders()?.length > 0">
            <div *ngFor="let order of orders()">
                <br />
                <div class="row prow">
                    <div class="col pcol center">
                        <b>ITEMS TO BE DELIVERED</b>
                    </div>
                </div>
                <div class="row prow">
                    <div class="col pcol">
                        <b>UIN</b>
                    </div>
                    <div class="col pcol center">
                        <b>Order Date {{ order.orderDate| date: dateFormat}}</b>
                    </div>
                    <div class="col pcol center">
                        <b>Qty</b>
                    </div>
                </div>

                <div *ngFor="let orderProduct of order.products" class="row prow">
                    <div class="col pcol">
                        {{ orderProduct.uin }}
                    </div>
                    <div class="col pcol center">
                        {{ orderProduct.product?.description }}
                    </div>
                    <div class="col pcol center">
                        {{ orderProduct.quantity }}
                    </div>
                </div>
                <hr />
            </div>
        </div>
        <div class="center">
            ******Draft******Draft******Draft******Draft******Draft******Draft******
            ******Draft******Draft******Draft******Draft******Draft******Draft******
        </div>
    </div>
</div>

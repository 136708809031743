import { Product } from "src/app/entity-models/product.entity";
import { FilterService } from "src/app/services/filter.service";
import { OrderLineItemStatuses, OrderLineItemStatusLookup, OrderStatuses, OrderStatusLookup, GenericLookup, GenericDropDownDto } from "shield.shared";
import { Subscription } from "rxjs";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { ElementRef } from "@angular/core";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { AccountOwnership } from "src/app/entity-models/account-ownership.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { OrderTypes } from "shield.shared";


export class OrderInformationViewmodel {
    //public vars
    filterService: FilterService;
    productDelineationService: ProductDelineationService;
    formBuilder: UntypedFormBuilder;

    dateForm: UntypedFormGroup;
    endDateDefault: moment.Moment = moment();
    orderEndDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    isProductsSearchStale = false;
    isWholesalerSearchStale = false;
    orderStatuses = new Array<GenericLookup<OrderStatuses>>();
    orderLineItemStatuses = new Array<GenericLookup<OrderLineItemStatuses>>();
    orderTypeInput: string;
    products = new Array<Product>();
    productSubscription: Subscription;
    selectedOrderStatuses = new Array<GenericLookup<OrderStatuses>>();
    selectedOrderLineItemStatuses = new Array<GenericLookup<OrderLineItemStatuses>>();
    selectedProducts = new Array<Product>();
    selectedProductsHold = new Array<Product>();
    selectedOrderTypes = new Array<string>();
    orderTypes = new Array<string>();
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    orderStartDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    uinInput: string;

    productsDropdownSettings = {
        singleSelection: false,
        text: "Products",
        unSelectAllText: "Deselect All",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 2,
        autoPosition: false,
        labelKey: "description",
        searchBy: ["description"],
        classes: "multi-select-container c-btn"
    }

    projectsSubscription: Subscription;
    projects = new Array<GenericDropDownDto>();
    selectedProjects = new Array<GenericDropDownDto>();
    selectedProjectsHold = new Array<GenericDropDownDto>();
    isProjectsStale: boolean;
    projectsDropdownSettings = {
        text: "Projects",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        noDataLabel: "Loading projects...",
        classes: "multi-select-container c-btn"
    }

    wholesalersSubscription: Subscription;
    wholesalers = new Array<Customer>();
    selectedWholesalers = new Array<Customer>();
    selectedWholesalersHold = new Array<Customer>();
    isWholesalersStale: boolean;
    wholesalersDropdownSettings = {
        text: "Wholesalers",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayName",
        searchBy: ["displayName"],
        noDataLabel: "Loading wholesalers...",
        classes: "multi-select-container c-btn"
    }

    chainsSubscription: Subscription;
    chains = new Array<AccountOwnership>();
    selectedChains = new Array<AccountOwnership>();
    selectedChainsHold = new Array<AccountOwnership>();
    isChainsStale: boolean;
    chainsDropdownSettings = {
        text: "Chains",
        singleSelection: false,
        unSelectAllText: "Deselect All",
        filterUnSelectAllText: "Deselect all filtered results",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "name",
        searchBy: ["name"],
        noDataLabel: "Loading chains...",
        classes: "multi-select-container c-btn"
    }

    constructor(
        filterService: FilterService,
        productDelineationService: ProductDelineationService,
        formBuilder: UntypedFormBuilder
    ) {
        this.filterService = filterService;
        this.productDelineationService = productDelineationService;
        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            orderEndDate: ["", []],
            orderStartDate: ["", []]
        });

        this.initializeStatusDropDowns();
    }

    initializeStatusDropDowns(): void {
        this.orderTypes.push(OrderTypes.EDI);
        this.orderTypes.push(OrderTypes.EOrder);
        this.orderTypes.push(OrderTypes.Manual);

        for(const status of OrderStatusLookup) {
            this.orderStatuses.push(status)
        }

        for(const status of OrderLineItemStatusLookup) {
            this.orderLineItemStatuses.push(status)
        }
    }
}

<div class="PageWrapper">
    <div class="d-flex">
        <ul class="header-tabs m-0 list-inline list-inline-flex">
            <li class="list-inline-item-custom" routerLinkActive="active"
                (click)="currentPage = Tracking">
                <a class="no-underline" [routerLink]="['tracking']">Order Tracking</a>
            </li>
            <li class="list-inline-item-custom" routerLinkActive="active"
                (click)="currentPage = QuickOrder">
                <a class="no-underline" [routerLink]="['quick-order']">Quick Order</a>
            </li>
        </ul>
    </div>
    <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
</div>

import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";

export class AddTimeEntryViewmodel {
    header: string;
    showFooter: boolean;
    headerLeftText: string;
    headerRightText: string;
    buttonLeftText: string;
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    buttonRightText: string;
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    width: string;
    height: string;
    isConfirmed = false;
    hasErrors = false;

    editingTimeEntry?: TimeEntry;
    existingDailyTimeLogs: TimeEntry[] = [];
    selectedTimeEntryType: TimeEntryType;
    timeEntryTypes: TimeEntryType[] = [];
    start?: Date;
    end?: Date;
    comments?: string;
    selectedDate: Date;
}

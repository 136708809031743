<div class="container">
    @if (hasRequiredValues()) {
        <p>There are {{count()}} remaining missing receipts</p>
        <mat-slide-toggle [checked]="generateReceipts()" (change)="generateReceipts.set($event.checked)">
            {{generateReceipts() ? 'Start' : 'Stop'}} Generating Receipts
        </mat-slide-toggle>
        <div class="loading" *ngIf="nextReceiptLoading()">
            <img src="assets/img/shield-vector-logo.svg"/>
            <p>Next Receipt Loading.</p>
        </div>
        <mat-card appearance="outlined" class="d-flex flex-column flex-grow-1 card">
        @if (isWholesalerEAS()) {
            <app-eas-wholesaler-format class="capture" #easWholesaler
                [customer]="customer()"
                [wholesaler]="wholesaler()"
                [call]="call()"
                [employee]="employee()"
                [contact]="contact()"
                [receiptNumberExtention]="receiptNumberExtention()"
                [activeProducts]="activeProducts()"
            ></app-eas-wholesaler-format>
        }        
        @if (isWholesalerSwisher()) {
            <app-swisher-wholesaler-format class="capture" #swisherWholesaler
                [customer]="customer()"
                [wholesaler]="wholesaler()"
                [call]="call()"
                [employee]="employee()"
                [contact]="contact()"
                [receiptNumberExtention]="receiptNumberExtention()"
                [activeProducts]="activeProducts()"
            ></app-swisher-wholesaler-format>
        }
        @if (isRetailEAS()) {
            <app-eas-retail-format class="capture" #easRetail
                [customer]="customer()"
                [call]="observableCall"
                [employee]="employee()"
                [contact]="contact()"
                [receiptNumberExtention]="receiptNumberExtention()"
                [activeProducts]="activeProducts()"
            ></app-eas-retail-format>
        } 
        @if (isRetailSwisher()) {
            <app-swisher-retail-format class="capture" #swisherRetail
                [customer]="customer()"
                [call]="observableCall"
                [employee]="employee()"
                [contact]="contact()"
                [receiptNumberExtention]="receiptNumberExtention()"
                [activeProducts]="activeProducts()"
            ></app-swisher-retail-format>
        }
        </mat-card>
    } @else {
        @if (error()) {
            <p>There was an error while generating missing receipts. Please refresh and try again.</p>
            <p>{{error()}}</p>
        } @else if (nextReceiptLoading()) {
            <div class="loading">
                <img src="assets/img/shield-vector-logo.svg"/>
                <p>Loading Missing Receipts</p>
            </div>
        } @else if (!nextReceiptLoading() && count() === 0) {
            <p>There are no missing receipts to generate.</p>
        }
    }
</div>
<div *ngIf="customer && hasReceipts && stepperCallApplicationService.callOrderProductViewModels">
    <mat-card appearance="outlined" class="inner-card">
        <div class="row">
            <div class="col-12 mb-3 mb-xl-0 row header">
                <div class="col-12 col-xl-3 s-teal-color">
                    <b>Wholesale</b>
                </div>
                <div class="col-12 col-xl-9">
                    <mat-form-field class="address-dropdown" appearance="outline" color="accent">
                        <mat-label>Wholesale Address</mat-label>
                        <mat-select [(value)]="selectedWholesaler">
                            <mat-option *ngFor="let option of addressOptions" [value]="option">
                                {{ option.name }} - {{ option.businessAddress?.city ? option.businessAddress?.city :
                                option.dbaAddress?.city }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div id="wholesale-receipt">
            @for (wholesaler of wholesalers; track wholesaler.id;) {
                @if (isWholesaleDraft || isWholesaleFinal || selectedWholesaler?.id === wholesaler.id) {
                    @if(isWholesaleDraft) {
                        <app-swisher-wholesaler-format-draft #wholesaleDraft
                            [activeProducts]="activeProducts$|async"
                            [call]="call"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            [wholesaler]="wholesaler"
                            [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                        >
                        </app-swisher-wholesaler-format-draft>
                        @if(isEasEmployee) {
                            <app-eas-wholesaler-format-draft #wholesaleDraft 
                                [activeProducts]="activeProducts$|async"
                                [call]="call"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                [wholesaler]="wholesaler"
                                [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                            >
                            </app-eas-wholesaler-format-draft>
                        } @else {
                            <app-eas-wholesaler-swisher-rep-format-draft #wholesaleDraft
                                [activeProducts]="activeProducts$|async"
                                [call]="call"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                [wholesaler]="wholesaler"
                                [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                            >
                            </app-eas-wholesaler-swisher-rep-format-draft>
                        }
                    } @else {
                        <app-swisher-wholesaler-format #swisherWholesaleFormat
                            [activeProducts]="activeProducts$|async"
                            [call]="call"
                            [signature]="signature"
                            [contact]="selectedContact"
                            [customer]="customer"
                            [employee]="employee"
                            [wholesaler]="wholesaler"
                            [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                        >
                        </app-swisher-wholesaler-format>
                        @if (isEasEmployee) {
                            <app-eas-wholesaler-format #easWholesaleFormat
                                [activeProducts]="activeProducts$|async"
                                [call]="call"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                [wholesaler]="wholesaler"
                                [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                            >
                            </app-eas-wholesaler-format>
                        } @else {
                            <app-eas-wholesaler-swisher-rep-format #easWholesaleFormat
                                [activeProducts]="activeProducts$|async"
                                [call]="call"
                                [signature]="signature"
                                [contact]="selectedContact"
                                [customer]="customer"
                                [employee]="employee"
                                [wholesaler]="wholesaler"
                                [receiptNumberExtention]="wholesaler.receiptNumberExtention"
                            >
                            </app-eas-wholesaler-swisher-rep-format>
                        }
                    }
                }
            }
        </div>
    </mat-card>
</div>

<ng-container *ngIf="viewModel$|async; let vm">
    <ng-container *ngIf="vm.isCallable">
        <div *ngIf="vm.callType===retail">
            <app-retail-call 
                #retail 
                (blockCall)="blockCall()" 
                (cancelCall)="cancelCall()"
            ></app-retail-call>
        </div>
        <div *ngIf="vm.callType===wholesale">
            <app-wholesale-call #wholesale (blockCall)="blockCall()" (cancelCall)="cancelCall()"></app-wholesale-call>
        </div>
        <div *ngIf="vm.callType===chainHq">
            <app-chain-hq-call #chain (blockCall)="blockCall()" (cancelCall)="cancelCall()"></app-chain-hq-call>
        </div>
        <div *ngIf="vm.callType===rmWholesale">
            <app-rm-wholesale-call #rmWholesale (blockCall)="blockCall()" (cancelCall)="cancelCall()"></app-rm-wholesale-call>
        </div>
    </ng-container>
</ng-container>

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Picture } from "src/app/entity-models/picture.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CallTypes, PictureParamDto, SortDirection } from "shield.shared";
import { PictureCarouselViewModel } from "../../account.viewmodels/picture-carousel.viewmodel";
import { PictureViewModel } from "../../account.viewmodels/picture.viewmodel";
import { Call } from "../../call-master/call-services/call.service";
import { PictureComponent } from "../../picture/picture.component";
import { CallHistoryAccountProfileViewModel } from "../account-profile.viewmodels/call-history-account-profile.viewModel";
import { SortBy } from "../../account.viewmodels/sort-by.viewmodel";
import { CustomerCallHistoryBaseComponent } from "../customer-call-history-base/customer-call-history-base.component";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { PingService } from "src/app/services/ping.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { PictureModalComponent } from "src/app/dialogs/pop-out-img-dialog/img-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CustomerOfflineService } from "src/app/services/offline-services/customer-offline.service";

@Component({
    selector: "app-customer-detailed-call-history",
    templateUrl: "./customer-detailed-call-history.component.html",
    styleUrls: ["./customer-detailed-call-history.component.scss"]
})
export class CustomerDetailedCallHistoryComponent extends CustomerCallHistoryBaseComponent {

    @Input()
    get customer(): Customer {
        return this._customer;
    }
    set customer(value: Customer) {
        if (value?.id !== this._customer?.id) {
            if (value) {

                this._customer = value;
                void this.callHistorySearch();
            }
        }
    }

    @Input() openClosingNotes: (vm: CallHistoryAccountProfileViewModel) => void;

    @Input() callHistoryTitle: string;

    hasBeforePicture = false;
    hasAfterPicture = false;

    beforeImage = "";
    afterImage = "";
    customerNumber = ""
    userZrt = ""
    callHistoryAccountProfileViewModel: CallHistoryAccountProfileViewModel[] = [];
    shouldWait$ = new BehaviorSubject<boolean>(false);
    pictureMap = new Map<string, Picture>();

    sortBy: SortBy;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    photoCarousel: SwisherOverlayRef<
        PictureCarouselViewModel,
        PictureComponent
    >;

    constructor(
        overlayService: OverlayService,
        systemInformationDelineationService: SystemInformationDelineationService,
        callDelineationService: CallDelineationService,
        private pictureDelineationService: PictureDelineationService,
        private snackbarService: SnackbarService,
        private pleasewaitService: PleaseWaitService,
        pingService: PingService,
        private dialog: MatDialog,
        private offlineCustService: CustomerOfflineService
    ) {
        super(overlayService,
            systemInformationDelineationService,
            callDelineationService,
            pingService
            )
    }

    openPictureInModal(picture: string, allData: any) {
        // let obj = {
        //     userZrt: this.userZrt,
        //     customerNumber: this.customerNumber,
        //     pictureType: allData
        // }
        this.dialog.open(PictureModalComponent, {
            data: {picture: picture, allData: allData},
            autoFocus: false,
            maxHeight: "80vh",
            width: "800px"
        });
    }
    async callHistorySearch(): Promise<void> {
        if (this.customer) {

            this.pleasewaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            const calls = await this.callHistoryBaseSearch();
            const viewModels: CallHistoryAccountProfileViewModel[] = [];

            const pictures = new Array<Picture>();
            for (const call of calls) {

                const viewModel: CallHistoryAccountProfileViewModel = new CallHistoryAccountProfileViewModel();
                viewModel.buildViewModleFromModel(call);
                viewModels.push(viewModel);
            };

            this.callHistoryAccountProfileViewModel = viewModels;
            this.beforeImage = "";
            this.afterImage = "";

            let hasBeforeOrAfterPhotos = false;

            for (const vm of this.callHistoryAccountProfileViewModel) {
                // let res = await this.offlineCustService.getById(vm.call.customerId)
                // this.customerNumber = res.customerNumber
                // this.userZrt = res.zrt
                if (!hasBeforeOrAfterPhotos) {
                    if (vm.beforeCallPicture || vm.afterCallPicture) {
                        hasBeforeOrAfterPhotos = true;
                        if (vm.beforeCallPicture) {
                            const image: string = await this.getPicture(
                                vm.beforeCallPicture, vm.call.id
                            );
                            if (image) {
                                this.hasBeforePicture = true;
                            }
                            this.beforeImage = image;
                        }

                        if (vm.afterCallPicture) {
                            const image: string = await this.getPicture(
                                vm.afterCallPicture, vm.call.id
                            );
                            if (image) {
                                this.hasAfterPicture = true;
                            }
                            this.afterImage = image;
                        }
                    }
                } else {
                    break;
                }
            }
            this.shouldWait$.next(false);
        }
    }

    async getPicture(callPicture: CallPicture, callId: string): Promise<string> {

        if (this.pictureMap.has(callPicture.id)) { return this.pictureMap.get(callPicture.id).image}
        const params = new PictureParamDto();
        params.callId = callId;
        params.pictureId = callPicture.id;
        params.writable = false;
        const result = await this.pictureDelineationService.getPicture(params);
        if (result?.values) {
            this.pictureMap.set(result.values.id, result.values);
        }
        return result?.values?.image;
    }

    async processPictures(call: Call): Promise<void> {

        if (call.callType === CallTypes.retail
            || call.callType === CallTypes.wholesale
            || call.callType === CallTypes.rmWholesale) {



        const pictureViewModels: PictureViewModel[] = [];

        this.pleasewaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);
        const picturesResponse = await this.pictureDelineationService.getPicturesByCall(call);
        if (!picturesResponse?.values) {
            this.snackbarService.showWarning("Unable to locate images for this call.");
        }

        const picturesForCall = picturesResponse.values;

        for (const callPicture of call.callPictures) {
            const pictureViewModel = new PictureViewModel();
            pictureViewModel.callpicture = callPicture;

            if (this.pictureMap.has(callPicture.id)) {
                pictureViewModel.picture = this.pictureMap.get(callPicture.id);
            } else {
                pictureViewModel.picture = picturesForCall.find((p) => p.id === callPicture.id);
                this.pictureMap.set(callPicture.id, pictureViewModel.picture);
            }

            // the pictureViewModel.picture is set in both case of the if/else, above
            // so this line, below, is at best redundant - & in the case of issue #2831, it overwites the actual picture
            // still need to look into why picturesResponse doesn't have the pictures, tho

            // pictureViewModel.picture = picturesForCall.find((p) => p.id === callPicture.id);
            pictureViewModels.push(pictureViewModel);
        }

        const pictureCarouselViewModel = new PictureCarouselViewModel();
        pictureCarouselViewModel.pictureViewModels = pictureViewModels;
        this.photoCarousel = this.overlayService.open(
            PictureComponent,
            pictureCarouselViewModel,
            true
        );}
        this.shouldWait$.next(false);
    }
}

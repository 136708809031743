import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { combineLatest, from, iif, merge, of } from "rxjs";
//import { LogService } from "./log.service";
import {
    MsalGuardConfiguration,
    MsalService,
    MSAL_GUARD_CONFIG,
    MsalBroadcastService
} from "@azure/msal-angular";
import { EmployeeDto, SalespersonRole } from "shield.shared";
import { InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { DatabaseService } from "./database.service";
import { Employee } from "../entity-models/employee.entity";
import { PingService } from "./ping.service";
import { catchError, filter, map, shareReplay, switchMap, take, takeWhile, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class AppStateService {
    private msalComplete$ = this.msalBroadcastService.inProgress$
        .pipe(
            tap((status) => console.log("Msal in progress status: ", status)),
            filter((status) => status === InteractionStatus.None),
            shareReplay(1)
        )
    private onlineNoUser$ = this.pingService.online.pipe(
        takeWhile(() => !this.employee),
        filter((isOnline) => isOnline),
        take(1),
        shareReplay(1)
    );
    
    private employee: Employee;

    public currentUser = merge(
        this.msalComplete$,
        this.onlineNoUser$
    ).pipe(
        switchMap(() => this.authService.instance.handleRedirectPromise()),
        map(() => this.authService.instance.getAllAccounts()?.[0]),
        shareReplay(1)
    )

    public currentEmployee = this.currentUser.pipe(
        filter((user) => !!user),
        switchMap((user) => combineLatest([
            from(this.dbService.employees.where("email").equals(user.username).first()), //Dexie Employee
            iif(() => this.pingService.onlineCurrentStatus, // API Employee or Null if Offline
                this.http.get<EmployeeDto>(`/api/employees/search/email/${user.username}`).pipe(
                    catchError((e) => {
                        this.setError(e);
                        return of(null);
                    }),
                    map((dto) => dto ? this.employeeDtoToEmployee(dto) : null)
                ),
                of(null)
            ),
            of(user) //The account info user
        ])),
        switchMap(async ([dexieEmployee, apiEmployee, user]) => {
            const employee = apiEmployee ?? dexieEmployee;
            if (!employee) {
                this.setError({
                    message: `Employee not found for email: ${user.username}. A sync may be required.`
                });
                return;
            }

            employee.user = user;
            await this.setSearchableZrt(employee);
            if (apiEmployee?.rowversion ?? "0" > (dexieEmployee?.rowversion ?? "0")) {
                await this.dbService.employees.put(employee);
            }
            this.setError(null);
            this.employee = employee;
            return employee;
        }),
        shareReplay(1)
    )

    public isAuthenticated$ = this.currentUser.pipe(
        map((user) => ({authenticated: !!user}))
    )



    public initialSycComplete = false;

    public appLoadError = false;
    public errorMessage = "";
    public errorCode = "";

    constructor(
        private http: HttpClient,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        //private log: LogService,
        private dbService: DatabaseService,
        private pingService: PingService,
        private msalBroadcastService: MsalBroadcastService,
    ) {}

    private employeeDtoToEmployee(dto: EmployeeDto): Employee {
        return {
            ...dto,
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : null,
            modifiedUtcDateTime: dto.modifiedUtcDateTime
                ? new Date(dto.modifiedUtcDateTime)
                : null,
            user: null
        };
    }

    private setError(e: Partial<HttpErrorResponse | null>): void {
        if (!e) {
            this.appLoadError = false;
            this.errorCode = "";
            this.errorMessage = "";
            return;
        }

        this.appLoadError = true;
        this.errorCode = `${e.status ?? null}`;
        this.errorMessage = e.error?.message ?? e.message ?? "An unknown error occurred.";
    }

    private async setSearchableZrt(employee: Employee): Promise<void> {
        const registeredUser = await this.dbService.registeredUsers
            .where("employeeId")
            .equals(employee.id)
            .first();

        if (registeredUser && registeredUser.zrtOverride) {
            employee.zrt = registeredUser.zrtOverride;
        } else {
            employee.searchableZrt = employee.zrt;
        }

        const length: number = employee?.zrt?.length ?? 0;

        if (length > 2) {
            if (employee.zrt[length - 1] !== "0") {
                employee.salespersonRole = SalespersonRole.territoryManager;
                employee.searchableZrt = employee.zrt;
            } else if (employee.zrt[length - 2] !== "0") {
                employee.salespersonRole = SalespersonRole.regionalManager;
                employee.searchableZrt = employee.zrt.substring(0, length - 1);
            } else {
                employee.salespersonRole = SalespersonRole.zoneManager;
                employee.searchableZrt = employee.zrt.substring(0, length - 2);
            }
        }
    }

    async refreshAuthToken(): Promise<void> {
        console.log("Refresh starting");
        await this.authService.instance.acquireTokenSilent({
            scopes: [environment.auth.apiScope],
            account: this.authService.instance.getAccountByUsername(this.employee.email),
            forceRefresh: true,
        }).then(_ => {
            console.log("Refresh complete.");
            return Promise.resolve();
        }).catch(err => {
            console.log("Refresh failed.");
            console.log(JSON.stringify(err));
            return Promise.reject(err);
        });
    }

    async login(): Promise<void> {
        await this.authService.instance.handleRedirectPromise();

        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({
                ...this.msalGuardConfig.authRequest
            } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout(): void {
        this.authService.logout();
    }
}

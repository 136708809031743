import { Injectable } from "@angular/core";
import {
    AccountFilterDto,
    activeProjectStatuses,
    CustomerTypeEnum,
    FilterRequestV2Dto,
    GenericDropDownDto,
    invalidOwnerCodes,
    ProfileProjectParamsDto,
    ProjectAssignmentCountParamsDto,
    ProjectBatchParamsDto,
    ProjectByIdParamsDto,
    ProjectCustomerAndEmployeeParamsDto,
    ProjectCustomerColumns,
    ProjectEmployeeAssignmentParamsDto,
    ProjectMetricParamsDto,
    ProjectMetricProductDto,
    ProjectMetricWholesalerDto,
    ProjectMetricZrtDto,
    ProjectStatuses,
    ProjectStoreAssignmentParamsDto,
} from "shield.shared";
import { ProjectBasicCustomer } from "src/app/entity-models/project-basic-customer.entity";
import { ProjectCustomer } from "src/app/entity-models/project-customer.entity";
import { Project } from "src/app/entity-models/project.entity";
import { DatabaseService } from "../database.service";

@Injectable()
export class ProjectOfflineService {

    constructor(private dbService: DatabaseService) { }

    private offlineErrorMessage = "This project feature is not available in offline mode.";

    async checkStagingProjectStatus(key: string): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async getAssignmentCounts(key: ProjectAssignmentCountParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async unassignEmployeesFromStores(key: ProjectEmployeeAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async assignEmployeesToStores(key: ProjectEmployeeAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async setProjectStoreForAssignment(key: ProjectStoreAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async getCustomersForProjectEmployeeBatch(key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async discardProject(id: string): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async deleteProject(id: string): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async upsertProject(project: Project): Promise<Project> {
        throw Error(this.offlineErrorMessage);
    }

    async getById(key: ProjectByIdParamsDto): Promise<Project> {

        return await this.dbService.projects.where("id").equals(key.id).first();
    }

    async getProjectBatch(id: ProjectBatchParamsDto): Promise<Project[]> {

        throw Error(this.offlineErrorMessage);
    }

    async getCustomersForProjectBatch(key: FilterRequestV2Dto<AccountFilterDto, ProjectCustomerColumns>): Promise<ProjectBasicCustomer[]> {
        throw Error(this.offlineErrorMessage);
    }

    async getDropDown(): Promise<GenericDropDownDto[]> {
        const projects = await this.dbService.projects.where("projectStatusId").anyOf(activeProjectStatuses).toArray();


        return projects.sort((a, b) => a.name.localeCompare(b.name)).map((p) => {
            return {
                id: p.id,
                name: p.name,
                displayValue: p.name
            }
        });
    }

    async getProjectCustomersByProjectId(id: string): Promise<ProjectCustomer[]> {
        throw Error(this.offlineErrorMessage);
    }

    async getMetrics(params: ProjectMetricParamsDto): Promise<ProjectMetricZrtDto[]
        | ProjectMetricWholesalerDto[]
        | ProjectMetricProductDto[]> {
        throw Error(this.offlineErrorMessage);
    }

    async setProjectStoreAsAssigned(params: ProjectStoreAssignmentParamsDto): Promise<ProjectCustomer | undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async setProjectStoreAsUnassigned(params: ProjectStoreAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async setProjectStoresAsAssigned(params: ProjectStoreAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async setProjectStoresAsUnassigned(params: ProjectStoreAssignmentParamsDto): Promise<undefined> {
        throw Error(this.offlineErrorMessage);
    }

    async getProfileProjects(params: ProfileProjectParamsDto): Promise<Project[]> {
        let chainIds = new Array<string>();

        if (params.customerType.id === CustomerTypeEnum.ChainHQ) {
            if (!invalidOwnerCodes.includes(params.customerOwnerCode)) {
                const chains = await this.dbService.customers.where("ownerCode")
                    .equals(params.customerOwnerCode).toArray();
                if (chains) {
                    chainIds = chains.map((c) => c.id);
                }
            }
        }
        const projectsInActiveStatuses = await this.dbService.projects.where("projectStatusId").anyOf(params.projectStatusIds).toArray();
        const projectsVisableDate = projectsInActiveStatuses.filter((p) => p.visibleDate?.setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0));
        const rtn = projectsVisableDate.filter((project) => {

            const hasProducts = (project.projectProducts ?? [])
                .some((pp) => pp.wholesalerId === params.customerId);
            const hasEmployee = (project.projectEmployees ?? [])
                .some((pe) => pe.employeeId === params.employeeId);
            const hasAssignment = !!(project.projectCustomers ?? [])
                .filter((pc) => pc.customerId === params.customerId || chainIds.includes(pc.customerId)).length;

            switch (params.customerType.id) {
                case CustomerTypeEnum.DirectWholesaler:
                case CustomerTypeEnum.IndirectWholesaler:
                    return hasProducts ? hasEmployee : hasAssignment;
                default:
                    return hasAssignment;
            }

        });

        return rtn;
    }

    async getStartedProjectsByCustomerAndEmployeeIds(params: ProjectCustomerAndEmployeeParamsDto): Promise<Project[]> {

        const customer = await this.dbService.customers.where("id").equals(params.customerId).first();

        if (!customer) throw `Unable to locate customer with id: ${params.customerId}`;

        return await this.dbService.projects.where("projectStatusId").anyOf([ProjectStatuses.Visible, ProjectStatuses.Started])
            .and(
                (p) => {
                    if (p.startDate?.setHours(0,0,0,0) > Date.now()) {
                        return false;
                    }

                    const isWholesaler = (customer.customerType.id === CustomerTypeEnum.DirectWholesaler
                        || customer.customerType.id === CustomerTypeEnum.IndirectWholesaler);
                    if (isWholesaler) {
                        if (!p.projectEmployees.filter((e) => e.employeeId === params.employeeId).length
                            && !p.areStoresVisibleToAll
                        ) {
                            return false;
                        }
                        if (!p.projectProducts.filter((p) => p.wholesalerId === customer.id).length) {
                            return false;
                        }
                    } else {
                        if (!p.projectCustomers.filter((pc) => pc.customerId === customer.id).length) {
                            return false;
                        }
                    }
                    return true;
                }
            )
            .toArray();
    }
}

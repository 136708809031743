import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderTrackingComponent } from '../order-tracking/order-tracking.component';

export enum DirectOrderPage {
  Tracking,
  QuickOrder
}

@Component({
  selector: 'direct-order',
  templateUrl: './direct-order.component.html',
  styleUrls: ['./direct-order.component.scss']
})
export class DirectOrderComponent implements OnInit {

  public Tracking = DirectOrderPage.Tracking;
  public QuickOrder = DirectOrderPage.QuickOrder;
  public currentPage: DirectOrderPage = DirectOrderPage.Tracking;
  public headerText = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.route.children.length === 0) {
      this.router.navigate(["resources/direct-order/tracking"]);
    }
  }

  //Called when the router outlet activates the target component.
  onOutletLoaded(component: unknown) {
    if (component instanceof OrderTrackingComponent) {
      this.currentPage = DirectOrderPage.Tracking;
    } else if (component instanceof DirectOrderComponent) {
      this.currentPage = DirectOrderPage.QuickOrder;
    }
  }

}
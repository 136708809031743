import {
    Component,
    OnInit
} from "@angular/core";
import { Subscription } from "rxjs";
import { Customer } from "src/app/entity-models/customer.entity";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { ContactComponent } from "../../contact/contact.component";
import { ContactViewModel } from "../../contact/contact.viewmodel";
import { RemoveContactComponent } from "../../remove-contact/remove-contact.component";
import { CallHistoryAccountProfileViewModel } from "../account-profile.viewmodels/call-history-account-profile.viewModel";
import {
    activeProjectStatuses,
    CallTypes,
    ContractPaymentMethodLookup,
    ContractPaymentMethods,
    ContractPaymentStatuses,
    ContractPaymentStatusLookup,
    ContractPaymentTypeLookup,
    ContractPaymentTypes,
    ContractTemplateTypeIdAndZoneParamDto,
    ContractTemplateTypes,
    CustomerTypeEnum,
    EmployeeRoleType,
    newSequentialId,
    ProjectStatuses,
    ProjectStatusLookup,
    ProjectTypeLookup,
    ProjectTypes,
    StagingProjectStatus
} from "shield.shared";
import moment from "moment";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { WholsaleCallViewmodel } from "../../call-master/wholesale-call/wholesale-call.viewmodel";
import { WholesaleCallComponent } from "../../call-master/wholesale-call/wholesale-call.component";
import { ChainHqCallViewmodel } from "../../call-master/chain-hq-call/chain-hq-call.viewmodel";
import { ChainHqCallComponent } from "../../call-master/chain-hq-call/chain-hq-call.component";
import { NewAgreementViewModel } from "../retail-profile/retail-agreements/new-agreement/new-agreement.viewmodel";
import { NewAgreementComponent } from "../retail-profile/retail-agreements/new-agreement/new-agreement.component";
import { ViewAgreementViewModel } from "../retail-profile/retail-agreements/view-agreement/view-agreement.viewmodel";
import { ViewAgreementComponent } from "../retail-profile/retail-agreements/view-agreement/view-agreement.component";
import { RetailAgreementViewModel } from "../retail-profile/retail-agreements/retail-agreements.viewmodel";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { Signature } from "src/app/entity-models/signature.entity";
import { RelatedProjectViewmodel } from "../related-projects/related-projects.viewmodel";
import { Project } from "src/app/entity-models/project.entity";
import { Router } from "@angular/router";
import { EditWholesalersViewModel } from "../retail-profile/related-wholesalers/edit-wholesalers/edit-wholesalers.viewmodel";
import { EditWholesalersComponent } from "../retail-profile/related-wholesalers/edit-wholesalers/edit-wholesalers.component";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ViewRetailProjectViewModel } from "../related-projects/view-retail-project/view-retail-project.viewmodel";
import { ViewRetailProjectComponent } from "../related-projects/view-retail-project/view-retail-project.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ClosingNotesComponent } from "../../call-master/stepper-call/closing-notes/closing-notes.component";
import { ClosingNotesModalViewModel } from "../../call-master/call-viewmodels/closing-notes-modal.viewmodel";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ContractTemplateDelineationService } from "src/app/services/delineation-services/contract-template-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { ContractPaymentDelineationService } from "src/app/services/delineation-services/contract-payment-delineation.service";
import { ProjectApplicationService } from "src/app/details/project/project-services/project-application.service";
import { WholesalerGroupMemberDelineationService } from "src/app/services/delineation-services/wholesaler-group-member-delineation.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ContactConverterService } from "src/app/services/converter-services/contact-converter.service";
import { SyncService } from "src/app/services/sync.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

@Component({
    selector: "app-account-profile-base",
    template: "",
    styleUrls: ["./account-profile-base.component.scss"]
})
export abstract class AccountProfileBaseComponent implements OnInit {
    customer: Customer;
    px3Rank: string|undefined;
    customerSubscription: Subscription;
    currentEmployee: Employee;
    employeeSubscription: Subscription;
    contactOptions: ContactViewModel[] = [];
    agreementOptions: RetailAgreementViewModel[] = [];
    relatedProjects: RelatedProjectViewmodel[] = [];
    isNotTm: boolean;

    contactOverlayRef: SwisherOverlayRef<ContactViewModel, ContactComponent>;
    removeContactOverlayRef: SwisherOverlayRef<
        ContactViewModel,
        RemoveContactComponent
    >;

    retailClosingNotesOverlayRef: SwisherOverlayRef<
        ClosingNotesModalViewModel,
        ClosingNotesComponent
    >;
    wholesaleClosingNotesOverlayRef: SwisherOverlayRef<
        WholsaleCallViewmodel,
        WholesaleCallComponent
    >;
    chainHqClosingNotesOverlayRef: SwisherOverlayRef<
        ChainHqCallViewmodel,
        ChainHqCallComponent
    >;
    newAgreementOverlayRef: SwisherOverlayRef<
        NewAgreementViewModel,
        NewAgreementComponent
    >;
    viewAgreementOverlayRef: SwisherOverlayRef<
        ViewAgreementViewModel,
        ViewAgreementComponent
    >;
    editWholesalersOverlayRef: SwisherOverlayRef<
        EditWholesalersViewModel,
        EditWholesalersComponent
    >;
    projectOverlayRef: SwisherOverlayRef<
        ViewRetailProjectViewModel,
        ViewRetailProjectComponent
    >;
    confirmationOverlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    initialized = false;

    constructor(
        protected appStateService: AppStateService,
        protected overlayService: OverlayService,
        protected contactDelineationService: ContactDelineationService,
        protected customerContractDelineationService: CustomerContractDelineationService,
        protected contractPaymentDelineationService: ContractPaymentDelineationService,
        protected customerDelineationService: CustomerDelineationService,
        protected customerStateService: CustomerStateService,
        protected contractTemplateDelineationService: ContractTemplateDelineationService,
        protected projectDelineationService: ProjectDelineationService,
        protected router: Router,
        protected projectStateService: ProjectStateService,
        protected projectApplicationService: ProjectApplicationService,
        protected wholesalerGroupMemberDelineationService: WholesalerGroupMemberDelineationService,
        protected snackbarService: SnackbarService,
        protected px3RankService: Px3DelineationService,
        private syncService: SyncService,
    ) {}

    ngOnInit(): void {
        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                async (customer) => {
                    if (!this.customerStateService.ignoreCustomerSubscription) {
                        this.customer = customer;

                        if (this.customer && this.currentEmployee && !this.initialized) {
                            this.initialized = true;
                            this.initialize();
                        }
                    }
                }
            );
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe(
                async (employee) => {
                    if (employee && this.currentEmployee?.id !== employee.id) {
                        this.currentEmployee = employee;
                        this.isNotTm = !!employee.employeeRoles.find((r) =>
                            r.employeeRoleType.id === EmployeeRoleType.RM ||
                            r.employeeRoleType.id === EmployeeRoleType.ZM ||
                            r.employeeRoleType.id === EmployeeRoleType.CS ||
                            r.employeeRoleType.id === EmployeeRoleType.CorpAdmin ||
                            r.employeeRoleType.id === EmployeeRoleType.ShieldAdmin
                        );
                        if (this.customer && this.currentEmployee && !this.initialized) {
                            this.px3Rank = (await this.px3RankService.getById(this.customer.px3RankId))?.rank;
                            this.initialized = true;
                            this.initialize();
                        }
                    }
                }
            );
        }
    }

    async initialize(): Promise<void> {
        void this.getContactOptions();
        void this.getAgreements();
        void this.getProjects();
    }

    onAddContact(): void {
        const data: ContactViewModel = new ContactViewModel();
        data.customerId = this.customer.id;
        data.headerLeftText = "Store Profile - Add Contact";

        data.createdUtcDateTime = new Date();
        data.createdUserZrt = this.currentEmployee.zrt;
        data.createdUserId = this.currentEmployee.id;
        data.createdUserFullName = this.currentEmployee.fullName;

        data.modifiedUtcDateTime = new Date();
        data.modifiedUserZrt = this.currentEmployee.zrt;
        data.modifiedUserId = this.currentEmployee.id;
        data.modifiedUserFullName = this.currentEmployee.fullName;

        this.contactOverlayRef = this.overlayService.open(
            ContactComponent,
            data,
            true as boolean
        );

        this.contactOverlayRef.afterClosed$.subscribe((overlayReturn) => {
            if (overlayReturn) {
                this.getContactOptions();
            }
        });
    }

    async onEditContact(data: ContactViewModel): Promise<void> {
        const response = await this.contactDelineationService.getById(data.id);
        if (!response) { return; }

        const contact = response.values;

        data = ContactConverterService.buildViewModelFromDomainModel(
            contact,
            this.customer.id
        );
        data.headerLeftText = "Store Profile - Edit Contact";
        data.headerRightText = contact.modifiedUtcDateTime
            ? "Last Updated on " + moment(contact.modifiedUtcDateTime).format(MY_DATE_FORMATS.display.dateInput)
            : "";
        data.modifiedUtcDateTime = new Date();
        data.modifiedUserZrt = this.currentEmployee.zrt;
        data.modifiedUserId = this.currentEmployee.id;
        data.modifiedUserFullName = this.currentEmployee.fullName;
        this.contactOverlayRef = this.overlayService.open(
            ContactComponent,
            data,
            true
        );

        this.contactOverlayRef.afterClosed$.subscribe((myData) => {
            if (myData) {
                this.getContactOptions();
            }
        });
    }

    async onRemoveContact(data: ContactViewModel): Promise<void> {

        const response = await this.contactDelineationService.getById(data.id);
        if (!response) { return; }

        const contact = response.values;

        data = ContactConverterService.buildViewModelFromDomainModel(
            contact,
            this.customer.id
        );
        data.headerLeftText = "Store Profile - Remove Contact";

        this.removeContactOverlayRef = this.overlayService.open(
            RemoveContactComponent,
            data,
            true
        );

        this.removeContactOverlayRef.afterClosed$.subscribe((myData) => {
            if (myData) {
                this.getContactOptions();
            }
        });
    }

    async getContactOptions(): Promise<void> {
        const response = await this.contactDelineationService.getByCustomerId(this.customer.id);
        if (!response) { return; }

        const contacts = response.values.sort((a, b) =>
            new Date(a.clientLastTouchedUtcDateTime ?? a.modifiedUtcDateTime).getTime() >=
            new Date(b.clientLastTouchedUtcDateTime ?? b.modifiedUtcDateTime).getTime()
                ? -1
                : 1
        );

        const contactOptions: ContactViewModel[] = [];
        contacts.forEach((contact) => {
            if (contact && !contact.isDeleted) {
                contactOptions.push(
                    ContactConverterService.buildViewModelFromDomainModel(
                        contact,
                        this.customer.id
                    )
                );
            }
        });
        this.contactOptions = contactOptions;
    }

    async getAgreements(): Promise<void> {
        const response = await this.customerContractDelineationService.getByCustomerId(this.customer.id);
        if (!response) { return; }

        const customerContracts = response.values;

        const agreementOptions: RetailAgreementViewModel[] = [];
        customerContracts?.forEach((contract) => {
            const payments = contract.customerContractPayments?.sort((a, b) =>
                a.createdUtcDateTime < b.createdUtcDateTime ? 1 : -1
            );

            const agreement = new RetailAgreementViewModel;

            agreement.id = contract.id;
            agreement.contractNumber = contract.number;
            agreement.level = "Level " + contract.level;
            agreement.customerContract = contract;
            agreement.dateSigned = moment(
                contract.signatureDate
            ).format(MY_DATE_FORMATS.display.dateInput);
            agreement.status = payments[0].contractPaymentStatus.description;

            agreementOptions.push(agreement);
        });
        this.agreementOptions = agreementOptions;
    }

    async getProjects(): Promise<void> {

        const response = await this.projectDelineationService.getProfileProjects(activeProjectStatuses,
            this.customer.customerType,
            this.currentEmployee.id,
            this.customer.id);
        if (!response) { return; }

        const projects = response.values;

        if (projects) {
            this.relatedProjects = projects
                .map((p) => this.buildRelatedProjectViewModelFromProject(p))
                .sort((a, b) =>
                    new Date(a.endDate).getTime() >=
                        new Date(b.endDate).getTime()
                        ? -1
                        : 1
                );
        }
    }

    buildRelatedProjectViewModelFromProject(project: Project): RelatedProjectViewmodel {
        return {
            project: project,
            startDate: project.startDate ? moment(project.startDate).format(MY_DATE_FORMATS.display.dateInput) : "None",
            endDate: project.endDate ? moment(project.endDate).format(MY_DATE_FORMATS.display.dateInput) : "None",
            status: ProjectStatusLookup.find((s) => s.id === project.projectStatusId).description,
            type: ProjectTypeLookup.find((t) => t.id === project.projectTypeId).description
        };
    }

    async addAgreement(newContract?: CustomerContract): Promise<void> {
        if (newContract) {
            await this.customerContractDelineationService.upsertAgreement(newContract);
            this.syncService.forceOutboundSync();
        }
        void this.getAgreements();
    }

    openClosingNotes(vm: CallHistoryAccountProfileViewModel): void {


        const date = "Call Date: " + moment(new Date(vm.call.startTime)).format(
            MY_DATE_FORMATS.display.dateInput
        );

        switch (vm.call.callType) {
            case CallTypes.retail:
            case CallTypes.rmWholesale:
                {
                    const data: ClosingNotesModalViewModel = new ClosingNotesModalViewModel();
                    data.headerLeftText = "Call History";
                    data.call = vm.call;
                    data.selectedIndex = null;
                    data.headerRightText = date;
                    data.width = "95vw";
                    data.height = "85vh";

                    this.retailClosingNotesOverlayRef = this.overlayService.open(
                        ClosingNotesComponent,
                        data,
                        true
                    );
                }
                break;
            case CallTypes.wholesale: {
                const data = new WholsaleCallViewmodel();
                data.headerLeftText = "Call History";
                data.buildViewModelFromDomainModel(vm.call);
                data.headerRightText = date;
                this.wholesaleClosingNotesOverlayRef = this.overlayService.open(
                    WholesaleCallComponent,
                    data,
                    true);
            }
                break;
            case CallTypes.chainHq: {
                const data = new ChainHqCallViewmodel();
                data.headerLeftText = "Call History";
                data.buildViewModelFromDomainModel(vm.call);
                data.headerRightText = date;
                this.chainHqClosingNotesOverlayRef = this.overlayService.open(
                    ChainHqCallComponent,
                    data,
                    true);
            }
                break;
            default:
                break;
        }
    }

    async openNewAgreement(): Promise<void> {
        const params = new ContractTemplateTypeIdAndZoneParamDto();
        params.typeId = ContractTemplateTypes.RetailIncentiveAgreement;
        params.zone = this.customer.zone;

        const response = await this.contractTemplateDelineationService.getByTypeIdAndZone(params);
        if (!response) {
            return;
        }

        const contractTemplates = response.values;

        const templateZRT = contractTemplates.find(
            (ct) => ct.zone === this.customer.zone &&
                ct.region === this.customer.region &&
                ct.territory === this.customer.territory
        );
        const templateZR = contractTemplates.find(
            (ct) => ct.zone === this.customer.zone &&
                ct.region === this.customer.region
        );
        const templateZ = contractTemplates.find(
            (ct) => ct.zone === this.customer.zone
        );
        const template0 = contractTemplates.find(
            (ct) => ct.zone === "00" && ct.region === "0" && ct.territory === "0"
        );
        const contractTemplate = templateZRT ?? templateZR ?? templateZ ?? template0;
        const data = new NewAgreementViewModel(contractTemplate);
        data.contacts = this.contactOptions;
        data.buttonLeftFunction = () => this.newAgreementOverlayRef.close(data);
        data.buttonRightFunction = () => {
            data.signaturePad.penColor = "black";
            data.agreementFormGroup.markAllAsTouched();
            data.agreementFormGroup.controls["levelControl"].markAllAsTouched();
            if (data.isSaveDisabled()) {
                return;
            }
            data.isConfirmed = true;
            this.newAgreementOverlayRef.close(data);
        }
        data.width = "50vw";
        data.height = "85vh";
        this.newAgreementOverlayRef = this.overlayService.open(
            NewAgreementComponent,
            data,
            true
        );

        this.newAgreementOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data && ref.data.isConfirmed) {
                const newContract = new CustomerContract();
                newContract.createdUtcDateTime = new Date();
                newContract.createdUserId = this.currentEmployee.id;
                newContract.createdUserZrt = this.currentEmployee.zrt;
                newContract.createdUserFullName = this.currentEmployee.fullName;
                newContract.id = newSequentialId();
                newContract.customerId = this.customer.id;
                newContract.customerNumber = this.customer.customerNumber;
                newContract.number = this.customer.zrt + new Date(new Date().toISOString()).getTime();
                newContract.level = ref.data.level;
                newContract.isNew = true; // validate this on server...
                newContract.signatureDate = new Date();
                newContract.endDate = ref.data.date;
                newContract.customerName = this.customer.name;
                newContract.retailIdentificationNumber = this.customer.customerNumber;
                newContract.address1 = this.customer.businessAddress.address1;
                newContract.address2 = this.customer.businessAddress.address2;
                newContract.city = this.customer.businessAddress.city;
                newContract.state = this.customer.businessAddress.state;
                newContract.zipCode = this.customer.businessAddress.zip;
                newContract.px3RankId = this.customer.px3RankId;
                newContract.customerContactId = ref.data.contact.id;
                newContract.customerContactName = ref.data.contact.firstName + " " + ref.data.contact.lastName;
                newContract.customerContractPayments = new Array<CustomerContractPayment>();
                newContract.customerSignature = new Signature();
                newContract.customerSignature.id = newContract.id;
                newContract.customerSignature.image = ref.data.signaturePad.toDataURL();

                const signedPayment = new CustomerContractPayment();
                signedPayment.createdUtcDateTime = new Date();
                signedPayment.createdUserId = this.currentEmployee.id;
                signedPayment.createdUserZrt = this.currentEmployee.zrt;
                signedPayment.createdUserFullName = this.currentEmployee.fullName;
                signedPayment.id = newSequentialId();
                signedPayment.customerId = this.customer.id;
                signedPayment.customerContractId = newContract.id;
                signedPayment.customerContactId = ref.data.contact.id;
                signedPayment.customerContactName = ref.data.contact.firstName + " " + ref.data.contact.lastName;
                signedPayment.contractPaymentStatus = ContractPaymentStatusLookup.find(
                    (s) => s.id === ContractPaymentStatuses.Signed
                );
                signedPayment.contractPaymentType = ContractPaymentTypeLookup.find(
                    (t) => t.id === ContractPaymentTypes.NA
                );
                signedPayment.contractPaymentMethod = ContractPaymentMethodLookup.find(
                    (m) => m.id === ContractPaymentMethods.NA
                );
                signedPayment.paymentDate = new Date();

                signedPayment.customerContactSignature = new Signature();
                signedPayment.customerContactSignature.id = signedPayment.id;
                signedPayment.customerContactSignature.image = ref.data.signaturePad.toDataURL();

                newContract.customerContractPayments.push(signedPayment);
                newContract.contractTemplate = ref.data.template;

                this.addAgreement(newContract);
            }
        });
    }

    openViewAgreement(agreement: RetailAgreementViewModel): void {
        const data = new ViewAgreementViewModel(agreement.customerContract
            , this.overlayService
            , this.currentEmployee
            , this.contactDelineationService
            , this.contractPaymentDelineationService
            , this.customerContractDelineationService
            , this.syncService);
        data.contacts = this.contactOptions;
        data.buttonLeftFunction = () => this.viewAgreementOverlayRef.close(data);
        this.viewAgreementOverlayRef = this.overlayService.open(
            ViewAgreementComponent,
            data,
            true
        );

        this.viewAgreementOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref && ref.data) {
                this.addAgreement();
            }
        });
    }

    async openNewProject(): Promise<void> {
        const projectCheckResponse = await this.projectDelineationService.checkStagingProjectStatus();

        if (!projectCheckResponse || !projectCheckResponse.values) return;

        if (projectCheckResponse.values.status === StagingProjectStatus.ExistingProjectFound) {
            this.openExistingStagingProjectModal(
                projectCheckResponse.values.id,
                projectCheckResponse.values.displayName
            );
            return;
        }

        this.createNewProject();
    }

    async openExistingStagingProjectModal(existingId: string, existingDisplayName: string): Promise<void> {
        if (this.confirmationOverlayRef) {
            this.confirmationOverlayRef.close();
		}
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation - Unsaved Changes";
        data.message = existingDisplayName
            ? `Project: ${existingDisplayName} has unsaved changes. Would you like to edit this project instead?`
            : "An existing project was found with unsaved changes. Would you like to edit the existing project?";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.confirmationOverlayRef.close(data);
        };
        data.buttonMiddleText = "Discard Changes";
        data.buttonMiddleFunction = async () => {
            this.confirmationOverlayRef.close(data);
            await this.projectDelineationService.discardProject(existingId);
            void this.createNewProject();
        };
        data.buttonRightText = "Edit Existing Project";
        data.buttonRightFunction = () => {
            this.confirmationOverlayRef.close(data);
            void this.router.navigate(["/details/project", existingId, "configuration"]);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );
        this.confirmationOverlayRef.afterClosed$.subscribe(() => {
            this.confirmationOverlayRef = undefined;
		});
    }

    async createNewProject(): Promise<void> {
        const project = new Project();
        project.id = newSequentialId();
        project.projectTypeId = ProjectTypes.RetailEngagement;
        project.projectStatusId = ProjectStatuses.Staging;
        project.name = "";
        project.areStoresVisibleToAll = false;

        await this.projectDelineationService.upsertProject(project);

        switch (this.customerStateService.customer.customerType.id) {
            case CustomerTypeEnum.IndirectWholesaler:
            case CustomerTypeEnum.DirectWholesaler:

                const response = await this.wholesalerGroupMemberDelineationService.getByWholesalerId(this.customerStateService.customer.id);
                if (!response) { return; }

                const member = response.values;
                if (!member) {
                    this.snackbarService.showError("This wholesaler is not part of a wholesaler group and has no products.  Cannot create new project.");
                    return;
                }

                void this.router.navigate([`/details/project/${project.id}/wholesaler`, this.customerStateService.customer.id]);
                break;
            case CustomerTypeEnum.ChainHQ:
                void this.router.navigate([`/details/project/${project.id}/chain-hq`, this.customerStateService.customer.ownerCode?.trim() ?? "0"]);
                break;
            default:
                void this.router.navigate([`/details/project/${project.id}/retail`, this.customerStateService.customer.id]);
                break;
        }
    }

    openViewProject(project: Project): void {
        const data = new ViewRetailProjectViewModel(project);
        data.isRetail = this.customer.customerType.id === CustomerTypeEnum.IndependentRetail
            || this.customer.customerType.id === CustomerTypeEnum.ChainRetail;
        data.width = "90vw";
        data.height = "85vh";
        data.buttonLeftFunction = () => this.projectOverlayRef.close();
        this.projectOverlayRef = this.overlayService.open(
            ViewRetailProjectComponent,
            data,
            true
        );
    }
}

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

export class RejectionReasonViewmodel {
    headerLeftText = "Rejection Reason";
    buttonLeftText = "Cancel";
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => false;
    buttonRightText = "Save";
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    height = "25vh";
    width = "40vw";

    rejectionReason: string;
    isConfirmed = false;

    rejectionFormGroup: UntypedFormGroup;
    rejectionReasonControl: UntypedFormControl;
    rejectionReasonSubscription: Subscription;

    constructor(formBuilder: UntypedFormBuilder){
        this.rejectionFormGroup = formBuilder.group({
            rejectionReasonControl: new UntypedFormControl("", Validators.required)
        });
    }

    isSaveDisabled(): boolean {
        return this.rejectionFormGroup.controls["rejectionReasonControl"].hasError("required") && this.rejectionFormGroup.controls["rejectionReasonControl"].touched;
    }

}

import { ElementRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { FilterService } from "src/app/services/filter.service";
export class DateInformationViewmodel {
    filterService: FilterService;
    formBuilder: UntypedFormBuilder;

    dateForm: UntypedFormGroup;

    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");

    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");

    constructor(
        filterService: FilterService,
        formBuilder: UntypedFormBuilder
    ) {
        this.filterService = filterService;
        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", null],
            startDate: ["", null]
        });

    }
}

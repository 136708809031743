import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

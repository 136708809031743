import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { MatDrawerMode, MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { ElementRef, Type } from "@angular/core";
import { FilterLocation } from "src/app/enums/filter-location";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterSortDto, GenericLookup, GenericResponseDto, GratisCurrentBalanceDto, GratisListColumns, GratisStatuses, GratisStatusLookup, newSequentialId, RefinerLocation, SharedHelper, SortDirection, SortDto, Subsidiary, valueSeparator } from "shield.shared";
import { MatSortHeader } from "@angular/material/sort";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { GratisRefinerService } from "./gratis-refiner.service";
import { Router } from "@angular/router";
import { AddButtonComponent } from "src/app/shared/page-header/buttons/add-button/add-button.component";
import { Employee } from "src/app/entity-models/employee.entity";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { RequestInformationFilterComponent } from "src/app/shared/filters/request-information-filter/request-information-filter.component";
import { DateInformationFilterComponent } from "src/app/shared/filters/date-information-filter/date-information-filter.component";
import { GratisGridViewModel } from "./gratis-grid.viewmodel";
import { Gratis } from "src/app/entity-models/gratis.entity";
import moment from "moment";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Helper } from "src/app/helpers/helper";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { MatSelect } from "@angular/material/select";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { SnackbarService } from "src/app/services/snackbar.service";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { Area } from "src/app/entity-models/area.entity";
import { FilterService } from "src/app/services/filter.service";
import { GratisDelineationService } from "src/app/services/delineation-services/gratis-delineation.service";
import { GratisZrtFilterService } from "./gratis-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ZrtTreeViewComponent } from "src/app/shared/zrt-tree-view/zrt-tree-view.component";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { State } from "src/app/entity-models/state.entity";

export class GratisViewmodel {
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<GratisListColumns>[] = [];
    allStatus = new GenericLookup<GratisStatuses>();
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentBalances = new Array<GratisCurrentBalanceDto>();
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    daysWaitingInput: string;
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    endDateDefault: moment.Moment = moment();
    faCaretDown: IconDefinition = faCaretDown;
    faCaretUp: IconDefinition = faCaretUp;
    filterLocation = FilterLocation.gratisList;
    filters: FilterAndParams[] = [];
    filterDataLoaded = false;
    filterService: FilterService;
    formBuilder: UntypedFormBuilder;
    gratisDelineationService: GratisDelineationService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridheightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Gratis Request List";
    isAllStatusPresent: boolean = true;
    isEnabledExpandedDetails = false;
    isFixedLayout = false;
    isGapSet = false;
    isInitialBatchCompleted = false;
    isSearchButtonDisabled = false;
    isTmOrRm = false;
    itemsRendedInViewPort = 13;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50;
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    purposeInput: string;
    refinerService: GratisRefinerService;
    refinerServiceSubscription: Subscription;
    refinerInputChangeSubscription: Subscription;
    router: Router;
    screenHeight = 0;
    selectedColumnVisibilityComunicator: string[];
    selectedStatuses = new Array<GenericLookup<GratisStatuses>>();
    shouldDisplayZrtSearch = false;
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    startDateDefault: moment.Moment = moment().subtract(30, "days");;
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    statuses = new Array<GenericLookup<GratisStatuses>>();
    statusSelect: MatSelect;
    topGapDistance = 0;
    total?: number;
    zrtFilterService: GratisZrtFilterService;
    zrtSelectionSubscription: Subscription;
    states = new Array<State>();

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<GratisListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<GratisListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as GratisListColumns;

                    filterSorts.push(sortDto);

                }
            });
            this.activeSorts = filterSorts;
            void this.getGratisBatch(0, true);
        }
    };

    constructor(
        refinerService: GratisRefinerService,
        gratisDelineationService: GratisDelineationService,
        pleaseWaitService: PleaseWaitService,
        formBuilder: UntypedFormBuilder,
        router: Router,
        snackbar: SnackbarService,
        employeeDelineationService: EmployeeDelineationService,
        zrtFilterService: GratisZrtFilterService,
        filterService: FilterService,
        states: Array<State>
    ) {
        this.refinerService = refinerService;
        this.gratisDelineationService = gratisDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.router = router;
        this.snackbar = snackbar;
        this.employeeDelineationService = employeeDelineationService;
        this.zrtFilterService = zrtFilterService;
        this.filterService = filterService;
        this.states = states;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            startDate: ["", []]
        });
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityComunicator = value;
                break;
            case ButtonActions.print:
                this.grid?.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid?.onExportAsExcel();
                break;
            case ButtonActions.add:
                void this.router.navigate(["/my-day/gratis-request-form/0"]);
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    // public methods
    buildGratisViewModelFromGratis(gratis: Gratis): GratisGridViewModel {
        return {
            ...gratis,
            displayName: Helper.formatDisplayName(gratis, true),
            startDate: gratis.startDate
                ? moment(gratis.startDate).format(this.dateFormat)
                : null,
            endDate: gratis.endDate
                ? moment(gratis.endDate).format(this.dateFormat)
                : null,
            gratisStatus: GratisStatusLookup.find(
                (lookup) => lookup.id === gratis.gratisStatusId
            )?.description,
            shipToState: this.states.find((state) => state.shortCode === gratis.shipToState) ?? null
        };
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridheightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridheightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridheightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridheightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getCurrentBalances(): Promise<void> {
        const results = await this.gratisDelineationService.getCurrentBalances();

        if (results) {
            if (results.isError) {
                this.shouldWait$.next(true);
                this.snackbar.showError(
                    "(" +
                    results.message +
                    ")" +
                    " An unexpected error occurred.  Please try your request again later."
                );
            } else {
                this.isGapSet = false;
                const currentBalances = (results.values ?? []);

                const selectedZrtValues = this.zrtFilterService.getZrtValues();
                let areaIds = new Array<string>();
                let zrtValues = new Array<string>();

                selectedZrtValues.forEach(v => v.length > 4 ? areaIds.push(v) : zrtValues.push(v));

                let areaBalances = new Array<GratisCurrentBalanceDto>();

                if (areaIds.length) {
                    const areaMappings = await this.filterService.getAreaToZrt();
                    const selectedAreaZrts = areaMappings.filter(a => areaIds.includes(a.areaId)).map(a => a.zrt);
                    areaBalances = currentBalances.filter(v => selectedAreaZrts.find(a => a.startsWith(v.zrt.substring(0, 2))));
                }

                let zrtBalances = currentBalances.filter(v => !areaBalances.map(a => a.zrt).includes(v.zrt));
                zrtBalances = zrtBalances
                    .filter(v => zrtValues.includes(v.zrt))
                    .concat(areaBalances)
                    .sort((a, b) => a.zrt?.localeCompare(b.zrt));
                this.currentBalances = zrtBalances;
            }
        }
    }

    async getGratisBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.refinerService.areDefaultsSet) return;
        void this.getCurrentBalances();
        const areRefinersTheSame = this.resolvePageIndex(index);

        if ((
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force)
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => {
                // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.gratisDelineationService.getBatch(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (!results) {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(false);
                    return;
                }

                this.total = results.count;
                this.isGapSet = false;

                let gridData: GridData[] = this.gridData.slice();
                if (this.pageIndex === 0) {
                    gridData = new Array<GridData>();
                }
                const largestIndex = gridData.length;

                for (const gratis of results?.values ?? []) {
                    const gridItem: GridData = {
                        data: this.buildGratisViewModelFromGratis(
                            gratis
                        ),
                        detailArrayName: "",
                        isExpanded: false,
                        index: largestIndex + results.values.indexOf(gratis)
                    };
                    gridData.push(gridItem);
                }
                this.gridData = gridData;

                this.dataSource = new TableVirtualScrollDataSource(
                    this.gridData
                );
                this.shouldResetGrid = false;
                this.isInitialBatchCompleted = true;
            }, 0);
        }
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        statusSelect: MatSelect,
        startDateInput: ElementRef,
        zrtTree?: ZrtTreeViewComponent
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.statusSelect = statusSelect;
        this.startDateInput = startDateInput;

        if (zrtTree) {
            zrtTree.zrtFilterService = this.zrtFilterService;
        }

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        this.setSubscriptions();

        const addButton = new AddButtonComponent();
        addButton.title = "Add Gratis Request";
        this.headerButtons.push(addButton);
        this.headerButtons.push(new ColumnVisabilityButtonComponent());
        this.headerButtons.push(new PrintButtonComponent());
        this.headerButtons.push(new ExcelExportButtonComponent());

        this.columnDef = [
            {
                headerName: GratisListColumns.gratisRequestNumber,
                dataPropertyName: "gratisNumber",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onNavigateToGratisRequest()
            },
            {
                headerName: GratisListColumns.name,
                dataPropertyName: "displayName",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: GratisListColumns.startDate,
                dataPropertyName: "startDate",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: GratisListColumns.endDate,
                dataPropertyName: "endDate",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: GratisListColumns.purpose,
                dataPropertyName: "purpose",
                isAvailable: true,
                isSelected: true,
                columnClass: "text-wrap",
                dataType: GridDataTypes.text,
                clickFunction: this.onExpandComment()
            },
            {
                headerName: GratisListColumns.status,
                dataPropertyName: "gratisStatus",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: GratisListColumns.daysWaiting,
                dataPropertyName: "daysWaiting",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: GratisListColumns.available,
                dataPropertyName: "availableAmount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: GratisListColumns.requested,
                dataPropertyName: "requestedAmount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: GratisListColumns.reimbursed,
                dataPropertyName: "reimbursedAmount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: GratisListColumns.carryForward,
                dataPropertyName: "carryForwardAmount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: GratisListColumns.manualGratis,
                dataPropertyName: "manualGratisAmount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            }
        ];

        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: MySearchesFilterComponent });
        tempFilters.push({ filter: RequestInformationFilterComponent, zrtFilterService: this.zrtFilterService });
        tempFilters.push({ filter: DateInformationFilterComponent });

        this.filters = tempFilters;

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;
    }

    setSubscriptions(): void {
        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getGratisBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (this.isInitialBatchCompleted || dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getGratisBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    navigateToGratisRequest(gratisId: string): void {
        void this.router.navigate(["/my-day/gratis-request-form", gratisId]);
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    onAddPurposeRefiner(): void {
        if (this.purposeInput) {
            this.refinerService.addRefiner(
                RefinerLocation.purpose,
                this.purposeInput,
                "purpose"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.purpose
            )
        }
    }

    onAddDaysWaitingRefiner(): void {
        if (this.daysWaitingInput) {
            this.refinerService.addRefiner(
                RefinerLocation.daysWaiting,
                this.daysWaitingInput,
                "daysWaitingInput",
                this.daysWaitingInput
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.daysWaiting
            )
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement
            ? this.startDateInput.nativeElement.value
            : this.dateForm.controls.startDate?.value?.format(
                this.dateFormat
            );
        if (
            (!date || Helper.isValidMomentDate(date, this.dateFormat)) &&
            this.refinerService.getRefinerByLocation(RefinerLocation.startDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.startDate,
                date
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.startDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "startDate"
                );
            }
        } else {
            if (event?.type === "blur") {
                if (!this.dateForm.controls.startDate.value) {
                    this.refinerService.removeRefinerByLocation(RefinerLocation.startDate, true, false);
                }
            }
        }
    }

    onExpandComment(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            const closingNotes = this.gridData[index].data.closingNotes;
            if (event && closingNotes?.length > 100) {
                if (this.gridData[index].data.closingNotesFormatted?.length < closingNotes.length) {
                    this.gridData[index].data.closingNotesFormatted = closingNotes;
                } else {
                    this.gridData[index].data.closingNotesFormatted = closingNotes.slice(0, 100) + "...";
                }
            }

        }
    }

    onOpenedStatusChange(): void {
        if (this.selectedStatuses.includes(this.allStatus)) {
            this.refinerService.removeRefinerByLocation(RefinerLocation.status, true, false);
        }
        else {
            if (!this.statusSelect.panelOpen) {
                const statusValues = new Array<string>();
                const statusDataValues = new Array<string>();
                for (const status of this.selectedStatuses) {
                    statusValues.push(status.description);
                    statusDataValues.push(status.id.toString());
                }

                const refiner = new Refiner();
                refiner.location = RefinerLocation.status;
                refiner.value = statusValues.join(", ");
                refiner.dataPropertyName = "id";
                refiner.dataValue = statusDataValues.join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onSelectionStatusChange(): void {
        if (
            this.isAllStatusPresent &&
            this.selectedStatuses.length > 1
        ) {
            const index = this.selectedStatuses.findIndex(
                (s) => s === this.allStatus
            );
            if (index !== -1) {
                this.selectedStatuses.splice(index, 1);
                this.isAllStatusPresent = false;
            }
        } else if (
            !this.isAllStatusPresent &&
            this.selectedStatuses.includes(
                this.allStatus
            )
        ) {
            this.selectedStatuses = [
                this.allStatus
            ];
            this.isAllStatusPresent = true;
        }
    }

    onNavigateToGratisRequest(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            this.navigateToGratisRequest(this.gridData[index].data.id)
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        this.search(false);
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddDaysWaitingRefiner();
            this.onAddPurposeRefiner();
            this.onAddStartDateRefiner();
            this.onOpenedStatusChange();
        }

        this.activeRefiners = this.refinerService.refiners.slice();

        this.getGratisBatch(this.pageIndex);
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) return;

        if (!this.refinerService.areDefaultsSet) {
            this.selectedStatuses = this.statuses.filter(v => v.description == this.allStatus.description);

            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee, true);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;

            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        this.allStatus.description = "All";
        this.statuses.push(this.allStatus);
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.NotSubmitted));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Rejected));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.NotComplete));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingRegionalManager));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingZoneManager));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.AwaitingCustomerService));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Complete));
        this.statuses.push(GratisStatusLookup.find(v => v.id === GratisStatuses.Deleted));

        let zrtsResponse: GenericResponseDto<SearchZrtDropDown[]>
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
        } else {
            this.zrtFilterService.areas = new Array<Area>();
            zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt);
        }


        if (zrtsResponse && zrtsResponse?.values) {
            this.zrtFilterService.employeeZrts = zrtsResponse.values;
        }

        this.setDefaultFilter();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.purposeInput = null;
        this.daysWaitingInput = null;

        this.dateForm.controls.startDate.setValue(null);
        this.selectedStatuses = [this.allStatus];

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
        if (this.refinerServiceSubscription && !this.refinerServiceSubscription.closed) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (this.refinerInputChangeSubscription && !this.refinerInputChangeSubscription.closed) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (this.gridDataChangeSubscription && !this.gridDataChangeSubscription.closed) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (this.myListSubscription && !this.myListSubscription.closed) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.gratisDelineationService.getBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.startDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.status:
                const selectedStatuses = refiner.dataValue?.split(valueSeparator);
                const rtnSelectedStatuses = new Array<GenericLookup<GratisStatuses>>();

                if (selectedStatuses) {
                    for (const status of selectedStatuses) {
                        const found = this.statuses.find(
                            (ss) => ss.id?.toString() === status
                        );
                        if (found) {
                            rtnSelectedStatuses.push(found);
                        }
                    }
                }
                this.selectedStatuses = rtnSelectedStatuses.length > 0
                    ? rtnSelectedStatuses
                    : [this.allStatus];
                break;
            case RefinerLocation.purpose:
                this.purposeInput = refiner.value ?? null;
                break;
            case RefinerLocation.daysWaiting:
                this.daysWaitingInput = refiner.value ?? null;

            default:
                break;
        }
    }
}

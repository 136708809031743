<ng-container *ngIf="projectStateService.project && viewmodel && projectApplicationService.selectedIndex == currentStep">
    <div class="row">
        <div class="col-lg-9 col-sm-12 d-flex flex-column flex-grow-1">
            <div>
                <div class="row">
                    <div class="col-2 heading">
                        <h1 class="m-teal">
                            Assignments
                        </h1>
                    </div>
                    <div class="col-10 text-right">
                        <mat-checkbox color="warn" class="mr-1" (change)="assignEmployeesBasedOnCustomerZrt($event)">
                            Assignment also goes to current territory
                        </mat-checkbox>
                        <mat-checkbox color="warn"
                            [indeterminate]="false"
                            [(ngModel)]="projectStateService.project.areStoresVisibleToAll"
                            (ngModelChange)="viewmodel.onSetAllAssignments()"
                            [ngModelOptions]="{ standalone: true }">
                            All Stores Visible for Everyone
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 v-center">
                        <button class="btn btn-secondary mr-1 actionBtn" data-toggle="tooltip" data-placement="bottom"
                            title="View Filters" (click)="showFilters()">
                            <span class="white-space-nowrap">
                                <span class="material-icons">filter_alt</span>
                                Filters •
                                {{ viewmodel.projectAssignmentRefinerService.refiners.length }}
                            </span>
                        </button>
                    </div>
                    <div class="d-xl-none col-7"></div>
                    <div class="col-3 col-xl-2 check">
                        <strong>Selected Stores: {{ viewmodel.selectedStoreCount | number }}</strong>
                    </div>
                    <div class="col-xl-4 col-8 m-xl-auto">
                        <mat-button-toggle-group
                            [(ngModel)]="viewmodel.gridAssignedStatus"
                            (ngModelChange)="viewmodel.search()"
                        >
                            <mat-button-toggle [value]="viewmodel.assignmentStatusAll">Show All</mat-button-toggle>
                            <mat-button-toggle [value]="viewmodel.assignmentStatusAssigned">Assigned</mat-button-toggle>
                            <mat-button-toggle [value]="viewmodel.assignmentStatusUnassigned">Unassigned</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div class="col-xl-3 col-4 d-flex flex-row flex-wrap align-content-center justify-content-center">
                        <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.openAssignDialog()"
                            [disabled]="viewmodel.isAssignButtonDisabled()"
                            class="sw-green-btn actionBtn pull-right">ASSIGN</button>
                        <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.openUnassignDialog()"
                            [disabled]="viewmodel.isUnassignButtonDisabled()"
                            class="btn btn-secondary actionBtn pull-right">UNASSIGN</button>
                    </div>
                </div>
            </div>
            <mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
                <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
                    [fixedTopGap]="topGapDistance" [fixedBottomGap]="0" class="sidenav">
                    <app-filter-container [refinerService]="viewmodel.projectAssignmentRefinerService" [filters]="viewmodel.filters"
                        [isSearchDisabled]="viewmodel.isSearchButtonDisabled" [filterLocation]="viewmodel.filterLocation"
                        [expandPanelsOnInit]="viewmodel.expandPanelsOnInit"
                        (search)="viewmodel.search()" (reset)="viewmodel.reset()">
                    </app-filter-container>
                </mat-sidenav>
                <mat-sidenav-content #sideNavContent>
                    <div class="sidenav-content d-flex flex-column flex-grow-1">
                        <ng-container *ngIf="viewmodel.dataSource">
                            <div class="row d-flex flex-column flex-grow-1">
                                <div class="col-12 table-responsive d-flex flex-column flex-grow-1">
                                    <app-grid #grid [(dataSource)]="viewmodel.dataSource"
                                        [columnDefinitions]="viewmodel.columnDef"
                                        [showExpanderToggle]="false" [isFixedLayout]="viewmodel.isFixedLayout"
                                        [height]="gridHeight" [detailHeight]="viewmodel.detailHeight"
                                        [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort" [itemSize]="50"
                                        [sortFunction]="viewmodel.sortFunction" (rowSelectionChange)="viewmodel.setCustomerForAssignment($event)"
                                        [emptyMessage]="viewmodel.getEmptyMessage()"
                                        [total]="viewmodel.total"
                                        (getDataBatch)="viewmodel.getBatch($event)"
                                        class="d-flex flex-column flex-grow-1">
                                    </app-grid>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-sidenav-content>
            </mat-sidenav-container>
        </div>
        <div class="col-lg-3 col-sm-12 d-flex flex-column flex-grow-1" style="height: 75vh;">
            <mat-card appearance="outlined" class="w-100 d-flex flex-column flex-grow-1">
                <mat-form-field appearance="outline" color="accent" class="w-100 mb-0">
                    <mat-label>Summary By</mat-label>
                    <mat-select [(value)]="viewmodel.summarySortBy" (selectionChange)="viewmodel.buildSummaryViewModels()">
                        <mat-option [value]="viewmodel.summaryAssigned">
                            {{ viewmodel.summaryAssigned }}
                        </mat-option>
                        <mat-option [value]="viewmodel.summaryCity">
                            {{ viewmodel.summaryCity }}
                        </mat-option>
                        <mat-option [value]="viewmodel.summaryCounty">
                            {{ viewmodel.summaryCounty }}
                        </mat-option>
                        <mat-option [value]="viewmodel.summaryState">
                            {{ viewmodel.summaryState }}
                        </mat-option>
                        <mat-option [value]="viewmodel.summaryZip">
                            {{ viewmodel.summaryZip }}
                        </mat-option>
                        <mat-option [value]="viewmodel.summaryChain">
                            {{ viewmodel.summaryChain }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="inner-card d-flex flex-column flex-grow-1">
                    <mat-form-field appearance="outline" color="accent" class="w-100 search">
                        <span class="search-icon" matPrefix><mat-icon>search</mat-icon></span>
                        <input #summarySearchElement matInput maxlength="50" placeholder="Search" [(ngModel)]="viewmodel.summarySearchText" />
                    </mat-form-field>
                    <cdk-virtual-scroll-viewport #viewPort itemSize="50" minBufferPx="600" maxBufferPx="800" class="d-flex flex-column flex-grow-1">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th [style.top]="inverseOfTranslation" class="summary-head" scope="col" *ngIf="viewmodel.summarySortBy !== viewmodel.summaryAssigned">
                                        {{ viewmodel.summarySortBy }}
                                    </th>
                                    <th [style.top]="inverseOfTranslation" class="summary-head" scope="col" colspan="2" *ngIf="viewmodel.summarySortBy === viewmodel.summaryAssigned">
                                            ZRT / Name
                                    </th>
                                    <th [style.top]="inverseOfTranslation" class="summary-head text-right" scope="col">Assigned</th>
                                    <th [style.top]="inverseOfTranslation" class="summary-head text-right" scope="col" *ngIf="viewmodel.summarySortBy !== viewmodel.summaryAssigned">Unassigned</th>
                                    <th [style.top]="inverseOfTranslation" class="summary-head" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!viewmodel.summaryVms.length">
                                    <td class="text-center" colspan="4"><em>No data matching the summary criteria.</em></td>
                                </tr>
                                <tr *ngIf="viewmodel.summaryVms.length && !viewmodel.summaryVmsFiltered.length">
                                    <td class="text-center" colspan="4"><em>No data matching the filter criteria.</em></td>
                                </tr>
                                <tr *cdkVirtualFor="let item of viewmodel.summaryVmsFiltered">
                                    <td>
                                        {{ item.value }}
                                    </td>
                                    <td *ngIf="viewmodel.summarySortBy === viewmodel.summaryAssigned">
                                    </td>
                                    <td [ngClass]="!item.unassignedCount && !item.assignedCount ? 'text-danger font-weight-bold' : ''" class="text-right">
                                        {{ (projectStateService.project.areStoresVisibleToAll ? item.assignedCount + item.unassignedCount : item.assignedCount) | number }}
                                    </td>
                                    <td class="text-right" *ngIf="viewmodel.summarySortBy !== viewmodel.summaryAssigned">
                                        {{ (projectStateService.project.areStoresVisibleToAll ? 0 : item.unassignedCount) | number }}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.dataValue !== null">
                                            <button class="btn btn-sm text-right" [disabled]="viewmodel.isSummaryFilterDisabled()" (click)="viewmodel.searchBySummary(item.dataValue ? item.dataValue : item.value)">
                                                <fa-icon [icon]="viewmodel.faEye"></fa-icon>
                                            </button>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </cdk-virtual-scroll-viewport>
                    <table *ngIf="viewmodel.summaryVms.length" class="table table-striped table-hover">
                        <tr>
                            <td colspan="2" class="ml-3">
                                <strong>Total Unassigned</strong>
                            </td>
                            <td [ngClass]="viewmodel.getUnassignedCount() ? 'text-danger font-weight-bold' : ''" class="text-right">
                                {{ (viewmodel.getUnassignedCount()) | number }}
                            </td>
                            <td>
                                <button class="btn btn-sm"
                                    [disabled]="viewmodel.isSummaryFilterDisabled() || viewmodel.summarySortBy == viewmodel.summaryAssigned"
                                    (click)="viewmodel.showUnassignedFromSummary()">
                                    <fa-icon [icon]="viewmodel.faEye"></fa-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card>
        </div>
    </div>
</ng-container>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DatabaseService } from "src/app/services/database.service";
import { DataSyncQueueService } from "../data-sync-queue.service";
import {
    GenericVersionResponseDto,
    PictureDto,
    SharedHelper,
    SystemInformationKeys,
    VersionResponseDto
} from "shield.shared";
import { DataSyncHandlerInterface } from "../data-sync-handler-interface";
import { DataSyncHandlerBase } from "../data-sync-handler-base";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { Picture } from "src/app/entity-models/picture.entity";
import { SyncLevel } from "../sync-enums/sync-level.enum";
import { PictureConverterService } from "src/app/services/converter-services/picture-conveter.service";

@Injectable()
export class CallPictureDataSyncHandler
    extends DataSyncHandlerBase
    implements DataSyncHandlerInterface {

    onlineRepSyncType = SyncLevel.None;
    offlineRepSyncType = SyncLevel.Optional;
    isRunSuccessfull = false;
    syncVersionKey = SyncVersionKeyNames.callPicture;
    maxVersion: string = null;

    constructor(
        private dbService: DatabaseService,
        private http: HttpClient,
        private syncQueue: DataSyncQueueService
    ) {
        super();
    }

    async execute(): Promise<void> {
        this.log("Syncing call pictures...");

        await this.pullData();

        this.log("Done syncing call pictures...");
    }

    private async checkVersion(lastVersion: string): Promise<boolean> {
        if (!lastVersion) {
            return true; // need to sync first time
        }

        try {
            await this.getMaxVersion();
            return this.maxVersion !== lastVersion;
        } catch {
            return false;
        }
    }

    private get lastSyncVersion(): string {
        return localStorage.getItem(this.syncVersionKey);
    }

    private set lastSyncVersion(value: string) {
        localStorage.setItem(this.syncVersionKey, value);
    }

    private async pullData(): Promise<void> {
        if (!(await this.checkVersion(this.lastSyncVersion))) {
            this.log("Call picture data is up to date");
            this.isRunSuccessfull = true;
            return;
        }
        this.log("Call picture data is out of date, syncing...");

        const maxBatchSizeObj = await this.dbService.systemInformation.where("key").equals(SystemInformationKeys.customerPullSyncBatchSize)?.first();
        const maxBatchSize = SharedHelper.parseInt(maxBatchSizeObj?.value) ?? 50;
        try {
            let thisBatchSize: number;

            do {
                const lastVersion = this.lastSyncVersion;
                const versionQuery =
                    lastVersion && lastVersion !== "null"
                        ? `&version=${encodeURIComponent(lastVersion)}`
                        : "";
                const query = `?take=${maxBatchSize}${versionQuery}`;

                const response = await this.http
                    .get<GenericVersionResponseDto<PictureDto[]>>(`/api/calls/pictures${query}`)
                    .toPromise();

                thisBatchSize = response.values?.length ?? 0;

                this.log(
                    `Downloaded ${thisBatchSize} call pictures, saving to IndexedDB...`
                );

                let currentPicturesCount = 0;

                if (response.values != null && response.values.length > 0) {
                    for (const dto of response.values) {
                        if (dto.onlineReference != null && dto.onlineReference.url) {
                            try {
                                const pictureResponse = await this.http.get(dto.onlineReference.url, { responseType: "blob" })
                                    .toPromise();

                                const reader = new FileReader();
                                reader.readAsDataURL(pictureResponse);
                                reader.onload = async () => {
                                    const rtn = new Picture();
                                    rtn.id = dto.id;
                                    rtn.image = reader.result.toString();
                                    await this.dbService.pictures.put(rtn);
                                    currentPicturesCount++;
                                };
                            }
                            catch {
                                //TODO - Add logic to notify the server that this blob does not exist.
                            }
                        }
                    }
                }

                this.log(`  Saved  ${currentPicturesCount} call pictures.`);

                this.lastSyncVersion = response.maxVersion;
            } while (thisBatchSize > 0);

            this.log("Done saving call picture data.");
            this.isRunSuccessfull = true;
        } catch (e) {
            this.isRunSuccessfull = false;
            console.error("Error syncing call pictures", e);
        }
    }

    async pushData(): Promise<void> { }

    private async getMaxVersion(): Promise<void> {
        const resp = await this.http
            .get<VersionResponseDto>("/api/calls/pictures/version")
            .toPromise();
        this.maxVersion = resp.maxVersion;
    }

}

import { Injectable } from "@angular/core";
import { CustomerTypeEnum, GenericLookup } from "shield.shared";
import { Address } from "src/app/entity-models/address.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { formatDate } from "@angular/common";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AccountViewModel } from "../accounts-list/account.viewmodel";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { GoogleMapLatLng } from "../accounts-list/googleMapsModels";

@Injectable()
export class CustomerMapService {
    private static getImageURL(routed: boolean, customerType: GenericLookup<CustomerTypeEnum>, px3Rank: string) {
        let partialName = "independent-store";
        if (routed) {
            partialName = "routed-store";
        } else if (px3Rank) {
            partialName = "px3-ranked";
        } else if (customerType.id === CustomerTypeEnum.ChainHQ) {
            partialName = "hq";
        } else if (customerType.id === CustomerTypeEnum.IndirectWholesaler) {
            partialName = "w-indirect";
        } else if (customerType.id === CustomerTypeEnum.DirectWholesaler) {
            partialName = "w-direct";
        } else if (customerType.id === CustomerTypeEnum.ChainRetail) {
            partialName = "chain";
        }
        return `assets/img/store-types/${partialName}-icon.png`;
    }

    static getCustomerIconUrl(c: Customer | AccountViewModel, px3Rank: string): string {
        if (!c) return;
        return CustomerMapService.getImageURL(c.isRouted, c.customerType, px3Rank);
    }

    static getIconFromCustomerMarker(c: CustomerMarker): google.maps.Icon {
        if (!c) return;
        return {
            url: CustomerMapService.getImageURL(c.routed, c.customerType, c.px3Rank),
            scaledSize: { width: 20, height: 20 } as google.maps.Size
        }
    }

    static getMarkerAddressParts(
        address: Address,
        foundLatLngs?: CustomerMarker[]
    ): { lat: number; lng: number; cityStateZip: string; addressText: string } {
        const cityStateZip = address
            ? `${address.city}, ${address.state} ${address.zip}`
            : "";
        const addressText = address
            ? `${address.address1}${ address.address2 ?? ""}`
            : "";
        if (address && (!address?.latitude || !address?.longitude)) {
            const latlng = foundLatLngs?.find((ll) => ll.addressText === address.address1)
            return {
                lat: latlng?.address?.latitude,
                lng: latlng?.address?.longitude,
                cityStateZip,
                addressText
            };
        }
        return {
            lat: address?.latitude,
            lng: address?.longitude,
            cityStateZip,
            addressText
        };
    }

    static getCustomerMapMarker(c: Customer | AccountViewModel, label?: string, latLngs?: CustomerMarker[], px3Rank?: string|undefined): CustomerMarker {
        if (!c) return;
        const iconUrl = this.getCustomerIconUrl(c, px3Rank);
        const address = c.dbaAddress?.address1
            ? c.dbaAddress
            : c.businessAddress;
        const lastCall = c.lastCall
            ? formatDate(
                  c.lastCall,
                  MY_DATE_FORMATS.display.customDateInput,
                  "en-US"
              )
            : "None";
        return {
            ...CustomerMapService.getMarkerAddressParts(address, latLngs),
            customerId: c.id,
            customerNumber: c.customerNumber,
            title: c.name,
            label: label,
            customerType: c.customerType,
            address,
            availability: c.availability ? c.availability : "None/Unknown",
            px3Rank: px3Rank,
            active: c.isActive ? "Yes" : "No",
            lastCall,
            icon: {
                url: iconUrl,
                scaledSize: { width: 20, height: 20 } as google.maps.Size
            },
            routed: c.isRouted
        };
    }

    static getLatLngFromMarker(marker: CustomerMarker): { lat: number, lng: number } {
        if (!marker || !marker.address) return;

        return {
            lat: marker.address.latitude,
            lng: marker.address.longitude
        }
    }

    static isCustomerMarkerVisible(marker: CustomerMarker, lowerBound: GoogleMapLatLng, upperBound: GoogleMapLatLng): boolean {
        return marker.address && lowerBound && upperBound &&
            marker.address.latitude  >= lowerBound.lat &&
            marker.address.latitude  <= upperBound.lat &&
            marker.address.longitude >= lowerBound.lng &&
            marker.address.longitude <= upperBound.lng;
    }
}

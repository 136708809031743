import { Injectable } from "@angular/core";
import { CustomerPx3RankImportDto, GenericResponseDto } from "shield.shared";
import { CustomerPx3Rank } from "src/app/entity-models/customer-px3-rank.entity";
import { Px3IncentivePeriod } from "src/app/entity-models/px3-incentive-period.entity";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { DatabaseService } from "../database.service";
import { Px3RankOfflineService } from "../offline-services/px3-rank-offline.service";
import { Px3OnlineService } from "../online-services/px3-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";
import { HttpErrorResponse } from "@angular/common/http";
import { LogService } from "../log.service";

@Injectable()
export class Px3DelineationService extends DelineationContext<Px3Rank, string> {

    constructor(
        private px3OfflineService: Px3RankOfflineService,
        private px3OnlineService: Px3OnlineService,
        snackbarService: SnackbarService,
        datasourceDelineationService: DatasourceDelineationService,
        dbService: DatabaseService,
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getCurrentAndUpcomingPeriods(): Promise<Px3IncentivePeriod[]> {
        var result = await this.simpleCall<Px3RankOfflineService, Px3OnlineService, undefined, Px3IncentivePeriod[]>
            ("getCurrentAndUpcomingPeriods", undefined, this.px3OfflineService, this.px3OnlineService);

        return result.values;
    }

    async getAll(): Promise<Px3Rank[]> {
        var result = await this.simpleCall<Px3RankOfflineService, Px3OnlineService, undefined, Px3Rank[]>
            ("getAll", undefined, this.px3OfflineService, this.px3OnlineService);

        return result.values.sort((a, b) => a.rank < b.rank ? -1 : 1);
    }

    async getById(id: string): Promise<Px3Rank | undefined> {
        if (!id) return undefined;

        var result = await this.simpleCall<Px3RankOfflineService, Px3OnlineService, string, Px3Rank>
            ("getById", id, this.px3OfflineService, this.px3OnlineService);

        return result.values;
    }

    async getCurrentIncentivePeriod(): Promise<Px3IncentivePeriod | undefined> {
        var result = await this.simpleCall<Px3RankOfflineService, Px3OnlineService, undefined, Px3IncentivePeriod | undefined>
            ("getCurrentIncentivePeriod", undefined, this.px3OfflineService, this.px3OnlineService);
        return result.values;
    }

    async savePx3RankForCustomer(customerId: string, px3RankId?: string): Promise<GenericResponseDto<void>> {
        const result = await this.datasourceDelineationService.makeCall(
            undefined,
            () => this.px3OfflineService.savePx3RankForCustomer(customerId, px3RankId),
            () => this.px3OnlineService.savePx3RankForCustomer(customerId, px3RankId),
        );

        return this.handleError(result);
    }

    async bulkImport(importObj: CustomerPx3RankImportDto): Promise<number | HttpErrorResponse> {
        var result = await this.simpleCall<Px3RankOfflineService, Px3OnlineService, CustomerPx3RankImportDto, number>
            ("bulkImport", importObj, this.px3OfflineService, this.px3OnlineService);

        return result.values;
    }
}

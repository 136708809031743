import moment from "moment";
import { Customer } from "src/app/entity-models/customer.entity";
import { Product } from "src/app/entity-models/product.entity";
import { WholesalerGroupProductCatalogItem } from "src/app/entity-models/wholesaler-group-product-catalog-item.entity";

export class ProductViewmodel extends Product {
    uin: string;
    wholesalerGroupProductId: string;
    wholesalerId: string;
    dateAvailable?: Date;
    wholesaler: Customer;
    dateAvailableFormatted: string;

    static WholesalerGroupProductCatalogItemToProductViewmodel(item: WholesalerGroupProductCatalogItem): ProductViewmodel {
        const rtn = new ProductViewmodel();
        rtn.wholesalerGroupProductId = item.id;
        rtn.blend = item.product.blend;
        rtn.brand = item.product.brand;
        rtn.comboProduct = item.product.comboProduct;
        rtn.description = item.product.description;
        rtn.division = item.product.division;
        rtn.id = item.product.id;
        rtn.isDeactivated = item.product.isDeactivated;
        rtn.isDeleted = item.product.isDeleted;
        rtn.itemNumber = item.product.itemNumber;
        rtn.longDescription = item.product.longDescription;
        rtn.lowestSellableUpc = item.product.lowestSellableUpc;
        rtn.offerSupport = item.product.offerSupport;
        rtn.productCatalog = item.product.productCatalog;
        rtn.returnable = item.product.returnable;
        rtn.returnableUpc = item.product.returnableUpc;
        rtn.rowversion = item.product.rowversion;
        rtn.sellable = item.product.sellable;
        rtn.shapeCut = item.product.shapeCut;
        rtn.shield = item.product.shield;
        rtn.subsidiary = item.product.subsidiary;
        rtn.uin = item.productUIN;
        rtn.upcNumbers = item.product.upcNumbers;
        rtn.upcs = item.product.upcs;
        rtn.webOrderable = item.product.webOrderable;
        rtn.wholesalerGroupProductId = item.id;
        rtn.dateAvailable = item.dateAvailable;
        rtn.dateAvailableFormatted = moment(item.dateAvailable).format('MM/dd/yyyy');

        return rtn;
    }
}

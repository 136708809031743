import { ProductUpc } from "src/app/entity-models/product-upc.entity";
import { Product } from "src/app/entity-models/product.entity";

export class CallProductOutViewModel {
    id: string; //UUID
    product: Product;
    quantity: number;
    units: number;
    price: number;
    wholesalePrice: number;
    unsalable: boolean;
    isInDist: boolean;
    oldQuantity: number;
    oldUnits: number;
    oldRetailPrice: number;
    oldWholesalePrice: number;
    upc: string;
    total: number;
    totalWithTax: number;
    unitsOfMeasure: ProductUpc[];


    stateTaxAmount: number;
    countyTaxAmount: number;
    cityTaxAmount: number;
    statePrepaid: boolean;
    cityPrepaid: boolean;
    countyPrepaid: boolean;
    get isPristine(): boolean {
        return (
            this.quantity === 1 &&
            (this.units === (this.product.returnableUpc?.noOfEaches ?? 1)) &&
            this.price === 0 &&
            this.wholesalePrice === 0
        );
    }
}

import { Subscription } from "rxjs";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { TimeEntry } from "src/app/entity-models/time-entry.entity";
import { Helper } from "src/app/helpers/helper";

export class TimeEntryViewModel extends TimeEntry {
    duration: string;
    vmStart: string;
    vmEnd: string;
    editMode: boolean;
    isCallType: boolean;
    isEditRestricted = false;
    restrictedTypes = ["call"];
    startTimeSubscription: Subscription;
    endTimeSubscription: Subscription;
    typeSubscription: Subscription;
    isDeleted: boolean;

    constructor(defaultTimeEntryType: TimeEntryType, entry?: TimeEntry) {
        super();

        if (entry) {
            this.id = entry.id;
            this.sourceId = entry.sourceId;
            this.start = new Date(entry.start);
            this.end = new Date(entry.end);
            this.type = entry.type ?? defaultTimeEntryType;
            this.name = entry.name;
            this.employeeId = entry.employeeId;
            this.comments = entry.comments;
            this.createdBy = entry.createdBy;
            this.createdDate = entry.createdDate;
            this.updatedBy = entry.updatedBy;
            this.updatedDate = entry.updatedDate;
            this.vmStart = Helper.formatDate(this.start);
            this.vmEnd = Helper.formatDate(this.end);
            this.duration = this.setDuration();
            this.isDeleted = entry.isDeleted;
            this.rowversion = entry.rowversion;
            if (
                this.restrictedTypes.includes(
                    entry.type.name.toLocaleLowerCase()
                )
            ) {
                this.isEditRestricted = true;
            }
        }
    }

    static buildDomainModelFromViewmodel(
        vm: TimeEntryViewModel,
        user: string
    ): TimeEntry {
        const rtn = new TimeEntry();
        rtn.id = vm.id;
        rtn.sourceId = vm.sourceId;
        rtn.start = vm.start;
        rtn.end = vm.end;
        rtn.type = vm.type;
        rtn.name = vm.name;
        rtn.comments = vm.comments;
        rtn.employeeId = vm.employeeId;
        rtn.createdBy = vm.createdBy;
        rtn.createdDate = vm.createdDate;
        rtn.updatedBy = user;
        rtn.updatedDate = new Date();
        rtn.rowversion = vm.rowversion;
        rtn.isDeleted = vm.isDeleted;

        return rtn;
    }

    setDuration(): string {
        let rtn = "";
        const startTime: number = this.start?.getTime();
        const endTime: number = this.end?.getTime();

        if (startTime && endTime && startTime <= endTime) {
            rtn = Helper.getHoursAndMinutesFromMilliseconds(
                endTime - startTime
            );
        }

        return rtn;
    }
}

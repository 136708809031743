import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { MatCarouselComponent } from "@magloft/material-carousel";
import { Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Picture } from "src/app/entity-models/picture.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { Call, CallService } from "../call-services/call.service";
import { WholsaleCallViewmodel } from "./wholesale-call.viewmodel";
import { WholesaleCallApplicationService } from "./wholesale-services/wholesale-call-application.service";
import { faTrash, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { CallTypes } from "shield.shared";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { PictureDelineationService } from "src/app/services/delineation-services/picture-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { RegisteredUserDelineationService } from "src/app/services/delineation-services/registered-user-delineation.service";
import { CountDownViewmodel } from "src/app/dialogs/count-down/count-down.viewmodel";
import { CountDownComponent } from "src/app/dialogs/count-down/count-down.component";

@Component({
    selector: "app-wholesale-call",
    templateUrl: "./wholesale-call.component.html",
    styleUrls: ["./wholesale-call.component.scss"]
})
export class WholesaleCallComponent implements OnInit, OnDestroy {
    @ViewChild("carousel") carousel: MatCarouselComponent;
    vm: WholsaleCallViewmodel;
    pictureTags = "";
    pictureType = "";
    saveAndShareDisabled = true;
    slides: Picture[] = [];
    proportion = 75;
    faTrash: IconDefinition = faTrash;

    private currentEmployee: Employee;

    private observableEmployeeSubscription: Subscription;
    private customerSubscription: Subscription;
    private pictureSubscription: Subscription;
    private currentCustomer: Customer;

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    private countDownModalRef: SwisherOverlayRef<
        CountDownViewmodel,
        CountDownComponent
    >;

    call: Call;

    @Output() cancelCall = new EventEmitter();
    @Output() blockCall = new EventEmitter();

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private appStateService: AppStateService,
        public callService: CallService,
        private wholesaleCallApplicationService: WholesaleCallApplicationService,
        private customerDelineationService: CustomerDelineationService,
        private customerStateService: CustomerStateService,
        private router: Router,
        private overlayService: OverlayService,
        private pictueDelineationService: PictureDelineationService,
        private registeredUserDelineationService: RegisteredUserDelineationService,
        public injectedData: SwisherOverlayRef<
            WholsaleCallViewmodel,
            WholesaleCallComponent
        >
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.injectedData) {
            this.vm = this.injectedData.data;
            this.call = this.vm.wholesaleCall;
            const pictureResponse = await this.pictueDelineationService.getLocalPicturesByIds(
                this.vm.wholesaleCall.callPictures?.map((pic) => pic.id)
            );

            if (!pictureResponse) {
                return;
            }

            this.slides = pictureResponse.values;
            this.setPictureType(0);
        } else {
            if (!this.callService.call) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.callService.observableCall
                );
            }

            if (
                !this.observableEmployeeSubscription ||
                this.observableEmployeeSubscription.closed
            ) {
                this.observableEmployeeSubscription = this.appStateService.currentEmployee.subscribe(
                    (employee) => {
                        if (this.currentEmployee?.id !== employee?.id)
                            this.currentEmployee = employee;
                        if (this.currentEmployee) {
                            void this.startWholesaleCall();
                        }
                    }
                );
            }

            if (
                !this.customerSubscription ||
                this.customerSubscription.closed
            ) {
                this.customerSubscription = this.customerStateService.observableCustomer.subscribe(
                    (customer) => {
                        if (this.currentCustomer?.id !== customer?.id) {
                            this.currentCustomer = customer;
                            if (this.currentCustomer) {
                                void this.startWholesaleCall();
                            }
                        }
                    }
                );
            }
        }
    }

    ngOnDestroy(): void {
        if (this.pictureSubscription && !this.pictureSubscription.closed) {
            this.pictureSubscription.unsubscribe();
        }
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
        if (
            this.observableEmployeeSubscription &&
            !this.observableEmployeeSubscription.closed
        ) {
            this.observableEmployeeSubscription.unsubscribe();
        }
    }

    async deletePicture(): Promise<void> {
        const removedPicture = this.slides.splice(
            this.carousel.currentIndex,
            1
        );

        if (removedPicture) {
            if (this.callService.call?.callType === CallTypes.wholesale) {
                const index = this.callService.call.callPictures.findIndex(
                    (p) => p.id === removedPicture[0].id
                );

                this.callService.call.callPictures.splice(index, 1);
                await this.callService.savePicturesAndNotify();
            }
        }
    }

    async startWholesaleCall(): Promise<void> {
        if (this.currentEmployee && this.currentCustomer) {
            const result = await this.callService.blockCall(
                this.currentEmployee.id,
                this.countDownModalRef,
                this.overlayService
            );
            if (result) {
                this.blockCall.emit();
                void this.router.navigate([
                    "/accounts",
                    this.currentCustomer.id,
                    "profile"
                ]);
                this.pleaseWaitService.killPleaseWait();
                return;
            }

            if (this.currentEmployee.user &&
                Helper.getCustomerGenericType(
                    this.currentCustomer
                ) === CustomerGenericTypes.wholesale
            ) {
                this.callService.blockingCall = false;
                const openCall = await this.callService.getOpenCall(
                    this.currentEmployee
                );

                if (
                    openCall &&
                    openCall.customerId !==
                        this.customerStateService.customer.id
                ) {
                    // let them continue this call if its the oldest open call

                    const openCustomerResponse = await this.customerDelineationService.getById(
                        openCall.customerId
                    );
                    if (!openCustomerResponse) {
                        return;
                    }
                    const openCustomer = openCustomerResponse.values;

                    const data = this.callService.buildBasicCurrentCallModal(
                        openCustomer?.name ?? "this customer"
                    );
                    data.buttonLeftFunction = () => {
                        this.cancelCall.emit();
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };
                    data.buttonRightFunction = () => {
                        void this.router.navigate([
                            "/accounts",
                            openCustomer.id,
                            "profile"
                        ]);
                        this.pleaseWaitService.killPleaseWait();
                        this.modalRef.close();
                    };

                    this.modalRef = this.overlayService.open(
                        ConfirmationDialogComponent,
                        data
                    );
                } else {
                    if (!this.callService.call) {
                        await this.wholesaleCallApplicationService.startCall(
                            this.currentEmployee
                        );
                    }
                    this.vm = new WholsaleCallViewmodel();
                    this.vm.buildViewModelFromDomainModel(
                        this.callService.call as WholesaleCall
                    );

                    if (
                        this.callService.call?.callType === CallTypes.wholesale
                    ) {
                        const pictureResponse = await this.pictueDelineationService.getLocalPicturesByIds(
                            this.callService.call.callPictures?.map((pic) => pic.id)
                        );

                        if (!pictureResponse) {
                            return;
                        }

                        this.slides = pictureResponse.values;
                    }

                    if (
                        !this.pictureSubscription ||
                        this.pictureSubscription.closed
                    ) {
                        this.pictureSubscription = this.callService.observablePictures
                            .pipe(
                                map(async (pictures) => {
                                    if (pictures && pictures.length > 0) {
                                        if (
                                            this.callService.call?.callType ===
                                            CallTypes.wholesale
                                        ) {
                                            this.vm.wholesaleCall = this.callService.call;
                                            const pictureResponse = await this.pictueDelineationService.getLocalPicturesByIds(
                                                this.callService.call.callPictures?.map(
                                                    (pic) => pic.id
                                                )
                                            );

                                            if (!pictureResponse) {
                                                return;
                                            }

                                            this.slides =
                                                pictureResponse.values;
                                        }
                                        if (this.slides?.length > 0) {
                                            const index = this.slides.findIndex(
                                                (p) => p.id === pictures[0].id
                                            );

                                            setTimeout(() => {
                                                if (index !== -1) {
                                                    this.carousel.slideTo(
                                                        index
                                                    );
                                                    this.setPictureType(index);
                                                }
                                            }, 0);
                                        }
                                    }
                                })
                            )
                            .subscribe();
                    }
                    this.setPictureType(0);
                    this.call = this.callService.call;
                }
            }
        }
    }

    onOpenConfirmation(): void {
        if (this.modalRef) {
            this.modalRef.close();
        }
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.buttonRightFunction = () => this.save();
        data.buttonRightText = "Yes";
        data.buttonLeftText = "No";
        data.header = "Confirmation";
        data.message =
            "Saving this call will complete it and make it un-editable. Are you sure you want to complete the call?";
        this.modalRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );
        this.modalRef.afterClosed$.subscribe(() => {
            this.modalRef = undefined;
        });
    }

    onOpenShare(): void {
        const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
        data.shareEmployees = this.vm.shareEmployees;
        data.confirmButtonText = "Save and Share";
        data.comments = this.vm.callComments;
        data.isOffline = true;
        data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
            this.vm.callComments = closeData.comments;
            this.vm.shareEmployees = closeData.shareEmployees;
        };
        data.onSaveShare = async (
            saveData: ShareOptionsDialogViewmodel
        ): Promise<void> => {
            const ids = saveData.shareEmployees.map((e) => e.id);
            await this.callService.shareCall(
                this.call.createdUserId,
                `${Helper.formatDisplayName(this.call)}`,
                ids,
                saveData.comments
            );
            this.callService.call = this.vm.buildDomainModelFromViewModel();
            await this.callService.completeCall();
            this.wholesaleCallApplicationService.resetCall();
            this.shareOverlayRef.close();

            if (this.callService.routeId) {
                await this.router.navigate([
                    "/my-day/route-management",
                    this.callService.routeId,
                    "details"
                ]);
            } else {
                await this.router.navigate(["/accounts/customers"]);
            }
        };
        this.shareOverlayRef = this.overlayService.open(
            ShareOptionsDialogComponent,
            data
        );
    }

    private async save(): Promise<void> {
        this.callService.call = this.vm.buildDomainModelFromViewModel();
        let res:boolean = await this.callService.completeCall();
        if (res) {
            this.wholesaleCallApplicationService.resetCall();
            this.modalRef.close();
            if (this.callService.routeId) {
                await this.router.navigate([
                    "/my-day/route-management",
                    this.callService.routeId,
                    "details"
                ]);
            } else {
                await this.router.navigate(["/accounts/customers"]);
            }
        }
    }

    setPictureType(index: number): void {
        if (index || index === 0) {
            if (this.slides?.length > 0) {
                const picture: Picture = this.slides[index];
                if (picture) {
                    const wholesaleCallPicture = this.vm.pictures.find(
                        (p) => p.id === picture.id
                    );
                    this.pictureType = wholesaleCallPicture?.type;

                    if (wholesaleCallPicture?.tags) {
                        this.pictureTags = wholesaleCallPicture?.tags?.join(
                            ", "
                        );
                    } else {
                        this.pictureTags = "";
                    }
                }
            }
        }
    }
}

import moment from "moment";
import {
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Address } from "src/app/entity-models/address.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
export class AccountViewModel {
    id: string;
    customerNumber: string;
    lastCall: string;
    availability: string;
    name: string;
    businessAddress: Address;
    displayBusinessAddress: string;
    dbaAddress: Address;
    displayDbaAddress: string;
    city: string;
    county: string;
    state: string;
    zip: string;
    zrt: string;
    customerType: GenericLookup<CustomerTypeEnum>;
    customerTypeName: string;
    industryVolume: number;
    callsMade: string;
    swisherVolume: number;
    sharePercent: number;
    msaStore: string;
    phone: string;
    chain: string;
    callable: string;
    dateActivated: string;
    dateDeactivated: string;
    isRouted: boolean;
    isActive: boolean;

    assignedRepName: string;
    accountOwnershipLevel: string;
    storeCount: number;
    px3Rank?: string;
    px3RankId?: string;

    constructor(
        customer: Customer,
        px3Rank?: string,
    ) {
        this.id = customer.id;
        this.customerNumber = customer.customerNumber;
        this.lastCall = customer.lastCall ? moment(customer.lastCall).format(MY_DATE_FORMATS.display.dateInput) : "";
        this.availability = customer.availability;
        this.name = customer.name;
        this.businessAddress = customer.businessAddress;
        this.displayBusinessAddress = customer.businessAddress?.address1;
        this.dbaAddress = customer.dbaAddress;
        this.displayDbaAddress = customer.dbaAddress?.address1 ?? customer.businessAddress?.address1;
        this.city = customer.businessAddress?.city;
        this.county = customer.businessAddress?.county;
        this.state = customer.businessAddress?.state;
        this.zip = customer.businessAddress?.zip;
        this.zrt = customer.zrt;
        this.customerType = customer.customerType;
        this.customerTypeName = this.customerType?.name;
        this.industryVolume = customer.industryVolume ?? 0;
        this.swisherVolume = customer.shieldVolume ?? 0;
        this.sharePercent = customer.shieldVolumePercentage ?? (customer.industryVolume && customer.shieldVolume)
                ? (customer.shieldVolume / customer.industryVolume) : 0;
        this.msaStore = customer.isMsa ? "Y" : "N";
        this.phone = customer.hasPhone ? "Y" : "N";
        this.chain = customer.chain;
        this.callable = customer.isCallable || customer.isCallableOverridden ? "Y" : "N";
        this.px3Rank = px3Rank;
        this.px3RankId = customer.px3RankId;
        this.dateActivated = customer.reactivatedDate
            ? moment(customer.reactivatedDate).format(MY_DATE_FORMATS.display.customDateTimeInput)
            : customer.dateCreated
                ? moment(customer.dateCreated).format(MY_DATE_FORMATS.display.customDateTimeInput)
                : "";
        this.dateDeactivated = customer.deactivatedDate
            ? moment(customer.deactivatedDate).format(MY_DATE_FORMATS.display.customDateTimeInput)
            : "";
        this.isRouted = customer.isRouted;
        this.isActive = customer.isActive;
        this.assignedRepName = customer.assignedEmployeeFullName;
        this.accountOwnershipLevel = customer.accountOwnershipHierarchyLevelName;
        this.storeCount = customer.storeCount;
        if(customer.callsMade === null || customer.callsMade === undefined) {
            this.callsMade = '-';
        } else if (customer.callsMade == 0) {
            this.callsMade = '';
        } else {
            this.callsMade = customer.callsMade.toString();
        }
    }
}

import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { ProjectMetricsViewModel } from './project-metrics.viewmodel';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';
import { ProjectMetricsRefinerService } from './project-metrics-refiner.service';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { ProjectMetricsZrtFilterService } from './project-metrics-zrt-filter.service';
import { ZrtTreeViewComponent } from 'src/app/shared/zrt-tree-view/zrt-tree-view.component';

@Component({
  selector: 'app-project-metrics',
  templateUrl: './project-metrics.component.html',
  styleUrls: ['./project-metrics.component.scss'],
  providers: [PleaseWaitService]
})
export class ProjectMetricsComponent implements AfterViewChecked {
    viewmodel = new ProjectMetricsViewModel(
        this.employeeDelineationService,
        this.formBuilder,
        this.projectDelineationService,
        this.pleaseWaitService,
        this.projectMetricsRefinerService,
        this.stateService,
        this.snackbar,
        this.zrtFilterService
    );
    isVmReady = false;

    @ViewChild("startDateInput") startDateInput: ElementRef;
    @ViewChild("endDateInput") endDateInput: ElementRef;
    @ViewChild("zrtTree") zrtTree: ZrtTreeViewComponent;

    constructor(
        public productDelineationService: ProductDelineationService,
        private employeeDelineationService: EmployeeDelineationService,
        private formBuilder: UntypedFormBuilder,
        private projectDelineationService: ProjectDelineationService,
        private pleaseWaitService: PleaseWaitService,
        private projectMetricsRefinerService: ProjectMetricsRefinerService,
        private stateService: ProjectStateService,
        private snackbar: SnackbarService,
        private zrtFilterService: ProjectMetricsZrtFilterService
    ) { }

    ngAfterViewChecked(): void {
        if (!this.isVmReady) {
            this.isVmReady = true;
            setTimeout(() => {
                this.viewmodel.initialize(
                    this.startDateInput,
                    this.endDateInput,
                    this.zrtTree
                );
            }, 0);
        }
    }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, OnInit, computed, effect, inject, signal, viewChild } from '@angular/core';
import { MissingReceiptsService } from './missing-receipts.service';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Subject, filter, from, map, switchMap } from 'rxjs';
import jsPDF from 'jspdf';
import { Subsidiary } from 'shield.shared';
import { SubsidiaryTypes } from 'src/app/enums/subsidiary-types';
import { image } from 'html2canvas/dist/types/css/types/image';

@Component({
  selector: 'app-missing-receipts',
  templateUrl: './missing-receipts.component.html',
  styleUrl: './missing-receipts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissingReceiptsComponent {
    private missingReceiptService = inject(MissingReceiptsService);
    private productService = inject(ProductDelineationService);

    count = this.missingReceiptService.count;
    customer = this.missingReceiptService.customer;
    wholesaler = this.missingReceiptService.wholesaler;
    call = this.missingReceiptService.call;
    readonly observableCall = toObservable(this.call);
    employee = this.missingReceiptService.employee;
    contact = this.missingReceiptService.contact;
    receiptNumberExtention = this.missingReceiptService.receiptNumberExtention;
    error = this.missingReceiptService.error;
    activeProducts = toSignal(from(this.productService.getAll()).pipe(
        map(_ => this.productService.activeProducts)
    ))
    generateReceipts = signal(false);
    private orderProducts = computed(() => {
        if (!this.wholesaler()) return undefined;
        const products = this.activeProducts();
        const call = this.call();
        return call.orderProducts.map(op => ({
            ...op,
            product: products.get(op.productId)
        }));
    });

    isRetailEAS = computed(() => this.receiptNumberExtention() === "01");
    isRetailSwisher = computed(() => this.receiptNumberExtention() === "00");
    isWholesalerSwisher = computed(() => {
        if (!this.wholesaler()) return false;
        const orderProducts = this.orderProducts();
        const containsSwisher = orderProducts.some(op => op.product.subsidiary === SubsidiaryTypes.swisher || op.product.subsidiary === SubsidiaryTypes.rogueholdingsLlc);
        const rne = this.receiptNumberExtention();
        return containsSwisher && rne === "02"
    });
    isWholesalerEAS = computed(() => {
        if (!this.wholesaler()) return false;
        const orderProducts = this.orderProducts();
        const containsSwisher = orderProducts.some(op => op.product.subsidiary === SubsidiaryTypes.swisher || op.product.subsidiary === SubsidiaryTypes.rogueholdingsLlc);
        const containsEAS = orderProducts.some(op => op.product.subsidiary === SubsidiaryTypes.eas);
        const rne = this.receiptNumberExtention();
        if (containsSwisher && containsEAS && rne === "02") return false;
        return containsEAS && (rne === "02" || rne === "03");
    });


    swisherRetail = viewChild("swisherRetail", {read: ElementRef<HTMLElement>});
    swisherWholesaler = viewChild("swisherWholesaler", {read: ElementRef<HTMLElement>});
    easRetail = viewChild("easRetail", {read: ElementRef<HTMLElement>});
    easWholesaler = viewChild("easWholesaler", {read: ElementRef<HTMLElement>});

    receiptElement = computed(() => {
        switch (true) {
            case this.isWholesalerSwisher():
                return this.swisherWholesaler();
            case this.isWholesalerEAS():
                return this.easWholesaler();
            case this.isRetailSwisher():
                return this.swisherRetail();
            case this.isRetailEAS():
                return this.easRetail();
        }
    });

    export$ = new Subject<void>();
    private _onExport$ = this.export$.pipe(
        map(() => this.receiptElement()),
        filter(element => !!element),
        switchMap(elementToPDF),
        map(pdf => ({
            callId: this.call().id,
            receiptNumberExtention: this.receiptNumberExtention(),
            pdf
        })),
        takeUntilDestroyed()
    ).subscribe(this.missingReceiptService.saveReceipt);

    private _onCountChange = effect(() => {
        if (this.count() === 0) {
            this.generateReceipts.set(false);
        }
    }, {allowSignalWrites: true});

    nextReceiptLoading = this.missingReceiptService.nextReceiptLoading;
    
    hasRequiredValues = computed(() => {
        const customer = this.customer();
        const call = this.call();
        const employee = this.employee();
        const contact = this.contact();
        const receiptNumberExtention = this.receiptNumberExtention();
        const activeProducts = this.activeProducts();
        const hasValues = !!customer && !!call && !!employee && !!contact && !!receiptNumberExtention && !!activeProducts;
        return hasValues;
    })

    private nextGenerateReceiptTimeout: any;
    onGenerateReceipts = effect(() => {
        if (this.generateReceipts() && !this.nextReceiptLoading()) {
            this.nextGenerateReceiptTimeout = setTimeout(() => {
                this.export$.next();
            }, 1000);
        } else if (!this.generateReceipts()) {
            clearTimeout(this.nextGenerateReceiptTimeout);
        }
    })

}

const elementToPDF = async (element: ElementRef<HTMLElement>): Promise<string>  => {
    const htmlElement = element.nativeElement;
    const settings = {
        width: htmlElement.scrollWidth + 20 < 460 ? 460 : htmlElement.scrollWidth + 20,
        height: htmlElement.scrollHeight + 40 < 660 ? 660 : htmlElement.scrollHeight + 40,
        margin: [10, 10, 10, 10],
        scale: 1,
    }

    const pdf = new jsPDF("p", "px", [settings.width, settings.height], true);
    const pdfConf = {
        margin: settings.margin,
        html2canvas: { logging: false, scale: settings.scale, allowTaint: true, useCORS: true, imageTimeout: 0 },
    }
    await pdf.html(htmlElement, pdfConf);
    return pdf.output("dataurlstring");
}

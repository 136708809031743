import moment from "moment";
import { Order } from "src/app/entity-models/order.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

export class OrderSelectionViewmodel {
    id: string;
    customerName: string;
    wholesalerName: string;
    orderDate: moment.Moment;

    constructor(order: Order) {
        this.id = order.id;
        this.customerName = order.customerName;
        this.wholesalerName = order.wholesalerName;
        this.orderDate = moment(order.orderDate);
        this.orderDate.format(MY_DATE_FORMATS.display.dateInput);
    }
}

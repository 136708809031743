import { NgModule } from "@angular/core";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GratisRequestFormComponent } from "./gratis-request-form/gratis-request-form.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { GratisRefinerService } from "./gratis-refiner.service";
import { GratisComponent } from "./gratis.component";
import { SharedModule } from "src/app/shared/shared.module";
import { RejectionReasonComponent } from "./rejection-reason/rejection-reason.component";
import { ManualGratisEntryComponent } from './manual-gratis-entry/manual-gratis-entry.component';
import { GratisZrtFilterService } from "./gratis-zrt-filter.service";
import { NgxCurrencyDirective } from "ngx-currency";



@NgModule({
    declarations: [
        GratisComponent,
        GratisRequestFormComponent,
        RejectionReasonComponent,
        ManualGratisEntryComponent
    ],
    imports: [
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        SharedModule,
        NgxCurrencyDirective,
    ],
    providers: [GratisRefinerService, GratisZrtFilterService]
})
export class GratisModule {}

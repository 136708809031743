import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { OrdersModule } from "../orders/orders.module";
import { SharedModule } from "../shared/shared.module";
import { WholesaleProductsModule } from "../wholesale-products/wholesale-products.module";
import { DirectOrderComponent } from './direct-order/direct-order/direct-order.component';
import { OrderTrackingComponent } from './direct-order/order-tracking/order-tracking.component';
import { QuickOrderComponent } from './direct-order/quick-order/quick-order.component';
import { Px3ImportComponent } from './px3-import/px3-import/px3-import.component';
import { ResourcesRoutingModule } from "./resources.routing.module";
import { WholesalerGroupsListRefinerService } from "./wholesaler-groups/wholesaler-groups-list-refiner.service";
import { WholesalerGroupsListComponent } from "./wholesaler-groups/wholesaler-groups-list.component";
import { ZoneRecapReportZrtFilterService } from "./zone-recap-report/zone-recap-report-filter.service";
import { ZoneRecapReportRefinerService } from "./zone-recap-report/zone-recap-report-refiner.service";
import { ZoneRecapReportComponent } from './zone-recap-report/zone-recap-report.component';

@NgModule({
    declarations: [
        WholesalerGroupsListComponent,
        ZoneRecapReportComponent,
        Px3ImportComponent,
        DirectOrderComponent,
        QuickOrderComponent,
        OrderTrackingComponent,
    ],
    imports: [
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        SharedModule,
        ResourcesRoutingModule,
        ReactiveFormsModule,
        WholesaleProductsModule,
        OrdersModule,
    ],
    providers: [
        WholesalerGroupsListRefinerService,
        ZoneRecapReportRefinerService,
        ZoneRecapReportZrtFilterService
    ]
})
export class ResourcesModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { Observable, from } from 'rxjs';
import { filter, map, startWith, take, tap } from 'rxjs/operators';
import { GenericResponseDto, OrderLineItemStatuses } from 'shield.shared';
import { Customer } from 'src/app/entity-models/customer.entity';
import { OrderLineItem } from 'src/app/entity-models/order-line-item.entity';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { AddProductViewModel } from 'src/app/products/add-products/add-product.viewmodel';
import { AddProductsComponent } from 'src/app/products/add-products/add-products.component';
import { AddProductsViewmodel } from 'src/app/products/add-products/add-products.viewmodel';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { OrderDelineationService } from 'src/app/services/delineation-services/order-delineation.service';
import { ProductDelineationService } from 'src/app/services/delineation-services/product-delineation.service';
import { DialogService } from 'src/app/services/dialog.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { WholesalerViewmodel } from 'src/app/shared/viewmodels/wholesaler.viewmodel';

const DEFAULT_ORDER_QUANTITY = 1;

@Component({
  selector: 'quick-order',
  templateUrl: './quick-order.component.html',
  styleUrls: ['./quick-order.component.scss']
})
export class QuickOrderComponent implements OnInit {
  wholesalers$: Observable<WholesalerViewmodel[]>;
  selectedWholesaler: WholesalerViewmodel[] = [];
  lineItems: OrderLineItem[] = [];
  shipDate = new Date();
  @ViewChild('picker') picker: MatDatepicker<Date>;
  showWholesalerError = false;
  showShipDateError = false;
  showProductsError = false;

  wholesalerDropdownSettings = {
    singleSelection: true,
    showCheckbox: false,
    text: "Select Wholesaler",
    enableCheckAll: false,
    enableFilterSelectAll: false,
    enableSearchFilter: true,
    lazyLoading: true,
    badgeShowLimit: 1,
    labelKey: "displayValue",
    searchBy: ["displayValue"],
    classes: "multi-select-container c-btn pure-checkbox",
    disabled: true,
  };

  private addProductDialog?: SwisherOverlayRef<AddProductsViewmodel, AddProductsComponent>;

  constructor(
    customerService: CustomerDelineationService,
    private waitService: PleaseWaitService,
    private productService: ProductDelineationService,
    private overlayService: OverlayService,
    private orderService: OrderDelineationService,
    private dialogService: DialogService,
  ) {
    this.wholesalers$ = from(customerService.getDirectWholesalers()).pipe(
      startWith(new GenericResponseDto<Customer[]>()),
      map(resp => {
        return resp.values?.map(c => new WholesalerViewmodel(c)) ?? [];
      }),
      tap(_ => this.wholesalerDropdownSettings.disabled = false)
    );
  }

  async addOrderProduct(): Promise<void> {
    const viewmodel = await this.waitService.withSpinnerShowing(async () => {
      const activeProducts = await this.productService.observableActiveProducts
        .pipe(
          filter(p => p.size > 0), 
          map(p => {
            for(const key of p.keys()) {
              if (!p.get(key).sellable) {
                p.delete(key);
              }
            }
            return p;
          }), 
          take(1),
        )
        .toPromise();
      const data = new AddProductsViewmodel(true);
      data.buttonLeftText = "OK";
      data.buttonLeftFunction = () => this.addProducts(data.result);
      data.buildViewmodelProductsFromProductDomainModel(
        activeProducts,
        this.lineItems.map(p => p.productId)
      );
      return data;
    });
    this.addProductDialog = this.overlayService.open(AddProductsComponent, viewmodel, true);
  }

  addProducts(products: AddProductViewModel[]): void {
    const selected = products.filter(p => p.selected);
    this.lineItems.push(...selected.map(p => {
      const upc = p.product.upcs.find(u => u.uom === 'Case');
      if (!upc) {
        throw new Error("Product does not have a case UPC");
      }
      return {
        ...new OrderLineItem(),
        productId: p.product.id,
        productDescription: p.product.description,
        quantity: DEFAULT_ORDER_QUANTITY,
        orderLineItemStatusLookupId: OrderLineItemStatuses.Pending,
        uin: p.product.itemNumber,
        upc: upc.upc,
        unitsPerUpc: upc.noOfEaches,
      }
    }))

    //Once the products have been added, close the add product dialog. The new products
    //should be visible in the products dialog now.
    this.addProductDialog.close(null, true);
  }

  removeOrderProduct(index: number) {
    this.lineItems = this.lineItems.length === 1 ? [] : this.lineItems.splice(index, 1);
  }

  incrementItemQuantity(lineItem: OrderLineItem) {
    lineItem.quantity += 1;
  }

  decrementItemQuantity(lineItem: OrderLineItem) {
    lineItem.quantity = Math.max(lineItem.quantity - 1, 1);
  }

  clearForm() {
    this.lineItems = [];
    this.selectedWholesaler = null;
    this.shipDate = moment().add(30, 'days').toDate();
  }

  ngOnInit(): void {
    this.clearForm();
  }

  async submitOrder() {
    this.showWholesalerError = !this.selectedWholesaler;
    this.showShipDateError = !this.shipDate || this.shipDate < new Date();
    this.showProductsError = this.lineItems.length === 0;

    if (this.showWholesalerError || this.showShipDateError || this.showProductsError) {
      return;
    }

    try {
      const orderNumber = await this.waitService.withSpinnerShowing(() => {
        return this.orderService.createDirectOrder(
          this.selectedWholesaler[0].wholesaler,
          this.lineItems,
          this.shipDate,
        );
      });
      this.dialogService.showPromptDialog(`Order ${orderNumber} submitted.`, "Confirmation", "OK")
        .pipe(
          tap(_ => this.clearForm())
        ).subscribe();
    } catch (e) {
      this.dialogService.showPromptDialog(
        `Something went wrong when the order was attempted to be submitted. 
          Please check the order and try again.`
      ).subscribe();
      throw e;
    }
  }

}

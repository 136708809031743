import { Component, OnInit } from "@angular/core";
//Icons
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
    IconDefinition,
    faCheckSquare,
    faPen,
    faReceipt,
    faSave,
    faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { BehaviorSubject, Subscription, combineLatest, zip } from "rxjs";
import { SharedHelper, newSequentialId } from "shield.shared";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { State } from "src/app/entity-models/state.entity";
import { ChainStoreTypes } from "src/app/enums/chain-store-types";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SyncService } from "src/app/services/sync.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { ChainHeadquarterProfileViewmodel } from "../../profile-master/chain-hq-profile/chain-hq-profile.viewmodel";
import { switchMap, tap } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: "app-chain-hq-info",
    templateUrl: "./chain-hq-info.component.html",
    styleUrls: ["./chain-hq-info.component.scss"]
})
export class ChainHqInfoComponent implements OnInit {
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faSignOutAlt: IconDefinition = faSignOutAlt;
    faReceipt: IconDefinition = faReceipt;
    faCheckSquare: IconDefinition = faCheckSquare;
    viewMode = true;

    overlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    chainHeadquarterViewmodel: ChainHeadquarterProfileViewmodel = new ChainHeadquarterProfileViewmodel(
        this.employeeDelineationService,
        this.customerDelineationService,
        this.accountOwnershipDelineationService
    );

    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    formattedPhone: string;

    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    phoneFormControl = new UntypedFormControl(
        this.chainHeadquarterViewmodel?.customer?.phone,
        {
            validators: [Validators.pattern(this.usPhoneNumberRegEx)],
            updateOn: "blur"
        }
    );

    phoneNumberSubscription: Subscription;
    private employee: Employee;
    private customer: Customer;

    states: State[] = [];
    private shouldWait$ = new BehaviorSubject<void>(null);

    constructor(
        private pleaseWaitService: PleaseWaitService,
        private customerStateService: CustomerStateService,
        private overlayService: OverlayService,
        private router: Router,
        private filterService: FilterService,
        private appStateService: AppStateService,
        private syncService: SyncService,
        private employeeDelineationService: EmployeeDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.states = await this.filterService.getStates();

        combineLatest([this.appStateService.currentEmployee, this.customerStateService.observableCustomer])
            .pipe(
                tap( ([employee, customer]) => {
                    this.employee = employee;
                    this.customer = customer;
                }), 
                switchMap(_ => this.setupViewmodel()), untilDestroyed(this)
            )
            .subscribe();

        this.phoneFormControl.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(
                () => {
                    if (
                        this.phoneFormControl.valid &&
                        this.chainHeadquarterViewmodel.customer
                    ) {
                        this.chainHeadquarterViewmodel.customer.phone = this
                            .phoneFormControl.value as string;
                    }
                }
            );
    }

    onUltimateCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyUltimateCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.ultimate;
            if (this.chainHeadquarterViewmodel.immediateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.immediateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.ownerCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ownerCustomer = null;
            }
        }
    }

    onImmediateCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyImmediateCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.immediate;
            if (this.chainHeadquarterViewmodel.ultimateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ultimateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.ownerCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ownerCustomer = null;
            }
        }
    }

    onOwnerCustomerChange(c: Customer): void {
        this.chainHeadquarterViewmodel.dirtyOwnerCustomer = true;
        if (c && c.id === this.chainHeadquarterViewmodel.customer.id) {
            this.chainHeadquarterViewmodel.type = ChainStoreTypes.owner;
            if (this.chainHeadquarterViewmodel.ultimateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.ultimateCustomer = null;
            }
            if (this.chainHeadquarterViewmodel.immediateCustomer?.id === c.id) {
                this.chainHeadquarterViewmodel.immediateCustomer = null;
            }
        }
    }

    async onUltimateRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.ultimateOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.ultimateCustomerRep = employeeResponse.values.fullName;
    }

    async onImmediateRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.immediateOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.immediateCustomerRep = employeeResponse.values.fullName;
    }

    async onOwnerRepChange(e: Employee): Promise<void> {
        const employeeResponse = await this.employeeDelineationService.getById(e.id);

        if (!employeeResponse || !employeeResponse.values) return;

        this.chainHeadquarterViewmodel.ownerOwnership.assignedEmployeeId = employeeResponse.values.id;
        this.chainHeadquarterViewmodel.ownerCustomerRep = employeeResponse.values.fullName;
    }

    compareCustomerOptions(a: Customer, b: Customer): boolean {
        return a?.id === b?.id;
    }

    setMode = (): void => {
        this.viewMode = !this.viewMode;
        this.formattedPhone = SharedHelper.formatPhoneNumber(
            this.chainHeadquarterViewmodel.customer.phone
        );
    };

    openDialog = async (): Promise<void> => {
        const result = await this.chainHeadquarterViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            if (this.overlayRef) {
                this.overlayRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message =
                "Your changes have not been saved. Are you sure you want to cancel?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = () => {
                this.overlayRef.close();
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.overlayRef.close();
                void this.router.navigate([
                    "/accounts",
                    this.customerStateService.customer.id,
                    "profile"
                ]);
            };
            this.overlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.overlayRef.afterClosed$.subscribe(() => {
                this.overlayRef = undefined;
            });
        } else {
            this.setMode();
            void this.router.navigate([
                "/accounts",
                this.customerStateService.customer.id,
                "profile"
            ]);
        }
    };

    async save(): Promise<void> {
        const result = await this.chainHeadquarterViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            result.customer.lastEdited = new Date();
            result.customer.lastEditedBy = this.employee.fullName;
            if (this.customerStateService.customer.isActive !== result.customer.isActive) {
                result.customer.isActive
                    ? result.customer.reactivatedDate = new Date()
                    : result.customer.deactivatedDate = new Date();
            }

            const customerResponse = await this.customerDelineationService.upsertCustomer(result.customer);
            if (!customerResponse) { return; }
        }

        this.syncService.forceOutboundSync();

        void this.router.navigate([
            "/accounts",
            this.customerStateService.customer.id,
            "profile"
        ]);
    }

    private async setupViewmodel(): Promise<void> {
        if (this.customer && this.employee) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            this.phoneFormControl.setValue(this.customer.phone);
            let chainHeadquarterResponse = await this.customerDelineationService.getById(this.customer.id);

            if (!chainHeadquarterResponse) { return; }
            let chainHeadquarter = chainHeadquarterResponse.values;

            if (!chainHeadquarter) {
                chainHeadquarter ??= new Customer();
                chainHeadquarter.id = newSequentialId();
            }
            await this.chainHeadquarterViewmodel.buildViewmodelFromDomainModel(
                chainHeadquarter,
                this.customer,
                undefined,
                this.employee
            );
            this.formattedPhone = SharedHelper.formatPhoneNumber(this.customer.phone);

            this.shouldWait$.next();
        }
    }
}

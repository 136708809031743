import { Component, signal } from '@angular/core';
import { WholesalerBaseComponent } from '../wholesaler-format-base/wholesaler-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-eas-wholesaler-format-draft',
    templateUrl: './eas-wholesaler-format-draft.component.html',
    styleUrls: ['./eas-wholesaler-format-draft.component.scss']
})
export class EasWholesalerFormatDraftComponent extends WholesalerBaseComponent {
    subsidiary = signal(Subsidiary.EAS);
}

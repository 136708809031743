import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    AccountBatchParamsDto,
    CustomerDto,
    CustomerMarkerDto,
    CustomerTypeEnum,
    FilterRequestV2Dto,
    GenericRequestDto,
    GenericResponseDto,
    newSequentialId,
    WholesalerDropdownColumns,
    WholesalerFilterDto
} from "shield.shared";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { Customer } from "../../entity-models/customer.entity";
import { ConverterHelper } from "../converter-services/converter.helper";
import { CustomerConverterService } from "../converter-services/customer-converter.service";
import { DatabaseService } from "../database.service";

@Injectable()
export class CustomerOnlineService {
    constructor(private http: HttpClient,
        private dbSevice: DatabaseService) { }

    async getById(id: string): Promise<GenericResponseDto<Customer>> {
        const response = await this.http
            .get<GenericResponseDto<CustomerDto>>(`/api/customers/${id}`)
            .toPromise();

        return ConverterHelper.dtoToEntity<CustomerDto, Customer>(response, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getCustomersByIds(ids: string[]): Promise<GenericResponseDto<Customer[]>> {
        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = ids;

        const result = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/customers/get-by-ids`,
                request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getCustomersByOwnerCode(key: string): Promise<GenericResponseDto<Customer[]>> {
        const result = await this.http
            .get<GenericResponseDto<CustomerDto[]>>(`/api/customers/customerId/${key}`).toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getByCustomerTypes(customerTypes: CustomerTypeEnum[]): Promise<GenericResponseDto<Customer[]>> {
        const request = new GenericRequestDto<CustomerTypeEnum[]>();
        request.id = newSequentialId();
        request.values = customerTypes;

        const response = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/customers/get-by-type`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(response, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async upsertCustomer(customer: Customer): Promise<GenericResponseDto<Customer>> {
        const request = new GenericRequestDto<CustomerDto>();
        request.id = newSequentialId();
        request.values = CustomerConverterService.customerToCustomerDto(customer);

        const rtn = new GenericResponseDto<Customer | undefined>();

        await this.dbSevice.customers.put(customer);
        const response = await this.http
            .post<GenericResponseDto<CustomerDto>>(`/api/customers/save`, request)
            .toPromise();

        return ConverterHelper.dtoToEntity(response, (r) => CustomerConverterService.customerDtoToCustomer(r))
    }

    async getWholesalersForCustomer(customer: Customer): Promise<GenericResponseDto<Customer[]>> {
        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = customer.customerWholesalers.map((cw) => cw.wholesalerId);

        const response = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/wholesalers/get-by-ids`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(response, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getByIds(ids: string[]): Promise<GenericResponseDto<Customer[]>> {
        const request = new GenericRequestDto<string[]>();
        request.id = newSequentialId();
        request.values = ids

        const response = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/customers/get-by-ids`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(response, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getBatch(params: AccountBatchParamsDto): Promise<GenericResponseDto<Customer[]>> {
        const result = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/customers/list`, params.filterRequestDto)
            .toPromise();

        return ConverterHelper.dtoToEntityArray<CustomerDto, Customer>(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    getCustomerListExport(
        params: AccountBatchParamsDto
    ): Observable<Blob> {
        return this.http.post('/api/customers/export', params.filterRequestDto, { responseType: 'blob' });
    }

    async getWholesalersWithGroupProducts(): Promise<GenericResponseDto<Customer[]>> {

        const result = await this.http
            .get<GenericResponseDto<CustomerDto[]>>(`/api/wholesaler-groups/product-catalogs/get-wholesalers-with-products`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getWholesalersbyProductId(productId: string): Promise<GenericResponseDto<Customer[]>> {

        const result = await this.http
            .get<GenericResponseDto<CustomerDto[]>>(`/api/wholesalers/get-by-product-id/${productId}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getWholesalersInWholesalerGroups(key: undefined): Promise<GenericResponseDto<Customer[]>> {

        const filter = new WholesalerFilterDto();
        filter.hasWholesalerGroups = true;
        filter.hasWholesalerGroupProducts = false;

        const filterDto = new FilterRequestV2Dto<WholesalerFilterDto, WholesalerDropdownColumns>();
        filterDto.filters = filter;

        const request = new GenericRequestDto<FilterRequestV2Dto<WholesalerFilterDto, WholesalerDropdownColumns>>();
        request.id = newSequentialId();
        request.values = filterDto;

        const result = await this.http
            .post<GenericResponseDto<CustomerDto[]>>(`/api/wholesalers`, request)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerDtoToCustomer(r));
    }

    async getMarkersByEmployeeId(employeeId: string): Promise<GenericResponseDto<CustomerMarker[]>> {

        const result = await this.http
            .get<GenericResponseDto<CustomerMarkerDto[]>>(`/api/customers/markers/employee/${employeeId}`)
            .toPromise();

        return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerMarkerDtoToCustomerMarker(r));
    }

    async getMarkersByFilters(params: AccountBatchParamsDto): Promise<GenericResponseDto<CustomerMarker[]>> {
        const result = await this.http
            .post<GenericResponseDto<CustomerMarkerDto[]>>(`/api/customers/markers/`, params.filterRequestDto)
            .toPromise();

            return ConverterHelper.dtoToEntityArray(result, (r) => CustomerConverterService.customerMarkerDtoToCustomerMarker(r));

    }
}

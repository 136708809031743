import { NgxCurrencyInputMode, NgxCurrencyConfig } from "ngx-currency";

export const customCurrencyMaskConfig: NgxCurrencyConfig = {
    align: "right",
    allowNegative: false,
    allowZero: true,
    decimal: ".",
    precision: 2,
    prefix: "$ ",
    suffix: "",
    thousands: ",",
    nullable: true,
    min: 0,
    max: 1000000.0,
    inputMode: NgxCurrencyInputMode.Financial
};

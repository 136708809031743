import { CommonFilters, GenericLookup, ProjectStatuses, ProjectStatusLookup, ProjectTypeLookup, ProjectTypes, VisibleProjectStatuses } from "shield.shared";
import { FilterService } from "src/app/services/filter.service";

export class ProjectAttributesViewmodel {
    filterService: FilterService;
    all: CommonFilters.All = CommonFilters.All;

    allProjectStatus = new GenericLookup<ProjectStatuses>();
    isAllProjectStatusPresent: boolean;
    projectStatuses = new Array<GenericLookup<ProjectStatuses>>();
    selectedProjectStatuses = new Array<GenericLookup<ProjectStatuses>>();

    allProjectType = new GenericLookup<ProjectTypes>();
    isAllProjectTypePresent: boolean;
    projectTypes = new Array<GenericLookup<ProjectTypes>>();
    selectedProjectTypes = new Array<GenericLookup<ProjectTypes>>();

    constructor(filterService: FilterService) {
        this.filterService = filterService;
        this.allProjectType.name = this.all
        this.allProjectStatus.name = this.all;
    }

    

    async initializeDropDowns(): Promise<void> {
        this.allProjectStatus.description = "All";
        this.projectStatuses.push(this.allProjectStatus);
        for (const status of ProjectStatusLookup.filter(p => VisibleProjectStatuses.includes(p.id))) {
            this.projectStatuses.push(status);
        }

        this.allProjectType.description = "All";
        this.selectedProjectTypes.push(this.allProjectType);
        this.projectTypes.push(this.allProjectType);
        for (const type of ProjectTypeLookup) {
            this.projectTypes.push(type);
        }
    }
}

<div class="container-auto">
    <div class="row details-list">
        <div class="col-12 col-lg-6">
            <mat-card>
                <h1>Order #{{details.orderNumber}} status</h1>
                <div class="status-container">
                    <hr/>
                    <div class="status">
                        <div 
                            class="status-item" 
                            [ngClass]="{'current': details.orderStatus === 'Pending'}"
                        >
                            <fa-icon [icon]="faThumbsUp"></fa-icon>
                            <div class="sphere"></div>
                            <span>Order Recieved</span>
                        </div>
                        <div 
                            class="status-item"
                            [ngClass]="{'current': details.orderStatus === 'Waiting'}"
                        >
                            <fa-icon [icon]="faArchive"></fa-icon>
                            <div class="sphere"></div>
                            <span>Processing Order</span>
                        </div>
                        <div 
                            class="status-item"
                            [ngClass]="{'current': details.orderStatus === 'Shipped'}"
                        >
                            <fa-icon [icon]="faTruck"></fa-icon>
                            <div class="sphere"></div>
                            <span>Shipped</span>
                        </div>
                        <div 
                            class="status-item"
                            [ngClass]="{'current': details.orderStatus === 'Invoiced'}"
                        >
                            <fa-icon [icon]="faCheck"></fa-icon>
                            <div class="sphere"></div>
                            <span>Invoiced</span>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card>
                <h1>Order details for #{{details.orderNumber}}</h1>
                <div class="row">
                    <div class="section col-12 col-lg-6">
                        <div class="entry">
                            <div class="name">PO Number:</div>
                            <div class="value">{{details.poNumber}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Status:</div>
                            <div class="value">{{details.status}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Customer:</div>
                            <div class="value">{{details.customerName}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Bill To:</div>
                            <div class="value">{{details.billTo}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Ship To:</div>
                            <div class="value">{{details.shipTo}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Customer Service Rep:</div>
                            <div class="value">{{details.lineItems[0].customerServiceRep}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Shipped Via:</div>
                            <div class="value">{{details.lineItems[0].shipVia}}</div>
                        </div>
                    </div>
                    <div class="section col-12 col-lg-6">
                        <div class="entry">
                            <div class="name">Order Date:</div>
                            <div class="value">{{details.lineItems[0].shipDate | date: 'shortDate'}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Subsidiary:</div>
                            <div class="value">{{details.subsidiary}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Salesperson:</div>
                            <div class="value">{{details.salesperson}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Ship To:</div>
                            <div class="value">{{details.shipTo}}</div>
                        </div>
                        <div class="entry">
                            <div class="name">Order Origination:</div>
                            <div class="value">{{details.orderOrigination}}</div>
                        </div>
                    </div>
                </div>
            </mat-card>
            <mat-card>
                <h1>Invoices</h1>
                <div *ngIf="reportUrl">
                    <ng-container *ngFor="let item of getUniqueInvoiceNumbers() as invoices; let i = index">
                        <a 
                            href="{{formatInvoiceLink(item.invoiceNumber, item.invoiceInternalId)}}" 
                            target="_blank"
                        >{{item.invoiceNumber}}</a>
                        <span *ngIf="invoices.length > i + 1">, </span>
                    </ng-container>
                </div>
            </mat-card>
            <mat-card>
                <h1>Tracking Numbers</h1>
                <div>
                    <a href="{{details.trackingUrl}}">
                        {{details.trackingNumbers.join(", ")}}
                    </a>
                </div>
            </mat-card>
        </div>
        <div class="col-12 col-lg-6">
            <mat-card>
                <h1>Items</h1>
                <mat-card *ngFor="let item of details.lineItems">
                    <div class="items-list">
                        <div>
                            <span>Item: </span>
                            {{item.itemName}}
                        </div>
                        <div>
                            <span>Qty: </span>
                            {{item.quantity}} &#64; {{item.unitPrice | currency}}
                        </div>
                        <div>
                            <span>Total: </span>
                            {{(item.unitPrice * item.quantity) | currency}}
                        </div>
                        <div>
                            <span>Ship Date: </span>
                            {{item.shipDate | date: 'shortDate'}}
                        </div>
                        <div *ngIf="reportUrl">
                            <span>Invoice Number: </span>
                            <a href="{{formatInvoiceLink(item.invoiceNumber, item.invoiceInternalId)}}">
                                {{item.invoiceNumber}}
                            </a>
                        </div>
                        <div>
                            <span>Status: </span>
                            {{item.itemStatus}}
                        </div>
                    </div>
                </mat-card>
            </mat-card>
        </div>
    </div>
</div>
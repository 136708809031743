import { UntypedFormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { NamedStringDto } from "shield.shared";
import { Product } from "src/app/entity-models/product.entity";

export class GratisShippedDetailViewmodel {
    id: string;
    product: Product;
    quantity: number;
    unitOfMeasure: NamedStringDto;
    availableUnitOfMeasures: NamedStringDto[];
    costPerUnit: number;
    isDeleted: boolean;
    cbuSubscription: Subscription;
    formGroup = new UntypedFormGroup({});

    unsubscribe(): void {
        if (this.cbuSubscription) {
            this.cbuSubscription.unsubscribe();
        }
    }
}

<div class="wrapper">
    <mat-card appearance="outlined">
        <div class="row">
            <div class="col-12">
                <div class="card card-project-panel mb-3">
                    <div class="card-header">Metrics</div>
                    <div class="card-body un-scrollable p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="header d-flex p-3 justify-content-center align-items-center">
                                    <div class="flex-grow-1">
                                        <mat-form-field appearance="outline" color="accent" class="mat-input-search"
                                            [formGroup]="viewmodel.dateForm">
                                            <mat-label>Start Date</mat-label>
                                            <input #startDateInput
                                                matInput
                                                formControlName="startDate"
                                                [min]="viewmodel.startDateDefault"
                                                [max]="viewmodel.endDateDefault"
                                                [matDatepicker]="afterPicker"
                                                (keypress)="viewmodel.blockNonDateCharacters($event)"
                                                (keyup)="viewmodel.onAddStartDateRefiner($event)"
                                                (dateChange)="viewmodel.onAddStartDateRefiner(); viewmodel.getMetrics()"
                                                (blur)="viewmodel.onAddStartDateRefiner($event); viewmodel.getMetrics()" />
                                            <mat-datepicker touchUi #afterPicker></mat-datepicker>
                                            <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
                                        </mat-form-field>
                                        <mat-error *ngIf="
                                                viewmodel.dateForm
                                                    .get('startDate')
                                                    .hasError('dateGreaterThanEndDate')
                                            ">
                                            Start date can not be greater than End date.
                                        </mat-error>
                                    </div>
                                    <div class="flex-grow-1">
                                        <mat-form-field appearance="outline" color="accent" class="mat-input-search"
                                            [formGroup]="viewmodel.dateForm">
                                            <mat-label>End Date</mat-label>
                                            <input #endDateInput
                                                matInput
                                                formControlName="endDate"
                                                [min]="viewmodel.startDateDefault"
                                                [max]="viewmodel.endDateDefault"
                                                [matDatepicker]="beforePicker"
                                                (keypress)="viewmodel.blockNonDateCharacters($event)"
                                                (keyup)="viewmodel.onAddEndDateRefiner($event)"
                                                (dateChange)="viewmodel.onAddEndDateRefiner(); viewmodel.getMetrics()"
                                                (blur)="viewmodel.onAddEndDateRefiner($event); viewmodel.getMetrics()" />
                                            <mat-datepicker touchUi #beforePicker></mat-datepicker>
                                            <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
                                        </mat-form-field>
                                        <mat-error *ngIf="
                                                viewmodel.dateForm
                                                    .get('endDate')
                                                    .hasError('dateLessThanStartDate')
                                            ">
                                            End date can not be less than Start date.
                                        </mat-error>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div class="mat-input-search">
                                            <app-zrt-tree-view #zrtTree></app-zrt-tree-view>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h3 class="h-center">Summarize By: </h3>
                                        </div>
                                        <mat-button-toggle-group
                                            [(ngModel)]="viewmodel.summaryInput"
                                            (ngModelChange)="viewmodel.getMetrics()"
                                        >
                                            <mat-button-toggle [value]="viewmodel.summaryOptions.zrt">ZRT</mat-button-toggle>
                                            <mat-button-toggle [value]="viewmodel.summaryOptions.wholesaler">Wholesaler</mat-button-toggle>
                                            <mat-button-toggle [value]="viewmodel.summaryOptions.products">Products</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>{{ viewmodel.summaryInput }}</th>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.zrt">
                                                    <th class="text-right">Assigned</th>
                                                    <th class="text-right">Called On</th>
                                                    <th class="text-right">Not Called</th>
                                                    <th class="text-right">Stores w/ Orders</th>
                                                    <th class="text-right">Order Qty</th>
                                                    <th class="text-right">Cash Sales Qty</th>
                                                    <th class="text-right">Total Sticks (Orders)</th>
                                                    <th class="text-right">Total Sticks (Cash Sales)</th>
                                                    <th class="text-right">Order Amount</th>
                                                    <th class="text-right">Cash Sales Amount</th>
                                                    <th class="text-right">Gratis Amount</th>
                                                    <th class="text-right">Gratis %</th>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.wholesaler">
                                                    <th class="text-right">Total Calls</th>
                                                    <th class="text-right">Calls w/ Wholesaler</th>
                                                    <th class="text-right">Calls w/out Wholesaler</th>
                                                    <th class="text-right">Stores w/ Orders</th>
                                                    <th class="text-right">Order Qty</th>
                                                    <th class="text-right">Total Sticks (Orders)</th>
                                                    <th class="text-right">Order Amount</th>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.products">
                                                    <th class="text-right">Stores w/ Orders</th>
                                                    <th class="text-right">Cash Sales Qty</th>
                                                    <th class="text-right">Order Qty</th>
                                                    <th class="text-right">Total Sticks (Orders)</th>
                                                    <th class="text-right">Cash Sales Amount</th>
                                                    <th class="text-right">Order Amount</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="
                                                    let item of viewmodel.gridData
                                                ">
                                                <td>{{ item.name }}</td>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.zrt">
                                                    <td class="text-right">{{ item?.assigned | number }}</td>
                                                    <td class="text-right">{{ item?.calledOn | number }}</td>
                                                    <td class="text-right">{{ item?.notCalled | number }}</td>
                                                    <td class="text-right">{{ item?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ item?.orderCount | number }}</td>
                                                    <td class="text-right">{{ item?.cashCount | number }}</td>
                                                    <td class="text-right">{{ item?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ item?.cashTotalSticks | number }}</td>
                                                    <td class="text-right">{{ item?.orderAmount | currency }}</td>
                                                    <td class="text-right">{{ item?.cashAmount | currency }}</td>
                                                    <td class="text-right">{{ item?.gratisAmount | currency }}</td>
                                                    <td class="text-right">{{ item?.gratisPercentage | percent }}</td>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.wholesaler">
                                                    <td class="text-right">{{ item?.totalCalls | number }}</td>
                                                    <td class="text-right">{{ item?.callsWithWholesaler | number }}</td>
                                                    <td class="text-right">{{ item?.callsWithoutWholesaler | number }}</td>
                                                    <td class="text-right">{{ item?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ item?.orderCount | number }}</td>
                                                    <td class="text-right">{{ item?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ item?.orderAmount | currency }}</td>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.products">
                                                    <td class="text-right">{{ item?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ item?.cashCount | number }}</td>
                                                    <td class="text-right">{{ item?.orderCount | number }}</td>
                                                    <td class="text-right">{{ item?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ item?.cashAmount | currency }}</td>
                                                    <td class="text-right">{{ item?.orderAmount | currency }}</td>
                                                </ng-container>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td><b>Total</b></td>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.zrt">
                                                    <td class="text-right">{{ viewmodel.total?.assigned | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.calledOn | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.notCalled | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderCount | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.cashCount | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.cashTotalSticks | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderAmount | currency }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.cashAmount | currency }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.gratisAmount | currency }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.gratisPercentage | percent }}</td>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.wholesaler">
                                                    <td class="text-right">{{ viewmodel.total?.totalCalls | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.callsWithWholesaler | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.callsWithoutWholesaler | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderCount | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderAmount | currency }}</td>
                                                </ng-container>
                                                <ng-container *ngIf="viewmodel.summaryInput === viewmodel.summaryOptions.products">
                                                    <td class="text-right">{{ viewmodel.total?.storesWithOrders | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.cashCount | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderCount | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderTotalSticks | number }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.cashAmount | currency }}</td>
                                                    <td class="text-right">{{ viewmodel.total?.orderAmount | currency }}</td>
                                                </ng-container>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</div>

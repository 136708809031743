import { Component, ElementRef, QueryList, ViewChildren, signal } from '@angular/core';
import { WholesalerBaseComponent } from '../wholesaler-format-base/wholesaler-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-eas-wholesaler-swisher-rep-format',
    templateUrl: './eas-wholesaler-swisher-rep-format.component.html',
    styleUrls: ['./eas-wholesaler-swisher-rep-format.component.scss']
})
export class EasWholesalerSwisherRepFormatComponent extends WholesalerBaseComponent {

    @ViewChildren("originalEasWholesaleReceipt") originalEasWholesaleReceipt: QueryList<ElementRef>;

    printLogo = "/assets/img/easPrintLogo.jpg";
    subsidiary = signal(Subsidiary.EAS);
}

import { OrderDto, OrderExtractionDto, OrderLineItemDto } from "shield.shared";
import { OrderExtraction } from "src/app/entity-models/order-extraction.entity";
import { OrderLineItem } from "src/app/entity-models/order-line-item.entity";
import { Order } from "src/app/entity-models/order.entity";

export class OrderConverterService {

    static orderDtoToOrder(dto: OrderDto): Order {
        return {
            ...dto,
            orderStatusId: dto.orderStatusId,
            orderLineItems: dto.orderLineItems?.map((oli) =>
                OrderConverterService.orderLineItemDtoToOrderLineItem(oli)
            ),
            cancelledDate: dto.cancelledDate
                ? new Date(dto.cancelledDate)
                : null,
            orderDate: dto.orderDate ? new Date(dto.orderDate) : new Date(),
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : new Date(),
            modifiedUtcDateTime: dto.modifiedUtcDateTime
                ? new Date(dto.modifiedUtcDateTime)
                : new Date(),
            callDate: dto.callDate ? new Date(dto.callDate) : new Date()
        };
    }
    
    static orderLineItemToOrderLineItemDto(li: OrderLineItem): OrderLineItemDto {
        return {
            ...li,
            orderLineItemStatusId: li.orderLineItemStatusLookupId,
            createdUtcDateTime: li.createdUtcDateTime?.toISOString(),
            modifiedUtcDateTime: li.modifiedUtcDateTime?.toISOString(),
        }
    }

    static orderLineItemDtoToOrderLineItem(
        dto: OrderLineItemDto
    ): OrderLineItem {
        return {
            ...dto,
            orderLineItemStatusLookupId: dto.orderLineItemStatusId,
            createdUtcDateTime: dto.createdUtcDateTime ? new Date(dto.createdUtcDateTime) : null,
            modifiedUtcDateTime: dto.modifiedUtcDateTime ? new Date(dto.modifiedUtcDateTime) : null
        };
    }

    static orderExtractionDtoToOrderExtraction(dto: OrderExtractionDto): OrderExtraction {
        return {
            ...dto,
            id: dto.id,
            wholesalerId: dto.wholesalerId,
            createdUtcDateTime: dto.createdUtcDateTime
                ? new Date(dto.createdUtcDateTime)
                : new Date(),
            orders: dto.orders?.map((o) =>
                OrderConverterService.orderDtoToOrder(o)
            )
        };
    }
}

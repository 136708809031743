<div *ngIf="dataSource && columnDefinitions"
    class="d-flex flex-column justify-content-between bg-white align-items-center">
    <div *ngIf="hasGlobalSearch" class="w-100">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Search</mat-label>
            <input matInput (blur)="onApplyFilter($event)" placeholder="Ex. ium" #globalFilter>
        </mat-form-field>
    </div>
    <div *ngIf="isEnabledInternalGridControls" class="w-100">
        <mat-form-field appearance="outline" color="accent" class="w-100">
            <mat-label>Columns Visibility</mat-label>
            <mat-select #gridColumnSelect [(value)]="selectedColumnVisability"
                (openedChange)="onOpenedColumnSelectChange()" multiple>
                <mat-option *ngFor="let column of availableColumnsToDisplay" [value]="column">
                    {{ column }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="shouldDisplayInternalEntryCount" class="w-50 text-center">
        Entries Displayed: {{dataSource?.filteredData ? dataSource?.filteredData?.length : 0}}
    </div>
    <div>
        <div *ngIf="isEnabledInternalGridControls">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon class="menu-more">more_vert</mat-icon>
            </button>
        </div>
        <mat-menu #menu="matMenu">
            <button appThrottleClick (throttledClick)="onExportAsExcel()" mat-menu-item>
                <mat-icon class="menu-icon">file_download</mat-icon>
                <span class="mat-menu-span">Export Excel</span>
            </button>
            <button *ngIf="hasExpandableDetails" (click)="onRowDetailAdjust()" mat-menu-item>
                <mat-icon class="menu-icon">details</mat-icon>
                <span class="mat-menu-span">{{isEnabledExpandedDetails ? "Disable Row Details" : "Enable Row Details"
                    }}</span>
            </button>
            <button mat-menu-item (click)="onSetHasGlobalSearch()">
                <mat-icon class="menu-icon">travel_explore</mat-icon>
                <span class="mat-menu-span">{{hasGlobalSearch ? "Disable Global Search" : "Enable Global Search"
                    }}</span>
            </button>
            <button mat-menu-item (click)="isFixedLayout = !isFixedLayout">
                <mat-icon class="menu-icon">view_module</mat-icon>
                <span class="mat-menu-span">{{isFixedLayout ? "Disable Fixed Column Width" : "Enable Fixed Column Width"
                    }}</span>
            </button>
        </mat-menu>
    </div>
</div>
<div *ngIf="isVirtualScroll" class="d-flex flex-grow-1">
    <div id="table" class="table-section d-flex flex-grow-1">
        <cdk-virtual-scroll-viewport #viewport appendOnly [tvsItemSize]="itemSize" minBufferPx="50" maxBufferPx="100"
            (scrolledIndexChange)="onScrolledIndexChanged($event)" class="d-flex flex-grow-1">
            <table class="table table-bordered table-hover" #table #grid mat-table recycleRows [dataSource]="dataSource"
                multiTemplateDataRows cdkDropList cdkDropListOrientation="horizontal"
                (cdkDropListDropped)="drop($event)" (contentChanged)="contentChanged()" [fixedLayout]="isFixedLayout"
                matSort>
                <ng-container *ngFor="let column of columnDefinitions" [matColumnDef]="column.dataPropertyName">
                    <th [style]="'top'" [class]="'grid-cell' + ' ' + column.columnClass" mat-header-cell cdkDrag
                        matRipple *matHeaderCellDef mat-sort-header [disabled]="!isSortable" (click)="onSort(column)">
                        <div class="flex" (click)="toggleAllRowExpantion($event)">
                            <fa-icon *ngIf="showExpanderToggle &&
                            isEnabledExpandedDetails &&
                            !areAllRowsExpanded &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon pull-right"
                                [icon]="faCaretDown">
                            </fa-icon>
                            <fa-icon *ngIf="showExpanderToggle &&
                            areAllRowsExpanded &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon pull-right"
                                [icon]="faCaretUp">
                            </fa-icon>
                        </div>
                        <div *ngIf="column.isSelectable && column.dataType != dataTypes.checkboxNoHeader">
                            <mat-checkbox #theOneCheckboxToRuleThemAll class="checkbox-margin"
                                [checked]="allColumnsChecked" [color]="'warn'"
                                [indeterminate]="someColumnsChecked(dataSource)"
                                (change)="setAllColumnsChecked($event.checked, dataSource)"
                                [disabled]="!dataSource?.filteredData?.length" (click)="$event.stopPropagation()">
                                {{column.headerName}}
                            </mat-checkbox>
                        </div>
                        <b *ngIf="!column.isSelectable || (column.isSelectable && column.dataType == dataTypes.checkboxNoHeader)"
                            class="header-underline">
                            {{column.headerName}}
                        </b>
                    </th>
                    <td mat-cell [class]="'grid-cell' + (column.columnClass ? ' ' + column.columnClass : '')" matRipple
                        *matCellDef="let element"
                        [ngClass]="column.ngColumnClass ? column.ngColumnClassCriterion(element.index) ? column.ngColumnClass : '' : ''"
                        (click)="column.clickFunction && column.dataType === dataTypes.text ? onClick(column, $event, element.index) : undefined">
                        <div class="flex">
                            <div *ngIf="showExpanderToggle">
                                <fa-icon *ngIf="!element.isExpanded &&
                            element.detailArrayName &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon" [icon]="faCaretDown">
                                </fa-icon>
                                <fa-icon *ngIf="element.isExpanded &&
                            element.detailArrayName &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon" [icon]="faCaretUp">
                                </fa-icon>
                            </div>
                            <div *ngIf="column.isAvailable && !column.isDataNested">
                                <div *ngIf="column.isSelectable">
                                    <mat-checkbox [(ngModel)]="element.data.isSelected"
                                        [disabled]="column.selectDisableCriterion ? column.selectDisableCriterion(element.index) : false"
                                        (ngModelChange)="rowSelectionChanged(element.index)" [color]="'warn'">
                                    </mat-checkbox>
                                </div>
                                <div *ngIf="!column.isSelectable">
                                    <div *ngIf="column.clickFunction && column.isTemplate">
                                        <div (click)="onClick(column, $event, element.index)"
                                            [innerHTML]="element.data[column.dataPropertyName]"></div>
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.isTemplate">
                                        <div [innerHTML]="element.data[column.dataPropertyName]"></div>
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.dataType === dataTypes.currency">
                                        {{element.data[column.dataPropertyName] | currency}}
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.dataType === dataTypes.percent">
                                        {{element.data[column.dataPropertyName] | percent}}
                                    </div>
                                    <div
                                        *ngIf="!column.clickFunction && column.dataType === dataTypes.percentPlusTwoPlaces">
                                        {{element.data[column.dataPropertyName] | percent:'1.2-2'}}
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.dataType === dataTypes.number">
                                        {{element.data[column.dataPropertyName] | number}}
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.dataType === dataTypes.date">
                                        {{element.data[column.dataPropertyName] | date: dateFormat }}
                                    </div>
                                    <div *ngIf="!column.clickFunction && column.dataType === dataTypes.moment">
                                        {{element.data[column.dataPropertyName] | date: momentFormat }}
                                    </div>
                                    <div *ngIf="column.clickFunction &&
                                            (column.dataType === dataTypes.text ||
                                            column.dataType === dataTypes.numberOrString)">
                                        <div>
                                            {{element.data[column.dataPropertyName]}}</div>
                                    </div>
                                    <a class="link-column" *ngIf="column.clickFunction && !column.isTemplate && !column.dataType">
                                        <div (click)="onClick(column, $event, element.index)">
                                            {{element.data[column.dataPropertyName]}}
                                        </div>
                                        <icon-marker *ngIf="column.showIconMarker"
                                            [iconName]="column.iconName"
                                            (click)="onClick(column, $event, element.index)"
                                        ></icon-marker>
                                    </a>
                                    <div *ngIf="column.dataType === dataTypes.buttons" class="flex">
                                        <div *ngFor="let button of column.buttons">
                                            <button *ngIf="button.isVisible(element.index)" mat-raised-button
                                                class="btn btn-white mr-1 ml-1" [disabled]="button.isDisabled"
                                                [title]="button.title" (click)="button.onClick(element.index)">
                                                <fa-icon [icon]="button.icon" class="mr-2"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="!column.clickFunction && !column.isTemplate && (!column.dataType || column.dataType === dataTypes.numberOrString)">
                                        {{element.data[column.dataPropertyName]}}</div>
                                </div>
                            </div>
                            <div *ngIf="column.isAvailable && column.isDataNested">
                                <div *ngIf="column.isSelectable">
                                    <mat-checkbox></mat-checkbox>
                                </div>
                                <div *ngIf="column.nestedDataParentPropertyName">
                                    <a *ngIf="column.clickFunction" (click)="onClick(column, $event, element.index)">
                                        {{element.data[column.nestedDataParentPropertyName][column.nestedDataPropertyName]}}
                                    </a>
                                    <div *ngIf="!column.clickFunction">
                                        {{element.data[column.nestedDataParentPropertyName][column.nestedDataPropertyName]}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <div>
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let row"
                            [attr.colspan]="columnVisability?.length ? columnVisability?.length : 0">

                            <div class="row element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">
                                <ng-container
                                    *ngTemplateOutlet="detailTemplate; context: { data : row.data[row.detailArrayName] }"
                                    ;>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </div>


                <tr mat-header-row *matHeaderRowDef="columnVisability;sticky: true"></tr>
                <tr mat-row *matRowDef="let element; columns: columnVisability" class="element-row"
                [class.expanded-row]="element.isExpanded" (click)="toggleTableRow(element)"
                [class.table-striped-even]="dataSource?.filteredData?.indexOf(element) % 2 == 0">
                </tr>
                <div *ngIf="hasExpandableDetails && isEnabledExpandedDetails && detailTemplate && dataSource">
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row bg-white"></tr>
                    <div *ngIf="hasGlobalSearch">
                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td *ngIf="dataSource && columnVisability" class="mat-cell"
                                [attr.colspan]="columnVisability.length">No data
                                matching the selected
                                filter(s)
                            </td>
                        </tr>
                    </div>
                </div>
                <div *ngIf="dataSource?.data && emptyMessage">
                    <tr class="mat-row" *matNoDataRow>
                        <td *ngIf="dataSource.data.length == 0 && columnVisability" class="mat-cell text-center"
                            [attr.colspan]="columnVisability.length">
                            {{ emptyMessage }}
                        </td>
                    </tr>
                </div>
            </table>
            <div *ngIf="total > dataSource?.data?.length" class="d-flex justify-content-center">
                <mat-spinner class="mt-2 mb-2"></mat-spinner>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>
<div *ngIf="!isVirtualScroll" class="d-flex flex-grow-1">
    <div id="table" class="table-section d-flex flex-grow-1" [style]="{
    height: height?.length > 0 ? height : '',
    width: width?.length > 0 ? width : ''
}">
        <table class="table table-bordered table-hover" #table #grid mat-table recycleRows
            [dataSource]="renderedDataSource" multiTemplateDataRows cdkDropList cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="drop($event)" (contentChanged)="contentChanged()" [fixedLayout]="isFixedLayout"
            matSort>
            <ng-container *ngFor="let column of columnDefinitions" [matColumnDef]="column.dataPropertyName">
                <th [class]="'grid-cell' + ' ' + column.columnClass" mat-header-cell cdkDrag matRipple *matHeaderCellDef
                    mat-sort-header [disabled]="!isSortable" (click)="onSort(column)">
                    <div class="flex" (click)="toggleAllRowExpantion($event)">
                        <fa-icon *ngIf="showExpanderToggle &&
                            isEnabledExpandedDetails &&
                            !areAllRowsExpanded &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon pull-right"
                            [icon]="faCaretDown">
                        </fa-icon>
                        <fa-icon *ngIf="showExpanderToggle &&
                            areAllRowsExpanded &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon pull-right"
                            [icon]="faCaretUp">
                        </fa-icon>
                    </div>
                    <div *ngIf="column.isSelectable">
                        <mat-checkbox class="checkbox-margin" [checked]="allColumnsChecked" [color]="'warn'"
                            [indeterminate]="someColumnsChecked(renderedDataSource)"
                            (change)="setAllColumnsChecked($event.checked, renderedDataSource)"
                            (click)="$event.stopPropagation()">
                            {{column.headerName}}
                        </mat-checkbox>
                    </div>
                    <b *ngIf="!column.isSelectable" class="header-underline">{{column.headerName}}
                    </b>
                </th>
                <td mat-cell [class]="'grid-cell' + ' ' + column.columnClass" matRipple *matCellDef="let element"
                    [ngClass]="column.ngColumnClass ? column.ngColumnClassCriterion(element.index) ? column.ngColumnClass : '' : ''">
                    <div class="flex"
                        [ngStyle]="column.dataType === dataTypes.buttons && {'width': (2 * column.buttons.length) + 'vw' }">
                        <div *ngIf="showExpanderToggle">
                            <fa-icon *ngIf="!element.isExpanded &&
                            element.detailArrayName &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon" [icon]="faCaretDown">
                            </fa-icon>
                            <fa-icon *ngIf="element.isExpanded &&
                            element.detailArrayName &&
                            column.headerName === firstSelectableColumnDisplayed" class="icon" [icon]="faCaretUp">
                            </fa-icon>
                        </div>
                        <div *ngIf="column.isAvailable && !column.isDataNested">
                            <div *ngIf="column.isSelectable">
                                <mat-checkbox [(ngModel)]="element.data.isSelected"
                                    (ngModelChange)="rowSelectionChanged(element.index)" [color]="'warn'">
                                </mat-checkbox>
                            </div>
                            <div *ngIf="!column.isSelectable">
                                <div *ngIf="column.clickFunction && column.isTemplate">
                                    <div (click)="onClick(column, $event, element.index)"
                                        [innerHTML]="element.data[column.dataPropertyName]"></div>
                                </div>
                                <div *ngIf="!column.clickFunction && column.isTemplate">
                                    <div [innerHTML]="element.data[column.dataPropertyName]"></div>
                                </div>
                                <div *ngIf="!column.clickFunction && column.dataType === dataTypes.currency">
                                    {{element.data[column.dataPropertyName] | currency}}
                                </div>
                                <div *ngIf="!column.clickFunction && column.dataType === dataTypes.percent">
                                    {{element.data[column.dataPropertyName] | percent}}
                                </div>
                                <div *ngIf="!column.clickFunction && column.dataType === dataTypes.number">
                                    {{element.data[column.dataPropertyName] | number}}
                                </div>
                                <div *ngIf="!column.clickFunction && column.dataType === dataTypes.date">
                                    {{element.data[column.dataPropertyName] | date: dateFormat }}
                                </div>
                                <div *ngIf="!column.clickFunction && column.dataType === dataTypes.moment">
                                    {{element.data[column.dataPropertyName] | date: momentFormat }}
                                </div>
                                <div *ngIf="column.clickFunction && (column.dataType === dataTypes.text)">
                                    <div (click)="onClick(column, $event, element.index)">
                                        {{element.data[column.dataPropertyName]}}</div>
                                </div>
                                <a *ngIf="column.clickFunction && !column.isTemplate && !column.dataType">
                                    <div (click)="onClick(column, $event, element.index)">
                                        {{element.data[column.dataPropertyName]}}</div>
                                </a>
                                <div *ngIf="column.dataType === dataTypes.buttons" class="flex">
                                    <div *ngFor="let button of column.buttons">
                                        <button *ngIf="button.isVisible(element.index)" mat-raised-button
                                            class="btn btn-white mr-1 ml-1" [disabled]="button.isDisabled"
                                            [title]="button.title" (click)="button.onClick(element.index)">
                                            <fa-icon [icon]="button.icon" class="mr-2"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="!column.clickFunction && !column.isTemplate && (!column.dataType || column.dataType === dataTypes.numberOrString)">
                                    {{element.data[column.dataPropertyName]}}</div>
                            </div>
                        </div>
                        <div *ngIf="column.isAvailable && column.isDataNested">
                            <div *ngIf="column.isSelectable">
                                <mat-checkbox></mat-checkbox>
                            </div>
                            <div *ngIf="column.nestedDataParentPropertyName">
                                <a *ngIf="column.clickFunction" (click)="onClick(column, $event, element.index)">
                                    {{element.data[column.nestedDataParentPropertyName][column.nestedDataPropertyName]}}
                                </a>
                                <div *ngIf="!column.clickFunction">
                                    {{element.data[column.nestedDataParentPropertyName][column.nestedDataPropertyName]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>
            <div>
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let row"
                        [attr.colspan]="columnVisability?.length ? columnVisability?.length : 0">

                        <div class="row element-detail" [@detailExpand]="row.isExpanded ? 'expanded' : 'collapsed'">
                            <ng-container
                                *ngTemplateOutlet="detailTemplate; context: { data : row.data[row.detailArrayName] }" ;>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>
            </div>
            <tr mat-header-row *matHeaderRowDef="columnVisability;sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: columnVisability" class="element-row"
            [class.expanded-row]="element.isExpanded" (click)="toggleTableRow(element)"
            [class.table-striped-even]="dataSource?.filteredData?.indexOf(element) % 2 == 0">
            </tr>
            <div *ngIf="hasExpandableDetails && isEnabledExpandedDetails && detailTemplate && dataSource">
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row bg-white"></tr>
                <div *ngIf="hasGlobalSearch">
                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row" *matNoDataRow>
                        <td *ngIf="dataSource && columnVisability" class="mat-cell"
                            [attr.colspan]="columnVisability.length">No data
                            matching the selected
                            filter(s)
                        </td>
                    </tr>
                </div>
            </div>
        </table>
    </div>
</div>
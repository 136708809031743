import { Component, OnDestroy, OnInit } from "@angular/core";
//Icons
import {
    faPen,
    faSave,
    faSignOutAlt,
    faReceipt,
    faCheckSquare,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
//Dialog imports
import { OverlayService } from "src/app/services/overlay.service";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { Router } from "@angular/router";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { Subscription } from "rxjs/internal/Subscription";
import { WholesaleProfileViewmodel } from "../../profile-master/wholesale-profile/wholesale-account-profile.viewmodel";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { map } from "rxjs/operators";
import {
    UntypedFormControl,
    Validators
} from "@angular/forms";
import { State } from "src/app/entity-models/state.entity";
import { FilterService } from "src/app/services/filter.service";
import { AppStateService } from "src/app/services/app-state.service";
import { Customer } from "src/app/entity-models/customer.entity";
import {
    CustomerTypeEnum,
    GenericLookup,
    SharedHelper
} from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";

@Component({
    selector: "app-wholesale-account-edit",
    templateUrl: "./wholesale-account-edit.component.html",
    styleUrls: ["./wholesale-account-edit.component.css"]
})
export class WholesaleAccountEditComponent implements OnInit, OnDestroy {
    faPen: IconDefinition = faPen;
    faSave: IconDefinition = faSave;
    faSignOutAlt: IconDefinition = faSignOutAlt;
    faReceipt: IconDefinition = faReceipt;
    faCheckSquare: IconDefinition = faCheckSquare;

    overlayRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    wholesalerCustomerSubscription: Subscription;
    wholesalerViewmodel: WholesaleProfileViewmodel = new WholesaleProfileViewmodel(this.wholesalerGroupDelineationService);

    states: State[] = [];
    customerTypes: Array<GenericLookup<CustomerTypeEnum>>;

    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;

    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    phoneFormControl = new UntypedFormControl(
        this.wholesalerViewmodel?.customer?.phone,
        {
            validators: [Validators.pattern(this.usPhoneNumberRegEx)],
            updateOn: "blur"
        }
    );

    phoneNumberSubscription: Subscription;
    employeeSubscription: Subscription;
    private employeeName: string;
    private employee: Employee;
    private customer: Customer;

    constructor(
        private overlayService: OverlayService,
        private router: Router,
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private filterService: FilterService,
        private appStateService: AppStateService,
        private wholesalerGroupDelineationService: WholesalerGroupDelineationService
    ) {}

    async ngOnInit(): Promise<void> {
        const customerTypes = await this.filterService.getCustomerTypes();
        this.customerTypes = customerTypes.filter((ct) => ct.id !== CustomerTypeEnum.ChainHQ
            && ct.id !== CustomerTypeEnum.Unknown);
        this.states = await this.filterService.getStates();

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.appStateService.currentEmployee
                .pipe(
                    map(async (employee) => {
                        this.employee = employee;
                        this.employeeName = this.employee.fullName;
                        if(this.customer) {
                            await this.setUpViewmodel();
                        }
                    })
                )
                .subscribe();
        }

        if (
            !this.wholesalerCustomerSubscription ||
            this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        this.customer = customer;

                        if(this.employeeName) {
                            await this.setUpViewmodel();
                        }
                    })
                )
                .subscribe();
        }

        if (
            !this.phoneNumberSubscription ||
            this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription = this.phoneFormControl.valueChanges.subscribe(
                () => {
                    if (this.phoneFormControl.valid) {
                        this.wholesalerViewmodel.customer.phone = this
                            .phoneFormControl.value as string;
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.wholesalerCustomerSubscription &&
            !this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription.unsubscribe();
        }

        if (
            this.phoneNumberSubscription &&
            !this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription.unsubscribe();
        }

        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
    }

    openDialog = (): void => {
        const result = this.wholesalerViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            if (this.overlayRef) {
                this.overlayRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message =
                "Your changes have not been saved. Are you sure you want to cancel?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = () => {
                this.overlayRef.close();
            };
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.overlayRef.close();
                void this.router.navigate([
                    "/accounts",
                    this.customerStateService.customer.id,
                    "profile"
                ]);
            };
            this.overlayRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );
            this.overlayRef.afterClosed$.subscribe(() => {
                this.overlayRef = undefined;
            });
        } else {
            void this.router.navigate([
                "/accounts",
                this.customerStateService.customer.id,
                "profile"
            ]);
        }
    };

    async save(): Promise<void> {
        const result = this.wholesalerViewmodel.buildDomainModelsFromViewModel();
        if (!result.isEqual) {
            result.customer.lastEdited = new Date();
            result.customer.lastEditedBy = this.employeeName;
            if (this.customerStateService.customer.isActive !== result.customer.isActive) {
                result.customer.isActive
                    ? result.customer.reactivatedDate = new Date()
                    : result.customer.deactivatedDate = new Date();
            }

            await this.customerDelineationService.upsertCustomer(result.customer);
        }
        void this.router.navigate([
            "/accounts",
            this.customerStateService.customer.id,
            "profile"
        ]);
    }

    async setUpViewmodel(): Promise<void> {
        this.wholesalerViewmodel.buildViewmodelFromDomainModel(
            this.customer,
            this.employee,
            this.employeeName
        );
        this.phoneFormControl.setValue(
            SharedHelper.formatPhoneNumber(this.customer.phone)
        );
    }
}

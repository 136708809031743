import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import {
    IconDefinition,
    faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { extendMoment } from "moment-range";
import { Subscription } from "rxjs";
import { TimeEntryType } from "src/app/entity-models/time-entry-type.entity";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AddTimeEntryViewmodel } from "./add-time-entry.viewmodel";
import { MatSelect } from "@angular/material/select";
const myMoment = extendMoment(moment);

@Component({
    selector: "app-add-time-entry",
    templateUrl: "./add-time-entry.component.html",
    styleUrls: ["./add-time-entry.component.css"]
})
export class AddTimeEntryComponent implements OnInit, OnDestroy {
    faArrowRight: IconDefinition = faArrowRight;
    timeInputMask = MY_DATE_FORMATS.display.customTime;
    startTime: string;
    endTime: string;
    startTimeInvalid = false;
    endTimeInvalid = false;
    overlap = false;
    timeForm: UntypedFormGroup;
    startTimeSubscription: Subscription;
    endTimeSubscription: Subscription;
    timeTypes: TimeEntryType[] = [];
    selectedTimeEntryType: string;
    timeTwelveHrRegEx = new RegExp(
        /^([1-9]|0[1-9]|1[0-2]):[0-5][0-9] ([AaPp][Mm])$/
    );
    startTimeControl: UntypedFormControl;
    endTimeControl: UntypedFormControl;

    constructor(
        public swisherOverlayRef: SwisherOverlayRef<
            AddTimeEntryViewmodel,
            AddTimeEntryComponent
        >,
        private formBuilder: UntypedFormBuilder
    ) {
        this.formBuilder = formBuilder;
        this.startTimeControl = this.formBuilder.control(this.startTime, {
            validators: [
                this.isOverLapping(),
                this.isStartDateGreaterThenEnd(),
                this.isStartLaterThenNow()
            ],
            updateOn: "blur"
        });
        this.endTimeControl = this.formBuilder.control(this.endTime, {
            validators: [
                this.isOverLapping(),
                this.isStartDateGreaterThenEnd(),
                this.isEndLaterThenNow()
            ],
            updateOn: "blur"
        });

    }

    ngOnInit(): void {
        setTimeout(() => {
            const selectedDate = this.swisherOverlayRef?.data?.selectedDate;
            const now = new Date();
            now.setHours(23, 59, 59, 999);
            if (selectedDate?.getTime() > now.getTime()) {
                this.timeTypes = this.swisherOverlayRef.data.timeEntryTypes.filter(
                    (tt) =>
                        tt.isFutureCompliant && !tt.isOnlyApplicationControlled
                );
                this.selectedTimeEntryType = this.timeTypes.find(
                    (tt) => tt.isDefault && tt.isFutureCompliant
                )?.name;
            } else {
                this.timeTypes = [
                    ...this.swisherOverlayRef.data.timeEntryTypes
                ].filter((tt) => !tt.isOnlyApplicationControlled);
                this.selectedTimeEntryType = this.timeTypes.find(
                    (tt) => tt.isDefault && !tt.isFutureCompliant
                )?.name;
            }
            if (
                !this.startTimeSubscription ||
                this.startTimeSubscription.closed
            ) {
                this.startTimeSubscription = this.startTimeControl.valueChanges.subscribe(
                    () => {
                        if (this.startTimeControl.valid) {
                            const time = this.startTimeControl
                                .value as Date;
                            if (!time) {
                                return;
                            }

                            const myDate = new Date(
                                this.swisherOverlayRef.data.selectedDate
                            );

                            myDate.setHours(
                                time.getHours(),
                                time.getMinutes(),
                                0,
                                0
                            );
                            this.swisherOverlayRef.data.start = myDate;

                            if (this.endTimeControl.invalid) {
                                this.endTimeControl.updateValueAndValidity(
                                    { onlySelf: true }
                                );
                            }
                        }

                        this.swisherOverlayRef.data.hasErrors =
                            !this.endTimeControl.valid ||
                            !this.startTimeControl.valid;
                    }
                );
            }

            if (!this.endTimeSubscription || this.endTimeSubscription.closed) {
                this.endTimeSubscription = this.endTimeControl.valueChanges.subscribe(
                    () => {
                        if (this.endTimeControl.valid) {
                            const time = this.endTimeControl
                                .value as Date;
                            if (!time) {
                                return;
                            }
                            const myDate = new Date(
                                this.swisherOverlayRef.data.selectedDate
                            );
                            myDate.setHours(
                                time.getHours(),
                                time.getMinutes(),
                                0,
                                0
                            );
                            this.swisherOverlayRef.data.end = myDate;
                            if (this.startTimeControl.invalid) {
                                this.startTimeControl.updateValueAndValidity(
                                    { onlySelf: true }
                                );
                            }
                        }

                        this.swisherOverlayRef.data.hasErrors =
                            !this.endTimeControl.valid ||
                            !this.startTimeControl.valid;
                    }
                );
            }
            
            //Update with existing values if we're editing a current entry.
            const curEntry = this.swisherOverlayRef.data.editingTimeEntry;
            const data = this.swisherOverlayRef.data;
            if (curEntry) {
                this.startTimeControl.setValue(curEntry.start);
                this.endTimeControl.setValue(curEntry.end);
                data.start = curEntry.start;
                data.end = curEntry.end;
                data.selectedTimeEntryType = curEntry.type;
                data.comments = curEntry.comments;
                this.validateOnChange();
            }
        }, 0);
    }

    //This is needed for when we update the time entry type object when editing.
    //By default it just compares by object references but that won't work when
    //comparing against a time entry type we provide.
    compareTimeEntryTypes(type1?: TimeEntryType, type2?: TimeEntryType) {
        return type1 && type2 && type1.id === type2.id;
    }

    ngOnDestroy(): void {
        if (this.startTimeSubscription && !this.startTimeSubscription.closed) {
            this.startTimeSubscription.unsubscribe();
        }

        if (this.endTimeSubscription && !this.endTimeSubscription.closed) {
            this.endTimeSubscription.unsubscribe();
        }
    }

    isStartDateGreaterThenEnd(): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let end = null;

            const myStartTime = this.startTimeControl
                ?.value as Date | null;

            if (myStartTime) {
                start = new Date(
                    this.swisherOverlayRef.data.selectedDate.toString()
                );
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    0,
                    0
                );
            }

            const myEndTime = this.endTimeControl
                ?.value as Date | null;

            if (myEndTime) {
                end = new Date(
                    this.swisherOverlayRef.data.selectedDate.toString()
                );
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    0,
                    0
                );
            }

            if (start && end) {
                if (start.getTime() > end.getTime()) {
                    rtn = true;
                }
            }
            return rtn ? { startGreaterThenEnd: true } : null;
        };
    }

    isStartLaterThenNow(): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let now = null;
            let type = this.swisherOverlayRef.data.selectedTimeEntryType?.name;

            const futureTypes = this.timeTypes.find(
                (tt) => tt.isFutureCompliant && tt.name === type
            );

            if (!futureTypes) {
                const myStartTime = this.startTimeControl
                    ?.value as Date | null;

                if (myStartTime) {
                    start = new Date(
                        this.swisherOverlayRef.data.selectedDate.toString()
                    );
                    start.setHours(
                        myStartTime.getHours(),
                        myStartTime.getMinutes(),
                        0,
                        0
                    );
                }

                now = new Date();

                if (start) {
                    if (start.getTime() > now.getTime()) {
                        rtn = true;
                    }
                }
            }
            return rtn ? { startLaterThenNow: true } : null;
        };
    }

    isEndLaterThenNow(): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let end = null;
            let now = null;
            let type = this.swisherOverlayRef.data.selectedTimeEntryType?.name;

            const futureTypes = this.timeTypes.find(
                (tt) => tt.isFutureCompliant && tt.name === type
            );

            if (!futureTypes) {
                const myEndTime = this.endTimeControl
                    ?.value as Date | null;

                if (myEndTime) {
                    end = new Date(
                        this.swisherOverlayRef.data.selectedDate.toString()
                    );
                    end.setHours(
                        myEndTime.getHours(),
                        myEndTime.getMinutes(),
                        0,
                        0
                    );
                }

                now = new Date();

                if (end) {
                    if (end.getTime() > now.getTime()) {
                        rtn = true;
                    }
                }
            }
            return rtn ? { endLaterThenNow: true } : null;
        };
    }

    isOverLapping(): ValidatorFn {
        return (): { [key: string]: boolean } | null => {
            let rtn = false;
            let start = null;
            let end = null;

            const myStartTime = this.startTimeControl
                ?.value as Date | null;

            if (myStartTime) {
                start = new Date(
                    this.swisherOverlayRef.data.selectedDate.toString()
                );
                start.setHours(
                    myStartTime.getHours(),
                    myStartTime.getMinutes(),
                    0,
                    0
                );
            }

            const myEndTime = this.endTimeControl
                ?.value as Date | null;

            if (myEndTime) {
                end = new Date(
                    this.swisherOverlayRef.data.selectedDate.toString()
                );
                end.setHours(
                    myEndTime.getHours(),
                    myEndTime.getMinutes(),
                    0,
                    0
                );
            }

            //Don't compare against the current time entry or any "gap" entries.
            const compareTimeLogs = this.swisherOverlayRef.data.existingDailyTimeLogs.filter(
                entry => entry.type.name && entry.id !== this.swisherOverlayRef.data.editingTimeEntry?.id
            );

            if (start) {
                for (const entry of compareTimeLogs) {
                    if (start > entry.start && start < entry.end) {
                        rtn = true;
                        if (rtn) {
                            break;
                        }
                    }
                }
            }

            if (!rtn) {
                if (end) {
                    for (const entry of compareTimeLogs) {
                        if (
                            end > Helper.trimDateToSeconds(entry.start) &&
                            end < Helper.trimDateToSeconds(entry.end)
                        ) {
                            rtn = true;
                            if (rtn) {
                                break;
                            }
                        }
                    }
                }
            }

            if (!rtn) {
                for (const entry of compareTimeLogs) {
                    const range1 = myMoment.range(moment(start), moment(end));
                    const range2 = myMoment.range(
                        moment(entry.start),
                        moment(entry.end)
                    );
                    rtn = range1.overlaps(range2);
                    if (rtn) {
                        break;
                    }
                }
            }
            return rtn ? { overlap: true } : null;
        };
    }

    validateOnChange(): void {
        this.startTimeControl.updateValueAndValidity();
        this.endTimeControl.updateValueAndValidity();
    }
}

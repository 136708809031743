import { Component, signal } from '@angular/core';
import { Subsidiary } from 'shield.shared';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';

@Component({
    selector: 'app-swisher-retail-wide-tax-format-draft',
    templateUrl: './swisher-retail-wide-tax-format-draft.component.html',
    styleUrls: ['./swisher-retail-wide-tax-format-draft.component.scss']
})
export class SwisherRetailWideTaxFormatDraftComponent extends RetailBaseComponent {
    subsidiary = signal(Subsidiary.Swisher);
}

export const environment = {
    production: true,

    apiRootUrl: "/",

    auth: {
        tenantId: "93b9cbec-1e81-4ca3-8329-75a439ba1bdb",
        clientId: "d7a041fd-b9c6-4b00-9a62-fc52bcab413a",
        apiScope: "api://d7a041fd-b9c6-4b00-9a62-fc52bcab413a/default"
    },

    appInsights: {
        instrumentationKey: "5fe9233f-e4ce-4719-abbd-6b34a0befd59"
    },

    appVersion: require('../../package.json').version,
};

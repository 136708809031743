import { Injectable } from '@angular/core';
import { ConfirmationDialogViewmodel } from '../dialogs/confirmation-dialog/confirmation-dialog.viewmodel';
import { OverlayService } from './overlay.service';
import { Observable, Subject } from 'rxjs';
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { SwisherOverlayRef } from '../overlay/swisher-overlay-ref';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

  private openConfirmDialog?: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
  private openPromptDialog?: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;

  constructor(
    public overlayService: OverlayService,
  ){}

  showConfirmDialog(text?: string, title?: string, noText?: string, yesText?: string): Observable<boolean> {
    const result = new Subject<boolean>();
    const data = new ConfirmationDialogViewmodel();
    data.header = title ?? "Confirmation";
    data.message =
      text ?? "Are you sure you want to close this window? Changes you have made may not be saved.";
    data.buttonLeftText = noText ?? "Cancel";
    data.buttonLeftFunction = () => {
        this.openConfirmDialog?.close(null, true);
        result.next(false);
        result.complete();
    };
    data.buttonRightText = yesText ?? "Yes";
    data.buttonRightFunction = () => {
        this.openConfirmDialog?.close(null, true);
        result.next(true);
        result.complete();
    };

    this.openConfirmDialog = this.overlayService.open(
        ConfirmationDialogComponent,
        data
    );

    return result.asObservable();
  }

  showPromptDialog(text: string, title = "Information", button = "OK"): Observable<void> {
    const result = new Subject<void>();
    const data = new ConfirmationDialogViewmodel();
    data.header = title;
    data.message = text;
    data.buttonRightText = button;
    data.buttonRightFunction = () => {
        this.openPromptDialog?.close(null, true);
        result.next();
        result.complete();
    };

    this.openPromptDialog = this.overlayService.open(
        ConfirmationDialogComponent,
        data
    );

        return result.asObservable();
    }
}

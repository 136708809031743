<div *ngIf="hasSwisherProducts()">
    <div>
        <div class="center">
            ******Draft******Draft******Draft******Draft******Draft******Draft******
            ******Draft******Draft******Draft******Draft******Draft******Draft******
        </div>
        <div class="row prow">
            <div class="col pcol center">
                {{ customer()?.businessAddress?.name }} - Receipt #{{receiptNumber()}}
            </div>
        </div>
        <div *ngIf="customerCode()" class="row prow">
            <div class="col pcol center">
                Distributor Acct # for Retailer {{customerCode()}}
            </div>
        </div>
        <div *ngIf="stateOptLicense()?.licenseNumber" class="row prow">
            <div class="col pcol center">
                Retail License # {{stateOptLicense()?.licenseNumber}}
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol center">
                {{ formattedAddress() }}
            </div>
        </div>
        <div *ngIf="customer()?.businessAddress?.county" class="row prow">
            <div class="col pcol center">{{ customer().businessAddress?.county + ' County' }}</div>
        </div>
        <hr />
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">{{wholesaler().name}}</div>
        </div>
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">State OTP License ID {{wholesalerOptLicense()?.licenseNumber}}</div>
        </div>
        <div *ngIf="orders()?.length > 0">
            <div *ngFor="let order of orders()">
                <br />
                <div class="row prow">
                    <div class="col pcol center">
                        <b>ITEMS TO BE DELIVERED</b>
                    </div>
                </div>
                <div class="row prow">
                    <div class="col pcol">
                        <b>UIN</b>
                    </div>
                    <div class="col-8 pcol-8 center">
                        <b>Order Date {{order.orderDate | date: dateFormat}}</b>
                    </div>
                    <div class="col pcol">
                        <b class="pull-right">Qty</b>
                    </div>
                </div>

                <div *ngFor="let orderProduct of order.products" class="row prow">
                    <div class="col pcol">
                        {{ orderProduct.uin }}
                    </div>
                    <div class="col-8 pcol-8 center">
                        {{ orderProduct.product?.description }}
                    </div>
                    <div class="col pcol align-right">
                        {{ orderProduct.quantity }}
                    </div>
                </div>
                <hr />
            </div>
        </div>
        <div class="center">
            ******Draft******Draft******Draft******Draft******Draft******Draft******
            ******Draft******Draft******Draft******Draft******Draft******Draft******
        </div>
    </div>
</div>

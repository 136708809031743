import { Injectable } from "@angular/core";
import { CustomerOfflineService } from "../offline-services/customer-offline.service";
import { Customer } from "../../entity-models/customer.entity";
import { CustomerOnlineService } from "../online-services/customer-online.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { SnackbarService } from "../snackbar.service";
import {
    AccountBatchParamsDto,
    AccountsListColumns,
    CustomerTypeEnum,
    FilterRequestV2Dto,
    FilterSortDto,
    GenericResponseDto,
    SystemInformationKeys,
} from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { DelineationContext } from "./delineation-context.service";
import { DatabaseService } from "../database.service";
import {
    Observable,
    throwError
} from "rxjs";
import { Product } from "src/app/entity-models/product.entity";
import { DexieTableNames } from "src/app/enums/dexie-table-names";
import { AccountListFilterMapService } from "../filter-map-services/account-list-filter-map.service";
import { CustomerMarker } from "src/app/entity-models/customer-marker.entity";
import { GoogleMapLatLng } from "src/app/accounts/accounts-list/googleMapsModels";
import { WholesalerGroupProductCatalogItemOfflineService } from "../offline-services/wholesaler-group-product-catalog-item-offline.service";
import { SystemInformationDelineationService } from "./system-information-delineation.service";

@Injectable()
export class CustomerDelineationService extends DelineationContext<Customer, string> {

    constructor(private customerOfflineService: CustomerOfflineService,
        private customerOnlineService: CustomerOnlineService,
        snackbarService: SnackbarService,
        protected datasourceDelineationService: DatasourceDelineationService,
        protected dbService: DatabaseService,
        private groupCatalogService: WholesalerGroupProductCatalogItemOfflineService,
        private systemInformation: SystemInformationDelineationService,
    ) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async getCustomersByOwnerCode(code: string): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getCustomersByOwnerCode(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getCustomersByOwnerCode(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Customer[]>(code, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByCustomerTypes(customerTypes: CustomerTypeEnum[], forceOffline?: boolean): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: CustomerTypeEnum[]) => {
            return this.customerOfflineService.getByCustomerTypes(key);
        }
        const online = (key: CustomerTypeEnum[]) => {
            return forceOffline ? this.customerOfflineService.getByCustomerTypes(key) : this.customerOnlineService.getByCustomerTypes(key);
        }
        const response = await this.datasourceDelineationService.makeCall<CustomerTypeEnum[], Customer[]>(customerTypes, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async upsertCustomer(customer: Customer): Promise<GenericResponseDto<Customer>> {
        if (!customer) return;

        const offline = (key: Customer) => {
            return this.customerOfflineService.upsertCustomer(key);
        }
        const online = async (key: Customer) => {
            return await this.customerOnlineService.upsertCustomer(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Customer, Customer>(customer, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        customer = response.values;
        this.setLastCall(customer);
        customer.lastEdited = new Date();
        await this.persist(customer, DexieTableNames.customers);
        this.customerOfflineService.updateCustomerStore(customer);

        return response;
    }

    async getWholesalersForCustomer(customer: Customer): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: Customer) => {
            return this.customerOfflineService.getWholesalersForCustomer(key);
        }
        const online = (key: Customer) => {
            return this.customerOnlineService.getWholesalersForCustomer(key);
        }
        const response = await this.datasourceDelineationService.makeCall<Customer, Customer[] | undefined>(customer, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getByIds(ids: string[], forceOffline?: boolean): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: string[]) => {
            return this.customerOfflineService.getByIds(key);
        }
        const online = (key: string[]) => {
            return forceOffline ? this.customerOfflineService.getByIds(key) : this.customerOnlineService.getByIds(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string[], Customer[] | undefined>(
                ids, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getFromServerByIds(ids: string[]): Promise<GenericResponseDto<Customer[]>> {
            const response = await this.customerOnlineService.getByIds(ids);

            if (response.isError) {
                this.snackbarService.showError(response.message);
                return;
            }

            return response;
    }

    async getById(id: string, forceOffLine?: boolean): Promise<GenericResponseDto<Customer>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getById(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getById(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Customer | undefined>(id, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getBatch(id: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<AccountsListColumns>[]
        , forceOnline?: boolean
    ): Promise<GenericResponseDto<Customer[]>> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.zrts = key.filterRequestDto.filters?.zrtFilterDto?.zrts;
        key.employeeIds = key.filterRequestDto.filters?.zrtFilterDto?.employeeIds;

        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getBatch(key);
        }
        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getBatch(key);
        }

        const response = await this.datasourceDelineationService.makeCall<AccountBatchParamsDto, Customer[]>(key, offline, online, undefined, undefined, forceOnline);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    getAccountListExport(id: string
        , refiners: Refiner[]
        , pageSize: number
        , startIndex: number
        , filterSorts: FilterSortDto<AccountsListColumns>[]
    ): Observable<Blob | never> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.zrts = key.filterRequestDto.filters?.zrtFilterDto?.zrts;
        key.employeeIds = key.filterRequestDto.filters?.zrtFilterDto?.employeeIds;

        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getCustomerListExport(key);
        }
        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getCustomerListExport(key);
        }

        try {
            return this.datasourceDelineationService.makeCallWithBlobReturn<AccountBatchParamsDto, Observable<Blob | never>>(key, offline, online);
        } catch (e) {
            this.snackbarService.showWarning(e);
            return throwError(e);
        }
    }

    async getWholesalersWithGroupProducts(): Promise<GenericResponseDto<Customer[]>> {
        const offline = () => {
            return this.customerOfflineService.getWholesalersWithGroupProducts();
        }
        const online = () => {
            return this.customerOnlineService.getWholesalersWithGroupProducts();
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, Customer[]>(undefined, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getWholesalersByCustomerAndProduct(customer: Customer, product: Product): Promise<GenericResponseDto<Customer[]>> {

        if (!customer || !product) { return; }

        let rtn = new GenericResponseDto<Customer[]>();
        rtn.values = new Array<Customer>();

        const wholesalerIds = customer.customerWholesalers ? customer.customerWholesalers.map((c) => c.wholesalerId) : [];
        if (wholesalerIds.length > 0) {

            const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
                .where("wholesalerId")
                .anyOf(wholesalerIds)
                .and((item) => item.productId === product.id && !item.isDeactivated)
                .toArray();

            const wholesalerIdsWithProduct = [...new Set(wholesalerProductCatalogItems?.map((item) => item.wholesalerId))];

            return this.getByIds(wholesalerIdsWithProduct);
        }

        return rtn;
    }

    async getWholesalersByCustomerWithProducts(customer: Customer): Promise<GenericResponseDto<Customer[]>> {

        if (!customer) { return; }

        let rtn = new GenericResponseDto<Customer[]>();
        rtn.values = new Array<Customer>();

        const wholesalerIds = customer.customerWholesalers ? customer.customerWholesalers.map((c) => c.wholesalerId) : [];
        if (wholesalerIds.length > 0) {

            const wholesalerProductCatalogItems = await this.dbService.wholesalerProductCatalogItems
                .where("wholesalerId")
                .anyOf(wholesalerIds)
                .and(item => !item.isDeactivated)
                .toArray();

            const wholesalerIdsWithProducts = [...new Set(wholesalerProductCatalogItems?.map((item) => item.wholesalerId))];

            return this.getByIds(wholesalerIdsWithProducts);
        }

        return rtn;
    }

    async getWholesalersbyProduct(product: Product): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: string) => {
            return this.customerOfflineService.getWholesalersbyProductId(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getWholesalersbyProductId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Customer[]>(product.id, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getDirectWholesalers(): Promise<GenericResponseDto<Customer[]>> {
        return this.simpleCall(
            "getDirectWholesalers", 
            undefined, 
            this.customerOfflineService, 
            this.customerOfflineService,
        );
    }

    async getSwisherHqCustomer(): Promise<Customer> {
        const swisherHqCustomerNumber = (
            await this.systemInformation.getByKey(SystemInformationKeys.swisherHQCustomerNumber)
        ).values.value;
        return this.dbService.customers
            .where("customerNumber")
            .equals(swisherHqCustomerNumber)
            .first();
    }

    async getMarkersByEmployeeId(employeeId: string): Promise<GenericResponseDto<CustomerMarker[]>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getMarkersByEmployeeId(key);
        }
        const online = (key: string) => {
            return this.customerOnlineService.getMarkersByEmployeeId(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, CustomerMarker[] | undefined>(employeeId, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getMarkersByFilters(
        id: string,
        refiners: Refiner[],
        lowerBound?: GoogleMapLatLng,
        upperBound?: GoogleMapLatLng
    ): Promise<GenericResponseDto<CustomerMarker[]>> {

        const key = new AccountBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        const filters = AccountListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.filters = AccountListFilterMapService.mapLatLngFilter(filters, lowerBound, upperBound);
        key.filterRequestDto.pageSize = 2000;
        key.filterRequestDto.startIndex = 0;
        key.filterRequestDto.filterSorts = new Array<FilterSortDto<AccountsListColumns>>();

        const offline = (key: AccountBatchParamsDto) => {
            return this.customerOfflineService.getMarkersByFilters(key);
        }
        const online = (key: AccountBatchParamsDto) => {
            return this.customerOnlineService.getMarkersByFilters(key);
        }
        const response = await this.datasourceDelineationService.makeCall<AccountBatchParamsDto, CustomerMarker[]>(key, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getCustomerCallableStatus(customerId: string): Promise<GenericResponseDto<boolean>> {
        const offline = (key: string) => {
            return this.customerOfflineService.getCustomerCallableStatus(key);
        }
        const online = (key: string) => {
            return this.customerOfflineService.getCustomerCallableStatus(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, boolean>(customerId, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    //private
    private async setLastCall(customer: Customer): Promise<Customer> {

        const calls = await this.dbService.calls
            .where("customerId")
            .equals(customer.id)
            .toArray();

        const orderedCalls = calls.filter((call) => !!call.stopTime)
            .sort((a, b) => a.stopTime <= b.stopTime ? 1 : -1);

        if (orderedCalls?.length) {
            customer.lastCall = orderedCalls[0].stopTime;
        }

        return customer;
    }

    async getWholesalersInWholesalerGroups(): Promise<GenericResponseDto<Customer[]>> {
        const offline = (key: undefined) => {
            return this.customerOfflineService.getWholesalersInWholesalerGroups(key);
        }
        const online = (key: undefined) => {
            return this.customerOnlineService.getWholesalersInWholesalerGroups(key);
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, Customer[]>(undefined, offline, online);

        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }
}

import {
    CustomerTypeEnum,
    CustomerTypeLookup,
    EmployeeRoleType,
    GenericLookup,
    LicenseTypes,
    newSequentialId,
    SharedHelper
} from "shield.shared";
import { Address } from "src/app/entity-models/address.entity";
import { CustomerLicense } from "src/app/entity-models/customer-license.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { State } from "src/app/entity-models/state.entity";
import { Helper } from "src/app/helpers/helper";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { CustomerProfileViewModel } from "../../profile-master/customer-profile.viewmodel";

const PX3_NOT_RANKED_MESSAGE = 'Not Ranked'

export class RetailInfoViewmodel extends CustomerProfileViewModel {
    customerNumber: string;
    msaStore: string;
    isMsaStore: boolean;
    isCallable: boolean;
    isCallableOverridden: boolean;
    isActive: boolean;
    px3Rank?: string;
    customerTypes: Array<GenericLookup<CustomerTypeEnum>>;
    selectedCustomerType: GenericLookup<CustomerTypeEnum>;
    _phone: string;
    formattedPhone: string;
    chainName: string;
    chainRep: string;
    oldTobaccoLicense: string;
    tobaccoLicense: string;
    tobaccoLicenseLastUpdated: Date | null;
    oldStateTaxIdNumber: string;
    stateTaxIdNumber: string;
    stateTaxIdNumberLastUpdated: Date | null;
    oldFederalTaxIdNumber: string;
    federalTaxIdNumber: string;
    federalTaxIdNumberLastUpdated: Date | null;
    px3Ranks: GenericLookup<string>[];
    selectedPx3Rank?: GenericLookup<string>;
    oldRetailStateVaporLicense: string;
    retailStateVaporLicense: string;
    retailStateVaporLicenseLastUpdated: Date | null;
    storeComments: string;
    customerName: string;
    address1: string;
    address2: string;
    city: string;
    selectedState: string;
    states: State[];
    selectedDbaState: string;
    dbaStates: State[];
    zip: string;
    businessAddressName: string;
    dbaAddressName: string;
    drivingAddress1: string;
    drivingAddress2: string;
    drivingCity: string;
    drivingZip: string;
    filterService: FilterService;
    oldComments: string;
    employee: Employee;
    displayZrt: string;
    manager: string;
    rep: string;
    dateCreated: Date;
    dateDeactivated: Date;
    dateReactivated: Date;
    lastEdited: Date;
    lastEditedBy: string;
    chainRetailCustomerTypeId = CustomerTypeEnum.ChainRetail;
    get msaStatus() {
        let rtn = "Non-Callable";
        if (this.isMsaStore) {
            if (this.isCallable || this.isCallableOverridden) {
                rtn = "Callable";
            }
        }

        return rtn;
    }

    //readOnly
    territory: string;

    private customer: Customer;

    get phone(): string {
        return this._phone;
    }
    set phone(value: string) {
        this._phone = value;
        this.formattedPhone = SharedHelper.formatPhoneNumber(this._phone);
    }
    get employeeName(): string {
        return this.employee.fullName;
    }

    get isAdmin(): boolean {
        return this.employee.employeeRoles.filter(r => r.employeeRoleType.id == EmployeeRoleType.ShieldAdmin).length > 0
    }

    constructor(filterService: FilterService, private px3RankService: Px3DelineationService) {
        super();
        this.filterService = filterService;
        void this.initializeDropDowns();
    }

    async buildViewmodelFromCustomer(c: Customer, employee: Employee): Promise<void> {
        this.customer = { ...c, msaStatus: null };
        this.customerNumber = c.customerNumber;
        this.isMsaStore = c.isMsa;
        this.msaStore = c.isMsa ? "Yes" : "No";
        this.isCallable = c.isCallable
        this.isCallableOverridden = c.isCallableOverridden;
        this.isActive = c.isActive;
        this.px3Rank = (await this.px3RankService.getById(c.px3RankId))?.rank ?? PX3_NOT_RANKED_MESSAGE;
        this.selectedCustomerType = Object.assign({}, c.customerType);
        this.customerTypes = CustomerTypeLookup;
        this.phone = c.phone;
        this.chainName = c.chain;
        this.chainRep = c.chainRep;
        this.storeComments = c.comments;
        this.customerName = c.name;
        this.businessAddressName = c.businessAddress?.name;
        this.address1 = c.businessAddress?.address1;
        this.address2 = c.businessAddress?.address2;
        this.city = c.businessAddress?.city;
        this.selectedState = c.businessAddress?.state;
        if (c.businessAddress?.zip?.length > 9) {
            this.zip = c.businessAddress.zip.substring(0, 9);
        } else {
            this.zip = c.businessAddress?.zip;
        }
        this.dbaAddressName = c.dbaAddress?.name;
        this.drivingAddress1 = c.dbaAddress?.address1;
        this.drivingAddress2 = c.dbaAddress?.address2;
        this.drivingCity = c.dbaAddress?.city;
        this.selectedDbaState = c.dbaAddress?.state;
        if (c.dbaAddress?.zip?.length > 9) {
            this.drivingZip = c.dbaAddress.zip.substring(0, 9);
        } else {
            this.drivingZip = c.dbaAddress?.zip;
        }
        this.oldComments = c.comments;
        this.displayZrt = this.getDisplayZrtFromCustomer(c, employee);
        this.rep = c.rep;
        this.manager = c.manager;
        this.selectedDbaState = c.dbaAddress?.state;
        this.dateCreated = c.dateCreated;
        this.dateDeactivated = c.deactivatedDate;
        this.dateReactivated = c.reactivatedDate;
        this.lastEdited = c.lastEdited;
        this.lastEditedBy = c.lastEditedBy;
        this.setLicenseInfo(this.customer);
        const px3Rank = await this.px3RankService.getById(c.px3RankId) ?? null;
        this.selectedPx3Rank = {
            id: c.px3RankId ?? "",
            name: px3Rank?.rank ?? PX3_NOT_RANKED_MESSAGE,
            description: ""
        };

        //readOnly
        this.territory = c.territory;
    }

    setLicenseInfo(customer: Customer): void {
        if (customer) {
            const customerLicenses = customer.customerLicenses;
            if (customerLicenses?.length) {
                const federalTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.FederalTaxId && cl.isActive);
                const stateTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.StateTaxId && cl.isActive);
                const retailStateOTPLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
                const retailStateVaporLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive);

                this.oldFederalTaxIdNumber = federalTaxLicense?.licenseNumber;
                this.federalTaxIdNumber = federalTaxLicense?.licenseNumber;
                this.federalTaxIdNumberLastUpdated = federalTaxLicense?.modifiedUtcDateTime ?? federalTaxLicense?.createdUtcDateTime;
                this.oldStateTaxIdNumber = stateTaxLicense?.licenseNumber;
                this.stateTaxIdNumber = stateTaxLicense?.licenseNumber;
                this.stateTaxIdNumberLastUpdated = stateTaxLicense?.modifiedUtcDateTime ?? stateTaxLicense?.createdUtcDateTime;
                this.oldTobaccoLicense = retailStateOTPLicense?.licenseNumber;
                this.tobaccoLicense = retailStateOTPLicense?.licenseNumber;
                this.tobaccoLicenseLastUpdated = retailStateOTPLicense?.modifiedUtcDateTime ?? retailStateOTPLicense?.createdUtcDateTime;
                this.oldRetailStateVaporLicense = retailStateVaporLicense?.licenseNumber;
                this.retailStateVaporLicense = retailStateVaporLicense?.licenseNumber;
                this.retailStateVaporLicenseLastUpdated = retailStateVaporLicense?.modifiedUtcDateTime ?? retailStateVaporLicense?.createdUtcDateTime;
            }
        }
    }

    buildLicenseEntityFromViewmodel(): void {
        if (this.employee) {
            if (this.federalTaxIdNumber != this.oldFederalTaxIdNumber) {
                this.buildCustomerLicenese(LicenseTypes.FederalTaxId, this.federalTaxIdNumber);
            }
            if (this.stateTaxIdNumber != this.oldStateTaxIdNumber) {
                this.buildCustomerLicenese(LicenseTypes.StateTaxId, this.stateTaxIdNumber);
            }
            if (this.retailStateVaporLicense != this.oldRetailStateVaporLicense) {
                this.buildCustomerLicenese(LicenseTypes.RetailStateVaporLicense, this.retailStateVaporLicense);
            }
            if (this.tobaccoLicense != this.oldTobaccoLicense) {
                this.buildCustomerLicenese(LicenseTypes.RetailStateOTPLicense, this.tobaccoLicense);
            }
        }
    }

    private buildCustomerLicenese(licenseTypeId: LicenseTypes, licenseNumber: string): void {
        this.customer.customerLicenses ??= [];
        const licenses = this.customer.customerLicenses.filter((cl) => cl.licenseTypeId === licenseTypeId && cl.isActive);
        licenses.forEach((l) => {
            l.isActive = false;
        });

        const customerLicense = new CustomerLicense();
        customerLicense.id = newSequentialId();
        customerLicense.customerId = this.customer.id;
        customerLicense.licenseNumber = licenseNumber;
        customerLicense.licenseTypeId = licenseTypeId;
        customerLicense.isActive = true;
        customerLicense.createdUtcDateTime = new Date();
        customerLicense.createdUserId = this.employee.id;
        customerLicense.createdUserZrt = this.employee.zrt;
        customerLicense.createdUserFullName = this.employee.fullName;
        customerLicense.modifiedUtcDateTime = customerLicense.createdUtcDateTime;
        customerLicense.modifiedUserId = customerLicense.createdUserId;
        customerLicense.modifiedUserZrt = customerLicense.createdUserZrt;
        customerLicense.modifiedUserFullName = customerLicense.createdUserFullName;
        this.customer.customerLicenses.push(customerLicense);
    }

    buildDomainModelFromViewmodel(): Customer {
        this.customer.customerNumber = this.customerNumber;
        this.customer.isCallableOverridden = this.isCallableOverridden;
        this.customer.isActive = this.isActive;
        this.customer.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, this.selectedCustomerType.id);
        this.customer.phone = this.phone;
        this.customer.name = this.customerName;
        this.buildLicenseEntityFromViewmodel();

        if (!this.customer.businessAddress) {
            const businessAddress = new Address();
            businessAddress.id = newSequentialId();
            this.customer.businessAddress = businessAddress;
        }

        this.customer.businessAddress.address1 = this.address1;
        this.customer.businessAddress.address2 = this.address2;
        this.customer.businessAddress.city = Helper.titleCase( this.city );
        this.customer.businessAddress.state = this.selectedState;
        this.customer.businessAddress.zip = this.zip;

        const currentDbaAddress = new Address();
        currentDbaAddress.name = this.dbaAddressName;
        currentDbaAddress.address1 = this.drivingAddress1;
        currentDbaAddress.address2 = this.drivingAddress2;
        currentDbaAddress.city = this.drivingCity;
        currentDbaAddress.state = this.selectedDbaState;
        currentDbaAddress.zip = this.drivingZip;
        this.customer.dbaAddress = this.buildAddress(currentDbaAddress, this.customer.dbaAddress);

        if (this.oldComments !== this.storeComments) {
            this.customer.comments = this.storeComments;
            this.customer.commentsLastUpdated = new Date();
            this.customer.commentsLastUpdatedBy = this.employeeName;
        }

        return this.customer;
    }

    buildAddress(a: Address, existing: Address): Address | null {
        if (existing?.id == null
            && a.address1 == null
            && a.city == null
            && a.state == null
            && a.zip == null
        ) {
            return null;
        }

        const rtn = existing ?? new Address();
        if (rtn.id == null) {
            rtn.id = newSequentialId();
        }
        rtn.name = a.name != null ? a.name : existing.name;
        rtn.address1 = a.address1 != null ? a.address1 : existing.address1;
        rtn.address2 = a.address2 != null ? a.address2 : existing?.address2;
        rtn.city = a.city != null ? a.city : existing.city;
        rtn.state = a.state != null ? a.state : existing.state;
        rtn.zip = a.zip != null ? a.zip : existing.zip;

        return rtn;
    }

    private async initializeDropDowns(): Promise<void> {
        const customerTypes = await this.filterService.getCustomerTypes();
        this.customerTypes = customerTypes.filter((ct) => ct.id !== CustomerTypeEnum.ChainHQ
            && ct.id !== CustomerTypeEnum.Unknown);
        this.states = await this.filterService.getStates();
        this.dbaStates = await this.filterService.getStates();
        this.px3Ranks = ([{ //Add the "Not Ranked" option first.
            id: '',
            name: PX3_NOT_RANKED_MESSAGE,
            description: '',
        }]).concat((await this.px3RankService.getAll()).map(r => {
            return {
                id: r.id,
                name: r.rank,
                description: '',
            }
        })).sort((a, b) => a.name < b.name ? -1 : 1);
    }
}

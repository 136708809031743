import { Component, signal } from '@angular/core';
import { WholesalerBaseComponent } from '../wholesaler-format-base/wholesaler-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-swisher-wholesaler-format-draft',
    templateUrl: './swisher-wholesaler-format-draft.component.html',
    styleUrls: ['./swisher-wholesaler-format-draft.component.scss']
})
export class SwisherWholesalerFormatDraftComponent extends WholesalerBaseComponent {
    subsidiary = signal(Subsidiary.Swisher);
}

import { Component, signal } from '@angular/core';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-swisher-retail-format-draft',
    templateUrl: './swisher-retail-format-draft.component.html',
    styleUrls: ['./swisher-retail-format-draft.component.scss']
})
export class SwisherRetailFormatDraftComponent extends RetailBaseComponent {
    subsidiary = signal(Subsidiary.Swisher);
    constructor() {
        super();
    }

}

<div class="wrapper">
    <mat-card appearance="outlined">
        <app-stepper #stepper>
            <cdk-step label="In Distro">
                <app-in-distro></app-in-distro>
            </cdk-step>
            <cdk-step label="Sales/Gratis">
                <app-sales-gratis></app-sales-gratis>
            </cdk-step>
            <cdk-step label="Exchange">
                <app-exchange></app-exchange>
            </cdk-step>
            <cdk-step label="Receipts">
                <app-receipts #receipts></app-receipts>
            </cdk-step>
            <cdk-step label="Activities &amp; Surveys">
                <app-activities-surveys
                    [isReadOnly]="false"
                    [isPanel]="false"
                    [call]="callService.call"></app-activities-surveys>
            </cdk-step>
            <cdk-step label="Pictures">
                <app-after-pictures></app-after-pictures>
            </cdk-step>
            <cdk-step label="Closing Notes">
                <app-closing-notes #closingNotes (autoPrintRetailReceipts)="autoPrintRetailReceipts()"
                    (autoPrintWholesaleReceipts)="autoPrintWholesaleReceipts()"></app-closing-notes>
            </cdk-step>
        </app-stepper>
    </mat-card>
</div>

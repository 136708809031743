import { ElementRef, Type } from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import moment from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import {
    ContractPaymentStatuses,
    ContractPaymentStatusLookup,
    FilterSortDto,
    GenericLookup,
    newSequentialId,
    RefinerLocation,
    RetailContractsColumns,
    SharedHelper,
    SortDirection,
    Subsidiary,
    valueSeparator
} from "shield.shared";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { MatSortHeader } from "@angular/material/sort";
import { RetailContractsRefinerService } from "./retail-contracts-refiner.service";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { OverlayService } from "src/app/services/overlay.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { CallDateInformationFilterComponent } from "src/app/shared/filters/call-date-information-filter/call-date-information-filter.component";
import { CallAttributesFilterComponent } from "src/app/shared/filters/call-attributes-filter/call-attributes-filter.component";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { FilterLocation } from "src/app/enums/filter-location";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { AppStateService } from "src/app/services/app-state.service";
import { MatSelect } from "@angular/material/select";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { ContractInformationFilterComponent } from "src/app/shared/filters/contract-information-filter/contract-information-filter.component";
import { CustomerContract } from "src/app/entity-models/customer-contract.entity";
import { RetailContractGridViewModel } from "./retail-contract-grid.viewmodel";
import { RetailContractLineItemViewModel } from "./retail-contract-line-item.viewmodel";
import { Router } from "@angular/router";
import { ViewContractViewModel } from "./view-contract/view-contract.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { ViewContractComponent } from "./view-contract/view-contract.component";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ReceiptDelineationService } from "src/app/services/delineation-services/receipt-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { Helper } from "src/app/helpers/helper";
import { RetailContractsZrtFilterService } from "./retail-contracts-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";

export class RetailContractsViewmodel {
    //private vars
    private formBuilder: UntypedFormBuilder;

    //public vars
    accountInput: string;
    allStatus = new GenericLookup<ContractPaymentStatuses>();
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<RetailContractsColumns>[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    contractInput: string;
    customerContractDelineationService: CustomerContractDelineationService;
    contactDelineationService: ContactDelineationService
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    customerDelineationService: CustomerDelineationService;
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    dateTimeFormat: string = MY_DATE_FORMATS.display.customMomentDateTime;
    detailHeight = 10;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    filterDataLoaded = false;
    filterLocation = FilterLocation.retailContracts;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridhieghtOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Retail Contracts";
    initialBatchCompleted = false;
    isAllStatusPresent: boolean = true;
    isFixedLayout = false;
    isEnabledExpandedDetails = true;
    isGapSet = false;
    isSaveDisabled = true;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    myListSubscription: Subscription;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50; // buffer size of the grid. We probally need to make the min and output buffers of the grid virtual scroll an input.
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    refinerInputChangeSubscription: Subscription;
    refinerService: RetailContractsRefinerService;
    refinerServiceSubscription: Subscription;
    router: Router;
    screenHeight: number = 0;
    isSearchStale = false;
    selectedColumnVisibility: string[];
    selectedColumnVisibilityCommunicator: string[];
    selectedStatuses = new Array<GenericLookup<ContractPaymentStatuses>>();
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbar: SnackbarService;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    statuses = new Array<GenericLookup<ContractPaymentStatuses>>();
    statusSelect: MatSelect;
    streetInput: string;
    topGapDistance = 0;
    total?: number;
    receiptDelineationService: ReceiptDelineationService;
    viewContractOverlayRef: SwisherOverlayRef<ViewContractViewModel, ViewContractComponent>;
    zipInput: string;
    zrtFilterService: RetailContractsZrtFilterService;
    zrtSelectionSubscription: Subscription;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<RetailContractsColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<RetailContractsColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as RetailContractsColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;
            void this.getContractBatch(0, true);
        }
    }

    constructor(
        customerContractDelineationService: CustomerContractDelineationService,
        refinerService: RetailContractsRefinerService,
        overlayService: OverlayService,
        formBuilder: UntypedFormBuilder,
        customerDelineationService: CustomerDelineationService,
        snackbar: SnackbarService,
        pleaseWaitService: PleaseWaitService,
        appStateService: AppStateService,
        router: Router,
        contactDelineationService: ContactDelineationService,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: RetailContractsZrtFilterService,
        private px3RankService: Px3DelineationService
    ) {
        this.customerContractDelineationService = customerContractDelineationService;
        this.refinerService = refinerService;
        this.overlayService = overlayService;
        this.customerDelineationService = customerDelineationService;
        this.snackbar = snackbar;
        this.pleaseWaitService = pleaseWaitService;
        this.router = router;
        this.customerContractDelineationService = customerContractDelineationService;
        this.contactDelineationService = contactDelineationService;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]]
        });

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = appStateService.currentEmployee.subscribe(async (employee) => {
                this.employee = employee;
                await this.setFilterData();
                this.getContractBatch(0);
            });
        }
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityCommunicator = value;
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (this.accountInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.account)?.value !== this.accountInput) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name"
            );
        }

        if (!this.accountInput || this.accountInput === "") {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            this.isValidDate(date) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.signedOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.signedOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.signedOnOrBeforeDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "signedOnOrBeforeDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.signedOnOrBeforeDate,
                    true,
                    false
                );
                this.dateForm.controls.endDate.setValue(null);
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            this.isValidDate(date) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.signedOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.signedOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.signedOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "signedOnOrAfterDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                this.refinerService.removeRefinerByLocation(
                    RefinerLocation.signedOnOrAfterDate,
                    true,
                    false
                );
                this.dateForm.controls.startDate.setValue(null);
            }
        }
    }

    onAddStreetRefiner(): void {
        if (this.streetInput) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "businessAddress.address1"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street
            )
        }
    }

    onAddCityRefiner(): void {
        if (this.cityInput) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "businessAddress.city"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city
            )
        }
    }

    onAddZipRefiner(): void {
        if (this.zipInput) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "businessAddress.zip"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes
            )
        }
    }

    onAddNumberRefiner(): void {
        if (this.contractInput) {
            this.refinerService.addRefiner(
                RefinerLocation.contract,
                this.contractInput,
                "contractNumber"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.contract
            )
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }
        this.isSearchStale = true;

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    onOpenSignedContract(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            const myData = this.gridData[index].data as CustomerContract;
            const data = new ViewContractViewModel(myData, this.customerDelineationService, this.customerContractDelineationService, this.overlayService,
                this.contactDelineationService);
            data.buttonLeftFunction = () => this.viewContractOverlayRef.close(data);
            this.viewContractOverlayRef = this.overlayService.open(
                ViewContractComponent,
                data,
                true
            );
        };
    }

    onOpenedStatusChange(): void {
        if (this.selectedStatuses.includes(this.allStatus)) {
            this.refinerService.removeRefinerByLocation(RefinerLocation.lastCallStatus, true, false);
        }
        else {
            if (!this.statusSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.lastCallStatus;
                refiner.value = this.selectedStatuses.map((vm) => vm.description).join(", ");
                refiner.dataPropertyName = "id";
                refiner.dataValue = this.selectedStatuses.map((vm) => vm.id).join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner, true);
            }
        }
    }

    onRouteToProfile(): (event: MouseEvent, index: number) => void {
        return (event: MouseEvent, index: number) => {
            const customerId = this.gridData[index].data.customerId;
            void this.router.navigate(["/accounts", customerId, "profile"]);
        }
    }

    onSelectionStatusChange(): void {
        if (
            this.isAllStatusPresent &&
            this.selectedStatuses.length > 1
        ) {
            const index = this.selectedStatuses.findIndex(
                (s) => s === this.allStatus
            );
            if (index !== -1) {
                this.selectedStatuses.splice(index, 1);
                this.isAllStatusPresent = false;
            }
        } else if (
            !this.isAllStatusPresent &&
            this.selectedStatuses.includes(
                this.allStatus
            )
        ) {
            this.selectedStatuses = [
                this.allStatus
            ];
            this.isAllStatusPresent = true;
        }
    }

    //public methods
    async buildContractsGridViewModel(contract: CustomerContract): Promise<RetailContractGridViewModel> {
        const lineItems = new Array<RetailContractLineItemViewModel>();
        contract.customerContractPayments
            .sort((a, b) =>
                a.paymentDate > b.paymentDate ? 1 : -1
            ).forEach((pmt) => {
                const li: RetailContractLineItemViewModel = {
                    ...pmt,
                    paymentDate: moment(pmt.paymentDate).format(this.dateTimeFormat),
                    salesRepName: Helper.formatDisplayName(pmt)
                };
                lineItems.push(li);
            });
        return {
            ...contract,
            signatureDate: moment(contract.signatureDate).format(this.dateTimeFormat),
            template: contract.contractTemplate.contractTemplateType.description,
            type: contract.isNew ? "New" : "Renewed",
            status: lineItems[lineItems.length - 1].contractPaymentStatus.description,
            totalPayments: contract.totalPayment ?? 0,
            storeInformation: contract.customerName
                + ", " + contract.address1
                + (contract.address2
                    ? " " + contract.address2
                    : "")
                + ", " + contract.city
                + ", " + contract.state
                + " " + contract.zipCode,
            customerContractPayments: lineItems
        };
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridhieghtOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridhieghtOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridhieghtOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getContractBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.refinerService.areDefaultsSet) return;

        const areRefinersTheSame = this.resolvePageIndex(index);
        this.isSearchStale = false;

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            this.shouldWait$.next(true);
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const response = await this.customerContractDelineationService.getCustomerContracts(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts,
                    this.shouldWait$
                );

                if (!response) {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(false);
                    return;
                }

                const results = response.values;

                if (!this.shouldWait$.value) {
                    return;
                }
                else {
                    this.total = results.length;
                    this.isGapSet = false;

                    let gridData: GridData[] = this.gridData.slice();
                    if (this.pageIndex === 0) {
                        gridData = new Array<GridData>();
                    }
                    const largestIndex = gridData.length;
                    const px3Ranks = new Map((await this.px3RankService.getAll()).map((r) => [r.id, r.rank]));
                    for (const contract of results) {
                        const gridItem: GridData = {
                            data: await this.buildContractsGridViewModel(contract),
                            detailArrayName: "customerContractPayments",
                            isExpanded: this.grid?.areAllRowsExpanded ?? false,
                            index: largestIndex + results.indexOf(contract)
                        };
                        gridItem.data.px3Rank = px3Ranks.get(gridItem.data.px3RankId);
                        gridData.push(gridItem);
                    }

                    this.gridData = gridData;

                    this.dataSource = new TableVirtualScrollDataSource(
                        this.gridData
                    );

                    this.shouldResetGrid = false;
                    this.shouldWait$.next(false);
                }
                this.initialBatchCompleted = true;
            }, 0);
        }
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        statusSelect: MatSelect
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;
        this.statusSelect = statusSelect;

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        const printButtonComponent = new PrintButtonComponent();
        this.headerButtons.push(printButtonComponent);

        const excelExportButtonComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(excelExportButtonComponent);

        this.columnDef = [
            {
                headerName: RetailContractsColumns.contractId,
                dataPropertyName: "id",
                isAvailable: true,
                isSelected: false
            },
            {
                headerName: RetailContractsColumns.contractNumber,
                dataPropertyName: "number",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onOpenSignedContract()
            },
            {
                headerName: RetailContractsColumns.template,
                dataPropertyName: "template",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.dateSigned,
                dataPropertyName: "signatureDate",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.level,
                dataPropertyName: "level",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.type,
                dataPropertyName: "type",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.currentStatus,
                dataPropertyName: "status",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.totalPayment,
                dataPropertyName: "totalPayments",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.currency
            },
            {
                headerName: RetailContractsColumns.customerId,
                dataPropertyName: "customerNumber",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.storeInformation,
                dataPropertyName: "storeInformation",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onRouteToProfile()
            },
            {
                headerName: RetailContractsColumns.zrt,
                dataPropertyName: "createdUserZrt",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: RetailContractsColumns.px3Rank,
                dataPropertyName: "px3Rank",
                isAvailable: true,
                isSelected: true,
            },
            {
                headerName: "Customercontractpayments",
                dataPropertyName: "customerContractPayments",
                isAvailable: false,
                isSelected: true
            }
        ];
        const tempFilters: FilterAndParams[] = [];

        tempFilters.push({ filter: MySearchesFilterComponent });
        tempFilters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        tempFilters.push({ filter: CallDateInformationFilterComponent });
        tempFilters.push({ filter: ContractInformationFilterComponent });
        tempFilters.push({ filter: CallAttributesFilterComponent });

        this.filters = tempFilters;

        this.allStatus.description = "All";
        this.selectedStatuses.push(this.allStatus);
        this.statuses.push(this.allStatus);
        for (const status of ContractPaymentStatusLookup) {
            this.statuses.push(status);
        }

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getContractBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (this.initialBatchCompleted || dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getContractBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    isValidDate(value: any): boolean {
        const date = moment(value, this.dateFormat, true);
        return date.isValid();
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
            this.onAddNumberRefiner();
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getContractBatch(this.pageIndex);
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityCommunicator = event;
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee, false);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;
            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeCustomerServiceOrAdmin(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        this.setDefaultFilter();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;
        this.contractInput = null;

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.selectedStatuses = [this.allStatus];

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }
        if (
            this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.customerContractDelineationService.getCustomerContracts(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts,
                this.shouldWait$
            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.contract:
                this.contractInput = refiner.value;
                break;
            case RefinerLocation.signedOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.signedOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            case RefinerLocation.lastCallStatus:
                const selectedStatuses = refiner.value?.split(", ");
                const rtnSelectedStatuses = new Array<GenericLookup<ContractPaymentStatuses>>();

                if (selectedStatuses) {
                    for (const status of selectedStatuses) {
                        const found = this.statuses.find(
                            (s) => s.description === status.trim()
                        );
                        if (found) {
                            rtnSelectedStatuses.push(found);
                        }
                    }
                }
                this.selectedStatuses = rtnSelectedStatuses.length
                    ? rtnSelectedStatuses
                    : [this.allStatus];
                this.onOpenedStatusChange();
                break;
            default:
                break;
        }
    }
}

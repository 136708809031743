import { Component, Inject, HostListener } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { saveAs } from "file-saver";
import moment from "moment";

@Component({
    selector: "app-pop-out-img-dialog",
    templateUrl: "./img-dialog.component.html",
    styleUrls: ["./img-dialog.component.scss"]
})
export class PictureModalComponent {
    title: string = "";
    customer: string = "";
    zrt: string = "";
    customerNumber: string = "";
    image: any;
    todaysDate: string = "";
    fileName: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PictureModalComponent>
    ) {}
    ngOnInit() {
        if (typeof this.data.picture === "string") {
            this.image = this.data.picture;
            this.title = this.data.allData;
            this.todaysDate = moment(new Date()).utc().format("MMDDYYYY");
        } else {
            this.image = this.data.picture.url;
            if (typeof this.data.allData === "string") {
                this.title = this.data.allData;
            } else {
                this.title = this.data.picture.type;
            }
            this.todaysDate = moment(new Date()).utc().format("MMDDYYYY");
        }
    }

    closeDialog() {
        this.dialogRef.close();
    }
    downloadImg() {
        this.fileName = `${this.todaysDate}-${this.title}.png`;
        saveAs(this.image, this.fileName);
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.dialogRef) {
                this.closeDialog()
            }
        }
}

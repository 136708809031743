import { CustomerTypeEnum, GenericLookup } from "shield.shared";
import { CustomerGenericTypes } from "../enums/customer-generic-types";
import { Helper } from "../helpers/helper";
import { Address } from "./address.entity";
import { CustomerLicense } from "./customer-license.entity";
import { CustomerWholesaler } from "./customer-wholesaler.entity";
import { CustomerZrt } from "./customer-zrt.entity";
import { MsaDistributorVolumeResponse } from "./msa-distributor-volume-response.entity";
import { WholesalerProduct } from "./wholesaler-product.entity";

export class Customer {
    id: string;
    msaRetailId: string;
    customerNumber: string;
    retailNumber: string;
    chainIndicator: string;
    ownerCode: string;
    callFileIndicator: string;
    retailIndicator: string;
    availability: string;
    email: string;
    fax: string;
    federalTaxId: string;
    federalTaxIdLastUpdated: Date;
    hasPhone?: boolean;
    inDistributionVolume: number;
    industryVolume: number;
    shieldVolume: number;
    shieldVolumePercentage: number;
    isActive: boolean;
    isCallable: boolean;
    isCallableOverridden: boolean;
    isMsa: boolean;
    isRouted: boolean;
    lastCall: Date;
    license: string;
    licenseLastUpdated: Date;
    name: string;
    phone: string;
    region: string;
    status: string;
    stateTaxId: string;
    stateTaxIdLastUpdated: Date;
    territory: string;
    zone: string;
    zrt: string;
    comments: string;
    commentsLastUpdated: Date;
    commentsLastUpdatedBy: string;
    manager: string;
    rep: string;
    geoArea: string;
    businessAddress: Address | null;
    dbaAddress: Address | null;
    dateCreated: Date;
    lastEdited: Date;
    lastEditedBy: string;
    deactivatedDate: Date | null;
    deactivatedEmployeeId: string | null;
    deactivatedBy: string | null;
    reactivatedDate: Date | null;
    reactivatedEmployeeId: string | null;
    reactivatedBy: string | null;
    isDeleted: boolean | null;
    externalTdLinx: string;
    customerType: GenericLookup<CustomerTypeEnum>;
    lastEtlUpdated: Date | null;
    lastDeletionDate: Date | null;
    chain: string;
    nam: string;
    chainRep: string;
    distributorAccountNumber: string;
    groupId: number;
    isAddNewBusiness: boolean;
    program: string;
    orderType: string;
    assignedEmployeeFullName: string;
    accountOwnershipHierarchyLevelName: string;
    storeCount: number;
    isCashAndCarry: boolean;
    customerWholesalers: CustomerWholesaler[] = [];
    wholesalerProducts: WholesalerProduct[] = [];
    hasPendingOrders: boolean | null;
    customerLicenses: CustomerLicense[];
    customerZrts: CustomerZrt[];
    volumeResponse: MsaDistributorVolumeResponse;
    displayName: string;

    callsMade?: number|null;
    px3RankId?: string|null;

    // these values are numbers instead of boolean so that Dexie can index them
    hasServerProcessed: number;
    isZrtAssignment: number;
    isProjectAssignment: number;
    isSpecialAssignment: number;

    rowversion: string;

    get msaStatus() {
        let rtn: string;
        if (
            Helper.getCustomerGenericType(this) ===
            CustomerGenericTypes.retail
        ) {
            rtn = "Non-Callable";
            if (this.isMsa) {
                if (this.isCallable || this.isCallableOverridden) {
                    rtn = "Callable";
                }
            }
        }
        return rtn;
    }
}

import {
    CommonFilters,
    CustomerTypeEnum,
    GenericLookup
} from "shield.shared";
import { Px3Rank } from "src/app/entity-models/px3-rank.entity";
import { FilterService } from "src/app/services/filter.service";

export class ProjectCustomerAttributesViewmodel {
    filterService: FilterService;
    all: CommonFilters.All = CommonFilters.All;
    yes: CommonFilters.Yes = CommonFilters.Yes;
    no: CommonFilters.No = CommonFilters.No;
    overridden = "Overridden";

    allCustomerType = new GenericLookup<CustomerTypeEnum>();
    allPx3Rank = new GenericLookup<Px3Rank>();
    notRankedPx3Rank = new GenericLookup<Px3Rank>();
    isAllCustomerTypePresent: boolean;
    isAllPx3RankPresent: boolean;
    customerTypes = new Array<GenericLookup<CustomerTypeEnum>>();
    px3Ranks = new Array<GenericLookup<Px3Rank>>();
    selectedCustomerType = new Array<GenericLookup<CustomerTypeEnum>>();
    selectedMsa: string = this.all;
    selectedPhone: string = this.all;
    selectedActive: string = this.yes;
    selectedVolume: string = this.all;
    selectedCallable: string[] = [this.all];
    selectedPx3Ranks = new Array<GenericLookup<Px3Rank>>();

    constructor(filterService: FilterService) {
        this.filterService = filterService;
        this.allCustomerType.name = this.all;
    }

    async initializeStoreTypeDropDown(): Promise<void> {
        this.allCustomerType.name = "All";
        this.customerTypes.push(this.allCustomerType);
        const customerTypes = await this.filterService.getCustomerTypes();
        if(customerTypes) {
            for(let type of customerTypes) {
                this.customerTypes.push(type);
            }
        }
        this.selectedCustomerType = [this.allCustomerType];
    }
}

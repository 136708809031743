//[(dataSource)]="dataSource" => An array of TableVirtualScrollDataSource<GridData>. TableVirtualScrollDataSource acts just like
// MatTableDataSource with a few adjustments to make the Mat-Table compatable with the cdk virtual scroll.

//isVirtualScroll, defaulted to true. Set to false to remove virtual scrolling and change the dataSource to renderedDataSource using
//[(renderedDataSource)]="dataSource" => An array of TableVirtualScrollDataSource<GridData>. TableVirtualScrollDataSource acts just like
//MatTableDataSource in place of TableVirtualScrollDataSource

// export class GridData {
//     data: any; <- Represents a single row of data.
//     detailArrayName: string; <= the array property name in your data that represents one or more details.
//     isExpanded: boolean; <= Should the details of the row be expanded or not.
// }
// set-up => this.dataSource = new TableVirtualScrollDataSource(this.gridData); See test component for implemented example.

// [columnDefinitions]="columnDef"
// export class ColumnDef {
//     headerName: string; => The name of the grid column header cell.
//     dataPropertyName: string; => the data property name.
//     isAvailable: boolean; => if the column is to be rendered.
// }

//[(hasGlobalSearch)]="hasGlobalSearch" => If true, the global search internal to the grid is rendered. Defaulted to false.

//[(isEnabledExpandedDetails)]="isEnabledExpandedDetails" => if a detail template is provided, should the details be expandable. Defaulted to true.

//[detailTemplate]="detailTemplate" => the template to be rendered and bound to the provided data. See the test component for implementation example.

//[searchRefiners]="activeRefiners" => external Refiner array provided to filter on.
// export class Refiner {
//     value: string; => value used in search.
//     location: RefinerLocation; => not used in grid component.
//     dataPropertyName: string; => data property to search for value.
// }

//[searchRefinersGreaterThans]="refinerGreaterThans" => Specifies a list of refiner locations that should use a "greater than" comparator when filtering the grid.

//[searchRefinersLessThans]="refinerLessThans" => Specifies a list of refiner locations that should use a "less than" comparator when filtering the grid.

//[searchRefinersMultiselects]="refinerMultiselects" => Specifies refiner locations that optionally contain multiple values to compare to when filtering the grid.

//[selectedColumnVisability]="selectedColumnVisabilityComunicator" => The selected columns to be rendered in the grid.

//(selectedColumnVisabilityChange)="onSetSelectedColumnVisability($event)" => The selectedColumnVisability event emitter.

//[isEnabledInternalGridControls]="isEnabledInternalGridControls" => Determines if the internal grid controls are available. Defaults to false.

//isSortable => default is true. If true allows for sorting

//[shouldDisplayInternalEntryCount]="shouldDisplayInternalEntryCount" => Determines if the internal Entry count is displayed. Defaults to false.
//Entry count is the number of rows in that can\will be rendered in the grid component.
//This is after the filters are applied.

//[isFixedLayout]="isFixedLayout" => If true, the columns are equally spaced. Defaults to true.

//showExpanderToggle defualts to true. Controls if the row and headers first column should have caret icons

//[splitToken]="splitToken" => for multi value vales or multi value dataPropertyName. Defaults to ","

//rowSelectionChange the selection of a row on the grid has changed. Applies when a column is marked a selectable

//[height]="gridHeight" => height of grid. Default 100vh
//[width]="gridWidth" => width of grid. Default 100%

import {
    animate,
    state,
    style,
    transition,
    trigger
} from "@angular/animations";
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
    faCaretDown,
    faCaretUp,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { MatSelect } from "@angular/material/select";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import  XLSX from "xlsx";
import { GridData } from "../viewmodels/grid-data.viewmodel";
import { ColumnDef } from "../viewmodels/column-def.viewmodel";
import { SplitRefinerViewmodel } from "../viewmodels/split-refiner.viewmodel";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { NgxPrintDirective } from "../directives/print.directive";
import { RefinerLocation } from "shield.shared";
import { GridDataTypes } from "../enums/grid-data-types.enum";
import { MY_DATE_FORMATS } from "../constants/date-formats";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html",
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*", maxHeight: "50vh" })),
            transition(
                "expanded <=> collapsed, void => expanded",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            )
        ])
    ],
    styleUrls: ["./grid.component.scss"]
})
export class GridComponent implements OnInit {
    //view childs
    @ViewChild("gridColumnSelect") gridColumnSelect: MatSelect;
    @ViewChild("globalFilter") globalFilter: ElementRef<HTMLInputElement>;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild("grid") grid: MatTable<GridData>;
    @ViewChild("table") table: any;
    @ViewChild("theOneCheckboxToRuleThemAll") headerCheckbox: MatCheckbox
    @ViewChild("viewport") viewport: CdkVirtualScrollViewport;

    //inputs
    //columnDefinitions define the column header text, the data source column name, and if the the rows have expandable details
    private _columnDefinitions: ColumnDef[];
    @Input()
    get columnDefinitions(): ColumnDef[] {
        return this._columnDefinitions;
    }
    set columnDefinitions(value: ColumnDef[]) {
        this._columnDefinitions = value;
        if (value) {
            this.availableColumnsToDisplay = value
                .filter((cd) => cd.isAvailable)
                .map((cd) => cd.headerName);
            this.selectedColumnVisability = this.availableColumnsToDisplay.slice();
            this.selectedColumnVisabilityChange.emit(
                this.selectedColumnVisability
            );
            this.columnVisability = value
                .filter((cd) => cd.isAvailable)
                .map((cd) => cd.dataPropertyName);

            this.firstSelectableColumnDisplayed = this.selectedColumnVisability[0];
        }
    }

    private data: GridData[];
    @Input()
    get dataSource(): TableVirtualScrollDataSource<GridData> {
        return this._dataSource;
    }
    set dataSource(value: TableVirtualScrollDataSource<GridData>) {
        if (value) {
            this._dataSource = value;
            if (value && (!this.detailTemplate || !this.hasExpandableDetails)) {
                for (const row of this.dataSource.data) {
                    row.isExpanded = false;
                }
                this.numberOfExpandedDetails = 0;
                this.dataSourceChange.emit(this.dataSource);
            }
            this.inBufferScrollPlay = false;
            this.setfilterPredicate();
            this.data = value.data.slice();
            setTimeout(() => {
                this.grid.renderRows();
            }, 0);
        }
    }

    @Input()
    get renderedDataSource(): MatTableDataSource<GridData> {
        return this._renderedDataSource;
    }
    set renderedDataSource(value: MatTableDataSource<GridData>) {
        if (value) {
            this._renderedDataSource = value;
            if (value && (!this.detailTemplate || !this.hasExpandableDetails)) {
                for (const row of this.renderedDataSource.data) {
                    row.isExpanded = false;
                }
                this.numberOfExpandedDetails = 0;
                this.renderedDataSourceChange.emit(this.renderedDataSource);
            }
            this.inBufferScrollPlay = false;
            this.setfilterPredicate();
            this.data = value.data.slice();
            setTimeout(() => {
                this.grid.renderRows();
            }, 0);
        }
    }

    @Input() detailHeight: number = 0;

    private _detailTemplate: TemplateRef<any>;
    @Input()
    get detailTemplate(): TemplateRef<any> {
        return this._detailTemplate;
    }
    set detailTemplate(value: TemplateRef<any>) {
        this._detailTemplate = value;
        if (this.renderedDataSource && (!value || !this.hasExpandableDetails)) {
            for (const row of this.renderedDataSource.data) {
                row.isExpanded = false;
            }
            this.renderedDataSourceChange.emit(this.renderedDataSource);
        }
        if (this.dataSource && (!value || !this.hasExpandableDetails)) {
            for (const row of this.dataSource.data) {
                row.isExpanded = false;
            }
            this.dataSourceChange.emit(this.dataSource);
        }
        this.hasExpandableDetails = !!value;
    }

    @Output() headerSelectionChanged = new EventEmitter<boolean>();

    @Input() isEnabledInternalGridControls: boolean = false;

    private _isEnabledExpandedDetails: boolean = true;
    @Input()
    get isEnabledExpandedDetails(): boolean {
        return this._isEnabledExpandedDetails;
    }
    set isEnabledExpandedDetails(value: boolean) {
        this._isEnabledExpandedDetails = !!value;
        if (!value) {
            this.dataSource?.data.forEach((gridData) => {
                this.toggleTableRow(gridData);
            });
        }
        setTimeout(() => {
            if (this.grid && this.dataSource) {
                this.grid.renderRows();
            }
        }, 0);
    }

    //Whether to use a fixed table layout. Enabling this option will enforce consistent column widths and optimize rendering sticky styles for native tables.
    //No-op for flex tables.
    private _isFixedLayout = true;
    @Input()
    get isFixedLayout(): boolean {
        return this._isFixedLayout;
    }
    set isFixedLayout(value: boolean) {
        this._isFixedLayout = value;
        if (this.grid) {
            this.grid.renderRows();
        }
    }

    private _hasGlobalSearch: boolean = false;
    @Input()
    get hasGlobalSearch(): boolean {
        return this._hasGlobalSearch;
    }
    set hasGlobalSearch(value: boolean) {
        this._hasGlobalSearch = !!value;
    }

    private _height: string;
    @Input()
    get height(): string {
        return this._height;
    }
    set height(value: string) {
        if (value) {
            this._height = value;
        }
    }

    @Input() isSelectable: boolean = true;

    @Input() isSortable: boolean = true;

    @Input() itemsRendedInViewPort: number = 11;

    @Input() itemSize: number = 50;

    private _selectedColumnVisability: string[];
    @Input()
    get selectedColumnVisability(): string[] {
        return this._selectedColumnVisability;
    }
    set selectedColumnVisability(value: string[]) {
        this._selectedColumnVisability = value;
        if (value) {
            this.onOpenedColumnSelectChange();
            this.firstSelectableColumnDisplayed = value[0];
        }
    }

    private _filterFunction: (
        row: GridData,
        filter: string
    ) => boolean = this.getDefaultFilter();
    @Input()
    set filterFunction(value: (row: GridData, filter: string) => boolean) {
        if (value) {
            this._filterFunction = value;
        } else {
            this._filterFunction = this.getDefaultFilter();
        }
        if (this._dataSource) {
            this.setfilterPredicate();
        }
    }

    private _searchRefiners: Refiner[] = [];
    @Input()
    get searchRefiners(): Refiner[] {
        return this._searchRefiners;
    }
    set searchRefiners(value: Refiner[]) {
        for (const refiner of value) {
            if (!refiner.dataValue) {
                refiner.dataValue = refiner.value;
            }
        }
        this._searchRefiners = value;
        this.splitRefiners = this.createSplitRefiners();
    }

    private _searchRefinersGreaterThans: RefinerLocation[] = [];
    @Input()
    get searchRefinersGreaterThans(): RefinerLocation[] {
        return this._searchRefinersGreaterThans;
    }
    set searchRefinersGreaterThans(value: RefinerLocation[]) {
        this._searchRefinersGreaterThans = value;
    }

    private _searchRefinersLessThans: RefinerLocation[] = [];
    @Input()
    get searchRefinersLessThans(): RefinerLocation[] {
        return this._searchRefinersLessThans;
    }
    set searchRefinersLessThans(value: RefinerLocation[]) {
        this._searchRefinersLessThans = value;
    }

    private _searchRefinersMultiselects: RefinerLocation[] = [];
    @Input()
    get searchRefinersMultiselects(): RefinerLocation[] {
        return this._searchRefinersMultiselects;
    }
    set searchRefinersMultiselects(value: RefinerLocation[]) {
        this._searchRefinersMultiselects = value;
    }

    private _shouldDisplayInternalEntryCount: boolean = false;
    @Input()
    get shouldDisplayInternalEntryCount(): boolean {
        return this._shouldDisplayInternalEntryCount;
    }
    set shouldDisplayInternalEntryCount(value: boolean) {
        this._shouldDisplayInternalEntryCount = value;
    }

    @Input() showExpanderToggle = true;
    @Input() isVirtualScroll = true;

    @Input() splitToken: string = "|";

    private _sortFunction: (
        columnDef: ColumnDef
    ) => void = this.getDefaultSort();
    @Input()
    set sortFunction(value: (columnDef: ColumnDef) => void) {
        if (value) {
            this._sortFunction = value;
        } else {
            this._sortFunction = this.getDefaultSort();
        }
    }

    @Input() width: string = "100%";

    @Input() emptyMessage: string = "";

    @Input() total: number;

    //outputs
    @Output()
    dataSourceChange = new EventEmitter<
        TableVirtualScrollDataSource<GridData>
    >();

    @Output()
    renderedDataSourceChange = new EventEmitter<
        MatTableDataSource<GridData>
    >();

    @Output() getDataBatch = new EventEmitter<number>();

    @Output()
    hasGlobalSearchChange = new EventEmitter<boolean>();

    @Output()
    isEnabledExpandedDetailsChange = new EventEmitter<boolean>();

    @Output()
    rowExpanded = new EventEmitter<number>();

    @Output()
    rowSelectionChange = new EventEmitter<number>();

    @Output()
    selectedColumnVisabilityChange = new EventEmitter<string[]>();

    //public vars
    allColumnsChecked = false;
    areAllRowsExpanded = false;
    availableColumnsToDisplay: string[];
    columnVisability: string[] = [];
    dataTypes = GridDataTypes;
    expandedElement = this.columnDefinitions;
    faCaretDown: IconDefinition = faCaretDown;
    faCaretUp: IconDefinition = faCaretUp;
    firstSelectableColumnDisplayed: string;
    hasExpandableDetails: boolean;
    numberOfExpandedDetails = 0;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    momentFormat = MY_DATE_FORMATS.display.dateInput;

    //private vars
    private _dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    private _renderedDataSource: MatTableDataSource<GridData> = new MatTableDataSource<GridData>();
    private placeholder = "~placeHolder~";
    private splitRefiners: SplitRefinerViewmodel[];
    private inBufferScrollPlay = true;
    private gridPrintStyle = "/assets/css/grid-print.css";

    ngOnInit(): void {
        //this.isEnabledExpandedDetails = false;
    }

    //events
    onApplyFilter(event?: Event) {
        // the DataTable does not fire the filter predicate if !filter
        // so a place holder is added in the event the global filter !(filter)
        const filterValue = !!this.globalFilter?.nativeElement?.value
            ? this.globalFilter.nativeElement.value
            : this.placeholder;
        if (this.renderedDataSource.data?.length) {
            this.renderedDataSource.filter = filterValue;
            this.renderedDataSourceChange.emit(this.renderedDataSource);
            return;
        }
        this.dataSource.filter = filterValue;
        this.dataSourceChange.emit(this.dataSource);
    }

    onClick(columnDef: ColumnDef, event: MouseEvent, index: number): void {
        if (columnDef.clickFunction) {
            event.stopPropagation();
            columnDef.clickFunction(event, index);
        }
    }

    onExportAsExcel(): void {
        const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
            this.table._elementRef.nativeElement, {
            raw: true,
            display: true
        }
        ); //converts a DOM TABLE element to a worksheet
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        /* save to file */
        const now = new Date();
        const nowString =
            now.getFullYear() +
            "_" +
            (now.getMonth() + 1) +
            "_" +
            now.getDate() +
            "_" +
            now.getHours() +
            now.getMinutes() +
            now.getSeconds();
        XLSX.writeFile(wb, "Grid_" + nowString + ".xlsx");
    }

    onHeaderSelectChange(event: MatCheckboxChange): void {
        this.headerSelectionChanged.emit(event.checked);
    }

    onOpenedColumnSelectChange(): void {
        if (!this.gridColumnSelect?.panelOpen) {
            this.columnVisability = this.columnDefinitions
                .filter((cd) =>
                    this.selectedColumnVisability.includes(cd.headerName)
                )
                .map((cf) => cf.dataPropertyName);
            this.selectedColumnVisabilityChange.emit(
                this.selectedColumnVisability
            );
        }
    }

    onPrint(): void {
        const gridPrint = new NgxPrintDirective;

        gridPrint.printSectionId = "table";
        gridPrint.styleSheetFile = this.gridPrintStyle;

        gridPrint.print();
    }

    onRowDetailAdjust(): void {
        this.isEnabledExpandedDetails = !this.isEnabledExpandedDetails;
        this.isEnabledExpandedDetailsChange.emit(this.isEnabledExpandedDetails);
        if (!this.isEnabledExpandedDetails) {
            this.dataSource.data.forEach((gridData) => {
                gridData.isExpanded = false;
            });
            this.numberOfExpandedDetails = 0;
            this.dataSourceChange.emit(this.dataSource);
        }
    }

    onScrolledIndexChanged(index: number): void {
        if (!this.inBufferScrollPlay) {
            if (
                Math.ceil(index) + (this.itemsRendedInViewPort * 2) >=
                this.dataSource.filteredData.length +
                (this.numberOfExpandedDetails * this.detailHeight) /
                this.itemSize
            ) {
                if (this.total == undefined || this.total > this.dataSource.filteredData.length) {
                    if (index) {
                        this.inBufferScrollPlay = true;
                    }
                    this.getDataBatch.emit(index);
                }
            }
        }
    }

    onSetHasGlobalSearch(): void {
        this.hasGlobalSearch = !this.hasGlobalSearch;
        this.hasGlobalSearchChange.emit(this.hasGlobalSearch);
    }

    onSort(columnDef: ColumnDef): void {
        if (this.viewport) {
            this.viewport.scrollToIndex(0);
        }
        this._sortFunction(columnDef);
    }

    //public
    toggleAllRowExpantion(event: MouseEvent): void {
        event.stopPropagation();
        this.areAllRowsExpanded = !this.areAllRowsExpanded;
        if (this.renderedDataSource.data?.length) {
            for (const row of this.renderedDataSource.data) {
                row.isExpanded = this.areAllRowsExpanded;
            }
            this.numberOfExpandedDetails = this.areAllRowsExpanded ? this.renderedDataSource.data.length : 0;
            return;
        }
        for (const row of this.dataSource.data) {
            row.isExpanded = this.areAllRowsExpanded;
        }
        this.numberOfExpandedDetails = this.areAllRowsExpanded ? this.dataSource.data.length : 0;
    }

    contentChanged() {
        // This should be implemented when upgrading material to >= 12.2.2
    }

    createSplitRefiners(): SplitRefinerViewmodel[] {
        const rtn = new Array<SplitRefinerViewmodel>();

        for (const refiner of this.searchRefiners) {
            const refiners = this.creatRefinersFromMultiValues(refiner);
            let subRefiners = new Array<Refiner>();
            for (const myRefiner of refiners) {
                subRefiners = subRefiners.concat(
                    this.creatRefinersFromSearchLocations(myRefiner)
                );
            }
            const splitRefiner: SplitRefinerViewmodel = {
                ...Object.assign({}, refiner),
                subRefiners: subRefiners,
                splitDataProperty: refiner.dataPropertyName?.split(".")
            };
            rtn.push(splitRefiner);
        }
        return rtn;
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.columnVisability,
            event.previousIndex,
            event.currentIndex
        );
        const columns = Array.from(this.grid._contentHeaderRowDefs.first.columns);
        this.firstSelectableColumnDisplayed = this.columnDefinitions.find((cd) => cd.dataPropertyName === columns[0])?.headerName;
    }

    isSomeChecked(dataSource: MatTableDataSource<GridData> | TableVirtualScrollDataSource<GridData>): boolean {
        let rtn = true;
        if (dataSource.data.find((data) => data.data.isSelected) && dataSource.data.find((data) => data.data.isSelected)) {
            rtn = true;
        }
        return rtn;
    }

    rowSelectionChanged(index?: number): void { // optional for backwards compatability
        this.rowSelectionChange.emit(index);
    }

    setAllColumnsChecked(isSelected: boolean, dataSource: MatTableDataSource<GridData> | TableVirtualScrollDataSource<GridData>): void {
        this.allColumnsChecked = isSelected;
        if (dataSource.data == null) {
            return;
        }
        this.data.forEach(
            (c) => {
                if (dataSource.filteredData.some((v) => JSON.stringify(v.data) == JSON.stringify(c.data))) {
                    c.data.isSelected = isSelected;
                }
            }
        );
        this.rowSelectionChanged(-1); // -1 for all rows need to be evaluated
    }

    setHeaderCheckbox(value: boolean): void {
        this.headerCheckbox.checked = value;
    }

    someColumnsChecked(dataSource: MatTableDataSource<GridData> | TableVirtualScrollDataSource<GridData>): boolean {
        const count = dataSource.data.filter((gridRow) => gridRow.data.isSelected)?.length ?? 0
        if (count > 0 && count !== this.data.length) {
            return true;
        }
        return false;
    }

    updateAllColumnsChecked(dataSource: MatTableDataSource<GridData> | TableVirtualScrollDataSource<GridData>): void {
        this.allColumnsChecked =
            dataSource.data != null &&
            dataSource.data.every((d) => d.data.isSelected);
    }

    toggleTableRow(row: GridData) {
        if (this.hasExpandableDetails && this.isEnabledExpandedDetails) {
            row.isExpanded = !row.isExpanded;
            if (row.isExpanded) {
                this.numberOfExpandedDetails++;
                this.rowExpanded.emit(row.index);
            } else {
                this.numberOfExpandedDetails--;
            }
            this.areAllRowsExpanded = this.renderedDataSource.data?.length
                ? !this.renderedDataSource.data.find((gd) => !gd.isExpanded)
                : !this.dataSource.data.find((gd) => !gd.isExpanded);
        }
    }

    //private methods
    private creatRefinersFromMultiValues(sourceRefiner: Refiner): Refiner[] {
        const rtn = new Array<Refiner>();
        const values = sourceRefiner.dataValue.split(this.splitToken);
        for (const searchValue of values) {
            const refiner = Object.assign({}, sourceRefiner);
            refiner.dataValue = searchValue;
            rtn.push(refiner);
        }
        return rtn;
    }

    private creatRefinersFromSearchLocations(
        sourceRefiner: Refiner
    ): Refiner[] {
        const rtn = new Array<Refiner>();
        const values = sourceRefiner.dataPropertyName?.split(this.splitToken);
        if (values) {
            for (const searchColumn of values) {
                const refiner = Object.assign({}, sourceRefiner);
                refiner.dataPropertyName = searchColumn;
                rtn.push(refiner);
            }
        }
        return rtn;
    }

    private setfilterPredicate(): void {
        if (this._renderedDataSource?.data?.length) {
            this.renderedDataSource.filterPredicate = this._filterFunction;
            this.renderedDataSourceChange.emit(this.renderedDataSource);
            return;
        }

        if (this._dataSource) {
            this.dataSource.filterPredicate = this._filterFunction;
            this.dataSourceChange.emit(this.dataSource);
        }
    }

    //functions
    private getDefaultFilter(): (row: GridData, filter: string) => boolean {
        return (row: GridData, filter: string) => {
            let gfRtn = true;
            let rfRtn = true;
            // the DataTable does not fire the filter predicate if !filter
            // so a place holder is added in the event the global filter !(filter)
            filter = filter === this.placeholder ? null : filter;

            const keys = this.columnDefinitions
                .filter((data) => data.isAvailable)
                .map((cd) => cd.dataPropertyName);

            let globalFilter = filter?.split(",");
            const globalFilters: string[] = [];

            if (globalFilter) {
                for (let filter of globalFilter) {
                    globalFilters.push(filter.trim().toLocaleLowerCase());
                }
            }

            if (globalFilters.length > 0) {
                gfRtn = false;
                globalFilters.forEach((myFilter) => {
                    keys.forEach((key) => {
                        if (
                            !gfRtn &&
                            row.data[key]
                                ?.toString()
                                .toLocaleLowerCase()
                                .includes(myFilter)
                        ) {
                            gfRtn = true;
                        }
                    });
                });
            }

            if (this.searchRefiners.length > 0 && gfRtn) {
                for (const splitRefiner of this.splitRefiners) {
                    if (rfRtn) {
                        let isFound = false;
                        if (!splitRefiner.splitDataProperty) {
                            isFound = true;
                        } else {
                            for (const refiner of splitRefiner.subRefiners) {
                                if (
                                    !isFound &&
                                        splitRefiner.splitDataProperty.length > 1
                                        ? this.searchRefinersMultiselects.includes(refiner.location)
                                            ? refiner.dataValue
                                                ?.toLocaleLowerCase()
                                                .replace(/\s+/g, '')
                                                .split(",")
                                                .includes(row.data[
                                                    splitRefiner.splitDataProperty[0]
                                                ][
                                                    splitRefiner.splitDataProperty[1]
                                                ]
                                                    ?.toLocaleLowerCase()
                                                    .replace(/\s+/g, ''))
                                            : row.data[
                                                splitRefiner.splitDataProperty[0]
                                            ][splitRefiner.splitDataProperty[1]]
                                                ?.toLocaleLowerCase()
                                                .trim()
                                                .includes(
                                                    refiner.dataValue
                                                        ?.toLocaleLowerCase()
                                                        .trim()
                                                )
                                        : this.searchRefinersGreaterThans.includes(refiner.location)
                                            ? new Date(row.data[
                                                splitRefiner.splitDataProperty[0]
                                            ]
                                                ?.trim())
                                            >= (
                                                new Date(refiner.dataValue
                                                    ?.trim())
                                            )
                                            : this.searchRefinersLessThans.includes(refiner.location)
                                                ? new Date(row.data[
                                                    splitRefiner.splitDataProperty[0]
                                                ]
                                                    ?.trim())
                                                <= (
                                                    new Date(refiner.dataValue
                                                        ?.trim())
                                                )
                                                : this.searchRefinersMultiselects.includes(refiner.location)
                                                    ? refiner.dataValue
                                                        ?.toLocaleLowerCase()
                                                        .replace(/\s+/g, '')
                                                        .split(",")
                                                        .some((dv) => {
                                                            return row.data[
                                                                splitRefiner.splitDataProperty[0]
                                                            ]
                                                                ?.toLocaleLowerCase()
                                                                .replace(/\s+/g, '')
                                                                .includes(dv);
                                                        })
                                                    : row.data[
                                                        splitRefiner.splitDataProperty[0]
                                                    ]
                                                        ?.toLocaleLowerCase()
                                                        .trim()
                                                        .includes(
                                                            refiner.dataValue
                                                                ?.toLocaleLowerCase()
                                                                .trim()
                                                        )
                                ) {
                                    isFound = true;
                                    const i = 0;
                                }
                            }
                        }
                        if (!isFound) {
                            rfRtn = false;
                        }
                    }
                }
            }
            return gfRtn && rfRtn;
        };
    }

    private getDefaultSort(): (columnDef: ColumnDef) => void {
        return (columnDef: ColumnDef) => {
            const dataSource = this.renderedDataSource.data?.length
                ? this.renderedDataSource
                : this.dataSource;
            if (this.sort.direction) {
                let multiplyer = 1;

                if (this.sort.direction === "desc") {
                    multiplyer *= -1;
                }
                dataSource.data.sort((a, b) =>
                    (a.data[columnDef.dataPropertyName] ?? "") <=
                        (b.data[columnDef.dataPropertyName] ?? "")
                        ? -1 * multiplyer
                        : 1 * multiplyer
                );
            } else {
                dataSource.data = this.data.slice();
            }
            for (let row = 0; row < dataSource.data.length; row++) {
                dataSource.data[row].index = row;
            }
            if (this.grid) {
                dataSource.sort = this.sort;
                this.grid.renderRows();
                if (this.renderedDataSource.data?.length) {
                    this.renderedDataSourceChange.emit(this.renderedDataSource);
                    return;
                }
                this.dataSourceChange.emit(this.dataSource);
            }
        };
    }

    //this function handles the known issue of implementing a sticky header in a cdk virtual scroll component (https://github.com/angular/components/issues/14833). It implements one of the solutions found on GitHub (https://stackblitz.com/edit/components-issue-brsnj4?file=app%2Fapp.component.html)
    // public get inverseOfTranslation(): string {
    //     if (!this.viewport || !this.viewport["_renderedContentOffset"]) {
    //         return "-0px";
    //     }
    //     let offset = this.viewport["_renderedContentOffset"];
    //     return `-${offset}px`;
    // }
}

<form class="example-form" autocomplete="off">
    <mat-form-field appearance="outline" color="accent" class="w-100 mb-3">
        <mat-label>Select an Entry Type</mat-label>
        <mat-select [(value)]="swisherOverlayRef.data.selectedTimeEntryType" (valueChange)="validateOnChange()" [compareWith]="compareTimeEntryTypes">
            <mat-option *ngFor="let type of timeTypes" [value]="type">
                {{type.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="d-flex align-items-center">

        <div class="d-flex align-items-center">
            <mat-form-field appearance="outline" color="accent">
                <mat-label>Start Time</mat-label>
                <input matInput name="start" [formControl]="startTimeControl" [owlDateTime]="dt1"
                    placeholder="EX: 8:00 AM" [value]="startTime">
                <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt1">
                    <mat-icon>schedule</mat-icon>
                </button>
                <mat-error *ngIf="startTimeControl.invalid">
                    Time invalid.
                </mat-error>
                <mat-error *ngIf="startTimeControl.hasError('overlap')">
                    Time range overlaps an existing time entry range.
                </mat-error>
                <mat-error *ngIf="startTimeControl.hasError('startGreaterThenEnd')">
                    Start time can not be greater than the end time.
                </mat-error>
                <mat-error *ngIf="startTimeControl.hasError('startLaterThenNow')">
                    For work entries, time can not be later than the current time.
                </mat-error>
                <owl-date-time [pickerType]="'timer'" #dt1 [hour12Timer]="true"></owl-date-time>
            </mat-form-field>
        </div>

        <fa-icon [icon]="faArrowRight" class="text-black mx-3"></fa-icon>

        <div class="d-flex align-items-center">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" color="accent">
                        <mat-label>End Time</mat-label>
                        <input matInput name="end" [formControl]="endTimeControl" [owlDateTime]="dt2"
                            placeholder="EX: 3:45 PM" [value]="endTime">
                        <button matSuffix mat-icon-button [owlDateTimeTrigger]="dt2">
                            <mat-icon>schedule</mat-icon>
                        </button>
                        <mat-error *ngIf="endTimeControl.invalid">
                            Time invalid.
                        </mat-error>
                        <mat-error *ngIf="endTimeControl.hasError('overlap')">
                            Time range overlaps an existing time entry range.
                        </mat-error>
                        <mat-error *ngIf="endTimeControl.hasError('startGreaterThenEnd')">
                            Start time can not be greater than the end time.
                        </mat-error>
                        <mat-error *ngIf="endTimeControl.hasError('endLaterThenNow')">
                            For work entries, time can not be later than the current time.
                        </mat-error>
                        <owl-date-time [pickerType]="'timer'" #dt2 [hour12Timer]="true"></owl-date-time>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <mat-form-field appearance="outline" color="accent" class="w-100 mt-3">
        <mat-label id="time-entry-comments">Comments</mat-label>
        <textarea matInput name="comments" [(ngModel)]="swisherOverlayRef.data.comments"
            placeholder="These words are my own..." maxlength="4000">
        </textarea>
        <mat-hint align="end">{{
            swisherOverlayRef.data.comments?.length || 0
            }}/4000</mat-hint>
    </mat-form-field>
</form>

import { Injectable } from '@angular/core';
import { CallCashProductViewModel } from '../call-viewmodels/call-cash-product.viewmodel';
import { CallGratisProductViewModel } from '../call-viewmodels/call-gratis-product.viewmodel';
import { CallProductInViewModel } from '../call-viewmodels/call-product-in.viewmodel';
import { CallProductOutViewModel } from '../call-viewmodels/call-product-out.viewmodel';

export type Copyable = Partial<
    CallProductInViewModel &
    CallProductOutViewModel &
    CallCashProductViewModel &
    CallGratisProductViewModel
>;

@Injectable()
export class CallCopyService {

    constructor() { }

    private copyFields<T extends Copyable>(
        toProduct: T,
        fromProduct: T,
    ): T {
        toProduct.oldQuantity = toProduct.quantity;
        toProduct.oldUnits = toProduct.units;
        toProduct.oldPrice = toProduct.price;
        toProduct.oldWholesalePrice = toProduct.wholesalePrice;
        toProduct.quantity = fromProduct.quantity;
        toProduct.price = fromProduct.price;
        toProduct.discount = fromProduct.discount;
        toProduct.wholesalePrice = fromProduct.wholesalePrice;
        toProduct.value = fromProduct.value;

        //Attempt to find the UPC with the same unit of measure on the destination cash product.
        //If none is found, stick with the default.
        const fromUnitOfMeasure = fromProduct.unitsOfMeasure.find(uom => uom.upc === fromProduct.upc);
        const toUnitOfMeasure = toProduct.unitsOfMeasure.find(
            uom => uom.uom === fromUnitOfMeasure?.uom
        );
        if (fromUnitOfMeasure && fromUnitOfMeasure.uom === toUnitOfMeasure?.uom) {
            toProduct.upc = toUnitOfMeasure.upc;
            toProduct.units = toUnitOfMeasure.noOfEaches;
        }
        return toProduct;
    }

    copy<T extends Copyable>(
        fromProduct: T,
        allProducts: T[],
    ): T | undefined {
        const startingIndex = allProducts.indexOf(fromProduct);
        if (startingIndex < 0) {
            return;
        }
        let toProduct = allProducts.find((prod, i) => i > startingIndex && prod.isPristine);
        if (toProduct) {
            this.copyFields(toProduct, fromProduct);
        }
        return toProduct;
    }
}

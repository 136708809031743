import { Component, ElementRef, ViewChild, signal } from '@angular/core';
import { Subsidiary } from 'shield.shared';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';

@Component({
    selector: 'app-eas-retail-wide-tax-format-swisher-rep',
    templateUrl: './eas-retail-wide-tax-format-swisher-rep.component.html',
    styleUrls: ['./eas-retail-wide-tax-format-swisher-rep.component.scss']
})
export class EasRetailWideTaxFormatSwisherRepComponent extends RetailBaseComponent {

    @ViewChild("originalEasRetailReceipt") originalEasRetailReceipt: ElementRef;

    printLogo = "/assets/img/easPrintLogo.jpg";
    subsidiary = signal(Subsidiary.EAS);
}

import { Injectable } from "@angular/core";
import { MapGeocoder } from "@angular/google-maps";
import { Observable, of, switchMap, throwError } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class GeocoderService {

    constructor(private geocoder: MapGeocoder) {}

    geocode(
        request: google.maps.GeocoderRequest
    ): Observable<google.maps.GeocoderResult[]> {
        return this.geocoder.geocode(request).pipe(
            switchMap(response => {
                if (response.status === google.maps.GeocoderStatus.OK) {
                    return of(response.results);
                } else {
                    return throwError(() => new Error(`Google geocoder error: ${response.status}`));
                }
            }
        ));
    }
}

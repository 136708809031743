import { Injectable } from "@angular/core";
import {
    BehaviorSubject,
    Observable,
    ReplaySubject
} from "rxjs";
import {
    CustomerTypeEnum,
    CustomerTypeLookup,
    GenericLookup
} from "shield.shared";
import { UserFilter } from "src/app/entity-models/user-filter.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { DatabaseService } from "src/app/services/database.service";
import { AreaToZrt } from "../entity-models/area-to-zrt.entity";
import { Area } from "../entity-models/area.entity";
import { Country } from "../entity-models/country.entity";
import { County } from "../entity-models/county.entity";
import { State } from "../entity-models/state.entity";
import { MyListCommands } from "../enums/my-list-commands";

@Injectable({
    providedIn: "root"
})
export class FilterService {
    constructor(private dbService: DatabaseService) {}

    myListEntries$ = new BehaviorSubject<string[]>(new Array<string>());
    myListEntriesObservable: Observable<string[]> = this.myListEntries$.asObservable();
    myListCommand$ = new ReplaySubject<MyListCommands>(1);
    myListCommandObservable: Observable<MyListCommands> = this.myListCommand$.asObservable();

    specialCoverage$ = new ReplaySubject<boolean>(1);
    specialCoverageObservable: Observable<boolean> = this.specialCoverage$.asObservable();

    async getUserFilters(
        userId: string,
        filterLocation: FilterLocation
    ): Promise<UserFilter> {
        let userFilter: UserFilter = await this.dbService.userFilters
            .where({
                userId,
                filterLocation
            })
            .first();

        if (!userFilter) {
            userFilter = new UserFilter();
            userFilter.id = this.dbService.newSequentialId();
            userFilter.userId = userId;
            userFilter.filterLocation = filterLocation;
            userFilter.myList = [];
            userFilter.myFilters = [];
        } else {
            userFilter.myFilters = userFilter.myFilters.sort((a, b) =>
                a.name >= b.name ? 1 : -1
            );
        }

        return userFilter;
    }

    async saveUserSearch(userFilter: UserFilter): Promise<void> {
        await this.dbService.userFilters.put(userFilter, userFilter.id);
    }

    async getAreas(): Promise<Area[]> {
        return (await this.dbService.areas.toArray()).sort((a, b) =>
            a.id >= b.id ? 1 : -1
        );
    }

    async getAreaToZrt(): Promise<AreaToZrt[]> {
        return (await this.dbService.areaToZrts.toArray()).sort((a, b) =>
        a.id >= b.id ? 1 : -1
    );
    }

    async getCountries(): Promise<Country[]> {
        return (await this.dbService.countries.toArray()).sort((a, b) =>
            a.name >= b.name ? 1 : -1
        );
    }

    async getStates(): Promise<State[]> {
        return (await this.dbService.states.toArray()).sort((a, b) =>
            a.name >= b.name ? 1 : -1
        );
    }

    async getCounties(): Promise<County[]> {
        return (await this.dbService.counties.toArray()).sort((a, b) =>
            a.name >= b.name ? 1 : -1
        );
    }

    getCustomerTypes(): GenericLookup<CustomerTypeEnum>[]{
        return CustomerTypeLookup;
    }
}

import { Component, signal } from '@angular/core';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-eas-retail-narrow-tax-format-swisher-rep-draft',
    templateUrl: './eas-retail-narrow-tax-format-swisher-rep-draft.component.html',
    styleUrls: ['./eas-retail-narrow-tax-format-swisher-rep-draft.component.scss']
})
export class EasRetailNarrowTaxFormatSwisherRepDraftComponent extends RetailBaseComponent {

    subsidiary = signal(Subsidiary.EAS);
}

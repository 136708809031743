import { AfterContentChecked, Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { RefinerLocation, GenericLookup, ContractPaymentStatuses, ContractPaymentTypes, ContractPaymentMethods, ContractTemplateTypes, valueSeparator, CommonFilters, ProgramLevels } from "shield.shared";
import { ContractInformationViewmodel } from "./contract-information.viewmodel";
import { UntypedFormBuilder } from "@angular/forms";
import moment from "moment";

@Component({
    selector: "app-contract-information-filter",
    templateUrl: "./contract-information-filter.component.html",
    styleUrls: ["./contract-information-filter.component.css"]
})
export class ContractInformationFilterComponent extends FilterBaseComponent implements AfterContentChecked {
    //view childs
    @ViewChild("contractTypesSelect") contractTypesSelect: MatSelect;
    @ViewChild("lastCallStatusSelect") lastCallStatusSelect: MatSelect;
    @ViewChild("paymentTypesSelect") paymentTypesSelect: MatSelect;
    @ViewChild("paymentMethodsSelect") paymentMethodsSelect: MatSelect;
    @ViewChild("programLevelsSelect") programLevelsSelect: MatSelect;

    @ViewChild("signedStartDateInput") signedStartDateInput: ElementRef;
    @ViewChild("signedEndDateInput") signedEndDateInput: ElementRef;
    @ViewChild("paymentStartDateInput") paymentStartDateInput: ElementRef;
    @ViewChild("paymentEndDateInput") paymentEndDateInput: ElementRef;

    //public vars
    contentComponent: Type<ContractInformationFilterComponent>;
    icon = "history_edu";
    name = "Contract Information";
    viewmodel = new ContractInformationViewmodel(
        this.filterService, this.formBuilder
    );

    constructor(
        private filterService: FilterService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    ngAfterContentChecked(): void {
        if (!this.isInitialized && this.viewmodel.dropdownsInitialized) {
            this.setInitialized();
        }
    }

    //events
    onAddContractRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.contract,
            this.viewmodel.contractInput
        );
        if (
            this.viewmodel.contractInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.contract;
            refiner.value = this.viewmodel.contractInput;
            refiner.dataPropertyName = "contract";

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onAddSignedEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.signedEndDate.errors) return;

        const value = this.signedEndDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.signedOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.signedOnOrBeforeDate,
                this.signedEndDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.signedOnOrBeforeDate;
                    refiner.value = this.signedEndDateInput.nativeElement.value;
                    refiner.dataPropertyName = "endDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddSignedStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.signedStartDate.errors) return;

        const value = this.signedStartDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.signedOnOrAfterDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.signedOnOrAfterDate,
                this.signedStartDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.signedOnOrAfterDate;
                    refiner.value = this.signedStartDateInput.nativeElement.value;
                    refiner.dataPropertyName = "startDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddPaymentEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.paymentEndDate.errors) return;

        const value = this.paymentEndDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.paymentOnOrBeforeDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.paymentOnOrBeforeDate,
                this.paymentEndDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.paymentOnOrBeforeDate;
                    refiner.value = this.paymentEndDateInput.nativeElement.value;
                    refiner.dataPropertyName = "endDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onAddPaymentStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        if (this.viewmodel.dateForm.controls.paymentStartDate.errors) return;

        const value = this.paymentStartDateInput.nativeElement.value;
        const existing = this._refinerService.refiners.find(v => v.location === RefinerLocation.paymentOnOrAfterDate);

        if (value || existing) {
            this._refinerService.onInputChange(
                RefinerLocation.paymentOnOrAfterDate,
                this.paymentStartDateInput.nativeElement.value
            );
            if (!event || (event as KeyboardEvent).key === "Enter") {
                setTimeout(() => {
                    const refiner = new Refiner();
                    refiner.location = RefinerLocation.paymentOnOrAfterDate;
                    refiner.value = this.paymentStartDateInput.nativeElement.value;
                    refiner.dataPropertyName = "startDate";

                    this._refinerService.checkAndUpdateRefiner(refiner);
                }, 0)
            }
        }
    }

    onOpenedContractTypesChange(): void {
        this.viewmodel.isAllContractTypePresent = this.viewmodel.selectedContractTypes.includes(
            this.viewmodel.allContractType
        );
        if (this.viewmodel.isAllContractTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.contractType, true, false);
        }
        else {
            if (!this.contractTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.contractType;
                refiner.value = this.viewmodel.selectedContractTypes
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedContractTypes
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionContractTypesChange(): void {
        if (
            this.viewmodel.isAllContractTypePresent &&
            this.viewmodel.selectedContractTypes.length > 1
        ) {
            const index = this.viewmodel.selectedContractTypes.findIndex(
                (ct) => ct === this.viewmodel.allContractType
            );
            if (index !== -1) {
                this.viewmodel.selectedContractTypes.splice(index, 1);
                this.viewmodel.isAllContractTypePresent = false;
            }
        } else if (
            !this.viewmodel.isAllContractTypePresent &&
            this.viewmodel.selectedContractTypes.includes(
                this.viewmodel.allContractType
            )
        ) {
            this.viewmodel.selectedContractTypes = [
                this.viewmodel.allContractType
            ];
            this.viewmodel.isAllContractTypePresent = true;
        }
    }

    onOpenedLastCallStatusChange(): void {
        this.viewmodel.isAllLastCallStatusPresent = this.viewmodel.selectedLastCallStatuses.includes(
            this.viewmodel.allLastCallStatus
        );
        if (this.viewmodel.isAllLastCallStatusPresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.lastCallStatus, true, false);
        }
        else {
            if (!this.lastCallStatusSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.lastCallStatus;
                refiner.value = this.viewmodel.selectedLastCallStatuses
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedLastCallStatuses
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionLastCallStatusChange(): void {
        if (
            this.viewmodel.isAllLastCallStatusPresent &&
            this.viewmodel.selectedLastCallStatuses.length > 1
        ) {
            const index = this.viewmodel.selectedLastCallStatuses.findIndex(
                (lcs) => lcs === this.viewmodel.allLastCallStatus
            );
            if (index !== -1) {
                this.viewmodel.selectedLastCallStatuses.splice(index, 1);
                this.viewmodel.isAllLastCallStatusPresent = false;
            }
        } else if (
            !this.viewmodel.isAllLastCallStatusPresent &&
            this.viewmodel.selectedLastCallStatuses.includes(
                this.viewmodel.allLastCallStatus
            )
        ) {
            this.viewmodel.selectedLastCallStatuses = [
                this.viewmodel.allLastCallStatus
            ];
            this.viewmodel.isAllLastCallStatusPresent = true;
        }
    }

    onOpenedPaymentTypesChange(): void {
        this.viewmodel.isAllPaymentTypePresent = this.viewmodel.selectedPaymentTypes.includes(
            this.viewmodel.allPaymentType
        );
        if (this.viewmodel.isAllPaymentTypePresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.paymentType, true, false);
        }
        else {
            if (!this.paymentTypesSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.paymentType;
                refiner.value = this.viewmodel.selectedPaymentTypes
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedPaymentTypes
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionPaymentTypesChange(): void {
        if (
            this.viewmodel.isAllPaymentTypePresent &&
            this.viewmodel.selectedPaymentTypes.length > 1
        ) {
            const index = this.viewmodel.selectedPaymentTypes.findIndex(
                (pt) => pt === this.viewmodel.allPaymentType
            );
            if (index !== -1) {
                this.viewmodel.selectedPaymentTypes.splice(index, 1);
                this.viewmodel.isAllPaymentTypePresent = false;
            }
        } else if (
            !this.viewmodel.isAllPaymentTypePresent &&
            this.viewmodel.selectedPaymentTypes.includes(
                this.viewmodel.allPaymentType
            )
        ) {
            this.viewmodel.selectedPaymentTypes = [
                this.viewmodel.allPaymentType
            ];
            this.viewmodel.isAllPaymentTypePresent = true;
        }
    }

    onOpenedPaymentMethodsChange(): void {
        this.viewmodel.isAllPaymentMethodPresent = this.viewmodel.selectedPaymentMethods.includes(
            this.viewmodel.allPaymentMethod
        );
        if (this.viewmodel.isAllPaymentMethodPresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.paymentMethod, true, false);
        }
        else {
            if (!this.paymentMethodsSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.paymentMethod;
                refiner.value = this.viewmodel.selectedPaymentMethods
                    .map((vm) => vm.description)
                    .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedPaymentMethods
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionPaymentMethodsChange(): void {
        if (
            this.viewmodel.isAllPaymentMethodPresent &&
            this.viewmodel.selectedPaymentMethods.length > 1
        ) {
            const index = this.viewmodel.selectedPaymentMethods.findIndex(
                (pm) => pm === this.viewmodel.allPaymentMethod
            );
            if (index !== -1) {
                this.viewmodel.selectedPaymentMethods.splice(index, 1);
                this.viewmodel.isAllPaymentMethodPresent = false;
            }
        } else if (
            !this.viewmodel.isAllPaymentMethodPresent &&
            this.viewmodel.selectedPaymentMethods.includes(
                this.viewmodel.allPaymentMethod
            )
        ) {
            this.viewmodel.selectedPaymentMethods = [
                this.viewmodel.allPaymentMethod
            ];
            this.viewmodel.isAllPaymentMethodPresent = true;
        }
    }

    onOpenedProgramLevelsChange(): void {
        this.viewmodel.isAllProgramLevelPresent = this.viewmodel.selectedProgramLevels.includes(
            this.viewmodel.allProgramLevel
        );
        if (this.viewmodel.isAllProgramLevelPresent) {
            this._refinerService.removeRefinerByLocation(RefinerLocation.programLevel, true, false);
        }
        else {
            if (!this.programLevelsSelect.panelOpen) {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.programLevel;
                refiner.value = this.viewmodel.selectedProgramLevels
                .join(", ");
                refiner.dataPropertyName = "name";
                refiner.dataValue = this.viewmodel.selectedProgramLevels
                .join(valueSeparator);

                this._refinerService.checkAndUpdateRefiner(refiner);
            }
        }
    }

    onSelectionProgramLevelsChange(): void {
        if (
            this.viewmodel.isAllProgramLevelPresent &&
            this.viewmodel.selectedProgramLevels.length > 1
        ) {
            const index = this.viewmodel.selectedProgramLevels.findIndex(
                (pl) => pl === this.viewmodel.allProgramLevel
            );
            if (index !== -1) {
                this.viewmodel.selectedProgramLevels.splice(index, 1);
                this.viewmodel.isAllProgramLevelPresent = false;
            }
        } else if (
            !this.viewmodel.isAllProgramLevelPresent &&
            this.viewmodel.selectedProgramLevels.includes(
                this.viewmodel.allProgramLevel
            )
        ) {
            this.viewmodel.selectedProgramLevels = [
                this.viewmodel.allProgramLevel
            ];
            this.viewmodel.isAllProgramLevelPresent = true;
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.contract:
                    if (refiner.value !== this.viewmodel.contractInput) {
                        this.viewmodel.contractInput = refiner.value;
                    }
                    break;
                case RefinerLocation.signedOnOrAfterDate:
                    if (refiner.value !== this.signedStartDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.signedStartDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : null)
                    }
                    break;
                case RefinerLocation.signedOnOrBeforeDate:
                    if (refiner.value !== this.signedEndDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.signedEndDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : null)
                    }
                    break;
                case RefinerLocation.paymentOnOrAfterDate:
                    if (refiner.value !== this.paymentStartDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.paymentStartDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : null)
                    }
                    break;
                case RefinerLocation.paymentOnOrBeforeDate:
                    if (refiner.value !== this.paymentEndDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.paymentEndDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : null)
                    }
                    break;
                case RefinerLocation.contractType:
                    const selectedContractTypes = refiner.value?.split(", ");
                    const rtnSelectedContractTypes = new Array<GenericLookup<ContractTemplateTypes>>();

                    if (selectedContractTypes) {
                        for (const type of selectedContractTypes) {
                            const found = this.viewmodel.contractTypes.find(
                                (ct) => ct.description === type.trim()
                            );
                            if (found) {
                                rtnSelectedContractTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedContractTypes = rtnSelectedContractTypes.length
                        ? rtnSelectedContractTypes
                        : [this.viewmodel.allContractType];
                    this.onOpenedContractTypesChange();
                    break;
                case RefinerLocation.lastCallStatus:
                    const selectedLastCallStatuses = refiner.value?.split(", ");
                    const rtnSelectedLastCallStatuses = new Array<GenericLookup<ContractPaymentStatuses>>();

                    if (selectedLastCallStatuses) {
                        for (const status of selectedLastCallStatuses) {
                            const found = this.viewmodel.lastCallStatuses.find(
                                (lcs) => lcs.description === status.trim()
                            );
                            if (found) {
                                rtnSelectedLastCallStatuses.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedLastCallStatuses = rtnSelectedLastCallStatuses.length
                        ? rtnSelectedLastCallStatuses
                        : [this.viewmodel.allLastCallStatus];
                    this.onOpenedLastCallStatusChange();
                    break;
                case RefinerLocation.paymentType:
                    const selectedPaymentTypes = refiner.value?.split(", ");
                    const rtnSelectedPaymentTypes = new Array<GenericLookup<ContractPaymentTypes>>();

                    if (selectedPaymentTypes) {
                        for (const type of selectedPaymentTypes) {
                            const found = this.viewmodel.paymentTypes.find(
                                (pt) => pt.description === type.trim()
                            );
                            if (found) {
                                rtnSelectedPaymentTypes.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedPaymentTypes = rtnSelectedPaymentTypes.length
                        ? rtnSelectedPaymentTypes
                        : [this.viewmodel.allPaymentType];
                    this.onOpenedPaymentTypesChange();
                    break;
                case RefinerLocation.paymentMethod:
                    const selectedPaymentMethods = refiner.value?.split(", ");
                    const rtnSelectedPaymentMethods = new Array<GenericLookup<ContractPaymentMethods>>();

                    if (selectedPaymentMethods) {
                        for (const method of selectedPaymentMethods) {
                            const found = this.viewmodel.paymentMethods.find(
                                (pm) => pm.description === method.trim()
                            );
                            if (found) {
                                rtnSelectedPaymentMethods.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedPaymentMethods = rtnSelectedPaymentMethods.length
                        ? rtnSelectedPaymentMethods
                        : [this.viewmodel.allPaymentMethod];
                    this.onOpenedPaymentMethodsChange();
                    break;
                case RefinerLocation.programLevel:
                    const selectedProgramLevels = refiner.value?.split(", ");
                    const rtnSelectedProgramLevels = new Array<CommonFilters.All|ProgramLevels>();

                    if (selectedProgramLevels) {
                        for (const level of selectedProgramLevels) {
                            const found = this.viewmodel.programLevels.find(
                                (pl) => pl === level.trim()
                            );
                            if (found) {
                                rtnSelectedProgramLevels.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedProgramLevels = rtnSelectedProgramLevels.length
                        ? rtnSelectedProgramLevels
                        : [this.viewmodel.allProgramLevel];
                    this.onOpenedProgramLevelsChange();
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();

        tempRefiner.location = RefinerLocation.contract;
        const contractRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.contract
        );
        if (contractRefiner) {
            this.onInputChange(contractRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.contractType;
        const contractTypeRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.contractType
        );
        if (contractTypeRefiner) {
            this.onInputChange(contractTypeRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.lastCallStatus;
        const lastCallStatusRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.lastCallStatus
        );
        if (lastCallStatusRefiner) {
            this.onInputChange(lastCallStatusRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.signedOnOrAfterDate;
        const signedOnOrAfterDateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.signedOnOrAfterDate
        );
        if (signedOnOrAfterDateRefiner) {
            this.onInputChange(signedOnOrAfterDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.signedOnOrBeforeDate;
        const signedOnOrBeforeDateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.signedOnOrBeforeDate
        );
        if (signedOnOrBeforeDateRefiner) {
            this.onInputChange(signedOnOrBeforeDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.paymentOnOrAfterDate;
        const paymentOnOrAfterDateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.paymentOnOrAfterDate
        );
        if (paymentOnOrAfterDateRefiner) {
            this.onInputChange(paymentOnOrAfterDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.paymentOnOrBeforeDate;
        const paymentOnOrBeforeDateRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.paymentOnOrBeforeDate
        );
        if (paymentOnOrBeforeDateRefiner) {
            this.onInputChange(paymentOnOrBeforeDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.paymentType;
        const paymentTypesRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.paymentType
        );
        if (paymentTypesRefiner) {
            this.onInputChange(paymentTypesRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.paymentMethod;
        const paymentMethodsRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.paymentMethod
        );
        if (paymentMethodsRefiner) {
            this.onInputChange(paymentMethodsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.programLevel;
        const programLevelsRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.programLevel
        );
        if (programLevelsRefiner) {
            this.onInputChange(programLevelsRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}

import { FilterService } from "src/app/services/filter.service";
import {  GenericLookup, ContractPaymentStatuses, ContractPaymentTypes, ContractPaymentMethods, ContractPaymentTypeLookup, ContractPaymentMethodLookup, ContractPaymentStatusLookup, ContractTemplateTypes, ContractTemplateTypeLookup, ProgramLevels, CommonFilters } from "shield.shared";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import moment from "moment";
import { ElementRef } from "@angular/core";


export class ContractInformationViewmodel {
    //public vars
    filterService: FilterService;
    formBuilder: UntypedFormBuilder;
    dateForm: UntypedFormGroup;

    contractInput: string;

    signedStartDateInput: ElementRef;
    signedStartDateDefault: moment.Moment = moment().subtract(7, "days");
    signedStartMaxDate: moment.Moment = moment();

    signedEndDateInput: ElementRef;
    signedEndDateDefault: moment.Moment = moment();
    signedEndMaxDate: moment.Moment = moment();

    paymentStartDateInput: ElementRef;
    paymentStartMaxDate: moment.Moment = moment();

    paymentEndDateInput: ElementRef;
    paymentEndMaxDate: moment.Moment = moment();

    all: CommonFilters.All = CommonFilters.All;

    allContractType = new GenericLookup<ContractTemplateTypes>();
    isAllContractTypePresent: boolean;
    contractTypes = new Array<GenericLookup<ContractTemplateTypes>>();
    selectedContractTypes = new Array<GenericLookup<ContractTemplateTypes>>();

    allLastCallStatus = new GenericLookup<ContractPaymentStatuses>();
    isAllLastCallStatusPresent: boolean;
    lastCallStatuses = new Array<GenericLookup<ContractPaymentStatuses>>();
    selectedLastCallStatuses = new Array<GenericLookup<ContractPaymentStatuses>>();

    allPaymentType = new GenericLookup<ContractPaymentTypes>();
    isAllPaymentTypePresent: boolean;
    paymentTypes = new Array<GenericLookup<ContractPaymentTypes>>();
    selectedPaymentTypes = new Array<GenericLookup<ContractPaymentTypes>>();

    allPaymentMethod = new GenericLookup<ContractPaymentMethods>();
    isAllPaymentMethodPresent: boolean;
    paymentMethods = new Array<GenericLookup<ContractPaymentMethods>>();
    selectedPaymentMethods = new Array<GenericLookup<ContractPaymentMethods>>();

    allProgramLevel = this.all;
    isAllProgramLevelPresent: boolean;
    programLevels = new Array<CommonFilters.All|ProgramLevels>();
    selectedProgramLevels = new Array<CommonFilters.All|ProgramLevels>();

    dropdownsInitialized = false;

    constructor(
        filterService: FilterService,

        formBuilder: UntypedFormBuilder
    ) {
        this.filterService = filterService;

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            signedEndDate: ["", []],
            signedStartDate: ["", []],
            paymentEndDate: ["", []],
            paymentStartDate: ["", []]
        });

        this.initializeDropDowns();
    }

    initializeDropDowns(): void {
        this.allContractType.description = this.all;
        this.selectedContractTypes.push(this.allContractType);
        this.contractTypes.push(this.allContractType);
        for (const type of ContractTemplateTypeLookup) {
            this.contractTypes.push(type);
        }

        this.allLastCallStatus.description = this.all;
        this.selectedLastCallStatuses.push(this.allLastCallStatus);
        this.lastCallStatuses.push(this.allLastCallStatus);
        for (const status of ContractPaymentStatusLookup) {
            this.lastCallStatuses.push(status);
        }

        this.allPaymentType.description = this.all;
        this.selectedPaymentTypes.push(this.allPaymentType);
        this.paymentTypes.push(this.allPaymentType);
        for (const type of ContractPaymentTypeLookup) {
            if (type.id !== ContractPaymentTypes.NA) {
                this.paymentTypes.push(type);
            }
        }

        this.allPaymentMethod.description = this.all;
        this.selectedPaymentMethods.push(this.allPaymentMethod);
        this.paymentMethods.push(this.allPaymentMethod);
        for (const method of ContractPaymentMethodLookup) {
            if (method.id !== ContractPaymentMethods.NA) {
                this.paymentMethods.push(method);
            }
        }

        this.selectedProgramLevels.push(this.allProgramLevel);
        this.programLevels.push(this.allProgramLevel);
        this.programLevels.push(ProgramLevels.One);
        this.programLevels.push(ProgramLevels.Two);

        this.dropdownsInitialized = true;
    }
}

import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import { UntypedFormControl, Validators, UntypedFormGroup } from "@angular/forms";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Observable, Subject, Subscription } from "rxjs";

//Models
import { RetailStepperStep } from "../../../enums/retail-stepper-step";

import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import {
    CallPicture
} from "src/app/entity-models/call-picture.entity";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { Helper } from "src/app/helpers/helper";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { CustomerConverterService } from "src/app/services/converter-services/customer-converter.service";
import { StepperCallApplicationService } from "../stepper-call/stepper-call-services/stepper-call-application.service";
import { GenericLookup, PictureTypes } from "shield.shared";
import { MatSlider } from "@angular/material/slider";
import { SnackbarService } from "src/app/services/snackbar.service";

@Component({
    selector: "app-step-cam",
    templateUrl: "./step-cam.component.html",
    styleUrls: ["./step-cam.component.scss"]
})
export class StepCamComponent implements OnInit, OnDestroy {
    //View Children
    @ViewChild("modalCanvas") modalCanvas: ElementRef;
    @ViewChild("focusSlider") focusSlider: MatSlider;
    @ViewChild("confirmButton") confirmButton!: ElementRef;

    focusMin: number;
    focusMax: number;
    focusStep: number;
    focusValue: number;
    brightnessMin: number;
    brightnessMax: number;
    brightnessStep: number;
    brightnessValue: number;

    //Outputs
    @Output() moveStepper = new EventEmitter();

    //Private vars
    private capturedImage: string;
    cameraHeight: number;
    cameraWidth: number;
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<
        boolean | string
    >();
    private tags: string[] = [];
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();

    //Public vars
    get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    allowCameraSwitch = true;
    deviceId: string;
    errors: WebcamInitError[] = [];
    group: UntypedFormGroup;

    multipleWebcamsAvailable = false;
    //You can not Assign a string as a default value for a select unles it is an object property that matches the ngModel
    pictureTypeOptions: GenericLookup<PictureTypes>[] = [
        {
            id: PictureTypes.Before,
            name: PictureTypes.Before,
            description: undefined
        },
        {
            id: PictureTypes.After,
            name: PictureTypes.After,
            description: undefined
        },
        {
            id: PictureTypes.Displays,
            name: PictureTypes.Displays,
            description: undefined
        },
        {
            id: PictureTypes.Signage,
            name: PictureTypes.Signage,
            description: undefined
        },
        {
            id: PictureTypes.Other,
            name: PictureTypes.Other,
            description: undefined
        }
    ];
    selectedPictureValue: PictureTypes;

    // toggle webcam on/off
    showWebcam = true;
    tagsString: string = null;
    useFrontCamera = true;
    videoOptions: MediaTrackConstraints = {
        facingMode: { ideal: "environment" }
    };
    // latest snapshot
    webcamImage: WebcamImage = null;

    tagLimitRegEx = new RegExp("^([^,][*,]?[^,]*){0,10}$");

    tagFormControl = new UntypedFormControl(this.tagsString, {
        validators: [Validators.pattern(this.tagLimitRegEx)]
    });

    tagStringSubscription: Subscription;

    @HostListener("window:resize", ["$event"])
    onResize(event?: Event): void {
        const win = event ? (event.target as Window) : window;
        this.cameraWidth = win.innerWidth * 0.437;
        this.cameraHeight = this.cameraWidth * 0.749;
    }

    public constructor(
        public overlayRef: SwisherOverlayRef<null, StepCamComponent>,
        private stepperCallApplicationService: StepperCallApplicationService,
        private customerStateService: CustomerStateService,
        private changeDetectorRef: ChangeDetectorRef,
        private ref: SwisherOverlayRef<RetailStepperStep, StepCamComponent>,
        private snackbarService: SnackbarService
    ) {
        this.onResize();
    }

    ngOnInit(): void {
        this.overlayRef.blocking = true;

        this.group = new UntypedFormGroup({
            select: new UntypedFormControl("", Validators.required)
        });

        if (!this.tagStringSubscription || this.tagStringSubscription.closed) {
            this.tagStringSubscription =
                this.tagFormControl.valueChanges.subscribe(() => {
                    if (this.tagFormControl.valid) {
                        this.tagsString = this.tagFormControl.value as string;
                    }
                });
        }

        this.selectedPictureValue = null;

        switch (this.ref?.data) {
            case RetailStepperStep.before:
                this.selectedPictureValue = PictureTypes.Before;
                break;

            case RetailStepperStep.after:
                this.selectedPictureValue = PictureTypes.After;
                break;

            default:
                break;
        }

        this.group.controls.select.setValue(this.selectedPictureValue);
        this.group.markAsUntouched();

        WebcamUtil.getAvailableVideoInputs().then(
            (mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable =
                    mediaDevices && mediaDevices.length > 1;
            }
        );
    }

    ngOnDestroy(): void {
        if (this.tagStringSubscription && !this.tagStringSubscription.closed) {
            this.tagStringSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.confirmButton.nativeElement.focus();
        }, 0);
    }

    //Events
    public onCameraWasSwitched(deviceId: string): void {
        console.log("active device: " + deviceId);
        this.deviceId = deviceId;
    }

    public onHandleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    public async onHandleImage(webcamImage: WebcamImage): Promise<void> {
        this.webcamImage = webcamImage;
        this.changeDetectorRef.detectChanges();
        let zip: string =
            this.customerStateService.customer.businessAddress.zip;
        if (zip && zip.length > 5) {
            zip = zip.substring(0, 4);
        }
        const address = CustomerConverterService.getCustomerFlatBusinessAddress(
            this.customerStateService.customer
        );
        const datetime: string = new Date().toLocaleString();

        const textLine1: string = `${this.customerStateService.customer.name}, ${address}`;

        const textLine2: string = datetime;

        await this.addTextToImage(
            this.webcamImage.imageAsDataUrl,
            textLine1,
            textLine2
        );
    }

    public async onSave(): Promise<void> {
        if (this.tagsString) {
            const splitTaggs: string[] = this.tagsString.split(",", 10);

            splitTaggs.forEach((tag) => {
                this.tags.push(tag.trim());
            });
        }

        if (!this.capturedImage || this.capturedImage.length === 0) {
            if (this.webcamImage) {
                await this.onHandleImage(this.webcamImage);
                if (!this.capturedImage || this.capturedImage.length === 0) {
                    console.error("Webcam Image exists but Captured Image is still null after retrying. Setting Captured Image to Webcam Image as fallback.");
                    this.capturedImage = this.webcamImage.imageAsDataUrl;
                }
            } else {
                this.snackbarService.showError("Save called but no captured image could be found. Please retake the picture and try again.");
                return;
            }
        }

        const callPicture: CallPicture = new CallPicture();
        callPicture.type = this.selectedPictureValue;
        callPicture.tags = this.tags;

        const type = Helper.getCustomerGenericType(
            this.customerStateService.customer
        );

        switch (type) {
            case CustomerGenericTypes.retail:
            case CustomerGenericTypes.wholesale:
                await this.stepperCallApplicationService.addPicture(
                    callPicture,
                    this.capturedImage
                );
                break;
            default:
                break;
        }
        this.restCamera(false);
        this.overlayRef.close();
    }

    public onShowNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public onToggleWebcam(): void {
        this.webcamImage = null;
    }

    public onTriggerSnapshot(): void {
        if (this.webcamImage) {
            this.webcamImage = null;
        }
        this.trigger.next();
    }

    //Private methods
    private async addTextToImage(
        image: string,
        textLine1: string,
        textLine2: string
    ): Promise<void> {
        const canvas = this.modalCanvas.nativeElement as HTMLCanvasElement;
        const context = canvas.getContext("2d");
        const cameraWidth = this.cameraWidth;
        const cameraHeight = this.cameraHeight;
        // Draw Image function
        const img = new Image();
        img.src = image;
        await new Promise((resolve) => {
            img.onload = resolve;
        });
        context.drawImage(img, 0, 0, cameraWidth, cameraHeight);
        context.lineWidth = 2;

        const bannerHieght = 40;

        context.beginPath();
        context.rect(0, cameraHeight - bannerHieght, cameraWidth, bannerHieght);
        context.fillStyle = "black";
        context.fill();

        context.fillStyle = "white";
        context.font = "22px sans-serif";
        context.fillText(textLine1, 5, cameraHeight - 3);

        context.fillText(textLine2, 5, cameraHeight - 25);

        this.capturedImage = canvas.toDataURL();
    }

    public restCamera(shouldClearImage: boolean = true): void {
        this.tagsString = null;
        this.tags = [];

        if (shouldClearImage) {
            this.webcamImage = null;
        }
    }
}

import { ElementRef } from "@angular/core";
import {
    AbstractControl, UntypedFormBuilder, UntypedFormGroup,
    ValidatorFn
} from "@angular/forms";
import {
    MatDrawerMode, MatSidenav, MatSidenavContent
} from "@angular/material/sidenav";
import moment from "moment";
import { Moment } from "moment";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import {
    faEye, faPrint, faCloudDownloadAlt,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import { OrderListRefinerService } from "./orders-list-refiner.service";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { OrderListLineItemViewModel } from "./order-list-line-item.viewmodel";
import {
    FilterSortDto, GenericResponseDto, newSequentialId,
    OrderLineItemStatuses, OrderLineItemStatusLookup, OrderListColumns,
    OrderStatuses, OrderStatusLookup, RefinerLocation,
    SharedHelper, SortDirection, valueSeparator,
    GenericDropDownDto,
    Subsidiary
} from "shield.shared";
import { OrderListGridViewmodel } from "./order-list-grid.viewmodel";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { CustomerLocationFilterComponent } from "src/app/shared/filters/customer-location-filter/customer-location-filter.component";
import { Customer } from "src/app/entity-models/customer.entity";
import { MatSelect } from "@angular/material/select";
import { MatSortHeader } from "@angular/material/sort";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OrderDetailsDialogComponent } from "../../shared/dialogs/order-details-dialog/order-details-dialog.component";
import { OverlayService } from "src/app/services/overlay.service";
import { Router } from "@angular/router";
import { OrderEditOptions } from "../order-edit-options.enum";
import { SnackbarService } from "src/app/services/snackbar.service";
import { OrderEditOptionsComponent } from "../order-edit-options/order-edit-options.component";
import { OrderSelectionViewmodel } from "./order-selection.viewmodel";
import { Helper } from "src/app/helpers/helper";
import { CallDateInformationFilterComponent } from "src/app/shared/filters/call-date-information-filter/call-date-information-filter.component";
import { CallAttributesFilterComponent } from "src/app/shared/filters/call-attributes-filter/call-attributes-filter.component";
import { OrderInformationFilterComponent } from "src/app/shared/filters/order-information-filter/order-information-filter.component";
import { MySearchesFilterComponent } from "src/app/shared/filters/my-searches/my-searches-filter.component";
import { FilterLocation } from "src/app/enums/filter-location";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { Employee } from "src/app/entity-models/employee.entity";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { Order } from "src/app/entity-models/order.entity";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { AngularMultiSelect } from "angular2-multiselect-dropdown";
import { OrderDelineationService } from "src/app/services/delineation-services/order-delineation.service";
import { ActivitiesFilterService } from "src/app/services/activities-filter.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { OrderListZrtFilterService } from "./order-list-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { DialogService } from "src/app/services/dialog.service";

export abstract class OrderListViewmodel {
    //abstract members
    protected abstract getBatchApiCall(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<OrderListColumns>[]): Promise<GenericResponseDto<Order[]>>;

    public abstract columnDef: ColumnDef[];

    //private vars
    private formBuilder: UntypedFormBuilder;
    protected orderDelineationService: OrderDelineationService;
    protected overlayService: OverlayService;
    protected pleaseWaitService: PleaseWaitService;
    protected snackbar: SnackbarService;

    //public vars
    accountInput: string;
    accountOwnershipDelineationService: AccountOwnershipDelineationService;
    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<OrderListColumns>[] = [];
    activitiesFilterService: ActivitiesFilterService;
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    callDelineationService: CallDelineationService;
    cityInput: string;
    columnsToDisplay: string[];
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    customerDelineationService: CustomerDelineationService;
    dataSourceMoveRejectedProducts: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    defaultOrderDate = moment().add(1, "days");
    detailHeight = 10;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    endDateDefault: moment.Moment = moment();
    endDateInput: ElementRef;
    endMaxDate: moment.Moment = moment().add(3, "years");
    endMinDate: moment.Moment = moment().subtract(3, "years");
    faCloudDownloadAlt: IconDefinition = faCloudDownloadAlt;
    faEye: IconDefinition = faEye;
    faPrint: IconDefinition = faPrint;
    filterDataLoaded = false;
    filterLocation = FilterLocation.orderList;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridhieghtOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Order List";
    isEnabledExpandedDetails = true;
    isEnabledOrderEdit: boolean = false;
    isFixedLayout = false;
    isGapSet = false;
    isSaveDisabled = true;
    isSearchButtonDisabled = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    moveRejectedProductsToNewOrder =
        OrderEditOptions.moveRejectedProductsToNewOrder;
    movePendingOrderProductsToNewOrder =
        OrderEditOptions.movePendingOrderProductsToNewOrder;
    myListSubscription: Subscription;
    noneOrderEditOption = OrderEditOptions.none;
    changeWholesalerItemNumber = OrderEditOptions.changeWholesalerItemNumber;
    changeOrderDate = OrderEditOptions.changeOrderDate;
    orderDate: moment.Moment = this.defaultOrderDate;
    orderDateMin = Helper.roundMoment(moment().add(1, "days"));
    orderDateSubscription: Subscription;
    orderDetailsOverlayRef: SwisherOverlayRef<
        OrderListGridViewmodel,
        OrderDetailsDialogComponent
    >;
    orderEditOptions: string[];
    orderEditOptionsComponent: OrderEditOptionsComponent;
    orderEditSelect: MatSelect;
    orderLineItems: OrderListLineItemViewModel[];
    orderSelectables = new Array<OrderSelectionViewmodel>();
    pageIndex = 0;
    pageSize = 50;
    previousRefinerMap: Map<RefinerLocation, string>;
    projectDelineationService: ProjectDelineationService;
    screenHeight: number = 0;
    isSearchStale = false;
    selectedWholesalers: Customer[] = new Array<Customer>();
    selectedWholesaler: Customer;
    selectedWholesalersHold: Customer[] = new Array<Customer>();
    selectedColumnVisibility: string[];
    selectedColumnVisibilityComunicator: string[];
    selectedOrderEdit: OrderEditOptions = OrderEditOptions.none;
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    startDateDefault: moment.Moment = moment().subtract(7, "days");
    startDateInput: ElementRef;
    startMaxDate: moment.Moment = moment().add(3, "years");
    startMinDate: moment.Moment = moment().subtract(3, "years");
    streetInput: string;
    receiptNumber: string;
    refinerInputChangeSubscription: Subscription;
    refinerService: OrderListRefinerService;
    refinerServiceSubscription: Subscription;
    router: Router;
    topGapDistance = 0;
    total: number = 0;
    uinInput: string;
    wholesalers: Customer[] = new Array<Customer>();
    wholesalerSelect: AngularMultiSelect;
    zipInput: string;
    zrtFilterService: OrderListZrtFilterService;
    zrtSelectionSubscription: Subscription;

    //https://cuppalabs.github.io/components/multiselectDropdown/
    wholeSalerDropdownSettings = {
        singleSelection: false,
        text: "Select Wholesalers",
        unSelectAllText: "UnSelect All",
        enableCheckAll: false,
        enableFilterSelectAll: true,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 2,
        labelKey: "displayName",
        searchBy: ["displayName"],
        classes: "multi-select-container c-btn pure-checkbox",
        noDataLabel: "Loading wholesalers..."
    }

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<OrderListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<OrderListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as OrderListColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;

            void this.getOrderBatch(0, true);
        }
    };


    constructor(
        refinerService: OrderListRefinerService,
        formBuilder: UntypedFormBuilder,
        orderDelineationService: OrderDelineationService,
        customerDelineationService: CustomerDelineationService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        projectDelineationService: ProjectDelineationService,
        overlayService: OverlayService,
        pleaseWaitService: PleaseWaitService,
        router: Router,
        snackbar: SnackbarService,
        employeeDelineationService: EmployeeDelineationService,
        filterService: FilterService,
        zrtFilterService: OrderListZrtFilterService,
        activitiesFilterService: ActivitiesFilterService,
        callDelineationService: CallDelineationService,
        protected dialogService: DialogService,
    ) {
        this.refinerService = refinerService;
        this.orderDelineationService = orderDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.projectDelineationService = projectDelineationService;
        this.overlayService = overlayService;
        this.pleaseWaitService = pleaseWaitService;
        this.router = router;
        this.snackbar = snackbar;
        this.employeeDelineationService = employeeDelineationService;
        this.filterService = filterService;
        this.zrtFilterService = zrtFilterService;
        this.activitiesFilterService = activitiesFilterService;
        this.callDelineationService = callDelineationService;

        this.startDateDefault.set({ "hour": 0, "minute": 0, "second": 0, "millisecond": 0 });
        this.startMaxDate.set({ "hour": 0, "minute": 0, "second": 0, "millisecond": 0 });
        this.startMinDate.set({ "hour": 0, "minute": 0, "second": 0, "millisecond": 0 });
        this.endDateDefault.set({ "hour": 23, "minute": 59, "second": 59, "millisecond": 0 });
        this.endMaxDate.set({ "hour": 23, "minute": 59, "second": 59, "millisecond": 0 });
        this.endMinDate.set({ "hour": 23, "minute": 59, "second": 59, "millisecond": 0 });

        this.formBuilder = formBuilder;
        this.dateForm = this.formBuilder.group({
            endDate: ["", [this.lessThanStartDate()]],
            startDate: ["", [this.greaterThanEndDate()]],
            orderDate: ["", [this.isOrderDateInTheFuture()]]
        });
    }

    //events
    onAddAccountRefiner(event?: KeyboardEvent): void {
        if (
            this.accountInput &&
            this.refinerService.getRefinerByLocation(RefinerLocation.account)
                ?.value !== this.accountInput
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.account,
                this.accountInput
            );
        }

        if (this.accountInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.account,
                this.accountInput,
                "name"
            );
        }

        if (!this.accountInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.account
            )
        }
    }

    onAddCityRefiner(): void {
        if (this.cityInput) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "businessAddress.city"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city
            )
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.endDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.endDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.orderOnOrBeforeDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.orderOnOrBeforeDate,
                this.endDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.endDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.orderOnOrBeforeDate,
                    this.dateForm.controls.endDate.value?.format(
                        this.dateFormat
                    ),
                    "endDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                const refiner = this.refinerService.getRefinerByLocation(
                    RefinerLocation.orderOnOrBeforeDate
                );
                this.dateForm.controls.endDate.setValue(
                    refiner?.value
                        ? moment(new Date(refiner.value))
                        : null
                );
            }
        }
    }

    onAddStartDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        const date = this.startDateInput?.nativeElement?.value;
        if (
            Helper.isValidMomentDate(date, this.dateFormat) &&
            !this.dateForm.controls.startDate.errors &&
            this.refinerService.getRefinerByLocation(RefinerLocation.orderOnOrAfterDate)
                ?.value !== date
        ) {
            this.refinerService.onInputChange(
                RefinerLocation.orderOnOrAfterDate,
                this.startDateInput.nativeElement.value
            );

            if (
                this.dateForm.controls.startDate.value &&
                (!event || (event as KeyboardEvent).key === "Enter")
            ) {
                this.refinerService.addRefiner(
                    RefinerLocation.orderOnOrAfterDate,
                    this.dateForm.controls.startDate.value?.format(
                        this.dateFormat
                    ),
                    "startDate"
                );
            }
        } else {
            if (!date && event?.type === "blur") {
                const refiner = this.refinerService.getRefinerByLocation(
                    RefinerLocation.orderOnOrAfterDate
                );
                this.dateForm.controls.startDate.setValue(
                    refiner?.value
                        ? moment(new Date(refiner.value))
                        : null
                );
            }
        }
    }

    onAddStreetRefiner(): void {
        if (this.streetInput) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "businessAddress.address1"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street
            )
        }
    }

    onAddUinRefiner(): void {
        if (this.uinInput) {
            this.refinerService.addRefiner(
                RefinerLocation.uin,
                this.uinInput,
                "uin"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.uin
            )
        }
    }

    onAddZipRefiner(): void {
        if (this.zipInput) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "businessAddress.zip"
            );
        }
        else {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes
            )
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.columnVisibility:
                this.selectedColumnVisibilityComunicator = value;
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    async onOrderEditSelectChange(): Promise<void> {
        if (
            !this.orderEditSelect?.panelOpen &&
            this.selectedOrderEdit !== OrderEditOptions.none
        ) {
            this.orderLineItems = [];
            this.orderSelectables = [];
            let orderStatus: OrderStatuses = null;
            let orderLineItemStatus: OrderLineItemStatuses = null;
            switch (this.selectedOrderEdit) {
                case this.moveRejectedProductsToNewOrder:
                    orderLineItemStatus = OrderLineItemStatuses.Rejected;
                    break;
                case this.movePendingOrderProductsToNewOrder:
                case this.changeWholesalerItemNumber:
                    orderStatus = OrderStatuses.Pending;
                    orderLineItemStatus = OrderLineItemStatuses.Pending;
                    break;
                case this.changeOrderDate: {
                    orderStatus = OrderStatuses.Pending;
                    break;
                }
                default:
                    break;
            }

            if (
                !this.selectedWholesalers ||
                this.selectedWholesalers.length !== 1
            ) {
                this.snackbar.showWarning(
                    "Please select one Wholesaler from the drop down for Edit Option " +
                    this.selectedOrderEdit +
                    "."
                );
                this.selectedOrderEdit = OrderEditOptions.none;
                return;
            }

            const id = newSequentialId();
            const allOrders = await this.getOrderBatch(
                this.pageIndex,
                true,
                false
            );
            const orderLineItems = new Array<OrderListLineItemViewModel>();
            const orders = new Array<OrderSelectionViewmodel>();

            for (const order of allOrders) {
                if (!orderStatus || orderStatus === order.orderStatusId) {
                    if (this.selectedOrderEdit === this.changeOrderDate) {
                        orders.push(new OrderSelectionViewmodel(order));
                    } else {
                        for (const lineItem of order.orderLineItems) {
                            if (
                                !orderLineItemStatus ||
                                orderLineItemStatus ===
                                lineItem.orderLineItemStatusLookupId
                            ) {
                                orderLineItems.push(
                                    new OrderListLineItemViewModel(lineItem)
                                );
                            }
                        }
                    }
                }
            }

            switch (this.selectedOrderEdit) {
                case OrderEditOptions.moveRejectedProductsToNewOrder:
                case OrderEditOptions.movePendingOrderProductsToNewOrder:
                case OrderEditOptions.changeWholesalerItemNumber:
                    this.orderLineItems = orderLineItems;
                    if (this.orderLineItems.length === 0) {
                        this.selectedOrderEdit = OrderEditOptions.none;
                        this.snackbar.showInfo(
                            "No " +
                            OrderLineItemStatusLookup.find(
                                (lu) => lu.id === orderLineItemStatus
                            ).name +
                            " Order LineItems Found."
                        );
                    }
                    break;
                case OrderEditOptions.changeOrderDate:
                    this.orderSelectables = orders;
                    if (this.orderSelectables.length === 0) {
                        this.selectedOrderEdit = OrderEditOptions.none;
                        this.snackbar.showInfo(
                            "No" +
                            OrderStatusLookup.find(
                                (lu) => lu.id === orderStatus
                            ).name +
                            " Orders Found."
                        );
                    }

                default:
                    break;
            }
            setTimeout(() => {
                this.isGapSet = false;
            }, 1000);
        }
    }

    onRouteToProfile(): (event: MouseEvent, index: number) => void {
        return (event: MouseEvent, index: number) => {
            const customerId = this.gridData[index].data.customerId;
            void this.router.navigate(["/accounts", customerId, "profile"]);
        };
    }

    onOpenOrderDetails(): (event: MouseEvent, index: number) => void {
        return (event: MouseEvent, index: number) => {
            const data = this.gridData[index].data as OrderListGridViewmodel;
            this.receiptNumber = data.receiptNumber;
            data.csOrAdmin = this.isEnabledOrderEdit;

            this.orderDetailsOverlayRef = this.overlayService.open(
                OrderDetailsDialogComponent,
                data,
                false
            );

            this.orderDetailsOverlayRef.afterClosed$.subscribe((response) => {
                if (response?.data?.isConfirmed) {
                    this.snackbar.showInfo(
                        (response.data ?? 0) + " order(s) successfully cancelled."
                    );
                    this.getOrderBatch(0, true);
                }
            })
        };
    }

    onOpenedWholesalerSelect() {
        this.isSearchStale = true;
        this.selectedWholesalersHold = this.selectedWholesalers;
    }

    onClosedWholesalerSelect() {
        switch (this.selectedOrderEdit) {
            case OrderEditOptions.moveRejectedProductsToNewOrder:
                if (this.selectedWholesalers?.length !== 1) {
                    this.snackbar.showWarning(
                        OrderEditOptions.moveRejectedProductsToNewOrder +
                        " requires a Wholesaler to be selected."
                    );
                }
                this.selectedOrderEdit = OrderEditOptions.none;
                return;

            default:
                break;
        }

        const refiner = new Refiner();
        refiner.location = RefinerLocation.wholesalers;
        refiner.value = this.selectedWholesalers?.length < 2
            ? this.selectedWholesalers
                .map((wholesaler) => wholesaler.name)
                .join(", ")
            : this.selectedWholesalers?.length + " Selected";
        refiner.dataPropertyName = "name";
        refiner.dataValue = this.selectedWholesalers
            .map((wholesaler) => wholesaler.id)
            .join(valueSeparator);

        this.refinerService.checkAndUpdateRefiner(refiner, true);

        if (this.selectedWholesalersHold === this.selectedWholesalers) {
            this.isSearchStale = false;
        }
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    //multiSelect methods
    onItemSelect(item: any) {
        console.log(item);
        console.log(this.selectedWholesalers);
    }
    OnItemDeSelect(item: any) {
        console.log(item);
        console.log(this.selectedWholesalers);
    }
    onSelectAll(items: any) {
        console.log(items);
    }
    onDeSelectAll(items: any) {
        console.log(items);
    }


    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridhieghtOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridhieghtOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridhieghtOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridhieghtOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    checkSearchError(): void {
        if (this.dateForm) {
            if (
                this.dateForm
                    .get("endDate")
                    .hasError("dateLessThanStartDate") ||
                this.dateForm
                    .get("startDate")
                    .hasError("dateGreaterThanEndDate")
            ) {
                this.isSearchButtonDisabled = true;
            } else {
                this.isSearchButtonDisabled = false;
            }
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    compareWholesalers(a: Customer, b: Customer): boolean {
        return a && b && a.id === b.id;
    }

    lessThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const endDate = (control.value as Moment)
                    .startOf("day")
                    .valueOf();
                this.startMaxDate = control.value;

                if (this.dateForm.controls.startDate.value) {
                    const startDate = (this.dateForm.controls.startDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.startMaxDate = moment();
            }

            this.checkSearchError();

            return forbidden ? { dateLessThanStartDate: true } : null;
        };
    }

    async getOrderBatch(
        index: number,
        force?: boolean,
        waitForFiltersToPopulate = true
    ): Promise<Order[]> {
        if (!this.refinerService.areDefaultsSet) return;

        let rtn = new Array<Order>();
        const areRefinersTheSame = this.resolvePageIndex(index);

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.isSearchStale ||
            this.shouldResetGrid ||
            force
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            if (waitForFiltersToPopulate) {
                setTimeout(async () => {
                    rtn = await this.getOrders(force, rtn);
                }, 0);
            } else {
                rtn = await this.getOrders(force, rtn);
            }
            this.isSearchStale = false;
        }
        return rtn;
    }

    private async getOrders(force: boolean, rtn: Order[]) {
        const id = newSequentialId();

        const results = await this.getBatchApiCall(
            id,
            this.refinerService.refiners,
            force ? 0 : this.pageSize,
            this.pageIndex * this.pageSize,
            this.activeSorts
        );

        if (!results) {
            this.shouldResetGrid = true;
            this.shouldWait$.next(false);
            return;
        }

        this.total = results.count;
        this.isGapSet = false;

        let gridData: GridData[] = this.gridData.slice();
        if (this.pageIndex === 0) {
            gridData = new Array<GridData>();
        }
        const largestIndex = gridData.length;
        rtn = results?.values ?? [];
        for (const order of results?.values ?? []) {

            const gridItem: GridData = {
                data: new OrderListGridViewmodel(order),
                detailArrayName: "orderLineItems",
                isExpanded: this.grid?.areAllRowsExpanded ?? false,
                index: largestIndex + results.values.indexOf(order)
            };
            gridData.push(gridItem);
        }
        this.gridData = gridData;

        this.dataSource = new TableVirtualScrollDataSource(
            this.gridData
        );

        this.shouldResetGrid = false;

        return rtn;
    }

    isOrderDateInTheFuture(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const now = moment();
            if (
                Helper.roundMoment(control.value) <
                Helper.roundMoment(now, false)
            ) {
                forbidden = true;
            }

            return forbidden ? { NotInFuture: true } : null;
        };
    }

    greaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStart: Moment = control.value as Moment;

            if (momentStart) {
                const startDate = momentStart.startOf("day").valueOf();
                this.endMinDate = momentStart;

                if (this.dateForm.controls.endDate.value) {
                    const endDate = (this.dateForm.controls.endDate
                        .value as Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate > endDate) {
                        forbidden = true;
                    }
                }
            } else {
                this.endMinDate = null;
            }

            this.checkSearchError();

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        startDateInput: ElementRef,
        endDateInput: ElementRef,
        wholesalerSelect: AngularMultiSelect,
        orderEditSelect: MatSelect,
        orderEditOptionsComponent: OrderEditOptionsComponent
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.startDateInput = startDateInput;
        this.endDateInput = endDateInput;
        this.wholesalerSelect = wholesalerSelect;

        void this.setWholesalerOptions();

        this.orderEditSelect = orderEditSelect;
        this.orderEditOptionsComponent = orderEditOptionsComponent;

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        const printButtonComponent = new PrintButtonComponent();
        this.headerButtons.push(printButtonComponent);

        const excelExportButtonComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(excelExportButtonComponent);

        this.filters = [];

        this.filters.push({ filter: MySearchesFilterComponent });
        this.filters.push({ filter: CustomerLocationFilterComponent, zrtFilterService: this.zrtFilterService });
        this.filters.push({ filter: CallDateInformationFilterComponent });
        this.filters.push({ filter: OrderInformationFilterComponent });
        this.filters.push({ filter: CallAttributesFilterComponent });

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getOrderBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrtsHandleRefiners(this.refinerService).subscribe();
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getOrderBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    async save(): Promise<void> {
        let response: GenericResponseDto<Order> = null;

        const vms = this.orderEditOptionsComponent.viewmodel.dataSource.data
            .filter((gridData) => gridData.data.isSelected)
            .map((gridData) => gridData.data);
        const ids = vms.map((vm) => vm.id as string);

        if (ids?.length > 0) {
            if (
                this.selectedOrderEdit ===
                OrderEditOptions.moveRejectedProductsToNewOrder
            ) {
                response = await this.orderDelineationService.moveRejectedProductsToNewOrder(
                    ids,
                    this.orderDate
                );
                if (!response) { return; }
            }
            if (
                this.selectedOrderEdit ===
                OrderEditOptions.movePendingOrderProductsToNewOrder
            ) {
                response = await this.orderDelineationService.movePendingOrderProductsToNewOrder(
                    ids,
                    this.orderDate
                );
                if (!response) { return; }
            }

            let responses = new Array<GenericResponseDto<Order>>();
            if (this.selectedOrderEdit === OrderEditOptions.changeOrderDate) {

                for (const id of ids) {

                    const response = await this.orderDelineationService.changeOrderDate(
                        id,
                        this.orderDate
                    );
                    if (!response) { continue; }
                    responses.push(response);
                }
            }


            this.snackbar.showInfo(
                (response.affected ? response.affected : responses.length) + " records updated successfully!"
            );
            this.selectedOrderEdit = OrderEditOptions.none;
            this.getOrderBatch(0, true);

        } else {
            this.snackbar.showWarning("No Items selected to update.");
        }
    }

    search(shouldAddInputs: boolean = true) {
        if (shouldAddInputs) {
            this.onAddAccountRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
            this.onAddUinRefiner();
            this.onAddStartDateRefiner();
            this.onAddEndDateRefiner();
            this.onClosedWholesalerSelect();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getOrderBatch(this.pageIndex);
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityComunicator = event;
    }

    async setActivitiesFilters(): Promise<void> {
        const projectResponse = await this.projectDelineationService.getDropDown();
        if (!projectResponse) {
            this.shouldWait$.next(false);
            return;
        }
        const projects = projectResponse.values;
        if (projects?.length) {
            projects.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.activitiesFilterService.projects = projects ?? new Array<GenericDropDownDto>();

        const response = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (response && response.values) {
            this.activitiesFilterService.chains = response.values.sort((a, b) => a.name.localeCompare(b.name));
        }
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            this.dateForm.controls.startDate.setValue(this.startDateDefault);
            this.onAddStartDateRefiner();

            this.dateForm.controls.endDate.setValue(this.endDateDefault);
            this.onAddEndDateRefiner();

            const refiner = this.zrtFilterService.createDefaultZrtRefiner(this.employee, false);
            const isCorporateUser = Helper.isEmployeeCustomerServiceOrAdmin(this.employee);
            if (refiner) {
                this.refinerService.checkAndUpdateRefiner(refiner, true);
                if (isCorporateUser) {
                    this.zrtFilterService.defaultZrtSelection = this.zrtFilterService.selectedZrts;
                }
            }
            this.refinerService.isCorporateUser = isCorporateUser;
            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        if (Helper.isEmployeeAboveTmRoleAndAllowedToRemoveZrtFilter(this.employee)) {
            this.zrtFilterService.areas = await this.filterService.getAreas();
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts();
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        } else {
            const zrtsResponse = await this.employeeDelineationService.getEmployeeZrts(this.employee.searchableZrt ?? undefined);
            if (zrtsResponse && zrtsResponse?.values) {
                this.zrtFilterService.employeeZrts = zrtsResponse.values;
            }
        }

        this.setDefaultFilter();

        void this.setActivitiesFilters();
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.dateForm.controls.startDate.setValue(null);
        this.dateForm.controls.endDate.setValue(null);

        this.accountInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;
        this.uinInput = null;

        this.dateForm.controls.orderDate.setValue(this.defaultOrderDate);
        this.selectedWholesalers = [];
        this.selectedOrderEdit = OrderEditOptions.none;

        this.populateInputsFromRefiners();
    }

    setWholesalerFilterInput(): void {
        const refiner = this.refinerService.getRefinerByLocation(RefinerLocation.wholesalers);
        if (refiner) {
            this.setInputFromRefiner(refiner);
        }
    }

    async setWholesalerOptions(): Promise<void> {
        if (!this.activitiesFilterService.wholesalers?.length) {
            const types = Helper.getStoreTypesByCustomerGenericType(
                CustomerGenericTypes.wholesale
            );

            const response = await this.customerDelineationService.getByCustomerTypes(types, true);
            if (!response) {
                this.shouldWait$.next(false);
                return;
            }

            this.wholesalers = response.values;
            if (this.wholesalers?.length) {
                this.wholesalers.sort((a, b) => a.name.localeCompare(b.name));
            }
            this.activitiesFilterService.wholesalers = this.wholesalers ?? new Array<Customer>();
        } else {
            this.wholesalers = this.activitiesFilterService.wholesalers;
        }

        if (!this.wholesalers.length) {
            this.wholesalerSelect.settings.noDataLabel = "No wholesalers found.";
        }

        this.setWholesalerFilterInput();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }

    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }

        if (
            this.orderDateSubscription &&
            !this.orderDateSubscription.closed
        ) {
            this.orderDateSubscription.unsubscribe();
        }
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }
        if (this.zrtSelectionSubscription &&
            !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }
        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    validateOrderDate(): void {
        const now = moment();
        if (!Helper.isValidMomentDate(this.orderDate, this.dateFormat)) {
            this.dateForm.controls.orderDate.setValue(this.defaultOrderDate);
        }
        if (
            Helper.roundMoment(this.orderDate) < Helper.roundMoment(now, false)
        ) {
            this.dateForm.controls.orderDate.setValue(this.defaultOrderDate);
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbar.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.orderDelineationService.getBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.zrtByArea:
            case RefinerLocation.zrtByEmployee:
                this.zrtFilterService.applyRefiner(refiner);
                break;
            case RefinerLocation.account:
                this.accountInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
                break;
            case RefinerLocation.uin:
                this.uinInput = refiner.value;
                break;
            case RefinerLocation.orderOnOrAfterDate:
                const startDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.startDate.setValue(
                    startDate
                );
                break;
            case RefinerLocation.orderOnOrBeforeDate:
                const endDate = refiner.value
                    ? moment(new Date(refiner.value))
                    : null;
                this.dateForm.controls.endDate.setValue(
                    endDate
                );
                break;
            case RefinerLocation.wholesalers: {
                const selectedWholesalerIds = refiner.dataValue?.split(valueSeparator);
                const rtnSelectedWholesalers = new Array<Customer>();

                if (selectedWholesalerIds) {
                    for (const wholesalerId of selectedWholesalerIds) {
                        const found = this.wholesalers.find(
                            (ws) => ws.id === wholesalerId
                        );
                        if (found) {
                            rtnSelectedWholesalers.push(found);
                        }
                    }
                }
                this.selectedWholesalers = rtnSelectedWholesalers;
                break;
            }
            default:
                break;
        }
    }
}

<ng-container *ngIf="viewmodel" class="d-flex flex-column flex-grow-1">
    <ng-container>
        <div class="row">
            <div class="col-12">
                <app-page-header #header [name]="viewmodel.headerName"
                                 [entryCount]="viewmodel.total ? viewmodel.total : 0"
                                 [buttons]="viewmodel.headerButtons"
                                 [refinerService]="viewmodel.refinerService"
                                 [subAvailableColumns]="viewmodel.availableColumns"
                                 (onClickButton)="viewmodel.onButtonClickEvent($event)"
                                 (onButtonAction)="viewmodel.onButtonAction($event)"
                                 (headerExpansionChanged)="viewmodel.headerExpansionChanged()"
                                 (onColumnVisabilityMenuCloseEvent)="viewmodel.onSetColumnVisibilityCommunicator($event)">
                </app-page-header>
            </div>
        </div>
        <div class="d-flex p-2 justify-content-center align-items-center">
            <div>
                <button class="btn btn-secondary mr-2" data-toggle="tooltip" data-placement="bottom"
                        title="View Filters"
                        (click)="viewmodel.showFilters()">
                <span class="white-space-nowrap">
                    <span class="material-icons">filter_alt</span>
                    Filters •
                    {{ viewmodel.refinerService.refiners.length }}
                </span>
                </button>
            </div>
            <div class="flex-grow-1">
                <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                    <mat-label>Start on or After</mat-label>
                    <input #startDateInput matInput formControlName="startDate" [max]="viewmodel.startMaxDate"
                           [matDatepicker]="afterPicker" (keyup)="viewmodel.onAddStartDateRefiner($event)"
                           (dateChange)="viewmodel.onAddStartDateRefiner()"
                           (blur)="viewmodel.onAddStartDateRefiner($event)"/>
                    <mat-datepicker touchUi #afterPicker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="afterPicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                    Date format invalid.
                </mat-error>
                <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('startDate')
                        .hasError('dateGreaterThanEndDate')
                ">
                    Start date can not be greater than End date.
                </mat-error>
            </div>
            <div class="flex-grow-1">
                <mat-form-field appearance="outline" color="accent" [formGroup]="viewmodel.dateForm">
                    <mat-label>Start on or Before</mat-label>
                    <input #endDateInput matInput formControlName="endDate" [min]="viewmodel.endMinDate"
                           [max]="viewmodel.endMaxDate" [matDatepicker]="beforePicker"
                           (dateChange)="viewmodel.onAddEndDateRefiner()"
                           (keyup)="viewmodel.onAddEndDateRefiner($event)"
                           (blur)="viewmodel.onAddEndDateRefiner($event)"/>
                    <mat-datepicker touchUi #beforePicker></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="beforePicker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-error *ngIf="
                    viewmodel.dateForm.invalid
                ">
                    Date format invalid.
                </mat-error>
                <mat-error *ngIf="
                    viewmodel.dateForm
                        .get('endDate')
                        .hasError('dateLessThanStartDate')
                ">
                    End date can not be less than Start date.
                </mat-error>
            </div>
            <div class="flex-grow-1">
                <div class="mat-form-field">
                    <app-zrt-tree-view #zrtTree></app-zrt-tree-view>
                </div>
            </div>
            <div class="d-flex">
                <button appThrottleClick mat-raised-button (throttledClick)="viewmodel.search()"
                        [disabled]="viewmodel.isSearchButtonDisabled" class="mr-2" color="primary">SEARCH
                </button>
                <button mat-raised-button (click)="viewmodel.reset()" class="btn btn-secondary">RESET</button>
            </div>
        </div>
    </ng-container>
    <mat-sidenav-container class="sidenav-container d-flex flex-column flex-grow-1" autosize>
        <mat-sidenav #drawer [mode]="viewmodel.drawerMode" [fixedInViewport]="true"
                     [fixedTopGap]="viewmodel.topGapDistance" [fixedBottomGap]="0" class="sidenav">
            <app-filter-container [refinerService]="viewmodel.refinerService" [filters]="viewmodel.filters"
                                  [isSearchDisabled]="viewmodel.isSearchButtonDisabled"
                                  [filterLocation]="viewmodel.filterLocation"
                                  (search)="viewmodel.search()" (reset)="viewmodel.reset()">
            </app-filter-container>
        </mat-sidenav>
        <mat-sidenav-content #sideNavContent class="d-flex flex-column flex-grow-1">
            <div class="sidenav-content d-flex flex-column flex-grow-1">
                <ng-container>
                    <div class="table-responsive d-flex flex-column flex-grow-1">
                        <app-grid #grid [(dataSource)]="viewmodel.dataSource"
                                    [columnDefinitions]="viewmodel.columnDef"
                                    [searchRefiners]="viewmodel.activeRefiners"
                                    [isFixedLayout]="viewmodel.isFixedLayout" [height]="viewmodel.gridHeight"
                                    [isEnabledExpandedDetails]="viewmodel.isEnabledExpandedDetails"
                                    [itemsRendedInViewPort]="viewmodel.itemsRendedInViewPort"
                                    [selectedColumnVisability]="viewmodel.selectedColumnVisibilityCommunicator"
                                    [detailHeight]="viewmodel.detailHeight" [sortFunction]="viewmodel.sortFunction"
                                    (getDataBatch)="viewmodel.getBatch($event)"
                                    class="d-flex flex-column flex-grow-1">
                        </app-grid>
                    </div>
                </ng-container>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { ChangeOrderDateComponent } from './change-order-date/change-order-date.component';
import { ChangeWholesalerUinComponent } from './change-wholesaler-uin/change-wholesaler-uin.component';
import { MoveOrderProductsComponent } from './move-order-products/move-order-products.component';
import { OrderEditOptionsComponent } from './order-edit-options/order-edit-options.component';
import { OrderExtractionComponent } from './order-extraction/order-extraction.component';
import { OrderListZrtFilterService } from './orders-list/order-list-zrt-filter.service';
import { OrdersListComponent } from './orders-list/orders-list.component';
import { DirectOrderDetailsComponent } from './direct-order-details/direct-order-details.component';

@NgModule({
  declarations: [
    OrderExtractionComponent,
    OrderEditOptionsComponent,
    MoveOrderProductsComponent,
    ChangeWholesalerUinComponent,
    ChangeOrderDateComponent,
    OrdersListComponent,
    DirectOrderDetailsComponent,
  ],
  imports: [
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    SharedModule,
    CommonModule,
  ],
  providers: [
    OrderListZrtFilterService,
  ],
  exports: [
    OrderExtractionComponent,
    OrderEditOptionsComponent,
    MoveOrderProductsComponent,
    ChangeWholesalerUinComponent,
    ChangeOrderDateComponent,
    OrdersListComponent,
  ],
})
export class OrdersModule { }

import { Component, ElementRef, QueryList, ViewChildren, signal } from "@angular/core";
import { WholesalerBaseComponent } from "../wholesaler-format-base/wholesaler-base.component";
import { Subsidiary } from "shield.shared";

@Component({
    selector: "app-swisher-wholesaler-format",
    templateUrl: "./swisher-wholesaler-format.component.html",
    styleUrls: ["./swisher-wholesaler-format.component.scss"]
})
export class SwisherWholesalerFormatComponent extends WholesalerBaseComponent {
    @ViewChildren("originalSwisherWholesaleReceipt") originalSwisherWholesaleReceipt: QueryList<ElementRef<HTMLElement>>;

    printLogo = "/assets/img/SwisherPrintLogo.jpg";
    subsidiary = signal(Subsidiary.Swisher);
}

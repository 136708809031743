import { MatDrawerMode, MatSidenav, MatSidenavContent } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { faCopy, faEdit } from "@fortawesome/free-solid-svg-icons";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, skip, skipWhile } from "rxjs/operators";
import {
    approvedProjectRoles,
    EmployeeRoleType,
    FilterSortDto,
    newSequentialId,
    ProjectListColumns,
    ProjectStatuses,
    ProjectStatusLookup,
    ProjectTypeLookup,
    RefinerLocation,
    SharedHelper,
    SortDirection,
    StagingProjectStatus,
    valueSeparator,
    VisibleProjectStatuses
} from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Project } from "src/app/entity-models/project.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ButtonActions } from "src/app/shared/enums/button-actions.enum";
import { GridDataTypes } from "src/app/shared/enums/grid-data-types.enum";
import { GridButton } from "src/app/shared/grid/grid.button";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { AddButtonComponent } from "src/app/shared/page-header/buttons/add-button/add-button.component";
import { ColumnVisabilityButtonComponent } from "src/app/shared/page-header/buttons/column-visability-button/column-visability-button.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { PrintButtonComponent } from "src/app/shared/page-header/buttons/print-button/print-button.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { CopyProjectModalComponent } from "../copy-project-modal/copy-project-modal.component";
import { CopyProjectViewmodel } from "../copy-project-modal/copy-project.viewmodel";
import { NewProjectModalComponent } from "../new-project-modal/new-project-modal.component";
import { NewProjectViewmodel } from "../new-project-modal/new-project.viewmodel";
import { ProjectGridViewModel } from "./project-grid.viewmodel";
import { ProjectFilterService } from "./project-filter.service";
import { ProjectListRefinerService } from "./project-list-refiner.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { EmployeeDelineationService } from "src/app/services/delineation-services/employee-delineation.service";
import { AccountOwnershipDelineationService } from "src/app/services/delineation-services/account-ownership-delineation.service";
import { SearchZrtDropDown } from "src/app/entity-models/search-zrt-dropdown.entity";
import { MatSortHeader } from "@angular/material/sort";
import { ProjectListZrtFilterService } from "./project-list-zrt-filter.service";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { MyListCommands } from "src/app/enums/my-list-commands";
import { FilterService } from "src/app/services/filter.service";

export class ProjectListViewmodel {

    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<ProjectListColumns>[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    cityInput: string;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    copyProjectOverlayRef: SwisherOverlayRef<
        CopyProjectViewmodel,
        CopyProjectModalComponent
    >;
    confirmationOverlayRef: SwisherOverlayRef<ConfirmationDialogViewmodel, ConfirmationDialogComponent>;
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    detailHeight = 48;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    employee: Employee;
    employeeDelineationService: EmployeeDelineationService;
    employeeSubscription: Subscription;
    filterDataLoaded = false;
    filterLocation = FilterLocation.projectList;
    filters: FilterAndParams[] = [];
    filterService: FilterService;
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridheightOffsetDeduction = 0;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Project List";
    isEnabledExpandedDetails = false;
    isFixedLayout = false;
    isGapSet = false;
    isSearchButtonDisabled = false;
    isSearchStale = false;
    itemsRendedInViewPort = 13;
    managerInput: string;
    myListSubscription: Subscription;
    newProjectOverlayRef: SwisherOverlayRef<
        NewProjectViewmodel,
        NewProjectModalComponent
    >;
    nameInput: string;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50;
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string>;
    projectDelineationService: ProjectDelineationService;
    projectEmployeeIds = new Array<string>();
    projectFilterService: ProjectFilterService;
    projectStateService: ProjectStateService;
    refinerInputChangeSubscription: Subscription;
    refinerService: ProjectListRefinerService;
    refinerServiceSubscription: Subscription;
    router: Router;
    screenHeight: number = 0;
    selectedColumnVisibilityCommunicator: string[];
    shouldResetGrid = false;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    snackbarService: SnackbarService;
    streetInput: string;
    topGapDistance = 0;
    total = 0;
    customerDelineationService: CustomerDelineationService;
    accountOwnershipDelineationService: AccountOwnershipDelineationService
    zipInput: string;
    zrtFilterService: ProjectListZrtFilterService;
    zrtSelectionSubscription: Subscription;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<ProjectListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<ProjectListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as ProjectListColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;
            void this.getProjectBatch(0, true);
        }
    };

    constructor(
        refinerService: ProjectListRefinerService,
        router: Router,
        overlayService: OverlayService,
        projectStateService: ProjectStateService,
        projectDelineationService: ProjectDelineationService,
        pleaseWaitService: PleaseWaitService,
        snackbarService: SnackbarService,
        projectFilterService: ProjectFilterService,
        employeeDelineationService: EmployeeDelineationService,
        customerDelineationService: CustomerDelineationService,
        accountOwnershipDelineationService: AccountOwnershipDelineationService,
        zrtFilterService: ProjectListZrtFilterService,
        filterService: FilterService
    ) {
        this.refinerService = refinerService;
        this.router = router;
        this.overlayService = overlayService;
        this.projectStateService = projectStateService;
        this.projectDelineationService = projectDelineationService;
        this.pleaseWaitService = pleaseWaitService;
        this.snackbarService = snackbarService;
        this.projectFilterService = projectFilterService;
        this.employeeDelineationService = employeeDelineationService;
        this.customerDelineationService = customerDelineationService;
        this.accountOwnershipDelineationService = accountOwnershipDelineationService;
        this.zrtFilterService = zrtFilterService;
        this.filterService = filterService;
    }

    //events
    onButtonAction(value: any): void {
        switch (value) {
            case ButtonActions.add:
                this.onOpenNewProjectModal();
                break;
            case ButtonActions.print:
                this.grid.onPrint();
                break;
            case ButtonActions.exportToExcel:
                this.grid.onExportAsExcel();
                break;
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onAddManagerRefiner(event?: KeyboardEvent): void {
        if (this.managerInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.manager)?.value !== this.managerInput) {
            this.refinerService.onInputChange(
                RefinerLocation.manager,
                this.managerInput
            );
        }

        if (this.managerInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.manager,
                this.managerInput,
                "createdBy"
            );
        }

        if (!this.managerInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.manager,
                true,
                false
            )
        }
    }

    onAddNameRefiner(event?: KeyboardEvent): void {
        if (this.nameInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.name)?.value !== this.nameInput) {
            this.refinerService.onInputChange(
                RefinerLocation.name,
                this.nameInput
            );
        }

        if (this.nameInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.name,
                this.nameInput,
                "name"
            );
        }

        if (!this.nameInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.name,
                true,
                false
            )
        }
    }

    onAddStreetRefiner(event?: KeyboardEvent): void {
        if (this.streetInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.street)?.value !== this.streetInput) {
            this.refinerService.onInputChange(
                RefinerLocation.street,
                this.streetInput
            );
        }

        if (this.streetInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.street,
                this.streetInput,
                "address1"
            );
        }

        if (!this.streetInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.street,
                true,
                false
            )
        }
    }

    onAddCityRefiner(event?: KeyboardEvent): void {
        if (this.cityInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.city)?.value !== this.cityInput) {
            this.refinerService.onInputChange(
                RefinerLocation.city,
                this.cityInput
            );
        }

        if (this.cityInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.city,
                this.cityInput,
                "city"
            );
        }

        if (!this.cityInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.city,
                true,
                false
            )
        }
    }

    onAddZipRefiner(event?: KeyboardEvent): void {
        if (this.zipInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.zipCodes)?.value !== this.zipInput) {
            this.refinerService.onInputChange(
                RefinerLocation.zipCodes,
                this.zipInput
            );
        }

        if (this.zipInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.zipCodes,
                this.zipInput,
                "zip"
            );
        }

        if (!this.zipInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.zipCodes,
                true,
                false
            )
        }
    }

    async onEditProject(index: number): Promise<void> {
        const projectId = this.gridData[index].data.id;
        const projectCheckResponse = await this.projectDelineationService.checkStagingProjectStatus(projectId);

        if (!projectCheckResponse || !projectCheckResponse.values) return;

        switch (projectCheckResponse.values.status) {
            case StagingProjectStatus.IsBeingEdited:
                this.openStagingUserModal(
                    projectCheckResponse.values.displayName
                );
                break;
            case StagingProjectStatus.ExistingProjectFound:
                this.openExistingStagingProjectModal(
                    projectId,
                    projectCheckResponse.values.id,
                    projectCheckResponse.values.displayName
                );
                break;
            case StagingProjectStatus.OK:
                void this.router.navigate(["/details/project", projectId, "configuration"]);
                break;
            default:
                break;
        }
    }

    onCopyProject(index: number): void {
        this.openCopyProjectModal(this.gridData[index].data as Project);
    }

    onRefinersChange() {
        this.setRefinerInput();
        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }

        // Search on removal
        const shouldAddInputs = false;
        this.search(shouldAddInputs);
    }

    onViewProject(): (event: MouseEvent, index: number) => void {
        return async (event: MouseEvent, index: number) => {
            void this.router.navigate(["/details/project", this.gridData[index].data.id, "summary"]);

        }
    }

    //public methods
    buildNewProjectFromProject(project: Project): Project {
        const rtn = new Project();

        rtn.id = newSequentialId();
        rtn.name = "";
        rtn.mission = "";
        rtn.projectTypeId = null;

        rtn.projectTypeId = project.projectTypeId;
        rtn.startDate = project.startDate;
        rtn.visibleDate = project.visibleDate;
        rtn.endDate = project.endDate;
        rtn.shareSiteLink = project.shareSiteLink;
        rtn.wholesalerGroupId = project.wholesalerGroupId;
        rtn.areStoresVisibleToAll = project.areStoresVisibleToAll;

        rtn.projectActivitySurveys = project.projectActivitySurveys;
        rtn.projectActivitySurveys.forEach((as) => { as.id = newSequentialId(); as.projectId = rtn.id; return as });

        rtn.projectCustomers = project.projectCustomers;
        rtn.projectCustomers.forEach((c) => { c.id = newSequentialId(); c.projectId = rtn.id; return c });

        rtn.projectEmployees = project.projectEmployees;
        rtn.projectEmployees.forEach((e) => { e.id = newSequentialId(); e.projectId = rtn.id; return e });

        rtn.projectOrderDates = project.projectOrderDates;
        rtn.projectOrderDates.forEach((od) => { od.id = newSequentialId(); od.projectId = rtn.id; return od });

        rtn.projectProducts = project.projectProducts;
        rtn.projectProducts.forEach((p) => { p.id = newSequentialId(); p.projectId = rtn.id; return p });

        return rtn;
    }
    buildProjectGridViewModelFromProject(project: Project): ProjectGridViewModel {
        return {
            ...project,
            storeCount: project.storeCount ?? project.projectCustomers?.length ?? 0,
            projectType: ProjectTypeLookup.find(
                (lookup) => lookup.id === project.projectTypeId
            ).description,
            projectStatus: this.getVisibleProjectStatus(project.projectStatusId)
        };
    }

    getVisibleProjectStatus(id: number) : string {
        const status = VisibleProjectStatuses.find(s => s === id) || ProjectStatuses.Editable;

        return ProjectStatusLookup.find(s => s.id === status).description;
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridheightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridheightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridheightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridheightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
    }

    async getProjectBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        if (!this.refinerService.areDefaultsSet) return;

        const areRefinersTheSame = this.resolvePageIndex(index);
        this.isSearchStale = false;

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            this.shouldResetGrid ||
            force
        ) {
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.projectDelineationService.getProjectBatch(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (!results) {
                    this.shouldResetGrid = true;
                    this.shouldWait$.next(true);
                    return;
                }

                this.total = results.count;
                this.isGapSet = false;

                let gridData: GridData[] = this.gridData.slice();
                if (this.pageIndex === 0) {
                    gridData = new Array<GridData>();
                }
                const largestIndex = gridData.length;

                for (const project of results?.values ?? []) {
                    const gridItem: GridData = {
                        data: this.buildProjectGridViewModelFromProject(project),
                        detailArrayName: null,
                        isExpanded: false,
                        index: largestIndex + results.values.indexOf(project)
                    };
                    gridData.push(gridItem);
                }
                this.gridData = gridData;

                this.dataSource = new TableVirtualScrollDataSource(
                    this.gridData
                );
                this.shouldResetGrid = false;
                this.shouldWait$.next(false);
            }, 0);
        }
    }

    async getProjectEmployeeOptions(employee: Employee): Promise<void> {
        if (!employee) return;

        await this.setFilterData();
        this.getProjectBatch(0);
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    canCopy(index: number): boolean {
        return !!this.employee && this.gridData[index].data.projectStatusId !== ProjectStatuses.Canceled;
    }

    canEdit(index: number): boolean {
        return !!this.employee
            && this.gridData[index].data.projectStatusId !== ProjectStatuses.Canceled
            && this.gridData[index].data.projectStatusId !== ProjectStatuses.Completed;
    }

    initialize(
        drawer: MatSidenav,
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent
    ): void {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.drawer = drawer;
        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;

        const canEditOrCreate = this.employee && (this.employee.employeeRoles ?? [])
            .some((er) => approvedProjectRoles.includes(er.employeeRoleType.id));

        const addProjectComponent = new AddButtonComponent();
        addProjectComponent.title = "Add Project"

        if (canEditOrCreate) {
            this.headerButtons.push(addProjectComponent);
        }

        const columnVisabilityComponent = new ColumnVisabilityButtonComponent();
        this.headerButtons.push(columnVisabilityComponent);

        const printComponent = new PrintButtonComponent();
        this.headerButtons.push(printComponent);

        const exportComponent = new ExcelExportButtonComponent();
        this.headerButtons.push(exportComponent);

        const buttons = new Array<GridButton>();

        const editButton = new GridButton;
        editButton.icon = faEdit;
        editButton.title = "Edit";
        editButton.isDisabled = !canEditOrCreate;
        editButton.onClick = (index: number) => this.onEditProject(index);
        editButton.isVisible = (index: number) => this.canEdit(index);
        buttons.push(editButton);

        const copyButton = new GridButton;
        copyButton.icon = faCopy;
        copyButton.title = "Copy";
        copyButton.isDisabled = !canEditOrCreate;
        copyButton.isVisible = (index: number) => this.canCopy(index);
        copyButton.onClick = (index: number) => this.onCopyProject(index);
        buttons.push(copyButton);

        void this.setActivitiesFilter();

        this.columnDef = [
            {
                headerName: ProjectListColumns.startDate,
                dataPropertyName: "startDate",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.date
            },
            {
                headerName: ProjectListColumns.endDate,
                dataPropertyName: "endDate",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.date
            },
            {
                headerName: ProjectListColumns.projectName,
                dataPropertyName: "name",
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onViewProject()
            },
            {
                headerName: ProjectListColumns.projectType,
                dataPropertyName: "projectType",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: ProjectListColumns.mission,
                dataPropertyName: "mission",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: ProjectListColumns.visibleDate,
                dataPropertyName: "visibleDate",
                isAvailable: true,
                isSelected: false,
                dataType: GridDataTypes.date
            },
            {
                headerName: ProjectListColumns.storeCount,
                dataPropertyName: "storeCount",
                isAvailable: true,
                isSelected: true,
                dataType: GridDataTypes.number
            },
            {
                headerName: ProjectListColumns.status,
                dataPropertyName: "projectStatus",
                isAvailable: true,
                isSelected: true,
            }
        ];

        if (canEditOrCreate) {
            const buttonColumnDef = new ColumnDef()
            buttonColumnDef.headerName = "";
            buttonColumnDef.dataPropertyName = "buttonData";
            buttonColumnDef.isAvailable = true;
            buttonColumnDef.isSelected = true;
            buttonColumnDef.dataType = GridDataTypes.buttons;
            buttonColumnDef.buttons = buttons;
            this.columnDef.push(buttonColumnDef);
        }

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.pipe(
                skipWhile(() => !this.filterDataLoaded && !this.refinerService.areDefaultsSet)
            ).subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);
                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.search(true);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(
                (dc) => {
                    if (dc) {
                        this.pleaseWaitService.killPleaseWait();
                        this.shouldWait$.next(true);
                    }
                }
            );
        }

        if (
            !this.zrtSelectionSubscription ||
            this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription = this.zrtFilterService.observableSelectedZrts.subscribe((selectedZrts) => {
                const refiner = new Refiner();

                refiner.location = RefinerLocation.assigned;
                refiner.value = selectedZrts.length > 1
                    ? `${selectedZrts.length} Selected`
                    : selectedZrts
                        .map((vm) => vm.displayValue)
                        .join(", ");
                refiner.dataPropertyName = "employeeId";
                refiner.dataValue = selectedZrts
                    .map((vm) => vm.id)
                    .join(valueSeparator);

                this.refinerService.checkAndUpdateRefiner(refiner, true);
            });
        }

        if (!this.myListSubscription ||
            this.myListSubscription.closed
        ) {
            this.myListSubscription = this.filterService.myListCommandObservable.subscribe(async (command) => {
                switch (command) {
                    case MyListCommands.toggleOn:
                    case MyListCommands.toggleOff:
                        this.getProjectBatch(0, true);
                        break;
                    case MyListCommands.push:
                        this.getMyListIds();
                        break;
                    default:
                        break;

                }
            });
        }
    }

    openCopyProjectModal(project: Project): void {
        const data = new CopyProjectViewmodel(project);
        data.width = "30vw";
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.copyProjectOverlayRef.close(data);
        }

        this.copyProjectOverlayRef = this.overlayService.open(CopyProjectModalComponent, data, true);

        this.copyProjectOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref?.data?.isConfirmed) {
                let project = this.buildNewProjectFromProject(ref.data.project);
                project.projectStatusId = ProjectStatuses.InActive;
                project.name = ref.data.projectName;
                project.mission = ref.data.projectMission;

                const response = await this.projectDelineationService.upsertProject(project);

                if (response.isError) {
                    this.snackbarService.showError(response.message);
                    return;
                }

                void this.router.navigate([`/details/project/${project.id}`]);
            }
        })

    }

    async onOpenNewProjectModal(): Promise<void> {
        const projectCheckResponse = await this.projectDelineationService.checkStagingProjectStatus();

        if (!projectCheckResponse || !projectCheckResponse.values) return;

        switch (projectCheckResponse.values.status) {
            case StagingProjectStatus.ExistingProjectFound:
                this.openExistingStagingProjectModal(
                    null,
                    projectCheckResponse.values.id,
                    projectCheckResponse.values.displayName
                );
                break;
            case StagingProjectStatus.OK:
                void this.openNewProjectModal();
                break;
            default:
                break;
        }
    }

    openNewProjectModal(): void {
        const data = new NewProjectViewmodel();
        data.buttonRightFunction = () => {
            data.isConfirmed = true;
            this.newProjectOverlayRef.close(data);
        }

        this.newProjectOverlayRef = this.overlayService.open(NewProjectModalComponent, data, true);

        this.newProjectOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref?.data?.isConfirmed) {
                const project = new Project();
                project.id = newSequentialId();
                project.projectTypeId = ref.data.selectedProjectType;
                project.projectStatusId = ProjectStatuses.Staging;
                project.name = "";
                project.areStoresVisibleToAll = false;

                await this.projectDelineationService.upsertProject(project);

                void this.router.navigate(["/details/project", project.id, "configuration"]);
            }
        })
    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search(shouldAddInputs: boolean = true): void {
        if (shouldAddInputs) {
            this.onAddNameRefiner();
            this.onAddManagerRefiner();
            this.onAddStreetRefiner();
            this.onAddCityRefiner();
            this.onAddZipRefiner();
        }
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getProjectBatch(this.pageIndex);
    }

    async setActivitiesFilter(): Promise<void> {
        this.projectFilterService.useOwnerCode = false;

        const wholesalersResponse = await this.customerDelineationService.getWholesalersWithGroupProducts();
        if (!wholesalersResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const wholesalers = wholesalersResponse.values;
        if (wholesalers?.length) {
            wholesalers.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.projectFilterService.wholesalers = wholesalers ? wholesalers : new Array<Customer>();

        const accountOwnershipResponse = await this.accountOwnershipDelineationService.getAllInDropDown();
        if (!accountOwnershipResponse || !accountOwnershipResponse.values) {
            this.shouldWait$.next(false);
            return;
        }
        this.projectFilterService.chains = accountOwnershipResponse.values.sort((a, b) => a.name.localeCompare(b.name));
    }

    setDefaultFilter(): void {
        if (!(this.zrtFilterService.employeeZrts?.length) || !this.employee) {
            return;
        }

        if (!this.refinerService.areDefaultsSet) {
            if (this.employee.employeeRoles.some((er) => approvedProjectRoles.includes(er.employeeRoleType.id))) {
                this.zrtFilterService.selectedZrts = [];

                this.managerInput = this.employee.fullName;
                const refiner = new Refiner();
                refiner.location = RefinerLocation.manager;
                refiner.dataValue = refiner.value = this.managerInput;
                this.refinerService.addRefiners(refiner);
            } else {
                const selected = ProjectListZrtFilterService.findNodeById(this.zrtFilterService.zrts, this.employee.id);
                if (selected) {
                    this.zrtFilterService.selectedZrts = ProjectListZrtFilterService.addSelectedZrts(new Array<SearchZrtDropDown>(), selected);
                }
            }
            const refiner = new Refiner();
            const statuses = ProjectStatusLookup.filter(
                (t) => t.id !== ProjectStatuses.Completed
                    && t.id !== ProjectStatuses.Canceled
                    && t.id !== ProjectStatuses.Staging
            );
            refiner.location = RefinerLocation.projectStatus;
            refiner.value = statuses.map((t) => t.description).join(", ");
            refiner.dataValue = statuses.map((t) => t.id).join(valueSeparator);
            this.refinerService.addRefiners(refiner);

            this.refinerService.areDefaultsSet = true;
        }
        this.filterDataLoaded = true;
    }

    async setFilterData(): Promise<void> {
        this.zrtFilterService.byArea = false;
        const zrts = await this.employeeDelineationService.getEmployeeZrts();
        this.zrtFilterService.employeeZrts = zrts?.values ?? new Array<SearchZrtDropDown>();
        this.setDefaultFilter();
    }

    setColumnVisibilityCommunicator(event: string[]) {
        this.selectedColumnVisibilityCommunicator = event;
    }

    setRefinerInput(): void {
        if (!this.filterDataLoaded) return;

        this.setDefaultFilter();

        if (!this.refinerService.refiners.filter(v =>
            v.location == RefinerLocation.zrtByArea ||
            v.location == RefinerLocation.zrtByEmployee
        ).length
        ) {
            this.zrtFilterService.selectedZrts = [];
        }

        this.nameInput = null;
        this.managerInput = null;
        this.streetInput = null;
        this.cityInput = null;
        this.zipInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.employeeSubscription &&
            !this.employeeSubscription.closed
        ) {
            this.employeeSubscription.unsubscribe();
        }

        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }

        if (
            this.zrtSelectionSubscription && !this.zrtSelectionSubscription.closed
        ) {
            this.zrtSelectionSubscription.unsubscribe();
        }

        if (
            this.myListSubscription &&
            !this.myListSubscription.closed
        ) {
            this.myListSubscription.unsubscribe();
        }
    }

    private getMyListIds(): void {
        setTimeout(async () => {
            if (this.total > 10000) {
                this.snackbarService.showWarning("Total entry count is too high. Cannot push more than 10,000 records at once.");
                this.filterService.myListEntries$.next(undefined);
            } else {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                data.header = "Confirmation";
                data.message =
                    "Would you like to push all entries to My List?";
                data.buttonLeftText = "Cancel";
                data.buttonLeftFunction = () => {
                    this.confirmationOverlayRef.close(data);
                };
                data.buttonRightText = "Yes";
                data.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    this.confirmationOverlayRef.close(data);
                };

                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    data
                );

                this.confirmationOverlayRef.afterClosed$
                    .pipe(
                        map(async (event) => {
                            if (event && event.data && event.data.isConfirmed) {
                                this.filterService.myListEntries$.next(await this.getIdsForMyList());
                                this.shouldWait$.next(true);
                            } else {
                                this.filterService.myListEntries$.next(undefined);
                                this.shouldWait$.next(true);
                            }
                        })
                    )
                    .subscribe();
            }
        }, 0);
    }

    private async getIdsForMyList(): Promise<string[]> {
        let rtn = this.dataSource.filteredData.map((gd) => gd.data.id);

        if (this.employeeDelineationService.getOnlineState() && rtn.length < this.total) {
            const gridIdResponse = await this.projectDelineationService.getProjectBatch(
                newSequentialId(),
                this.activeRefiners,
                10000,
                this.pageIndex * this.pageSize,
                this.activeSorts

            );
            if (gridIdResponse?.values) {
                const gridIds = gridIdResponse.values.map(v => v.id);
                rtn = rtn.concat(gridIds.filter((v) => !rtn.includes(v)));
            }
        }

        return rtn;
    }

    private openExistingStagingProjectModal(currentId: string | null, existingId: string, existingDisplayName: string): void {
        if (this.confirmationOverlayRef) {
            this.confirmationOverlayRef.close();
        }
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Confirmation - Unsaved Changes";
        data.message = existingDisplayName
            ? `Project: ${existingDisplayName} has unsaved changes. Would you like to edit this project instead?`
            : "An existing project was found with unsaved changes. Would you like to edit the existing project?";
        data.buttonLeftText = "Cancel";
        data.buttonLeftFunction = () => {
            this.confirmationOverlayRef.close(data);
        };
        data.buttonMiddleText = "Discard Changes";
        data.buttonMiddleFunction = async () => {
            this.confirmationOverlayRef.close(data);
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            await this.projectDelineationService.discardProject(existingId);
            if (currentId) {
                void this.router.navigate(["/details/project", currentId, "configuration"]);
            } else {
                void this.openNewProjectModal();
            }

            this.shouldWait$.next(false);
        };
        data.buttonRightText = "Edit Existing Project";
        data.buttonRightFunction = () => {
            this.confirmationOverlayRef.close(data);
            void this.router.navigate(["/details/project", existingId, "configuration"]);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );

        this.confirmationOverlayRef.afterClosed$.subscribe(() => {
            this.confirmationOverlayRef = undefined;
        });
    }

    private openStagingUserModal(displayName: string): void {
        if (this.confirmationOverlayRef) {
            this.confirmationOverlayRef.close();
        }
        const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
        data.header = "Warning - Project Already Edited";
        data.message = `${displayName} is currently editing this project. Please contact this user or try again later.`
        data.buttonRightText = "Close";
        data.buttonRightFunction = () => {
            this.confirmationOverlayRef.close(data);
        };

        this.confirmationOverlayRef = this.overlayService.open(
            ConfirmationDialogComponent,
            data
        );
        this.confirmationOverlayRef.afterClosed$.subscribe(() => {
            this.confirmationOverlayRef = undefined;
        });
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.assigned:
                const values = refiner.dataValue.split(valueSeparator);
                if (values?.length) {
                    const selected = values.map(v => ProjectListZrtFilterService.findNodeById(this.zrtFilterService.zrts, v)).filter(v => !!v);
                    this.zrtFilterService.selectedZrts = selected;
                } else {
                    this.zrtFilterService.selectedZrts = [];
                }
                break;
            case RefinerLocation.name:
                this.nameInput = refiner.value;
                break;
            case RefinerLocation.manager:
                this.managerInput = refiner.value;
                break;
            case RefinerLocation.street:
                this.streetInput = refiner.value;
                break;
            case RefinerLocation.city:
                this.cityInput = refiner.value;
                break;
            case RefinerLocation.zipCodes:
                this.zipInput = refiner.value;
            default:
                break;
        }
    }
}

<app-swisher-wholesaler-format-draft
    [activeProducts]="activeProducts()"
    [call]="call()"
    [signature]="signature()"
    [contact]="contact()"
    [customer]="customer()"
    [employee]="employee()"
    [wholesaler]="wholesaler()"
    [receiptNumberExtention]="receiptNumberExtention()"
>
</app-swisher-wholesaler-format-draft>
<app-eas-wholesaler-format-draft *ngIf="isEasEmployee()"
    [activeProducts]="activeProducts()"
    [call]="call()"
    [signature]="signature()"
    [contact]="contact()"
    [customer]="customer()"
    [employee]="employee()"
    [wholesaler]="wholesaler()"
    [receiptNumberExtention]="receiptNumberExtention()"
>
</app-eas-wholesaler-format-draft>
<app-eas-wholesaler-swisher-rep-format-draft *ngIf="!isEasEmployee()"
    [activeProducts]="activeProducts()"
    [call]="call()"
    [signature]="signature()"
    [contact]="contact()"
    [customer]="customer()"
    [employee]="employee()"
    [wholesaler]="wholesaler()"
    [receiptNumberExtention]="receiptNumberExtention()"
>
</app-eas-wholesaler-swisher-rep-format-draft>
<mat-card appearance="outlined" class="no-padding extra-light-gray">
    <mat-card-content class="header">
        <b>{{ displayName }} Tax - Paid Via</b>
    </mat-card-content>
    <mat-card-content>
        <div class="mt-2">
            {{ total | precisionCurrency }}
        </div>
        <div class="row">
            <div class="margin-top"></div>
            <div>

                <mat-radio-group color="warn" class="payment-radio-group"
                    [(ngModel)]="selectedPaymentType"
                    (change)="selectedPaymentTypeChange.next($event.value)">
                    <mat-radio-button class="payment-radio-button" *ngFor="
                            let paymentMethod of paymentOptions
                        " [value]="paymentMethod">
                        {{ paymentMethod }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </mat-card-content>
</mat-card>

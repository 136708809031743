import { MatSelect } from "@angular/material/select";
import {
    MatDrawerMode,
    MatSidenav,
    MatSidenavContent
} from "@angular/material/sidenav";
import { MatSortHeader } from "@angular/material/sort";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { BehaviorSubject, Subscription } from "rxjs";
import { map, take } from "rxjs/operators";
import {
    FilterSortDto,
    OrderTypes,
    RefinerLocation,
    SharedHelper,
    SortDirection,
    WholesalerGroupsListColumns,
    newSequentialId,
    valueSeparator
} from "shield.shared";
import { FilterAndParams } from "src/app/entity-models/filters-and-params.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { WholesalerGroupEntry } from "src/app/entity-models/wholesaler-group-entry.entity";
import { WholesalerProductCatalogItems } from "src/app/entity-models/wholesaler-product-catalog-items.entity";
import { FilterLocation } from "src/app/enums/filter-location";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { WholesalerGroupProductsFilterComponent } from "src/app/shared/filters/wholesaler-group-products-filter/wholesaler-group-products-filter.component";
import { WholesalerGroupsFilterComponent } from "src/app/shared/filters/wholesaler-groups-filter/wholesaler-groups-filter.component";
import { GridComponent } from "src/app/shared/grid/grid.component";
import { ExcelExportButtonComponent } from "src/app/shared/page-header/buttons/excel-export-button/excel-export-button.component";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";
import { PageHeaderComponent } from "src/app/shared/page-header/page-header.component";
import { ColumnDef } from "src/app/shared/viewmodels/column-def.viewmodel";
import { ColumnSelector } from "src/app/shared/viewmodels/column-selector.viewmodel";
import { GridData } from "src/app/shared/viewmodels/grid-data.viewmodel";
import { WholesalerDivisionProductsListViewModel } from "../../wholesale-products/wholesaler-products-list/wholesaler-division-products-list.viewmodel";
import { WholesalerGroupProductsListViewModel } from "../../wholesale-products/wholesaler-products-list/wholesaler-group-products-list.viewmodel";
import { WholesalerProductsDialogComponent } from "../../wholesale-products/wholesaler-products-list/wholesaler-products-dialog.component";
import { WholesalerProductsListViewModel } from "../../wholesale-products/wholesaler-products-list/wholesaler-products-list.viewmodel";
import { WholesalerGroupsListRefinerService } from "./wholesaler-groups-list-refiner.service";

export class WholesalerGroupsListViewmodel {

    activeRefiners: Refiner[] = [];
    activeSorts: FilterSortDto<WholesalerGroupsListColumns>[] = [];
    availableColumns: ColumnSelector[];
    baseGridYOffset = 0;
    columnDef: ColumnDef[];
    columnsToDisplay: string[];
    currentRefinerMap: Map<RefinerLocation, string> = new Map();
    dataSource: TableVirtualScrollDataSource<GridData> = new TableVirtualScrollDataSource<GridData>();
    detailHeight = 10;
    drawer: MatSidenav;
    drawerMode: MatDrawerMode = "side";
    expandPanelsOnInit = true;
    filterLocation = FilterLocation.default;
    filters: FilterAndParams[] = [];
    grid: GridComponent;
    gridData = new Array<GridData>();
    gridDataChangeSubscription: Subscription;
    gridHeight = "75vh";
    gridHeightOffsetDeduction = 0;
    groupInput: string;
    header: PageHeaderComponent;
    headerButtons: HeaderButtonComponent[] = new Array<HeaderButtonComponent>();
    headerName = "Wholesaler Groups";
    initialBatchLoading = false;
    isFixedLayout = false;
    isEnabledExpandedDetails = true;
    isGapSet = false;
    isSaveDisabled = true;
    isSearchButtonDisabled = false;
    isSearchStale = false;
    itemsRendedInViewPort = 13;
    itemSize = 48;
    methodInput: string[];
    methodOptions: OrderTypes[];
    methodSelect: MatSelect;
    overlayService: OverlayService;
    pageIndex = 0;
    pageSize = 50; // buffer size of the grid. We probally need to make the min and output buffers of the grid virtual scroll an input.
    pleaseWaitService: PleaseWaitService;
    previousRefinerMap: Map<RefinerLocation, string> = new Map();
    productListOverlayRef: SwisherOverlayRef<WholesalerProductsListViewModel<any>, WholesalerProductsDialogComponent>;
    refinerInputChangeSubscription: Subscription;
    refinerService: WholesalerGroupsListRefinerService;
    refinerServiceSubscription: Subscription;
    screenHeight: number = 0;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    sideNavContent: MatSidenavContent;
    topGapDistance = 0;
    total: number;
    wholesalerGroupDelineationService: WholesalerGroupDelineationService;
    wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService;
    isAdmin: boolean;

    readonly sortFunction = (columnDef: ColumnDef) => {
        if (this.grid) {
            const filterSorts = new Array<FilterSortDto<WholesalerGroupsListColumns>>();
            this.grid.sort.sortables.forEach((item) => {
                let sortDirection: SortDirection;
                switch ((item as MatSortHeader)._sort.direction) {
                    case "asc":
                        sortDirection = SortDirection.ascending;
                        break;
                    case "desc":
                        sortDirection = SortDirection.descending;
                        break;
                    default:
                        sortDirection = SortDirection.none;
                        break;
                }
                const sortDto = new FilterSortDto<WholesalerGroupsListColumns>();
                sortDto.direction = sortDirection;
                const headerName = this.columnDef.find(
                    (cd) => cd.dataPropertyName === item.id
                ).headerName;
                if (
                    headerName === columnDef.headerName &&
                    sortDirection != SortDirection.none
                ) {
                    sortDto.column = headerName as WholesalerGroupsListColumns;
                    filterSorts.push(sortDto);
                }
            });
            this.activeSorts = filterSorts;
            void this.getBatch(0, true);
        }
    };

    constructor(
        overlayService: OverlayService,
        pleaseWaitService: PleaseWaitService,
        refinerService: WholesalerGroupsListRefinerService,
        wholesalerGroupDelineationService: WholesalerGroupDelineationService,
        wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService,
        private appStateService: AppStateService,
    ) {
        this.overlayService = overlayService;
        this.pleaseWaitService = pleaseWaitService;
        this.refinerService = refinerService;
        this.wholesalerGroupDelineationService = wholesalerGroupDelineationService;
        this.wholesalerGroupProductCatalogItemDelineationService = wholesalerGroupProductCatalogItemDelineationService;
    }

    //events
    onAddGroupRefiner(event?: KeyboardEvent): void {
        if (this.groupInput
            && this.refinerService.getRefinerByLocation(RefinerLocation.group)?.value !== this.groupInput) {
            this.refinerService.onInputChange(
                RefinerLocation.group,
                this.groupInput,
                this.refinerService.buildStringDataValue(this.groupInput)
            );
        }

        if (this.groupInput && (!event || event.key === "Enter")) {
            this.refinerService.addRefiner(
                RefinerLocation.group,
                this.groupInput,
                "name",
                this.refinerService.buildStringDataValue(this.groupInput)
            );
        }

        if (!this.groupInput) {
            this.refinerService.removeRefinerByLocation(
                RefinerLocation.group,
                true,
                false,
                true
            )
        }
    }

    onOpenedMethodChange(): void {
        if (!this.methodSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.orderMethod;
            refiner.value = this.methodInput?.join(", ");
            refiner.dataPropertyName = "method";
            refiner.dataValue = this.methodInput?.join(valueSeparator);

            this.refinerService.checkAndUpdateRefiner(refiner, true);
        }
    }

    onButtonAction(value: any): void {
        switch (value) {
            default:
                break;
        }
    }

    onButtonClickEvent(value: HeaderButtonComponent) {
        if (value.onClick) {
            value.onClick();
        }
    }

    onOpenProductList(): (event: MouseEvent, index: number) => void {
        return async (_: MouseEvent, index: number) => {
            const rowData = this.gridData[index].data as WholesalerGroupEntry;
            const expectedId = rowData.id;

            const loaded$ = new BehaviorSubject(null);
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(loaded$);
            const response = await this.wholesalerGroupProductCatalogItemDelineationService
                .getByWholesalerGroupId(expectedId);
            loaded$.next(null);

            if (!response) {
                return;
            }

            const viewmodel = new WholesalerGroupProductsListViewModel(
                this.isAdmin,
                rowData,
                response.values,
                rowData.groupMembers,
            );

            this.openProductList(viewmodel);
        };
    }

    async onOpenLineItemProductList(
        wholesalerGroupMemberId: string,
        name: string,
        groupId: string,
        wholesalerId: string,
    ): Promise<void> {

        const loaded$ = new BehaviorSubject(null);
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(loaded$);
        const response = await this.wholesalerGroupProductCatalogItemDelineationService
            .getByWholesalerGroupId(groupId);
        loaded$.next(null);
        if (!response) {
            return;
        }

        const group = this.gridData
            .find(g => g.data.id === groupId)?.data as WholesalerGroupEntry | undefined;
        if (!group) {
            throw "Group not found.";
        }

        const wholesalerItems = response.values.reduce((arr, gi) => {
            const wholesalerItem = gi.wholesalerItems.find(wi => wi.wholesalerId === wholesalerId);
            if (wholesalerItem) {
                arr.push(wholesalerItem);
            }
            return arr;
        }, [] as WholesalerProductCatalogItems[]);

        const viewmodel = new WholesalerDivisionProductsListViewModel(
            this.isAdmin,
            group,
            wholesalerItems,
            group.groupMembers.find(m => m.wholesalerId === wholesalerId)!,
            response.values,
        );

        this.openProductList(viewmodel);
    }

    onRefinersChange() {
        this.setRefinerInput();

        if (this.header) {
            if (this.refinerService.refiners.length === 0) {
                this.header.expanded = false;
            } else {
                this.header.expanded = true;
            }
        }
        this.isSearchStale = true;
    }

    //public methods
    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                this.gridHeightOffsetDeduction =
                    window.pageYOffset +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                if (
                    this.baseGridYOffset === 0 &&
                    this.gridHeightOffsetDeduction !== 0
                ) {
                    this.baseGridYOffset = this.gridHeightOffsetDeduction;
                }
                this.gridHeight =
                    75 * (this.screenHeight / 100) +
                    (this.baseGridYOffset - this.gridHeightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet = true;
        }
    }

    reset(): void {
        this.refinerService.resetRefiners();
        this.search();

    }

    async getBatch(
        index: number,
        force?: boolean
    ): Promise<void> {
        const areRefinersTheSame = this.resolvePageIndex(index);
        this.isSearchStale = false;

        if (
            this.total != this.gridData?.length ||
            !areRefinersTheSame ||
            force
        ) {
            this.initialBatchLoading = true;
            this.pleaseWaitService.showProgressSpinnerUntilLoaded(this.shouldWait$);

            setTimeout(async () => { // wait one angular cycle to ensure myFilters have reset.
                const id = newSequentialId();

                const results = await this.wholesalerGroupDelineationService.getBatch(
                    id,
                    this.refinerService.refiners,
                    force ? 0 : this.pageSize,
                    this.pageIndex * this.pageSize,
                    this.activeSorts
                );

                if (results) {

                    this.total = results.count;
                    this.isGapSet = false;

                    let gridData: GridData[] = this.gridData.slice();
                    if (this.pageIndex === 0) {
                        gridData = new Array<GridData>();
                    }
                    const largestIndex = gridData.length;

                    for (const value of results?.values ?? []) {
                        const gridItem: GridData = {
                            data: {
                                ...value,
                                detailData: {
                                    groupMembers: value.groupMembers,
                                    groupId: value.id,
                                },
                            },
                            detailArrayName: "detailData",
                            isExpanded: this.grid?.areAllRowsExpanded ?? false,
                            index: largestIndex + results.values.indexOf(value)
                        }
                        gridData.push(gridItem);
                        this.gridData = gridData;

                        this.dataSource = new TableVirtualScrollDataSource(
                            this.gridData
                        );
                    }
                }
                else {
                    this.shouldWait$.next(true);
                }
            }, 0);
        }
    }

    headerExpansionChanged(): void {
        this.isGapSet = false;
    }

    async initialize(
        grid: GridComponent,
        header: PageHeaderComponent,
        sideNavContent: MatSidenavContent,
        drawer: MatSidenav,
        methodSelect: MatSelect
    ): Promise<void> {
        this.pleaseWaitService.showProgressSpinnerUntilLoaded(
            this.shouldWait$
        );

        this.grid = grid;
        this.header = header;
        this.sideNavContent = sideNavContent;
        this.drawer = drawer;
        this.methodSelect = methodSelect;

        this.isAdmin = await this.appStateService.currentEmployee.pipe(take(1), map(employee =>
            Helper.isEmployeeCustomerServiceOrAdmin(employee)
        )).toPromise();
        this.columnDef = [
            {
                headerName: "Id",
                dataPropertyName: "id",
                isAvailable: false,
                isSelected: false
            },
            {
                headerName: WholesalerGroupsListColumns.groupName,
                dataPropertyName: "groupName",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: WholesalerGroupsListColumns.groupId,
                dataPropertyName: "groupId",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: WholesalerGroupsListColumns.numberOfMembers,
                dataPropertyName: "numberOfMembers",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: WholesalerGroupsListColumns.orderMethod,
                dataPropertyName: "method",
                isAvailable: true,
                isSelected: true
            },
            {
                headerName: WholesalerGroupsListColumns.numberOfProducts,
                dataPropertyName: "numberOfProducts",
                showIconMarker: true,
                iconName: this.isAdmin ? 'edit' : 'visibility',
                isAvailable: true,
                isSelected: true,
                clickFunction: this.onOpenProductList()
            },
            {
                headerName: "groupmembers",
                dataPropertyName: "groupMembers",
                isAvailable: false,
                isSelected: true
            }
        ];
        this.filters = [];
        this.filters.push({ filter: WholesalerGroupsFilterComponent });
        this.filters.push({ filter: WholesalerGroupProductsFilterComponent });

        this.setFilterData();

        const availableColumns = new Array<ColumnSelector>();

        this.columnsToDisplay = this.columnDef
            .filter((cd) => {
                if (cd.isAvailable) {
                    const column: ColumnSelector = {
                        name: cd.headerName,
                        isSelected: cd.isSelected,
                        columns: null
                    };
                    availableColumns.push(column);
                }
            })
            .map((cd) => cd.headerName);
        this.availableColumns = availableColumns;

        if (this.refinerService.areDefaultsSet) {
            this.populateInputsFromRefiners();
        }

        if (
            !this.refinerServiceSubscription ||
            this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription = this.refinerService.refiners$.subscribe(
                () => {
                    this.onRefinersChange();
                }
            );
        }

        if (
            !this.refinerInputChangeSubscription ||
            this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription = this.refinerService.refinerInputChange$.subscribe(
                (refiner) => {
                    if (refiner) {
                        this.setInputFromRefiner(refiner);

                        if (refiner.shouldSearchWhenCleared && !refiner.value) {
                            this.getBatch(0);
                        }
                    }

                    if (this.header) {
                        if (this.refinerService.refiners.length === 0) {
                            this.header.expanded = false;
                        } else {
                            this.header.expanded = true;
                        }
                    }
                    this.isGapSet = false;
                }
            );
        }

        if (
            !this.gridDataChangeSubscription ||
            this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription = this.grid.dataSourceChange.subscribe(() => this.shouldWait$.next(true));
        }


    }

    populateInputsFromRefiners(): void {
        for (const refiner of this.refinerService.refiners) {
            this.setInputFromRefiner(refiner);
        }
    }

    resolvePageIndex(index: number): boolean {
        this.currentRefinerMap = new Map<RefinerLocation, string>();
        for (const refiner of this.refinerService.refiners ?? []) {
            this.currentRefinerMap.set(refiner.location, refiner.dataValue ?? refiner.value);
        }

        const rtn = SharedHelper.compareMaps(
            this.currentRefinerMap,
            this.previousRefinerMap
        );

        if (rtn && this.total != this.gridData?.length && index !== 0) {
            this.pageIndex++;
        } else {
            this.previousRefinerMap = new Map(this.currentRefinerMap);
            this.pageIndex = 0;
        }
        return rtn;
    }

    search() {
        this.activeRefiners = this.refinerService.refiners.slice();

        this.getBatch(this.pageIndex);
    }

    setFilterData(): void {
        this.methodOptions = [];

        this.methodOptions.push(OrderTypes.EDI);
        this.methodOptions.push(OrderTypes.EOrder);
        this.methodOptions.push(OrderTypes.Manual);

        this.refinerService.areDefaultsSet = true;
    }

    setRefinerInput(): void {
        this.groupInput = null;
        this.methodInput = null;

        this.populateInputsFromRefiners();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet = false;
    }


    unsubscribe(): void {
        if (
            this.refinerServiceSubscription &&
            !this.refinerServiceSubscription.closed
        ) {
            this.refinerServiceSubscription.unsubscribe();
        }
        if (
            this.refinerInputChangeSubscription &&
            !this.refinerInputChangeSubscription.closed
        ) {
            this.refinerInputChangeSubscription.unsubscribe();
        }
        if (
            this.gridDataChangeSubscription &&
            !this.gridDataChangeSubscription.closed
        ) {
            this.gridDataChangeSubscription.unsubscribe();
        }
    }

    private openProductList(
        viewmodel: WholesalerProductsListViewModel<any>,
    ): void {
        viewmodel.iconButtons = [
            new ExcelExportButtonComponent(),
        ];

        this.productListOverlayRef = this.overlayService.open(
            WholesalerProductsDialogComponent,
            viewmodel,
            true,
        );

        this.productListOverlayRef.afterClosed$.pipe(take(1)).subscribe((event) => {
            if (event.data?.changed) {
                this.getBatch(0, true);
            }
        });
    }

    private setInputFromRefiner(refiner: Refiner): void {
        switch (refiner.location) {
            case RefinerLocation.group:
                this.groupInput = refiner.value ?? null;
                break;
            case RefinerLocation.orderMethod:
                const selected = refiner.dataValue?.split(valueSeparator);
                const rtnSelected = new Array<string>();

                if (selected) {
                    for (const item of selected) {
                        const found = this.methodOptions?.find(
                            (v) => v === item
                        );
                        if (found) {
                            rtnSelected.push(found);
                        }
                    }
                }
                this.methodInput = rtnSelected;
                this.onOpenedMethodChange();

                break;
            default:
                break;
        }
    }
}

import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { CustomerTypeEnum, GenericLookup } from "shield.shared";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AddNewBusinessViewmodel } from "./add-new-business.viewmodel";
import { Helper } from "src/app/helpers/helper";

@Component({
    selector: "app-add-new-business",
    templateUrl: "./add-new-business.component.html",
    styleUrls: ["./add-new-business.component.scss"]
})
export class AddNewBusinessComponent implements OnInit, OnDestroy {
    viewmodel: AddNewBusinessViewmodel;
    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    nameSubscription: Subscription;
    phoneSubscription: Subscription;
    selectedStoreTypeSubscription: Subscription;
    address1Subscription: Subscription;
    citySubscription: Subscription;
    selectedStateSubscription: Subscription;
    zipSubscription: Subscription;
    formGroup: UntypedFormGroup;

    constructor(
        public overlayRef: SwisherOverlayRef<
            AddNewBusinessViewmodel,
            AddNewBusinessComponent
        >
    ) {
        this.viewmodel = this.overlayRef.data;

        this.formGroup = new UntypedFormGroup({
            nameControl: new UntypedFormControl(
                this.viewmodel.name,
                Validators.required
            ),
            selectedStoreTypeControl: new UntypedFormControl("", Validators.required),
            address1Control: new UntypedFormControl("", Validators.required),
            cityControl: new UntypedFormControl("", Validators.required),
            selectedStateControl: new UntypedFormControl("", Validators.required),
            zipControl: new UntypedFormControl("", {
                validators: [Validators.required, Validators.minLength(5)]
            }),
            phoneControl: new UntypedFormControl("", {
                validators: [Validators.pattern(this.usPhoneNumberRegEx)]
            })
        });
    }

    ngOnInit(): void {
        if (!this.phoneSubscription || this.phoneSubscription.closed) {
            this.phoneSubscription = this.formGroup.controls.phoneControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.phone = this.formGroup.controls.phoneControl
                        .value as string;
                }
            );
        }
        if (!this.nameSubscription || this.nameSubscription.closed) {
            this.nameSubscription = this.formGroup.controls.nameControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.name = this.formGroup.controls.nameControl
                        .value as string;
                }
            );
        }
        if (
            !this.selectedStoreTypeSubscription ||
            this.selectedStoreTypeSubscription.closed
        ) {
            this.selectedStoreTypeSubscription = this.formGroup.controls.selectedStoreTypeControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.selectedCustomerType = this.formGroup.controls
                        .selectedStoreTypeControl.value as GenericLookup<CustomerTypeEnum>;
                }
            );
        }
        if (!this.address1Subscription || this.address1Subscription.closed) {
            this.address1Subscription = this.formGroup.controls.address1Control.valueChanges.subscribe(
                () => {
                    this.viewmodel.address1 = this.formGroup.controls
                        .address1Control.value as string;
                }
            );
        }
        if (!this.citySubscription || this.citySubscription.closed) {
            this.citySubscription = this.formGroup.controls.cityControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.city = Helper.titleCase( this.formGroup.controls.cityControl
                        .value ) as string;
                }
            );
        }
        if (
            !this.selectedStateSubscription ||
            this.selectedStateSubscription.closed
        ) {
            this.selectedStateSubscription = this.formGroup.controls.selectedStateControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.selectedState = this.formGroup.controls
                        .selectedStateControl.value as string;
                }
            );
        }
        if (!this.zipSubscription || this.zipSubscription.closed) {
            this.zipSubscription = this.formGroup.controls.zipControl.valueChanges.subscribe(
                () => {
                    this.viewmodel.zip = this.formGroup.controls.zipControl
                        .value as string;
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.nameSubscription && !this.nameSubscription.closed) {
            this.nameSubscription.unsubscribe();
        }
        if (this.phoneSubscription && !this.phoneSubscription.closed) {
            this.phoneSubscription.unsubscribe();
        }
        if (
            this.selectedStoreTypeSubscription &&
            !this.selectedStoreTypeSubscription.closed
        ) {
            this.selectedStoreTypeSubscription.unsubscribe();
        }
        if (this.address1Subscription && !this.address1Subscription.closed) {
            this.address1Subscription.unsubscribe();
        }
        if (this.citySubscription && !this.citySubscription.closed) {
            this.citySubscription.unsubscribe();
        }
        if (
            this.selectedStateSubscription &&
            !this.selectedStateSubscription.closed
        ) {
            this.selectedStateSubscription.unsubscribe();
        }
        if (this.zipSubscription && !this.zipSubscription.closed) {
            this.zipSubscription.unsubscribe();
        }
    }

    clear(): void {
        this.formGroup.reset();
        this.viewmodel.clear();
    }
}

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { extend } from "jquery";
import { Subscription } from "rxjs";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Helper } from "src/app/helpers/helper";

export class ManualGratisEntryViewmodel extends BasicDialogViewModel {

    reason: string;
    date: Date;
    amount: number;
    isConfirmed = false;

    fb: UntypedFormBuilder
    manualGratisFormGroup: UntypedFormGroup;
    private resonSubscription: Subscription;
    private dateSubscription: Subscription;
    private amountSubscription: Subscription;

    constructor(fb: UntypedFormBuilder){
        super();
        this.headerLeftText = "Manual Gratis Entry";
        this.buttonLeftText = "Cancel";
        this.buttonLeftDisabledFunction = () => false;
        this.buttonRightDisabledFunction = () => this.isSaveDisabled();
        this.buttonRightText = "Save";

        this.fb = fb;
        this.manualGratisFormGroup = this.fb.group({
            reasonControl: new UntypedFormControl("", Validators.required),
            dateControl: new UntypedFormControl("", Validators.required),
            amountControl: new UntypedFormControl("", [Validators.required, Validators.min(.01)])
        });
        this.resonSubscription = this.manualGratisFormGroup.controls["reasonControl"].valueChanges.subscribe((value) => {
            this.reason = value;
        });
        this.dateSubscription = this.manualGratisFormGroup.controls['dateControl'].valueChanges.subscribe((value) => {
            this.date = value;
        });
        this.amountSubscription = this.manualGratisFormGroup.controls['amountControl'].valueChanges.subscribe((value) => {
            this.amount = value;
        })
    }

    upsubscribe(): void {
        if (this.resonSubscription && !this.resonSubscription.closed) {
            this.resonSubscription.unsubscribe();
        }
        if (this.dateSubscription && !this.dateSubscription.closed) {
            this.dateSubscription.unsubscribe();
        }
        if (this.amountSubscription && !this.amountSubscription.closed) {
            this.amountSubscription.unsubscribe();
        }
    }

    isSaveDisabled(): boolean {
        //this.manualGratisFormGroup.controls["reasonControl"].markAsTouched();
        return (this.manualGratisFormGroup.controls["reasonControl"].hasError("required") && this.manualGratisFormGroup.controls["reasonControl"].touched)
            || (this.manualGratisFormGroup.controls["dateControl"].hasError("required") && this.manualGratisFormGroup.controls["dateControl"].touched)
            || ((this.manualGratisFormGroup.controls["amountControl"].hasError("required") || this.manualGratisFormGroup.controls["amountControl"].hasError("min"))
                && this.manualGratisFormGroup.controls["amountControl"].touched)
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }
}

import moment from "moment";

export class Px3IncentivePeriod {
    id: string;
    startDate: Date;
    endDate: Date;

    constructor(id: string, startDate: Date, endDate: Date) {
        this.id = id;
        this.startDate = startDate;
        this.endDate = endDate;
    }

    public get formattedDateRange(): string {
        return `${moment(this.startDate).format('MM/DD')} - ${moment(this.endDate).format('MM/DD')}`;
    }
}

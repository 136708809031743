import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { OverlayService } from "src/app/services/overlay.service";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { faEye, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { AccountProfileBaseComponent } from "../account-profile-base/account-profile-base.component";
import { CustomerDetailedCallHistoryComponent } from "../customer-detailed-call-history/customer-detailed-call-history.component";
import { Customer } from "src/app/entity-models/customer.entity";
import { RelatedWholesalerViewmodel } from "./related-wholesalers/related-wholesalers.viewmodel";
import { LastOrderViewModel } from "./last-order/last-order.viewmodel";
import { retailProfileViewmodel } from "./retail-profile.viewmodel";
import { AppStateService } from "src/app/services/app-state.service";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { BehaviorSubject, Subscription } from "rxjs";
import { EditWholesalersViewModel } from "./related-wholesalers/edit-wholesalers/edit-wholesalers.viewmodel";
import { EditWholesalersComponent } from "./related-wholesalers/edit-wholesalers/edit-wholesalers.component";
import moment from "moment";
import { CustomerWholesaler } from "src/app/entity-models/customer-wholesaler.entity";
import { ConfirmationDialogComponent } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.component";
import { ConfirmationDialogViewmodel } from "src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { ContractTemplateDelineationService } from "src/app/services/delineation-services/contract-template-delineation.service";
import { ContractPaymentDelineationService } from "src/app/services/delineation-services/contract-payment-delineation.service";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { Helper } from "src/app/helpers/helper";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { Router } from "@angular/router";
import { ProjectStateService } from "src/app/services/project-state-service";
import { ProjectApplicationService } from "src/app/details/project/project-services/project-application.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { WholesalerGroupMemberDelineationService } from "src/app/services/delineation-services/wholesaler-group-member-delineation.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { CallDelineationService } from "src/app/services/delineation-services/call-delineation.service";
import { CustomerTypeEnum, FilterRequestDto } from "shield.shared";
import { SyncService } from "src/app/services/sync.service";
import { Px3DelineationService } from "src/app/services/delineation-services/px3-delineation.service";
import { CallService } from "../../call-master/call-services/call.service";

@Component({
    selector: "app-retail-profile",
    templateUrl: "./retail-profile.component.html",
    styleUrls: ["./retail-profile.component.scss"]
})
export class RetailProfileComponent
    extends AccountProfileBaseComponent
    implements OnInit, OnDestroy {
    //Public vars
    @ViewChild("history") history: CustomerDetailedCallHistoryComponent;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    faEye: IconDefinition = faEye;

    customerMembers = new Array<RelatedWholesalerViewmodel>();
    lastOrderLineItems = new Array<LastOrderViewModel>();
    retailProfileViewmodel = new retailProfileViewmodel();

    retailProfileSubscription: Subscription;
    wholesalerUpdated =  new EventEmitter();

    shouldWait$ = new BehaviorSubject<boolean>(true);

    public constructor(
        appStateService: AppStateService,
        overlayService: OverlayService,
        contactDelineationService: ContactDelineationService,
        customerContractDelineationService: CustomerContractDelineationService,
        customerDelineationSevice: CustomerDelineationService,
        customerStateService: CustomerStateService,
        contractTemplateDelineationService: ContractTemplateDelineationService,
        contractPaymentDelineationService: ContractPaymentDelineationService,
        projectDelineationService: ProjectDelineationService,
        router: Router,
        projectStateService: ProjectStateService,
        projectApplicationService: ProjectApplicationService,
        wholesalerGroupMemberDelineationService: WholesalerGroupMemberDelineationService,
        snackbarService: SnackbarService,
        px3RankService: Px3DelineationService,
        private pleaseWaitSerive: PleaseWaitService,
        private productDelineationService: ProductDelineationService,
        private callDelineationService: CallDelineationService,
        private callService: CallService,
        syncService: SyncService,
    ) {
        super(appStateService
            , overlayService
            , contactDelineationService
            , customerContractDelineationService
            , contractPaymentDelineationService
            , customerDelineationSevice
            , customerStateService
            , contractTemplateDelineationService
            , projectDelineationService
            , router
            , projectStateService
            , projectApplicationService
            , wholesalerGroupMemberDelineationService
            , snackbarService
            , px3RankService
            , syncService);
    }

    async ngOnInit(): Promise<void> {
        super.ngOnInit();

        if (!this.retailProfileSubscription || this.retailProfileSubscription.closed) {
            this.retailProfileSubscription = this.customerStateService.observableCustomer.subscribe(
                (customer) => {
                    if (!this.customerStateService.ignoreCustomerSubscription) {
                        this.customer = customer;

                        if (this.customer) {
                            this.retailProfileViewmodel.buildRetailProfileViewmodel(this.customerStateService?.customer);
                            void this.buildWholesalerViewModels();
                            void this.getLastOrder();
                        }
                    }
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (this.customerSubscription && !this.customerSubscription.closed) {
            this.customerSubscription.unsubscribe();
        }
        if (this.retailProfileSubscription && !this.retailProfileSubscription.closed) {
            this.retailProfileSubscription.unsubscribe();
        }
    }

    async onOpenWholesalerDialog(wholesalerVm?: RelatedWholesalerViewmodel, excludedIds?: string[]): Promise<void> {
        if (this.confirmationOverlayRef) {
            this.confirmationOverlayRef.close();
		}

        if (this.editWholesalersOverlayRef) {
            this.editWholesalersOverlayRef.close();
		}


        const isEditMode = wholesalerVm?.wholesaler;
        if (wholesalerVm?.excludedIds?.length && !excludedIds?.length) {
            excludedIds = wholesalerVm.excludedIds;
        }

        const types = Helper.getStoreTypesByCustomerGenericType(
            CustomerGenericTypes.wholesale
        );

        this.pleaseWaitSerive.showProgressSpinnerUntilLoaded(this.shouldWait$);

        let wholesalers = new Array<Customer>();
        if (!isEditMode) {
            const wholesalersResponse = await this.customerDelineationService.getByCustomerTypes([CustomerTypeEnum.DirectWholesaler,
                CustomerTypeEnum.IndirectWholesaler], true);
            if (!wholesalersResponse) {
                this.shouldWait$.next(false);
                return;
            }

            wholesalers = wholesalersResponse.values;
            wholesalers = (wholesalers ?? []).filter((w) => w.isActive && !w.isDeleted && !excludedIds?.includes(w.id));
        }

        this.shouldWait$.next(false);

        const data = new EditWholesalersViewModel(wholesalerVm, wholesalers);
        data.headerLeftText = wholesalerVm?.wholesaler ? "Edit Wholesaler" : "Add Wholesaler";
        data.headerRightText = wholesalerVm?.wholesaler ? "Last Updated on " + moment(wholesalerVm.lastTouchedUtcDateTime).format(MY_DATE_FORMATS.display.dateInput) : "";
        let removeWholesaler = false
        data.buttonLeftFunction = wholesalerVm?.wholesaler
            ? () => {
                removeWholesaler = true
                const confirmation: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
                confirmation.header = "Remove Wholesaler";
                confirmation.message = "Are you sure you want to remove this wholesaler relationship?";
                confirmation.buttonLeftText = "No";
                confirmation.buttonLeftFunction = () => this.confirmationOverlayRef.close();
                confirmation.buttonRightText = "Yes";
                confirmation.buttonRightFunction = () => {
                    data.isConfirmed = true;
                    data.shouldDelete = true;
                    this.confirmationOverlayRef.close()
                    this.editWholesalersOverlayRef.close(data);
                }
                this.confirmationOverlayRef = this.overlayService.open(
                    ConfirmationDialogComponent,
                    confirmation
                );
            }
            : () => this.editWholesalersOverlayRef.close(data);
        data.buttonMiddleFunction = wholesalerVm.wholesaler
            ? () => this.editWholesalersOverlayRef.close(data)
            : null;
        data.buttonRightFunction = () => {
            if (removeWholesaler) {
                data.isConfirmed = true;
                data.shouldDelete = true;
                this.confirmationOverlayRef.close()
                this.editWholesalersOverlayRef.close(data);
            } else {
                data.isConfirmed = true;
                this.editWholesalersOverlayRef.close(data);
            }
        };
        this.editWholesalersOverlayRef = this.overlayService.open(
            EditWholesalersComponent,
            data,
            true
        );

        this.editWholesalersOverlayRef.afterClosed$.subscribe(async (ref) => {
            if (ref.data && ref.data.isConfirmed) {

                this.pleaseWaitSerive.showProgressSpinnerUntilLoaded(this.shouldWait$);

                const existingWholesaler = ref.data.wholesalerToEdit
                    ? this.customerStateService.customer.customerWholesalers
                        .find((w) => w.wholesalerId === ref.data.wholesalerToEdit.id)
                    : this.customerStateService.customer.customerWholesalers
                        .find((w) => w.wholesalerId === ref.data.wholesaler[0].id);
                if (existingWholesaler) {
                    if (ref.data.shouldDelete) {
                        this.customerStateService.customer.customerWholesalers
                            = this.customerStateService.customer.customerWholesalers
                                .filter((w) => w.wholesalerId !== existingWholesaler.wholesalerId);
                    } else {
                        existingWholesaler.customerCode = ref.data.customerCode;
                        existingWholesaler.modifiedUtcDateTime = new Date();
                    }
                } else {
                    const newWholesaler = new CustomerWholesaler();
                    newWholesaler.wholesalerId = ref.data.wholesaler[0].id;
                    newWholesaler.customerCode = ref.data.customerCode;
                    newWholesaler.modifiedUtcDateTime = newWholesaler.createdUtcDateTime = new Date();
                    newWholesaler.modifiedUserId = newWholesaler.createdUserId = this.currentEmployee.id;
                    newWholesaler.modifiedUserZrt = newWholesaler.createdUserZrt = this.currentEmployee.zrt;
                    newWholesaler.modifiedUserFullName = newWholesaler.createdUserFullName = this.currentEmployee.fullName;

                    this.customerStateService.customer.customerWholesalers.push(newWholesaler);
                }

                await this.customerDelineationService.upsertCustomer(
                    this.customerStateService.customer
                );

                const response = await this.customerDelineationService.getWholesalersForCustomer(this.customerStateService.customer);
                if (!response) {
                    return;
                }
                await this.buildWholesalerViewModels(response.values);
                this.shouldWait$.next(false);
            }
        });

        this.confirmationOverlayRef?.afterClosed$.subscribe(() => {
            this.confirmationOverlayRef = undefined;
		});
    }

    async buildWholesalerViewModels(wholesalers?: Customer[]): Promise<void> {

        if (!wholesalers) {
            const wholesalersResponse = await this.customerDelineationService.getByIds(this.customerStateService.customer.customerWholesalers.map(w => w.wholesalerId), true); // force offline
            if (wholesalersResponse?.values) wholesalers = wholesalersResponse.values;
        }

        const relations = new Array<RelatedWholesalerViewmodel>();
        if (wholesalers?.length) {
            const wholesalerMap = new Map(wholesalers.map((w) => [w.id, w]));
            for (const wholesaler of this.customerStateService.customer.customerWholesalers) {
                const memberRecord = new RelatedWholesalerViewmodel();
                memberRecord.wholesaler = wholesalerMap.get(wholesaler.wholesalerId);
                memberRecord.customerCode = wholesaler.customerCode;
                memberRecord.lastTouchedUtcDateTime =
                    wholesaler.modifiedUtcDateTime ??
                    wholesaler.createdUtcDateTime;
                memberRecord.addedByName = Helper.formatDisplayName(wholesaler) ?? "System";
                relations.push(memberRecord);
            }
            this.customerMembers = relations.sort((a, b) =>
                a.lastTouchedUtcDateTime >= b.lastTouchedUtcDateTime ? -1 : 1
            );
        } else {
            this.customerMembers = relations;
        }
    }

    async getLastOrder(): Promise<void> {
        this.lastOrderLineItems = new Array<LastOrderViewModel>();
        const filterRequestDto = new FilterRequestDto();
        const callType = this.callService.getCallTypeForCustomerAndEmployee(this.customerStateService.customer, this.currentEmployee);
        const lastOrderResponse = await this.callDelineationService.getLastCallOfTypeWithOrdersByCustomerId(this.customerStateService.customer?.id, callType);
        if (!lastOrderResponse) {
            this.shouldWait$.next(false);
            return;
        }

        const lastOrder = lastOrderResponse.values as RetailCall;

        if (lastOrder) {
            for (const order of lastOrder.orderProducts) {
                const vm = new LastOrderViewModel;
                vm.productId = order.productId;
                vm.type = "Order";
                vm.wholesalerId = order.wholesalerCustomerId;
                vm.quantity = order.quantity.toString();

                this.lastOrderLineItems.push(vm);
            }

            const productsResponse = await this.productDelineationService.getByIds(this.lastOrderLineItems.map((li) => li.productId));
            if (!productsResponse) {
                this.shouldWait$.next(false);
                return;
            }
            const products = productsResponse.values;

            if (products) {
                this.lastOrderLineItems.forEach(
                    (li) =>
                        (li.productName = products.find(
                            (p) => p.id === li.productId
                        )?.description.trim())
                );
            }

            const wholesalersResponse = await this.customerDelineationService.getByIds(this.lastOrderLineItems.filter((li => li.wholesalerId)).map((li) => li.wholesalerId));
            if (!wholesalersResponse) {
                this.shouldWait$.next(false);
                return;
            }
            const wholesalers = wholesalersResponse.values;

            if (wholesalers) {
                this.lastOrderLineItems.forEach((li) =>
                    li.wholesalerId
                        ? (li.wholesalerName = wholesalers.find(
                            (w) => w.id === li.wholesalerId
                        ).name)
                        : (li.wholesalerName = "")
                );
            }
        }
    }
}

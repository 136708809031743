import {
    CustomerTypeEnum,
    CustomerTypeLookup,
    GenericLookup,
    newSequentialId,
} from "shield.shared";
import { Address } from "src/app/entity-models/address.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { State } from "src/app/entity-models/state.entity";
import { Helper } from "src/app/helpers/helper";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { FilterService } from "src/app/services/filter.service";

export class AddNewBusinessViewmodel {
    name: string;
    phone: string;
    selectedCustomerType: GenericLookup<CustomerTypeEnum>;
    customerTypes: Array<GenericLookup<CustomerTypeEnum>>
    address1: string;
    address2: string;
    city: string;
    selectedState: string;
    states: State[];
    zip: string;
    showFooter = true;
    headerLeftText = "New Business";
    buttonLeftText = "Cancel";
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    buttonRightText = "Save";
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    width = "auto";
    height = "auto";
    isConfirmed = false;

    filterService: FilterService;
    customerDelineationService: CustomerDelineationService;

    employee: Employee;

    constructor(
        filterService: FilterService,
        employee: Employee,
        customerDelineationService: CustomerDelineationService
    ) {
        this.filterService = filterService;
        this.employee = employee;
        this.customerDelineationService = customerDelineationService;
        void this.initializeDropDowns();
    }

    async initializeDropDowns(): Promise<void> {
        const customerTypes = await this.filterService.getCustomerTypes();
        this.customerTypes = customerTypes.filter((ct) => ct.id !== CustomerTypeEnum.ChainHQ && ct.id !== CustomerTypeEnum.Unknown);
        this.states = await this.filterService.getStates();
    }

    async buildDomainModelFromViewModel(): Promise<Customer> {
        const rtn = new Customer();
        rtn.id = newSequentialId();
        rtn.name = this.name;
        rtn.phone = this.phone;
        rtn.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, this.selectedCustomerType.id);
        rtn.isActive = true;
        rtn.isMsa = false;
        rtn.isCallable = true;
        rtn.isCallableOverridden = false;

        const businessAddress = new Address();
        businessAddress.name = this.name;
        businessAddress.address1 = this.address1;
        businessAddress.address2 = this.address2;
        businessAddress.city = this.city;
        businessAddress.state = this.selectedState;
        businessAddress.zip = this.zip;
        rtn.businessAddress = businessAddress;
        rtn.dateCreated = new Date();
        rtn.lastEdited = rtn.dateCreated;
        rtn.lastEditedBy = this.employee.fullName;
        rtn.isAddNewBusiness = true;

        rtn.isZrtAssignment = 1;

        return rtn;
    }

    clear(): void {
        this.name = null;
        this.phone = null;
        this.selectedCustomerType = null;
        this.address1 = null;
        this.address2 = null;
        this.city = null;
        this.selectedState = null;
        this.zip = null;
    }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    TransactionDto,
    TransactionLineItemDto,
    EmailAttachment,
    GenericResponseDto,
    newSequentialId,
    NotificationRequestDto,
    GenericRequestDto,
    TransactionBatchParamsDto,
    FilterRequestV2Dto,
    TransactionListFilterDto,
    TransactionsListColumns
} from "shield.shared";
import { TransactionLineItem } from "../../entity-models/transaction-line-item.entity";
import { Transaction } from "../../entity-models/transaction.entity";
import { TransactionConverterService } from "../converter-services/transaction-converter.service";
import { ConverterHelper } from "../converter-services/converter.helper";

@Injectable()
export class TransactionOnlineService {
    constructor(
        private http: HttpClient
    ) { }

    async getById(id: string): Promise<GenericResponseDto<Transaction | undefined>> {

        const result = await this.http
            .get<GenericResponseDto<TransactionDto | undefined>>(`/api/transactions/${id}`)
            .toPromise();

            const rtn = new GenericResponseDto<Transaction>();
            if (result) {
                rtn.count = result.count;
                rtn.affected = result.affected;
                rtn.counts = result.counts;
                rtn.id = result.id;
                rtn.isError = result.isError;
                rtn.message = result.message;
                rtn.values = TransactionConverterService.transactionDtoToTransaction(result.values);
            }

            return rtn;
    }

    async getTransactionBatch(
        params: TransactionBatchParamsDto
    ): Promise<GenericResponseDto<Transaction[]>> {
        const request = new GenericRequestDto<FilterRequestV2Dto<TransactionListFilterDto, TransactionsListColumns>>();
        request.id = newSequentialId();
        request.values = params.filterRequestDto;

        const result = await this.http
            .post<GenericResponseDto<TransactionDto[]>>(
                "/api/transactions",
                request
            )
            .toPromise();

        const rtn = new GenericResponseDto<Transaction[]>();
        if (result) {
            rtn.count = result.count;
            rtn.affected = result.affected;
            rtn.counts = result.counts;
            rtn.id = result.id;
            rtn.isError = result.isError;
            rtn.message = result.message;
            rtn.values = new Array<Transaction>();
            for (const r of result.values) {
                rtn.values.push(TransactionConverterService.transactionDtoToTransaction(r));
            }
        }

        return rtn;
    }

    async saveInvoiceDetails(request: GenericRequestDto<TransactionLineItem[]>): Promise<GenericResponseDto<Transaction>> {

        let httpRequest = new GenericRequestDto<TransactionLineItemDto[]>();
        httpRequest.id = request.id;
        httpRequest.values = request.values.map((lineItem) => TransactionConverterService.transactionLineItemToTransationLineItemDto(lineItem));

        const result = await this.http
            .post<GenericResponseDto<TransactionDto>>(
                `/api/transactions/${httpRequest.id}/update-products`,
                request
            )
            .toPromise();

        const rtn = new GenericResponseDto<Transaction>();
        if (result) {
            rtn.count = result.count;
            rtn.affected = result.affected;
            rtn.counts = result.counts;
            rtn.id = result.id;
            rtn.isError = result.isError;
            rtn.message = result.message;
            rtn.values = result.values !== null ? TransactionConverterService.transactionDtoToTransaction(result.values) : null;
        }

        return rtn;
    }

}

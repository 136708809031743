<ng-container>
    <mat-accordion>
        <mat-expansion-panel hideToggle [(expanded)]="expanded" (afterCollapse)="onExpandChange()"
            (afterExpand)="onExpandChange()">
            <mat-expansion-panel-header>
                <div class="expansion-header d-flex align-items-center justify-content-between">
                    <div class="flex-grow-1 flex-basis-0">
                        <div class="client-list-toggle" data-toggle="collapse" role="button" aria-expanded="false">
                            {{name}}
                            <mat-icon *ngIf="!expanded" class="menu-icon">expand_more</mat-icon>
                            <mat-icon *ngIf="expanded" class="menu-icon">expand_less</mat-icon>
                        </div>
                    </div>
                    <div class="text-center flex-grow-1 flex-basis-0">
                        <small>
                            Total Entries: {{ (entryCount ? entryCount : 0) | number }}
                        </small>
                    </div>
                    <div class="d-flex justify-content-end flex-grow-1 flex-basis-0">
                        <div *ngFor="let button of buttons">
                            <a class="btn btn-sm btn-white" data-toggle="tooltip" data-placement="bottom"
                                [title]="button.title" [ngClass]="{'disabled': button.isDisabled}"
                                (click)="onButtonClick(button); $event.stopPropagation()">
                                <div [ngClass]="{'rotate': button.isLoading}">
                                    <fa-icon [icon]="button.isLoading ? spinner : button.icon"></fa-icon>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <mat-menu #menu="matMenu" class="pull-right" (closed)="onColumnVisabilityMenuClose()">
                    <section class="checkbox-section">
                        <span>
                            <mat-checkbox class="checkbox-margin" [checked]="allColumnsChecked" [color]="'warn'"
                                [indeterminate]="someColumnsChecked()" (change)="setAllColumnsChecked($event.checked)"
                                (click)="$event.stopPropagation()">
                                {{availableColumns.name}}
                            </mat-checkbox>
                        </span>
                        <span>
                            <ul>
                                <li *ngFor="let column of availableColumns.columns">
                                    <mat-checkbox [(ngModel)]="column.isSelected" [color]="'warn'"
                                        (ngModelChange)="updateAllColumnsChecked()" (click)="$event.stopPropagation()">
                                        {{column.name}}
                                    </mat-checkbox>
                                </li>
                            </ul>
                        </span>
                    </section>
                </mat-menu>
                <div mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu">
                </div>
            </mat-expansion-panel-header>
            <div class="expansion-body">
                <div class="d-flex">
                    <div id="filter-chips" class="show left-section" [ngClass]="{
                        '': (refinerService?.stagedRefiners$ | async)?.length > 0
                    }">
                        <mat-chip-listbox #chipList aria-label="Test">
                            <ng-container *ngFor="let refiner of refinerService?.stagedRefiners$ | async">
                                <mat-chip-option *ngIf="refiner.shouldPreventRemoval"
                                    [selectable]="false" [removable]="!refiner.shouldPreventRemoval"
                                    (removed)="refinerService?.removeRefiner(refiner)">
                                    <div *ngIf="refiner.value">
                                        {{ refiner.location }}-{{ " " + refiner.value }}&nbsp;
                                    </div>
                                    <div *ngIf="!refiner.value">
                                        {{ refiner.location }}
                                    </div>
                                </mat-chip-option>
                            </ng-container>
                            <ng-container *ngFor="let refiner of refinerService?.stagedRefiners$ | async">
                                <mat-chip-option matChipRemove *ngIf="!refiner.shouldPreventRemoval"
                                    [selectable]="false" [removable]="!refiner.shouldPreventRemoval"
                                    (removed)="refinerService?.removeRefiner(refiner)">
                                    <div *ngIf="refiner.value">
                                        {{ refiner.location }}-{{ " " + refiner.value }}
                                        <span class="material-icons ml-2">close</span>
                                    </div>
                                    <div *ngIf="!refiner.value">
                                        {{ refiner.location }}
                                        <span class="material-icons ml-2">close</span>
                                    </div>
                                </mat-chip-option>
                            </ng-container>
                            <div class="my-clear-all ml-2" *ngIf="(refinerService?.stagedRefiners$ | async)?.length > 0"
                                (click)="refinerService?.clearRefiners()">
                                Clear all
                            </div>
                        </mat-chip-listbox>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
<div class="wrapper d-flex flex-grow-1">
    <mat-card appearance="outlined" class="w-100 d-flex flex-grow-1">
        <div class="row d-flex flex-grow-1">
            <div class="col-xl-3 col-12 d-flex flex-column flex-grow-1">
                <app-project-summary-release-dates 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [project]="project"
                ></app-project-summary-release-dates>
                <app-project-summary-order-dates 
                    #summaryOrderDates 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [project]="project"
                ></app-project-summary-order-dates>
            </div>
            <div class="col-xl-5 col-12 d-flex flex-column flex-grow-1">
                <app-project-summary-stores 
                    #summaryStores 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [project]="project"
                ></app-project-summary-stores>
                <app-project-summary-assignments 
                    #summaryAssignments 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [project]="project"
                ></app-project-summary-assignments>
            </div>
            <div class="col-xl-4 col-12 d-flex flex-column flex-grow-1">
                <app-project-summary-products
                    #summaryProducts 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [buildProducts]="!inStepper"
                    [project]="project"></app-project-summary-products>
                <app-project-summary-activities-surveys
                    #summaryActivitySurveys 
                    class="project-summary-grid-item d-flex flex-column flex-grow-1"
                    [project]="project"></app-project-summary-activities-surveys>
            </div>
        </div>
    </mat-card>
</div>
import {
    CustomerTypeEnum,
    CustomerTypeLookup,
    GenericLookup,
    LicenseTypes,
    newSequentialId } from "shield.shared";
import { Address } from "src/app/entity-models/address.entity";
import { CustomerLicense } from "src/app/entity-models/customer-license.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { WholesalerProduct } from "src/app/entity-models/wholesaler-product.entity";
import { Helper } from "src/app/helpers/helper";
import { WholesalerGroupDelineationService } from "src/app/services/delineation-services/wholesaler-group-delineation.service";
import { CustomerProfileViewModel } from "../customer-profile.viewmodel";

export class WholesaleProfileViewmodel extends CustomerProfileViewModel {
    id: string;
    customer: Customer;
    manager: string;
    rep: string;
    numberOfStores: number;
    isCashAndCarry: boolean;
    customerType: GenericLookup<CustomerTypeEnum>;
    customerTypeName: string;
    federalTaxId: string;
    oldFederalTaxId: string;
    federalTaxIdLastUpdated: Date;
    stateTaxId: string;
    oldStateTaxId: string;
    stateTaxIdLastUpdated: Date;
    retailStateVaporLicense: string;
    oldRetailStateVaporLicense: string;
    retailStateVaporLicenseLastUpdated: Date;
    retailStateOTPLicense: string;
    oldRetailStateOTPLicense: string;
    retailStateOTPLicenseLastUpdated: Date;
    displayZrt: string;
    groupName: string | null = null;
    orderType: string | null = null;

    private oldCustomer: Customer;
    private employeeName: string;
    private wholesalerProducts: WholesalerProduct[];
    private employee: Employee;

    constructor(private wholesalerGroupDelineationService: WholesalerGroupDelineationService){
        super();
    }

    async buildViewmodelFromDomainModel(
        customer: Customer,
        employee: Employee,
        employeeName?: string
    ): Promise<void> {
        this.employee = employee;
        if (customer) {
            this.customer = customer;
            this.oldCustomer = Object.assign({}, customer);
            //Break the address obj ref
            this.oldCustomer.businessAddress = Object.assign({}, customer.businessAddress);
            this.oldCustomer.dbaAddress = Object.assign({}, customer.dbaAddress);
            this.oldCustomer.customerLicenses = customer.customerLicenses?.slice();
            this.numberOfStores = customer.storeCount;
            this.isCashAndCarry = customer.isCashAndCarry;
            this.customer = customer;
            this.rep = customer.rep;
            this.manager = customer.manager;
            this.customerType = Object.assign({}, customer.customerType);
            this.displayZrt = this.getDisplayZrtFromCustomer(customer, employee);
            this.employeeName = employeeName;
            if (this.isCashAndCarry) {
                this.customerTypeName = this.customer.customerType?.name.concat(" Cash and Carry");
            }
            if (!this.customer.businessAddress) {
                const businessAddress = new Address();
                businessAddress.id = newSequentialId();
                this.customer.businessAddress = businessAddress;
            }

            if (!this.customer.dbaAddress) {
                const dbaAddress = new Address();
                dbaAddress.id = newSequentialId();
                this.customer.dbaAddress = dbaAddress;
            }
            this.wholesalerProducts = customer.wholesalerProducts;

            const customerLicenses = customer.customerLicenses;
            if (customerLicenses?.length) {
                const federalTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.FederalTaxId && cl.isActive);
                const stateTaxLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.StateTaxId && cl.isActive);
                const retailStateOTPLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive);
                const retailStateVaporLicense = customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive);

                this.federalTaxId = federalTaxLicense?.licenseNumber;
                this.federalTaxIdLastUpdated = federalTaxLicense?.modifiedUtcDateTime ?? federalTaxLicense?.createdUtcDateTime;
                this.stateTaxId = stateTaxLicense?.licenseNumber;
                this.stateTaxIdLastUpdated = stateTaxLicense?.modifiedUtcDateTime ?? stateTaxLicense?.createdUtcDateTime;
                this.retailStateOTPLicense = retailStateOTPLicense?.licenseNumber;
                this.retailStateOTPLicenseLastUpdated = retailStateOTPLicense?.modifiedUtcDateTime ?? retailStateOTPLicense?.createdUtcDateTime;
                this.retailStateVaporLicense = retailStateVaporLicense?.licenseNumber;
                this.retailStateVaporLicenseLastUpdated = retailStateVaporLicense?.modifiedUtcDateTime ?? retailStateVaporLicense?.createdUtcDateTime;

                this.oldFederalTaxId = this.federalTaxId;
                this.oldStateTaxId = this.stateTaxId;
                this.oldRetailStateOTPLicense = this.retailStateOTPLicense;
                this.oldRetailStateVaporLicense = this.retailStateVaporLicense;
            }

            const response = await this.wholesalerGroupDelineationService.getByWholesalerId(customer.id);
            if (response) {
                this.groupName = response.name;
                this.orderType = response.orderType;
            }
        }
    }

    buildDomainModelsFromViewModel(): {
        customer: Customer;
        isEqual: boolean;
    } {
        const now = new Date();
        const customer = this.customer;
        customer.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, customer.customerType.id);
        customer.isCashAndCarry = this.isCashAndCarry;
        customer.storeCount = this.numberOfStores;
        this.buildLicenseEntityFromViewmodel();
        customer.wholesalerProducts = this.wholesalerProducts;
        const isEqual: boolean = this.isEqual(customer);

        return { customer, isEqual };
    }

    buildLicenseEntityFromViewmodel(): void {
        if (this.employee) {
            if (this.federalTaxId != this.oldFederalTaxId) {
                this.buildCustomerLicenese(LicenseTypes.FederalTaxId, this.federalTaxId);
            }
            if (this.stateTaxId != this.oldStateTaxId) {
                this.buildCustomerLicenese(LicenseTypes.StateTaxId, this.stateTaxId);
            }
            if (this.retailStateVaporLicense != this.oldRetailStateVaporLicense) {
                this.buildCustomerLicenese(LicenseTypes.RetailStateVaporLicense, this.retailStateVaporLicense);
            }
            if (this.retailStateOTPLicense != this.oldRetailStateOTPLicense) {
                this.buildCustomerLicenese(LicenseTypes.RetailStateOTPLicense, this.retailStateOTPLicense);
            }
        }
    }

    private buildCustomerLicenese(licenseTypeId: LicenseTypes, licenseNumber: string): void {
        this.customer.customerLicenses ??= [];
        const index = this.customer.customerLicenses.findIndex((cl) => cl.licenseTypeId === licenseTypeId);
        if (index !== -1) {
            this.customer.customerLicenses[index].isActive = false;
        }

        const customerLicense = new CustomerLicense();
        customerLicense.id = newSequentialId();
        customerLicense.customerId = this.customer.id;
        customerLicense.licenseNumber = licenseNumber;
        customerLicense.licenseTypeId = licenseTypeId;
        customerLicense.isActive = true;
        customerLicense.createdUtcDateTime = new Date();
        customerLicense.createdUserId = this.employee.id;
        customerLicense.createdUserZrt = this.employee.zrt;
        customerLicense.createdUserFullName = this.employee.fullName;
        if (index) {
            customerLicense.modifiedUtcDateTime = customerLicense.createdUtcDateTime;
            customerLicense.modifiedUserId = customerLicense.createdUserId;
            customerLicense.modifiedUserZrt = customerLicense.createdUserZrt;
            customerLicense.modifiedUserFullName = customerLicense.createdUserFullName;
        }
        this.customer.customerLicenses.push(customerLicense);
    }

    isEqual(customer: Customer): boolean {
        return (JSON.stringify(customer) === JSON.stringify(this.oldCustomer));
    }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subscriber, Subscription } from "rxjs";
import { approvedProjectRoles, EmployeeRoleType } from "shield.shared";
import { Employee } from "../entity-models/employee.entity";
import { AppStateService } from "../services/app-state.service";
import { PingService } from "../services/ping.service";
import { SnackbarService } from "../services/snackbar.service";


@Injectable({
    providedIn: 'root'
})
export class CanActivateProjectCreationGuard  {

    private employee: Employee;
    private employeeSubscription: Subscription;
    private canEmployeeActivate: boolean;
    private count = 0;


    constructor(private appStateService: AppStateService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe((employee) => {
                this.employee = employee;
            });
        }

        return new Observable<boolean>((observer) => {

            this.count = 0;
            this.canEmployeeActivate = false;
            void this.counter(observer);
        });
    }

    private complete(observer: Subscriber<boolean>): void {

        observer.next(this.canEmployeeActivate);
        observer.complete();
        if (!this.canEmployeeActivate) {
            // may want an Access denied landing page here;
            void this.router.navigate(["/accounts/customers"]);
        }
    }

    private checkSecurity() {
        if (this.employee) {
            this.canEmployeeActivate = this.employee && this.employee.employeeRoles.some((er) => approvedProjectRoles.includes(er.employeeRoleType.id));
            return;
        }
    }

    private async counter(observer: Subscriber<boolean>) {
        setTimeout(() => {

            this.count++;
            if (this.count < 200 && !this.employee) {
                this.counter(observer);
            } else {
                this.checkSecurity()
                this.complete(observer);
            }
        }, 10);
    }
}

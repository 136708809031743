import { Component, OnDestroy } from "@angular/core";
import { ApplicationinsightsAngularpluginErrorService, IErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { Subscription } from "dexie";
import { take } from "rxjs/operators";
import { SystemInformationKeys } from "shield.shared";
import { SyncVersionKeyNames } from "src/app/enums/sync-version-key-names";
import { AppStateService } from "src/app/services/app-state.service";
import { SystemInformationDelineationService } from "src/app/services/delineation-services/system-information-delineation.service";
import { SyncService } from "src/app/services/sync.service";

@Component({
    selector: "app-sync-gate",
    templateUrl: "./sync-gate.component.html",
    styleUrls: ["./sync-gate.component.scss"]
})

export class SyncGateComponent implements OnDestroy {

    public completionPercentage = 0;
    public statusMessage: string = "";
    private syncCompletionSubscription: Subscription;
    private syncLevelOneSubscription: Subscription;
    private syncInProgressSubscription: Subscription;

    readonly largeSyncKeys = [
        SyncVersionKeyNames.customer,
        SyncVersionKeyNames.wholesalerGroupProductCatalog,
        SyncVersionKeyNames.wholesalerProductCatalog,
        SyncVersionKeyNames.wholesaler
    ];

    constructor(
        private syncService: SyncService,
        public appStateService: AppStateService,
        private aiErrorService: ApplicationinsightsAngularpluginErrorService,
        private systemInformation: SystemInformationDelineationService
    ) {
        const existingMap = new Map<string, boolean>(JSON.parse(window.localStorage.getItem("initial-sync-array")));
        if (existingMap.size == 0) {
            this.syncService.firstSync = true;
        }

        this.syncLevelOneSubscription = this.syncService.observableSuccessfulLevelOneSync.pipe(
            take(1)
        ).subscribe(async () => {
            const shouldLogErrors = await this.systemInformation.getByKey(SystemInformationKeys.logAIErrorsToConsole);
            const errorHandler = (this.aiErrorService["errorServices"]).find((es: IErrorService) => (es as any)["name"] === "LogErrorsToConsole");
            if (errorHandler && shouldLogErrors?.values && shouldLogErrors.values.value !== "true") {
                this.aiErrorService.removeErrorHandler(errorHandler);
            }
            this.completionPercentage = 100;
            this.statusMessage = "Success! You have completed your initial sync."
        });

        this.syncInProgressSubscription = this.syncService.observableSyncVersionKeyInProgress.subscribe((keyName) => {
            if (keyName) {
                // we could get smart here but since this is a rough representation I opted for hard-coding this.
                // as of 2022 there are 14 initial syncs and each is weighted the same in terms of making the progress bar fill
                this.completionPercentage += 7;
                const readableKeyName = this.getReadableKeyName(keyName);
                const showLongWarning = this.largeSyncKeys.includes(keyName);
                this.statusMessage = `Syncing ${readableKeyName} data...${ showLongWarning ? " (this may take a while)" : ""}`;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.syncLevelOneSubscription && !this.syncLevelOneSubscription.closed) {
            this.syncLevelOneSubscription.unsubscribe();
        }

        if (this.syncCompletionSubscription && !this.syncCompletionSubscription.closed) {
            this.syncCompletionSubscription.unsubscribe();
        }

        if (this.syncInProgressSubscription && !this.syncInProgressSubscription.closed) {
            this.syncInProgressSubscription.unsubscribe();
        }
    }

    getErrorMessage(): string {
        return `${this.appStateService.errorCode ? "Error Code " + this.appStateService.errorCode + ": " : ""}${this.appStateService.errorMessage}`
    }

    private getReadableKeyName(keyName: SyncVersionKeyNames): string {
        switch (keyName) {
            case SyncVersionKeyNames.accountownership: return "account ownership"
            case SyncVersionKeyNames.area: return "area"
            case SyncVersionKeyNames.areaToZrt: return "area"
            case SyncVersionKeyNames.contact: return "contact"
            case SyncVersionKeyNames.contractTemplate: return "contract"
            case SyncVersionKeyNames.country: return "country"
            case SyncVersionKeyNames.county: return "county"
            case SyncVersionKeyNames.customerContract: return "contract"
            case SyncVersionKeyNames.customerContractPayment: return "contract"
            case SyncVersionKeyNames.customer: return "customer"
            case SyncVersionKeyNames.customerByProject: return "customer"
            case SyncVersionKeyNames.customerBySpecial: return "customer"
            case SyncVersionKeyNames.dayTimeEntries: return "time entry"
            case SyncVersionKeyNames.employee: return "employee"
            case SyncVersionKeyNames.notification: return "notification"
            case SyncVersionKeyNames.product: return "product"
            case SyncVersionKeyNames.project: return "project"
            case SyncVersionKeyNames.px3Rank: return "px3 rank"
            case SyncVersionKeyNames.px3IncentivePeriod: return "px3 rank"
            case SyncVersionKeyNames.customerPx3Rank: return "px3 rank"
            case SyncVersionKeyNames.registeredUser: return "user"
            case SyncVersionKeyNames.route: return "route"
            case SyncVersionKeyNames.stateCategory: return "state"
            case SyncVersionKeyNames.state: return "state"
            case SyncVersionKeyNames.stateLicense: return "license"
            case SyncVersionKeyNames.survey: return "survey"
            case SyncVersionKeyNames.systemInformation: return "system"
            case SyncVersionKeyNames.taxRate: return "tax"
            case SyncVersionKeyNames.timeEntry: return "time entry"
            case SyncVersionKeyNames.timeEntryType: return "time entry"
            case SyncVersionKeyNames.wholesalerGroup: return "wholesaler group"
            case SyncVersionKeyNames.wholesalerGroupMember: return "wholesaler group"
            case SyncVersionKeyNames.wholesalerGroupProductCatalog: return  "wholesaler product"
            case SyncVersionKeyNames.wholesalerProductCatalog: return "wholesaler product"
            case SyncVersionKeyNames.wholesaler: return "wholesaler"
            case SyncVersionKeyNames.zrtAssignment: return "ZRT"

        }
    }
}

import { CallTypes } from "shield.shared";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { DistributionActionItems } from "../stepper-call/stepper-call-enums/distribution-action-items";
export class CallDistributionViewModel extends BasicDialogViewModel {
    width = "75vw";
    height = "75vh";

    mode: DistributionActionItems;
    isOrderMode: boolean;
    callType: CallTypes;
}

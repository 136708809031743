<div class="row">
    <div class="col-12">
        <mat-card appearance="outlined">
            <table class="table table-bordered table-striped table-hover">
                <thead>
                    <tr>
                        <th colspan="3">
                            <div class="row">
                                <div class="col name-column-width no-padding padding-little-left">
                                    Cat
                                </div>
                                <div class="col center">
                                    <div class="row">
                                        <div class="col no-padding">
                                            Qty Ext
                                        </div>
                                    </div>
                                </div>
                                <div class="col no-padding padding-little-right">
                                    Price
                                </div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasState" colspan="2">
                            <div class="row">
                                <div class="col">
                                    State - {{ vm.stateName !== 'Kentucky' ? vm.stateName + '*' : vm.stateName }}
                                </div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasCounty" colspan="2" class="county-tax-width">
                            <div class="row">
                                <div class="col">County - {{ vm.county }}</div>
                            </div>
                        </th>
                        <th *ngIf="vm.hasCity" colspan="2" class="city-tax-width">
                            <div class="row">
                                <div class="col">City - {{ vm.city }}</div>
                            </div>
                        </th>
                        <th>
                            <div class="row">
                                <div class="col">Total</div>
                            </div>
                            <div class="row">
                                <div class="col">Tax</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let record of vm.records">
                        <td colspan="3" class="product-info">
                            <div class="row">
                                <div class="col">
                                    {{ record.category }}
                                </div>
                                <div class="col">
                                    {{ record.quantityExtention }}
                                </div>
                                <div class="col">
                                    {{ record.price | precisionCurrency }}
                                </div>
                            </div>
                        </td>
                        <tax-entry
                            *ngIf="vm.hasState"
                            [rate]="record.stateRate"
                            [tax]="record.stateTax"
                            [record]="record"
                            [calculationMethod]="record.stateCalculationMethod"
                        ></tax-entry>
                        <tax-entry
                            *ngIf="vm.hasCounty"
                            [rate]="record.countyRate"
                            [tax]="record.countyTax"
                            [record]="record"
                            [calculationMethod]="record.countyCalculationMethod"
                        ></tax-entry>
                        <tax-entry
                            *ngIf="vm.hasCity"
                            [rate]="record.cityRate"
                            [tax]="record.cityTax"
                            [record]="record"
                            [calculationMethod]="record.cityCalculationMethod"
                        ></tax-entry>
                        <td colspan="2" class="county-tax-width">
                            <div class="row">
                                <div *ngIf="!record.stateTax && record.stateTax !== 0
                                    && !record.cityTax && record.cityTax !== 0
                                    && !record.countyTax && record.countyTax !== 0" class="col"
                                >
                                    No Tax
                                </div>
                            </div>
                            <div *ngIf="record.stateTax || record.stateTax === 0
                                || record.countyTax || record.countyTax === 0
                                || record.cityTax || record.cityTax === 0" class="row"
                            >
                                <div class="col">
                                    <div>{{ record.totalTax | precisionCurrency }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card>
    </div>
</div>
<div class="row" *ngIf="vm.stateName !== 'Kentucky'">
    <div class="col">
        <div class="my-3 pull-left">
            <strong>*Note - State taxes here are advisory, as they are already included
            in the price.</strong>
        </div>
    </div>
</div>
<div class="row paid-via-row">
    <div class="col-3">
        <tax-payment-options
            *ngIf="vm.hasCounty"
            displayName = "County"
            [total]="vm.countyTotal"
            [(selectedPaymentType)]="vm.selectedCountyPaymentType"
            (selectedPaymentTypeChange)="vm.onSelectedCountyPaymentTypeChange()"
            [paymentOptions]="vm.paymentOptions"
        ></tax-payment-options>
    </div>
    <div class="col-3">
        <tax-payment-options
            *ngIf="vm.hasCity"
            displayName = "City"
            [total]="vm.cityTotal"
            [(selectedPaymentType)]="vm.selectedCityPaymentType"
            (selectedPaymentTypeChange)="vm.onSelectedCityPaymentTypeChange()"
            [paymentOptions]="vm.paymentOptions"
        ></tax-payment-options>
    </div>
    <div class="col-2">
        <mat-card appearance="outlined" class="no-padding extra-light-gray">
            <mat-card-content class="header">
                <b>Total Tax</b>
            </mat-card-content>
            <mat-card-content>
                <div class="mt-2">
                    {{ vm.stateTotal + vm.cityTotal + vm.countyTotal  | currency }}
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

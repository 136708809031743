<div *ngIf="customer && hasReceipts">
    <mat-card appearance="outlined" class="inner-card">
        <div id="retail-receipt">
            <app-swisher-retail-format *ngIf="!isTaxState && !isRetailDraft" #swisherRetailFormat
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="00"
            >
            </app-swisher-retail-format>
            <app-swisher-retail-narrow-tax-format *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow" #swisherRetailFormat
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="00">
            </app-swisher-retail-narrow-tax-format>
            <app-swisher-retail-wide-tax-format *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.wide" #swisherRetailFormat 
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="00">
            </app-swisher-retail-wide-tax-format>
            <app-retail-format-draft *ngIf="isRetailDraft" #retailDraft
                [customerReceiptAddress]="customerReceiptAddress" [signature]="signature"
                [signatureRetailWidth]="signatureRetailWidth" [signatureRetailHeight]="signatureRetailHeight"
                [selectedContact]="selectedContact" [isEasEmployee]="isEasEmployee"
                [isTaxState]="isTaxState" [selectedFormat]="selectedFormat" [call$]="call$"
                [activeProducts]="activeProducts$|async" [employee]="employee" [customer]="customer">
            </app-retail-format-draft>
            <app-eas-retail-format *ngIf="!isTaxState && isEasEmployee && !isRetailDraft" #easRetailFormat 
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01">
            </app-eas-retail-format>
            <app-eas-retail-narrow-tax-format #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow && isEasEmployee"
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01"
            >
            </app-eas-retail-narrow-tax-format>
            <app-eas-retail-wide-tax-format #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.wide && isEasEmployee" 
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01">
            </app-eas-retail-wide-tax-format >
            <app-eas-retail-wide-tax-format-swisher-rep #easRetailFormat *ngIf="isTaxState && selectedFormat===selectedFormats.wide && !isEasEmployee"
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01">
            </app-eas-retail-wide-tax-format-swisher-rep>
            <app-eas-retail-format-swisher-rep *ngIf="!isTaxState && !isEasEmployee && !isRetailDraft" #easRetailFormat
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01"
            >
            </app-eas-retail-format-swisher-rep>
            <app-eas-retail-narrow-tax-format-swisher-rep #easRetailFormat *ngIf="isTaxState && !isRetailDraft && selectedFormat===selectedFormats.narrow && !isEasEmployee"
                [activeProducts]="activeProducts$|async"
                [call]="call$"
                [signature]="signature"
                [contact]="selectedContact"
                [customer]="customer"
                [employee]="employee"
                receiptNumberExtention="01">
            </app-eas-retail-narrow-tax-format-swisher-rep>
        </div>
    </mat-card>
</div>

import { ElementRef } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faPrint } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import { Subscription } from "rxjs";
import { ContractPaymentMethods, ContractPaymentMethodLookup, ContractPaymentStatuses, ContractPaymentStatusLookup
    , ContractPaymentTypeLookup, ContractPaymentTypes, GenericLookup, newSequentialId } from "shield.shared";
import SignaturePad from "signature_pad";
import { ContactViewModel } from "src/app/accounts/contact/contact.viewmodel";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { ShareOptionsDialogComponent } from "src/app/dialogs/share-options-dialog/share-options-dialog.component";
import { ShareOptionsDialogViewmodel } from "src/app/dialogs/share-options-dialog/share-options-dialog.viewmodel";
import { CustomerContractPayment } from "src/app/entity-models/customer-contract-payment.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { DelineationStates } from "src/app/enums/delineation-states";
import { Helper } from "src/app/helpers/helper";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { CustomerContractDelineationService } from "src/app/services/delineation-services/customer-contract-delineation.service";
import { OverlayService } from "src/app/services/overlay.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";

export class PaymentStatusViewModel extends BasicDialogViewModel {
    payment: CustomerContractPayment;
    action: string;
    viewMode: boolean;
    agreement: ElementRef;

    faEnvelope: IconDefinition = faEnvelope;
    faPrint: IconDefinition = faPrint;

    contractPaymentStatuses = ContractPaymentStatuses;
    status: GenericLookup<ContractPaymentStatuses>;
    reason: string;
    amount: number;
    method: GenericLookup<ContractPaymentMethods>;
    type: GenericLookup<ContractPaymentTypes>;
    contact: ContactViewModel;
    swisherRep: string;
    contractNumber: string;

    statuses = new Array<GenericLookup<ContractPaymentStatuses>>();
    types = new Array<GenericLookup<ContractPaymentTypes>>();
    methods = new Array<GenericLookup<ContractPaymentMethods>>();
    contacts: ContactViewModel[];

    signaturePad: SignaturePad;
    canvasWidth = 600;
    canvasHeight = 150;
    canvasWidthViewMode = 200;
    canvasHeightViewMode = 50;

    dateFormat = MY_DATE_FORMATS.display.customDateInput;

    isConfirmed = false;

    fb: UntypedFormBuilder
    paymentStatusFormGroup: UntypedFormGroup;
    private statusSubscription: Subscription;
    private reasonSubscription: Subscription;
    private amountSubscription: Subscription;
    private methodSubscription: Subscription;
    private typeSubscription: Subscription;
    private repSubscription: Subscription;

    shareEmployees: Employee[];
    shareComments: string;

    customerContractDelineationService: CustomerContractDelineationService;
    overlayService: OverlayService;

    private shareOverlayRef: SwisherOverlayRef<
        ShareOptionsDialogViewmodel,
        ShareOptionsDialogComponent
    >;

    constructor(payment: CustomerContractPayment, contacts: ContactViewModel[], view: boolean,
        customerContractDelineationService: CustomerContractDelineationService,
        overlayService: OverlayService
    ) {
        super();
        this.payment = payment;
        this.contacts = contacts;
        this.viewMode = view;
        this.customerContractDelineationService = customerContractDelineationService;
        this.overlayService = overlayService;

        this.action = this.payment.id
            ? view
                ? "View"
                : "Edit"
            : "Add";

        this.status = payment.contractPaymentStatus ?? new GenericLookup<ContractPaymentStatuses>();
        this.reason = payment.reason ?? "";
        this.amount = payment.paymentAmount ?? null;
        this.method = payment.contractPaymentMethod ?? new GenericLookup<ContractPaymentMethods>();
        this.type = payment.contractPaymentType ?? new GenericLookup<ContractPaymentTypes>();
        this.contact = contacts.find((c) => c.id === payment.customerContactId) ?? null;

        this.headerLeftText = this.action + " Payment/Status";

        if (this.viewMode) {
            this.buttonLeftText = "Email Payment";
            this.buttonMiddleText = "Print Payment";
            this.buttonRightText = "Close";

            this.buttonLeftFunction = () => {
                const data: ShareOptionsDialogViewmodel = new ShareOptionsDialogViewmodel();
                data.shareEmployees = this.shareEmployees;
                data.confirmButtonText = "Share Payment";
                data.comments = this.shareComments;
                data.onClose = (closeData: ShareOptionsDialogViewmodel) => {
                    this.shareComments = closeData.comments;
                    this.shareEmployees = closeData.shareEmployees;
                }
                data.onSaveShare = async (
                    saveData: ShareOptionsDialogViewmodel
                ): Promise<void> => {
                    const ids = saveData.shareEmployees.map((e) => e.id);
                    let image = await this.generateContractPdfDataUrl(
                        this.agreement?.nativeElement
                    );
                    if (image) {
                        image = image.replace(/^[^,]+,/, '');
                        image = image.replace(/\s/g, '')
                        for (const employeeId of ids) {
                            await this.customerContractDelineationService.emailContract(employeeId, image, this.shareComments);
                        }
                    }
                }
                this.shareOverlayRef = this.overlayService.open(
                    ShareOptionsDialogComponent,
                    data
                );
            };
            this.buttonMiddleFunction = async () => {
                const image = await this.generateContractPdfDataUrl(
                    this.agreement?.nativeElement
                );
                if (image) {
                    Helper.addIFrameImage(document, [image]);
                }
            };

            this.buttonLeftDisabledFunction = () => false;
            this.buttonMiddleDisabledFunction = () => false;
            this.buttonRightDisabledFunction = () => false;
        } else {
            this.buttonLeftText = "Cancel";
            this.buttonRightText = "Save";
            this.buttonLeftDisabledFunction = () => false;
            this.buttonRightDisabledFunction = () => this.isSaveDisabled();
        }

        for(const status of ContractPaymentStatusLookup) {
            if (status.id !== ContractPaymentStatuses.Signed || view) {
                this.statuses.push(status);
            }
        }

        for(const type of ContractPaymentTypeLookup) {
            this.types.push(type)
        }

        for(const method of ContractPaymentMethodLookup) {
            if (method.id !== ContractPaymentMethods.NA || view) {
                this.methods.push(method)
            }
        }

        const compliantOrComplete =
            this.status?.id ===
            (ContractPaymentStatuses.Compliant ||
                ContractPaymentStatuses.Completed);

        this.fb = new UntypedFormBuilder;
        this.paymentStatusFormGroup = this.fb.group({
            statusControl: new UntypedFormControl("", Validators.required),
            reasonControl: new UntypedFormControl({value: this.reason ?? "", disabled: !this.status || compliantOrComplete}),
            amountControl: new UntypedFormControl({value: this.amount ?? "", disabled: !this.status || !compliantOrComplete}),
            typeControl:   new UntypedFormControl({value: this.type?.description ?? "", disabled: !this.status || compliantOrComplete}),
            methodControl: new UntypedFormControl({value: this.methods.find((m) => m.id === this.method?.id) ?? "", disabled: !this.status || !compliantOrComplete}),
            repControl:    new UntypedFormControl({value: this.contact ?? "", disabled: !this.status})

        });
        this.paymentStatusFormGroup.controls["statusControl"].setValue(this.statuses.find((s) => s.id === this.status?.id));
        this.statusSubscription = this.paymentStatusFormGroup.controls["statusControl"].valueChanges.subscribe((value) => {
            this.status = value;
            switch(this.status.id) {
                case ContractPaymentStatuses.Compliant:
                    this.paymentStatusFormGroup.controls["reasonControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["reasonControl"].disable();
                    this.paymentStatusFormGroup.controls["reasonControl"].updateValueAndValidity();
                    this.reason = null;

                    this.paymentStatusFormGroup.controls["amountControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["amountControl"].enable();
                    this.paymentStatusFormGroup.controls["amountControl"].updateValueAndValidity();
                    this.amount = this.paymentStatusFormGroup.controls["amountControl"].value;

                    this.paymentStatusFormGroup.controls["methodControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["methodControl"].enable();
                    this.paymentStatusFormGroup.controls["methodControl"].updateValueAndValidity();
                    this.method = this.paymentStatusFormGroup.controls["methodControl"].value;

                    this.paymentStatusFormGroup.controls["typeControl"].setValue(
                        ContractPaymentTypeLookup.find((t) => t.id === ContractPaymentTypes.Normal)?.description
                    );
                    this.paymentStatusFormGroup.controls["typeControl"].enable();
                    this.paymentStatusFormGroup.controls["typeControl"].updateValueAndValidity();

                    this.paymentStatusFormGroup.controls["repControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["repControl"].updateValueAndValidity();
                    break;
                case ContractPaymentStatuses.NonCompliant:
                    this.paymentStatusFormGroup.controls["reasonControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["reasonControl"].enable();
                    this.paymentStatusFormGroup.controls["reasonControl"].updateValueAndValidity();
                    this.reason = this.paymentStatusFormGroup.controls["reasonControl"].value;

                    this.paymentStatusFormGroup.controls["amountControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["amountControl"].disable();
                    this.paymentStatusFormGroup.controls["amountControl"].updateValueAndValidity();
                    this.amount = null;

                    this.paymentStatusFormGroup.controls["methodControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["methodControl"].disable();
                    this.paymentStatusFormGroup.controls["methodControl"].updateValueAndValidity();
                    this.method = ContractPaymentMethodLookup.find(
                        (m) => m.id === ContractPaymentMethods.NA
                    );

                    this.paymentStatusFormGroup.controls["typeControl"].setValue(
                        ContractPaymentTypeLookup.find((t) => t.id === ContractPaymentTypes.NA)?.description
                    );
                    this.paymentStatusFormGroup.controls["typeControl"].disable();
                    this.paymentStatusFormGroup.controls["typeControl"].updateValueAndValidity();

                    this.paymentStatusFormGroup.controls["repControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["repControl"].updateValueAndValidity();

                    break;
                case ContractPaymentStatuses.Completed:
                    this.paymentStatusFormGroup.controls["reasonControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["reasonControl"].disable();
                    this.paymentStatusFormGroup.controls["reasonControl"].updateValueAndValidity();
                    this.reason = null;

                    this.paymentStatusFormGroup.controls["methodControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["amountControl"].enable();
                    this.paymentStatusFormGroup.controls["amountControl"].updateValueAndValidity();
                    const amount = this.paymentStatusFormGroup.controls["amountControl"].value
                    this.amount = amount ? amount : 0;

                    this.paymentStatusFormGroup.controls["methodControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["methodControl"].disable();
                    this.paymentStatusFormGroup.controls["methodControl"].updateValueAndValidity();
                    this.method = ContractPaymentMethodLookup.find(
                        (m) => m.id === ContractPaymentMethods.NA
                    );

                    this.paymentStatusFormGroup.controls["typeControl"].setValue(
                        ContractPaymentTypeLookup.find((t) => t.id === ContractPaymentTypes.Bonus)?.description
                    );
                    this.paymentStatusFormGroup.controls["typeControl"].enable();
                    this.paymentStatusFormGroup.controls["typeControl"].updateValueAndValidity();

                    this.paymentStatusFormGroup.controls["repControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["repControl"].updateValueAndValidity();
                    break;
                case ContractPaymentStatuses.Canceled:
                    this.paymentStatusFormGroup.controls["reasonControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["reasonControl"].enable();
                    this.paymentStatusFormGroup.controls["reasonControl"].updateValueAndValidity();
                    this.reason = this.paymentStatusFormGroup.controls["reasonControl"].value;

                    this.paymentStatusFormGroup.controls["amountControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["amountControl"].disable();
                    this.paymentStatusFormGroup.controls["amountControl"].updateValueAndValidity();
                    this.amount = null;

                    this.paymentStatusFormGroup.controls["methodControl"].clearValidators();
                    this.paymentStatusFormGroup.controls["methodControl"].disable();
                    this.paymentStatusFormGroup.controls["methodControl"].updateValueAndValidity();
                    this.method = ContractPaymentMethodLookup.find(
                        (m) => m.id === ContractPaymentMethods.NA
                    );

                    this.paymentStatusFormGroup.controls["typeControl"].setValue(
                        ContractPaymentTypeLookup.find((t) => t.id === ContractPaymentTypes.NA)?.description
                    );
                    this.paymentStatusFormGroup.controls["typeControl"].disable();
                    this.paymentStatusFormGroup.controls["typeControl"].updateValueAndValidity();

                    this.paymentStatusFormGroup.controls["repControl"].setValidators([Validators.required]);
                    this.paymentStatusFormGroup.controls["repControl"].updateValueAndValidity();
                    break;
                default:
                    break;
            }
        });
        this.reasonSubscription = this.paymentStatusFormGroup.controls["reasonControl"].valueChanges.subscribe((value) => {
            this.reason = value;
        });
        this.amountSubscription = this.paymentStatusFormGroup.controls["amountControl"].valueChanges.subscribe((value) => {
            this.amount = value;
        });
        this.methodSubscription = this.paymentStatusFormGroup.controls["methodControl"].valueChanges.subscribe((value) => {
            this.method = value;
        });
        this.typeSubscription = this.paymentStatusFormGroup.controls["typeControl"].valueChanges.subscribe((value) => {
            this.type = this.types.find((t) => t.description === value);
        });
        this.repSubscription = this.paymentStatusFormGroup.controls["repControl"].valueChanges.subscribe((value) => {
            this.contact = value;
        });

        if (view) this.paymentStatusFormGroup.disable();
        if (!this.payment.id) this.payment.id = newSequentialId();
        if (!this.payment.paymentDate) this.payment.paymentDate = new Date();
    }

    unsubscribe(): void {
        if (this.statusSubscription && !this.statusSubscription.closed) {
            this.statusSubscription.unsubscribe();
        }
        if (this.reasonSubscription && !this.reasonSubscription.closed) {
            this.reasonSubscription.unsubscribe();
        }
        if (this.amountSubscription && !this.amountSubscription.closed) {
            this.amountSubscription.unsubscribe();
        }
        if (this.methodSubscription && !this.methodSubscription.closed) {
            this.methodSubscription.unsubscribe();
        }
        if (this.typeSubscription && !this.typeSubscription.closed) {
            this.typeSubscription.unsubscribe();
        }
        if (this.repSubscription && !this.repSubscription.closed) {
            this.repSubscription.unsubscribe();
        }
    }

    onClearSignature(): void {
        this.signaturePad.clear();
    }

    onBeginSignature(): void {
        this.signaturePad.penColor = "black";
    }

    isOfflineAndExistingGratis(): boolean {
        return !!this.payment.id &&
        this.customerContractDelineationService.getDelineationState() !== DelineationStates.online &&
        this.payment.contractPaymentMethod?.id === ContractPaymentMethods.Gratis
    }

    isSignatureInvalid(): boolean {
        // penColor is used here as a pseudo-touch flag. To emulate the functionality of the existing formControls, I set the pen color from rgb(1,1,1) to black
        // after the signature pad has been used.  This makes the save button available until pressed, at which point the signature pad will clearly signpost that
        // it is a required field if it has not already been filled in.
        return this.signaturePad?.isEmpty() && this.signaturePad?.penColor === "black";
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }

    isSaveDisabled(): boolean {
        return (this.paymentStatusFormGroup.controls["statusControl"].hasError("required") && this.paymentStatusFormGroup.controls["statusControl"].touched)
            || (this.paymentStatusFormGroup.controls["reasonControl"].hasError("required") && this.paymentStatusFormGroup.controls["reasonControl"].touched)
            || (this.paymentStatusFormGroup.controls["typeControl"].hasError("required") && this.paymentStatusFormGroup.controls["typeControl"].touched)
            || (this.paymentStatusFormGroup.controls["methodControl"].hasError("required") && this.paymentStatusFormGroup.controls["methodControl"].touched)
            || (this.paymentStatusFormGroup.controls["repControl"].hasError("required") && this.paymentStatusFormGroup.controls["repControl"].touched)
            || this.isSignatureInvalid()
            || this.isOfflineAndExistingGratis();
    }

    async generateContractPdfDataUrl(htmlElement: HTMLElement): Promise<string> {
        let rtn = "";
        const pdf = new jsPDF('p', 'mm', 'a4', true);

        await pdf.html(htmlElement, {
            margin: [5, 5, 12, 12],
            html2canvas: { logging: false, scale: 0.2 }
        }).then(() => {
            rtn = pdf.output("dataurlstring");
        });

        return rtn;
    }
}

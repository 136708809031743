import { EventEmitter, TemplateRef, Type } from "@angular/core";
import { Subject } from "rxjs";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";

export class BasicDialogViewModel {
    header: string;
    contentType: "template" | "string" | "component" = "component";
    content: string | TemplateRef<any> | Type<any>;
    contentString: string;
    contentTemplateRef: TemplateRef<any>;
    contentComponent: Type<any>;
    context: any;
    showFooter: boolean;
    iconButtons: HeaderButtonComponent[] = [];
    iconButtonClick$ = new Subject<HeaderButtonComponent>();
    headerLeftText: string;
    headerRightText: string;
    headerRightButtonText: string;
    headerRightButtonClick$ = new EventEmitter<void>();
    buttonLeftText: string;
    buttonLeftFunction: () => void;
    buttonLeftDisabledFunction: () => boolean;
    buttonMiddleText: string;
    buttonMiddleFunction: () => void;
    buttonMiddleDisabledFunction: () => boolean;
    buttonRightText: string;
    buttonRightFunction: () => void;
    buttonRightDisabledFunction: () => boolean;
    shouldClose: () => boolean = () => true;
    width: string;
    height: string;
    isConfirmed = false;
    blocking = false;
    backgroundColor?: string;

    get iconButtonClicked() {
        return this.iconButtonClick$.asObservable();
    }
}

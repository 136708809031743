<mat-card appearance="outlined" class="mt-3">
    <div *ngIf="validationErrorMessages && validationErrorMessages.length > 0">
        <div class="alert alert-danger" role="alert">
            <div class="row">
                <b>Validation Summary</b>
            </div>
            <div *ngFor="let message of validationErrorMessages">
                <mat-icon>warning</mat-icon>
                {{ message }}
            </div>
        </div>
    </div>
    <!-- Product Out -->
    <div>
        <div class="row">
            <div class="col-11">
                <h1 class="m-teal">Exchanges</h1>
            </div>
            <div class="col">
                <button appThrottleClick *ngIf="call?.shouldVisitTax"
                    [disabled]="isFinalWholesaleReceiptPrinted || isFinalRetailReceiptPrinted"
                    (throttledClick)="onOpenTaxCalculator()" class="btn sw-red-btn btn-sm" tabindex="0"
                    type="button">
                    Tax Calculator
                </button>
            </div>
        </div>
        <mat-card appearance="outlined" class="inner-card" *ngIf="stepperCallApplicationService.isExchangeOutBuilt">
            <div class="row bottom-margin">
                <div class="col">
                    <button appThrottleClick
                        [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                        class="btn sw-red-btn btn-sm" type="button" (throttledClick)="addProductOut()">
                        <span class="flaticon-add"></span> Product Out
                    </button>
                </div>
            </div>
            <div>
                <div class="overflow-auto">
                    <table *ngIf="
                            stepperCallApplicationService.callProductOutViewModels &&
                            stepperCallApplicationService.callProductOutViewModels.length > 0
                        " class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Description</th>
                                <th scope="col"></th>
                                <th scope="col">Unit QTY</th>
                                <th scope="col">Unit of Measure</th>
                                <th scope="col">Total Sticks</th>
                                <th scope="col">Retail Price</th>
                                <th scope="col">Retail Total</th>
                                <th scope="col">Wholesale Price</th>
                                <th scope="col">Wholesale Total</th>
                                <th scope="col">Unsalable</th>
                                <th scope="col">Remove Dist</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                                    let productOut of stepperCallApplicationService.callProductOutViewModels;
                                    let i = index
                                ">
                                <td class="icon-width">
                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                        class="btn btn-outline-danger btn-sm text-decoration-none"
                                        (click)="removeProductOut(productOut)" title="Remove Product">
                                        <span class="flaticon-delete"></span>
                                    </button>
                                </td>
                                <td class="description-fixed-width">
                                    {{ productOut?.product?.description }}
                                </td>
                                <td>
                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                        class="btn btn-outline-info btn-sm"
                                        (click)="
                                        copyProductOut(
                                                productOut
                                            )
                                        " title="Copy Product Metadata">
                                        <span class="material-icons">content_copy</span>
                                    </button>
                                </td>
                                <td class="counter-fixed-width">
                                    <div class="input-group counter-control">
                                        <div class="input-group-prepend">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                class="btn btn-outline-secondary btn-sm" type="button" (click)="
                                                    incrementProductOutQuantity(
                                                        productOut
                                                    )
                                                ">
                                                <span class="material-icons">add</span>
                                            </button>
                                        </div>
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            type="text" #productOutQty (mouseup)="select(productOutQty)" (blur)="
                                                validationProductOutQuantity(
                                                    productOut
                                                )
                                            " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                            aria-label="Example text with button addon"
                                            [(ngModel)]="productOut.quantity" />
                                        <div class="input-group-append">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                class="btn btn-outline-secondary btn-sm" type="button" (click)="
                                                    decrementProductOutQuantity(
                                                        productOut
                                                    )
                                                ">
                                                <span class="material-icons">remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="counter-fixed-width">
                                    <div class="input-group">
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>Unit of Measure</mat-label>
                                            <mat-select [value]="productOut.upc" (valueChange)="setProductOutUPC(productOut, $event)">
                                                <mat-option *ngFor="let upc of productOut.unitsOfMeasure" [value]="upc.upc">
                                                    {{upc.uom}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td class="dotted-right-border total-sticks-fixed-width">
                                    {{
                                    productOut.quantity * productOut.units

                                    }}
                                </td>
                                <td>
                                    <mat-form-field class="price-input" appearance="outline" color="accent">
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            matInput #productOutRetailPrice type="text"
                                            (mouseup)="select(productOutRetailPrice)"
                                            (blur)="validationProductOutRetailPrice(productOut);" currencyMask
                                            [(ngModel)]="productOut.price" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    {{
                                    productOut.quantity *
                                    productOut.price | currency
                                    }}
                                </td>
                                <td>
                                    <mat-form-field class="price-input" appearance="outline" color="accent">
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            matInput #productOutWholesalePrice type="text"
                                            (mouseup)="select(productOutWholesalePrice)"
                                            (blur)="validationProductOutWholesalePrice(productOut);" currencyMask
                                            [(ngModel)]="
                                                productOut.wholesalePrice
                                            " />
                                    </mat-form-field>
                                </td>
                                <td>
                                    {{
                                    productOut.quantity *
                                    productOut.wholesalePrice | currency
                                    }}
                                </td>
                                <td class="check-box">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            [indeterminate]="false" (change)="adjustSalable(productOut)"
                                            [(ngModel)]="productOut.unsalable" [color]="'warn'"></mat-checkbox>
                                    </span>
                                </td>
                                <td class="check-box">
                                    <span>
                                        <mat-checkbox
                                            [disabled]="isFinalRetailReceiptPrinted || isInProductIn(productOut)" tabindex="0"
                                            [indeterminate]="false" (change)="removeFromDist(productOut)"
                                            [color]="'warn'" [checked]="
                                                !productOut.isInDist
                                            "></mat-checkbox>
                                    </span>
                                </td>
                            </tr>
                            <tr *ngIf="
                            stepperCallApplicationService.callProductOutViewModels &&
                            stepperCallApplicationService.callProductOutViewModels.length > 0
                                " class="table-light">
                                <td colspan="6"></td>
                                <td colspan="2" class="text-right">
                                    <span>
                                        <small class="mr-2">Total Out Retail</small>
                                        <b>{{
                                            productOutRetailSum | currency
                                            }}</b>
                                    </span>
                                </td>
                                <td colspan="2" class="text-right">
                                    <span>
                                        <small class="mr-2">Total Out Wholesale</small>
                                        <b>{{
                                            productOutWholesaleSum | currency
                                            }}</b>
                                    </span>
                                </td>
                                <td colspan="2"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card>
    </div>

    <!-- Product In -->
    <div>
        <mat-card appearance="outlined" class="inner-card" *ngIf="stepperCallApplicationService.isExchangeinBuilt">
            <div class="row bottom-margin">
                <div class="col">
                    <button appThrottleClick
                    [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                        class="btn sw-red-btn btn-sm" type="button" (throttledClick)="addProductIn()">
                        <span class="flaticon-add"></span> Product In
                    </button>
                </div>
            </div>
            <div>
                <div class="overflow-auto">
                    <table *ngIf="
                    stepperCallApplicationService.callProductInViewModels &&
                    stepperCallApplicationService.callProductInViewModels.length > 0
                        " class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Description</th>
                                <th scope="col"></th>
                                <th scope="col">Unit QTY</th>
                                <th scope="col">Unit of Measure</th>
                                <th scope="col">Total Sticks</th>
                                <th scope="col">Retail Price</th>
                                <th scope="col">Retail Total</th>
                                <th scope="col">Wholesale Price</th>
                                <th scope="col">Wholesale Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="
                                    let productIn of stepperCallApplicationService.callProductInViewModels;
                                    let i = index
                                ">
                                <td class="icon-width">
                                    <button [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                        class="btn btn-outline-danger btn-sm" (click)="removeProductIn(productIn)"
                                        title="Remove Product">
                                        <span class="flaticon-delete"></span>
                                    </button>
                                </td>
                                <td class="description-fixed-width">
                                    {{ productIn?.product?.description }}
                                </td>
                                <td>
                                    <button [disabled]="isFinalRetailReceiptPrinted"
                                        class="btn btn-outline-info btn-sm"
                                        (click)="
                                    copyProductIn(productIn)
                                        " title="Copy Product Metadata">
                                        <span class="material-icons">content_copy</span>
                                </button>
                                </td>
                                <td class="counter-fixed-width">
                                    <div class="input-group counter-control">
                                        <div class="input-group-prepend">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                class="btn btn-outline-secondary btn-sm" type="button" (click)="
                                                    incrementProductInQuantity(
                                                        productIn
                                                    )
                                                ">
                                                <span class="material-icons">add</span>
                                            </button>
                                        </div>
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            type="text" #productInQty (mouseup)="select(productInQty)" (blur)="
                                                validationProductInQuantity(
                                                    productIn
                                                )
                                            " class="form-control form-control-sm mw-7rem-form" mask="000000"
                                            aria-label="Example text with button addon"
                                            [(ngModel)]="productIn.quantity" />
                                        <div class="input-group-append">
                                            <button [disabled]="isFinalRetailReceiptPrinted" tabindex="-1"
                                                class="btn btn-outline-secondary btn-sm" type="button" (click)="
                                                    decrementProductInQuantity(
                                                        productIn
                                                    )
                                                ">
                                                <span class="material-icons">remove</span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td class="counter-fixed-width">
                                    <div class="input-group">
                                        <mat-form-field appearance="outline" color="accent">
                                            <mat-label>Unit of Measure</mat-label>
                                            <mat-select [value]="productIn.upc" (valueChange)="setProductInUPC(productIn, $event)">
                                                <mat-option *ngFor="let upc of productIn.unitsOfMeasure" [value]="upc.upc">
                                                    {{upc.uom}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </td>
                                <td class="dotted-right-border total-sticks-fixed-width">
                                    {{
                                    productIn.quantity * productIn.units

                                    }}
                                </td>
                                <td>
                                    <mat-form-field class="price-input" appearance="outline" color="accent">
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            matInput #productInRetailPrice (mouseup)="select(productInRetailPrice)"
                                            (blur)="validationProductInRetailPrice(productIn)" currencyMask
                                            [(ngModel)]="productIn.price" />
                                    </mat-form-field>
                                </td>
                                <td>
                                    {{
                                    productIn.quantity *
                                    productIn.price | currency
                                    }}
                                </td>
                                <td>
                                    <mat-form-field class="price-input" appearance="outline" color="accent">
                                        <input
                                            [disabled]="isFinalRetailReceiptPrinted" tabindex="0"
                                            matInput #productInWholeSalePrice
                                            (mouseup)="select(productInWholeSalePrice)"
                                            (blur)="validationProductInWholesalePrice(productIn)" currencyMask
                                            [(ngModel)]="
                                                productIn.wholesalePrice
                                            " />
                                    </mat-form-field>
                                </td>
                                <td>
                                    {{
                                    productIn.quantity *
                                    productIn.wholesalePrice | currency
                                    }}
                                </td>
                            </tr>
                            <tr *ngIf="
                            stepperCallApplicationService.callProductInViewModels &&
                            stepperCallApplicationService.callProductInViewModels.length > 0
                                ">
                                <td colspan="5"></td>
                                <td colspan="2" class="text-right">
                                    <span>
                                        <small class="mr-2">Total In Retail</small>
                                        <b>{{
                                            productInRetailSum | currency
                                            }}</b>
                                    </span>
                                </td>
                                <td colspan="2" class="text-right">
                                    <span>
                                        <small class="mr-2">Total In Wholesale</small>
                                        <b>{{
                                            productInWholesaleSum | currency
                                            }}</b>
                                    </span>
                                </td>
                                <td colspan="2"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </mat-card>
    </div>

    <div class="row mt-3">
        <div class="col col-lg-9 col-sm-6">
            <div class="d-flex align-items-baseline justify-content-end mobile-col">
                <h3 class="mr-2" [ngClass]="
                        totalDiffRetail < 0 ? 'text-danger' : 'text-info'
                    ">
                    Total Diff Retail
                </h3>
                <h2 [ngClass]="
                        totalDiffRetail < 0 ? 'text-danger' : 'text-info'
                    ">
                    {{ totalDiffRetail | currency }}
                </h2>
            </div>
        </div>
        <div Class="col col-lg-3 col-sm-6">
            <div class="d-flex align-items-baseline justify-content-end mobile-col">
                <h3 class="mr-2 text-info">
                    Total Diff Wholesale
                </h3>
                <h2 class="text-info">
                    {{ totalDiffWholesale | currency }}
                </h2>
            </div>
        </div>
    </div>
</mat-card>

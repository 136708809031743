import { NgModule } from "@angular/core";
import { RouterModule, Routes, mapToCanActivate, mapToCanDeactivate } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { CanDeactivateGuard } from "../guards/can-deactivate.guard";
import { TimeLogComponent } from "../my-day/time-log/time-log.component";
import { GratisRequestFormComponent } from "./gratis/gratis-request-form/gratis-request-form.component";
import { GratisComponent } from "./gratis/gratis.component";
import { RouteManagementComponent } from "./route-management/route-management.component";
import { RouteDetailsComponent } from "./route-management/route-details/route-details.component";
import { RoutePx3SnapshotComponent } from "./route-management/route-px3-snapshot/route-px3-snapshot.component";
import { SharedModule } from "../shared/shared.module";

const routes: Routes = [
    {
        canActivate: mapToCanActivate([MsalGuard]),
        canDeactivate: mapToCanDeactivate([CanDeactivateGuard]),
        path: "my-day/time-log",
        component: TimeLogComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "my-day/route-management",
        component: RouteManagementComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "my-day/route-management/:routeId/details",
        component: RouteDetailsComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "my-day/route-management/:routeId/px3-snapshot",
        component: RoutePx3SnapshotComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "my-day/gratis",
        component: GratisComponent
    },
    {
        canActivate: mapToCanActivate([MsalGuard]),
        path: "my-day/gratis-request-form/:gratisId",
        component: GratisRequestFormComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MyDayRoutingModule {}

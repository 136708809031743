import { Component, ElementRef, ViewChild, signal } from '@angular/core';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
  selector: 'app-eas-retail-wide-tax-format',
  templateUrl: './eas-retail-wide-tax-format.component.html',
  styleUrls: ['./eas-retail-wide-tax-format.component.scss']
})
export class EasRetailWideTaxFormatComponent extends RetailBaseComponent {

    @ViewChild("originalEasRetailReceipt") originalEasRetailReceipt: ElementRef;

    printLogo = "/assets/img/easPrintLogo.jpg";
    subsidiary = signal(Subsidiary.EAS);
}

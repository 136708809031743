import { NgModule } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatChip } from "@angular/material/chips";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { SharedModule } from "../shared/shared.module";
import { OrdersListComponent } from "../orders/orders-list/orders-list.component";
import { TransactionsListComponent } from "./transactions/transactions-list/transactions-list.component";
import { SalesRoutingModule } from "./sales.routing.module";
import { InvoiceDetailsComponent } from "./transactions/invoice-details/invoice-details.component";
import { TransactionListZrtFilterService } from "./transactions/transactions-list/transaction-list-zrt-filter.service";
import { NgxCurrencyDirective } from "ngx-currency";
import { OrdersModule } from "../orders/orders.module";

@NgModule({
    declarations: [
        TransactionsListComponent,
        InvoiceDetailsComponent,
    ],
    imports: [
        FontAwesomeModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        SharedModule,
        SalesRoutingModule,
        NgxCurrencyDirective,
        OrdersModule,
    ],
    providers: [
        MatChip,
        TransactionListZrtFilterService
    ],
    exports: [
        OrdersListComponent,
    ]
})
export class SalesModule {}

import {
    CallCashProductDto,
    CallDto,
    CallExchangeInProductDto,
    CallExchangeOutProductDto,
    CallGratisProductDto,
    CallHistoryEntryDto,
    CallOrderProductDto,
    CallPictureDto,
    CallProductStatusDto,
    CallProductTaxDto,
    CallReceiptDto,
    CallReceiptLicenseDto,
    CallSurveyAnswerDto,
    CallTypes,
    ChainHqCallDto,
    CustomerTypeLookup,
    GenericLookup,
    newSequentialId,
    RefinerDto,
    RefinerGroupDto,
    RefinerLocation,
    RetailCallDto,
    RmWholesaleCallDto,
    WholesaleCallDto
} from "shield.shared";
import { Call } from "src/app/accounts/call-master/call-services/call.service";
import { CallCashProduct } from "src/app/entity-models/call-cash-product.entity";
import { CallProductTax } from "src/app/entity-models/call-cash-tax.entity";
import { CallExchangeInProduct } from "src/app/entity-models/call-exchange-in-product.entity";
import { CallExchangeOutProduct } from "src/app/entity-models/call-exchange-out-product.entity";
import { CallGratisProduct } from "src/app/entity-models/call-gratis-product.entity";
import { CallHistoryEntry } from "src/app/entity-models/call-history-entry.entity";
import { CallOrderProduct } from "src/app/entity-models/call-order-product.entity";
import { CallPicture } from "src/app/entity-models/call-picture.entity";
import { CallProductStatus } from "src/app/entity-models/call-product-status.entity";
import { CallReceipt } from "src/app/entity-models/call-receipt";
import { CallReceiptLicense } from "src/app/entity-models/call-receipt-license.entity";
import { CallSurveyAnswer } from "src/app/entity-models/call-survey-answer.entity";
import { ChainHqCall } from "src/app/entity-models/chain-hq-call.entity";
import { CustomerWholesaler } from "src/app/entity-models/customer-wholesaler.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { WholesaleCall } from "src/app/entity-models/wholesale-call.entity";
import { ContactConverterService } from "./contact-converter.service";
import { RefinerConverterService } from "./refiner-converter.service";
import cloneDeep from "lodash-es/cloneDeep";
import { CallOrderDate } from "src/app/entity-models/call-order-date.entity";
import { Helper } from "src/app/helpers/helper";

export class CallConverterService {
    static callDtoToCall(dto: CallDto): Call {
        if (dto) {
            let result: Call;

            switch (dto.callType) {
                case CallTypes.retail:
                    result = new RetailCall();
                    result.id = dto.id;
                    result.customerId = dto.customerId;
                    result.startTime = dto.startTime
                        ? new Date(dto.startTime)
                        : null;
                    result.stopTime = dto.stopTime
                        ? new Date(dto.stopTime)
                        : null;
                    result.callProductStatus = (dto.callProductStatus ?? []).map((ps) =>
                        CallConverterService.callProductStatusDtoToCallProductStatus(
                            ps
                        )
                    );
                    result.cashProducts = dto.cashProducts?.map((p) =>
                        CallConverterService.callCashProductDtoToEntity(p)
                    );
                    result.gratisProducts = dto.gratisProducts?.map((p) =>
                        CallConverterService.callGratisProductDtoToEntity(p)
                    );
                    result.orderProducts = dto.orderProducts?.map((p) =>
                        CallConverterService.callOrderProductDtoToEntity(p)
                    );
                    result.exchangeInProducts = dto.exchangeInProducts?.map(
                        (p) =>
                            CallConverterService.callExchangeInProductDtoToEntity(
                                p
                            )
                    );
                    result.exchangeOutProducts = dto.exchangeOutProducts?.map(
                        (p) =>
                            CallConverterService.callExchangeOutProductDtoToEntity(
                                p
                            )
                    );
                    result.callPictures = dto.callPictures?.map((p) =>
                        CallConverterService.callPictureDtoToEntity(p)
                    );
                    result.callReceipts = dto.callReceipts?.map((cr) =>
                        CallConverterService.callReceiptDtoToEntity(cr)
                    );
                    result.surveyAnswers = dto.callSurveyAnswers?.map((sa) =>
                        CallConverterService.callSurveyAnswerDtoToEntity(sa)
                    );
                    result.selectedContact = dto.selectedContact
                        ? ContactConverterService.contactDtoToContact(
                              dto.selectedContact
                          )
                        : null;
                    result.inDistProductIds = dto.inDistProductIds;
                    result.unsalableProductIds = dto.unsalableProductIds;
                    result.closingNotes = dto.closingNotes;
                    result.createdUtcDateTime = dto.createdUtcDateTime
                        ? new Date(dto.createdUtcDateTime)
                        : null;
                    result.createdUserId = dto.createdUserId;
                    result.createdUserFullName = dto.createdUserFullName;
                    result.createdUserZrt = dto.createdUserZrt;
                    result.modifiedUtcDateTime = dto.modifiedUtcDateTime
                        ? new Date(dto.modifiedUtcDateTime)
                        : null;
                    result.modifiedUserId = dto.modifiedUserId;
                    result.modifiedUserFullName = dto.modifiedUserFullName;
                    result.modifiedUserZrt = dto.modifiedUserZrt;
                    result.rowversion = dto.rowversion;
                    result.callType = dto.callType;
                    result.isDeleted = dto.isDeleted;
                    break;

                    case CallTypes.wholesale:
                        result = new WholesaleCall();
                        dto = dto as WholesaleCallDto;
                        result.id = dto.id;
                        result.customerId = dto.customerId;
                        result.startTime = dto.startTime
                            ? new Date(dto.startTime)
                            : null;
                        result.stopTime = dto.stopTime
                            ? new Date(dto.stopTime)
                            : null;
                        result.callPictures = dto.callPictures?.map((p) =>
                            CallConverterService.callPictureDtoToEntity(p)
                        );
                        result.surveyAnswers = dto.callSurveyAnswers?.map((sa) =>
                            CallConverterService.callSurveyAnswerDtoToEntity(sa)
                        );
                        result.closingNotes = dto.closingNotes;
                        result.createdUtcDateTime = dto.createdUtcDateTime
                            ? new Date(dto.createdUtcDateTime)
                            : null;
                        result.createdUserId = dto.createdUserId;
                        result.createdUserFullName = dto.createdUserFullName;
                        result.createdUserZrt = dto.createdUserZrt;
                        result.modifiedUtcDateTime = dto.modifiedUtcDateTime
                            ? new Date(dto.modifiedUtcDateTime)
                            : null;
                        result.modifiedUserId = dto.modifiedUserId;
                        result.isReturns = dto.isReturns;
                        result.isPurchase = dto.isPurchase;
                        result.isSwisherDay = dto.isSwisherDay;
                        result.isTradeShow = dto.isTradeShow;
                        result.purchaseInvoice = dto.purchaseInvoice;
                        result.creditMemo = dto.creditMemo;
                        result.billThrough = dto.billThrough;
                        result.purchaseAmount = dto.purchaseAmount;
                        result.creditAmount = dto.creditAmount;
                        result.rowversion = dto.rowversion;
                        result.callType = dto.callType;
                        result.isDeleted = dto.isDeleted;
                        break;

                case CallTypes.rmWholesale:
                    result = new RmWholesaleCall();
                    result.id = dto.id;
                    result.customerId = dto.customerId;
                    result.startTime = dto.startTime
                        ? new Date(dto.startTime)
                        : null;
                    result.stopTime = dto.stopTime
                        ? new Date(dto.stopTime)
                        : null;
                    result.callProductStatus = dto.callProductStatus.map((ps) =>
                        CallConverterService.callProductStatusDtoToCallProductStatus(
                            ps
                        )
                    );
                    result.cashProducts = dto.cashProducts.map((p) =>
                        CallConverterService.callCashProductDtoToEntity(p)
                    );
                    result.gratisProducts = dto.gratisProducts.map((p) =>
                        CallConverterService.callGratisProductDtoToEntity(p)
                    );
                    result.orderProducts = dto.orderProducts.map((p) =>
                        CallConverterService.callOrderProductDtoToEntity(p)
                    );
                    result.exchangeInProducts = dto.exchangeInProducts.map(
                        (p) =>
                            CallConverterService.callExchangeInProductDtoToEntity(
                                p
                            )
                    );
                    result.exchangeOutProducts = dto.exchangeOutProducts.map(
                        (p) =>
                            CallConverterService.callExchangeOutProductDtoToEntity(
                                p
                            )
                    );
                    result.callPictures = dto.callPictures?.map((p) =>
                        CallConverterService.callPictureDtoToEntity(p)
                    );
                    result.callReceipts = dto.callReceipts?.map((cr) =>
                        CallConverterService.callReceiptDtoToEntity(cr)
                    );
                    result.surveyAnswers = dto.callSurveyAnswers?.map((sa) =>
                        CallConverterService.callSurveyAnswerDtoToEntity(sa)
                    );
                    result.selectedContact = dto.selectedContact
                        ? ContactConverterService.contactDtoToContact(
                                dto.selectedContact
                            )
                        : null;
                    result.inDistProductIds = dto.inDistProductIds;
                    result.unsalableProductIds = dto.unsalableProductIds;
                    result.closingNotes = dto.closingNotes;
                    result.createdUtcDateTime = dto.createdUtcDateTime
                        ? new Date(dto.createdUtcDateTime)
                        : null;
                    result.createdUserId = dto.createdUserId;
                    result.createdUserFullName = dto.createdUserFullName;
                    result.createdUserZrt = dto.createdUserZrt;
                    result.modifiedUtcDateTime = dto.modifiedUtcDateTime
                        ? new Date(dto.modifiedUtcDateTime)
                        : null;
                    result.modifiedUserId = dto.modifiedUserId;
                    result.modifiedUserFullName = dto.modifiedUserFullName;
                    result.modifiedUserZrt = dto.modifiedUserZrt;
                    result.rowversion = dto.rowversion;
                    result.callType = dto.callType;
                    result.isDeleted = dto.isDeleted;
                    break;
                case CallTypes.chainHq:
                    result = new ChainHqCall();
                    result.id = dto.id;
                    result.customerId = dto.customerId;
                    result.startTime = dto.startTime
                        ? new Date(dto.startTime)
                        : null;
                    result.stopTime = dto.stopTime
                        ? new Date(dto.stopTime)
                        : null;
                    result.orderProducts = dto.orderProducts.map((p) =>
                        CallConverterService.callOrderProductDtoToEntity(p)
                    );
                    result.surveyAnswers = dto.callSurveyAnswers?.map((sa) =>
                        CallConverterService.callSurveyAnswerDtoToEntity(sa)
                    );
                    result.closingNotes = dto.closingNotes;
                    result.createdUtcDateTime = dto.createdUtcDateTime
                        ? new Date(dto.createdUtcDateTime)
                        : null;
                    result.createdUserId = dto.createdUserId;
                    result.createdUserFullName = dto.createdUserFullName;
                    result.createdUserZrt = dto.createdUserZrt;
                    result.modifiedUtcDateTime = dto.modifiedUtcDateTime
                        ? new Date(dto.modifiedUtcDateTime)
                        : null;
                    result.modifiedUserId = dto.modifiedUserId;
                    result.rowversion = dto.rowversion;
                    result.callType = dto.callType;
                    result.isDeleted = dto.isDeleted;
                    break;
                default:
                    break;
            }

            result.px3Rank = dto.px3Rank;
            result.px3RankId = dto.px3RankId;

            return result;
        }
    }

    static callProductStatusDtoToCallProductStatus(
        dto: CallProductStatusDto
    ): CallProductStatus {
        return {
            ...dto,
            approvedProduct: dto.approvedProduct ? new Date(dto.approvedProduct) : null,
        };
    }

    static callReceiptLicenseDtoToCallReceiptLicense(
        dto: CallReceiptLicenseDto
    ): CallReceiptLicense {
        return {
            ...dto
        };
    }

    static callReceiptDtoToEntity(callReceipt: CallReceiptDto): CallReceipt {
        return {
            ...callReceipt,
            callReceiptLicenses: callReceipt.callReceiptLicenses.map((crl) =>
                CallConverterService.callReceiptLicenseDtoToCallReceiptLicense(
                    crl
                )
            )
        };
    }

    static callSurveyAnswerDtoToEntity(
        dto: CallSurveyAnswerDto
    ): CallSurveyAnswer {
        return {
            ...dto
        };
    }

    static callCashProductDtoToEntity(
        dto: CallCashProductDto
    ): CallCashProduct {
        return {
            id: dto.id,
            productId: dto.productId,
            discount: dto.discount,
            price: dto.price,
            quantity: dto.quantity,
            units: dto.units,
            upc: dto.upc,
            callProductTax: this.callProductTaxDtoToCallProductTax(
                dto.callProductTax
            )
        };
    }

    static callProductTaxDtoToCallProductTax(
        dto: CallProductTaxDto
    ): CallProductTax {
        return {
            ...dto
        };
    }

    static callGratisProductDtoToEntity(
        dto: CallGratisProductDto
    ): CallGratisProduct {
        return {
            id: dto.id,
            productId: dto.productId,
            value: dto.value,
            quantity: dto.quantity,
            units: dto.units,
            upc: dto.upc,
            callProductTax: this.callProductTaxDtoToCallProductTax(
                dto.callProductTax
            )
        };
    }

    static callOrderProductDtoToEntity(
        dto: CallOrderProductDto
    ): CallOrderProduct {

        const entity = new CallOrderProduct();

        entity.id = dto.id;
        entity.productId = dto.productId;
        entity.quantity = dto.quantity;
        entity.storeCount = dto.storeCount;
        entity.uin = dto.uin;
        entity.units = dto.units;
        entity.upc = dto.upc;
        entity.wholesalerCustomerId = dto.wholesalerCustomerId;

        const callOrderDate = new CallOrderDate();
        callOrderDate.orderDate = dto.orderDate ? new Date(dto.orderDate) : new Date();
        callOrderDate.quantity = entity.quantity;
        entity.callOrderDates = [callOrderDate];

        return entity;
    }

    static callExchangeInProductDtoToEntity(
        dto: CallExchangeInProductDto
    ): CallExchangeInProduct {
        return {
            id: dto.id,
            productId: dto.productId,
            quantity: dto.quantity,
            units: dto.units,
            price: dto.price,
            wholesalePrice: dto.wholesalePrice,
            upc: dto.upc,
            callProductTax: this.callProductTaxDtoToCallProductTax(
                dto.callProductTax
            )
        };
    }

    static callExchangeOutProductDtoToEntity(
        dto: CallExchangeOutProductDto
    ): CallExchangeOutProduct {
        return {
            id: dto.id,
            productId: dto.productId,
            quantity: dto.quantity,
            units: dto.units,
            price: dto.price,
            wholesalePrice: dto.wholesalePrice,
            upc: dto.upc,
            callProductTax: this.callProductTaxDtoToCallProductTax(
                dto.callProductTax
            )
        };
    }

    static callPictureDtoToEntity(dto: CallPictureDto): CallPicture {
        return {
            id: dto.id,
            type: dto.pictureType,
            tags: dto.tags,
            blobPath: dto.blobPath
        };
    }

    static callToCallDto(
        entity: Call,
        customerWholesalers: CustomerWholesaler[]
    ): CallDto {
        let dto: CallDto;
        switch (entity.callType) {
            case CallTypes.retail:
                dto = new RetailCallDto();
                dto.id = entity.id;
                dto.customerId = entity.customerId;
                dto.startTime = entity.startTime
                    ? new Date(entity.startTime).toISOString()
                    : null;
                dto.stopTime = entity.stopTime
                    ? new Date(entity.stopTime).toISOString()
                    : null;
                dto.cashProducts = entity.cashProducts.map((p) =>
                    CallConverterService.callCashProductToDto(p)
                );
                dto.gratisProducts = entity.gratisProducts.map((p) =>
                    CallConverterService.callGratisProductToDto(p)
                );
                dto.orderProducts = CallConverterService.callOrderProductToDto(entity.orderProducts, customerWholesalers);
                dto.exchangeInProducts = entity.exchangeInProducts.map((p) =>
                    CallConverterService.callExchangeInProductToDto(p)
                );
                dto.exchangeOutProducts = entity.exchangeOutProducts.map((p) =>
                    CallConverterService.callExchangeOutProductToDto(p)
                );
                dto.callPictures = entity.callPictures?.map((p) =>
                    CallConverterService.callPictureToDto(p)
                );
                dto.callReceipts = entity.callReceipts?.map((cr) =>
                    CallConverterService.callReceiptToDto(cr)
                );
                dto.selectedContact = entity.selectedContact
                    ? ContactConverterService.contactToContactDto(
                          entity.selectedContact
                      )
                    : null;
                dto.inDistProductIds = entity.inDistProductIds;
                dto.unsalableProductIds = entity.unsalableProductIds;
                dto.closingNotes = entity.closingNotes;
                dto.createdUtcDateTime = entity.createdUtcDateTime
                    ? new Date(entity.createdUtcDateTime).toISOString()
                    : null;
                dto.createdUserId = entity.createdUserId;
                dto.createdUserFullName = entity.createdUserFullName;
                dto.createdUserZrt = entity.createdUserZrt;
                dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
                    ? new Date(entity.modifiedUtcDateTime).toISOString()
                    : null;
                dto.modifiedUserId = entity.modifiedUserId;
                dto.modifiedUserFullName = entity.modifiedUserFullName;
                dto.modifiedUserZrt = entity.modifiedUserZrt;
                dto.rowversion = entity.rowversion;
                dto.callType = entity.callType;
                dto.isDeleted = entity.isDeleted;

                dto.callProductStatus = entity.callProductStatus.map((ps) =>
                    CallConverterService.callProductStatusToCallProductStatusDto(
                        ps
                    )
                );

                dto.callSurveyAnswers = entity.surveyAnswers.map((sa) =>
                    CallConverterService.callSurveyAnswerToDto(sa)
                );
                break;

            case CallTypes.rmWholesale:
                dto = new RmWholesaleCallDto();
                dto.id = entity.id;
                dto.customerId = entity.customerId;
                dto.startTime = entity.startTime
                    ? new Date(entity.startTime).toISOString()
                    : null;
                dto.stopTime = entity.stopTime
                    ? new Date(entity.stopTime).toISOString()
                    : null;
                dto.callProductStatus = entity.callProductStatus.map((ps) =>
                    CallConverterService.callProductStatusToCallProductStatusDto(
                        ps
                    )
                );
                dto.cashProducts = entity.cashProducts.map((p) =>
                    CallConverterService.callCashProductToDto(p)
                );
                dto.gratisProducts = entity.gratisProducts.map((p) =>
                    CallConverterService.callGratisProductToDto(p)
                );
                dto.orderProducts = CallConverterService.callOrderProductToDto(entity.orderProducts, customerWholesalers);

                dto.exchangeInProducts = entity.exchangeInProducts.map((p) =>
                    CallConverterService.callExchangeInProductToDto(p)
                );
                dto.exchangeOutProducts = entity.exchangeOutProducts.map((p) =>
                    CallConverterService.callExchangeOutProductToDto(p)
                );
                dto.callPictures = entity.callPictures?.map((p) =>
                    CallConverterService.callPictureToDto(p)
                );
                dto.callReceipts = entity.callReceipts?.map((cr) =>
                    CallConverterService.callReceiptToDto(cr)
                );
                dto.selectedContact = entity.selectedContact
                    ? ContactConverterService.contactToContactDto(
                          entity.selectedContact
                      )
                    : null;
                dto.inDistProductIds = entity.inDistProductIds;
                dto.unsalableProductIds = entity.unsalableProductIds;
                dto.closingNotes = entity.closingNotes;
                dto.createdUtcDateTime = entity.createdUtcDateTime
                    ? new Date(entity.createdUtcDateTime).toISOString()
                    : null;
                dto.createdUserId = entity.createdUserId;
                dto.createdUserFullName = entity.createdUserFullName;
                dto.createdUserZrt = entity.createdUserZrt;
                dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
                    ? new Date(entity.modifiedUtcDateTime).toISOString()
                    : null;
                dto.modifiedUserId = entity.modifiedUserId;
                dto.modifiedUserFullName = entity.modifiedUserFullName;
                dto.modifiedUserZrt = entity.modifiedUserZrt;
                dto.rowversion = entity.rowversion;
                dto.callType = entity.callType;
                dto.isDeleted = entity.isDeleted;

                dto.callProductStatus = entity.callProductStatus.map((ps) =>
                    CallConverterService.callProductStatusToCallProductStatusDto(
                        ps
                    )
                );

                dto.callSurveyAnswers = entity.surveyAnswers.map((sa) =>
                    CallConverterService.callSurveyAnswerToDto(sa)
                );
                break;

            case CallTypes.wholesale:
                dto = new WholesaleCallDto();
                dto.id = entity.id;
                dto.customerId = entity.customerId;
                dto.startTime = entity.startTime
                    ? new Date(entity.startTime).toISOString()
                    : null;
                dto.stopTime = entity.stopTime
                    ? new Date(entity.stopTime).toISOString()
                    : null;
                dto.callPictures = entity.callPictures?.map((p) =>
                    CallConverterService.callPictureToDto(p)
                );
                dto.closingNotes = entity.closingNotes;
                dto.createdUtcDateTime = entity.createdUtcDateTime
                    ? new Date(entity.createdUtcDateTime).toISOString()
                    : null;
                dto.createdUserId = entity.createdUserId;
                dto.createdUserFullName = entity.createdUserFullName;
                dto.createdUserZrt = entity.createdUserZrt;
                dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
                    ? new Date(entity.modifiedUtcDateTime).toISOString()
                    : null;
                dto.modifiedUserId = entity.modifiedUserId;
                dto.isReturns = entity.isReturns;
                dto.isPurchase = entity.isPurchase;
                dto.isSwisherDay = entity.isSwisherDay;
                dto.isTradeShow = entity.isTradeShow;
                dto.purchaseInvoice = entity.purchaseInvoice;
                dto.creditMemo = entity.creditMemo;
                dto.billThrough = entity.billThrough;
                dto.purchaseAmount = entity.purchaseAmount;
                dto.creditAmount = entity.creditAmount;
                dto.rowversion = entity.rowversion;
                dto.callType = entity.callType;
                dto.isDeleted = entity.isDeleted;
                break;
            case CallTypes.chainHq:
                dto = new ChainHqCallDto();
                dto.id = entity.id;
                dto.customerId = entity.customerId;
                dto.startTime = entity.startTime
                    ? new Date(entity.startTime).toISOString()
                    : null;
                dto.stopTime = entity.stopTime
                    ? new Date(entity.stopTime).toISOString()
                    : null;
                dto.orderProducts = CallConverterService.callOrderProductToDto(entity.orderProducts, customerWholesalers);
                dto.closingNotes = entity.closingNotes;
                dto.createdUtcDateTime = entity.createdUtcDateTime
                    ? new Date(entity.createdUtcDateTime).toISOString()
                    : null;
                dto.createdUserId = entity.createdUserId;
                dto.createdUserFullName = entity.createdUserFullName;
                dto.createdUserZrt = entity.createdUserZrt;
                dto.modifiedUtcDateTime = entity.modifiedUtcDateTime
                    ? new Date(entity.modifiedUtcDateTime).toISOString()
                    : null;
                dto.modifiedUserId = entity.modifiedUserId;
                dto.rowversion = entity.rowversion;
                dto.callType = entity.callType;
                dto.isDeleted = entity.isDeleted;
                break;

            default:
                break;
        }
        return dto;
    }

    static callProductStatusToCallProductStatusDto(
        entity: CallProductStatus
    ): CallProductStatusDto {
        return {
            ...entity,
            approvedProduct: entity.approvedProduct ? new Date(entity.approvedProduct).toISOString() : null,
        };
    }

    static callReceiptLicenseToCallReceiptLicenseDto(
        entity: CallReceiptLicense
    ): CallReceiptLicenseDto {
        return {
            ...entity
        };
    }

    static callReceiptToDto(callReceipt: CallReceipt): CallReceiptDto {
        return {
            ...callReceipt,
            callReceiptLicenses: callReceipt.callReceiptLicenses.map((crl) =>
                CallConverterService.callReceiptLicenseToCallReceiptLicenseDto(
                    crl
                )
            )
        };
    }

    static callCashProductToDto(entity: CallCashProduct): CallCashProductDto {
        return {
            id: entity.id,
            productId: entity.productId,
            discount: entity.discount,
            price: entity.price,
            quantity: entity.quantity,
            units: entity.units,
            upc: entity.upc,
            callProductTax: entity.callProductTax
                ? this.callProductTaxToCallProductTaxDto(entity.callProductTax)
                : null
        };
    }

    static callProductTaxToCallProductTaxDto(
        entity: CallProductTax
    ): CallProductTaxDto {
        return {
            ...entity
        };
    }

    static callGratisProductToDto(
        entity: CallGratisProduct
    ): CallGratisProductDto {
        return {
            id: entity.id,
            productId: entity.productId,
            value: entity.value,
            quantity: entity.quantity,
            units: entity.units,
            upc: entity.upc,
            callProductTax: entity.callProductTax
                ? this.callProductTaxToCallProductTaxDto(entity.callProductTax)
                : null
        };
    }

    static callOrderProductToDto(
        entities: CallOrderProduct[],
        customerWholesalers: CustomerWholesaler[]
    ): CallOrderProductDto[] {

        const split = new Array<CallOrderProduct>();
        const today = new Date();
        today.setHours(0, 0, 0, 999);
        for (const callOrderProduct of entities) {

            if (!callOrderProduct.callOrderDates?.length) {

                const orderDate = new CallOrderDate();
                orderDate.orderDate = today;
                orderDate.quantity = callOrderProduct.quantity;
                callOrderProduct.callOrderDates = [orderDate];
                split.push(callOrderProduct);
                continue;
            } else if (callOrderProduct.callOrderDates.length === 1) {

                split.push(callOrderProduct);
                continue;
            } else {

                for (const orderDate of callOrderProduct.callOrderDates ) {

                    if (orderDate.quantity > 0) {
                        const newCallOrderProduct = cloneDeep(callOrderProduct);
                        newCallOrderProduct.quantity = orderDate.quantity;
                        newCallOrderProduct.callOrderDates = [orderDate];
                        split.push(newCallOrderProduct);
                    }
                }
            }
        }

        const rtn = new Array<CallOrderProductDto>();

        for (const entity of split) {

            const dto = new CallOrderProductDto();
            dto.id = newSequentialId();
            dto.productId = entity.productId;
            dto.quantity = entity.quantity;
            dto.storeCount = entity.storeCount;
            dto.uin = entity.uin;
            dto.units = entity.units;
            dto.upc = entity.upc;
            dto.wholesalerCustomerId = entity.wholesalerCustomerId;
            dto.wholesalerCustomerCode = customerWholesalers.find((v) => v.wholesalerId === entity.wholesalerCustomerId)?.customerCode ?? "";
            dto.orderDate = (entity.callOrderDates[0]?.orderDate ?? new Date()).toISOString();
            dto.projectId = entity.selectedProject?.id;
            rtn.push(dto);
        }

        return rtn;
    }

    static callExchangeInProductToDto(
        entity: CallExchangeInProduct
    ): CallExchangeInProductDto {
        return {
            id: entity.id,
            productId: entity.productId,
            quantity: entity.quantity,
            units: entity.units,
            price: entity.price,
            wholesalePrice: entity.wholesalePrice,
            upc: entity.upc,
            callProductTax: entity.callProductTax
                ? this.callProductTaxToCallProductTaxDto(entity.callProductTax)
                : null
        };
    }

    static callExchangeOutProductToDto(
        entity: CallExchangeOutProduct
    ): CallExchangeOutProductDto {
        return {
            id: entity.id,
            productId: entity.productId,
            quantity: entity.quantity,
            units: entity.units,
            price: entity.price,
            wholesalePrice: entity.wholesalePrice,
            upc: entity.upc,
            callProductTax: entity.callProductTax
                ? this.callProductTaxToCallProductTaxDto(entity.callProductTax)
                : null
        };
    }

    static callPictureToDto(entity: CallPicture): CallPictureDto {
        return {
            id: entity.id,
            pictureType: entity.type,
            tags: entity.tags,
            blobPath: entity.blobPath
        };
    }

    static callSurveyAnswerToDto(
        entity: CallSurveyAnswer
    ): CallSurveyAnswerDto {
        return {
            ...entity
        };
    }

    static callHistoryEntryDtoToCallHistoryEntry(
        dto: CallHistoryEntryDto
    ): CallHistoryEntry {
        const entry = new CallHistoryEntry();

        entry.id = dto.id;
        entry.customerId = dto.customerId;
        entry.startTime = dto.startTime ? new Date(dto.startTime) : null,
        entry.stopTime = dto.stopTime ? new Date(dto.stopTime) : null
        entry.createdById = dto.createdById;
        entry.createdByName = dto.createdByName;
        entry.durationMins = dto.durationMins;
        entry.closingNotes = dto.closingNotes;
        entry.customerName = dto.customerName;
        entry.customerNumber = dto.customerNumber;
        entry.address = dto.address;
        entry.city = dto.city;
        entry.state = dto.state;
        entry.county = dto.county;
        entry.zip = dto.zip;
        entry.zrt = dto.zrt;
        entry.customerType = GenericLookup.getFromLookup(CustomerTypeLookup, dto.customerType);
        entry.callType = dto.callType;
        entry.hasPictures = dto.hasPictures;
        entry.px3RankId = dto.px3RankId;
        entry.pictures = dto.pictures;
        entry.hasBeforePicture = dto.hasBeforePicture;
        entry.hasAfterPicture = dto.hasAfterPicture;

        return entry;
    }

    static mapDtoData(refiner: RefinerDto): RefinerGroupDto {
        const refinerDtos = new Array<RefinerDto>();

        switch (refiner.refinerLocation) {
            case RefinerLocation.callOnOrAfterDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Calls",
                        "StopTime",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callOnOrBeforeDate:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Calls",
                        "StopTime",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.account:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Name",
                        refiner.value,
                        refiner.dataValue
                    ),
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "CustomerNumber",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.street:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Address1",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.city:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "City",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.states:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "State",
                        refiner.value
                    )
                );
                break;
            case RefinerLocation.counties:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "County",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.zipCodes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerBusinessAddress",
                        "Zip",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.storeTypes:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Customers",
                        "Type",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.callType:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "Calls",
                        "CallType",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.hasPictures:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallPictures",
                        "RecordsExists",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.pictureType:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallPictures",
                        "PictureType",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.tags:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CallPictures",
                        "Tags",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            case RefinerLocation.px3Rank:
                refinerDtos.push(
                    RefinerConverterService.createRefinerDto(
                        refiner.refinerLocation,
                        "CustomerPx3Ranks",
                        "Px3RankId",
                        refiner.value,
                        refiner.dataValue
                    )
                );
                break;
            default:
                break;
        }

        return RefinerConverterService.splitRefinerDtoValues(refinerDtos);
    }
}

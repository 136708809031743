<div #originalSwisherRetailReceipt id="retail-draft-receipt">
    <app-swisher-retail-format-draft *ngIf="!isTaxState" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="00"
    >
    </app-swisher-retail-format-draft>
    <app-swisher-retail-narrow-tax-format-draft *ngIf="isTaxState && selectedFormat === selectedFormats.narrow;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="00">
    </app-swisher-retail-narrow-tax-format-draft>
    <app-swisher-retail-wide-tax-format-draft *ngIf="isTaxState && selectedFormat === selectedFormats.wide;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="00">
    </app-swisher-retail-wide-tax-format-draft>
    <app-eas-retail-wide-tax-format-draft *ngIf="isEasEmployee && isTaxState && selectedFormat === selectedFormats.wide;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01">
    </app-eas-retail-wide-tax-format-draft>
    <app-eas-retail-wide-tax-format-swisher-rep-draft *ngIf="!isEasEmployee && isTaxState && selectedFormat === selectedFormats.wide;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01">
    </app-eas-retail-wide-tax-format-swisher-rep-draft>
    <app-eas-retail-format-draft *ngIf="isEasEmployee && !isTaxState" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01"
    >
    </app-eas-retail-format-draft>
    <app-eas-retail-format-swisher-rep-draft *ngIf="!isEasEmployee && !isTaxState" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01"
    > 
    </app-eas-retail-format-swisher-rep-draft>
    <app-eas-retail-narrow-tax-format-draft *ngIf="isEasEmployee && isTaxState && selectedFormat === selectedFormats.narrow;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01">
    </app-eas-retail-narrow-tax-format-draft>
    <app-eas-retail-narrow-tax-format-swisher-rep-draft *ngIf="!isEasEmployee && isTaxState && selectedFormat === selectedFormats.narrow;" #draftReceipts
        [activeProducts]="activeProducts"
        [call]="call$"
        [signature]="signature"
        [contact]="selectedContact"
        [customer]="customer"
        [employee]="employee"
        receiptNumberExtention="01">
    </app-eas-retail-narrow-tax-format-swisher-rep-draft>
</div>

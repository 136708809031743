import { Component, signal } from '@angular/core';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';


@Component({
    selector: 'app-eas-retail-format-draft',
    templateUrl: './eas-retail-format-draft.component.html',
    styleUrls: ['./eas-retail-format-draft.component.scss']
})
export class EasRetailFormatDraftComponent extends RetailBaseComponent {
    subsidiary = signal(Subsidiary.EAS);
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { ProjectStep } from 'src/app/enums/project-step';
import { OverlayService } from 'src/app/services/overlay.service';
import { PleaseWaitService } from 'src/app/services/please-wait.service';
import { ProjectStateService } from 'src/app/services/project-state-service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { GridComponent } from 'src/app/shared/grid/grid.component';
import { ProjectApplicationService } from '../../project-services/project-application.service';
import { ProjectAssignmentsViewmodel } from './project-assignments.viewmodel';
import { ProjectDelineationService } from 'src/app/services/delineation-services/project-delineation.service';
import { EmployeeDelineationService } from 'src/app/services/delineation-services/employee-delineation.service';
import { ProjectAssignmentRefinerService } from './project-assignment-refiner.service';
import { ProjectAssignmentsZrtFilterService } from './project-assignments-zrt-filter.service';
import { AccountOwnershipDelineationService } from 'src/app/services/delineation-services/account-ownership-delineation.service';
import { ActivitiesFilterService } from 'src/app/services/activities-filter.service';
import { BehaviorSubject } from 'rxjs';
import { CustomerDelineationService } from 'src/app/services/delineation-services/customer-delineation.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogViewmodel } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.viewmodel';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';

@Component({
    selector: 'app-project-assignments',
    templateUrl: './project-assignments.component.html',
    styleUrls: ['./project-assignments.component.scss']
})
export class ProjectAssignmentsComponent implements OnDestroy, AfterViewChecked {

    @ViewChild("drawer") drawer: MatSidenav;
    @ViewChild("grid") grid: GridComponent;
    @ViewChild("sideNavContent") sideNavContent: MatSidenavContent;
    @ViewChild("viewPort") viewPort: CdkVirtualScrollViewport;
    @ViewChild("summarySearchElement") summarySearchElement: ElementRef;

    viewmodel = new ProjectAssignmentsViewmodel(
        this.projectAssignmentRefinerService,
        this.pleaseWaitService,
        this.projectDelineationService,
        this.snackbarService,
        this.projectStateService,
        this.projectApplicationService,
        this.overlayService,
        this.employeeDelineationService,
        this.accountOwnershipDelineationService,
        this.activitiesFilterService,
        this.zrtFilterService,
        this.customerDelineationService
    );
    isViewmodelReady = false;
    shouldSearch = true;
    currentStep = ProjectStep.assignments;
    topGapDistance = 0;
    gridHeight = "58vh";
    screenHeight: number = 0;
    isGapSet$ = new BehaviorSubject(false);

    private modalRef: SwisherOverlayRef<
        ConfirmationDialogViewmodel,
        ConfirmationDialogComponent
    >;

    constructor(
        private projectAssignmentRefinerService: ProjectAssignmentRefinerService,
        private pleaseWaitService: PleaseWaitService,
        private projectDelineationService: ProjectDelineationService,
        public projectStateService: ProjectStateService,
        public projectApplicationService: ProjectApplicationService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private employeeDelineationService: EmployeeDelineationService,
        private accountOwnershipDelineationService: AccountOwnershipDelineationService,
        private activitiesFilterService: ActivitiesFilterService,
        private zrtFilterService: ProjectAssignmentsZrtFilterService,
        private customerDelineationService: CustomerDelineationService
    ) { }

    ngOnDestroy(): void {
        this.viewmodel.unsubscribe();
    }

    showFilters(): void {
        this.drawer.toggle();
        this.isGapSet$.next(false);
    }

    ngAfterViewChecked(): void {
        if (this.projectApplicationService.selectedIndex < ProjectStep.assignments && !this.shouldSearch) {
            this.shouldSearch = true;
        }

        if (this.projectApplicationService.selectedIndex !== ProjectStep.assignments) return;

        if (this.shouldSearch) {
            this.shouldSearch = false;
            void this.viewmodel.getBatch(0, true);
        }
        if (this.isViewmodelReady && !this.isGapSet$.value) {
            this.calculateGap();
        }

        if (!this.isViewmodelReady
            && this.grid
            && this.sideNavContent
            && this.drawer
            && this.summarySearchElement
        ) {
            this.isViewmodelReady = true;
            this.getScreenSize();
            setTimeout(() => {
                void this.viewmodel.initialize(
                    this.grid,
                    this.sideNavContent,
                    this.drawer,
                    this.summarySearchElement,
                    this.isGapSet$
                );
            }, 0);
        }
    }

    assignEmployeesBasedOnCustomerZrt(ev: MatCheckboxChange): void {
        if (ev.checked){
            this.viewmodel.assignEmployeesBasedOnCustomerZrt();
        } else {
            if (this.modalRef) {
                this.modalRef.close();
            }
            const data: ConfirmationDialogViewmodel = new ConfirmationDialogViewmodel();
            data.header = "Confirmation";
            data.message = "Are you sure you want to remove the automatic assignments?";
            data.buttonLeftText = "No";
            data.buttonLeftFunction = null;
            data.buttonRightText = "Yes";
            data.buttonRightFunction = () => {
                this.modalRef.close();
                this.viewmodel.undoAssignEmployeesBasedOnCustomerZrt();
            }

            this.modalRef = this.overlayService.open(
                ConfirmationDialogComponent,
                data
            );

            this.modalRef.afterClosed$.subscribe(() => {
                this.modalRef = undefined;
            });
        }
    }

    calculateGap(): void {
        if (this.sideNavContent) {
            setTimeout(() => {
                this.topGapDistance =
                    window.scrollY +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.top;
                const gridheightOffsetDeduction =
                    window.scrollY +
                    this.sideNavContent
                        .getElementRef()
                        ?.nativeElement?.getBoundingClientRect()?.bottom;
                this.gridHeight =
                    (40 * (this.screenHeight / 100) - gridheightOffsetDeduction) +
                    "px";
            }, 0);
            this.isGapSet$.next(true);
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        this.screenHeight = window.innerHeight;
    }

    //this function handles the known issue of implementing a sticky header in a cdk virtual scroll component (https://github.com/angular/components/issues/14833). It implements one of the solutions found on GitHub (https://stackblitz.com/edit/components-issue-brsnj4?file=app%2Fapp.component.html)
    public get inverseOfTranslation(): string {
        if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
            return "-0px";
        }
        let offset = this.viewPort["_renderedContentOffset"];
        return `-${offset}px`;
    }

}

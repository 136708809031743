import {
    AfterContentChecked,
    Component,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { BasicDialogViewModel } from "./basic-dialog.viewmodel";
import { HeaderButtonComponent } from "src/app/shared/page-header/buttons/header-button/header-button.component";

@Component({
    selector: "app-basic-dialog",
    templateUrl: "./basic-dialog.component.html",
    styleUrls: ["./basic-dialog.component.scss"]
})
export class BasicDialogComponent
    extends BasicDialogViewModel
    implements OnInit, AfterContentChecked, OnDestroy {

    constructor(public ref: SwisherOverlayRef<any, any>) {
        super();
    }

    blockingSubscription: Subscription

    ngOnInit(): void {

        if (!this.blockingSubscription || this.blockingSubscription.closed) {
            this.blockingSubscription = this.ref.observableBlocking.subscribe((blocking) => {
                setTimeout(() => this.blocking = blocking, 0);
            });
        }

        this.content = this.ref.content;

        this.setDataMappings();

        if (typeof this.content === "string") {
            this.contentType = "string";
            this.contentString = this.content;
        } else if (this.content instanceof TemplateRef) {
            this.contentType = "template";
            this.contentTemplateRef = this.content;
            this.context = {
                close: this.ref.close.bind(this.ref),
                data: this.ref.data
            };
        } else {
            this.contentType = "component";
            this.contentComponent = this.content;
        }
    }

    ngOnDestroy(): void {
        if (this.blockingSubscription && !this.blockingSubscription.closed) {
            this.blockingSubscription.unsubscribe();
        }
    }

    close(): void {
        this.ref.close(this.ref.data);
    }

    ngAfterContentChecked(): void {
        if (this.ref.data?.needsMappingsRefreshed) {
            this.setDataMappings();

            this.ref.data.needsMappingsRefreshed = false;
        }
    }

    @HostListener('window:keyup', ['$event'])
        onKeyUp(event: KeyboardEvent): void {
            if (event.key === 'Enter' && this.ref) {
                this.buttonRightFunction()
            }
        }

    setDataMappings(): void {
        this.iconButtons = this.ref.data?.iconButtons ?? [];
        this.iconButtonClick$ = this.ref.data?.iconButtonClick$ ?? new Subject<HeaderButtonComponent>();
        this.headerLeftText = this.ref.data?.headerLeftText;
        this.headerRightText = this.ref.data?.headerRightText ?? "";
        this.headerRightButtonText = this.ref.data?.headerRightButtonText;
        this.headerRightButtonClick$ = this.ref.data?.headerRightButtonClick$ ?? new EventEmitter();
        this.buttonLeftText = this.ref.data?.buttonLeftText ?? "Cancel";
        if (!this.ref.data?.buttonLeftFunction) {
            this.buttonLeftFunction = () => this.ref.close();
        } else {
            this.buttonLeftFunction = this.ref.data?.buttonLeftFunction;
        }
        if (!this.ref.data?.buttonLeftDisabledFunction) {
            this.buttonLeftDisabledFunction = () => {
                return false;
            };
        } else {
            this.buttonLeftDisabledFunction = this.ref.data?.buttonLeftDisabledFunction;
        }

        this.buttonMiddleText = this.ref.data?.buttonMiddleText;
        if (!this.ref.data?.buttonMiddleFunction) {
            this.buttonMiddleFunction = () => this.ref.close();
        } else {
            this.buttonMiddleFunction = this.ref.data?.buttonMiddleFunction;
        }
        if (!this.ref.data?.buttonMiddleDisabledFunction) {
            this.buttonMiddleDisabledFunction = () => {
                return false;
            };
        } else {
            this.buttonMiddleDisabledFunction = this.ref.data?.buttonMiddleDisabledFunction;
        }

        this.buttonRightText = this.ref.data?.buttonRightText;
        if (!this.ref.data?.buttonRightFunction) {
            this.buttonRightFunction = () => this.ref.close();
        } else {
            this.buttonRightFunction = this.ref.data?.buttonRightFunction;
        }
        if (!this.ref.data?.buttonRightDisabledFunction) {
            this.buttonRightDisabledFunction = () => {
                return false;
            };
        } else {
            this.buttonRightDisabledFunction = this.ref.data?.buttonRightDisabledFunction;
        }

        this.width = this.ref.data?.width;
        this.height = this.ref.data?.height;
        this.backgroundColor = this.ref.data?.backgroundColor;

        this.showFooter = !!this.buttonLeftText || !!this.buttonRightText;
    }
}

import { Component, OnInit } from '@angular/core';
import { DirectOrderDetailsDto, SharedHelper, SystemInformationKeys } from 'shield.shared';
import { SwisherOverlayRef } from 'src/app/overlay/swisher-overlay-ref';
import { SystemInformationDelineationService } from 'src/app/services/delineation-services/system-information-delineation.service';
import { DirectOrderDetailsViewModel } from './direct-order-details.viewmodel';
import { faArchive, faCheck, faThumbsUp, faTruck } from '@fortawesome/free-solid-svg-icons';

const INVOICE_NUMBER_PLACEHOLDER = "$NUMBER";
const INTERNAL_ID_PLACEHOLDER = "$INTERNAL_ID";

@Component({
  selector: 'direct-order-details',
  templateUrl: './direct-order-details.component.html',
  styleUrls: ['./direct-order-details.component.scss']
})
export class DirectOrderDetailsComponent implements OnInit {

  reportUrl?: string;

  public faThumbsUp = faThumbsUp;
  public faArchive = faArchive;
  public faTruck = faTruck;
  public faCheck = faCheck;

  constructor(
    private ref: SwisherOverlayRef<DirectOrderDetailsViewModel, DirectOrderDetailsComponent>,
    private systemInformation: SystemInformationDelineationService,
  ) { }

  get details(): DirectOrderDetailsDto {
    return this.ref.data.details;
  }

  getUniqueInvoiceNumbers(): {invoiceNumber: string, invoiceInternalId: string}[] {
    return SharedHelper.unique(this.details.lineItems, "invoiceNumber").map(li => {
      return {
        invoiceNumber: li.invoiceNumber,
        invoiceInternalId: li.invoiceInternalId,
      };
    });
  }

  formatInvoiceLink(invoiceNumber: string, internalId: string) {
    return this.reportUrl!
      .replace(INVOICE_NUMBER_PLACEHOLDER, invoiceNumber)
      .replace(INTERNAL_ID_PLACEHOLDER, internalId);
  }

  async ngOnInit(): Promise<void> {
    this.reportUrl = (await this.systemInformation.getByKey(SystemInformationKeys.orderInvoiceUrl))
      .values
      .value;
  }

}

<mat-card appearance="outlined" class="mt-3" style="height: 600px;">
    <div *ngIf="validationErrorMessages && validationErrorMessages.length > 0">
        <div class="alert alert-danger" role="alert">
            <div class="row">
                <b>Validation Summary</b>
            </div>
            <div *ngFor="let message of validationErrorMessages">
                <mat-icon>warning</mat-icon>
                {{ message }}
            </div>
        </div>
    </div>
    <h2 class="margin-bottom-unset m-teal">
        <b>After Picture</b>
    </h2>
    <div class="row">
        <div class="col-lg-6 col-sm-12">
            <h2 class="centered m-teal">Before</h2>
            <div class="centered-carousel">
                <div class="row">
                    <div class="col">
                        <mat-carousel [autoplay]="false" color="primary"
                            [proportion]="proportion" maxWidth="530px"
                            [hideArrows]="true" [hideIndicators]="true"
                            orientation="ltr">
                            <mat-carousel-slide *ngIf="beforeSlide.length===0">
                                <img src="assets/img/no-before-picture.png" style="height: 530px !important; width: auto;"/>
                            </mat-carousel-slide>
                            <mat-carousel-slide *ngFor="
                                            let slide of beforeSlide;
                                        " [image]="slide.image" overlayColor="#00000040" [hideOverlay]="true">
                            </mat-carousel-slide>
                        </mat-carousel>
                    </div>
                </div>
            </div>
            <div *ngIf="beforeSlide?.length === 0" class="d-flex flex-column align-items-center mt-3">
                <button appThrottleClick class="btn btn-outline-danger" (throttledClick)="onOpenModal(stepperStepBefore)">
                    <span class="flaticon-photo-camera"></span> Take Before
                    Picture
                </button>
            </div>
            <div *ngIf="beforeSlide?.length > 0" class="d-flex mt-3 justify-content-center">
                <button appThrottleClick class="btn btn-outline-danger" (throttledClick)="onOpenModal(stepperStepBefore)">
                    <span class="flaticon-photo-camera"></span> Retake Before
                    Picture
                </button>
            </div>
        </div>
        <div class="col-lg-6 col-sm-12">
            <h2 class="centered m-teal">{{pictureType ? pictureType : ''}}</h2>
            <div class="centered-carousel">
                <div class="row">
                    <div class="col-1 chevron-container">
                        <span class="chevron material-icons" (click)="carousel.previous()">chevron_left</span>
                    </div>
                    <div class="col">
                        <mat-carousel #carousel (change)="setPictureType($event)" [autoplay]="false" color="primary"
                            [proportion]="proportion" maxWidth="530px" [slides]="slides?.length" [loop]="true"
                            [hideArrows]="true" [hideIndicators]="false" [useKeyboard]="true" [useMouseWheel]="true"
                            orientation="ltr">
                            <mat-carousel-slide *ngIf="!afterPicture">
                                <img src="assets/img/no-picture.png" style="height: 530px !important; width: auto;"/>
                            </mat-carousel-slide>
                            <mat-carousel-slide #matCarouselSlide *ngFor="
                                            let slide of slides;
                                            let i = index
                                        " [image]="slide.image" overlayColor="#00000040" [hideOverlay]="true">
                            </mat-carousel-slide>
                        </mat-carousel>
                    </div>
                    <div class="col-1 chevron-container">
                        <span class="chevron material-icons" (click)="carousel.next()">chevron_right</span>
                    </div>
                </div>
            </div>
            <div *ngIf="!afterPicture" class="d-flex flex-column align-items-center mt-3">
                <button appThrottleClick class="btn btn-outline-danger" (throttledClick)="onOpenModal(stepperStepAfter)">
                    <span class="flaticon-photo-camera"></span> Take After
                    Picture
                </button>
            </div>
            <div *ngIf="afterPicture" class="d-flex mt-3 justify-content-center">
                <button appThrottleClick class="btn btn-outline-danger" (throttledClick)="onOpenModal(stepperStepAfter)">
                    <span class="flaticon-photo-camera"></span> Retake After
                    Picture
                </button>
            </div>
        </div>
    </div>
</mat-card>

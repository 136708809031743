import {Component, HostBinding, OnDestroy, OnInit} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidatorFn,
    Validators
} from "@angular/forms";
import { Router } from "@angular/router";
//Icons
import {
    faPen,
    faPlus,
    faTrash,
    IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { Customer } from "src/app/entity-models/customer.entity";
import { WholesalerProduct } from "src/app/entity-models/wholesaler-product.entity";
//Dialog imports
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { AppStateService } from "src/app/services/app-state.service";
import { OverlayService } from "src/app/services/overlay.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { SnackbarService } from "src/app/services/snackbar.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { CustomerStateService } from "../../account-services/customer-state.service";
import { AddProductsComponent } from "../../../products/add-products/add-products.component";
import { AddProductsViewmodel } from "../../../products/add-products/add-products.viewmodel";
import { WholesalerProductViewmodel } from "./wholesaler-product-viewmodel";
import { WholesalerAccountProductsViewmodel } from "./wholesaler-account-products.viewmodel";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { newSequentialId } from "shield.shared";

@Component({
    selector: "app-wholesale-products",
    templateUrl: "./wholesale-products.component.html",
    styleUrls: ["./wholesale-products.component.css"]
})
export class WholesaleProductsComponent implements OnInit, OnDestroy {
    @HostBinding("class") class = "d-flex flex-column flex-grow-1";
    faPen: IconDefinition = faPen;
    faPlus: IconDefinition = faPlus;
    faTrash: IconDefinition = faTrash;

    addProductOverlayRef: SwisherOverlayRef<
        AddProductsViewmodel,
        AddProductsComponent
    >;

    wholesalerCustomerSubscription: Subscription;
    employeeSubscription: Subscription;
    wholesalerProductsViewmodel = new WholesalerAccountProductsViewmodel();
    tomorrow = new Date();
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    myForm: UntypedFormGroup;
    dateFormSubscription: Subscription;

    shouldWait$ = new BehaviorSubject<boolean>(true);

    private employeeName: string;
    private customer: Customer;

    public constructor(
        private overlayService: OverlayService,
        private appStateService: AppStateService,
        private customerStateService: CustomerStateService,
        private customerDelineationService: CustomerDelineationService,
        private productDelineationService: ProductDelineationService,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private snackbar: SnackbarService,
        private pleaseWait: PleaseWaitService
    ) {}

    ngOnInit(): void {
        this.tomorrow.setDate(this.tomorrow.getDate() + 1);
        this.tomorrow.setHours(0, 0, 0, 0);

        this.myForm = this.formBuilder.group({});

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.appStateService.currentEmployee
                .pipe(
                    map(async (employee) => {
                        this.employeeName = employee.fullName;
                        await this.setUpViewmodel();
                    })
                )
                .subscribe();
        }

        if (
            !this.wholesalerCustomerSubscription ||
            this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription = this.customerStateService.observableCustomer
                .pipe(
                    map(async (customer) => {
                        this.customer = customer;
                        await this.setUpViewmodel();
                    })
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        if (
            this.wholesalerCustomerSubscription &&
            !this.wholesalerCustomerSubscription.closed
        ) {
            this.wholesalerCustomerSubscription.unsubscribe();
        }

        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }

        this.unsubscribeFromControls();
    }

    addProduct(): void {
        const data: AddProductsViewmodel = new AddProductsViewmodel();
        data.buttonLeftDisabledFunction = () => false;
        data.buttonLeftFunction = () => this.addProductOverlayRef.close(data);
        data.buildViewmodelProductsFromProductDomainModel(
            this.productDelineationService.activeProducts,
            this.wholesalerProductsViewmodel.wholesaleProducts.map(
                (wp) => wp.product.id
            )
        );
        this.addProductOverlayRef = this.overlayService.open(
            AddProductsComponent,
            data,
            true
        );

        this.addProductOverlayRef.afterClosed$.subscribe((ref) => {
            if (ref && ref.data && ref.data.result) {
                this.wholesalerProductsViewmodel.wholesaleProducts = ref.data.result
                    .filter((r) => r.selected)
                    .map((vm) => {
                        const foundWholesaleProduct = this.wholesalerProductsViewmodel.wholesaleProducts.find(
                            (wp) => wp.product.id === vm.product.id
                        );
                        if (foundWholesaleProduct) {
                            // Was selected before the modal was opened
                            return foundWholesaleProduct;
                        } else {
                            //new Selection
                            const wholesaleProduct = new WholesalerProduct();
                            const date = new Date();
                            wholesaleProduct.wholesalerId = this.customer.id;
                            wholesaleProduct.dateCreated = date;
                            wholesaleProduct.createdBy = this.employeeName;
                            wholesaleProduct.lastUpdated = date;
                            wholesaleProduct.lastUpdatedBy = this.employeeName;
                            wholesaleProduct.id = newSequentialId();
                            const newProductSelection = new WholesalerProductViewmodel();
                            newProductSelection.buildWholesalerAccountProductViewmodelFromWholesalerProductAndProduct(
                                wholesaleProduct,
                                vm.product
                            );
                            return newProductSelection;
                        }
                    })
                    ?.sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1));

                this.setValidators();
            }
        });
    }

    cancel(): void {
        void this.router.navigate([
            "/accounts",
            this.customerStateService.customer.id,
            "profile"
        ]);
    }

    delete(i: number): void {
        const products = this.wholesalerProductsViewmodel.wholesaleProducts.slice();
        products.splice(i, 1);
        this.wholesalerProductsViewmodel.wholesaleProducts = products;
        this.setValidators();
    }

    invalidFutureDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            if (control.value) {
                const date = control.value as Moment;

                if (moment() > date) {
                    forbidden = true;
                }
            }

            return forbidden ? { dateInPast: true } : null;
        };
    }

    async save(): Promise<void> {
        this.myForm.markAsTouched();
        let uinInvalid = false;
        for (
            let i = 0;
            i < this.wholesalerProductsViewmodel.wholesaleProducts.length;
            i++
        ) {
            this.myForm.controls["uin" + i.toString()].markAsTouched();
            if (
                !uinInvalid &&
                this.myForm.controls["uin" + i.toString()].invalid
            ) {
                uinInvalid = true;
            }
        }

        if (this.myForm.valid) {
            this.customer.wholesalerProducts = this.wholesalerProductsViewmodel.wholesaleProducts.map(
                (wp) =>
                    wp.buildWholesalerProductFromWholesalerAccountProductViewmodel()
            );
            await this.customerDelineationService.upsertCustomer(this.customer);
            this.snackbar.showInfo("Save Successful");
            void this.router.navigate([
                "/accounts",
                this.customerStateService.customer.id,
                "volume"
            ]);
        } else if (uinInvalid) {
            this.snackbar.showWarning(
                "UIN's are required on Wholesaler Products."
            );
        }
    }

    async setUpViewmodel(): Promise<void> {
        if (this.customer && this.employeeName) {

            const products = this.productDelineationService.activeProducts;
            const model = this.wholesalerProductsViewmodel.buildViewmodelFromDomainModel(
                this.customer,
                products
            );

            this.wholesalerProductsViewmodel.wholesaleProducts = model.sort(
                (a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)
            );

            this.setValidators();
        }
    }

    setValidators(): void {
        this.pleaseWait.showProgressSpinnerUntilLoaded(this.shouldWait$);
        this.unsubscribeFromControls();
        this.myForm = this.formBuilder.group({});
        setTimeout(() => {
            for (
                let i = 0;
                i < this.wholesalerProductsViewmodel.wholesaleProducts.length;
                i++
            ) {
                const itterator = i.toString();
                if (!this.myForm.controls["dateAvailable" + itterator]) {
                    this.myForm.addControl(
                        "dateAvailable" + itterator,
                        new UntypedFormControl()
                    );
                    this.myForm.controls[
                        "dateAvailable" + itterator
                    ].setValidators([this.invalidFutureDate()]);
                    this.myForm.controls[
                        "dateAvailable" + itterator
                    ].updateValueAndValidity();

                    this.myForm.controls["dateAvailable" + itterator].setValue(
                        this.wholesalerProductsViewmodel.wholesaleProducts[i]
                            .dateAvailable
                    );

                    let controlSubscription = this.wholesalerProductsViewmodel
                        .wholesaleProducts[i].dateAvailableSubscription;
                    if (!controlSubscription || controlSubscription.closed) {
                        controlSubscription = this.myForm.controls[
                            "dateAvailable" + itterator
                        ].valueChanges.subscribe((value: Moment) => {
                            this.wholesalerProductsViewmodel.wholesaleProducts[
                                i
                            ].dateAvailable = value?.toDate();
                        });
                    }
                }

                if (!this.myForm.controls["uin" + itterator]) {
                    this.myForm.addControl(
                        "uin" + itterator,
                        new UntypedFormControl()
                    );
                    this.myForm.controls["uin" + itterator].setValidators(
                        Validators.required
                    );
                    this.myForm.controls[
                        "uin" + itterator
                    ].updateValueAndValidity();
                    this.myForm.controls["uin" + itterator].setValue(
                        this.wholesalerProductsViewmodel.wholesaleProducts[i]
                            .uin
                    );

                    let uinControlSubscription = this
                        .wholesalerProductsViewmodel.wholesaleProducts[i]
                        .uinSubscription;
                    if (
                        !uinControlSubscription ||
                        uinControlSubscription.closed
                    ) {
                        uinControlSubscription = this.myForm.controls[
                            "uin" + itterator
                        ].valueChanges.subscribe((value: string) => {
                            this.wholesalerProductsViewmodel.wholesaleProducts[
                                i
                            ].uin = value;
                        });
                    }
                }
            }
            this.shouldWait$.next(false);
        }, 0);
    }

    unsubscribeFromControls(): void {
        for (
            let i = 0;
            i < this.wholesalerProductsViewmodel.wholesaleProducts.length;
            i++
        ) {
            const subscription = this.wholesalerProductsViewmodel
                .wholesaleProducts[i].dateAvailableSubscription;
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }

            const uinSubscription = this.wholesalerProductsViewmodel
                .wholesaleProducts[i].uinSubscription;
            if (uinSubscription && !uinSubscription.closed) {
                uinSubscription.unsubscribe();
            }
        }
    }
}

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { GenericLookup, ProjectStatuses, ProjectTypeLookup, ProjectTypes } from "shield.shared";
import { Project } from "src/app/entity-models/project.entity";
import { ProjectStateService } from "src/app/services/project-state-service";

export class ProjectDefinitionViewmodel {

    project: Project;

    projectType: string;
    projectName: string;
    mission: string;
    additionalResources: string;
    projectDefinitionFormGroup: UntypedFormGroup;
    subscriptionProjectName: Subscription;
    subscriptionMission: Subscription;
    subscriptionAdditionalResources: Subscription;
    subscriptionProjectType: Subscription;
    projectSubscription: Subscription;

    projectStateService: ProjectStateService;
    projectStatuses = ProjectStatuses;

    selectedProjectType: ProjectTypes;
    projectTypes: GenericLookup<ProjectTypes>[];

    constructor(projectStateService: ProjectStateService,
        formBuilder: UntypedFormBuilder){
        this.projectStateService = projectStateService;
        this.projectDefinitionFormGroup = formBuilder.group({
            projectNameControl: new UntypedFormControl(Validators.required),
            missionControl: new UntypedFormControl(Validators.required),
            additionalResourcesControl: new UntypedFormControl(),
            projectTypeControl: new UntypedFormControl(Validators.required)
        });

        this.projectTypes = ProjectTypeLookup;
        this.selectedProjectType = ProjectTypes.RetailEngagement;

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe((project) => {
                if (project) {
                    this.projectName = project.name;
                    this.mission = project.mission;
                    this.additionalResources = project.shareSiteLink;
                    this.selectedProjectType = project.projectTypeId;

                    this.projectDefinitionFormGroup.controls["projectNameControl"].setValue(this.projectName);
                    this.projectDefinitionFormGroup.controls["missionControl"].setValue(this.mission);
                    this.projectDefinitionFormGroup.controls["additionalResourcesControl"].setValue(this.additionalResources);
                    this.projectDefinitionFormGroup.controls["projectTypeControl"].setValue(this.selectedProjectType);
                }
            });
        }

        if (!this.subscriptionProjectType || this.subscriptionProjectType.closed) {
            this.subscriptionProjectType = this.projectDefinitionFormGroup.controls["projectTypeControl"].valueChanges.subscribe((projectType) => {
                this.selectedProjectType = projectType;
                if (this.projectStateService.project) {
                    this.projectStateService.project.projectTypeId = this.selectedProjectType;
                }
            });
        }

        if (!this.subscriptionProjectName || this.subscriptionProjectName.closed) {
            this.subscriptionProjectName = this.projectDefinitionFormGroup.controls["projectNameControl"].valueChanges.subscribe((projectName) => {
                if (this.projectName !== projectName) {
                    this.projectName = projectName;
                    if (this.projectStateService.project) {
                        this.projectStateService.project.name = projectName;
                    }
                }

            });
        }

        if (!this.subscriptionMission || this.subscriptionMission.closed) {
            this.subscriptionMission = this.projectDefinitionFormGroup.controls["missionControl"].valueChanges.subscribe((mission) => {
                if (this.mission !== mission) {
                    this.mission = mission;
                    if (this.projectStateService.project) {
                        this.projectStateService.project.mission = mission;
                    }
                }
            });
        }

        if (!this.subscriptionAdditionalResources || this.subscriptionAdditionalResources.closed) {
            this.subscriptionAdditionalResources = this.projectDefinitionFormGroup.controls["additionalResourcesControl"].valueChanges.subscribe((additionalResources: string) => {
                if (this.additionalResources !== additionalResources) {
                    this.additionalResources = additionalResources;
                    if (this.projectStateService.project) {
                        this.projectStateService.project.shareSiteLink = additionalResources;
                    }
                }
            });
        }


    };

    unsubscribe(): void {

        if (this.subscriptionProjectName && !this.subscriptionProjectName.closed) {
            this.subscriptionProjectName.unsubscribe();
        }

        if (this.subscriptionMission && !this.subscriptionMission.closed) {
            this.subscriptionMission.unsubscribe();
        }

        if (this.subscriptionAdditionalResources && !this.subscriptionAdditionalResources.closed) {
            this.subscriptionAdditionalResources.unsubscribe();
        }
    }

    builtViewmodelFromEntity(entity: Project): void {
        if (entity) {
            this.project = entity;
            this.projectType = ProjectTypeLookup.find((pt) => pt.id === entity.projectTypeId)?.description;
            this.projectName = entity.name;
            this.mission = entity.mission;
            this.additionalResources = entity.shareSiteLink;
        }
    }

    buildEntityFromViewmodel(): void {
        if (this.project) {
            this.project.name = this.projectName;
            this.project.mission = this.mission;
            this.project.shareSiteLink = this.additionalResources;

            this.projectStateService.project = this.project;
        }
    }
}

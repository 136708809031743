import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable, throwError } from "rxjs";
import {
    ChangeOrderDateParamsDto, ChangeUinParamsDto, DirectOrderDetailsDto, DirectOrderDto, EmailAttachment, EmailOrderParamsDto,
    FilterRequestV2Dto, FilterSortDto,
    GenericResponseDto, MoveOrderProductsParamsDto,
    NotificationRequestDto, OrderBatchParamsDto,
    OrderListColumns,
    OrderListFilterDto,
    OrdersByWholesalersParamsDto,
    RefinerLocation,
    newSequentialId
} from "shield.shared";
import { Customer } from "src/app/entity-models/customer.entity";
import { OrderExtraction } from "src/app/entity-models/order-extraction.entity";
import { OrderLineItem } from "src/app/entity-models/order-line-item.entity";
import { Order } from "src/app/entity-models/order.entity";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { OrderConverterService } from "../converter-services/order-converter.service";
import { DatabaseService } from "../database.service";
import { OrderListFilterMapService } from "../filter-map-services/orders-list-filter-map.service";
import { OrderOfflineService } from "../offline-services/order-offline.service";
import { OrderOnlineService } from "../online-services/order-online.service";
import { SnackbarService } from "../snackbar.service";
import { DatasourceDelineationService } from "./datasource-delineation.service";
import { DelineationContext } from "./delineation-context.service";

@Injectable()
export class OrderDelineationService extends DelineationContext<Order, string> {

    constructor(private orderOfflineService: OrderOfflineService
        , private orderOnlineService: OrderOnlineService
        , protected datasourceDelineationService: DatasourceDelineationService
        , protected dbService: DatabaseService
        , snackbarService: SnackbarService) {
        super(dbService, datasourceDelineationService, snackbarService);
    }

    async createDirectOrder(
        wholesaler: Customer,
        lineItems: OrderLineItem[],
        orderDate: Date,
    ): Promise<string> {
        const directOrder: DirectOrderDto = {
            customerId: wholesaler.id,
            orderDate: orderDate.toISOString(),
            lineItems: lineItems.map(li =>
                OrderConverterService.orderLineItemToOrderLineItemDto(li)
            ),
        }
        return (await this.simpleCall<OrderOfflineService,OrderOnlineService,DirectOrderDto,string>(
            "createDirectOrder",
            directOrder,
            this.orderOfflineService,
            this.orderOnlineService,
        )).values;
    }

    async getDirectOrderDetails(orderNumber: string): Promise<DirectOrderDetailsDto | undefined> {
        return (await this.simpleCall<OrderOfflineService, OrderOnlineService, string, DirectOrderDetailsDto | undefined>(
            "getDirectOrderDetails",
            orderNumber,
            this.orderOfflineService,
            this.orderOnlineService
        )).values;
    }

    async getOrder(id: string): Promise<GenericResponseDto<Order>> {

        const offline = (key: string) => {
            return this.orderOfflineService.getOrder(key);
        }
        const online = (key: string) => {
            return this.orderOnlineService.getOrder(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Order>(id, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getDirectBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<OrderListColumns>[],
    ): Promise<GenericResponseDto<Order[]>> {
        return this._getBatch(id, refiners, pageSize, startIndex, filterSorts, "getDirectBatch");
    }

    async getBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<OrderListColumns>[],
    ): Promise<GenericResponseDto<Order[]>> {
        return this._getBatch(id, refiners, pageSize, startIndex, filterSorts, "getBatch");
    }

    private async _getBatch(
        id: string,
        refiners: Refiner[],
        pageSize: number,
        startIndex: number,
        filterSorts: FilterSortDto<OrderListColumns>[],
        serviceFunction: keyof OrderOnlineService & keyof OrderOfflineService,
    ): Promise<GenericResponseDto<Order[]>> {

        const key = new OrderBatchParamsDto();
        key.filterRequestDto = new FilterRequestV2Dto();
        key.filterRequestDto.id = id;
        key.filterRequestDto.filters = OrderListFilterMapService.mapFilterData(refiners);
        key.filterRequestDto.pageSize = pageSize;
        key.filterRequestDto.startIndex = startIndex;
        key.filterRequestDto.filterSorts = filterSorts;
        key.zrt = refiners?.find((r) =>
            r.location === RefinerLocation.zrt || r.location === RefinerLocation.zrtByEmployee
        )?.value;

        return await this.simpleCall<OrderOfflineService, OrderOnlineService, OrderBatchParamsDto, Order[]>(
            serviceFunction,
            key,
            this.orderOfflineService,
            this.orderOnlineService,
        );
    }

    async changeOrderDate(id: string, orderDate: moment.Moment): Promise<GenericResponseDto<Order>> {

        const params = new ChangeOrderDateParamsDto();
        params.id = id;
        params.orderDate = orderDate;

        const offline = (key: ChangeOrderDateParamsDto) => {
            return this.orderOfflineService.changeOrderDate(key);
        }
        const online = (key: ChangeOrderDateParamsDto) => {
            return this.orderOnlineService.changeOrderDate(key);
        }
        const response = await this.datasourceDelineationService.makeCall<ChangeOrderDateParamsDto, Order>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async changeWholesalerUin(wholesalerId: string, oldUin: string, newUin: string): Promise<GenericResponseDto<boolean>> {

        const params = new ChangeUinParamsDto();
        params.wholesalerId = wholesalerId;
        params.oldUin = oldUin;
        params.newUin = newUin;

        const offline = (key: ChangeUinParamsDto) => {
            return this.orderOfflineService.changeWholesalerUin(key);
        }
        const online = (key: ChangeUinParamsDto) => {
            return this.orderOnlineService.changeWholesalerUin(key);
        }
        const response = await this.datasourceDelineationService.makeCall<ChangeUinParamsDto, boolean>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async movePendingOrderProductsToNewOrder(lineItemIds: string[], orderDate: moment.Moment): Promise<GenericResponseDto<Order>> {

        const params = new MoveOrderProductsParamsDto();
        params.lineItemIds = lineItemIds;
        params.orderDate = orderDate;

        const offline = (key: MoveOrderProductsParamsDto) => {
            return this.orderOfflineService.movePendingOrderProductsToNewOrder(key);
        }
        const online = (key: MoveOrderProductsParamsDto) => {
            return this.orderOnlineService.movePendingOrderProductsToNewOrder(key);
        }
        const response = await this.datasourceDelineationService.makeCall<MoveOrderProductsParamsDto, Order>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async moveRejectedProductsToNewOrder(lineItemIds: string[], orderDate: moment.Moment): Promise<GenericResponseDto<Order>> {

        const params = new MoveOrderProductsParamsDto();
        params.lineItemIds = lineItemIds;
        params.orderDate = orderDate;

        const offline = (key: MoveOrderProductsParamsDto) => {
            return this.orderOfflineService.moveRejectedProductsToNewOrder(key);
        }
        const online = (key: MoveOrderProductsParamsDto) => {
            return this.orderOnlineService.moveRejectedProductsToNewOrder(key);
        }
        const response = await this.datasourceDelineationService.makeCall<MoveOrderProductsParamsDto, Order>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async emailOrder(employeeId: string, callId: string, receiptId: string): Promise<GenericResponseDto<undefined>> {

        const request = new NotificationRequestDto();
        request.id = newSequentialId();
        request.employeeId = employeeId;
        request.recipientEmployeeId = employeeId;
        request.sendEmail = true;
        request.subject = "Receipt for Retail Call"
        request.message = "";
        request.attachment = new EmailAttachment;
        request.attachment.type = "application/pdf"
        request.attachment.name = "Swisher Receipt.pdf"

        const params = new EmailOrderParamsDto();
        params.callId = callId;
        params.receiptId = employeeId;
        params.request = request

        const offline = (key: EmailOrderParamsDto) => {
            return this.orderOfflineService.emailOrder(key);
        }
        const online = (key: EmailOrderParamsDto) => {
            return this.orderOnlineService.emailOrder(key);
        }
        const response = await this.datasourceDelineationService.makeCall<EmailOrderParamsDto, undefined>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async cancelOrder(id: number): Promise<GenericResponseDto<boolean>> {

        const offline = (key: number) => {
            return this.orderOfflineService.cancelOrder(key);
        }
        const online = (key: number) => {
            return this.orderOnlineService.cancelOrder(key);
        }
        const response = await this.datasourceDelineationService.makeCall<number, boolean>(id, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async cancelCallOrders(id: number): Promise<GenericResponseDto<number>> {

        const offline = (key: number) => {
            return this.orderOfflineService.cancelCallOrders(key);
        }
        const online = (key: number) => {
            return this.orderOnlineService.cancelCallOrders(key);
        }
        const response = await this.datasourceDelineationService.makeCall<number, number>(id, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getWholesalersWithOrders(): Promise<GenericResponseDto<Customer[]>> {

        const offline = (key: undefined) => {
            return this.orderOfflineService.getWholesalersWithOrders(key);
        }
        const online = (key: undefined) => {
            return this.orderOnlineService.getWholesalersWithOrders(key);
        }
        const response = await this.datasourceDelineationService.makeCall<undefined, Customer[]>(undefined, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getAvailableOrdersByWholesaler(wholesalerId: string, refiners: Refiner[]): Promise<GenericResponseDto<Order[]>> {

        const params = new OrdersByWholesalersParamsDto();
        params.wholesalerId = wholesalerId;
        params.filterRequestDto = new FilterRequestV2Dto();
        params.filterRequestDto.filters = OrderListFilterMapService.mapFilterData(refiners);
        params.filterRequestDto.pageSize = 100;
        params.filterRequestDto.startIndex = 0;
        params.filterRequestDto.filterSorts = new Array<FilterSortDto<OrderListColumns>>();
        params.filterRequestDto.id = wholesalerId;

        const offline = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOfflineService.getAvailableOrdersByWholesaler(key);
        }
        const online = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOnlineService.getAvailableOrdersByWholesaler(key);
        }
        const response = await this.datasourceDelineationService.makeCall<OrdersByWholesalersParamsDto, Order[]>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getOrdersByExtraction(extractionId: string): Promise<GenericResponseDto<Order[]>> {

        const offline = (key: string) => {
            return this.orderOfflineService.getOrdersByExtraction(key);
        }
        const online = (key: string) => {
            return this.orderOnlineService.getOrdersByExtraction(key);
        }
        const response = await this.datasourceDelineationService.makeCall<string, Order[]>(extractionId, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    async getExtractionsByWholesaler(wholesalerId: string): Promise<GenericResponseDto<OrderExtraction[]>> {

        const params = new OrdersByWholesalersParamsDto();
        params.wholesalerId = wholesalerId;
        params.filterRequestDto = new FilterRequestV2Dto();
        params.filterRequestDto.filters = new OrderListFilterDto();
        params.filterRequestDto.pageSize = 100;
        params.filterRequestDto.startIndex = 0;
        params.filterRequestDto.filterSorts = new Array<FilterSortDto<OrderListColumns>>();
        params.filterRequestDto.id = wholesalerId;

        const offline = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOfflineService.getExtractionsByWholesaler(key);
        }
        const online = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOnlineService.getExtractionsByWholesaler(key);
        }
        const response = await this.datasourceDelineationService.makeCall<OrdersByWholesalersParamsDto, OrderExtraction[]>(params, offline, online);


        if (response.isError) {
            this.snackbarService.showError(response.message);
            return;
        }

        return response;
    }

    extractByWholesaler(wholesalerId: string): Observable<Blob | never> {

        const offline = (key: string) => {
            return this.orderOfflineService.extractByWholesaler(key);
        }
        const online = (key: string) => {
            return this.orderOnlineService.extractByWholesaler(key);
        }

        try {
            return this.datasourceDelineationService.makeCallWithBlobReturn<string, Observable<Blob | never>>(wholesalerId, offline, online);
        } catch (e) {
            this.snackbarService.showWarning(e);
            return throwError(e);
        }
    }

    extractByParams(wholesalerId: string, refiners: Refiner[]): Observable<Blob | never> {

        const params = new OrdersByWholesalersParamsDto();
        params.wholesalerId = wholesalerId;
        params.filterRequestDto = new FilterRequestV2Dto();
        params.filterRequestDto.filters = OrderListFilterMapService.mapFilterData(refiners);
        params.filterRequestDto.pageSize = 100;
        params.filterRequestDto.startIndex = 0;
        params.filterRequestDto.filterSorts = new Array<FilterSortDto<OrderListColumns>>();
        params.filterRequestDto.id = wholesalerId;

        const offline = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOfflineService.extractByParams(key);
        }
        const online = (key: OrdersByWholesalersParamsDto) => {
            return this.orderOnlineService.extractByParams(key);
        }

        try {
            return this.datasourceDelineationService.makeCallWithBlobReturn<OrdersByWholesalersParamsDto, Observable<Blob | never>>(params, offline, online);
        } catch (e) {
            this.snackbarService.showWarning(e);
            return throwError(e);
        }
    }

    getExtractionFile(extractionId: string): Observable<Blob | never> {

        const offline = (key: string) => {
            return this.orderOfflineService.getExtractionFile(key);
        }
        const online = (key: string) => {
            return this.orderOnlineService.getExtractionFile(key);
        }

        try {
            return this.datasourceDelineationService.makeCallWithBlobReturn<string, Observable<Blob | never>>(extractionId, offline, online);
        } catch (e) {
            this.snackbarService.showWarning(e);
            return throwError(e);
        }
    }

}

import { Component, ElementRef, ViewChild, signal } from '@angular/core';
import { RetailBaseComponent } from '../retail-format-base/retail-base.component';
import { Subsidiary } from 'shield.shared';

@Component({
    selector: 'app-swisher-retail-narrow-tax-format',
    templateUrl: './swisher-retail-narrow-tax-format.component.html',
    styleUrls: ['./swisher-retail-narrow-tax-format.component.scss']
})
export class SwisherRetailNarrowTaxFormatComponent extends RetailBaseComponent {

    @ViewChild("originalSwisherRetailReceipt") originalSwisherRetailReceipt: ElementRef;

    printLogo = "/assets/img/SwisherPrintLogo.jpg";
    subsidiary = signal(Subsidiary.Swisher);
}

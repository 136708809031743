//Angular
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";

//Models
import { ContactViewModel } from "./contact.viewmodel";
import { DayAvailabilityViewModel } from "../account.viewmodels/day-availability.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { DatabaseService } from "src/app/services/database.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { Contact } from "src/app/entity-models/contact.entity";
import { Availability } from "src/app/entity-models/availability.entity";
import { CustomerStateService } from "../account-services/customer-state.service";
import { ContactDelineationService } from "src/app/services/delineation-services/contact-delineation.service";
import { ContactConverterService } from "src/app/services/converter-services/contact-converter.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { newSequentialId } from "shield.shared";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
    retailCallCustomerContactViewModel: ContactViewModel = new ContactViewModel();
    usPhoneNumberRegEx = new RegExp(
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );

    firstNameFormControl = new UntypedFormControl(this.retailCallCustomerContactViewModel.firstName,
        Validators.required
    );
    lastNameFormControl = new UntypedFormControl(this.retailCallCustomerContactViewModel.lastName,
        Validators.required
    );

    phoneFormControl = new UntypedFormControl(
        this.retailCallCustomerContactViewModel.phoneNumber,
        {
            validators: [Validators.pattern(this.usPhoneNumberRegEx)],
            updateOn: "blur"
        }
    );

    altPhoneFormControl = new UntypedFormControl(
        this.retailCallCustomerContactViewModel.alternatePhoneNumber,
        [Validators.pattern(this.usPhoneNumberRegEx)]
    );

    emailFormControl = new UntypedFormControl(
        this.retailCallCustomerContactViewModel.email,
        // eslint-disable-next-line @typescript-eslint/unbound-method
        [Validators.email]
    );

    firstNameSubscription: Subscription;
    lastNameSubscription: Subscription;
    phoneNumberSubscription: Subscription;
    altPhoneNumberSubscription: Subscription;
    emailSubscription: Subscription;

    constructor(
        public overlayRef: SwisherOverlayRef<
            ContactViewModel,
            ContactComponent
        >,
        private contactDelineationService: ContactDelineationService,
        private customerDelineationService: CustomerDelineationService,
        private customerStateService: CustomerStateService
    ) {}

    ngOnInit(): void {
        const data = this.overlayRef.data;

        if (
            !this.firstNameSubscription ||
            this.firstNameSubscription.closed
        ) {
            this.firstNameSubscription = this.firstNameFormControl.valueChanges.subscribe(
                () => {
                    this.retailCallCustomerContactViewModel.firstName = this
                        .firstNameFormControl.value as string;
                }
            );
        }

        if (
            !this.lastNameSubscription ||
            this.lastNameSubscription.closed
        ) {
            this.lastNameSubscription = this.lastNameFormControl.valueChanges.subscribe(
                () => {
                    this.retailCallCustomerContactViewModel.lastName = this
                        .lastNameFormControl.value as string;
                }
            );
        }

        if (
            !this.phoneNumberSubscription ||
            this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription = this.phoneFormControl.valueChanges.subscribe(
                () => {
                    if (this.phoneFormControl.valid) {
                        this.retailCallCustomerContactViewModel.phoneNumber = this
                            .phoneFormControl.value as string;
                    }
                }
            );
        }

        if (
            !this.altPhoneNumberSubscription ||
            this.altPhoneNumberSubscription.closed
        ) {
            this.altPhoneNumberSubscription = this.altPhoneFormControl.valueChanges.subscribe(
                () => {
                    if (this.altPhoneFormControl.valid) {
                        this.retailCallCustomerContactViewModel.alternatePhoneNumber = this
                            .altPhoneFormControl.value as string;
                    }
                }
            );
        }

        if (!this.emailSubscription || this.emailSubscription.closed) {
            this.emailSubscription = this.emailFormControl.valueChanges.subscribe(
                () => {
                    if (this.emailFormControl.valid) {
                        this.retailCallCustomerContactViewModel.email = this
                            .emailFormControl.value as string;
                    }
                }
            );
        }

        data.buttonRightFunction = () => this.save();
        data.buttonRightText = "Save";
        data.buttonRightDisabledFunction = () => this.isSaveDisabled();
        data.buttonLeftFunction = () => this.close();
        data.buttonLeftDisabledFunction = () => {
            return false;
        };
        data.buttonLeftText = "Cancel";
        data.needsMappingsRefreshed = true;

        if (!data?.id) {
            const contact = new Contact();
            contact.availability = new Availability();
            contact.id = newSequentialId();

            contact.createdUtcDateTime = data.createdUtcDateTime;
            contact.createdUserZrt = data.createdUserZrt;
            contact.createdUserId = data.createdUserId;
            contact.createdUserFullName = data.createdUserFullName;

            contact.modifiedUtcDateTime = data.modifiedUtcDateTime;
            contact.modifiedUserZrt = data.modifiedUserZrt;
            contact.modifiedUserId = data.modifiedUserId;
            contact.modifiedUserFullName = data.modifiedUserFullName;

            this.retailCallCustomerContactViewModel = ContactConverterService.buildViewModelFromDomainModel(
                contact,
                data.customerId
            );
        } else {
            this.retailCallCustomerContactViewModel = data;
        }
    }

    ngAfterViewInit(): void {
        this.firstNameFormControl.setValue(
            this.retailCallCustomerContactViewModel.firstName
        );
        this.lastNameFormControl.setValue(
            this.retailCallCustomerContactViewModel.lastName
        );
        this.phoneFormControl.setValue(
            this.retailCallCustomerContactViewModel.phoneNumber
        );
        this.altPhoneFormControl.setValue(
            this.retailCallCustomerContactViewModel.alternatePhoneNumber
        );
        this.emailFormControl.setValue(
            this.retailCallCustomerContactViewModel.email
        );
    }

    ngOnDestroy(): void {
        if (
            this.firstNameSubscription &&
            !this.firstNameSubscription.closed
        ) {
            this.firstNameSubscription.unsubscribe();
        }

        if (
            this.lastNameSubscription &&
            !this.lastNameSubscription.closed
        ) {
            this.lastNameSubscription.unsubscribe();
        }

        if (
            this.phoneNumberSubscription &&
            !this.phoneNumberSubscription.closed
        ) {
            this.phoneNumberSubscription.unsubscribe();
        }

        if (
            this.altPhoneNumberSubscription &&
            !this.altPhoneNumberSubscription.closed
        ) {
            this.altPhoneNumberSubscription.unsubscribe();
        }

        if (this.emailSubscription && !this.emailSubscription.closed) {
            this.emailSubscription.unsubscribe();
        }
    }

    //Public Methods
    clearAll(): void {
        for (const day of this.retailCallCustomerContactViewModel.dailyAvailability) {
            this.setAll(day, false);
        }
    }

    close(): void {
        this.overlayRef.close();
    }

    isSaveDisabled(): boolean {
        return (
            (this.firstNameFormControl.hasError("required") && this.firstNameFormControl.touched) ||
            (this.lastNameFormControl.hasError("required") && this.lastNameFormControl.touched) ||
            this.phoneFormControl.hasError("pattern") ||
            this.altPhoneFormControl.hasError("pattern") ||
            this.emailFormControl.hasError("email")
        );
    }

    async save(): Promise<void> {
        this.firstNameFormControl.markAsTouched();
        this.lastNameFormControl.markAsTouched();

        if (this.isSaveDisabled()) return;
        const contact: Contact = ContactConverterService.buildDomainModelFromViewModel(
            this.retailCallCustomerContactViewModel
        );

        const response = await this.contactDelineationService.save(contact);
        if (!response) { return; }

        //update customer contact phone, if there is a change to the contact with a phone number
        await this.customerStateService.updateCustomerByContact();

        await this.customerDelineationService.upsertCustomer(
            this.customerStateService.customer
        );

        this.close();
    }

    setAll(day: DayAvailabilityViewModel, completed: boolean): void {
        day.am = completed;
        day.pm = completed;
        day.all = completed;
    }

    someComplete(day: DayAvailabilityViewModel): boolean {
        if ((day.am && !day.pm) || (!day.am && day.pm)) {
            return true;
        } else {
            return false;
        }
    }

    updateAllComplete(day: DayAvailabilityViewModel): void {
        if (day.am && day.pm) {
            day.all = true;
        } else {
            day.all = false;
        }
    }
}

import { ChangeDetectionStrategy, Component, ElementRef, Signal, computed, inject, input } from "@angular/core";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import { map, switchMap } from "rxjs";
import { LicenseTypes, Subsidiary, newSequentialId } from "shield.shared";
import { CallOrderDate } from "src/app/entity-models/call-order-date.entity";
import { Contact } from "src/app/entity-models/contact.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { Employee } from "src/app/entity-models/employee.entity";
import { Product } from "src/app/entity-models/product.entity";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { SubsidiaryTypes } from "src/app/enums/subsidiary-types";
import { Helper } from "src/app/helpers/helper";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { StateDelineationService } from "src/app/services/delineation-services/state-delineation.service";
import { StateLicenseDelineationService } from "src/app/services/delineation-services/state-license-delineation.service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";
import { WholesaleReceiptViewmodel } from "../wholesale-receipt.viewmodel";
import { OrderViewmodel } from "../order.viewmodel";
import { CallOrderProductViewModel } from "src/app/accounts/call-master/call-viewmodels/call-order-product.viewmodel";
import { CallReceiptLicense } from "src/app/entity-models/call-receipt-license.entity";

type OrderProduct = {
    id: string;
    productId: string;
    quantity: number;
    uin: string;
    upc: string;
    units: number;
    callOrderDates: CallOrderDate[];
};
type OrderProductWithProduct = OrderProduct & {
    product: Product;
};
@Component({
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WholesalerBaseComponent {
    private stateService = inject(StateDelineationService);
    private stateLicenseService = inject(StateLicenseDelineationService);
    elementRef = inject(ElementRef);
    customer = input.required<Customer>();
    wholesaler = input.required<Customer>();
    call = input.required<RetailCall | RmWholesaleCall>();
    employee = input.required<Employee>();
    contact = input.required<Contact>();
    receiptNumberExtention = input.required<string>();
    activeProducts = input.required<Map<string, Product>>();
    subsidiary: Signal<Subsidiary>;
    signature = input<string>(undefined);
    swisherAddress = "P.O. Box 2230, Jacksonville, FL 32203";
    dateTimeFormat = MY_DATE_FORMATS.display.customDateTimeInput;
    dateFormat = MY_DATE_FORMATS.display.customDateInput;
    private orderProducts = computed(() =>
        this.call()
            .orderProducts.filter((op) => op.wholesalerCustomerId === this.wholesaler().id)
            .map<OrderProductWithProduct>((orderProduct) => ({
                ...orderProduct,
                product: this.activeProducts().get(orderProduct.productId)
            }))
    );
    salesRepName = computed(() => `${this.employee().lastName}, ${this.employee().firstName} `);
    contactName = computed(() => {
        const contact = this.contact();
        return (contact?.lastName ? contact.lastName + ", " : "") + (contact?.firstName ? contact.firstName : "");
    });
    zrtAndSalesRepName = computed(() => `${this.employee().zrt ? `ZRT ${this.employee().zrt}, ` : ""}${this.salesRepName()}`);
    time = computed(() => this.call().stopTime ?? new Date());
    state = toSignal(
        toObservable(this.customer).pipe(
            switchMap((customer) => this.stateService.getByShortCode(customer.businessAddress.state)),
            map((state) => state.values)
        )
    );
    swisherOrderProducts = computed(() => this.orderProducts().filter(filterSwisher));
    easOrderProducts = computed(() => this.orderProducts().filter(filterEas));
    customerCode = computed(() => this.customer().customerWholesalers.find((cw) => cw.wholesalerId === this.wholesaler().id)?.customerCode);
    orders = computed(() => {
        const orderDates = new Map<number, OrderProductWithProduct[]>();
        const products = this.subsidiary() === Subsidiary.Swisher ? this.swisherOrderProducts() : this.easOrderProducts();
        for (const product of products) {
            if (!product.callOrderDates || product.callOrderDates.length === 0) {
                const orderDate = new Date();
                orderDate.setHours(0, 0, 0, 999);
                product.callOrderDates = [{ orderDate, quantity: product.quantity }];
            }
            for (const orderDate of product.callOrderDates.filter((od) => od.quantity > 0)) {
                const splitProduct = { ...product };
                splitProduct.quantity = orderDate.quantity;
                if (!orderDates.has(orderDate.orderDate.getTime())) {
                    orderDates.set(orderDate.orderDate.getTime(), [splitProduct]);
                } else {
                    const mapItem = orderDates.get(orderDate.orderDate.getTime());
                    mapItem.push(splitProduct);
                }
            }
        }
        return Array.from(orderDates.entries()).map(([orderDate, products]) => ({ orderDate, products }));
    });

    private stateLicenses = toSignal(
        toObservable(this.customer).pipe(
            switchMap((customer) => this.stateLicenseService.getByShortCode(customer.businessAddress.state)),
            map((stateLicenses) => stateLicenses.values)
        )
    );

    formattedAddress = computed(() => {
        const address = this.customer().businessAddress;
        return `${address.address1} ${address.address2 ? address.address2 : ""}, ${address.city}, ${address.state} ${address.zip}`;
    });

    receiptNumber = computed(() => {
        const receiptNumberExtention = this.receiptNumberExtention();
        const call = this.call();
        let receiptNumber = call.callReceipts.find(cr => cr.receiptNumberExtention === receiptNumberExtention)?.receiptNumber;
        if (!receiptNumber) {
            receiptNumber = `${this.employee().zrt}${call.receiptBase}`
        }
        return `${receiptNumber}${receiptNumberExtention}`;
    })

    stateOptLicense = computed(() =>
        this.customer().customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive)
    );
    wholesalerOptLicense = computed(() =>
        this.wholesaler().customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateOTPLicense && cl.isActive)
    );
    stateVaporLicense = computed(() =>
        this.customer().customerLicenses.find((cl) => cl.licenseTypeId === LicenseTypes.RetailStateVaporLicense && cl.isActive)
    );

    swisherLicense = computed(() => this.stateLicenses()?.find((sl) => sl.subsidiaryId === Subsidiary.Swisher)?.number ?? "");
    easLicense = computed(() => this.stateLicenses()?.find((sl) => sl.subsidiaryId === Subsidiary.EAS)?.number ?? "");

    

    hasSwisherProducts = computed(() => this.swisherOrderProducts().length > 0);
    hasEasProducts = computed(() => this.easOrderProducts().length > 0);
    viewModel = computed(() => {
        const vm = new WholesaleReceiptViewmodel();
        vm.customerCode = this.customerCode();
        vm.wholesaler = this.wholesaler();
        const orderProducts = this.subsidiary() === Subsidiary.EAS ? this.easOrderProducts() : this.swisherOrderProducts();
        vm.orders = buildOrders(orderProducts, this.wholesaler());
        vm.subsidiary = this.subsidiary() === Subsidiary.EAS ? SubsidiaryTypes.eas : SubsidiaryTypes.swisher;
        vm.retailOptLicense = this.stateOptLicense() ? buildReceiptLicense(LicenseTypes.RetailStateOTPLicense, this.stateOptLicense()?.licenseNumber) : undefined;
        vm.retailVaporLicense = this.stateVaporLicense() ? buildReceiptLicense(LicenseTypes.RetailStateVaporLicense, this.stateVaporLicense()?.licenseNumber) : undefined;
        vm.wholesalerRetailOptLicense = this.wholesalerOptLicense() ? buildReceiptLicense(LicenseTypes.RetailStateOTPLicense, this.wholesalerOptLicense()?.licenseNumber) : undefined;
        vm.receiptNumber = this.receiptNumber();
        return vm;
    })
}

const filterSwisher = (orderProduct: OrderProductWithProduct) =>
    Helper.equalsIgnoreCase(orderProduct.product?.subsidiary, SubsidiaryTypes.swisher) ||
    Helper.equalsIgnoreCase(orderProduct.product?.subsidiary, SubsidiaryTypes.rogueholdingsLlc);
const filterEas = (callProduct: OrderProductWithProduct) => Helper.equalsIgnoreCase(callProduct.product?.subsidiary, SubsidiaryTypes.eas);



const buildOrders = (orderProducts: OrderProductWithProduct[], wholesaler: Customer): OrderViewmodel[] => {
    const orders: OrderViewmodel[] = [];
    const orderVMs: CallOrderProductViewModel[] = [];
    for (const orderProduct of orderProducts) {
        const order = new CallOrderProductViewModel(false, orderProduct.callOrderDates);
        order.id = orderProduct.productId
        order.product = orderProduct.product;
        order.quantity = orderProduct.quantity;
        order.units = orderProduct.units;
        order.uin = orderProduct.uin;
        order.oldUnits = order.units;
        order.oldQuantity = order.quantity;
        order.upc = orderProduct.upc;
        order.wholesaler = [new WholesalerViewmodel(wholesaler)];
        order.wholesalers = order.wholesaler;
        orderVMs.push(order);
    }
    const orderDates = new Map<number, CallOrderProductViewModel[]>();
    for (const product of orderVMs) {
        if (!product.orderDates || product.orderDates.length === 0) {
            const orderDate = new Date();
            orderDate.setHours(0, 0, 0, 999);
            product.orderDates = [{ orderDate, quantity: product.quantity }];
        }
        for (const orderDate of product.orderDates.filter((od) => od.quantity > 0)) {
            const splitProduct = { ...product } as CallOrderProductViewModel;
            splitProduct.quantity = orderDate.quantity;
            if (!orderDates.has(orderDate.orderDate.getTime())) {
                orderDates.set(orderDate.orderDate.getTime(), [splitProduct]);
            } else {
                const mapItem = orderDates.get(orderDate.orderDate.getTime());
                mapItem.push(splitProduct);
            }
        }
    }

    for (const entry of orderDates.entries()) {
        const order = new OrderViewmodel();
        order.orderDate = new Date(entry[0]);
        order.callOrderProductViewmodels = orderDates.get(entry[0]);
        orders.push(order);
    }

    return orders;
}
const buildReceiptLicense = (licenseTypeId: LicenseTypes, licenseNumber: string): CallReceiptLicense => {
    const callReceiptLicense = new CallReceiptLicense();
    callReceiptLicense.id = newSequentialId();
    callReceiptLicense.licenseNumber = licenseNumber;
    callReceiptLicense.licenseTypeId = licenseTypeId;

    return callReceiptLicense;
}



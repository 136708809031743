<div *ngIf="hasEasProducts()">
    <div class="row prow">
        <div class="col pcol center image-margin-top">
            <img *ngIf="printLogo" [src]="printLogo" alt="Eas Print Logo" />
        </div>
    </div>
    <div class="row prow">
        <div class="col pcol center"><b>E-Alternative Solutions Ship To Form</b></div>
    </div>
    <div class="row prow">
        <div class="col pcol center">
            {{swisherAddress}}
        </div>
    </div>
    <div class="row prow">
        <div class="col pcol-5 center">{{ time() | date: dateTimeFormat }}</div>
        <div class="col pcol-6 center">
            {{zrtAndSalesRepName()}}
        </div>
    </div>
    <div class="row prow">
        <div class="col pcol center">
            <div>EAS State License # {{ easLicense() }}</div>
        </div>
    </div>
    <hr />
    <div>
        <div class="row prow">
            <div class="col pcol center">
                {{ customer()?.businessAddress?.name }} - Receipt #{{receiptNumber()}}
            </div>
        </div>
        <div *ngIf="customerCode()" class="row prow">
            <div class="col pcol center">
                Distributor Acct # for Retailer {{customerCode()}}
            </div>
        </div>
        <div *ngIf="stateVaporLicense()?.licenseNumber" class="row prow">
            <div class="col pcol center">
                Retail State Vapor License # {{ stateVaporLicense().licenseNumber }}
            </div>
        </div>
        <div class="row prow center">
            <div class="col pcol center">Retail State OTP License # {{stateOptLicense()?.licenseNumber ?
                stateOptLicense().licenseNumber : 'None Listed'}}</div>
        </div>
        <div class="row prow">
            <div class="col pcol center">
                {{ formattedAddress() }}
            </div>
        </div>
        <div *ngIf="customer()?.businessAddress?.county" class="row prow">
            <div class="col pcol center">{{ customer().businessAddress.county + ' County' }}</div>
        </div>
        <hr />
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">{{wholesaler().name}}</div>
        </div>
        <div *ngIf="wholesaler()" class="row prow">
            <div class="col pcol center">State OTP License ID {{wholesalerOptLicense()?.licenseNumber}}</div>
        </div>
        <div *ngIf="orders()?.length > 0">
            <div *ngFor="let order of orders()">
                <br />
                <div class="row prow">
                    <div class="col pcol center">
                        <b>ITEMS TO BE DELIVERED</b>
                    </div>
                </div>
                <div class="row prow">
                    <div class="col pcol">
                        <b>UIN</b>
                    </div>
                    <div class="col pcol center">
                        <b>Order Date {{ order.orderDate| date: dateFormat}}</b>
                    </div>
                    <div class="col pcol center">
                        <b>Qty</b>
                    </div>
                </div>

                <div *ngFor="let orderProduct of order.products" class="row prow">
                    <div class="col pcol">
                        {{ orderProduct.uin }}
                    </div>
                    <div class="col pcol center">
                        {{ orderProduct.product?.description }}
                    </div>
                    <div class="col pcol center">
                        {{ orderProduct.quantity }}
                    </div>
                </div>
                <hr />
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol">
                Salesrep: ({{salesRepName()}})
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol larger">
                <b>Customer Representative:</b>
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol">
                <img *ngIf="signature()" [src]="signature()" alt="Signature" width="400" height="100" />
                <hr class="no-top-margin" />
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol">Signature ({{contactName()}})</div>
            <br />
        </div>
        <div class="row prow">
            <div class="col pcol center">
                Falsification of this form is grounds for immedaite dismissal
            </div>
        </div>
        <div class="row prow">
            <div class="col pcol center">Receipt #{{receiptNumber()}}</div>
        </div>
    </div>
</div>
<div *ngIf="call && !!isReadOnly" class="d-flex flex-column flex-grow-1">
    <div [ngClass]="isPanel ? 'panel-size' : 'not-panel-size'">
        <div class="row">
            <div class="col scroll-activities padding-left-activities">
                <div *ngIf="displayActivities?.length">
                    <ul class="nopad" *ngFor="let vm of displayActivities; let i = index">
                        <h3 class="closing-notes-activity-heading">
                            {{ vm.title }}
                        </h3>
                        <ul *ngFor="let response of vm.surveyResponses">
                            <div class="property-item">
                                <h4>{{ response.text }}:</h4>
                                <section *ngIf="
                                            response.surveyQuestionType.id ===
                                            surveyQuestionTypes.CheckBox
                                        " class="example-section">
                                    <div class="padding-left">
                                        <h4>
                                            {{
                                            response.answer.answer ===
                                            "true"
                                            ? "Y"
                                            : "N"
                                            }}
                                        </h4>
                                    </div>
                                </section>
                                <section *ngIf="
                                            response.surveyQuestionType.id ===
                                            surveyQuestionTypes.Integer
                                        ">
                                    <div class="padding-left">
                                        <h4 *ngIf="
                                            response.answer.answer
                                                ">
                                            {{
                                                response.answer.answer
                                            }}
                                        </h4>
                                        <h4 *ngIf="
                                                    !response.answer.answer
                                                ">
                                            {{ 0 }}
                                        </h4>
                                    </div>
                                </section>
                                <section *ngIf="response.surveyQuestionType.id !== surveyQuestionTypes.CheckBox
                                    && response.surveyQuestionType.id !== surveyQuestionTypes.Integer">
                                    <div class="padding-left">
                                        <h4>
                                            {{ response.answer.answer ? response.answer.answer : "N/A" }}
                                        </h4>
                                    </div>
                                </section>
                            </div>
                        </ul>
                    </ul>
                </div>
                <div *ngIf="!displayActivities?.length">
                    <h3 class="closing-notes-activity-heading">
                        No Activities Completed
                    </h3>
                </div>
            </div>
            <div class="col scroll-survey">
                <div class="padding-right" *ngIf="displaySurveys?.length">
                    <ul class="nopad" *ngFor="let vm of displaySurveys; let i = index">
                        <h3 class="closing-notes-activity-heading">
                            {{ vm.title }}
                        </h3>
                        <ul *ngFor="let response of vm.surveyResponses">
                            <div class="property-item">
                                <h4>{{ response.text }}:</h4>
                                <section *ngIf="
                                            response.surveyQuestionType.id ===
                                            surveyQuestionTypes.CheckBox
                                        " class="example-section">
                                    <div class="padding-left">
                                        <h4>
                                            {{
                                            response.answer.answer ===
                                            "true"
                                            ? "Y"
                                            : "N"
                                            }}
                                        </h4>
                                    </div>
                                </section>
                                <section *ngIf="
                                            response.surveyQuestionType.id ===
                                            surveyQuestionTypes.Integer
                                        ">
                                    <div class="padding-left">
                                        <h4 *ngIf="
                                            response.answer.answer
                                                ">
                                            {{
                                                response.answer.answer
                                            }}
                                        </h4>
                                        <h4 *ngIf="
                                                    !response.answer.answer
                                                ">
                                            {{ 0 }}
                                        </h4>
                                    </div>
                                </section>
                                <section *ngIf="response.surveyQuestionType.id !== surveyQuestionTypes.CheckBox
                                    && response.surveyQuestionType.id !== surveyQuestionTypes.Integer">
                                    <div class="padding-left">
                                        <h4>
                                            {{ response.answer.answer ? response.answer.answer : "N/A" }}
                                        </h4>
                                    </div>
                                </section>
                            </div>
                        </ul>
                    </ul>
                </div>
                <div *ngIf="!displaySurveys?.length">
                    <h3 class="closing-notes-activity-heading">
                        No Surveys Completed
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-card appearance="outlined" *ngIf="!isReadOnly" class="mt-3" class="d-flex flex-column flex-grow-1">
    <div *ngIf="call">
        <div class="row">
            <ng-container *ngIf="!call?.surveys?.length">
                <div class="col-12 text-center" [ngClass]="isPanel ? 'panel-size' : 'not-panel-size'">
                    <h1 class="m-teal margin-top-revert">
                        No activities or surveys found for this customer.
                    </h1>
                </div>
            </ng-container>
            <ng-container *ngIf="call?.surveys?.length">
                <div class="col">
                    <div class="vertically-scrollable" [ngClass]="isPanel ? 'panel-size' : 'not-panel-size'">
                        <h1 class="m-teal margin-top-revert">
                            Activities
                        </h1>
                            <ng-container *ngIf="shouldShowEmptyMessage(call?.surveys, surveyTypes.Activity)">
                                <h2 class="m-teal">No activities found for this customer.</h2>
                            </ng-container>
                            <mat-accordion #activities class="headers-align" multi>
                                <ul *ngFor="
                                    let activity of call.surveys;
                                    let i = index
                                ">
                                    <div *ngIf="activity.surveyType?.id === surveyTypes.Activity">
                                        <mat-expansion-panel [expanded]="true">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <h2 class="m-teal">
                                                        <u>
                                                            {{ activity.title }}
                                                        </u>
                                                    </h2>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ul *ngFor="
                                                let response of call.surveys[i].surveyResponses;
                                                let j = index
                                            ">
                                                <div [ngClass]="{
                                                        'mobile-column':
                                                            response.surveyQuestionType.id ===
                                                            surveyQuestionTypes.Text ||
                                                            response.surveyQuestionType.id === surveyQuestionTypes.Integer
                                                    }">
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.CheckBox
                                                        " class="example-section">
                                                        <mat-checkbox
                                                            [color]="'accent'"
                                                            [checked]="response.answer.answer === 'true'"
                                                            (change)="onSurveyResponse($event.checked, response)"
                                                            class="example-margin">
                                                            {{ response.text }}
                                                        </mat-checkbox>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Integer
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                digitOnly
                                                                mask="999999"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Decimal
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                digitOnly
                                                                [decimal]="true"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Text
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                maxLength="50"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.DropDown
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{ response.text }}</mat-label>
                                                            <mat-select
                                                                [(value)]="response.surveyQuestionOptionDefault"
                                                                (valueChange)="onSurveyResponse($event, response)">
                                                                <mat-option
                                                                    *ngFor="let option of response.surveyQuestionOptions"
                                                                    [value]="option"
                                                                    >
                                                                    {{ option.text }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </section>
                                                </div>
                                            </ul>
                                        </mat-expansion-panel>
                                    </div>
                                </ul>
                        </mat-accordion>
                    </div>
                </div>
                <div class="col">
                    <div class="vertically-scrollable" [ngClass]="isPanel ? 'panel-size' : 'not-panel-size'">
                        <h1 class="m-teal margin-top-revert">
                            Surveys
                        </h1>
                            <ng-container *ngIf="shouldShowEmptyMessage(call?.surveys, surveyTypes.Survey)">
                                <h2 class="m-teal">No surveys found for this customer.</h2>
                            </ng-container>
                            <mat-accordion #activities class="headers-align" multi>
                                <ul *ngFor="
                                    let survey of call.surveys;
                                    let i = index
                                ">
                                    <div *ngIf="survey.surveyType?.id === surveyTypes.Survey">
                                        <mat-expansion-panel [expanded]="true">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <h2 class="m-teal margin-bottom-unset">
                                                        <u>
                                                            {{ survey.title }}
                                                        </u>
                                                    </h2>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <ul *ngFor="
                                                let response of call.surveys[i].surveyResponses;
                                                let j = index
                                            ">
                                                <div [ngClass]="{
                                                        'mobile-column':
                                                            response.surveyQuestionType.id ===
                                                            surveyQuestionTypes.Text ||
                                                            response.surveyQuestionType.id === surveyQuestionTypes.Integer
                                                    }">
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.CheckBox
                                                        " class="example-section">
                                                        <mat-checkbox
                                                            [color]="'accent'"
                                                            [checked]="response.answer.answer === 'true'"
                                                            (change)="onSurveyResponse($event.checked, response)"
                                                            class="example-margin">
                                                            {{ response.text }}
                                                        </mat-checkbox>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Integer
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                digitOnly
                                                                mask="999999"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Decimal
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                digitOnly
                                                                [decimal]="true"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.Text
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{
                                                                response.text
                                                                }}</mat-label>
                                                            <input matInput
                                                                type="text"
                                                                maxLength="50"
                                                                [(ngModel)]="response.answer.answer"
                                                                (ngModelChange)="onSurveyResponse($event, response)"/>
                                                        </mat-form-field>
                                                    </section>
                                                    <section *ngIf="
                                                        response.surveyQuestionType.id === surveyQuestionTypes.DropDown
                                                        ">
                                                        <mat-form-field appearance="outline" class="w-100" [color]="'accent'">
                                                            <mat-label>{{ response.text }}</mat-label>
                                                            <mat-select
                                                                [(value)]="response.surveyQuestionOptionDefault"
                                                                (valueChange)="onSurveyResponse($event, response)">
                                                                <mat-option
                                                                    *ngFor="let option of response.surveyQuestionOptions"
                                                                    [value]="option"
                                                                    >
                                                                    {{ option.text }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </section>
                                                </div>
                                            </ul>
                                        </mat-expansion-panel>
                                    </div>
                                </ul>
                        </mat-accordion>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</mat-card>

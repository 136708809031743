import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { BasicDialogViewModel } from "src/app/dialogs/basic-dialog/basic-dialog.viewmodel";
import { Customer } from "src/app/entity-models/customer.entity";
import { Helper } from "src/app/helpers/helper";
import { RelatedWholesalerViewmodel } from "../related-wholesalers.viewmodel";

export interface WholesalerDropdownItem {
    id: string;
    wholesaler: Customer;
    label: string;
}

export class EditWholesalersViewModel extends BasicDialogViewModel {
    wholesalers: WholesalerDropdownItem[];
    wholesaler: WholesalerDropdownItem[];

    wholesalerToEdit: Customer;
    customerCode: string;

    fb: UntypedFormBuilder;
    fg: UntypedFormGroup;
    codeSubscription: Subscription;

    shouldDelete: boolean = false;

    wholeSalerDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Wholesaler *",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "label",
        searchBy: ["label"],
        classes:"multi-select-container c-btn pure-checkbox"
    }

    constructor(wholesalerVm: RelatedWholesalerViewmodel, wholesalers: Customer[]) {
        super();
        this.wholesalers = (wholesalers ?? []).sort((a, b) => a?.name?.localeCompare(b?.name)).map((w) => this.buildDropdownItem(w));
        if (wholesalerVm.wholesaler) {
            this.wholesalerToEdit = wholesalerVm.wholesaler;
            this.customerCode = wholesalerVm.customerCode;

            this.buttonLeftText = "Remove Wholesaler";
            this.buttonLeftDisabledFunction = () => false;

            this.buttonMiddleText = "Close";
            this.buttonMiddleDisabledFunction = () => false;
        } else {
            this.buttonLeftText = "Close";
            this.buttonLeftDisabledFunction = () => false;
        }
        this.width = "30vw";

        this.buttonRightText = "Save";
        this.buttonRightDisabledFunction = () => this.isSaveDisabled();

        this.fb = new UntypedFormBuilder;
        this.fg = this.fb.group({
            codeControl: new UntypedFormControl(this.customerCode, Validators.required)
        });
        this.codeSubscription = this.fg.controls["codeControl"].valueChanges.subscribe((value) => {
            this.customerCode = value;
        });
    }

    onSelect(w: WholesalerDropdownItem) {
        this.wholesaler = [w];
    }

    buildDropdownItem(w: Customer): WholesalerDropdownItem {
        return {
            id: w.id,
            wholesaler: w,
            label: w.displayName
        }
    }

    isSaveDisabled(): boolean {
        return !this.customerCode || (!this.wholesaler && !this.wholesalerToEdit);
    }

    select(element: HTMLInputElement): void {
        Helper.selectInputText(element);
    }

    unsubscribe(): void {
        if (this.codeSubscription && !this.codeSubscription.closed) {
            this.codeSubscription.unsubscribe();
        }
    }
}

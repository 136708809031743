import { Component, ElementRef, QueryList, Signal, ViewChildren, signal } from "@angular/core";
import { WholesalerBaseComponent } from "../wholesaler-format-base/wholesaler-base.component";
import { Subsidiary } from "shield.shared";

@Component({
    selector: "app-eas-wholesaler-format",
    templateUrl: "./eas-wholesaler-format.component.html",
    styleUrls: ["./eas-wholesaler-format.component.scss"]
})
export class EasWholesalerFormatComponent extends WholesalerBaseComponent {

    @ViewChildren("originalEasWholesaleReceipt") originalEasWholesaleReceipt: QueryList<ElementRef<HTMLElement>>;

    printLogo = "/assets/img/easPrintLogo.jpg";
    subsidiary = signal(Subsidiary.EAS);
}

export class OrderLineItem {
    id: string;
    orderId: string;
    productId: string;
    productDescription: string;
    quantity: number;
    orderLineItemStatusLookupId: number;
    uin: string;
    upc: string;
    unitsPerUpc?: number;
    movedToNewOrderId: string | null;
    createdUserId: string;
    createdUtcDateTime: Date;
    createdUserZrt: string;
    createdUserFullName: string
    modifiedUtcDateTime: Date;
    modifiedUserId: string;
    modifiedUserZrt: string;
    modifiedUserFullName: string;

}

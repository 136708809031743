import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import moment from "moment";
import { Subscription } from "rxjs";
import { EmployeeRoleType, ProjectStatuses, SharedHelper } from "shield.shared";
import { Employee } from "src/app/entity-models/employee.entity";
import { AppStateService } from "src/app/services/app-state.service";
import { ProjectStateService } from "src/app/services/project-state-service";
import { MY_DATE_FORMATS } from "src/app/shared/constants/date-formats";
import { ProjectApplicationService } from "../../../project-services/project-application.service";

enum ReleaseDates {
    visibleDate,
    startDate,
    endDate
}

export class ProjectSummaryReleaseDatesViewmodel {
    readonly releaseDates = ReleaseDates;

    releaseDateForm: UntypedFormGroup;
    dateFormat: string = MY_DATE_FORMATS.display.dateInput;
    jsDateFormat: string = MY_DATE_FORMATS.display.customDateInput;
    formBuilder: UntypedFormBuilder;

    visibleForFieldSubscription: Subscription;
    startDateSubscription: Subscription;
    endDateSubscription: Subscription;
    projectSubscription: Subscription;
    employeeSubscription: Subscription;

    visibleForFieldMin = new Date();
    startMin = new Date();
    endMin = new Date();
    isReadOnly = true;
    employee: Employee;

    appStateService: AppStateService;
    projectApplicationService: ProjectApplicationService;
    projectStateService: ProjectStateService;

    constructor(formBuilder: UntypedFormBuilder
        , projectApplicationService: ProjectApplicationService
        , projectStateService: ProjectStateService
        , appStateService: AppStateService) {

        this.formBuilder = formBuilder;
        this.projectApplicationService = projectApplicationService;
        this.projectStateService = projectStateService;
        this.appStateService = appStateService;

        this.visibleForFieldMin.setHours(0, 0, 0);
        this.startMin.setHours(0, 0, 0);
        this.endMin = SharedHelper.addDays(this.endMin, 1);
        this.endMin.setHours(0, 0, 0);

        this.releaseDateForm = this.formBuilder.group({
            visibleForFieldDate: ["", [Validators.required, this.greaterThanStartDate()]],
            startDate: ["", [Validators.required, this.dateGreaterThanEndDate()]],
            endDate: ["", [Validators.required]],
        })
    }

    initialize(): void {

        if (!this.visibleForFieldSubscription || this.visibleForFieldSubscription.closed) {
            this.visibleForFieldSubscription = this.releaseDateForm.controls["visibleForFieldDate"].valueChanges.subscribe((visibleForFieldDate) => {
                    this.projectStateService.project.visibleDate = (visibleForFieldDate as moment.Moment)
                        ? (visibleForFieldDate as moment.Moment).toDate()
                        : null;
            });
        }

        if (!this.startDateSubscription || this.startDateSubscription.closed) {
            this.startDateSubscription = this.releaseDateForm.controls["startDate"].valueChanges.subscribe((startDate) => {
                if (!this.releaseDateForm.get("startDate").hasError("required")
                    && !this.releaseDateForm.get("startDate").hasError("dateGreaterThanEndDate")) {
                    this.projectStateService.project.startDate = (startDate as moment.Moment).toDate();
                    this.releaseDateForm.controls["visibleForFieldDate"].updateValueAndValidity();
                }
            });
        }

        if (!this.endDateSubscription || this.endDateSubscription.closed) {
            this.endDateSubscription = this.releaseDateForm.controls["endDate"].valueChanges.subscribe((endDate) => {
                if (!this.releaseDateForm.get("endDate").hasError("required")) {
                    this.projectStateService.project.endDate = (endDate as moment.Moment).toDate();
                    this.releaseDateForm.controls["startDate"].updateValueAndValidity();
                    this.releaseDateForm.controls["visibleForFieldDate"].updateValueAndValidity();
                }
            });
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee.subscribe((employee) => {
                if (employee) {
                    this.employee = employee;
                    this.setPermissions();
                }
            });
        }

        if (!this.projectSubscription || this.projectSubscription.closed) {
            this.projectSubscription = this.projectStateService.observableProject.subscribe((project) => {
                if (project) {
                    if (project.visibleDate) {
                        this.releaseDateForm.controls.visibleForFieldDate.setValue(moment(project.visibleDate));
                    }
                    if (project.startDate) {
                        this.releaseDateForm.controls.startDate.setValue(moment(project.startDate));
                    }
                    if (project.endDate) {
                        this.releaseDateForm.controls.endDate.setValue(moment(project.endDate));
                    }

                    this.setPermissions();
                }
            });
        }
    }

    setPermissions(): void {
        if (this.employee && this.projectStateService.project) {
        this.isReadOnly = !(this.employee.employeeRoles ?? []).some((er) => er.employeeRoleType.id !== EmployeeRoleType.TM)
                        || this.projectStateService.project.projectStatusId === ProjectStatuses.Completed
                        || this.projectStateService.project.projectStatusId === ProjectStatuses.Canceled
                        || this.isReadOnly;
        }
    }

    // validation for visibleForFieldDate
    getReadOnlyStatus(date: ReleaseDates): boolean {
        switch (date) {
            case ReleaseDates.visibleDate:
                return !this.projectStateService.project
                    || this.projectStateService.project.projectStatusId === ProjectStatuses.Visible
                    || this.projectStateService.project.projectStatusId === ProjectStatuses.Started
                    || this.projectStateService.isReadOnly
                    || this.isReadOnly;
            case ReleaseDates.startDate:
                return !this.projectStateService.project
                    || this.projectStateService.project.projectStatusId === ProjectStatuses.Started
                    || this.projectStateService.isReadOnly
                    || this.isReadOnly;
            case ReleaseDates.endDate:
                return this.projectStateService.isReadOnly
                    || this.isReadOnly;
        }
    }

    greaterThanStartDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentVisibleForField: moment.Moment = control.value as moment.Moment;

            if (momentVisibleForField) {
                const visibleDate = momentVisibleForField.startOf("day").valueOf();

                if (this.releaseDateForm.controls.startDate.value) {
                    const startDate = (this.releaseDateForm.controls.startDate
                        .value as moment.Moment)
                        .startOf("day")
                        .valueOf();
                    if (startDate < visibleDate) {
                        forbidden = true;
                    }
                }
            }

            return forbidden ? { dateGreaterThanStartDate: true } : null;
        };
    }

    dateGreaterThanEndDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let forbidden = false;

            const momentStartDate: moment.Moment = control.value as moment.Moment;

            if (momentStartDate) {
                const startDate = momentStartDate.startOf("day").valueOf();

                if (this.releaseDateForm.controls.endDate.value) {
                    const endDate = (this.releaseDateForm.controls.endDate
                        .value as moment.Moment)
                        .startOf("day")
                        .valueOf();
                    if (endDate < startDate) {
                        forbidden = true;
                    }
                }
            }

            return forbidden ? { dateGreaterThanEndDate: true } : null;
        };
    }
    
    blockNonDateCharacters(event: any)
    {
        return(event.charCode > 46 && event.charCode < 58);
    }

    async cancelProject(): Promise<void> {
        await this.projectApplicationService.cancelProject();
    }

    async cancel(): Promise<void> {
        await this.projectApplicationService.cancel();
    }

    unsubscribe(): void {
        if (this.visibleForFieldSubscription && !this.visibleForFieldSubscription.closed) {
            this.visibleForFieldSubscription.unsubscribe();
        }
        if (this.startDateSubscription && !this.startDateSubscription.closed) {
            this.startDateSubscription.unsubscribe();
        }
        if (this.endDateSubscription && !this.endDateSubscription.closed) {
            this.endDateSubscription.unsubscribe();
        }
        if (this.projectSubscription && !this.projectSubscription.closed) {
            this.projectSubscription.unsubscribe();
        }
        if (this.employeeSubscription && !this.employeeSubscription.closed) {
            this.employeeSubscription.unsubscribe();
        }
    }
}

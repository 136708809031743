import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Permissions } from 'shield.shared';
import { Helper } from '../helpers/helper';
import { AppStateService } from '../services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class CanActivateDirectOrderGuard implements CanActivate {

  constructor(private appState: AppStateService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.appState.currentEmployee.pipe(
      // wait until we have an actual employee
      filter(e => !!e),
      map(e => {
        if (Helper.isEmployeeInRoles(e, Permissions.directOrderReadWrite)) {
          return true;
        } else {
          return this.router.parseUrl("/")
        }
      }),
    )
  }

}

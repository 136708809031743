import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { filter, skip, take, takeUntil, takeWhile } from "rxjs/operators";
import { AppStateService } from "./services/app-state.service";
import { Helper } from "./helpers/helper";
import { PingService } from "./services/ping.service";
import { SyncService } from "./services/sync.service";
import { NgswUpdateService } from "./services/ngsw-update-service";
import { Title } from '@angular/platform-browser';

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
    title = "app";
    isAuthenticated = false;
    pingInitalized = false;
    initialSyncComplete = false;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private syncService: SyncService,
        private ngswUpdateService: NgswUpdateService,
        private msalBroadcastService: MsalBroadcastService,
        private appStateService: AppStateService,
        private pingService: PingService,
        private titleService: Title
    ) {}

    ngOnInit(): void {
        //check the ngsw for updates
        this.ngswUpdateService.checkForUpdates();

        try {
            let title = 'Swisher Shield';
            switch (window.location.hostname) {
                case 'localhost':
                    title += ' - LOCAL';
                break;
                case 'si-shield-dev-web.azurewebsites.net':
                    title += ' - DEV';
                break;
                case 'si-shield-test-web.azurewebsites.net':
                    title += ' - TEST';
                break;
                case 'si-shield-uat-web.azurewebsites.net':
                    title += ' - UAT';
                break;
                case 'si-shield-prod-app-staging.azurewebsites.net':
                    title += ' - BETA';
                break;
            }
            this.titleService.setTitle(title);
        } catch(e) {}

        this.pingService.observablePingIsInitalized.pipe(takeWhile(result => !this.pingInitalized)).subscribe((result) => this.pingInitalized = result);

        this.syncService.observableSuccessfulLevelOneSync.pipe(take(1)).subscribe(() => {
            this.initialSyncComplete = true;
        });

        this.appStateService.isAuthenticated$.pipe(takeUntil(this._destroying$)).subscribe((result) => {
            this.isAuthenticated = result.authenticated;
        });
    }

    // Remove the lint ignore when the push service is added back
    // eslint-disable-next-line @typescript-eslint/require-await
    async ngOnDestroy(): Promise<void> {
        console.log("ngOnDestroy called");
        //await this.pushService.destroy();
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this.isAuthenticated = false;
    }

    // DMC - Push currently disabled
    // private async handlePushMessage(msg: any) {
    //     console.log("Received push message", msg);

    //     if (msg.body && msg.title && msg.tag) {
    //         if (Notification.permission === "granted") {
    //             new Notification(msg.title, {
    //                 body: msg.body,
    //                 tag: msg.tag
    //             });
    //         }
    //     }
    // }
}

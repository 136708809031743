import {
    Component,
    ElementRef, HostBinding,
    Input,
    OnInit,
    ViewChild
} from "@angular/core";
import { BehaviorSubject, fromEvent, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { RetailStepperStep } from "src/app/enums/retail-stepper-step";
import { Helper } from "src/app/helpers/helper";
import {
    CallTypes,
    GenericDropDownDto,
    ProjectStatuses,
} from "shield.shared";
import { CustomerGenericTypes } from "src/app/enums/customer-generic-types";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { DistributionActionItems } from "../stepper-call-enums/distribution-action-items";
import { Call, CallService } from "../../call-services/call.service";
import { CallDistributionProductViewModel } from "../../call-viewmodels/call-distribution-product.viewmodel";
import { RadioButtonViewModel } from "../../../../shared/viewmodels/radio-button.viewmodel";
import { CustomerStateService } from "src/app/accounts/account-services/customer-state.service";
import { CallDistributionService } from "../../call-services/call-distribution.service";
import { PleaseWaitService } from "src/app/services/please-wait.service";
import { DistributionProperties } from "../stepper-call-enums/distribution-properties";
import { RetailCall } from "src/app/entity-models/retail-call.entity";
import { SnackbarService } from "src/app/services/snackbar.service";
import { ProductViewmodel } from "./product.viewmodel";
import { CallDistributionViewModel } from "../../call-viewmodels/call-distribution.viewmodel";
import { SwisherOverlayRef } from "src/app/overlay/swisher-overlay-ref";
import { DistributionDialogComponent } from "src/app/dialogs/distribution-dialog/distribution-dialog.component";
import { WholesalerViewmodel } from "src/app/shared/viewmodels/wholesaler.viewmodel";
import { ProductFilterCategories } from "src/app/enums/product-filter-categories";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ProductDelineationService } from "src/app/services/delineation-services/product-delineation.service";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { StepperCallApplicationService } from "../stepper-call-services/stepper-call-application.service";
import { RmWholesaleStepperStep } from "src/app/enums/rm-wholesale-stepper-step";
import { RmWholesaleCall } from "src/app/entity-models/rm-wholesale-call.entity";
import { Customer } from "src/app/entity-models/customer.entity";
import { ProjectDelineationService } from "src/app/services/delineation-services/project-delineation.service";
import { AppStateService } from "src/app/services/app-state.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Employee } from "src/app/entity-models/employee.entity";
import { Project } from "src/app/entity-models/project.entity";
import { WholesalerGroupProductCatalogItemDelineationService } from "src/app/services/delineation-services/wholesaler-group-product-catalog-item-delineation.service";
@UntilDestroy()
@Component({
    selector: "app-distribution-grid",
    templateUrl: "./distribution-grid.component.html",
    styleUrls: ["./distribution-grid.component.scss"]
})
export class DistributionGridComponent implements OnInit {
    @HostBinding("class") class = "worksheet-static d-flex flex-column flex-grow-1";
    @ViewChild("search") search: ElementRef;
    @ViewChild("viewport") viewport: CdkVirtualScrollViewport;

    //Inputs
    @Input()
    get isOrderMode(): boolean {
        return this._isOrderMode;
    }
    set isOrderMode(value: boolean) {
        if (value) {
            this._isOrderMode = value;
        }
    }

    @Input()
    get mode(): DistributionActionItems {
        return this._mode;
    }
    set mode(value: DistributionActionItems) {
        this._mode = value;
    }

    //Private vars
    private _isOrderMode: boolean;
    private lastCall: Call;
    private _mode: DistributionActionItems;
    private customer: Customer;
    private callId: string;
    private customerType: CustomerGenericTypes;
    private callType: CallTypes;
    private hasInjectedData = false;
    private customerSubscription: Subscription;
    private callSubscription: Subscription;
    private employeeSubscription: Subscription;
    private employee: Employee;
    private savedProjectSelection: Project;

    //Public vars
    categories: RadioButtonViewModel[] = [];
    subsidiaries: RadioButtonViewModel[] = [];
    displayedCallProducts: CallDistributionProductViewModel[] = [];
    filterProductRadioGroup: RadioButtonViewModel[] = [];
    selectedFilteredProduct: string;
    selectedCategoryProduct: string;
    selectedSubsidiary: string;
    isToggleInDistDisabled = true;
    isOnnInitPass = true;
    isWholesaler = false;
    products: ProductViewmodel[] = [];
    projectDropDown = new Array<GenericDropDownDto>();
    projects = new Array<Project>();
    searchText = "";
    selectedIndex: RetailStepperStep | RmWholesaleStepperStep;
    selectedProject = new Array<GenericDropDownDto>();
    searchValueSubscription: Subscription;
    selectedIndexSubscription: Subscription;
    shouldWait$ = new BehaviorSubject<boolean>(true);
    isFinalRetailReceiptPrinted = false;
    isFinalWholesaleReceiptPrinted = false;
    wholesalers: WholesalerViewmodel[] = new Array<WholesalerViewmodel>();
    selectedWholesaler: WholesalerViewmodel[];
    productSubscription: Subscription;
    wholesalerMappedProductIds = new Array<string>();
    isGenericWholesalerStoreType = false;

    //https://cuppalabs.github.io/components/multiselectDropdown/
    projectsDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Project",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: this.hasInjectedData ? "multi-select-container-modal c-btn pure-checkbox" : "multi-select-container c-btn pure-checkbox"
    };

    //https://cuppalabs.github.io/components/multiselectDropdown/
    wholesalerDropdownSettings = {
        singleSelection: true,
        showCheckbox: false,
        text: "Select Wholesaler",
        enableCheckAll: false,
        enableFilterSelectAll: false,
        enableSearchFilter: true,
        lazyLoading: true,
        badgeShowLimit: 1,
        labelKey: "displayValue",
        searchBy: ["displayValue"],
        classes: "multi-select-container c-btn pure-checkbox"
    };

    constructor(
        private appStateService: AppStateService,
        private callService: CallService,
        public stepperCallApplicationService: StepperCallApplicationService,
        private productDelineationService: ProductDelineationService,
        private projectDelineationService: ProjectDelineationService,
        private customerStateService: CustomerStateService,
        public callDistributionService: CallDistributionService,
        private pleaseWaitService: PleaseWaitService,
        private customerDelineationService: CustomerDelineationService,
        private snackbarService: SnackbarService,
        private overlayRef: SwisherOverlayRef<
            CallDistributionViewModel,
            DistributionDialogComponent
        >,
        private wholesalerGroupProductCatalogItemDelineationService: WholesalerGroupProductCatalogItemDelineationService
    ) {}

    async ngOnInit(): Promise<void> {
        if (this.overlayRef.data) {
            this.hasInjectedData = true;
        } else {
            this.hasInjectedData = false;
        }

        this.projectsDropdownSettings = {
            singleSelection: true,
            showCheckbox: false,
            text: "Select Project",
            enableCheckAll: false,
            enableFilterSelectAll: false,
            enableSearchFilter: true,
            lazyLoading: true,
            badgeShowLimit: 1,
            labelKey: "displayValue",
            searchBy: ["displayValue"],
            classes: this.hasInjectedData ? "multi-select-container-modal c-btn pure-checkbox" : "multi-select-container c-btn pure-checkbox"
        };

        for (const value of Object.values(ProductFilterCategories)) {
            const radioButton: RadioButtonViewModel = new RadioButtonViewModel();
            radioButton.name = value;
            radioButton.value = value;
            this.filterProductRadioGroup.push(radioButton);
        }

        this.selectedFilteredProduct = "All";
        this.selectedSubsidiary = 'All'

        if (!this.callSubscription || this.callSubscription.closed) {
            this.callSubscription = this.callService.observableCall
                .pipe(untilDestroyed(this))
                .subscribe(async (call) => {
                    this.callType = call?.callType;

                    if (!!this.customer) {
                        switch (call?.callType) {
                            case CallTypes.retail:
                            case CallTypes.rmWholesale:
                                this.callId = call.id;
                                this.isFinalRetailReceiptPrinted =
                                    call.isFinalRetailReceiptPrinted || call.isEmailFinalRetailReceipt;
                                this.isFinalWholesaleReceiptPrinted =
                                    call.isFinalWholesaleReceiptPrinted || call.isEmailFinalWholesaleReceipt;
                                if (
                                    (!this.products ||
                                        this.products.length === 0 ||
                                        this.callService.call?.id !==
                                            this.callId) &&
                                    this.callService.call &&
                                    ((this.callType === CallTypes.retail &&
                                        (this.selectedIndex === RetailStepperStep.inDistribution ||
                                            this.selectedIndex === RetailStepperStep.closingNotes)) ||
                                        (this.callType === CallTypes.rmWholesale &&
                                            (this.selectedIndex === RmWholesaleStepperStep.inDistribution ||
                                                this.selectedIndex ===RmWholesaleStepperStep.closingNotes)) ||
                                        this.hasInjectedData) &&
                                    this.customer.id
                                ) {
                                    this.stepperCallApplicationService.shouldBuildProducts = true;
                                    await this.builtUpProducts();
                                }
                                break;
                            case CallTypes.chainHq:
                                this.callId = call.id;
                                if (
                                    !this.products ||
                                    this.products.length === 0 ||
                                    (this.callService.call &&
                                        this.callService.call.id !== this.callId &&
                                        this.customer.id)
                                ) {
                                    await this.builtUpProducts();
                                }
                            default:
                                break;
                        }
                    }
                });
        }

        if (!this.employeeSubscription || this.employeeSubscription.closed) {
            this.employeeSubscription = this.appStateService.currentEmployee
                .pipe(untilDestroyed(this))
                .subscribe((employee) => {
                    if (employee) {
                        this.employee = employee;
                        if (this.customer) {
                            this.initalizeProjects();
                        }
                    }
                });
        }

        if (!this.customerSubscription || this.customerSubscription.closed) {
            this.customerSubscription = this.customerStateService.observableCustomer
                .pipe(untilDestroyed(this))
                .subscribe(async (customer) => {
                    if (
                        customer &&
                        (!this.products ||
                            this.products.length === 0 ||
                            (this.callService.call.callType ===
                                CallTypes.retail &&
                                (this.selectedIndex === RetailStepperStep.inDistribution ||
                                    this.selectedIndex === RetailStepperStep.closingNotes)) ||
                            (this.callService.call.callType ===
                                CallTypes.rmWholesale &&
                                (this.selectedIndex === RmWholesaleStepperStep.inDistribution ||
                                    this.selectedIndex === RmWholesaleStepperStep.closingNotes)) ||
                            this.hasInjectedData)
                    ) {
                        this.customer = customer;
                        this.isGenericWholesalerStoreType = Helper.getCustomerGenericType(this.customer) === CustomerGenericTypes.wholesale;
                        const radioButtonIndex = this.filterProductRadioGroup.findIndex((radioButton) => this.isGenericWholesalerStoreType
                            ? radioButton.name === ProductFilterCategories.orders
                            : radioButton.name === ProductFilterCategories.cash);
                        if (radioButtonIndex > -1) {
                            this.filterProductRadioGroup.splice(radioButtonIndex, 1);
                        }
                        const callType = this.callService.getCallTypeForCustomerAndEmployee(this.customer, this.employee);
                        const callResponse = await this.callService.getLastCallOfTypeByCustomerId(
                            this.customer.id,
                            callType
                        );
                        if (!callResponse) {
                            this.shouldWait$.next(false);
                            return;
                        }

                        this.lastCall = callResponse.values
                            ? callResponse.values
                            : undefined;

                        this.callService.callSubject.next(this.callService.call);
                        if (this.employee) {
                            this.initalizeProjects();
                        }
                        const type = Helper.getCustomerGenericType(
                            customer
                        );

                        await this.getWholesalerOptions();

                        this.customerType = type;
                        switch (type) {
                            case CustomerGenericTypes.retail:
                                this.stepperCallApplicationService.shouldBuildProducts = true;
                                break;
                            case CustomerGenericTypes.wholesale:
                                if (
                                    this.callService?.call?.callType ===
                                    CallTypes.rmWholesale
                                ) {
                                    this.stepperCallApplicationService.shouldBuildProducts = true;
                                }
                                break;
                            default:
                                break;
                        }
                        await this.builtUpProducts();
                    }
                });
        }

        setTimeout(() => {
            if (this.search) {
                const searchValue = fromEvent(
                    this.search.nativeElement,
                    "input"
                ).pipe(
                    map(
                        (e: InputEvent) => (e.target as HTMLInputElement).value
                    ),
                    debounceTime(500),
                    distinctUntilChanged(),
                    untilDestroyed(this)
                );

                if (
                    !this.searchValueSubscription ||
                    this.searchValueSubscription.closed
                ) {
                    this.searchValueSubscription = searchValue.subscribe(() => {
                        if (
                            !this.products ||
                            this.products.length === 0 ||
                            (this.callService.call.callType ===
                                CallTypes.retail &&
                                (this.selectedIndex ===
                                    RetailStepperStep.inDistribution ||
                                    this.selectedIndex ===
                                        RetailStepperStep.salesGratis ||
                                    this.selectedIndex ===
                                        RetailStepperStep.exchange) &&
                                this.customer &&
                                this.callService.call) ||
                            (this.callService.call.callType ===
                                CallTypes.rmWholesale &&
                                (this.selectedIndex ===
                                    RmWholesaleStepperStep.inDistribution ||
                                    this.selectedIndex ===
                                        RmWholesaleStepperStep.salesGratis ||
                                    this.selectedIndex ===
                                        RmWholesaleStepperStep.exchange) &&
                                this.customer.id &&
                                this.callService.call) ||
                            this.callType === CallTypes.chainHq
                        ) {
                            this.stepperCallApplicationService.shouldBuildProducts = true;
                            void this.builtUpProducts(true);
                            this.evaluateIfToggleAllInDistIsDisables();
                        }
                    });
                }
            }

            if (
                !this.selectedIndexSubscription ||
                this.selectedIndexSubscription.closed
            ) {
                this.selectedIndexSubscription = this.stepperCallApplicationService.observableSelectedIndex
                    .pipe(untilDestroyed(this))
                    .subscribe(async (selectedIndex) => {
                        this.selectedIndex = selectedIndex;
                        if (
                            ((this.callType === CallTypes.retail &&
                                (this.selectedIndex ===
                                    RetailStepperStep.inDistribution ||
                                    this.selectedIndex ===
                                        RetailStepperStep.salesGratis ||
                                    this.selectedIndex ===
                                        RetailStepperStep.exchange ||
                                    this.selectedIndex ===
                                        RetailStepperStep.closingNotes)) ||
                                (this.callType === CallTypes.rmWholesale &&
                                    (this.selectedIndex ===
                                        RmWholesaleStepperStep.inDistribution ||
                                        this.selectedIndex ===
                                            RmWholesaleStepperStep.salesGratis ||
                                        this.selectedIndex ===
                                            RmWholesaleStepperStep.exchange ||
                                        this.selectedIndex ===
                                            RmWholesaleStepperStep.closingNotes))) &&
                            this.customer?.id &&
                            this.callService.call
                        ) {
                            if (
                                this.stepperCallApplicationService
                                    .shouldBuildProducts ||
                                !this.products ||
                                this.products.length === 0
                            ) {
                                await this.builtUpProducts();
                            } else {
                                this.filterDisplayProducts(
                                    this.displayedCallProducts
                                );
                            }
                            await this.evaluateIfToggleAllInDistIsDisables();
                        }
                    });
            }
        }, 0);
    }

    //Events
    onWholesalerSelectChange(): void {
        setTimeout(() => {
            this.isWholesaler = !!this.selectedWholesaler.length;
            this.stepperCallApplicationService.shouldBuildProducts = true;
            this.categories = new Array<RadioButtonViewModel>();
            // if ((this.callService.call as RetailCall | RmWholesaleCall).selectedProject) {
            //     this.stepperCallApplicationService.hasOrderableProduct = !!this.isWholesaler;
            // }

            void this.builtUpProducts();
        }, 0);
    }

    //Public methods
    async builtUpProducts(search?: boolean): Promise<void> {
        if (
            (((this.callType === CallTypes.retail ||
                this.callType === CallTypes.rmWholesale) &&
                (this.stepperCallApplicationService.shouldBuildProducts ||
                    this.stepperCallApplicationService
                        .salesAndGratisWholesaleHasBeenUpdated)) ||
                this.customerType === CustomerGenericTypes.chainHeadquarter) &&
            this.customer &&
            this.callId &&
            ((this.callType === CallTypes.retail &&
                (
                    this.selectedIndex === RetailStepperStep.inDistribution ||
                    this.selectedIndex === RetailStepperStep.closingNotes
                )
            ) || (this.callType === CallTypes.rmWholesale &&
                (
                    this.selectedIndex === RmWholesaleStepperStep.inDistribution ||
                    this.selectedIndex === RmWholesaleStepperStep.closingNotes
            )) ||
                this.hasInjectedData)
        ) {
            if (
                this.callType === CallTypes.retail ||
                (this.callType === CallTypes.rmWholesale &&
                    (this.callService?.call as RmWholesaleCall)
                        ?.lastIndexVisited === this.selectedIndex)
            ) {
                this.stepperCallApplicationService.shouldBuildProducts = false;
            }

            if (!search) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
            }

            let products = new Array<ProductViewmodel>();

            if (this.selectedWholesaler?.length) {
                if (this.selectedProject?.length) {

                    const project = this.projects.find(
                        (project) => project.id === this.selectedProject[0].id
                    );
                    const wholesalerProjectProducts = project.projectProducts.filter(
                        (pp) =>
                            pp.wholesalerId ===
                            this.selectedWholesaler[0].wholesaler.id
                    );

                    if (wholesalerProjectProducts?.length) {
                        const wholesalerGroupProductCatalogItemResponse = await this.productDelineationService.getProductViewmodelsForWholesalerProjectProducts(
                            wholesalerProjectProducts
                        );
                        if (wholesalerGroupProductCatalogItemResponse?.values) {
                            products = wholesalerGroupProductCatalogItemResponse.values;
                        }
                    }
                    await this.buildProductsViewModels(products);
                } else {
                    const productViewmodelsResponse = await this.productDelineationService.getWholesalerProducts(
                        this.selectedWholesaler[0].wholesaler
                    );
                    if (!productViewmodelsResponse) {
                        this.shouldWait$.next(false);
                        return;
                    }

                    products = productViewmodelsResponse.values;
                    await this.buildProductsViewModels(products);
                }
            } else {
                if (this.selectedProject?.length) {
                    const project = this.projects.find(
                        (project) => project.id === this.selectedProject[0].id
                    );
                    const projectProducts = project.projectProducts.filter(
                        (pp) => !pp.wholesalerId
                    );

                    if (projectProducts?.length) {
                        const productResponse = await this.productDelineationService.getByIds(
                            projectProducts.map((pp) => pp.productId)
                        );
                        if (productResponse?.values) {
                            products = productResponse.values.map(
                                (product) => product as ProductViewmodel
                            );
                        }
                    }
                    await this.buildProductsViewModels(products);
                } else {
                    this.productSubscription = this.productDelineationService.observableActiveProducts.subscribe(
                        async (productsMap) => {
                            if (productsMap?.size && !this.selectedProject?.length) {
                                if (this.productSubscription)
                                    this.productSubscription.unsubscribe();

                                const productViewmodels = new Array<ProductViewmodel>();
                                productsMap.forEach((p) =>
                                    productViewmodels.push(
                                        p as ProductViewmodel
                                    )
                                );

                                await this.buildProductsViewModels(productViewmodels);
                            }
                        }
                    );
                }
            }

            this.stepperCallApplicationService.salesAndGratisWholesaleHasBeenUpdated = false;
        }
    }

    buildFilters(): void {
        this.categories = [];
        this.subsidiaries = []
        const all: RadioButtonViewModel = new RadioButtonViewModel();
        all.name = "All";
        all.value = "All";

        const categories = new Array<RadioButtonViewModel>();
        categories.push(all);

        const subsidiaries = new Array<RadioButtonViewModel>();
        subsidiaries.push(all);

        const distinctDivision: string[] = [
            ...new Set(this.products.map((myProducts) => myProducts.division))
        ];
        distinctDivision?.forEach((division) => {
            const rb = new RadioButtonViewModel();
            rb.name = division;
            rb.value = division;
            categories.push(rb);
        });

        const distinctSub: string[] = [
            ...new Set(this.products.map((myProducts) => myProducts.subsidiary))
        ];

        const cleanDistinctSub = distinctSub.filter(Boolean);

        cleanDistinctSub?.forEach((sub) => {
            const rb = new RadioButtonViewModel();
            rb.name = sub;
            rb.value = sub;
            subsidiaries.push(rb);
        });

        this.categories = categories.sort((a, b) =>
            a.name <= b.name ? -1 : 1
        );

        this.subsidiaries = subsidiaries.sort((a, b) =>
        a.name <= b.name ? -1 : 1
        );

        this.selectedCategoryProduct = all.name;
        this.selectedSubsidiary = all.name
    }

    async adjustAllInDist(): Promise<void> {
        if (
            this.displayedCallProducts &&
            this.displayedCallProducts.length > 0
        ) {
            const availableDisplayCallProducts = this.displayedCallProducts.filter(
                (dcp) => !this.isInDistDisabled(dcp)
            );
            if (availableDisplayCallProducts.length) {
                this.pleaseWaitService.showProgressSpinnerUntilLoaded(
                    this.shouldWait$
                );
                const progromaticUpdate = true;
                const first = availableDisplayCallProducts[0];
                let isChecked = true;
                if (first) {
                    isChecked = !first.isInDist;
                }
                for (const callProduct of availableDisplayCallProducts) {
                    callProduct.isInDist = isChecked;

                    await this.adjustInDist(callProduct, progromaticUpdate);
                }
            }

            this.stepperCallApplicationService.shouldBuildProducts = true;
            await this.builtUpProducts();
        }
    }

    displayProduct(item: any) {
        let firstLetter = item.charAt(0).toLocaleUpperCase()
        return firstLetter
    }

    async adjustCash(
        event: MatCheckboxChange,
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        if (!this.isFinalRetailReceiptPrinted) {
            if (
                this.customerStateService.isTaxAvailable() &&
                callProduct.isCash
            ) {
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).shouldVisitTax = true;
                this.callService.saveCallAndNotify();
            }
            const distributionProperty =
                callProduct.isCash && !callProduct.isInDist
                    ? DistributionProperties.inDist
                    : null;
            callProduct.excludeUpdating = "isCash";

            await this.applyCheckBoxLogic(
                callProduct,
                distributionProperty,
                DistributionActionItems.cash
            );

            if (!(this.callService.call as RetailCall).cashProducts?.length) {
                (this.callService.call as RetailCall).shouldVisitTax = false;
            } else if (this.customerStateService.isTaxAvailable()) {
                (this.callService.call as RetailCall).shouldVisitTax = true;
            }
            this.callService.saveCallAndNotify();
        }
    }

    async adjustCos(
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        callProduct.excludeUpdating = "isCos";
        await this.applyCheckBoxLogic(
            callProduct,
            DistributionProperties.cos,
            null
        );
    }

    async adjustInDist(
        callProduct: CallDistributionProductViewModel,
        programaticUpdate?: boolean
    ): Promise<void> {
        callProduct.excludeUpdating = programaticUpdate ? "isInDist" : null;
        await this.applyCheckBoxLogic(
            callProduct,
            DistributionProperties.inDist,
            this.mode === DistributionActionItems.exchangeOut
                ? DistributionActionItems.exchangeOut
                : null
        );
    }

    async adjustIsSelected(
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        if (
            this.customerStateService.isTaxAvailable() &&
            callProduct.isSelect &&
            this.mode === DistributionActionItems.cash
        ) {
            (this.callService.call as RetailCall).shouldVisitTax = true;
            this.callService.saveCallAndNotify();
        }

        if (
            (!this.selectedWholesaler ||
                this.selectedWholesaler?.length === 0) &&
            (callProduct.isOrder || this.mode === DistributionActionItems.order)
        ) {
            const hasMappingResponse = await this.productDelineationService.hasProductWholesalerMappings(
                this.customerStateService.customer,
                callProduct.product
            );
            if (!hasMappingResponse) {
                this.shouldWait$.next(false);
                return;
            }

            const hasMapping = hasMappingResponse.values;

            const doesWholesalerAndProductCurrentlyExist = !!(this.callService
                .call as RetailCall)?.orderProducts?.find(
                (op) =>
                    op.wholesalerCustomerId === callProduct.wholesaler?.id &&
                    op.productId === callProduct.product?.id
            );

            if (!hasMapping || doesWholesalerAndProductCurrentlyExist) {
                this.snackbarService.showWarning(
                    this.customerStateService.customer.name +
                        " has no wholesale customer mappings for " +
                        callProduct.product.description +
                        "."
                );
                setTimeout(() => {
                    callProduct.isSelect = false;
                }, 0);

                return;
            }
        }

        if (this.selectedWholesaler?.length) {
            callProduct.wholesaler = this.selectedWholesaler[0].wholesaler;
        }

        callProduct.excludeUpdating = "isSelect";
        this.stepperCallApplicationService.shouldBuildProducts = true;
        await this.applyCheckBoxLogic(
            callProduct,
            callProduct.isSelect &&
                !callProduct.isInDist &&
                this.mode !== DistributionActionItems.exchangeOut
                ? DistributionProperties.inDist
                : null,
            this.mode
        );

        if (!(this.callService.call as RetailCall).cashProducts?.length) {
            (this.callService.call as RetailCall).shouldVisitTax = false;
        } else if (this.customerStateService.isTaxAvailable()) {
            (this.callService.call as RetailCall).shouldVisitTax = true;
        }
        this.callService.saveCallAndNotify();
    }

    async adjustIntro(
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        callProduct.excludeUpdating = "isIntro";
        await this.applyCheckBoxLogic(
            callProduct,
            DistributionProperties.intro,
            null
        );
    }

    async adjustOos(
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        callProduct.excludeUpdating = "isOos";
        await this.applyCheckBoxLogic(
            callProduct,
            DistributionProperties.oos,
            null
        );
    }

    async adjustOrder(
        callProduct: CallDistributionProductViewModel
    ): Promise<void> {
        if (!this.isFinalWholesaleReceiptPrinted) {
            callProduct.excludeUpdating = "isOrder";
            if (this.selectedWholesaler?.length) {
                callProduct.wholesaler = this.selectedWholesaler[0].wholesaler;
            }
            if (!!(this.callService.call as RetailCall | RmWholesaleCall)?.selectedProject) {
                callProduct.selectedProject = (this.callService.call as RetailCall | RmWholesaleCall).selectedProject
            }
            await this.applyCheckBoxLogic(
                callProduct,
                callProduct.isOrder && !callProduct.isInDist
                    ? DistributionProperties.inDist
                    : null,
                DistributionActionItems.order
            );
            this.stepperCallApplicationService.distributionOrderProducthasBeenUpdated = true;
        }
    }

    async applyCheckBoxLogic(
        displayProduct: CallDistributionProductViewModel,
        updateProperty: DistributionProperties,
        updateAction: DistributionActionItems
    ): Promise<void> {
        displayProduct.selectedProject = this.projects.find((project) => project.id === this.selectedProject[0]?.id);
        const copyDisplayProduct = await this.callDistributionService.updateDistributionViewModel(
            displayProduct,
            updateProperty,
            updateAction
        );

        this.copyCallDistributionProductViewModel(
            displayProduct,
            copyDisplayProduct
        );
        this.filterDisplayProducts([displayProduct]);
        this.stepperCallApplicationService.shouldBuildProducts = true;
        await this.callService.saveCallAndNotify();
    }

    closedProjectSelect(): void {
        //Defer to next cycle to allow the SelectedProject drop down input to catch up.
        setTimeout(async () => {
            const project = this.projects.find(
                (p) =>
                    p.id ===
                    (this.selectedProject?.length
                        ? this.selectedProject[0].id
                        : null)
            );

            if (project !== this.savedProjectSelection) {
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).selectedProject = project;

                this.selectedWholesaler = [];
            }

            if (!!project) {
                let wholesalerIds = project.projectProducts
                    .filter((pp) => !!pp.wholesalerId)
                    .map((pp) => pp.wholesalerId);

                wholesalerIds = [...new Set(wholesalerIds)];
                if (wholesalerIds.length) {
                    await this.getWholesalerOptions(wholesalerIds);
                    this.selectedWholesaler = this.wholesalers[0]
                        ? this.wholesalers.slice(0, 1)
                        : new Array<WholesalerViewmodel>();
                }
                this.onWholesalerSelectChange();
            } else if (!project) {
                this.selectedWholesaler = new Array<WholesalerViewmodel>();
                await this.getWholesalerOptions();
                this.onWholesalerSelectChange();
            }
        }, 0);
    }

    copyCallDistributionProductViewModel(
        oldVm: CallDistributionProductViewModel,
        newVm: CallDistributionProductViewModel
    ): void {
        const exclude = newVm.excludeUpdating;
        if (!Helper.equalsIgnoreCase(exclude, "isCash")) {
            oldVm.isCash = newVm.isCash;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isOrder")) {
            oldVm.isOrder = newVm.isOrder;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isInDist")) {
            oldVm.isInDist = newVm.isInDist;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isIntro")) {
            oldVm.isIntro = newVm.isIntro;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isOos")) {
            oldVm.isOos = newVm.isOos;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isCos")) {
            oldVm.isCos = newVm.isCos;
        }
        if (!Helper.equalsIgnoreCase(exclude, "isSelect")) {
            oldVm.isSelect = newVm.isSelect;
        }
    }

    evaluateIfToggleAllInDistIsDisables(): void {
        if (
            this.searchText ||
            (this.selectedFilteredProduct &&
                !Helper.equalsIgnoreCase(
                    this.selectedFilteredProduct,
                    "All"
                )) ||
            (this.selectedCategoryProduct &&
                !Helper.equalsIgnoreCase(this.selectedCategoryProduct, "All"))
                ||
            (this.selectedSubsidiary &&
                !Helper.equalsIgnoreCase(this.selectedSubsidiary, "All"))
         )
        {
            this.isToggleInDistDisabled = false;
        } else {
            this.isToggleInDistDisabled = true;
        }
    }

    filterDisplayProducts(
        callProducts?: CallDistributionProductViewModel[]
    ): void {
        setTimeout(() => {
        if (
            this.isOnnInitPass ||
            (this.callType === CallTypes.retail &&
                (this.stepperCallApplicationService.selectedIndex ===
                    RetailStepperStep.inDistribution ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RetailStepperStep.salesGratis ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RetailStepperStep.exchange ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RetailStepperStep.closingNotes)) ||
            (this.callType === CallTypes.rmWholesale &&
                (this.stepperCallApplicationService.selectedIndex ===
                    RmWholesaleStepperStep.inDistribution ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RmWholesaleStepperStep.salesGratis ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RmWholesaleStepperStep.exchange ||
                    this.stepperCallApplicationService.selectedIndex ===
                        RmWholesaleStepperStep.closingNotes)) ||
            this.callType === CallTypes.chainHq
        ) {
            this.isOnnInitPass = false;

            if (callProducts) {
                this.updateCallProducts(callProducts);
            }

            let rtn: CallDistributionProductViewModel[] = this
                .callDistributionService.callProducts;
            switch (this.selectedFilteredProduct) {
                case "All":
                    break;
                case "In Dist":
                    rtn = this.callDistributionService.callProducts.filter(
                        (products) => products.isInDist
                    );
                    break;
                case "Not In Dist":
                    rtn = this.callDistributionService.callProducts.filter(
                        (products) => !products.isInDist
                    );
                    break;
                case "Orders":
                    rtn = this.callDistributionService.callProducts.filter(
                        (products) => products.isOrder
                    );
                    break;
                case "Cash":
                    rtn = this.callDistributionService.callProducts.filter(
                        (products) => products.isCash
                    );
                    break;
                case "Approved Products":
                    rtn = this.callDistributionService.callProducts.filter(
                        (products) => products.dateAvailable
                    );
                    break;
                default:
                    break;
            }

            if (!Helper.equalsIgnoreCase(this.selectedCategoryProduct, "All")) {
                rtn = rtn.filter((productVm) =>
                    Helper.equalsIgnoreCase(
                        productVm.product.division,
                        this.selectedCategoryProduct
                    )
                );
            }

            if (!Helper.equalsIgnoreCase(this.selectedSubsidiary, "All")) {
                rtn = rtn.filter((productVm) =>
                    Helper.equalsIgnoreCase(
                        productVm.product.subsidiary,
                        this.selectedSubsidiary
                    )
                );
            }

            if (this.searchText) {
                const searchText = this.searchText.toLocaleUpperCase();
                rtn = rtn
                    .filter(
                        (displayedProduct) =>
                            (displayedProduct.product.description &&
                                displayedProduct.product.description
                                    .toLocaleUpperCase()
                                    .includes(searchText)) ||
                            (displayedProduct.product.longDescription &&
                                displayedProduct.product.longDescription
                                    .toLocaleUpperCase()
                                    .includes(searchText)) ||
                            (displayedProduct.product.itemNumber &&
                                displayedProduct.product.itemNumber
                                    .toLocaleUpperCase()
                                    .includes(searchText))
                    )
                    .sort((a, b) =>
                        a.product.description <= b.product.description ? -1 : 1
                    );
            } else {
                rtn.sort((a, b) =>
                    a.product.description <= b.product.description ? -1 : 1
                );
            }

            this.setWholesalerProductMappings(rtn);

            if (this.mode === DistributionActionItems.order) {
                rtn = rtn.filter((vm) => !!vm.hasWholesalerProductMapping || !!vm.selectedProject);
            }
                this.displayedCallProducts = rtn;
            }
        }, 0)
        this.shouldWait$.next(true);
    }

    async initalizeProjects(): Promise<void> {
        const response = await this.projectDelineationService.getProfileProjects(
            [ProjectStatuses.Started],
            this.customer.customerType,
            this.employee.id,
            this.customer.id
        );
        if (!response) {
            return;
        }

        if (response.values?.length) {
            this.projects = response.values.filter(
                (p) => !!p.projectProducts?.length
            );
            this.projectDropDown = this.projects.map((p) => {
                const item = new GenericDropDownDto();
                item.id = p.id;
                item.name = p.name;
                item.displayValue = p.name;

                return item;
            });
        }

        // const selectedProject = (this.callService.call as RetailCall | RmWholesaleCall)?.selectedProject
        // if (selectedProject) {
        //     const selectedProjectDropDownDto = new GenericDropDownDto();
        //     selectedProjectDropDownDto.id = selectedProject.id;
        //     selectedProjectDropDownDto.name = selectedProject.name;
        //     selectedProjectDropDownDto.displayValue = selectedProject.name;
        //     this.selectedProject = [selectedProjectDropDownDto];
        //     this.closedProjectSelect();
        // }
    }

    openedProjectSelect(): void {
        this.savedProjectSelection = this.projects.find(
            (p) =>
                p.id ===
                (this.selectedProject?.length
                    ? this.selectedProject[0].id
                    : null)
        );
    }

    setWholesalerProductMappings(
        rtn: CallDistributionProductViewModel[]
    ): void {
        if (rtn?.length && this.wholesalerMappedProductIds?.length) {
            rtn.forEach((product) => {
                if (
                    this.wholesalerMappedProductIds.find(
                        (viewmodel) => viewmodel === product.product.id
                    )
                ) {
                    product.hasWholesalerProductMapping = true;
                } else {
                    product.hasWholesalerProductMapping = false;
                }
            });
        }
    }



    //Private methods
    private async buildProductsViewModels(products: ProductViewmodel[]): Promise<void> {
        if (products) {
            this.products = products;
            if (this.categories?.length === 0) {
                this.buildFilters();
            }
            const callProducts: CallDistributionProductViewModel[] = [];

            if (
                this.callService.call?.callType === CallTypes.retail ||
                this.callService.call?.callType === CallTypes.rmWholesale ||
                this.callService.call?.callType === CallTypes.chainHq
            ) {
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).inDistProductIds ??= [];
                this.callService.call.productsIntro ??= [];
                this.callService.call.productsOos ??= [];
                this.callService.call.productsCos ??= [];
                this.callService.call.productsOrder ??= [];
                this.callService.call.orderProducts ??= [];

                if (
                    this.callService.call?.callType === CallTypes.retail ||
                    this.callService.call?.callType === CallTypes.rmWholesale
                ) {
                    this.callService.call.productsCash ??= [];
                    this.callService.call.exchangeInProducts ??= [];
                    this.callService.call.exchangeOutProducts ??= [];
                    this.callService.call.cashProducts ??= [];
                }

                let selectedProject: Project = null;
                if (this.selectedProject?.length) {
                    const projectResponse = await this.projectDelineationService.getProjectById(this.selectedProject[0].id);
                    if (projectResponse?.values) {
                        selectedProject = projectResponse.values
                    }
                }

                if (this.callService.call.inDistAddedFromLastCall) {

                    for (const product of products) {
                        const callProduct: CallDistributionProductViewModel = new CallDistributionProductViewModel();
                        callProduct.product = product;

                        if (!!selectedProject) {
                            callProduct.selectedProject = selectedProject;
                        }

                        callProduct.isInDist = (this.callService.call as
                            | RetailCall
                            | RmWholesaleCall).inDistProductIds.includes(
                            product.id
                        );
                        callProduct.isIntro = this.callService.call.productsIntro.includes(
                            product.id
                        );
                        callProduct.isOos = this.callService.call.productsOos.includes(
                            product.id
                        );
                        callProduct.isCos = this.callService.call.productsCos.includes(
                            product.id
                        );
                        callProduct.isOrder = this.callService.call.productsOrder.includes(
                            product.id + (product.wholesalerId ?? "")
                        );

                        if (
                            this.callService.call?.callType ===
                                CallTypes.retail ||
                            this.callService.call?.callType ===
                                CallTypes.rmWholesale
                        ) {
                            callProduct.isCash = this.callService.call.productsCash.includes(
                                product.id
                            );
                            if (
                                this.mode === DistributionActionItems.exchangeIn
                            ) {
                                callProduct.isSelect = !!this.callService.call.exchangeInProducts.find(
                                    (prodIn) => prodIn.productId === product.id
                                );
                            }
                            if (
                                this.mode ===
                                DistributionActionItems.exchangeOut
                            ) {
                                callProduct.isSelect = !!this.callService.call.exchangeOutProducts.find(
                                    (prodOut) =>
                                        prodOut.productId === product.id
                                );
                            }
                            if (this.mode === DistributionActionItems.cash) {
                                callProduct.isSelect = !!this.callService.call.cashProducts.find(
                                    (productCash) =>
                                        productCash.productId === product.id
                                );
                            }
                            if (this.mode === DistributionActionItems.gratis) {
                                callProduct.isSelect = !!this.callService.call.gratisProducts.find(
                                    (prodGratis) =>
                                        prodGratis.productId === product.id
                                );
                            }
                        }

                        if (this.mode === DistributionActionItems.order) {
                            callProduct.isSelect = !!this.callService.call.orderProducts.find(
                                (productOrder) =>
                                    productOrder.productId === product.id &&
                                    productOrder.wholesalerCustomerId ===
                                        (product.wholesalerId ?? "")
                            );
                        }
                        callProduct.dateAvailable = product.dateAvailable;

                        callProduct.packSize =
                            callProduct.product.returnableUpc?.noOfEaches ?? 1;

                        callProducts.push(callProduct);
                    }
                } else {
                    if (
                        !this.lastCall ||
                        this.lastCall?.callType === CallTypes.retail ||
                        this.lastCall?.callType === CallTypes.rmWholesale ||
                        this.lastCall?.callType === CallTypes.chainHq
                    ) {
                        const hasInDistRecords =
                            (this.lastCall as RetailCall | RmWholesaleCall)
                                ?.inDistProductIds?.length > 0;

                        for (const product of products) {
                            const callProduct: CallDistributionProductViewModel = new CallDistributionProductViewModel();
                            callProduct.product = product;

                            if (!!selectedProject) {
                                callProduct.selectedProject = selectedProject;
                            }

                            if (
                                hasInDistRecords &&
                                (this.lastCall as
                                    | RetailCall
                                    | RmWholesaleCall)?.inDistProductIds.includes(
                                    product.id
                                )
                            ) {
                                callProduct.isInDist = true;

                                //Do not have to go through checkbox logic as we only recover the inDist value if pulling from lastCall
                                (this.callService.call as
                                    | RetailCall
                                    | RmWholesaleCall).inDistProductIds.push(
                                    product.id
                                );
                            }

                            callProduct.packSize = callProduct.product.returnableUpc?.noOfEaches ?? 1;

                            callProducts.push(callProduct);
                        }
                        this.callService.call.inDistAddedFromLastCall = true;
                    }
                }
            }

            this.callDistributionService.callProducts = callProducts;

            this.filterDisplayProducts(
                this.callDistributionService.callProducts
            );
        } else {
            this.shouldWait$.next(false);
        }
    }

    private updateCallProducts(
        displayedCallProducts: CallDistributionProductViewModel[]
    ) {
        if (
            displayedCallProducts &&
            this.callDistributionService.callProducts &&
            this.callDistributionService.callProducts.length >
                displayedCallProducts.length
        ) {
            displayedCallProducts.forEach((displayed) => {
                const index: number = this.callDistributionService.callProducts.findIndex(
                    (callProduct) =>
                        callProduct.product.id === displayed.product.id
                );
                if (index !== -1) {
                    this.callDistributionService.callProducts[
                        index
                    ] = displayed;
                }
            });
        }
    }

    isInDistDisabled(
        displayedCallProduct: CallDistributionProductViewModel
    ): boolean {
        let rtn = false;
        if (this.isFinalRetailReceiptPrinted) {
            const lockedRetailProduct =
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).cashProducts?.find(
                    (p) => p.productId === displayedCallProduct.product.id
                ) ??
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).gratisProducts?.find(
                    (p) => p.productId === displayedCallProduct.product.id
                ) ??
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).exchangeInProducts?.find(
                    (p) => p.productId === displayedCallProduct.product.id
                ) ??
                (this.callService.call as
                    | RetailCall
                    | RmWholesaleCall).exchangeOutProducts?.find(
                    (p) => p.productId === displayedCallProduct.product.id
                );
            rtn = !!lockedRetailProduct;
        }
        if (!rtn && this.isFinalWholesaleReceiptPrinted) {
            const lockedWholesaleProduct = (this.callService.call as
                | RetailCall
                | RmWholesaleCall).orderProducts?.find(
                (p) => p.productId === displayedCallProduct.product.id
            );
            rtn = !!lockedWholesaleProduct;
        }
        return rtn;
    }

    isSelectDisabled(): boolean {
        return this.mode === DistributionActionItems.order
            ? this.isFinalWholesaleReceiptPrinted
            : this.isFinalRetailReceiptPrinted;
    }

    private async getWholesalerOptions(wholesalerIds?: string[]) {
        let wholesalers = new Array<Customer>();
        const wholesalersResponse = await this.customerDelineationService.getWholesalersByCustomerWithProducts(
            this.customerStateService.customer
        );

        if (!wholesalersResponse) {
            this.shouldWait$.next(false);
        } else {
            const availableWholesalers = this.customerStateService.customer.customerWholesalers
                .map((wholesalers) => wholesalers.wholesalerId)
                .filter(v => wholesalerIds ? wholesalerIds.includes(v) : !!v);
            wholesalers = wholesalersResponse.values?.filter((wholesaler) =>
                availableWholesalers.includes(wholesaler.id)
            );
            this.wholesalers = wholesalers
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((wholesaler) => new WholesalerViewmodel(wholesaler));

            if (wholesalers.length) {
                const mappedProductIdsResponse = await this.productDelineationService.getWholesalerMappedProduct(
                    wholesalers.map((w) => w.id)
                );
                if (mappedProductIdsResponse?.values?.length) {
                    this.wholesalerMappedProductIds =
                        mappedProductIdsResponse.values;
                }
                this.stepperCallApplicationService.hasOrderableProduct = !!this.wholesalerMappedProductIds?.length;

            } else {
                this.stepperCallApplicationService.hasOrderableProduct = false;
            }
        }
    }
}

import { Component, ElementRef, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import moment from "moment";
import { RefinerLocation, valueSeparator } from "shield.shared";
import { Refiner } from "src/app/entity-models/refiner.entity";
import { CustomerDelineationService } from "src/app/services/delineation-services/customer-delineation.service";
import { FilterService } from "src/app/services/filter.service";
import { FilterBaseComponent } from "../filter-base/filter-base.component";
import { OrderExtractionViewmodel } from "./order-extraction.viewmodel";
import { OrderExtractionWholesalerService } from "src/app/orders/order-extraction/order-extraction-wholesaler.service";

@Component({
    selector: "app-order-extraction-filter",
    templateUrl: "./order-extraction-filter.component.html",
    styleUrls: ["./order-extraction-filter.component.css"]
})
export class OrderExtractionFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {
    //view childs
    @ViewChild("wholesalerSelect") wholesalerSelect: MatSelect;
    @ViewChild("chainSelect") chainSelect: MatSelect;
    @ViewChild("endDateInput") endDateInput: ElementRef;

    //public vars
    contentComponent: Type<OrderExtractionFilterComponent>;
    icon = "layers";
    name = "Order Information";
    viewmodel = new OrderExtractionViewmodel(
        this.filterService, this.customerDelineationService, this.formBuilder
    );

    constructor(
        private filterService: FilterService,
        private customerDelineationService: CustomerDelineationService,
        private wholesalerService: OrderExtractionWholesalerService,
        private formBuilder: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.setInitialized();
        if (
            !this.viewmodel.wholesalerSubscription ||
            this.viewmodel.wholesalerSubscription.closed
        ) {
            this.viewmodel.wholesalerSubscription = this.wholesalerService.observableWholesalers.subscribe(
                (wholesalers) => {
                    if (wholesalers) {
                        this.viewmodel.wholesalers = wholesalers;
                    }
                }
            );
        }

        if (
            !this.viewmodel.selectedWholesalerSubscription ||
            this.viewmodel.selectedWholesalerSubscription.closed
        ) {
            this.viewmodel.selectedWholesalerSubscription = this.wholesalerService.observableSelectedWholesaler.subscribe(
                (wholesaler) => {
                    this.viewmodel.selectedWholesaler = wholesaler;
                }
            );
        }
    }

    ngOnDestroy(): void {
        if (
            this.viewmodel.wholesalerSubscription &&
            !this.viewmodel.wholesalerSubscription.closed
        ) {
            this.viewmodel.wholesalerSubscription.unsubscribe();
        }

        if (
            this.viewmodel.selectedWholesalerSubscription &&
            !this.viewmodel.selectedWholesalerSubscription.closed
        ) {
            this.viewmodel.selectedWholesalerSubscription.unsubscribe();
        }
    }

    //events
    onAddAccountRefiner(event?: KeyboardEvent) {
        this._refinerService.onInputChange(
            RefinerLocation.account,
            this.viewmodel.accountInput
        );
        if (
            this.viewmodel.accountInput &&
            (!event || this.inputSubmitKeys.includes(event.key))
        ) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.account;
            refiner.value = this.viewmodel.accountInput;
            refiner.dataPropertyName = "storeInformation";
            this._refinerService.addRefiners(refiner);
        }
    }

    onAddEndDateRefiner(event?: KeyboardEvent | FocusEvent): void {
        this._refinerService.onInputChange(
            RefinerLocation.orderOnOrBeforeDate,
            this.endDateInput.nativeElement.value
        );
        if (!event || (event as KeyboardEvent).key === "Enter") {
            setTimeout(() => {
                const refiner = new Refiner();
                refiner.location = RefinerLocation.orderOnOrBeforeDate;
                refiner.value = this.endDateInput.nativeElement.value;
                refiner.dataPropertyName = "orderDate";

                this._refinerService.checkAndUpdateRefiner(refiner);
            }, 0)
        }
    }

    onOpenedChainChange(): void {
        if (!this.chainSelect.panelOpen) {
            const refiner = new Refiner();
            refiner.location = RefinerLocation.chains;
            refiner.value = this.viewmodel.selectedChains.join(", ");
            refiner.dataPropertyName = "chainName";
            refiner.dataValue = this.viewmodel.selectedChains.join(valueSeparator);

            this._refinerService.checkAndUpdateRefiner(refiner);
        }
    }

    onOpenedWholesalerChange(): void {
        if (!this.wholesalerSelect.panelOpen) {
            this.wholesalerService.selectedWholesaler = this.viewmodel.selectedWholesaler;
        }
    }

    onInputChange(refiner: Refiner): void {
        if (refiner) {
            switch (refiner.location) {
                case RefinerLocation.orderOnOrBeforeDate:
                    if (!refiner.value || refiner.value !== this.endDateInput?.nativeElement.value) {
                        this.viewmodel.dateForm.controls.endDate.setValue(refiner.value
                            ? moment(new Date(refiner.value))
                            : null)
                    }
                    break;
                case RefinerLocation.account:
                    if (refiner.value !== this.viewmodel.accountInput) {
                        this.viewmodel.accountInput = refiner.value;
                    }
                    break;
                case RefinerLocation.chains:
                    const selectedChains = refiner.dataValue?.split(valueSeparator);
                    const rtnSelectedChains = new Array<string>();

                    if (selectedChains) {
                        for (const chain of selectedChains) {
                            const found = this.viewmodel.selectedChains.find(
                                (sc) => sc === chain
                            );
                            if (found) {
                                rtnSelectedChains.push(found);
                            }
                        }
                    }
                    this.viewmodel.selectedChains = rtnSelectedChains;
                    break;
                default:
                    break;
            }
        }
    }

    onRefinersChange(): void {
        const tempRefiner = new Refiner();
        tempRefiner.location = RefinerLocation.wholesalers;
        const wholesalersRefiner = this._refinerService.refiners.find(
            (refiner) => refiner.location === RefinerLocation.wholesalers
        );
        if (wholesalersRefiner) {
            this.onInputChange(wholesalersRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.orderOnOrBeforeDate;
        const endDateRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.orderOnOrBeforeDate
        );
        if (endDateRefiner) {
            this.onInputChange(endDateRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.account;
        const accountRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.account
        );
        if (accountRefiner) {
            this.onInputChange(accountRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }

        tempRefiner.location = RefinerLocation.chains;
        const chainRefiner = this._refinerService?.refiners.find(
            (refiner) => refiner.location === RefinerLocation.chains
        );
        if (chainRefiner) {
            this.onInputChange(chainRefiner);
        } else {
            this.onInputChange(tempRefiner);
        }
    }
}

<div class="dialog-container">
    <div class="row" class="margin-auto">
        <div class="sheen-container">
            <img (click)="clickLogo($event)" class="loader-logo loader-spin" src="../../../assets/img/shield-vector-logo.svg" alt="Shield loading image" />
            <div class="sheen"></div>
        </div>
    </div>
    <div class="row">
        <div class="col">{{text}}</div>
    </div>
</div>
